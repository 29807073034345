Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"

exports.btnExampleTitle = "CLICK ME";
exports.rolesAndPermissionsAPIEndpoint = "roles_permissions/default_permissions";
exports.updateRolesAndPermissions = "roles_permissions"
// Customizable Area End