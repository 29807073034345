import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import * as yup from 'yup';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  showActions: boolean;
  createModal: boolean;
  previewModal: boolean;
  recruiterDetailArray: any;
  token: string;
  auth: string | null | undefined;
  pageNumber: any;
  perPageRecord: any;
  search_keyword: any;
  order_by: any;
  direction: any;
  paginationDetails: any;
  previewId: any;
  previewData: any;
  editModal: boolean;
  deleteOpen: boolean;
  createOrEditOpen: boolean;
  deleteOneId: any;
  deleteAllId: any;
  multiSelectArray: any;
  isDisableOrEnable: any;
  allChecked: boolean;
  selectedFile: any;
  imageUrl: any;
  fileName: string;
  sizeAndImage: boolean;
  companyDetailArray: any;
  getOneCompanyData: any;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  importModal: boolean;
  fileUrl: any;
  previewArray: any

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RecruiterConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  auth: string | null | undefined = window.localStorage.getItem("auth")
  getAllRecruiterApiCallId: any;
  createNewRecruiterApiCallId: any;
  updateCompanyApiCallId: any;
  deleteRecruiterApiCallId: any;

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showActions: false,
      createModal: false,
      previewModal: false,
      recruiterDetailArray: [],
      token: "",
      auth: "",
      paginationDetails: {},
      pageNumber: 1,
      perPageRecord: 40,
      search_keyword: "",
      order_by: "name",
      direction: "default",
      previewId: "",
      previewData: [],
      editModal: false,
      createOrEditOpen: false,
      deleteOpen: false,
      allChecked: false,
      deleteOneId: null,
      deleteAllId: [],
      multiSelectArray: [],
      isDisableOrEnable: true,
      selectedFile: '',
      imageUrl: null,
      fileName: '',
      sizeAndImage: false,
      companyDetailArray: [],
      getOneCompanyData: {},
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      importModal: false,
      fileUrl: null,
      previewArray: []

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.getAllRecruiters(
      this.state.pageNumber,
      this.state.perPageRecord,
      this.state.search_keyword,
      this.state.order_by,
      this.state.direction
    );
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.perPageRecord !== this.state.perPageRecord ||
      prevState.search_keyword !== this.state.search_keyword ||
      prevState.order_by !== this.state.order_by ||
      prevState.direction !== this.state.direction
    ) {
      // console.log(prevState);
      this.getAllRecruiters(
        this.state.pageNumber,
        this.state.perPageRecord,
        this.state.search_keyword,
        this.state.order_by,
        this.state.direction
      );
    }
    if (prevState.previewId !== "") {
      // this.getFilterData(this.state.previewId)
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (this.getAllRecruiterApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllRecruiterApiCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('adminResponse : ', responseJson)
        let array = responseJson.data;
        let pagination = responseJson.meta.pagination;

        console.log(pagination);
        array.map((val: any) => {
          val.isChecked = false;
        });
        console.log("array check =====>", array);
        this.setState({
          recruiterDetailArray: array,
          paginationDetails: pagination,
        });

      } else {
        console.log('Profile Source List Error : ', responseJson)

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    if (this.createNewRecruiterApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createNewRecruiterApiCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('adminResponse : ', responseJson)
        this.getAllRecruiters(
          this.state.pageNumber,
          this.state.perPageRecord,
          this.state.search_keyword,
          this.state.order_by,
          this.state.direction
        );
        this.setState({ createModal: false })

      } else {
        console.log('Profile Source List Error : ', responseJson)

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    if (this.deleteRecruiterApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteRecruiterApiCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('delete : ', responseJson)
        this.setState({
          deleteOpen: false,
        });
        this.getAllRecruiters(
          this.state.pageNumber,
          this.state.perPageRecord,
          this.state.search_keyword,
          this.state.order_by,
          this.state.direction
        );

      } else {
        console.log('Profile Source List Error : ', responseJson)

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    if (this.updateCompanyApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateCompanyApiCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('delete : ', responseJson)
        this.setState({
          editModal: false,
        });
        this.getAllRecruiters(
          this.state.pageNumber,
          this.state.perPageRecord,
          this.state.search_keyword,
          this.state.order_by,
          this.state.direction
        );

      } else {
        console.log('Profile Source List Error : ', responseJson)

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);

  }

  prevPageHandler = () => {
    const temp = this.state.paginationDetails.prev_page;
    console.log("prevPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };

  nextPageHandler = () => {
    const temp = this.state.paginationDetails.next_page;
    console.log("nextPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };

  getFilterData = (id: any) => {
    console.log(id)
    this.setState({
      previewData: this.state.recruiterDetailArray.filter((admin: any) => {
        return admin.id === id
      }
      )
    })
  }

  selectAllHandler = () => {
    let arrayIds: any = [];
    let isDisableOrEnable: any;

    this.state.recruiterDetailArray.map((val: any) => {
      val.isChecked = this.state.allChecked ? false : true;
      if (!this.state.allChecked) {
        arrayIds.push(val.id);
      }
    });
    console.log("this.state.allChecked)", this.state.allChecked, arrayIds);
    if (this.state.allChecked) {
      isDisableOrEnable = true;
    }
    else {
      isDisableOrEnable = false;
    }
    this.setState({
      recruiterDetailArray: this.state.recruiterDetailArray,
      allChecked: !this.state.allChecked,
      // deleteAllId: arrayIds,
      multiSelectArray: arrayIds,
      isDisableOrEnable
    });
  };

  isDeleteButtonDisable = () => {
    let recruiterDetailArray = [...this.state.recruiterDetailArray]
    for (let index = 0; index < recruiterDetailArray.length; index++) {
      const element = recruiterDetailArray[index];
      if (element.isChecked) {
        return false;
      }

    }
    return true;
  }
  multiSelectHandler = (index: any, id: any) => {
    let isDisableOrEnable: any;
    let array: any;
    array = [...this.state.multiSelectArray]
    let idCheckedOrNot: any = array.indexOf(id);
    console.log("idCheckedOrNot ====>", idCheckedOrNot)
    if (idCheckedOrNot === -1) {
      array.push(id)
      this.setState({ multiSelectArray: array })
      console.log("array push ====>", array)
    }
    else {
      array = array.filter((ids: any) => ids != id)
      console.log("array pop ====>", array)
      this.setState({ multiSelectArray: array })
    }
    let recruiterDetailArray = [...this.state.recruiterDetailArray]
    recruiterDetailArray[index].isChecked = !recruiterDetailArray[index].isChecked;
    if (this.isDeleteButtonDisable()) {
      isDisableOrEnable = true;
    } else {
      isDisableOrEnable = false;
    }
    this.setState({ recruiterDetailArray, multiSelectArray: array, isDisableOrEnable });
  };


  selectedFileHandler = (evt: any) => {
    const file = evt.target?.files[0];
    if ((file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg") && file.size <= 10e6) {
      console.log("file=====================>", file.type, file.size, file.name);
      this.setState({ selectedFile: file });
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ imageUrl: reader.result, fileName: file.name, sizeAndImage: false });
        }
      };
      reader.readAsDataURL(evt.target?.files[0]);
    }
    else {
      this.setState({ sizeAndImage: true })
    }
  };

  updateRecruiter = (values: any) => {
    const header = {
      token: this.auth,
    };
    console.log("update api  values==================>", values, this.state.selectedFile);

    const formData = new FormData();

    formData.append('data[type]', 'email_account');
    formData.append('data[section]', 'recruiter');
    if (this.state.selectedFile) {
      formData.append('data[attributes][avatar]', this.state.selectedFile);
    }
    formData.append('data[attributes][first_name]', values.firstName);
    formData.append('data[attributes][last_name]', values.lastName);
    formData.append('data[attributes][new_phone_number]', `+91 ${values.phoneNumber}`);
    formData.append('data[attributes][new_alternate_number]', `+91 ${values.alternateNumber}`);
    formData.append('data[attributes][new_whatsapp_number]', `+91 ${values.whatsappNumber}`);
    formData.append('data[attributes][country]', values.country);
    formData.append('data[attributes][state]', values.state);
    formData.append('data[attributes][city_district]', values.city_district);
    // formData.append('data[attributes][company]', values.company)
    formData.append('data[attributes][email]', values.email);
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.dashboardRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.dashboardWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.dashboardDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'dashboard')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.requirementsRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.requirementsWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.requirementsDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'requirements')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.profileRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.profileWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.profileDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'profile')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.reportsRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.reportsWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.reportsDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'reports')
    formData.append('data[attributes][permissions_attributes][][read_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][write_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][delete_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][feature]', 'roles_and_permissions')
    formData.append('data[attributes][permissions_attributes][][read_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][write_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][delete_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][feature]', 'manage_user')

    const httpBody = formData;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAdminApiEndPoint + `${values.user_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCompany = () => {
    console.log("id of array delete  this.state.multiSelectArray=========>", this.state.multiSelectArray);
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log(this.state.deleteOneId)

    this.deleteRecruiterApiCallId = requestMessage.messageId;
    if (this.state.deleteOneId) {
      console.log("im here 1")
      let id = [];
      id.push(this.state.deleteOneId)
      this.setState({ deleteOneId: null })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteRecruiterAPIEndPoint + `[${id}]`
      );
    } else {
      console.log("im here 2")
      console.log(`${this.state.multiSelectArray}`)
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteRecruiterAPIEndPoint + `[${this.state.multiSelectArray}]`
      );
      this.setState({ multiSelectArray: [], allChecked: false, isDisableOrEnable: true })
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  //   setFormData = (values: any) => {
  //     const formData = new FormData();

  //     this.getFormData(values, formData);
  //     return formData;
  // }

  // getFormData = (values: any, formData: any, parentKey: any) => {

  //     if (values && typeof values === 'object' && !(values instanceof Date) && !(values instanceof File) && !(values instanceof Array)) {
  //         Object.keys(values).forEach(key => {
  //             this.getFormData(values[key], formData, parentKey ? `${parentKey}[${key}]` : key);
  //         });
  //     }
  //     else if (values instanceof Array) {
  //         values.forEach((item) => formData.append(`${parentKey}[]`, item))
  //     }
  //     else {
  //         const value = values == null ? '' : values;
  //         formData.append(parentKey, value);
  //     }
  //     // Object.keys(values).forEach(key => formData.append(key, JSON.stringify(values[key])));
  //     // return formData;
  // }

  createNewRecruiter = (values: any) => {
    const header = {
      token: this.auth,
    };

    let permissionArray = [{
      "read_permission": values.dashboardRead,
      "write_permission": values.dashboardWrite,
      "delete_permission": values.dashboardDelete,
      "feature": "dashboard"
    },
    {
      "read_permission": values.requirementsRead,
      "write_permission": values.requirementsWrite,
      "delete_permission": values.requirementsDelete,
      "feature": "requirements"
    },
    {
      "read_permission": values.profileRead,
      "write_permission": values.profileWrite,
      "delete_permission": values.profileDelete,
      "feature": "profile"
    }, {
      "read_permission": values.reportsRead,
      "write_permission": values.reportsWrite,
      "delete_permission": values.reportsDelete,
      "feature": "reports"
    }];

    console.log("values create==================>", values);
    const formData = new FormData();

    formData.append('data[type]', 'email_account');
    formData.append('data[section]', 'recruiter');
    if (this.state.selectedFile) {
      formData.append('data[attributes][avatar]', this.state.selectedFile);
    }
    formData.append('data[attributes][first_name]', values.firstName);
    formData.append('data[attributes][last_name]', values.lastName);
    formData.append('data[attributes][full_phone_number]', `+91 ${values.phoneNumber}`);
    formData.append('data[attributes][full_alternate_number]', `+91 ${values.alternateNumber}`);
    formData.append('data[attributes][full_whatsapp_number]', `+91 ${values.whatsappNumber}`);
    formData.append('data[attributes][email]', values.email);
    formData.append('data[attributes][country]', values.country);
    formData.append('data[attributes][state]', values.state);
    formData.append('data[attributes][city_district]', values.city_district);
    // formData.append('data[attributes][company]', values.company)
    formData.append('data[attributes][email]', values.email);
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.dashboardRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.dashboardWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.dashboardDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'dashboard')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.requirementsRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.requirementsWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.requirementsDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'requirements')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.profileRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.profileWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.profileDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'profile')
    formData.append('data[attributes][permissions_attributes][][read_permission]', values.reportsRead)
    formData.append('data[attributes][permissions_attributes][][write_permission]', values.reportsWrite)
    formData.append('data[attributes][permissions_attributes][][delete_permission]', values.reportsDelete)
    formData.append('data[attributes][permissions_attributes][][feature]', 'reports')
    formData.append('data[attributes][permissions_attributes][][read_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][write_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][delete_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][feature]', 'roles_and_permissions')
    formData.append('data[attributes][permissions_attributes][][read_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][write_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][delete_permission]', 'false')
    formData.append('data[attributes][permissions_attributes][][feature]', 'manage_user')








    // const httpBody = formData;


    // var htmlBody = {
    //   "data": {
    //     "type": "email_account",
    //     "section": "admin",
    //     "attributes": {
    //       "first_name": values.firstName,
    //       "last_name": values.firstName,
    //       "full_phone_number": `+91 ${values.phoneNumber}`,
    //       "full_alternate_number": `+91 ${values.alternateNumber}`,
    //       "full_whatsapp_number": `+91 ${values.whatsappNumber}`,
    //       "email": values.email,
    //       "permissions_attributes": [
    //         {
    //           "read_permission": values.dashboardRead,
    //           "write_permission": values.dashboardWrite,
    //           "delete_permission": values.dashboardDelete,
    //           "feature": "dashboard"
    //         },
    //         {
    //           "read_permission": values.requirementsRead,
    //           "write_permission": values.requirementsWrite,
    //           "delete_permission": values.requirementsDelete,
    //           "feature": "requirements"
    //         },
    //         {
    //           "read_permission": values.profileRead,
    //           "write_permission": values.profileWrite,
    //           "delete_permission": values.profileDelete,
    //           "feature": "profile"
    //         },
    //         {
    //           "read_permission": values.reportsRead,
    //           "write_permission": values.reportsWrite,
    //           "delete_permission": values.reportsDelete,
    //           "feature": "reports"
    //         }
    //       ]
    //     }
    //   }
    // }

    // console.log(htmlBody)


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewRecruiterApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewAdminAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };
  getAllRecruiters = (
    pageNumber: any,
    perPageRecord: any,
    search_keyword: any,
    order_by: any,
    direction: any
  ) => {
    const header = {
      "Content-Type": configJSON.adminListApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRecruiterApiCallId = requestMessage.messageId;
    let getAllCompanyEndPoint
    if (search_keyword === "" && direction === "default") {
      getAllCompanyEndPoint = `account/accounts?page=1&per=5&search_keyword=&order_by=&direction=asc&section=recruiter`;
    }
    else {
      getAllCompanyEndPoint = `account/accounts?page=${pageNumber}&per=${perPageRecord}&search_keyword=${search_keyword}&order_by=${order_by}&direction=${direction}&section=recruiter`;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAllCompanyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
  createAdminValidations() {
    const validations = yup.object().shape({
      /*
      avatar: yup.mixed()
        .required("Avatar is required."),*/
      firstName: yup.string()
        .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("First Name is required."),
      lastName: yup.string()
        .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Last Name is required."),
      company: yup.string()
        .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("company Name is required."),
      email: yup
        .string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
      phoneNumber: yup.number()
        .required("Phone number is required.")
        .typeError("Only numbers are allowed.")
        .positive("Negative numbers are not allowed.")
        .integer("Phone can't contain a decimal.")
        .min(1000000000, "Minimum 10 digits are required.")
        .max(9999999999, "Maximum 10 digits are allowed."),
      alternateNumber: yup.number()
        .typeError("Only numbers are allowed.")
        .positive("Negative numbers are not allowed.")
        .integer("Phone can't contain a decimal.")
        .min(1000000000, "Minimum 10 digits are required.")
        .max(9999999999, "Maximum 10 digits are allowed."),
      whatsappNumber: yup.number()
        .typeError("Only numbers are allowed.")
        .positive("Negative numbers are not allowed.")
        .integer("Phone can't contain a decimal.")
        .min(1000000000, "Minimum 10 digits are required.")
        .max(9999999999, "Maximum 10 digits are allowed."),
      country: yup.string()
        .required("Country is required."),
      state: yup.string()
        .required("State is required."),
      city_district: yup.string()
        .required("City is required."),
    });

    return validations;
  }
}
