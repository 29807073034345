"use strict";


//const baseURL = "http://localhost:8080/https://decimalspacesrecruitment-31859-ruby.31859.dev.ap-southeast-1.aws.svc.builder.cafe";
const baseURL = "https://decimalspacesrecruitment-31859-ruby.31859.dev.ap-southeast-1.aws.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
