Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";

exports.placeHolderEmail = "Email Address";
exports.placeHolderPassword = "Password";
exports.placeHolderConfirmPassword = "Confirm Password";

exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "CREATE ACCOUNT";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";
exports.errorPassword = "Atleast One Digit, One Special Character [@$!%*?&], One Upper and One lowercase letter is required. Minimum Password length is 8";
exports.errorConfirmPasswordMatch = "Passwords must match.";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
