import React from "react";
import CompanyController from "./CompanyController.web";
import map from "lodash/map";
import csc from 'country-state-city';
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import "../scss/style.scss";
import { Formik , Form , Field , ErrorMessage } from 'formik';

import Modal from "react-modal";
Modal.setAppElement("#root");
export default class Company extends CompanyController {
  render() {
    var allCountryList = csc.getAllCountries();
    var allStateList = csc.getAllStates();
    var allCityList = csc.getAllCities();
    let states :any = {}
    let cities : any ={}
    let countries : any = {}
   let stateNames:any={}
    for (let index = 0; index < allCountryList.length; index++) {
      const element : any = allCountryList[index];
        countries[element.name] = element.isoCode
    }
    for (let index = 0; index < allStateList.length; index++) {
      const element : any = allStateList[index];
      stateNames[element.name] = element.isoCode
    }

    for (let index = 0; index < allStateList.length; index++) {
      const element : any = allStateList[index];
      if(!states[element.countryCode]){
        states[element.countryCode] = []
        states[element.countryCode].push(element)
      }
      else {
        states[element.countryCode] = [element,...states[element.countryCode]]
      }
    }

    for (let index = 0; index < allCityList.length; index++) {
      const element : any = allCityList[index];
      if(!cities[element.stateCode]){
        cities[element.stateCode] = []
        cities[element.stateCode].push(element)
      }
      else {
        cities[element.stateCode] = [element,...cities[element.stateCode]]
      }
    }
     //console.log("allCountryList=========================>",allCountryList)
     //console.log("allStateList=========================>",states)
     //console.log("allCityList=========================>",cities)
     //console.log("this.state.multiSelectArray======>",this.state.multiSelectArray)
     console.log("this.state?.companyDetailArray",this.state?.companyDetailArray,this.state?.companyDetailArray.length )
    return (
      <>
        <div className="main-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <section className="main-table-wrapper">
                  <div className="top-section flex-between">
                    <div className="headings">
                      <h1 className="mb-1">Manage Company</h1>
                      <p>
                        Lorem Ipsume demo contetn should be here so we can see
                        how it works.
                      </p>
                    </div>
                    <div className="search-links">
                      <div className="links mb-1">
                        <button className="link" onClick={()=>{this.setState({createOrEditOpen:true ,editOpen:false})}}>
                          Create
                        </button>
                      </div>
                      <div className="search-box">
                        <input
                          type="search"
                          id="search"
                          className="input"
                          value={this.state?.search_keyword||""}
                          onChange={this.onChangeHandler}
                        required/>
                        <label htmlFor="search">Search Here</label>
                        <img
                        className="search-icon"
                        src={require("../assets/search-icon.svg")}
                        alt="Icon"
                      />
                      </div>
                    </div>
                  </div>
                  <div className="table-nav">
                    <div className={this.state?.companyDetailArray.length!==0 ?"flex-center":"flex-center visibility-hidden"}>
                    <label className="checkbox-lbl">
                     <input type="checkbox" checked={this.state.allChecked}  onClick={this.selectAllHandler}/>
                      <span className="checkmark"></span>
                    </label>
                    <button className="link" disabled={this.state.isDisableOrEnable} onClick={()=>{this.setState({deleteOpen:true})}}>
                      <img
                        className="delete-item ml-1"
                        src={require("../assets/delete-icon.png")}
                        alt="Icon"
                      />
                      </button>
                    </div>
                    <div className="record-counter flex-center">
                      <p>Show</p>
                      <select name="record" id="record" value={this.state.perPageRecord} onChange={this.perPageRecordHandler}  className="select-options">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                      {
                        this.state?.companyDetailArray.length!==0 ?  
                        <p>Showing {this.state.paginationDetails.current_page} to {this.state.paginationDetails.total_pages} of {this.state.paginationDetails.total_count}</p>
                        :
                        <p>Showing {this.state.paginationDetails.current_page} to {this.state.paginationDetails.current_page} of {this.state.paginationDetails.total_count}</p>
                      
                      }
                      {/* {console.log("this.state.paginationDetails.prev_page",this.state.paginationDetails.prev_page)} */}
                      <div className="prev-page page-nav" ><button className="icon-btn" onClick ={this.prevPageHandler} disabled={this.state.paginationDetails.prev_page === null ?true:false} ><img className="" src={require("../assets/right-arrow.svg")} alt="icon"  /></button></div>
                      <div className="next-page page-nav" ><button className="icon-btn" onClick ={this.nextPageHandler} disabled={this.state.paginationDetails.next_page === null ?true:false}><img className="" src={require("../assets/right-arrow.svg")} alt="icon" /></button></div>
                    </div>
                  </div>
                
                  <div className="table-head">
                    <div className="t-row">
                      <div className="th">
                        Sort By :  <select name="sort" id="sort" value={this.state.direction} onChange={this.directionHandler} className="select-simple">
                        <option value="default">Default</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      </div>
                      <div className="t-div flex-between-center">
                        <div className="td min-width-23">Email address</div>
                        <div className="td">Contact no</div>
                        <div className="td">Employer name</div>
                        <div className="td min-width-25">Company Address</div>
                        <div className="td min-width-12" />
                      </div>
                    </div>
                  </div>
                  {
                    this.state?.companyDetailArray.length!==0  ?
                    <div className="table-body">
                    {map(
                      this.state?.companyDetailArray,
                      (companyDetail: any, index: any) => {
                        return (
                          <div className="t-row" key={companyDetail?.id} >
                            <div className="th">
                            <label className="checkbox-lbl ">
                              <input type="checkbox" disabled={this.state.allChecked} checked={companyDetail.isChecked} onChange={()=>{this.multiSelectHandler(index ,companyDetail?.id)}} />
                              <span className="checkmark"></span>
                            </label>
                              <img
                                className="logo-icon"
                                src={companyDetail.attributes?.logo || require("../assets/Group-1.5x.png") }
                                alt="Icon"
                              />{" "}
                              {companyDetail.attributes.name}
                            </div>
                            <div className="t-div flex-between-center">
                              <div className="td min-width-23">
                                {companyDetail.attributes.email}
                              </div>
                              <div className="td">
                                {companyDetail.attributes.full_contact_number}
                              </div>
                              <div className="td">
                                {companyDetail.attributes.employer_name}
                              </div>
                              <div className="td min-width-25">
                                {companyDetail.attributes.address}
                              </div>
                              <div className="td icons min-width-12">
                                <span className="edit-row" id={companyDetail?.id}>
                                  <img
                                    className="edit-item"
                                    src={require("../assets/edit-icon.png")}
                                    alt="Icon"
                                    onClick={()=>{this.getOneCompany(companyDetail?.id) }}
                                  />
                                </span>
                                <span className="delete-row" id={companyDetail?.id}>
                                  <img
                                    className="delete-item"
                                    src={require("../assets/delete-icon.png")}
                                    alt="Icon"
                                    onClick={()=>{this.setState({deleteOpen:true,deleteOneId:companyDetail?.id })}}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        ); 
                      }
                    )}
                  </div>
                 :
                 <div className="table-body no-results-found">
                 <p>No records to display</p>
             </div>
                  }
                  
                </section>
              </div>
            </div>
          </div>
        </div>
        <Modal className="alert-popup mt-5"
        isOpen={this.state.deleteOpen}
      >
        <div className="popup-wrap">
          <div>
              <h2 className="mb-3">Confirm Do You Want To Delete ?</h2>
              <div className="flex-between">
                <button className="btn-primary" onClick={()=>{this.setState({deleteOpen:false, deleteOneId:null })}}>Cancel</button>
                <button className="btn-orange" onClick={()=>{this.deleteCompany()}}>Confirm</button>
              </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={this.state.createOrEditOpen}
        onRequestClose={()=>{this.setState({createOrEditOpen:false,imageUrl:null , selectedFile:'' ,sizeAndImage:false})}}
      >
        <div className="popup-box">
          <div className="header flex-between-center">
            <h2 className="flex-center"><img className="mr-1 link" src={require("../assets/left-arrow.svg")} onClick={()=>{this.setState({createOrEditOpen:false ,imageUrl:null,selectedFile:'',sizeAndImage:false})}} alt=""/>Create Company</h2>
            <div className="close">
              <img className="" src={require("../assets/close-icon.svg")} onClick={()=>{this.setState({createOrEditOpen:false ,imageUrl:null,selectedFile:'' ,sizeAndImage:false})}}  alt=""/>
            </div>
          </div>
          <div className="popup-body">
            { this.state.imageUrl===null ? (
                <div className="input-file mb-2">
                <input type="file" name="" onChange={(evt)=>{this.selectedFileHandler(evt)}} />
                <img className="upload-file" src={require("../assets/input-file.svg")} alt=""/>
                <p className="font-16">Click here to browse or drag and drop a file</p>
                <button className="btn-primary" >Browse</button>
              {this.state.sizeAndImage ?<p><span className="error">File is not image format or file-size is more than 10 MB.</span></p> :<p>File must be an image format. The maximum file-size is 10 MB.</p> }  
              </div>
               ) : ( 
                <div className="uploaded-file flex-center mb-2">
                <img className="upload-file" src={this.state.imageUrl} alt=""/>
                <p className="ml-2">{this.state.fileName}</p>
                <div className="close ml-2" >
                <img className="w-60" src={require("../assets/close.png")} onClick={()=>{this.setState({imageUrl:null , selectedFile:'',sizeAndImage:false})}} alt=""/>
              </div>
              </div>
               )
            }

            <Formik
              initialValues={
                this.state.editOpen ?
                { company:this.state.getOneCompanyData.attributes?.name,
                employer:this.state.getOneCompanyData.attributes?.employer_name,
                email:this.state.getOneCompanyData.attributes?.email,
                contact:this.state.getOneCompanyData.attributes?.full_contact_number,
                country:this.state.getOneCompanyData.attributes?.country || " ",
                state:this.state.getOneCompanyData.attributes?.state|| " ",
                city_district:this.state.getOneCompanyData.attributes?.city|| " ",
                updateId :this.state.getOneCompanyData?.id } :
                {
                company: '',
                employer: '',
                email:'',
                contact:'',
                country:'',
                state:'',
                city_district:'',
                countryCode:''

              }}
              validationSchema={this.createInformationFormValidations}
              onSubmit={(values) => {
                console.log(values)
                this.state.editOpen ?this.upateCompanyId(values) :
                this.createNewCompany(values);
              }}
            >
              {({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
              })=>(
                 //@ts-ignore
                <Form>
                <div className={errors.company &&  touched.company ?"input-box error" : "input-box" }>
              <Field className="input" type="text" name="company" id="company"  value={values.company}  required />
              <label htmlFor="company">Company Name</label>
              <span className="error" >
                <ErrorMessage name="company"/>
              </span>
            </div>
            <div className="flex-between">
              <div className= {errors.employer &&  touched.employer ?"input-box w-50 pr-1 error" : "input-box w-50 pr-1" }>
                <Field className="input" type="text" id="employer" name="employer"  value={values.employer} required/>
                <label htmlFor="employer">Employer Name</label>
                <span className="error">
                <ErrorMessage name="employer"/>
              </span>
              </div>
              <div className= {errors.email &&  touched.email ?"input-box w-50 ml-1 error" : "input-box w-50 ml-1" }>
                <Field className="input" type="text" id="email" name="email"  value={values.email}  required/>
                <label htmlFor="email">Email Address</label>
                <span className="error">
                <ErrorMessage name="email"/>
              </span>
              </div>
              </div>
              <div className= {errors.contact &&  touched.contact ?"input-box error" : "input-box" }>
              {map(
                      allCountryList,
                      (country: any) => {
                        if(country.name === values.country && values.country !== ""){
                          return (
                            < div className="contry-code" key ={country.isoCode}>
                            <ReactCountryFlag
                            countryCode={country.isoCode}
                            svg
                            style={{
                              width: '2em',
                              height: '2em',
                          }}
                            />
                            <span>+{country.phonecode}</span>
                            </div>
                            )
                        }   
                      }
                    )}
                    <div className="display-icon-for-flag">
                    </div>
                    <div className="for-vertical-line"></div>
                <Field className="input tel" type="number" id="contact" name="contact"  value={values.contact}  required/>
                <label htmlFor="contact" className="tel">Contact number</label>
                <span className="error">
                <ErrorMessage name="contact"/>
              </span>
              </div>
            <h2 className="mt-1">Address and Location</h2>
            <div className="flex-between">
            <div className= {errors.country && touched.country ?"input-box w-50 pr-1 error" : "input-box w-50 pr-1" }>
            <select name="country" id="country" className="select-option" onChange={handleChange} onBlur={handleBlur} value={values.country}  required>
                <option value="">Country/Region</option>
                {map(
                      allCountryList,
                      (country: any, index: any) => {
                        
                        return (
                          <option value={country.name} key = {country.isoCode}>{country.name}</option>
                          )
                      }
                    )}
            </select>
            <span className="error">
                <ErrorMessage name="country"/>
              </span>
            </div>
            
            <div className={errors.state && touched.state ?"input-box w-50 pl-1 error" : "input-box w-50 pl-1" }>
            <select name="state" id="state" className="select-option" onChange={handleChange} onBlur={handleBlur} value={values.state} required>
                <option value="">State</option>
                { values.country && map(
                      states[countries[values.country]],
                      (state: any, index: any) => {
                        return (
                          <option value={state.name} key = {state.isoCode}>{state.name}</option>
                          )
                      }
                    )}
            </select>
            <span className="error">
                <ErrorMessage name="state"/>
              </span>
            </div>
            </div>
            <div className={errors.city_district && touched.city_district ?"input-box w-50 pr-1 error" : "input-box w-50 pr-1" }>
            <select name="city_district" id="city_district" className="select-option"  onChange={handleChange} onBlur={handleBlur} value={values.city_district}required>
                <option value=""> City/District</option>
                { values.state && map(
                      cities[stateNames[values.state]],
                      (city: any, index: any) => {
                        return (
                          <option value={city.name} key = {city.name}>{city.name}</option>
                          )
                      }
                    )}
             </select>
             <span className="error">
                <ErrorMessage name="city_district"/>
              </span>
            </div>
            <div className="flex-between mt-3">
            <button className="btn-primary" onClick={()=>{this.setState({createOrEditOpen:false ,imageUrl:null , selectedFile:'',sizeAndImage:false})}}>Cancel</button>
           {this.state.editOpen ?  <button type="submit" className="btn-orange">Update</button>:  <button type="submit" className="btn-orange">Create</button>}
            </div>
         </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
     
      </>
    );
  }
}

