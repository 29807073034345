import React, { Fragment, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  CheckBox
} from "react-native";
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { BsPencil } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import Loader from "../../../components/src/Loader";
import './react-file-drop.css';
import './style.css';
import { FileDrop } from 'react-file-drop';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select'
//@ts-ignore
import classnames from "classnames";
import { Formik } from 'formik';
import moment from "moment";
//@ts-ignore
import Sticky from 'react-stickynode';
//@ts-ignore
import _ from "lodash";
//@ts-ignore
import SuspenseImage from "react-graceful-image";

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { HiOutlineLocationMarker, HiOutlineTranslate } from "react-icons/hi";
import { GoPencil } from "react-icons/go";
import { BsArrowRight } from "react-icons/bs";
import { FiCamera } from "react-icons/fi";
import { FaTimes, FaTimesCircle, FaPlus } from "react-icons/fa";
import { RiUploadCloudLine } from "react-icons/ri";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

export default class UserProfileBasicEditBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

    this.hideSkillBox = this.hideSkillBox.bind(this);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    //fetch languages
    this.setState({
      loading: true,
      languagesLoading: true,
      projectsLoading: true,
      educationsLoading: true,
      itSkillsLoading: true,
      employmentHistoryLoading: true
    }, () => {
      this.fetchProfile();
      this.fetchLanguages();
      this.fetchProjects();
      this.fetchEducations();
      this.fetchItSkills();
      this.getEmploymentHistory();
    });

    //this.getValidations();
    //this.requestSessionData();
    //this.setState({ loading: true }, () => {

    //this.fetchProfile();
    //});


    window.document.addEventListener("click", this.hideSkillBox);

  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.document.removeEventListener("click", this.hideSkillBox);
  }


  hideSkillBox(e: any) {
    if (e.target.id !== "keyskillslistbox") {
      this.setState({ showProfileSkillList: false });
    }
    if (e.target.id !== "projectskillslistbox") {
      this.setState({ showProfileSkillList: false });
    }
  }


  // Customizable Area End



  render() {
    const { navigation } = this.props;

    const styles = getStyles();
    const assets = require("./assets");
    const currentHash = window.location.hash;

    const editableLanguageItem = this.state.languagesData.find((item, index) => item.edit === true);
    const editableItSkillItem = this.state.itSkillsData.find((item, index) => item.edit === true);


    const { first_name, last_name, email, phone_number, dob,
      marital_status, gender, current_location, employment_type, employed, category, physically_challenged } = this.state.profileData?.attributes ?? {};

    const initialProfileValues: any = {
      first_name: first_name ?? "",
      last_name: last_name ?? "",
      email: email ?? "",
      phone_number: phone_number ?? "",
      dob: dob ? moment(dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
      marital_status: marital_status ?? "Married",
      gender: gender ?? "",
      current_location: current_location ?? "",
      employment_type: (employment_type === "0" && "full_time") ||
        (employment_type === "1" && "part_time") ||
        (employment_type === "2" && "freelancer") ||
        (employment_type === "3" && "remote"),
      employed: employed ? "Employed" : "Unemployed",
      category: category ?? "",
      physically_challenged: physically_challenged ? "Yes" : "No"
    };

    const {
      role,
      industry,
      current_designation,
      preferred_location,
      total_experience,
      annual_salary,
      functional_area,
      notice_period,
      highest_degree,
      skill_list
    } = this.state.profileData?.attributes ?? {};
    const initialCandidateValues: any = {
      role: role ?? "",
      industry: industry ?? "",
      current_designation: current_designation ?? "",
      preferred_location: preferred_location ?? "",
      total_experience: total_experience ?? "",
      annual_salary: annual_salary ?? "",
      functional_area: functional_area ?? "",
      notice_period: notice_period ?? "",
      highest_degree: highest_degree ?? "",
      skill_list: skill_list ?? []
    }


    const profileFileInputRef: any = React.createRef();

    const onReplaceClick = () => {
      profileFileInputRef.current.click()
    }

    const onProfileFileInputChange = (event: any) => {
      const { files } = event.target;
      onAddProfileFiles(files);
    }
    const onAddProfileFiles = (newFiles: Array<File>) => {

      if (newFiles.length > 0) {

        console.log("Filesext ", newFiles[0]);
        if (!newFiles[0].type.includes("image/")) {
          this.showAlert(
            "",
            "Only Images are allowed",
            "OK"
          );
          return;
        }
        if (newFiles[0].size > 2097152) {
          this.showAlert(
            "",
            "Max file size allowed is 2mb",
            "OK"
          );
          return;
        }
        var profileImageFile = newFiles[0];
        this.setState({ loading: true }, () => {
          this.updateProfilePhoto(profileImageFile);
        });
      }
    }



    const resumeFileInputRef: any = React.createRef();
    const onBrowseClickResume = () => {
      resumeFileInputRef.current.click()
    }
    const onDropResume = (files: any) => {
      onAddFilesResume(files);
    }
    const onResumeFileInputChange = (event: any) => {
      const { files } = event.target;
      onAddFilesResume(files);
    }
    const onAddFilesResume = (newFiles: Array<File>) => {

      if (newFiles.length > 0) {


        console.log("Filesext ", newFiles[0]);
        if (!["doc", "docx", "rtf", "pdf"].includes(newFiles[0].name.split(".").pop() || "")) {
          this.showAlert(
            "",
            "Only doc, docx, rtf, pdf are allowed",
            "OK"
          );
          return;
        }


        if (newFiles[0].size > 2097152) {
          this.showAlert(
            "",
            "Max file size allowed is 2mb",
            "OK"
          );
          return;
        }

        var resumeDocFile = newFiles[0];
        this.setState({ loading: true }, () => {
          this.updateResumeFile(resumeDocFile);
        });
      }
    }

    var resumeFileName = "";
    if (typeof this.state.profileData?.attributes?.resume === "string") {
      if (this.state.profileData?.attributes?.resume.lastIndexOf("/") && this.state.profileData?.attributes?.resume.lastIndexOf("/") + 1 < this.state.profileData?.attributes?.resume.length) {
        resumeFileName = this.state.profileData?.attributes?.resume.substring(this.state.profileData?.attributes?.resume.lastIndexOf("/") + 1);
      }
    }

    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading || this.state.languagesLoading || this.state.projectsLoading || this.state.educationsLoading || this.state.itSkillsLoading || this.state.employmentHistoryLoading} />
        <View style={styles.leftColumn}>

          <div style={{ height: "100%" }} id="sticky-containerr" >
            <Sticky bottomBoundary='#sticky-container' top={130} enabled={false} >
              {(props: any) => (
                <div style={props.style}>
                  <div>
                    <View style={{ backgroundColor: "#ffffff", width: "100%", borderRadius: 5 }}>

                      <TouchableOpacity style={[styles.leftMenuItemWrapper, (currentHash === "#info" || currentHash === "") && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#info")}>
                        <Text style={[styles.leftMenuItem, (currentHash === "#info" || currentHash === "") && styles.leftMenuItemActive]}>Personal Info</Text>
                      </TouchableOpacity >


                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#details" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#details")}>
                        <Text style={[styles.leftMenuItem, currentHash === "#details" && styles.leftMenuItemActive]}>Candidate Details</Text>
                      </TouchableOpacity >


                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#experience" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#experience")}>
                        <Text style={[styles.leftMenuItem, currentHash === "#experience" && styles.leftMenuItemActive]}>Work</Text>
                      </TouchableOpacity >


                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#education" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#education")}>
                        <Text style={[styles.leftMenuItem, currentHash === "#education" && styles.leftMenuItemActive]}>Education</Text>
                      </TouchableOpacity >

                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#itskills" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#itskills")}>
                        <Text style={[styles.leftMenuItem, currentHash === "#itskills" && styles.leftMenuItemActive]}>IT Skills</Text>
                      </TouchableOpacity >

                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#project" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#project")}>
                        <Text style={[styles.leftMenuItem, currentHash === "#project" && styles.leftMenuItemActive]}>Project</Text>
                      </TouchableOpacity >

                      <TouchableOpacity style={[styles.leftMenuItemWrapper, currentHash === "#language" && styles.leftMenuItemWrapperActive]} onPress={() => window.location.replace("#language")} >
                        <Text style={[styles.leftMenuItem, currentHash === "#language" && styles.leftMenuItemActive]}>Language</Text>
                      </TouchableOpacity>



                    </View>

                    <Image source={assets.profileEditLeftImage} resizeMode={"contain"} style={styles.profileEditLeftImage} />
                  </div>
                </div>
              )}
            </Sticky>
          </div>

        </View>

        <View style={{ height: 1, width: "2%" }}>

        </View>


        <View style={styles.mainColumn}>
          {this.state.profileData &&
            <View style={[styles.card, { paddingHorizontal: "3%" }]} nativeID={"info"}>

              <Formik
                initialValues={initialProfileValues}
                onSubmit={(values) => {

                  if (!this.state.profileData?.attributes?.avatar) {

                    this.showAlert(
                      "",
                      "Profile image is required.",
                      "OK"
                    );
                    return;
                  }



                  if (moment().diff(values.dob, 'years') < 18) {
                    this.showAlert("", "You should be atleast 18 years old", "OK");
                    return;
                  }

                  this.setState({ loading: true }, () => this.updateProfile({
                    ...values,
                    employed: values.employed === "Employed" ? true : false,
                    physically_challenged: values.physically_challenged === "Yes" ? true : false
                  }));
                }}
                validationSchema={this.getPersonalInfoFormValidations}
              >
                {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {



                  return (
                    <Fragment>


                      <Text style={styles.blockTitle}>Personal Info</Text>
                      <Text style={[styles.silverText, { marginTop: 5 }]}>Please fill your general information & add your profile photo.</Text>

                      <View style={[{ flexDirection: "row", paddingHorizontal: 0, paddingVertical: 20 }]} >
                        {typeof "" === "string" &&
                          <View style={{ flex: 0.3, justifyContent: "flex-start", alignItems: "center" }}>
                          {/*<Image source={typeof this.state.profileData?.attributes?.avatar === "string" ? { uri: this.state.profileData?.attributes.avatar } : assets.userProfileImage} onError={() => { this.forceUpdate(); }} resizeMode={"contain"} style={{ height: "100%", maxHeight: 111, width: "100%" }} />*/}
                          <SuspenseImage
                            src={typeof this.state.profileData?.attributes?.avatar === "string" ? this.state.profileData?.attributes.avatar : assets.userProfileImage}
                            alt='I'
                            resizeMode={"cover"}
                            retry={{ count: 4, delay: 2, accumulate: 'multiply' }}
                            style={{ height: "100%", maxHeight: 111, width: "100%" }}
                          />
                            <TouchableOpacity onPress={() => { onReplaceClick(); }} ><Text style={{ color: "#e45d33", display: "flex", width: 80, justifyContent: "space-between" }}><RiUploadCloudLine style={{ fontSize: 18 }} />REPLACE</Text></TouchableOpacity>
                            <input
                              onChange={onProfileFileInputChange}
                              ref={profileFileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              multiple={false}
                              value=""
                            />
                          </View>
                        }
                        <View style={{ height: "100%", width: 1, backgroundColor: "silver", marginHorizontal: 15 }} />

                        <View style={{ flexDirection: "column", flex: 1 }}>
                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <TextField name="first_name" onChange={handleChange} onBlur={() => setFieldTouched('first_name')} variant={"outlined"} label={"First Name"} error={!!(errors.first_name && touched.first_name)} helperText={touched.first_name && errors.first_name} style={styles.plainTextInput} value={values.first_name} />

                            </View>

                            <View style={styles.inputWrapper} >
                              <TextField name="last_name" onChange={handleChange} onBlur={() => setFieldTouched('last_name')} variant={"outlined"} label={"Last Name"} error={!!(errors.last_name && touched.last_name)} helperText={touched.last_name && errors.last_name} style={styles.plainTextInput} value={values.last_name} />

                            </View>
                          </View>


                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <Tooltip title={values.email} arrow>
                                <TextField name="email" onChange={handleChange} onBlur={() => setFieldTouched('email')} variant={"outlined"} label={"Email Address"} error={!!(errors.email && touched.email)} helperText={touched.email && errors.email} style={styles.plainTextInput} value={values.email} />
                              </Tooltip>
                            </View>

                            <View style={styles.inputWrapper} >
                              <TextField name="phone_number" onChange={handleChange} onBlur={() => setFieldTouched('phone_number')} variant={"outlined"} label={"Contact No."} error={!!(errors.phone_number && touched.phone_number)} helperText={touched.phone_number && errors.phone_number} style={styles.plainTextInput} value={values.phone_number} />
                            </View>
                          </View>


                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <TextField name="dob" type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('dob')} variant={"outlined"} label={"DOB"} error={!!(errors.dob && touched.dob)} helperText={touched.dob && errors.dob} style={styles.plainTextInput} value={values.dob} />
                            </View>
                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Marital Status"}
                                onChange={(option: any) => setFieldValue("marital_status", option.value)}
                                className={classnames("", { "select-error-input": touched.marital_status && errors.marital_status })}
                                value={values.marital_status ? { label: values.marital_status, value: values.marital_status } : undefined}
                                options={[
                                  { label: "Married", value: "Married" },
                                  { label: "Unmarried", value: "Unmarried" }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.marital_status && errors.marital_status &&
                                  <Text style={styles.textInputErrorMessage}>{errors.marital_status}</Text>
                                }
                              </View>
                            </View>
                          </View>


                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Gender"}
                                onChange={(option: any) => setFieldValue("gender", option.value)}
                                className={classnames("", { "select-error-input": touched.gender && errors.gender })}
                                value={values.gender ? { value: values.gender, label: values.gender } : undefined}
                                options={[
                                  { value: "Male", label: "Male" },
                                  { value: "Female", label: "Female" }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.gender && errors.gender &&
                                  <Text style={styles.textInputErrorMessage}>{errors.gender}</Text>
                                }
                              </View>
                            </View>

                            <View style={styles.inputWrapper} >
                              <TextField name="current_location" onChange={handleChange} onBlur={() => setFieldTouched('current_location')} variant={"outlined"} label={"Current Location"} error={!!(errors.current_location && touched.current_location)} helperText={touched.current_location && errors.current_location} style={styles.plainTextInput} value={values.current_location} />

                            </View>
                          </View>


                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Job Type"}
                                onChange={(option: any) => setFieldValue("employment_type", option.value)}
                                className={classnames("", { "select-error-input": touched.employment_type && errors.employment_type })}
                                value={values.employment_type ? { value: values.employment_type, label: _.capitalize(_.replace((values.employment_type ?? ""), /_/g, " ")) } : undefined}
                                options={[
                                  { value: 'full_time', label: 'Full Time' },
                                  { value: 'part_time', label: 'Part Time' },
                                  { value: 'freelancer', label: 'Freelancer' },
                                  { value: 'remote', label: 'Remote' }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.employment_type && errors.employment_type &&
                                  <Text style={styles.textInputErrorMessage}>{errors.employment_type}</Text>
                                }
                              </View>
                            </View>

                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Employment Status"}
                                onChange={(option: any) => setFieldValue("employed", option.value)}
                                className={classnames("", { "select-error-input": touched.employed && errors.employed })}
                                value={values.employed ? { label: values.employed, value: values.employed } : undefined}
                                options={[
                                  { label: "Employed", value: "Employed" },
                                  { label: "Unemployed", value: "Unemployed" }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.employed && errors.employed &&
                                  <Text style={styles.textInputErrorMessage}>{errors.country}</Text>
                                }
                              </View>
                            </View>
                          </View>

                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Category"}
                                onChange={(option: any) => setFieldValue("category", option.value)}
                                className={classnames("", { "select-error-input": touched.category && errors.category })}
                                value={values.category ? { label: _.capitalize(_.replace((values.category ?? ""), /_/g, " ")), value: values.category } : undefined}
                                options={[
                                  { label: "Professional", value: "professional" },
                                  { label: "Intermediate", value: "intermediate" },
                                  { label: "Fresher", value: "fresher" }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.category && errors.category &&
                                  <Text style={styles.textInputErrorMessage}>{errors.category}</Text>
                                }
                              </View>
                            </View>

                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder={"Physically Challenged"}
                                onChange={(option: any) => setFieldValue("physically_challenged", option.value)}
                                className={classnames("", { "select-error-input": touched.physically_challenged && errors.physically_challenged })}
                                value={values.physically_challenged ? { label: values.physically_challenged, value: values.physically_challenged } : undefined}
                                options={[
                                  { label: "Yes", value: "Yes" },
                                  { label: "No", value: "No" }
                                ]}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.physically_challenged && errors.physically_challenged &&
                                  <Text style={styles.textInputErrorMessage}>{errors.physically_challenged}</Text>
                                }
                              </View>
                            </View>
                          </View>

                        </View>

                      </View>


                      <View style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end", marginVertical: 20 }}>
                        <TouchableOpacity onPress={() => { handleSubmit() }} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                        <TouchableOpacity onPress={() => null /* this.onCancelEmploymentHistory(index)*/} style={[styles.smallButton, { display: "none" }]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                      </View>

                    </Fragment>
                  )
                }}
              </Formik>

              <View>

                <Text style={styles.blockTitle}>Attach Resume</Text>
                <Text style={[styles.silverText, { marginTop: 5 }]}>Lorem Ipsum demo content here.</Text>

                {typeof this.state.profileData?.attributes?.resume !== "string" ?
                  <View style={{ height: 180, width: "100%", marginBottom: 10, marginTop: 18 }}>
                    <FileDrop onDrop={onDropResume}>

                      <div>
                        <div style={{ color: "rgba(0, 0, 0, 0.87)", marginTop: 10, fontSize: 16, fontWeight: "bold" }}>
                          Drag & drop resume here<br /><br />
                          <TouchableOpacity onPress={onBrowseClickResume} style={[styles.button, { marginHorizontal: "auto", backgroundColor: "#e45d33", borderColor: "#e45d33" }]} ><Text style={[styles.buttonText, { color: "#ffffff" }]}>UPLOAD RESUME</Text></TouchableOpacity>
                        </div>
                      </div>
                      <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 14, marginBottom: 16 }}>
                        Supported Formats: doc, docx, rtf, pdf, upto 2 MB.
                      </div>
                    </FileDrop>
                    <input
                      onChange={onResumeFileInputChange}
                      ref={resumeFileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      multiple={false}
                      value=""
                    />
                  </View>
                  :
                  <View style={[styles.formRow, { marginTop: 15, marginBottom: 15, padding: 15, borderWidth: 1, borderRadius: 5, borderColor: "#e3e3e3" }]}>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={{ fontWeight: "500" }}>{typeof resumeFileName === "string" ? resumeFileName : ""}</Text>
                      </View>
                      <TouchableOpacity onPress={() => this.setState({ loading: true }, () => this.deleteResumeFile())}>
                        <FaTimes style={styles.historyContainerIcons} />
                      </TouchableOpacity>
                    </View>
                  </View>
                }
              </View>
            </View>
          }


          {this.state.profileData &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"details"}>
              <Formik
                initialValues={initialCandidateValues}
                onSubmit={(values) => { this.setState({ loading: true }, () => this.updateProfile({ ...values, skill_list: values.skill_list.join(",") })); }}
                validationSchema={this.getCandidateDetailFormValidations}
              >
                {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {

                  return (
                    <Fragment>
                      <Text style={styles.blockTitle}>Candidate Details</Text>
                      <Text style={[styles.silverText, { marginTop: 5 }]}>Please fill your general information & add your profile photo.</Text>



                      <View style={[styles.formRow, { marginTop: 20 }]}>
                        <View style={styles.inputWrapper} >
                          <TextField name="role" onChange={handleChange} onBlur={() => setFieldTouched('role')} variant={"outlined"} label={"Role"} error={!!(errors.role && touched.role)} helperText={touched.role && errors.role} style={styles.plainTextInput} value={values.role} />

                        </View>

                        <View style={styles.inputWrapper} >
                          <TextField name="industry" onChange={handleChange} onBlur={() => setFieldTouched('industry')} variant={"outlined"} label={"Industry"} error={!!(errors.industry && touched.industry)} helperText={touched.industry && errors.industry} style={styles.plainTextInput} value={values.industry} />

                        </View>
                      </View>

                      <View style={[styles.formRow]}>
                        <View style={styles.inputWrapper} >
                          <TextField name="current_designation" onChange={handleChange} onBlur={() => setFieldTouched('current_designation')} variant={"outlined"} label={"Current Designation"} error={!!(errors.current_designation && touched.current_designation)} helperText={touched.current_designation && errors.current_designation} style={styles.plainTextInput} value={values.current_designation} />

                        </View>

                        <View style={styles.inputWrapper} >
                          <TextField name="preferred_location" onChange={handleChange} onBlur={() => setFieldTouched('preferred_location')} variant={"outlined"} label={"Preferred Location"} error={!!(errors.preferred_location && touched.preferred_location)} helperText={touched.preferred_location && errors.preferred_location} style={styles.plainTextInput} value={values.preferred_location} />

                        </View>
                      </View>

                      <View style={[styles.formRow]}>
                        <View style={styles.inputWrapper} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"Total Experience"}
                            onChange={(option: any) => setFieldValue("total_experience", option.value)}
                            className={classnames("", { "select-error-input": touched.total_experience && errors.total_experience })}
                            value={values.total_experience ? { value: values.total_experience, label: values.total_experience } : undefined}
                            options={[
                              { value: "Less Than 3 Months", label: "Less Than 3 Months" },
                              { value: "3+ Months", label: "3+ Months" },
                              { value: "6+ Months", label: "6+ Months" },
                              { value: "1+ Year", label: "1+ Year" },
                              { value: "2+ Year", label: "2+ Year" },
                              { value: "3+ Year", label: "3+ Year" },
                              { value: "4+ Year", label: "4+ Year" },
                              { value: "5+ Year", label: "5+ Year" },
                              { value: "6+ Year", label: "6+ Year" },
                              { value: "7+ Year", label: "7+ Year" },
                              { value: "8+ Year", label: "8+ Year" },
                              { value: "9+ Year", label: "9+ Year" },
                              { value: "10+ Year", label: "10+ Year" }
                            ]}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.total_experience && errors.total_experience &&
                              <Text style={styles.textInputErrorMessage}>{errors.total_experience}</Text>
                            }
                          </View>
                        </View>

                        <View style={styles.inputWrapper} >
                          <TextField name="annual_salary" onChange={handleChange} onBlur={() => setFieldTouched('annual_salary')} variant={"outlined"} label={"Annual Salary (in lacs)"} error={!!(errors.annual_salary && touched.annual_salary)} helperText={touched.annual_salary && errors.annual_salary} style={styles.plainTextInput} value={values.annual_salary} />

                        </View>
                      </View>

                      <View style={[styles.formRow]}>
                        <View style={styles.inputWrapper} >
                          <TextField name="functional_area" onChange={handleChange} onBlur={() => setFieldTouched('functional_area')} variant={"outlined"} label={"Functional Area"} error={!!(errors.functional_area && touched.functional_area)} helperText={touched.functional_area && errors.functional_area} style={styles.plainTextInput} value={values.functional_area} />

                        </View>

                        <View style={styles.inputWrapper} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"Notice Period"}
                            onChange={(option: any) => setFieldValue("notice_period", option.value)}
                            className={classnames("", { "select-error-input": touched.notice_period && errors.notice_period })}
                            value={values.notice_period ? { value: values.notice_period, label: values.notice_period } : undefined}
                            options={[
                              { value: "1 Month", label: "1 Month" },
                              { value: "2 Months", label: "2 Months" },
                              { value: "3 Months", label: "3 Months" }
                            ]}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.notice_period && errors.notice_period &&
                              <Text style={styles.textInputErrorMessage}>{errors.notice_period}</Text>
                            }
                          </View>
                        </View>
                      </View>

                      <View style={[styles.formRow]}>
                        <View style={styles.inputWrapper} >
                          <TextField name="highest_degree" onChange={handleChange} onBlur={() => setFieldTouched('highest_degree')} variant={"outlined"} label={"Highest Degree"} error={!!(errors.highest_degree && touched.highest_degree)} helperText={touched.highest_degree && errors.highest_degree} style={styles.plainTextInput} value={values.highest_degree} />

                        </View>

                        <View style={styles.inputWrapper} >
                        </View>
                      </View>

                      <View style={[styles.formRow, { zIndex: 1 }]}>
                        <View style={[styles.inputWrapper, { width: "100%", marginBottom: 18, justifyContent: "center" }]} >
                          <TextField name="skill_list"
                            value={this.state.profileSkillInput}

                            onChange={(e: any) => {
                              if (e.target.value != "") {
                                this.getProfileSkillTags(e.target.value);
                              }
                              this.setState({ showProfileSkillList: true, profileSkillInput: e.target.value });
                            }}

                            onKeyPress={(e: any) => {
                              if (e.which == 13 && e.target.value != "" && !values.skill_list.includes(e.target.value)) {
                                setFieldValue('skill_list', [...values.skill_list, e.target.value]);

                                this.setState({ showProfileSkillList: false, profileSkillInput: "" });
                              }
                            }}

                            onBlur={(e: any) => { setFieldTouched('skill_list'); }} variant={"outlined"} label={"Key Skills"} error={!!(errors.skill_list && touched.skill_list)} helperText={touched.skill_list && errors.skill_list} style={{ ...styles.plainTextInput, ...{ marginBottom: 0 } }} />

                          {this.state.profileSkillInput != "" && !values.skill_list.includes(this.state.profileSkillInput) &&
                            <TouchableOpacity onPress={() => {
                              if (this.state.profileSkillInput != "" && !values.skill_list.includes(this.state.profileSkillInput)) {
                                setFieldValue('skill_list', [...values.skill_list, this.state.profileSkillInput]);

                                this.setState({ showProfileSkillList: false, profileSkillInput: "" });
                              }
                            }} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", right: 15, position: "absolute" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>ADD</Text></TouchableOpacity>
                          }
                        </View>
                        {this.state.showProfileSkillList && this.state.skillList?.length > 0 &&
                          <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="keyskillslistbox">

                            {this.state.skillList.map((item, index) => (
                              <div onClick={(e: any) => {
                                if (!values.skill_list.includes(item)) {
                                  setFieldValue('skill_list', [...values.skill_list, item]);
                                  this.setState({ showProfileSkillList: false, profileSkillInput: "" });
                                }
                              }} className="skill-item">{item}</div>
                            ))}
                          </div>
                        }
                      </View>


                      {Array.isArray(values.skill_list) &&
                        <View style={styles.keySkillsBlock}>
                          <View style={styles.keySkillTagsContainer}>
                            {values.skill_list.map((item: string, index: number) => (
                              <Text style={styles.keySkillTag}>{item} <TouchableOpacity style={styles.tagTimesIcon} onPress={(e) => setFieldValue("skill_list", values.skill_list.filter((item: string, idx: number) => idx != index))}><FaTimesCircle style={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 14 }} /></TouchableOpacity></Text>
                            ))}
                          </View>
                        </View>
                      }



                      <View style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end", marginVertical: 20 }}>
                        <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                        <TouchableOpacity onPress={() => null /* this.onCancelEmploymentHistory(index)*/} style={[styles.smallButton, { display: "none" }]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                      </View>


                    </Fragment>
                  )
                }
                }
              </Formik>
            </View>
          }



          <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"experience"}>
            <Text style={styles.blockTitle}>Work Experience</Text>
            <Text style={[styles.silverText, { marginTop: 5, marginBottom: 20 }]}>Tell us about your work history.</Text>

            <View>


              {this.state.employmentHistory.map<React.ReactNode>((item: any, index: number) => (
                <View key={"form-" + index}>



                  {item.edit ?

                    <Formik
                      initialValues={{
                        "company_logo": item.attributes.company_logo || "",
                        "job_title": item.attributes.job_title,
                        "employment_type": _.lowerCase((item.attributes.employment_type ?? "_")).replace(/ /g, "_"),
                        "company": item.attributes.company,
                        "start_date": moment(item.attributes.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                        "end_date": moment(item.attributes.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                      }}
                      onSubmit={(values) => {

                        if (moment().isBefore(values.end_date) || moment().isSame(values.end_date)) {
                          this.showAlert("", "Working To can't be greater than current date", "OK");
                          return;
                        }
                        if (moment(values.start_date).isAfter(values.end_date) || moment(values.start_date).isSame(values.end_date)) {
                          this.showAlert("", "Working To date should be greater than from date", "OK");
                          return;
                        }
                        this.setState({ employmentHistoryLoading: true }, () => item.id ? this.updateEmploymentHistory(values, item.id) : this.addEmploymentHistory(values));
                      }}
                      validationSchema={this.getEmploymentHistoryFormValidations}
                    >
                      {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {


                        const fileInputRef: any = React.createRef();
                        const onBrowseClick = () => {
                          fileInputRef.current.click()
                        }
                        const onDrop = (files: any) => {
                          onAddFiles(files);
                        }
                        const onFileInputChange = (event: any) => {
                          const { files } = event.target;
                          onAddFiles(files);
                        }
                        const onAddFiles = (newFiles: Array<File>) => {

                          if (newFiles.length > 0) {

                            console.log("Filesext ", newFiles[0]);
                            if (!newFiles[0].type.includes("image/")) {
                              this.showAlert(
                                "",
                                "Only Images are allowed",
                                "OK"
                              );
                              return;
                            }
                            if (newFiles[0].size > 2097152) {
                              this.showAlert(
                                "",
                                "Max file size allowed is 2mb",
                                "OK"
                              );
                              return;
                            }

                            setFieldValue("company_logo", newFiles[0]);
                          }
                        }

                        var fileName = ""
                        if (typeof values.company_logo === "string") {
                          if (values.company_logo.lastIndexOf("/") && values.company_logo.lastIndexOf("/") + 1 < values.company_logo.length) {
                            fileName = values.company_logo.substring(values.company_logo.lastIndexOf("/") + 1);
                          }
                        }

                        return (
                          <Fragment>

                            {values.company_logo ?
                              <View style={[styles.formRow, { marginTop: 15, marginBottom: 30 }]}>
                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                                      {/*<Image source={typeof values.company_logo === "string" ? { uri: values.company_logo } : { uri: URL.createObjectURL(values.company_logo) }} resizeMode={"cover"} style={styles.company_logo} />*/}
                                      <SuspenseImage
                                      src={typeof values.company_logo === "string" ? values.company_logo : URL.createObjectURL(values.company_logo)}
                                      alt='I'
                                      resizeMode={"cover"}
                                      retry={{ count: 4, delay: 2, accumulate: 'multiply' }}
                                      style={styles.company_logo}
                                    />
                                    <Text style={{ marginLeft: 10, fontWeight: "500" }}>{typeof values.company_logo === "string" ? fileName : values.company_logo.name}</Text>
                                  </View>
                                  <TouchableOpacity onPress={() => setFieldValue("company_logo", "")}>
                                    <FaTimes style={styles.historyContainerIcons} />
                                  </TouchableOpacity>
                                </View>
                              </View>

                              :

                              <View style={[styles.formRow, { marginTop: 15 }]}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField onChange={(e) => null} value={"Upload Company Logo"} variant={"outlined"} disabled={true} label={""} error={!!(errors.company_logo && touched.company)} helperText={touched.company_logo && errors.company_logo} style={styles.uploadTextInput} className={"upload-input"} />
                                  <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                                    <TouchableOpacity onPress={() => onBrowseClick()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40, width: 120, marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]} >UPLOAD LOGO</Text></TouchableOpacity>
                                  </View>
                                </View>
                                <input
                                  onChange={onFileInputChange}
                                  ref={fileInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  multiple={false}
                                  value=""
                                />
                              </View>
                            }

                            <View style={[styles.formRow]} >
                              <View style={[styles.inputWrapper, { width: "100%" }]} >
                                <TextField name="job_title" disabled={!item.edit} onChange={handleChange} onBlur={() => setFieldTouched('job_title')} variant={"outlined"} label={"Most Recent Job Title"} error={!!(errors.job_title && touched.job_title)} helperText={touched.job_title && errors.job_title} style={styles.plainTextInput} value={values.job_title} />
                              </View>
                            </View>

                            <View style={styles.formRow}>
                              <View style={[styles.inputWrapper, { width: "100%" }]} >
                                <Select
                                  styles={selectStyles}
                                  placeholder="Employment Type"
                                  isSearchable={false}
                                  onChange={(option: any) => setFieldValue("employment_type", option.value)}
                                  defaultValue={{ value: values.employment_type, label: _.capitalize(_.replace((item.attributes.employment_type ?? "_"), /_/g, " ")) }}
                                  isDisabled={!item.edit}
                                  options={[
                                    { value: 'full_time', label: 'Full Time' },
                                    { value: 'part_time', label: 'Part Time' },
                                    { value: 'freelancer', label: 'Freelancer' },
                                    { value: 'remote', label: 'Remote' }
                                  ]}
                                  className={classnames("", { "select-error-input": touched.employment_type && errors.employment_type })}

                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.employment_type && errors.employment_type &&
                                    <Text style={[styles.textInputErrorMessage, { position: "relative", marginBottom: 20 }]}>{errors.employment_type}</Text>
                                  }
                                </View>
                              </View>
                            </View>

                            <View style={styles.formRow}>
                              <View style={[styles.inputWrapper, { width: "100%" }]} >
                                <TextField name="company" disabled={!item.edit} onChange={handleChange} onBlur={() => setFieldTouched('company')} variant={"outlined"} label={"Most Recent Company"} error={!!(errors.company && touched.company)} helperText={touched.company && errors.company} style={styles.plainTextInput} value={values.company} />
                              </View>
                            </View>

                            <View style={styles.formRow}>
                              <View style={styles.inputWrapper} >
                                <TextField name="start_date" disabled={!item.edit} type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('start_date')} variant={"outlined"} label={"Working From"} error={!!(errors.start_date && touched.start_date)} helperText={touched.start_date && errors.start_date} style={styles.plainTextInput} value={values.start_date} />
                              </View>

                              <View style={styles.inputWrapper} >
                                <TextField name="end_date" disabled={!item.edit} type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('end_date')} variant={"outlined"} label={"Working To"} error={!!(errors.end_date && touched.end_date)} helperText={touched.end_date && errors.end_date} style={styles.plainTextInput} value={values.end_date} />
                              </View>
                            </View>

                            {item.edit ?
                              <View style={{ width: "100%", flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                                <TouchableOpacity onPress={() => this.onCancelEmploymentHistory(index)} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                              </View>
                              :
                              <View style={{ width: "100%", flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => this.onEditEmploymentHistory(index)} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>EDIT</Text></TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ employmentHistoryLoading: true }, () => this.deleteEmploymentHistory(item.id))} style={[styles.smallButton]}><Text style={[styles.buttonText]}>DELETE</Text></TouchableOpacity>
                              </View>
                            }

                            <View style={{ height: 20, width: "100%" }} />
                          </Fragment>
                        )
                      }
                      }
                    </Formik>
                    :
                    <View>
                      <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }]} >
                        {typeof item.attributes?.company_logo === "string" &&
                          <View style={{ flex: 0.15, height: "100%", justifyContent: "center", alignItems: "center" }}>
                            {/*<Image source={{ uri: item.attributes?.company_logo }} resizeMode={"cover"} style={{ height: "100%", width: "100%" }} />*/}
                            <SuspenseImage
                              src={item.attributes?.company_logo || assets.userProfileImage}
                              alt='I'
                              resizeMode={"cover"}
                              retry={{ count: 4, delay: 2, accumulate: 'multiply' }}
                              style={{ height: "100%", width: "100%" }}
                            />
                            </View>
                        }
                        <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                          <View>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.job_title}</Text>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "bold" }}>{item.attributes?.company} | {item.attributes?.employment_type && (item.attributes?.employment_type)}</Text>
                          </View>
                          <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{moment(item.attributes?.start_date, 'DD-MM-YYYY').format('MMM YYYY')} to {moment(item.attributes?.end_date, 'DD-MM-YYYY').format('MMM YYYY')} {item.attributes.duration && "(" + item.attributes.duration + ")"}</Text>

                        </View>
                        <View style={{ flex: 0.15, height: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                          <TouchableOpacity onPress={() => this.onEditEmploymentHistory(index)} >
                            <BsPencil style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => this.setState({ employmentHistoryLoading: true }, () => this.deleteEmploymentHistory(item.id))}>
                            <AiOutlineDelete style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                        </View>
                      </View>

                      <View style={{ height: 20, width: "100%" }} />

                    </View>

                  }



                </View>
              ))
              }
              <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

              <View>
                <TouchableOpacity onPress={() => this.onAddMoreEmploymentHistory()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
              </View>
            </View>
          </View>





          <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"education"}>
            <Text style={styles.blockTitle}>Education Qualification</Text>
            <Text style={[styles.silverText, { marginTop: 5, marginBottom: 20 }]}>Tell us about your Education Qualification.</Text>

            <View >
              <View>


                {this.state.educationsData.map<React.ReactNode>((item: any, index: number) => (
                  <View key={"form-" + index}>
                    {item.edit ?

                      <Formik
                        initialValues={{
                          "institute": item.attributes.institute || "",
                          "degree": item.attributes.degree || "",
                        }}
                        onSubmit={(values) => { this.setState({ educationsLoading: true }, () => item.id === "" ? this.addEducation(values) : this.updateEducation(values, item.id)) }}
                        validationSchema={this.getEducationFormValidations}
                      >
                        {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {


                          return (
                            <Fragment>


                              <View style={styles.formRow}>
                                <View style={styles.inputWrapper} >
                                  <TextField name="institute" onChange={handleChange} onBlur={() => setFieldTouched('institute')} variant={"outlined"} label={"Institute"} error={!!(errors.institute && touched.institute)} helperText={touched.institute && errors.institute} style={styles.plainTextInput} value={values.institute} />
                                </View>

                                <View style={styles.inputWrapper} >
                                  <TextField name="degree" onChange={handleChange} onBlur={() => setFieldTouched('degree')} variant={"outlined"} label={"Degree"} error={!!(errors.degree && touched.degree)} helperText={touched.degree && errors.degree} style={styles.plainTextInput} value={values.degree} />
                                </View>
                              </View>

                              <View style={{ width: "100%", flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10, width: 170 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>ADD EDUCATION</Text></TouchableOpacity>
                                <TouchableOpacity onPress={() => this.onCancelEducation(index)} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                              </View>


                              <View style={{ height: 20, width: "100%" }} />
                            </Fragment>
                          )
                        }
                        }
                      </Formik>
                      :
                      <View>
                        <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }]} >

                          <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                            <View>
                              <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.institute}</Text>
                            </View>
                            <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{item.attributes?.degree}</Text>

                          </View>
                          <View style={{ flex: 0.15, height: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                            <TouchableOpacity onPress={() => this.onEditEducation(index)} >
                              <BsPencil style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.setState({ educationsLoading: true }, () => this.deleteEducation(item.id))}>
                              <AiOutlineDelete style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View style={{ height: 20, width: "100%" }} />

                      </View>

                    }
                  </View>
                ))
                }
                <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

                <View>
                  <TouchableOpacity onPress={() => this.onAddMoreEducation()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
                </View>
              </View>
            </View>
          </View>



          <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"itskills"}>
            <Text style={styles.blockTitle}>IT Skills</Text>
            <Text style={[styles.silverText, { marginTop: 5, marginBottom: 20 }]}>Specify details about programming languages (such as Java, Python, C/C++, Oracle and SQL etc)</Text>

            <View >
              <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>


                {this.state.itSkillsData.map<React.ReactNode>((item: any, index: number) => item.id !== "" && (
                  <View key={"form-" + index} style={{ width: "32%" }}>


                    <View>
                      <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }, item.edit && { borderWidth: 3, borderColor: "#e45d33" }]} >

                        <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                          <View>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.skill_name}</Text>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "bold" }}>{item.attributes?.working_duration}</Text>
                          </View>
                          <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{item.attributes?.last_used} | {item.attributes?.version}</Text>

                        </View>
                        <View style={{ flex: 0.15, height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                          <TouchableOpacity onPress={() => this.onEditItSkill(index)} >
                            <BsPencil style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => this.setState({ itSkillsLoading: true }, () => this.deleteItSkill(item.id))}>
                            <AiOutlineDelete style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                        </View>
                      </View>

                      <View style={{ height: 20, width: "100%" }} />

                    </View>


                  </View>
                ))
                }

                <View style={{ height: 1, width: "32%" }} />
                <View style={{ height: 1, width: "32%" }} />

              </View>

              <View >

                {editableItSkillItem &&
                  <Formik
                    initialValues={{
                      "skill_name": editableItSkillItem.attributes?.skill_name ?? "",
                      "version": editableItSkillItem.attributes?.version ?? "",
                      "last_used": editableItSkillItem.attributes?.last_used ?? "",
                      "working_from": moment(editableItSkillItem.attributes?.working_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                      "working_to": moment(editableItSkillItem.attributes?.working_to, 'DD-MM-YYYY').format('YYYY-MM-DD')
                    }}
                    onSubmit={(values) => {

                      if (moment().isBefore(values.working_to) || moment().isSame(values.working_to)) {
                        this.showAlert("", "Working To can't be greater than current date", "OK");
                        return;
                      }
                      if (moment(values.working_from).isAfter(values.working_to) || moment(values.working_from).isSame(values.working_to)) {
                        this.showAlert("", "Working To date should be greater than from date", "OK");
                        return;
                      }

                      this.setState({ itSkillsLoading: true }, () => { editableItSkillItem.id === "" ? this.addItSkill(values) : this.updateItSkill(values, editableItSkillItem.id) })
                    }}
                    validationSchema={this.getItSkillFormValidations}
                  >
                    {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit, setValues }) => {

                      useEffect(() => {
                        setValues(editableItSkillItem.attributes);
                        setFieldValue('working_from', moment(editableItSkillItem.attributes?.working_from, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                        setFieldValue('working_to', moment(editableItSkillItem.attributes?.working_to, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                      }, [editableItSkillItem]);


                      return (
                        <Fragment>

                          <View style={styles.formRow}>
                            <View style={[styles.inputWrapper, { width: "100%" }]} >
                              <TextField name="skill_name" onChange={handleChange} onBlur={() => setFieldTouched('skill_name')} variant={"outlined"} label={"Skill Name"} error={!!(errors.skill_name && touched.skill_name)} helperText={touched.skill_name && errors.skill_name} style={styles.plainTextInput} value={values.skill_name} />
                            </View>
                          </View>

                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <TextField name="version" onChange={handleChange} onBlur={() => setFieldTouched('version')} variant={"outlined"} label={"Version"} error={!!(errors.version && touched.version)} helperText={touched.version && errors.version} style={styles.plainTextInput} value={values.version} />
                            </View>
                            <View style={styles.inputWrapper} >
                              <TextField name="last_used" onChange={handleChange} onBlur={() => setFieldTouched('last_used')} variant={"outlined"} label={"Last Used"} error={!!(errors.last_used && touched.last_used)} helperText={touched.last_used && errors.last_used} style={styles.plainTextInput} value={values.last_used} />
                            </View>
                          </View>

                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <TextField name="working_from" type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('working_from')} variant={"outlined"} label={"Working From"} error={!!(errors.working_from && touched.working_from)} helperText={touched.working_from && errors.working_from} style={styles.plainTextInput} value={values.working_from} />
                            </View>

                            <View style={styles.inputWrapper} >
                              <TextField name="working_to" type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('working_to')} variant={"outlined"} label={"Working To"} error={!!(errors.working_to && touched.working_to)} helperText={touched.working_to && errors.working_to} style={styles.plainTextInput} value={values.working_to} />
                            </View>
                          </View>


                          <View style={{ width: "100%", flexDirection: "row" }}>
                            <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                            <TouchableOpacity onPress={() => this.onCancelItSkill()} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                          </View>

                          <View style={{ height: 20, width: "100%" }} />
                        </Fragment>
                      )
                    }
                    }
                  </Formik>
                }

                <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

                <View style={{ width: "100%" }}>
                  <TouchableOpacity onPress={() => this.onAddMoreItSkill()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
                </View>

              </View>
            </View>
          </View>



          <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"project"}>
            <Text style={styles.blockTitle}>Project</Text>
            <Text style={[styles.silverText, { marginTop: 5, marginBottom: 20 }]}>Tell us about your Education Qualification.</Text>

            <View >
              <View>


                {this.state.projectsData.map<React.ReactNode>((item: any, index: number) => (
                  <View key={"form-" + index}>
                    {item.edit ?

                      <Formik
                        initialValues={{
                          "title": item.attributes?.title ?? "",
                          "skill_list": item.attributes?.skill_list ?? [],
                          "role": item.attributes?.role ?? "",
                          "role_description": item.attributes?.role_description ?? "",
                          "project_description": item.attributes?.project_description ?? "",
                        }}
                        onSubmit={(values) => { this.setState({ projectsLoading: true }, () => item.id === "" ? this.addProject({ ...values, skill_list: values.skill_list.join(",") }) : this.updateProject({ ...values, skill_list: values.skill_list.join(",") }, item.id)) }}
                        validationSchema={this.getProjectFormValidations}
                      >
                        {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {



                          return (
                            <Fragment>

                              <View style={[styles.formRow]} >
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="title" onChange={handleChange} onBlur={() => setFieldTouched('title')} variant={"outlined"} label={"Project Title"} error={!!(errors.title && touched.title)} helperText={touched.title && errors.title} style={styles.plainTextInput} value={values.title} />
                                </View>
                              </View>

                              <View style={[styles.formRow, { zIndex: 1 }]} >
                                <View style={[styles.inputWrapper, { width: "100%", marginBottom: 18, justifyContent: "center" }]} >
                                  <TextField name="skill_list"
                                    value={this.state.projectSkillInput[index] ?? ""}

                                    onChange={(e: any) => {
                                      if (e.target.value != "") {
                                        this.getProjectSkillTags(e.target.value);
                                        this.state.projectSkillInput[index] = e.target.value;
                                      }
                                      this.setState({ showProfileSkillList: true });
                                    }}

                                    onKeyPress={(e: any) => {
                                      if (e.which == 13 && e.target.value != "" && !values.skill_list.includes(e.target.value)) {
                                        setFieldValue('skill_list', [...values.skill_list, e.target.value]);
                                        this.state.projectSkillInput[index] = "";
                                        this.setState({ showProfileSkillList: false });
                                      }
                                    }}



                                    onBlur={(e: any) => { setFieldTouched('skill_list'); }} variant={"outlined"} label={"Skill Used"} error={!!(errors.skill_list && touched.skill_list)} helperText={touched.skill_list && errors.skill_list} style={{ ...styles.plainTextInput, ...{ marginBottom: 0 } }} />
                                  {(this.state.projectSkillInput[index] ?? "") != "" && !values.skill_list.includes(this.state.projectSkillInput[index]) &&
                                    <TouchableOpacity onPress={() => {
                                      if (this.state.projectSkillInput[index] != "" && !values.skill_list.includes(this.state.projectSkillInput[index])) {
                                        setFieldValue('skill_list', [...values.skill_list, this.state.projectSkillInput[index] ?? ""]);
                                        this.state.projectSkillInput[index] = "";
                                        this.setState({ showProfileSkillList: false });
                                      }
                                    }} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", right: 15, position: "absolute" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>ADD</Text></TouchableOpacity>
                                  }

                                </View>

                                {this.state.showProfileSkillList && this.state.skillList?.length > 0 &&
                                  <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="projectskillslistbox">

                                    {this.state.skillList.map((item, index) => (
                                      <div onClick={(e: any) => {
                                        if (!values.skill_list.includes(item)) {
                                          setFieldValue('skill_list', [...values.skill_list, item]);
                                          this.state.projectSkillInput[index] = "";
                                          this.setState({ showProfileSkillList: false });
                                        }
                                      }} className="skill-item">{item}</div>
                                    ))}
                                  </div>
                                }
                              </View>

                              {Array.isArray(values.skill_list) &&
                                <View style={styles.keySkillsBlock}>
                                  <View style={styles.keySkillTagsContainer}>
                                    {values.skill_list.map((item: string, index: number) => (
                                      <Text style={styles.keySkillTag}>{item} <TouchableOpacity style={styles.tagTimesIcon} onPress={(e) => setFieldValue("skill_list", values.skill_list.filter((item: string, idx: number) => idx != index))}><FaTimesCircle style={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 14 }} /></TouchableOpacity></Text>
                                    ))}
                                  </View>
                                </View>
                              }

                              <View style={[styles.formRow, { marginTop: 20 }]} >
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="role" onChange={handleChange} onBlur={() => setFieldTouched('role')} variant={"outlined"} label={"Role"} error={!!(errors.role && touched.role)} helperText={touched.role && errors.role} style={styles.plainTextInput} value={values.role} />
                                </View>
                              </View>

                              <View style={[styles.formRow]} >
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="role_description" onChange={handleChange} onBlur={() => setFieldTouched('role_description')} variant={"outlined"} label={"Role Description"} error={!!(errors.role_description && touched.role_description)} helperText={touched.role_description && errors.role_description} style={styles.plainTextInput} value={values.role_description} />
                                </View>
                              </View>

                              <View style={[styles.formRow]} >
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="project_description" onChange={handleChange} onBlur={() => setFieldTouched('project_description')} variant={"outlined"} label={"Project Description"} error={!!(errors.project_description && touched.project_description)} helperText={touched.project_description && errors.project_description} style={styles.plainTextInput} value={values.project_description} />
                                </View>
                              </View>

                              <View style={{ width: "100%", flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>ADD PROJECT</Text></TouchableOpacity>
                                <TouchableOpacity onPress={() => this.onCancelProject(index)} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                              </View>

                              <View style={{ height: 20, width: "100%" }} />
                            </Fragment>
                          )
                        }
                        }
                      </Formik>
                      :
                      <View>
                        <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }]} >

                          <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                            <View>
                              <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.title}</Text>
                              <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "bold" }}>{item.attributes?.role} | {Array.isArray(item.attributes?.skill_list) && (item.attributes?.skill_list.join(","))}</Text>
                            </View>
                            <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{item.attributes?.project_description}</Text>

                          </View>
                          <View style={{ flex: 0.15, height: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                            <TouchableOpacity onPress={() => this.onEditProject(index)} >
                              <BsPencil style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.setState({ projectsLoading: true }, () => this.deleteProject(item.id))}>
                              <AiOutlineDelete style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View style={{ height: 20, width: "100%" }} />

                      </View>

                    }
                  </View>
                ))
                }
                <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

                <View>
                  <TouchableOpacity onPress={() => this.onAddMoreProject()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
                </View>
              </View>
            </View>
          </View>







          <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]} nativeID={"language"}>
            <Text style={styles.blockTitle}>Language</Text>
            <Text style={[styles.silverText, { marginTop: 5, marginBottom: 20 }]}>If you are not working anywhere please reply.</Text>

            <View >
              <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>


                {this.state.languagesData.map<React.ReactNode>((item: any, index: number) => item.id !== "" && (
                  <View key={"form-" + index} style={{ width: "32%" }}>


                    <View>
                      <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }, item.edit && { borderWidth: 3, borderColor: "#e45d33" }]} >
                        {typeof item.attributes?.company_logo === "string" &&
                          <View style={{ flex: 0.15, height: "100%", justifyContent: "center", alignItems: "center" }}>
                             {/*<Image source={{ uri: item.attributes?.company_logo }} resizeMode={"cover"} style={{ height: "100%", width: "100%" }} />*/}
                             <SuspenseImage
                              src={item.attributes?.company_logo || assets.userProfileImage}
                              alt='I'
                              resizeMode={"cover"}
                              retry={{ count: 4, delay: 2, accumulate: 'multiply' }}
                              style={{ height: "100%", width: "100%" }}
                            />
                            </View>
                        }
                        <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                          <View>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.name}</Text>
                            <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "bold" }}>{item.attributes?.read && "Read "}{item.attributes?.write && "Write "}{item.attributes?.speak && "Speak"}</Text>
                          </View>
                          <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{item.attributes?.proficiency}</Text>

                        </View>
                        <View style={{ flex: 0.15, height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                          <TouchableOpacity onPress={() => this.onEditLanguage(index)} >
                            <BsPencil style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => this.setState({ languagesLoading: true }, () => this.deleteLanguage(item.id))}>
                            <AiOutlineDelete style={styles.historyContainerIcons} />
                          </TouchableOpacity>
                        </View>
                      </View>

                      <View style={{ height: 20, width: "100%" }} />

                    </View>


                  </View>
                ))
                }

                <View style={{ height: 1, width: "32%" }} />
                <View style={{ height: 1, width: "32%" }} />

              </View>

              <View >

                {editableLanguageItem &&
                  <Formik
                    initialValues={{
                      "name": editableLanguageItem.attributes?.name ?? "",
                      "proficiency": editableLanguageItem.attributes?.proficiency ? _.lowerCase(editableLanguageItem.attributes?.proficiency) : "",
                      "read": editableLanguageItem.attributes?.read ?? true,
                      "write": editableLanguageItem.attributes?.write ?? true,
                      "speak": editableLanguageItem.attributes?.speak ?? true
                    }}
                    onSubmit={(values) => {
                      if (!values.read && !values.write && !values.speak) {
                        this.showAlert("", "Please select atleast one option from read, write and speak.", "OK");
                        return;
                      }

                      this.setState({ languagesLoading: true }, () => { editableLanguageItem.id === "" ? this.addLanguage(values) : this.updateLanguage(values, editableLanguageItem.id) })
                    }}
                    validationSchema={this.getLanguageFormValidations}
                  >
                    {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit, setValues }) => {


                      useEffect(() => {
                        setValues(editableLanguageItem.attributes)
                        setFieldValue("proficiency", editableLanguageItem.attributes?.proficiency ? _.lowerCase(editableLanguageItem.attributes?.proficiency) : "");
                      }, [editableLanguageItem]);


                      return (
                        <Fragment>


                          <View style={styles.formRow}>
                            <View style={styles.inputWrapper} >
                              <TextField name="name" onChange={handleChange} onBlur={() => setFieldTouched('name')} variant={"outlined"} label={"Language"} error={!!(errors.name && touched.name)} helperText={touched.name && errors.name} style={styles.plainTextInput} value={values.name} />
                            </View>

                            <View style={styles.inputWrapper} >
                              <Select
                                styles={selectStyles}
                                isSearchable={true}
                                placeholder="Proficiency"
                                onChange={(option: any) => setFieldValue("proficiency", option.value)}
                                value={typeof values.proficiency === "string" && values.proficiency.length > 0 ? { value: values.proficiency, label: _.capitalize(values.proficiency) } : undefined}
                                options={[
                                  { value: 'beginner', label: 'Beginner' },
                                  { value: 'intermediate', label: 'Intermediate' },
                                  { value: 'advanced', label: 'Advanced' },
                                ]}
                                className={classnames("", { "select-error-input": touched.proficiency && errors.proficiency })}

                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.proficiency && errors.proficiency &&
                                  <Text style={[styles.textInputErrorMessage, { position: "relative", marginBottom: 20 }]}>{errors.proficiency}</Text>
                                }
                              </View>
                            </View>
                          </View>

                          <View style={[styles.formRow, { justifyContent: "flex-start", marginBottom: 20 }]}>
                            <Text style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", width: 55 }}><CheckBox value={values.read} onChange={() => setFieldValue("read", !values.read)} />Read</Text>
                            <Text style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", width: 55, marginLeft: 24 }}><CheckBox value={values.write} onChange={() => setFieldValue("write", !values.write)} />Write</Text>
                            <Text style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", width: 55, marginLeft: 24 }}><CheckBox value={values.speak} onChange={() => setFieldValue("speak", !values.speak)} />Speak</Text>
                          </View>

                          <View style={{ width: "100%", flexDirection: "row" }}>
                            <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                            <TouchableOpacity onPress={() => this.onCancelLanguage()} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                          </View>

                          <View style={{ height: 20, width: "100%" }} />
                        </Fragment>
                      )
                    }
                    }
                  </Formik>
                }

                <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

                <View style={{ width: "100%" }}>
                  <TouchableOpacity onPress={() => this.onAddMoreLanguage()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
                </View>



              </View>
            </View>
          </View>


        </View>
      </View >
    );




    return (
      //Required for all blocks
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        {/* Required for all blocks */}
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View>
              <Text style={styles.titleWhySignUp}>{this.labelHeader}</Text>

              <Text style={styles.title}>{this.labelFirstName}</Text>
              <TextInput
                testID={"txtInputFirstName"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.labelFirstName} //UI Engine::From Sketch
                {...this.txtInputFirstNameProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.lastName}</Text>
              <TextInput
                testID={"txtInputLastName"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.lastName} //UI Engine::From Sketch
                {...this.txtInputLastNameProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.labelArea}</Text>
              <CountryCodeSelector
                allowPropChange={true}     //Merge Engine::From BDS
                navigation={navigation}    //Merge Engine::From BDS
                id={"CountryCodeSelector"} //Merge Engine::From BDS
                placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                style={styles.bgRectBorder} //UI Engine::From Sketch
                disable={!this.state.countryCodeEnabled}  //Merge Engine::From BDS
                value={this.state.currentCountryCode} //Merge Engine::From BDS
              />

              <Text style={styles.title}>{this.labelMobile}</Text>
              <TextInput
                testID={"txtInputPhoneNumber"} //Merge Engine::From BDS
                style={styles.bgMobileInput} //UI Engine::From Sketch
                placeholder={this.labelMobile} //UI Engine::From Sketch
                {...this.txtInputPhoneNumberProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.labelEmail}</Text>
              <TextInput
                testID={"txtInputEmail"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.labelEmail} //UI Engine::From Sketch
                {...this.txtInputEmailProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              {this.state.llChangePwdDummyShowContainerVisible ? (
                <View>
                  <Text style={styles.title}>{this.labelCurrentPassword}</Text>
                  <Text style={styles.bgDummyPassword}>*********</Text>

                  <View style={{ marginTop: 24, marginBottom: 16 }}>
                    <Button
                      testID={"btnEnableEditPassword"} //Merge Engine::From BDS
                      title={this.btnTextChangePassword}  //UI Engine::From Sketch
                      color="#6200EE"                     //UI Engine::From Sketch
                      {...this.btnEnableEditPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />
                  </View>
                </View>
              ) : null}

              {this.state.llDoChangePwdContainerVisible ? (
                <View>
                  <Text style={styles.title} //UI Engine::From Sketch
                  >
                    {this.labelCurrentPassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View
                    style={styles.bgPasswordContainer} //UI Engine::From Sketch
                  >
                    <TextInput
                      testID={"txtInputCurrentPassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelCurrentPassword} //UI Engine::From Sketch
                      {...this.txtInputCurrentPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btnPasswordShowHideButton"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnPasswordShowHideButtonProps} //Merge Engine::From BDS - {...this.testIDProps}
                    >
                      <Image
                        testID={"imgPasswordShowhide"}  //Merge Engine::From BDS
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgPasswordShowhideProps} //Merge Engine::From BDS - {...this.testIDProps}
                      />
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={styles.title} //UI Engine::From Sketch
                  >{this.labelNewPassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View style={styles.bgPasswordContainer} //UI Engine::From Sketch
                  >
                    <TextInput
                      testID={"txtInputNewPassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelNewPassword} //UI Engine::From Sketch
                      {...this.txtInputNewPasswordProps}  //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btntNewPasswordShowHideButton"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnNewPasswordShowHideButtonProps}
                    >
                      <Image
                        testID={"imgNewPasswordShowhide"}  //Merge Engine::From BDS
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgNewPasswordShowhideProps} //Merge Engine::From BDS - {...this.testIDProps}

                      />
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={styles.helperText} //UI Engine::From Sketch
                  >
                    {this.state.passwordHelperText} {/*UI Engine::From Sketch*/}
                  </Text>

                  <Text
                    style={styles.title} //UI Engine::From Sketch
                  >
                    {this.labelRePassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View style={styles.bgPasswordContainer}>
                    <TextInput
                      testID={"txtInputReTypePassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelRePassword} //UI Engine::From Sketch
                      {...this.txtInputReTypePasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btnReTypePasswordShowHide"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnReTypePasswordShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}}
                    >
                      <Image
                        testID="imgReTypePasswordShowhide"
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgReTypePasswordShowhideProps}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={{ marginTop: 24, marginBottom: 0 }}>
                    <Button
                      testID={"btnDisableEditPassword"} //Merge Engine::From BDS
                      title={this.btnTextCancelPasswordChange} //UI Engine::From Sketch
                      color="#767676" //UI Engine::From Sketch
                      {...this.btnDisableEditPasswordProps}
                    />
                  </View>
                </View>
              ) : null}

              <View style={{ marginTop: 24, marginBottom: 32 }}>
                <Button
                  testID={"btnSubmit"} //Merge Engine::From BDS
                  title={this.btnTextSaveChanges} //UI Engine::From Sketch
                  color="#6200EE"
                  disabled={this.state.saveButtonDisable}
                  onPress={() => this.validateMobileAndThenUpdateUserProfile()}
                />
              </View>
            </View>
            {/* Customizable Area End */}
            {/* Merge Engine UI Engine Code */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

}
// Customizable Area Start


const PrettoSlider = withStyles({
  root: {
    color: '#299c14',
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -8,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#299c14"
  },
  rail: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "whitesmoke"
  },
})(Slider);









// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  leftColumn: {
    width: "18%"
  },
  mainColumn: {
    width: "60%"
  },
  avatarBlock: {
    borderBottomWidth: 1,
    borderBottomColor: "whitesmoke"
  },
  avatarBackground: {
    backgroundColor: "#141d3d",
    height: 120,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  profilePicture: {
    height: 100,
    width: 100,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "rgba(26, 24, 29, 0.32)",
    marginBottom: -40
  },
  profileName: {
    fontSize: 20,
    color: "#1a181d"
  },
  profileOccupation: {
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: 5
  },
  profileLocation: {
    color: "rgba(0, 0, 0, 0.6)",
    alignItems: "center",
    textAlignVertical: "center",
    marginTop: 10,
    marginBottom: 30
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  personalInfoBlock: {
    paddingHorizontal: "5%",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: "whitesmoke"
  },
  personalInfoRow: {
    flexDirection: "row",
    marginTop: 12
  }
  ,
  personalInfoLeftText: {
    color: "rgba(65, 65, 65, 0.87)",
    flex: 1,
    fontSize: 12
  },
  personalInfoRightText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 12
  }
  ,
  blockTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 18,
    fontWeight: "500"
  },
  keySkillsBlock: {
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 7,
    marginTop: 5
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 5,
    paddingHorizontal: "5%",
    paddingVertical: 18,
    scrollMargin: 130
  },
  addInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 15,
    borderTopColor: "whitesmoke",
    borderTopWidth: 1
  },
  addInfoLeftWrapper: {
    flexDirection: "row",
    alignItems: "center"
  },
  addInfoLeftIcon: {
    fontSize: 18,
    color: "#e45d33"
  },
  addInfoRightIcon: {
    fontSize: 16,
    color: "#1a181d",
    float: "right"
  },
  addInfoText: {
    marginLeft: 10,
    fontSize: 14,
    color: "#e45d33"
  },
  candidateItemContainer: {
    marginTop: 20,
    width: "33.333333%"
  },
  candidateTitleText: {
    color: "rgba(0, 0, 0, 0.38)",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
  },
  candidateDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11,
    marginTop: 5,
    letterSpacing: 0.25
  },
  projectDimText: {
    color: "rgba(0, 0, 0, 0.6)",
    flex: 1,
    fontSize: 10
  },
  projectDarkText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    marginTop: 5
  },
  projectTitleText: {
    fontSize: 14
  },
  itSkillsItemContainer: {
    width: "100%",
    flexDirection: "row"
  },
  itSkillsTitleText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
    fontWeight: "500"
  },
  itSkillsDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11
  },
  languageItemContainer: {
    width: "100%",
    flexDirection: "row"
  },
  languageTitleText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
    fontWeight: "500"
  },
  languageDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 12,
    fontWeight: "500"
  },
  leftMenuItemWrapper: {
    borderLeftColor: "#ffffff",
    borderLeftWidth: 3,
    padding: 12
  },
  leftMenuItem: {
    color: "#1a181d",
    fontWeight: "500",
    fontSize: 16
  },
  leftMenuItemWrapperActive: {
    borderLeftColor: "#e45d33"
  },
  leftMenuItemActive: {
    color: "#e45d33",
    fontWeight: "500",
    fontSize: 16
  },
  silverText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  },
  historyContainerIcons: {
    fontSize: 20,
    color: "silver"
  },
  tagTimesIcon: {
    height: "100%",
    verticalAlign: "sub",
    marginLeft: 8
  },
  smallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 120,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  company_logo: {
    height: 48,
    width: 48
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  uploadTextInput: {
    width: "100%",
    marginBottom: 18,
    color: "silver"
  },
  profileEditLeftImage: {
    width: "100%",
    height: 210,
    marginTop: 100
  }
}

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {

  }),
  // below  768
  maxWidth(768, {
    leftColumn: {
      width: "100%"
    },
    mainColumn: {
      width: "100%",
      order: 3
    },
    rightColumn: {
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
  }),
);


const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 52,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  }
}

// Customizable Area End