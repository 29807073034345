//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import Storage from "../../../../framework/src/StorageProvider.web";
import * as yup from 'yup';
import {
  jobDetailsValuesApiContentType,
  jobDetailsValuesApiMethod,
  jobDetailValuesApiEndPoint,
  downloadPdfApiContentType,
  downloadPdfApiMethod,
  downloadPdfApiEndPoint,
} from "../config";
// Customizable Area End

// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  editPostModal: any;
  openJobPreviewModal: any;
  closeEditPostModal: any;
  onChangeLayout?: (height: number) => void;
  isOpenEditPost: boolean;
  backToEdit: any;
}

interface S {
  // Customizable Area Start
  requrimentTitle: string;
  txtSavedValue: string;
  enableField: boolean;
  sidemenuHeight: any;
  loading: boolean;

  post_preferences: Array<any>;
  jobDetails: any;
  title_input: string;
  pdfVariables: any;
}

interface SS {
  id: any;
}

export default class EditJobPostController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiCallMessageUpdateJobIdDetailsRequestId: any;
  jobDetailValuesApiCallMessageRequestId: any;
  jobId: any;
  downloadPdfApiCallMessageRequestId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      requrimentTitle: '',
      txtSavedValue: "A",
      enableField: false,
      isOpenEditPost: false,
      loading: false,
      post_preferences: [],
      jobDetails: {},
      title_input: "",
      pdfVariables: getInitialPdfToggleData()
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (this.jobDetailValuesApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.jobDetailValuesApiCallMessageRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson.data?.attributes?.post_preferences)) {
          responseJson.data?.attributes?.post_preferences.sort((a: any, b: any) => a.id - b.id);
          this.setState({ post_preferences: responseJson.data?.attributes?.post_preferences, jobDetails: responseJson.data, loading: false });
          if (this.state.title_input == "") {
            this.setState({ title_input: responseJson.data?.attributes?.job_title });
          }
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ loading: false })
    }
    else if (this.apiCallMessageUpdateJobIdDetailsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateJobIdDetailsRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson.data?.attributes?.post_preferences)) {
          responseJson.data?.attributes?.post_preferences.sort((a: any, b: any) => a.id - b.id);
          this.setState({ post_preferences: responseJson.data?.attributes?.post_preferences, jobDetails: responseJson.data, loading: false });
          if (this.state.title_input == "") {
            this.setState({ title_input: responseJson.data?.attributes?.job_title });
          }
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (this.downloadPdfApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.downloadPdfApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      debugger

      if (responseJson && !responseJson.errors) {
        debugger
      }
    }

  }
  // Customizable Area Start
  txtInputRequrimentTitleWebProps = {
    onChangeText: (text: string) => {
      this.setState({ requrimentTitle: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputRequrimentTitleWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputRequrimentTitleWebProps
    : this.txtInputMobileProps;

  // btnShowHideProps = {
  //   onPress: () => {
  //     this.setState({ enableField: !this.state.enableField });
  //     this.txtInputProps.secureTextEntry = !this.state.enableField;
  //     this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
  //       ? imgPasswordVisible
  //       : imgPasswordInVisible;
  //   }
  // };

  // btnShowHideImageProps = {
  //   source: this.txtInputProps.secureTextEntry
  //     ? imgPasswordVisible
  //     : imgPasswordInVisible
  // };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.requrimentTitle
    );
    this.send(msg);
  }

  async downloadPdf() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": downloadPdfApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadPdfApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      downloadPdfApiEndPoint + "/" + this.jobId + "/export_pdf"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      downloadPdfApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async getJobDetailsValues() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": jobDetailsValuesApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobDetailValuesApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      jobDetailValuesApiEndPoint + "/" + this.jobId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      jobDetailsValuesApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateJobIdDetails(values: any, isJobDetail: boolean = false) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const body = !isJobDetail ? {
      "job_detail": {
        "post_preferences_attributes": values
      }
    } :
      {
        "job_detail": values
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateJobIdDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/job_details/${this.jobId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}


const getInitialPdfToggleData = () => {

  const data = {
    ...{
      "Job Title": "job_title",
      "Reporting To": "reporting_to",
      "Reportees/Team": "team",
      "Employment Type": "employment_type",
      "Job Details": "job_details",
      "Key Result Areas": "key_result_areas",
      "Key Perfotmance Indicators": "key_performance_indicators",
      "Desired Candidate Profile": "desired_candidate_profile",
      "Annual CTC": "annual_ctc"
    },
    ...{
      "Other Salary Details": "other_salary_details",
      "Work Expreince": "work_experience",
      "Keywords": "keywords",
      "Job Located": "job_location_details",
      "Hiring For": "hiring_for",
      "Visibility Settings": "visibility",
      "Recuriter Name": "recruiter",
      "No. of Vacancies": "vacancies",
      "Refernce Code": "reference_code"
    },
    ...{
      "Graduation": "graduation",
      "Post Graduation": "post_graduation",
      "Doctrate": "doctorate",
      "Industry": "industry"
    },
    ...{
      "Functional Area": "functional_area",
      "Have any questions for the candidate": "any_questions_for_candidate",
      "Show contact details to jobseekers": "show_contact_details_to_jobseeker"
    },
    ...{
      "Brief": "brief",
      "Role Dynamics": "role_dynamics",
      "Required candidate skills": "required_candidate_skills"
    },
    ...{
      "Target Pool": "target_pool",
      "Conclusion": "conclusion"
    }
  };


  var newData = {};

  Object.values(data).forEach((item) => {
    newData[item] = true;
  });
  return newData;
}