//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// @ts-ignore
//@ts-nocheck
import { View, Text, Image } from 'react-native';
import React, { Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from 'formik'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/intersection'
import forEach from 'lodash/forEach'
import Icon from 'react-native-vector-icons/FontAwesome';
// import Loader from "../../../../components/src/Loader";
import JobPreview from "./JobPostModal/JobPreview.web";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
import { Editor, EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
// import Storage from "../../../../framework/src/StorageProvider.web";
import { FaCheck, FaPlus, FaThList } from "react-icons/fa";
import SearchStrategyForm from './SearchStrategyForm.web';
import classnames from 'classnames';

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import SearchStrategyController, {
  Props,
} from "./SearchStrategyController.web";

const stepsValue = null;

const customStyles = {
  textHeading: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textActive: {
    color: "#fff",
    marginLeft: 55
  },
  lightstepText: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textError: {
    color: "#e45d33",
    marginLeft: 55
  }
}


class SearchStrategy extends SearchStrategyController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getFunctionalAreas()
    this.getCourseList();
    this.getPostGraduationList();
    this.getDoctorateList();
    this.getSearchStrategyDetails();
    this.getKeywordsList();
  }


  getSidebarValue = (steps: any, values: any, touched: any) => {
    this.stepsValue = Object.keys(steps)
    this.touched = touched
    this.values = values
    console.log(this.values, "Search Strategy")
  }

  getSideMenu = (currentHash: any, formikProps: FormikProps) => {
    const styles = getStyles();
    const assets = require("./assets");

    const { values, touched, errors } = formikProps;

    console.log('Errors', errors)

    const getClassName = (field: string) => {
      let className = this.props.classes.textHeading;

      if (touched.hasOwnProperty(field) && touched[field]) {
        className = errors.hasOwnProperty(field) ? this.props.classes.textError : this.props.classes.textActive;
      }

      // if (values.hasOwnProperty(field) && values[field]) {
      //   className = this.props.classes.textActive
      // }

      if (touched.hasOwnProperty(field) && !touched[field]) {
        className = this.props.classes.textError
      }

      if (field === 'basic_detail') {
        const touchedFields = Object.keys(touched);
        const errorFields = Object.keys(errors);
        const basicDetailsFields = [
          "affirmative_action_value_id",
          "annual_salary_max",
          "annual_salary_min",
          "candidate_age_from",
          "candidate_age_to",
          "comments",
          "current_employer",
          "current_location",
          "designation_all_words",
          "designation_current_employer",
          "designation_type_her",
          "employer_all_words",
          "employer_type",
          "exclude_current_employer",
          "exclude_employer_all_words",
          "exclude_employer_type",
          "experience_max",
          "experience_min",
          "functional_area_id",
          "industry_type",
          "notice_period",
          "ug_qualification_any",
          "ppg_qualification_any",
          "ppg_qualification_enforced",
          "profile_source",
          "requirements",
          "resume_per_page_relevance",
          "role",
          "sort_by_relevance",
          "star_rating_from",
          "star_rating_to",
          "status",
          "ug_qualification_enforced",
          "work_status_for_other_country",
          "work_status_for_usa"
        ]

        const basicDetailsTouchedFields = intersection(touchedFields, basicDetailsFields);
        const basicDetailsErrorFields = intersection(errorFields, basicDetailsFields);

        if (basicDetailsFields.length === basicDetailsTouchedFields.length) {
          className = basicDetailsErrorFields.length > 0 ? this.props.classes.textError : this.props.classes.textActive;
        }

        if (basicDetailsTouchedFields.length > 0 && !touched[basicDetailsTouchedFields[0]]) {
          className = this.props.classes.textError
        }
      }

      return className;

    }


    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
        </View>

        <View style={styles.stepsContainer}>

          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[values?.brief !== "" && values?.role_dynamics !== "" && values?.required_skill_ids !== "" && values?.basic_detail !== "" && values?.conclusion !== "" ? styles.stepIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepIconActive]} style={{ display: 'inline-flex' }}>
                {values?.brief !== "" && values?.role_dynamics !== "" && values?.required_skill_ids !== "" && values?.basic_detail !== "" && values?.conclusion !== "" ? <FaCheck size={10} /> : 1}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Search Strategy</Text>
          </View>

          <View style={[(currentHash === "#brief" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#brief")}>
            <Text style={[(currentHash === "#brief" && currentHash === "")]} className={getClassName('brief')}>Brief</Text>
          </View>

          <View style={[(currentHash === "#role_dynamics" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#role_dynamics")} >
            <Text style={[(currentHash === "#role_dynamics" && currentHash === "")]} className={getClassName('role_dynamics')}>Role Dynamics</Text>
          </View>

          <View style={[(currentHash === "#required_skill_ids" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#required_skill_ids")}>
            <Text style={[(currentHash === "#required_skill_ids" && currentHash === "")]} className={getClassName('required_skill_ids')}>Required candidate skills</Text>
          </View>

          <View style={[(currentHash === "#basic_detail" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#basic_detail")}>
            <Text style={[(currentHash === "#basic_detail" && currentHash === "")]} className={getClassName('basic_detail')}>Traget pool</Text>
          </View>

          <View style={[(currentHash === "#conclusion" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#conclusion")}>
            <Text style={[(currentHash === "#conclusion" && currentHash === "")]} className={getClassName('conclusion')}>Conculsion</Text>
          </View>
        </View>
      </View>
    )
  }

  getSidemenuFooter = () => {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      <View style={{ marginTop: "100%" }}>

        <Text style={styles.leftTitle}>Lorem Ipsum has been the industry's standard</Text>
        <Text style={[styles.text, { marginTop: 8, color: "rgba(255,255,255,0.3)" }]}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</Text>


        <View style={{ marginTop: 50 }}>
          <PaginationIndicator count={3} selected={1} style={{ justifyContent: "flex-start" }} />
        </View>
        {/*<Text style={[styles.text, { marginTop: 50, color: "#ffffff" }]}>© 2021 RMS. All right Reserved.</Text>*/}
      </View>
    )
  }

  onLayoutChange = (h: any) => {
    console.log('onLayoutChange >>>>> ', h)
  }

  getKeySkill = (data) => {
    if (data && data?.required_skill?.data && data?.required_skill?.data.length > 0) {
      const newList = data?.required_skill?.data.map((item) => (item.id))
      console.log("=====", newList)
      return newList
    }
    return ''
  }

  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const jobDetailId = localStorage.getItem("id")
    const currentHash = window.location.hash;
    const { searchStrategyValues, isFormLoading } = this.state

    const functionalAreaList = this.state.functionalAreaList.map((item) => {
      return ({
        value: item.id,
        label: item.attributes.name
      })
    });

    const courses = this.state.courses.map((item) => {
      return ({
        value: item.id,
        label: item.attributes.name
      })
    });

    const postGraduationList = this.state.postGraduationList.map((item) => ({
      value: item.id,
      label: item.attributes.name
    }));

    const doctorateList = this.state.doctorateList.map((item) => ({
      value: item.id,
      label: item.attributes.name
    }));

    const keywordsList = this.state.keywordList.map((item) => ({
      value: item.id,
      label: item.attributes.name
    }))

    const emptyData = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));

    const initialValues = {
      job_detail_id: jobDetailId,
      brief: searchStrategyValues?.brief || emptyData,
      role_dynamics: searchStrategyValues?.role_dynamics || emptyData,
      required_skill_ids: this.getKeySkill(searchStrategyValues),
      // basic_detail: this.state.basicDetailObj,
      search_in: searchStrategyValues?.target_pool?.search_in || '',
      any_keywords: searchStrategyValues?.target_pool?.any_keywords || "",
      all_keywords: searchStrategyValues?.target_pool?.all_keywords || "",
      exclude_keywords: searchStrategyValues?.target_pool?.exclude_keywords || "",
      //
      experience_min: searchStrategyValues?.target_pool?.experience_min || null,
      experience_max: searchStrategyValues?.target_pool?.experience_max || null,
      currency: searchStrategyValues?.target_pool?.currency || "inr",
      annual_salary_min: searchStrategyValues?.target_pool?.annual_salary_min || null,
      annual_salary_max: searchStrategyValues?.target_pool?.annual_salary_max || null,
      current_location: searchStrategyValues?.target_pool?.current_location || "",
      requirements: searchStrategyValues?.target_pool?.requirements || "",
      status: searchStrategyValues?.target_pool?.status || "",
      profile_source: searchStrategyValues?.target_pool?.profile_source || "",
      comments: searchStrategyValues?.target_pool?.comments || "",
      functional_area_id: searchStrategyValues?.target_pool?.functional_area_id || "",
      role: searchStrategyValues?.target_pool?.role || "",
      industry_type: searchStrategyValues?.target_pool?.industry_type || "",
      employer_type: searchStrategyValues?.target_pool?.employer_type || "",
      current_employer: searchStrategyValues?.target_pool?.current_employer || "",
      employer_all_words: searchStrategyValues?.target_pool?.employer_all_words || "",
      exclude_employer_type: searchStrategyValues?.target_pool?.exclude_employer_type || "",
      exclude_current_employer: searchStrategyValues?.target_pool?.exclude_current_employer || "",
      exclude_employer_all_words: searchStrategyValues?.target_pool?.exclude_employer_all_words || "",
      designation_type_her: searchStrategyValues?.target_pool?.designation_type_her || "",
      designation_current_employer: searchStrategyValues?.target_pool?.designation_current_employer || "",
      designation_all_words: searchStrategyValues?.target_pool?.designation_all_words || "",
      notice_period: searchStrategyValues?.target_pool?.notice_period || "",
      ug_qualification_any: searchStrategyValues?.target_pool?.ug_qualification_any || "",
      ug_qualification_enforced: searchStrategyValues?.target_pool?.ug_qualification_enforced || "",
      pg_qualification_any: searchStrategyValues?.target_pool?.pg_qualification_any || "",
      pg_qualification_enforced: searchStrategyValues?.target_pool?.pg_qualification_enforced || "",
      ppg_qualification_any: searchStrategyValues?.target_pool?.ppg_qualification_any || "",
      ppg_qualification_enforced: searchStrategyValues?.target_pool?.ppg_qualification_enforced || "",
      resume_processed_by: searchStrategyValues?.target_pool?.resume_processed_by || "",
      star_rating_from: searchStrategyValues?.target_pool?.star_rating_from || null,
      star_rating_to: searchStrategyValues?.target_pool?.star_rating_to || null,
      candidate_age_from: searchStrategyValues?.target_pool?.candidate_age_from || null,
      candidate_age_to: searchStrategyValues?.target_pool?.candidate_age_to || null,
      work_status_for_usa: searchStrategyValues?.target_pool?.work_status_for_usa || "",
      work_status_for_other_country: searchStrategyValues?.target_pool?.work_status_for_other_country || "",
      affirmative_action_value_id: searchStrategyValues?.target_pool?.affirmative_action_value_id || "",
      show_candidate_seeking: searchStrategyValues?.target_pool?.show_candidate_seeking || "",
      sort_by_relevance: searchStrategyValues?.target_pool?.sort_by_relevance || "",
      resume_per_page_relevance: searchStrategyValues?.target_pool?.resume_per_page_relevance || "",
      conclusion: searchStrategyValues?.conclusion || emptyData,
      employment_type: searchStrategyValues?.target_pool?.employment_type || "",
      show_cand_mobile_no: searchStrategyValues?.target_pool?.show_cand_mobile_no || false,
      show_cand_email_id: searchStrategyValues?.target_pool?.show_cand_email_id || false,
      show_cand_attached_resume: searchStrategyValues?.target_pool?.show_cand_attached_resume || false,
      show_cand_search_only_premium_resume: searchStrategyValues?.target_pool?.show_cand_search_only_premium_resume || false,
      search_women_candidate_only: searchStrategyValues?.target_pool?.search_women_candidate_only || false,
      search_candidate_special_ability: searchStrategyValues?.target_pool?.search_candidate_special_ability || false,
      include_profile_with_zero_salary_or_not_mentioned: searchStrategyValues?.target_pool?.include_profile_with_zero_salary_or_not_mentioned || false,
      post_preferences_attributes: [
        { name: "brief", value: false },
        { name: "role_dynamics", value: false },
        { name: "required_candidate_skills", value: false },
        { name: "target_pool", value: false },
        { name: "conclusion", value: false },
      ],
    }

    const basicDetail = {
      search_in: searchStrategyValues?.target_pool?.search_in || "",
      any_keywords: searchStrategyValues?.target_pool?.any_keywords || "",
      all_keywords: searchStrategyValues?.target_pool?.all_keywords || "",
      exclude_keywords: searchStrategyValues?.target_pool?.exclude_keywords || "",
    }

    const fileInputRef: any = React.createRef();
    const onBrowseClick = () => {
      console.log(fileInputRef)
      fileInputRef.current.click()
    }

    const onFileInputChange = (e: any) => {
      const reader = new FileReader();
      // const file = e.target.files[0];
      this.addFile(e.target.files)
    }

    const onDrop = (files) => {
      this.addFile(files)
    }

    const onSubmitSearchStrategy = (values: any) => {
      const mainKeys = [
        "brief", "job_detail_id", "role_dynamics", "conclusion", "required_skill_ids", "detail_attachment", "post_preferences_attributes"
      ]
      const attachments = {
        detail_attachment: !isEmpty(this.state.fileObject) ? this.state.fileObject : ""
      }
      const detailsObj = { ...values }
      let params = { target_pool_attributes: {} }
      forEach(detailsObj, (value, key) => {
        if (mainKeys.includes(key)) {
          params = { ...params, [key]: value }
        } else {
          params = {
            ...params,
            target_pool_attributes: {
              ...params.target_pool_attributes,
              [key]: value
            }
          }
        }
      })
      this.submitSearchStrategy(params, attachments)
    }


    if (this.state.isFormLoading) return 'Fetching ...'

    return (
      <View style={[styles.container]}>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitSearchStrategy}
          validationSchema={() => this.getSearchStrategyFormValidations()}
        >
          {(formikProps) => {

            const { values } = formikProps

            const preSelectedRequiredSkill = keywordsList.filter(obj =>
            (Array.isArray(values.required_skill_ids) &&
              values.required_skill_ids.some((item) => (item == obj.value))
            )
            )

            return (
              <Fragment>
                <View style={[styles.leftContainer]}>
                  {this.getSideMenu(currentHash, formikProps)}
                  {this.getSidemenuFooter()}
                </View>
                <View style={[styles.rightContainer,]} >
                  <SearchStrategyForm
                    {...this.props}
                    {...formikProps}
                    data={keywordsList}
                    functionalAreaList={functionalAreaList}
                    courses={courses}
                    postGraduationList={postGraduationList}
                    doctorateList={doctorateList}
                    basicDetail={basicDetail}
                    onBrowseClick={onBrowseClick}
                    onFileInputChange={onFileInputChange}
                    onDrop={onDrop}
                    fileInputRef={fileInputRef}
                    preSelectedRequiredSkill={preSelectedRequiredSkill}
                    onChangeLayout={(height: any) => { this.setState({ sidemenuHeight: height + 220 }) }} />
                </View>
              </Fragment>
            )
          }}
        </Formik>

        <JobPreview
          isJobPreview={this.state.isJobPreview}
          jobPreviewModal={this.jobPreviewModal}
          closeJobPreviewModal={this.closeJobPreviewModal}
          openJobPreviewModal={this.openJobPreviewModal}
          backToEdit={this.props.backToEdit}
          navigation={this.props.navigation}
          onEdit={this.props.onEdit}
        />

      </View>
    );
  }

}

export default withStyles(customStyles)(SearchStrategy)


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 30,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "320px",
    // minHeight: "100%",
    padding: "27px",
    justifyContent: "space-between"
  },
  logoImage: {
    marginTop: 100,
    width: 45,
    height: 45
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "#fff",
    marginLeft: 30
  },
  stepAdditionalTextActive: {
    color: "rgb(126, 126, 126)",
    marginLeft: 30
  },
  stepIconContainer: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "5px"
  },
  stepIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepAdditionalIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepIcon: {
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    color: "rgb(20,29,61)"
  },
  stepAdditionalIconActive: {
    fontSize: "14px",
    color: "#fff"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    width: "calc(100% - 320px)",
    // minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  headingForm: {
    fontSize: "larger",
    marginBottom: 15,
    color: "white",
  },
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  // maxWidth(992, {
  //   leftContainer: {
  //     display: "none"
  //   },
  //   rightContainer: {
  //     width: "100%"
  //   }
  // }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);