import React, { Fragment } from "react";
import { StyleSheet, Text, View } from "react-native";

export default function PrivacyPolicy() {

    return (
        <Fragment>
            <View style={styles.section}>
                <Text style={styles.excerpt}>
                    Please read the following terms and conditions carefully as it sets out the terms of a legally binding agreement between you (the reader) and Business Standard Private Limited.
                </Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.heading}>
                    1) Introduction
                </Text>
                <Text style={styles.description}>
                    This following sets out the terms and conditions on which you may use the content on
                    business-standard.com website, business-standard.com's mobile browser site, Business Standard instore Applications and other digital publishing services (www.smartinvestor.in, www.bshindi.com and www.bsmotoring,com) owned by Business Standard Private Limited, all the services herein will be referred to as Business Standard Content Services.
                </Text>
            </View>


            <View style={styles.section}>
                <Text style={styles.heading}>
                    1) Introduction
                </Text>
                <Text style={styles.description}>
                    This following sets out the terms and conditions on which you may use the content on
                    business-standard.com website, business-standard.com's mobile browser site, Business Standard instore Applications and other digital publishing services (www.smartinvestor.in, www.bshindi.com and www.bsmotoring,com) owned by Business Standard Private Limited, all the services herein will be referred to as Business Standard Content Services.
                </Text>
            </View>


            <View style={styles.section}>
                <Text style={styles.heading}>
                    1) Introduction
                </Text>
                <Text style={styles.description}>
                    This following sets out the terms and conditions on which you may use the content on
                    business-standard.com website, business-standard.com's mobile browser site, Business Standard instore Applications and other digital publishing services (www.smartinvestor.in, www.bshindi.com and www.bsmotoring,com) owned by Business Standard Private Limited, all the services herein will be referred to as Business Standard Content Services.
                </Text>
            </View>


            <View style={styles.section}>
                <Text style={styles.heading}>
                    1) Introduction
                </Text>
                <Text style={styles.description}>
                    This following sets out the terms and conditions on which you may use the content on
                    business-standard.com website, business-standard.com's mobile browser site, Business Standard instore Applications and other digital publishing services (www.smartinvestor.in, www.bshindi.com and www.bsmotoring,com) owned by Business Standard Private Limited, all the services herein will be referred to as Business Standard Content Services.
                </Text>
            </View>

        </Fragment>
    );
}

const styles = StyleSheet.create({
    excerpt: {
        fontSize: 16,
        color: "black",
        letterSpacing: 0.15
    },
    section: {
        paddingBottom: 20
    },
    heading: {
        fontSize: 18,
        fontWeight: "500",
        letterSpacing: 0.25,
        paddingBottom: 14
    },
    description: {
        fontSize: 14,
        letterSpacing: 0.13,
        lineHeight: 20
    }
});