import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";
import * as yup from 'yup';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  defaultPasswordReset: any;
  role: string;
  open: boolean;
  passwordHelperText: string;
  enableCurrentTypePasswordField: boolean;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  isCurrentPasswordTouched: boolean;
  isPasswordTouched: boolean;
  isConfirmPasswordTouched: boolean;
  currentPassword: string;
  password: string;
  reTypePassword: string;
  errorResponse: string;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  userProfileGetApiCallId: string = "";
  userApiUpdatePasswordCallId: string = "";
  userAttr: any;
  passwordReg: RegExp;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  btnTextSignUp: string;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      defaultPasswordReset: "",
      role: "",
      open: true,
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableCurrentTypePasswordField: true,
      isPasswordTouched: false,
      isConfirmPasswordTouched: false,
      isCurrentPasswordTouched: false,
      password: "",
      reTypePassword: "",
      currentPassword: "",
      errorResponse: ""
    };

    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {

      if (this.userProfileGetApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.userProfileGetApiCallId) {

        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

      if (responseJson && !responseJson.errors &&  responseJson.data.attributes) { 
        this.userAttr = responseJson.data.attributes;
        if (this.userAttr !== null && this.userAttr !== undefined) {
          let default_password_reset_at = this.userAttr.default_password_reset_at;
          let role = this.userAttr.role.name;
          this.setState({
            defaultPasswordReset: default_password_reset_at,
            role: role
          });  
        }

      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (this.userApiUpdatePasswordCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.userApiUpdatePasswordCallId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    if (responseJson && !responseJson.errors) {
      this.showAlert("", "Password updated successfully", "OK");
      this.setState({open: false});
    } else {
      this.parseApiErrorResponse(responseJson);
      const validateUserError = JSON.stringify(responseJson.errors[0].profile).replace(/\"/g, "");
      this.setState({errorResponse : validateUserError})
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnableCurrentPasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnCurrentPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enableCurrentTypePasswordField: !this.state.enableCurrentTypePasswordField });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.state
        .enableCurrentTypePasswordField;
      this.imgEnableCurrentPasswordFieldProps.source = this.txtInputCurrentPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };


  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };


  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPassword: text });      
      //@ts-ignore
      this.txtInputCurrentPasswordProps.value = text;
    },
    secureTextEntry: true,
    onBlur: () => {
      this.setState({ isCurrentPasswordTouched: true });
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
    onBlur: () => {
      this.setState({ isConfirmPasswordTouched: true });
    }
  };

  txtInputPasswordProps = {
    onChange: (e: any) => {
      var text = e.target.value;
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
    onBlur: () => {
      this.setState({ isPasswordTouched: true });
    }
  };

  btnUpdatePasswordProps = {
    onPress: () => this.updatePassword({ current_password: this.state.currentPassword, new_password: this.state.password })
  };

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }


  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  async getUserProfile() {
    const authToken = await Storage.get("authToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updatePassword(values: any) {
    const authToken = await Storage.get("authToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userApiUpdatePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdatePassword
    );

    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const data = {
      current_password: values.currentPassword,
      new_password: values.password
    };


    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getFormValidations() {
    const validations = yup.object().shape({
      currentPassword: yup
      .string()
      .min(8, "Minimum Password length is 8.")
      .max(16, "Maximum Password length is 16")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")
      .required("Current Password is required."),
      password: yup
        .string()
        .min(8, "Minimum Password length is 8.")
        .max(16, "Maximum Password length is 16")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")
        .required("Password is required."),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords and confirm password must match")
        .required("Confirm Password is required."),
    });
    return validations;
  }
}
