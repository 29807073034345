//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import Icon from 'react-native-vector-icons/FontAwesome';
import './react-file-drop.css';
import './style.css';
import { FileDrop } from 'react-file-drop';

import CountryCodeSelector from "../../../../blocks/country-code-selector/src/CountryCodeSelector";
import * as Utilities from "../../../../framework/src/Utilities";
import Storage from "../../../../framework/src/StorageProvider.web";
import { MdCloudUpload } from "react-icons/md"
import { FaTimes } from "react-icons/fa";
import TextField from '@material-ui/core/TextField';
import csc from 'country-state-city';
import { ICountry, IState, ICity } from 'country-state-city';
import Select, { components } from 'react-select';
//@ts-ignore
import classnames from "classnames";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import AdditionalDetailFormController, {
  Props
} from "./AdditionalDetailFormController";


export default class BasicInformationForm extends AdditionalDetailFormController {
  constructor(props: Props) {
    super(props);

    //Storage.get("profileData").then((profileData) => this.setState({ profileData }));
  }


  fileInputRef: any = React.createRef();
  onBrowseClick = () => {
    this.fileInputRef.current.click()
  }
  onDrop = (files: any) => {
    this.onAddFiles(files);
  }
  onFileInputChange = (event: any) => {
    const { files } = event.target;
    this.onAddFiles(files);
  }
  onAddFiles = (newFiles: Array<File>) => {
    var file: any = undefined;
    if (newFiles.length > 0) {
      file = newFiles[0];
      console.log("Filesext ", newFiles[0]);
      if (!newFiles[0].type.includes("image/")) {
        this.showAlert(
          "",
          "Only Images are allowed",
          "OK"
        );
        return;
      }
      if (newFiles[0].size > 10485760) {
        this.showAlert(
          "",
          "Max file size allowed is 10mb",
          "OK"
        );
        return;
      }


      this.props.parent.setState({ loading: true }, () => {
        this.updateProfilePhoto(file);
      });
    }

    //for (let i = 0; i < newFiles.length; i++) {
    //this.state.files.push(newFiles[i]);
    //this.state.filesProgress.push(50);
    //};
    //this.setState({ files: [...this.state.files] });
  }

  componentDidUpdate() {
    if (this.state.profileData && this.state.profileImage !== this.state.profileData.attributes.avatar) {
      this.setState({ profileImage: this.state.profileData.attributes.avatar });
    }
    Dimensions.addEventListener('change', () => this.forceUpdate());
  }

  async componentDidMount() {
    super.componentDidMount();
    this.props.parent.setState({ loading: true }, () => {
      this.fetchProfile();
      this.getCountryList();
    });
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    Dimensions.removeEventListener('change', () => this.forceUpdate());
  }


  onPressNext(values: any) {
    /*
        if (!this.state.profileImage) {
          this.setState({ profileImageError: "Profile Image is required" });
          this.showAlert(
            "",
            "Profile Image is required.",
            "OK"
          );
          return;
        }
    */

    this.props.parent.setState({ loading: true }, () => this.updateProfile({
      ...values,
      //avatar: undefined,
      new_whatsapp_number: values.new_whatsapp_number ? "+" + this.state.countries[values.whatsappCountryCodeIndex]?.attributes?.country_code + values.new_whatsapp_number : undefined,
      new_phone_number: values.new_phone_number ? "+" + this.state.countries[values.countryCodeIndex]?.attributes?.country_code + values.new_phone_number : undefined,
      new_alternate_number: values.new_alternate_number ? "+" + this.state.countries[values.alternateCountryCodeIndex]?.attributes?.country_code + values.new_alternate_number : undefined,
      country: values.country.label,
      state: values.state.label,
      city_district: values.city_district.label,
      step: 2
    }));
  }


  render() {

    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.email) && this.state.email != "";
    const isPasswordValid = this.state.password != "";
    const isStep1Complete = true;
    const isStep2Complete = false;
    const isStep3Complete = false;
    const isStep4Complete = false;
    const profileData = this.state.profileData;


    var allCountryList = csc.getAllCountries();
    var allStateList = csc.getAllStates();
    var allCityList = csc.getAllCities();

    var defaultCountry: any = allCountryList.find((item, index) => item.name === profileData?.attributes?.country);

    if (defaultCountry) {
      defaultCountry = { value: defaultCountry.isoCode, label: defaultCountry.name };
    }


    var defaultState: any = allStateList.find((item, index) => item.name === profileData?.attributes?.state);

    if (defaultState) {
      defaultState = { value: defaultState.isoCode, label: defaultState.name };
    }


    var defaultCity: any = allCityList.find((item, index) => item.name === profileData?.attributes?.city_district);

    if (defaultCity) {
      defaultCity = { value: defaultCity.name, label: defaultCity.name };
    }

    const initialValues = {
      //avatar: profileData?.attributes?.avatar || "",
      first_name: profileData?.attributes?.first_name ?? "",
      last_name: profileData?.attributes?.last_name ?? "",
      new_phone_number: profileData?.attributes?.phone_number ?? "",
      new_alternate_number: profileData?.attributes?.alternate_number ?? "",
      new_whatsapp_number: profileData?.attributes?.whatsapp_number ?? "",
      countryCodeIndex: this.state.countries.findIndex((item) => item.attributes?.country_code === (profileData?.attributes?.country_code ?? "91")) || 0,
      alternateCountryCodeIndex: this.state.countries.findIndex((item) => item.attributes?.country_code === (profileData?.attributes?.alternate_number_country_code ?? "91")) ?? 0,
      whatsappCountryCodeIndex: this.state.countries.findIndex((item) => item.attributes?.country_code === (profileData?.attributes?.whatsapp_number_country_code ?? "91")) ?? 0,
      country: defaultCountry ?? "",
      state: defaultState ?? "",
      city_district: defaultCity ?? ""
    }


    //var countries = this.state.countries.map((item) => ({ value: item.attributes?.name, label: item.attributes?.name }));
    //var countries = this.state.countries.map((item) => ({ value: item.attributes?.name, label: item.attributes?.name }));
    var countryCodes = this.state.countries.map((item, index) => ({ value: index, label: `${item.attributes?.name} | +${item.attributes?.country_code}` }));


    console.log("chhola ", this.state.profileData);

    return this.state.profileData ? (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <TouchableOpacity style={[styles.button, { marginRight: 80 }]} onPress={() => Utilities.logoutUser("force", () => this.goToLogin())}><Text style={styles.buttonText}>LOG OUT</Text></TouchableOpacity>
        </View>
        <View style={{ minHeight: "calc( 100vh - 116px )", width: "100%", flexDirection: "row" }}>
          <View style={styles.leftContainer}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                this.onPressNext(values);
              }}
              validationSchema={this.geBasicInformationFormValidations}
            >
              {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {

                const [showFlag, setShowFlag] = useState(false);
                const whatsRef: any = useRef(undefined);

                useEffect(() => {
                  setShowFlag(false);
                }, [values.whatsappCountryCodeIndex]);

                useEffect(() => {
                  if (!showFlag) {
                    setShowFlag(true);
                  }
                }, [showFlag]);


                //for address

                var countryList = allCountryList.map((item) => ({ value: item.isoCode, label: item.name }));
                var stateList = csc.getStatesOfCountry(values.country?.value).map((item) => ({ value: item.isoCode, label: item.name }));
                var cityList = csc.getCitiesOfState(values.country?.value, values.state?.value).map((item) => ({ value: item.name, label: item.name }));



                return (
                  <Fragment>
                    <View>
                      <Text style={styles.rightTitle}>Basic Information</Text>
                      <Text style={styles.rightSubTitle}>Before you looking for job, we need to know some information to get to know you.</Text>

                      <Text style={styles.formLabel}>Personal Information</Text>

                      {this.state.profileImage != undefined ?

                        <View style={[styles.textInputContainer, { height: 220, width: "100%", marginBottom: 20, justifyContent: "center", alignItems: "center" }]}>
                          <View>
                            <TouchableOpacity onPress={() => this.props.parent.setState({ loading: true }, () => this.deleteProfilePhtoto())} style={styles.profileCrossIconContainer} >
                              <FaTimes style={styles.profileCrossIcon} />
                            </TouchableOpacity>
                            <Image source={{ uri: this.state.profileImage } || assets.userProfileImage} resizeMode={"cover"} style={styles.profilePicture} />
                          </View>
                        </View>

                        :

                        <Fragment>
                          <View style={{ width: "100%", marginBottom: 20 }}>
                            <FileDrop onDrop={this.onDrop}>

                              <div style={{ marginBottom: 12 }}>
                                <MdCloudUpload className="upload-icon" color="#1c1920" size={52} />
                                <div style={{ color: "#1c1920", marginTop: 10, fontSize: 13 }}>
                                  Click here to browse or<br />drag and drop a file<br />
                                  <TouchableOpacity onPress={this.onBrowseClick} style={[styles.button, { marginHorizontal: "auto", marginTop: 12 }]} ><Text style={styles.buttonText}>BROWSE</Text></TouchableOpacity>
                                </div>
                              </div>
                              <div style={styles.text}>
                                <span style={{ paddingTop: 12, paddingBottom: 12 }}>File must be an image format. The maximum file-size is 10 MB.</span>
                              </div>
                            </FileDrop>
                            <input
                              onChange={this.onFileInputChange}
                              ref={this.fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              multiple={false}
                              value=""
                            />
                          </View>

                          {/*<View style={styles.textInputErrorMessageContainer}>
                          {this.state.profileImageError != "" &&
                            <Text style={styles.textInputErrorMessage}>{this.state.profileImageError}</Text>
                          }
                        </View>*/}
                        </Fragment>
                      }

                      <View style={styles.formRow}>
                        <View style={styles.inputWrapper} >
                          <TextField name="first_name" onChange={handleChange} onBlur={() => setFieldTouched('first_name')} variant={"outlined"} label={"First Name"} error={!!(errors.first_name && touched.first_name)} helperText={touched.first_name && errors.first_name} style={styles.plainTextInput} value={values.first_name} />

                        </View>
                        <View style={styles.inputWrapper} >
                          <TextField name="last_name" onChange={handleChange} onBlur={() => setFieldTouched('last_name')} variant={"outlined"} label={"Last Name"} error={!!(errors.last_name && touched.last_name)} helperText={touched.last_name && errors.last_name} style={styles.plainTextInput} value={values.last_name} />

                        </View>
                      </View>


                      <View style={[styles.formRow, { zIndex: 1 }]}>
                        {/*<View style={styles.inputWrapper} >
                          <TextField name="new_phone_number" onChange={(e) => { e.target.value && !e.target.value.startsWith("+") ? setFieldValue("new_phone_number", "+" + e.target.value) : handleChange(e); }} onBlur={() => setFieldTouched('new_phone_number')} variant={"outlined"} label={"Phone Number"} error={!!(errors.new_phone_number && touched.new_phone_number)} helperText={touched.new_phone_number && errors.new_phone_number} style={styles.plainTextInput} value={values.new_phone_number} />
                        </View>*/}
                        <View style={[styles.inputWrapper, { flexDirection: "row", zIndex: 1 }]} >
                          <View style={{ height: 53, zIndex: 1, position: "absolute", width: 90, flexDirection: "row", alignItems: "center" }}>
                            {showFlag &&
                              <ReactCountryFlag
                                countryCode={this.state.countries[values.countryCodeIndex]?.id}
                                svg
                                style={{
                                  width: 28,
                                  height: 28,
                                  position: "absolute",
                                  left: 12
                                }}
                                title={this.state.countries[values.countryCodeIndex]?.id}
                              />
                            }
                            <Select
                              styles={{
                                ...whatsappSelectStyles,
                                menu: (provided: any, state: any) => {
                                  return {
                                    ...provided,
                                    position: "absolute",
                                    width: whatsRef?.current?.offsetWidth ?? "200px"
                                  }
                                }
                              }}
                              isSearchable={true}
                              onChange={(option: any) => setFieldValue('countryCodeIndex', option.value)}
                              placeholder={"+" + this.state.countries[values.countryCodeIndex]?.attributes?.country_code}
                              className={classnames("", { "select-error-input": touched.countryCodeIndex && errors.countryCodeIndex })}
                              value={{ value: this.state.countries[values.countryCodeIndex]?.attributes?.country_code, label: "+" + this.state.countries[values.countryCodeIndex]?.attributes?.country_code }}
                              options={countryCodes}
                              components={{
                                Option: ({ ...props }) => <components.Option {...props}><div>
                                  <ReactCountryFlag
                                    countryCode={this.state.countries[props.data.value]?.id}
                                    svg
                                    style={{
                                      width: 28,
                                      height: 28,
                                      marginRight: 12
                                    }}
                                    title={this.state.countries[props.data.value]?.id}
                                  />
                                  {this.state.countries[props.data.value]?.attributes?.name + " | + " + this.state.countries[props.data.value]?.attributes?.country_code}</div></components.Option>
                              }}
                            />
                            <View style={{ height: 20, backgroundColor: "silver", width: 1, zIndex: -1 }} />
                          </View>
                          <View style={{ width: "100%" }}>

                            <TextField name="new_phone_number" onChange={handleChange} onBlur={() => setFieldTouched('new_phone_number')} variant={"outlined"} label={"Phone Number"} error={!!(errors.new_phone_number && touched.new_phone_number)} helperText={touched.new_phone_number && errors.new_phone_number} style={styles.plainTextInput} value={values.new_phone_number} className="whatsapp-input" />

                          </View>
                          <View style={{ position: "absolute", right: 0, height: 56, paddingRight: 10, justifyContent: "center", display: "none" }}>
                            <Image source={assets.whatsappIcon} style={{ height: 28, width: 28 }} resizeMode="contain" />
                          </View>
                        </View>


                        {/*<View style={styles.inputWrapper} >
                          <TextField name="new_alternate_number" onChange={(e) => { e.target.value && !e.target.value.startsWith("+") ? setFieldValue("new_alternate_number", "+" + e.target.value) : handleChange(e); }} onBlur={() => setFieldTouched('new_alternate_number')} variant={"outlined"} label={"Alternate Number"} error={!!(errors.new_alternate_number && touched.new_alternate_number)} helperText={touched.new_alternate_number && errors.new_alternate_number} style={styles.plainTextInput} value={values.new_alternate_number} />

                          </View>*/}
                        <View style={[styles.inputWrapper, { flexDirection: "row" }]} >
                          <View style={{ height: 53, zIndex: 1, position: "absolute", width: 90, flexDirection: "row", alignItems: "center" }}>
                            {showFlag &&
                              <ReactCountryFlag
                                countryCode={this.state.countries[values.alternateCountryCodeIndex]?.id}
                                svg
                                style={{
                                  width: 28,
                                  height: 28,
                                  position: "absolute",
                                  left: 12
                                }}
                                title={this.state.countries[values.alternateCountryCodeIndex]?.id}
                              />
                            }
                            <Select
                              styles={{
                                ...whatsappSelectStyles,
                                menu: (provided: any, state: any) => {
                                  return {
                                    ...provided,
                                    position: "absolute",
                                    width: whatsRef?.current?.offsetWidth ?? "200px"
                                  }
                                }
                              }}
                              isSearchable={true}
                              onChange={(option: any) => setFieldValue('alternateCountryCodeIndex', option.value)}
                              placeholder={"+" + this.state.countries[values.alternateCountryCodeIndex]?.attributes?.country_code}
                              className={classnames("", { "select-error-input": touched.alternateCountryCodeIndex && errors.alternateCountryCodeIndex })}
                              value={{ value: this.state.countries[values.alternateCountryCodeIndex]?.attributes?.country_code, label: "+" + this.state.countries[values.alternateCountryCodeIndex]?.attributes?.country_code }}
                              options={countryCodes}
                              components={{
                                Option: ({ ...props }) => <components.Option {...props}><div>
                                  <ReactCountryFlag
                                    countryCode={this.state.countries[props.data.value]?.id}
                                    svg
                                    style={{
                                      width: 28,
                                      height: 28,
                                      marginRight: 12
                                    }}
                                    title={this.state.countries[props.data.value]?.id}
                                  />
                                  {this.state.countries[props.data.value]?.attributes?.name + " | + " + this.state.countries[props.data.value]?.attributes?.country_code}</div></components.Option>
                              }}
                            />
                            <View style={{ height: 20, backgroundColor: "silver", width: 1, zIndex: -1 }} />
                          </View>
                          <View style={{ width: "100%" }}>

                            <TextField name="new_alternate_number" onChange={handleChange} onBlur={() => setFieldTouched('new_alternate_number')} variant={"outlined"} label={"Alternate Number"} error={!!(errors.new_alternate_number && touched.new_alternate_number)} helperText={touched.new_alternate_number && errors.new_alternate_number} style={styles.plainTextInput} value={values.new_alternate_number} className="whatsapp-input" />

                          </View>
                          <View style={{ position: "absolute", right: 0, height: 56, paddingRight: 10, justifyContent: "center", display: "none" }}>
                            <Image source={assets.whatsappIcon} style={{ height: 28, width: 28 }} resizeMode="contain" />
                          </View>
                        </View>
                      </View>


                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { flexDirection: "row" }]} >
                          <View style={{ height: 53, zIndex: 1, position: "absolute", width: 90, flexDirection: "row", alignItems: "center" }}>
                            {showFlag &&
                              <ReactCountryFlag
                                countryCode={this.state.countries[values.whatsappCountryCodeIndex]?.id}
                                svg
                                style={{
                                  width: 28,
                                  height: 28,
                                  position: "absolute",
                                  left: 12
                                }}
                                title={this.state.countries[values.whatsappCountryCodeIndex]?.id}
                              />
                            }
                            <Select
                              styles={{
                                ...whatsappSelectStyles,
                                menu: (provided: any, state: any) => {
                                  return {
                                    ...provided,
                                    position: "absolute",
                                    width: whatsRef?.current?.offsetWidth ?? "200px"
                                  }
                                }
                              }}
                              isSearchable={true}
                              onChange={(option: any) => { setFieldValue('whatsappCountryCodeIndex', option.value); console.log("WhatsRef ", whatsRef); }}
                              placeholder={"+" + this.state.countries[values.whatsappCountryCodeIndex]?.attributes?.country_code}
                              className={classnames("", { "select-error-input": touched.whatsappCountryCodeIndex && errors.whatsappCountryCodeIndex })}
                              value={{ value: this.state.countries[values.whatsappCountryCodeIndex]?.attributes?.country_code, label: "+" + this.state.countries[values.whatsappCountryCodeIndex]?.attributes?.country_code }}
                              options={countryCodes}
                              components={{
                                Option: ({ ...props }) => <components.Option {...props}><div>
                                  <ReactCountryFlag
                                    countryCode={this.state.countries[props.data.value]?.id}
                                    svg
                                    style={{
                                      width: 28,
                                      height: 28,
                                      marginRight: 12
                                    }}
                                    title={this.state.countries[props.data.value]?.id}
                                  />
                                  {this.state.countries[props.data.value]?.attributes?.name + " | + " + this.state.countries[props.data.value]?.attributes?.country_code}</div></components.Option>
                              }}
                            />
                            <View style={{ height: 20, backgroundColor: "silver", width: 1, zIndex: -1 }} />
                          </View>
                          <View style={{ width: "100%" }}>
                            <TextField ref={whatsRef} name="new_whatsapp_number" onChange={handleChange} onBlur={() => setFieldTouched('new_whatsapp_number')} variant={"outlined"} label={"WhatsApp Number"} error={!!(errors.new_whatsapp_number && touched.new_whatsapp_number)} helperText={touched.new_whatsapp_number && errors.new_whatsapp_number} style={styles.plainTextInput} value={values.new_whatsapp_number} className="whatsapp-input" />

                          </View>
                          <View style={{ position: "absolute", right: 0, height: 56, paddingRight: 10, justifyContent: "center" }}>
                            <Image source={assets.whatsappIcon} style={{ height: 28, width: 28 }} resizeMode="contain" />
                          </View>
                        </View>
                      </View>

                      <Text style={styles.formLabel}>Address and Location</Text>
                      <View style={[styles.formRow, { zIndex: -1 }]}>
                        <View style={styles.inputWrapper} >

                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"Country"}
                            onChange={(option: any) => { setFieldValue('country', option); }}
                            className={classnames("", { "select-error-input": touched.country && errors.country })}
                            value={values.country}
                            options={countryList}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.country && errors.country &&
                              <Text style={styles.textInputErrorMessage}>{errors.country}</Text>
                            }
                          </View>
                        </View>


                        <View style={styles.inputWrapper} >

                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"State"}
                            onChange={(option: any) => setFieldValue('state', option)}
                            className={classnames("", { "select-error-input": touched.state && errors.state })}
                            options={stateList}
                            value={values.state}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.state && errors.state &&
                              <Text style={styles.textInputErrorMessage}>{errors.state}</Text>
                            }
                          </View>
                          {/*<TextField name="city_district" onChange={handleChange} onBlur={() => setFieldTouched('city_district')} variant={"outlined"} label={"City/District"} error={!!(errors.city_district && touched.city_district)} helperText={touched.city_district && errors.city_district} style={styles.plainTextInput} value={values.city_district} />*/}

                        </View>
                      </View>

                      <View style={[styles.formRow, { zIndex: -1 }]}>
                        <View style={styles.inputWrapper} >


                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"City"}
                            onChange={(option: any) => setFieldValue('city_district', option)}
                            className={classnames("", { "select-error-input": touched.city_district && errors.city_district })}
                            value={values.city_district}
                            options={cityList}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.city_district && errors.city_district &&
                              <Text style={styles.textInputErrorMessage}>{errors.city_district}</Text>
                            }
                          </View>
                        </View>


                        <View style={styles.inputWrapper} >
                          {/*<TextField name="city_district" onChange={handleChange} onBlur={() => setFieldTouched('city_district')} variant={"outlined"} label={"City/District"} error={!!(errors.city_district && touched.city_district)} helperText={touched.city_district && errors.city_district} style={styles.plainTextInput} value={values.city_district} />*/}

                        </View>
                      </View>



                    </View>


                    <View style={styles.bottomContainer}>
                      <TouchableOpacity style={[styles.button, { opacity: 0 }]} disabled={true}><Text style={styles.buttonText}>PREVIOUS STEP</Text></TouchableOpacity>
                      <TouchableOpacity onPress={() => { handleSubmit(); }} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>NEXT STEP</Text></TouchableOpacity>
                    </View>
                  </Fragment>
                )
              }}
            </Formik>
          </View>
          <View style={styles.rightContainer}>
            <Image source={assets.rightPeople} style={styles.rightImage} resizeMode="contain" />
          </View>
        </View>
      </View>
    ) :
      <View></View>
      ;
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "calc(100% - 116px)"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4,
    paddingVertical: 12
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    height: 116,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center"

  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
  ,
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 15,
    width: 15,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    height: 38
  }
  ,
  leftContainer: {
    width: "70%",
    minHeight: "100%",
    justifyContent: "space-between",
    paddingHorizontal: "8%",
    paddingBottom: "3%"
  },
  rightContainer: {
    width: "30%",
    minHeight: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  rightImage: {
    width: "21vw",
    height: "21vw"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  profilePicture: {
    height: 150,
    width: 130,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: "rgba(26, 24, 29, 0.32)"
  },
  profileCrossIconContainer: {
    zIndex: 1
  },
  profileCrossIcon: {
    color: "black",
    position: "absolute",
    right: 0,
    zIndex: 1,
    padding: 5,
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {
    leftContainer: {
      paddingHorizontal: "4%"
    }
  }),
  // below 1200
  maxWidth(1200, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
    leftContainer: {
      paddingHorizontal: "4%",
      width: "100%"
    }
  }),
  // below  626
  maxWidth(626, {
    leftContainer: {
      paddingHorizontal: "2%",
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);


const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  }
}



const whatsappSelectStyles = {
  container: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: "transparent"
    }
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 50,
      position: "aboslute",
      marginLeft: 2,
      border: "unset",
      width: 90,
      backgroundColor: "transparent"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      justifyContent: "flex-end"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  input: (provided: any, state: any) => {
    return {
      ...provided,
      color: "gray"
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  }
}
