import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Grid, Link, Select, MenuItem, CircularProgress, IconButton, Table, TableBody, TableCell, Checkbox, TableHead, TableRow, Icon, Button } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Search, ArrowDropDown, ChevronLeft, ChevronRight, DeleteOutline, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import AttributeModal from './AttributeModal.web'
import { editIcon, showIcon, hideIcon, dragIcon } from "./assets";
// Customizable Area End

import AttributeController, {
    Props,
    configJSON
} from "./AttributeController";


class Attribute extends AttributeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getAttributeList()
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const {
            hoveredStatusIndex,
            attributeList
        } = this.state
        return (
            <Box className={classes.container} >
                <Box className={classes.listContainer} >
                    <Grid sm={12} container={true} style={{ height: '100%', display: 'block' }} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={8} container={true} >
                                <Box style={{ padding: 24, }} >
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#1a181d'
                                    }} >Attribute</Typography>
                                    <Typography style={{
                                        opacity: 0.4,
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }}>Lorem Ipsume demo contetn should be here so we can see how it works.</Typography>
                                </Box>
                            </Grid>
                            <Grid alignItems='flex-end' sm={4} container={true} style={{
                                paddingTop: 24,
                                paddingRight: 24,
                                paddingBottom: 24,
                                flexDirection: 'column'
                            }} >

                                <Button onClick={() => this.openModalHandler()} style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#e45d33',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }} >CREATE</Button>

                                <Box className={classes.searchBoxContainer} >
                                    <input required style={{
                                        width: '100%',
                                        padding: 0,
                                        border: 'none',
                                        outline: 'none',
                                        height: '100%',
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        paddingLeft: 14
                                    }}
                                        type='text'
                                        value={this.state.searchKeyword}
                                        onChange={(e: any) => this.handleSearch(e.target.value)}
                                        placeholder='Search Here'
                                    />
                                    <Search style={{ color: '#000000', width: 17, opacity: 0.57, height: 17, marginRight: 22 }} />
                                </Box>
                            </Grid>
                        </Grid>
                        {!attributeList.loading && attributeList.data.length > 0 && (
                            <DragDropContext onDragEnd={this.handleOnDragEnd}>
                                <Grid sm={12} container={true} style={{ display: 'block' }} >
                                    <Grid sm={12} container={true} alignItems='center' style={{ paddingLeft: 25, paddingRight: 16, height: 56, borderBottom: '1px solid rgba(221, 221, 221, 0.57)', borderTop: '1px solid rgba(221, 221, 221, 0.57)' }} >
                                        <Grid sm={6} container={true} style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: 'rgba(0, 0, 0, 0.87)'
                                        }}  > Attributes </Grid>
                                        <Grid sm={6} container={true} className={classes.clolumnHeading}  > Index </Grid>
                                    </Grid>

                                    <Droppable droppableId={'attributeDroppableArea'}>
                                        {(provided, snapshot) => (
                                            <Grid sm={12} container={true} {...provided.droppableProps} ref={provided.innerRef} >
                                                {attributeList.data.map((attribute: any, i: number) => (
                                                    <Draggable key={attribute.id} draggableId={`${attribute.id}-${i}`} index={i}>
                                                        {(provided, snapshot) => (
                                                            <Grid sm={12} alignItems='center' container={true} ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps} style={{ ...provided.draggableProps.style, paddingLeft: 25, paddingRight: 16, cursor: 'default', backgroundColor: hoveredStatusIndex === `${i}` ? '#f1f1f1' : "#fcfcfc", height: 52 }} onMouseOver={() => { this.handleRowHover(i.toString()) }} onMouseLeave={() => { this.handleRowHover("") }}>
                                                                <Grid sm={6} container={true} className={classes.heading}>
                                                                    {_.startCase(_.toLower(attribute.attributes.name))}
                                                                </Grid>
                                                                <Grid sm={3} container={true} className={classes.heading}>
                                                                    {i}
                                                                </Grid>
                                                                <Grid sm={3} container={true} className={classes.heading}>
                                                                    <Box display='flex' alignItems='center' width='100%' justifyContent='flex-end' height='18px'>
                                                                        {hoveredStatusIndex === `${i}` && (
                                                                            <Fragment>
                                                                                <IconButton onClick={() => this.openModalHandler(true)} style={{ padding: 0 }}>
                                                                                    <Icon>
                                                                                        <img src={editIcon} style={{ width: 18, height: 18 }} />
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => this.updateAttribute([{ ...attribute.attributes, enabled: !attribute.attributes.enabled }])} style={{ padding: 0, marginLeft: 12, marginRight: 12 }}>
                                                                                    <Icon>
                                                                                        <img src={attribute.attributes.enabled ? showIcon : hideIcon} style={{ width: 18, height: 18 }} />
                                                                                    </Icon>
                                                                                </IconButton>

                                                                                <img src={dragIcon} style={{ width: 18, height: 18, cursor: 'grab' }} />

                                                                            </Fragment>
                                                                        )}
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </Grid>
                            </DragDropContext>
                        )}
                        {attributeList.loading && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='65%' >
                                <CircularProgress style={{ color: "#e45d33" }} />
                            </Box>
                        )}
                        {!attributeList.loading && attributeList.data.length === 0 && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='55%' >
                                <Typography style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: 'rgba(0, 0, 0, 0.6)'
                                }} >No records to display</Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
                <AttributeModal
                    attributes={this.state.attributes}
                    showModal={this.state.showModal}
                    isEdit={this.state.isEdit}
                    showNewAttribute={this.state.showNewAttribute}
                    createUpdateLoading={this.state.createUpdateLoading}
                    newAttribute={this.state.newAttribute}
                    closeModalHandler={this.closeModalHandler}
                    changeTextHandler={this.changeTextHandler}
                    createAndUpdateHandler={this.createAndUpdateHandler}
                    updateVisibilityHandler={this.updateVisibilityHandler}
                    updateIndexHandler={this.updateIndexHandler}
                    showNewAttributeFieldHandler={this.showNewAttributeFieldHandler}
                    newAttributeBlurHandler={this.newAttributeBlurHandler}
                    newAttributeChangeHandler={this.newAttributeChangeHandler}
                    validateAttributeName={this.validateAttributeName}
                />
            </Box >
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: "9.5rem",
        paddingBottom: "7.8%",
        backgroundColor: "#fcfcfc",
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '85%',
        borderRadius: 2,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        overflow: 'hidden'
    },
    searchBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ffffff',
        height: 40,
        marginTop: 21,
        width: '100%',
        maxWidth: 414
    },
    selectContainer: {
        padding: '0px !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus': {
            backgroundColor: '#ffffff',
        }
    },
    clolumnHeading: {
        fontFamily: 'Poppins',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    heading: {
        fontFamily: 'Poppins',
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    }
});


export default withStyles(styles)(Attribute)
// Customizable Area End
