import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Grid, Select, InputLabel, Checkbox, FormHelperText, FormControl, IconButton, Icon, Button, Modal, Backdrop, Fade, TextField, MenuItem } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { KeyboardBackspace, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import { uploadIcon, whatsappIcon } from "./assets";
// Customizable Area End

import AdminConsoleController, {
    Props,
    configJSON
} from "./AdminConsoleController";
import { object } from "yup";

class AdminPreviewModal extends AdminConsoleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            classes,
            showModal,
            closeModalHandler,
            adminDetails,
            isEdit,
            createUpdateLoading,
            errors,
            adminType,
            countries,
            states,
            cities,
            avatarHandler,
            deleteAvatarHandler,
            onChangeInputHandler,
            onBlurInputHandler,
            updatePermissionHandler,
            createAdminHandler
        } = this.props

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModal}
                onClose={closeModalHandler}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes: {
                        root: classes.backdrop
                    }
                }}
            >
                <Fade in={showModal}>
                    <Box className={classes.container} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ height: 60, backgroundColor: "#1a181d" }} >
                                <Box display="flex" alignItems='center' pl='26px' >
                                    <KeyboardBackspace style={{ height: 24, width: 24, marginRight: 18, color: '#ffffff' }} />
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#ffffff'
                                    }} >{`${isEdit ? 'Edit' : 'Create'} ${adminType === 'admin' ? 'Admin' : 'Recruiter'}`}</Typography>
                                </Box>
                                <IconButton onClick={closeModalHandler} style={{ marginRight: 12, }} >
                                    <Icon>
                                        <CloseIcon style={{ height: 24, width: 24, opacity: 0.7, color: '#ffffff' }} />
                                    </Icon>
                                </IconButton>
                            </Grid>

                            <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: 24, paddingTop: 24, paddingRight: 24, paddingBottom: 32 }}>
                                <Grid sm={12} alignItems='center' justify='center' container={true} style={{ backgroundColor: "#ffffff", height: 308, marginBottom: errors.hasOwnProperty('avatar') ? 0 : 32, width: '100%', position: 'relative', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', overflow: 'hidden' }}>
                                    {adminDetails?.avatar ? (
                                        <Box style={{ position: 'relative' }} >
                                            <img src={adminDetails?.avatarUrl || adminDetails?.avatar} style={{ height: 206, width: 206, borderRadius: 6 }} />
                                            <IconButton onClick={() => deleteAvatarHandler()} style={{ position: 'absolute', top: 0, right: 0 }} >
                                                <Icon>
                                                    <CloseIcon style={{ height: 24, width: 24, color: '#ffffff' }} />
                                                </Icon>
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Box style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', opacity: 0.9 }} >
                                            <input type='file' data-max-size="10240" onChange={(e: any) => avatarHandler(e?.target?.files[0])} style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 2, cursor: 'pointer', opacity: 0 }} accept="image/png, image/jpeg, image/jpg" />
                                            <img src={uploadIcon} style={{ height: 68, width: 68 }} />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                fontWeight: 300,
                                                color: '#1c1920',
                                            }}>Click here to browse or</Typography>
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                fontWeight: 300,
                                                color: '#1c1920',
                                            }}>drag and drop a file</Typography>

                                            <Button style={{
                                                marginTop: 16,
                                                borderRadius: 4,
                                                backgroundColor: '#fafafa',
                                                height: 56,
                                                width: 200,
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                                            }} variant="contained">Browse</Button>

                                            <Typography style={{
                                                marginTop: 26,
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 'normal',
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            }}>File must be an image format. The maximum file-size is 10 MB.</Typography>
                                        </Box>
                                    )}
                                </Grid>
                                {errors.hasOwnProperty('avatar') && (
                                    <Typography style={{
                                        marginTop: 5,
                                        marginBottom: errors.hasOwnProperty('avatar') ? 32 : 0,
                                        fontFamily: 'Poppins',
                                        fontSize: 14,
                                        fontWeight: 'normal',
                                        color: 'red',
                                    }}>{errors['avatar']}</Typography>
                                )}

                                <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", marginBottom: 24 }}>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56
                                            }}
                                            label="First Name"
                                            defaultValue={adminDetails?.first_name}
                                            value={adminDetails?.first_name}
                                            variant="outlined"
                                            name='first_name'
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('first_name')}
                                            helperText={errors['first_name']}
                                        />
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-end' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56
                                            }}
                                            label="Last Name"
                                            defaultValue={adminDetails?.last_name}
                                            value={adminDetails?.last_name}
                                            variant="outlined"
                                            name='last_name'
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('last_name')}
                                            helperText={errors['last_name']}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", marginBottom: 24 }}>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56,
                                                opacity: isEdit ? 0.5 : 1
                                            }}
                                            label="Email Address"
                                            defaultValue={adminDetails?.email}
                                            value={adminDetails?.email}
                                            variant="outlined"
                                            name='email'
                                            disabled={isEdit}
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('email') || errors.hasOwnProperty('account')}
                                            helperText={errors['email'] || errors['account']}
                                        />
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-end' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56
                                            }}
                                            label="Phone No."
                                            defaultValue={adminDetails?.phone_number}
                                            value={adminDetails?.phone_number}
                                            variant="outlined"
                                            name='phone_number'
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('phone_number')}
                                            helperText={errors['phone_number']}
                                            inputProps={{ maxlength: "10" }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", marginBottom: 24 }}>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56
                                            }}
                                            label="Alternate number"
                                            defaultValue={adminDetails?.alternate_number}
                                            value={adminDetails?.alternate_number}
                                            variant="outlined"
                                            name='alternate_number'
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('alternate_number')}
                                            helperText={errors['alternate_number']}
                                            inputProps={{ maxlength: '10' }}
                                        />
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='flex-end' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <TextField
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                width: '95%',
                                                height: 56
                                            }}
                                            label="WhatsApp Number"
                                            defaultValue={adminDetails?.whatsapp_number}
                                            value={adminDetails?.whatsapp_number}
                                            variant="outlined"
                                            name='whatsapp_number'
                                            onChange={onChangeInputHandler}
                                            onBlur={onBlurInputHandler}
                                            error={errors.hasOwnProperty('whatsapp_number')}
                                            helperText={errors['whatsapp_number']}
                                            inputProps={{ maxlength: '10' }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 20, marginBottom: 20 }}>
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 18,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}>Address and Location</Typography>
                                </Grid>

                                <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", marginBottom: 36 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <FormControl variant="outlined" style={{ width: '95%', height: 56 }} error={errors.hasOwnProperty('country')}>
                                            <InputLabel id="country-select">Country/Region</InputLabel>
                                            <Select
                                                labelId='country-select'
                                                value={adminDetails?.country}
                                                onChange={onChangeInputHandler}
                                                onBlur={onBlurInputHandler}
                                                name='country'
                                                label="Country/Region"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {countries?.map(country => (
                                                    <MenuItem key={country.isoCode} value={country.name}>{country.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.hasOwnProperty('country') && <FormHelperText>{errors['country']}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid sm={12} md={4} alignItems='center' justify='center' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <FormControl variant="outlined" style={{ width: '95%', height: 56 }} error={errors.hasOwnProperty('state')}>
                                            <InputLabel id="state-select">State</InputLabel>
                                            <Select
                                                labelId='state-select'
                                                value={adminDetails?.state}
                                                onChange={onChangeInputHandler}
                                                onBlur={onBlurInputHandler}
                                                name='state'
                                                label="State"
                                                disabled={states?.length === 0}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {states?.map(state => (
                                                    <MenuItem key={state.isoCode} value={state.name}>{state.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.hasOwnProperty('state') && <FormHelperText>{errors['state']}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid sm={12} md={4} alignItems='center' justify='flex-end' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <FormControl variant="outlined" style={{ width: '95%', height: 56 }} error={errors.hasOwnProperty('city_district')}>
                                            <InputLabel id="city-select"> City/District</InputLabel>
                                            <Select
                                                labelId='city-select'
                                                value={adminDetails?.city_district}
                                                onChange={onChangeInputHandler}
                                                onBlur={onBlurInputHandler}
                                                name='city_district'
                                                label="City/District"
                                                disabled={cities?.length === 0}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {cities?.map((city, index) => (
                                                    <MenuItem key={index} value={city.name}>{city.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.hasOwnProperty('city_district') && <FormHelperText>{errors['city_district']}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 18,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        opacity: 0.9
                                    }}>Set Permissions</Typography>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 24 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Dashboard</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('dashboard', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('dashboard', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('dashboard', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Requirements</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('requirements', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('requirements', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('requirements', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 28 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Profile</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('profile', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('profile', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('profile', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Reports</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('reports', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('reports', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('reports', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 28 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Manage User</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('manage_user', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('manage_user', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('manage_user', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Roles & Permissions</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.read_permission}
                                                onChange={(e: any) => updatePermissionHandler('roles_and_permissions', 'read_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.write_permission}
                                                onChange={(e: any) => updatePermissionHandler('roles_and_permissions', 'write_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.delete_permission}
                                                onChange={(e: any) => updatePermissionHandler('roles_and_permissions', 'delete_permission', e?.target?.checked)}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid sm={12} container={true} justify='space-between' style={{ marginTop: '46px' }} >
                                    <Button onClick={closeModalHandler} style={{
                                        borderRadius: 4,
                                        backgroundColor: 'rgba(98, 2, 238, 0)',
                                        height: 56,
                                        width: '25%',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        fontFamily: 'Poppins',
                                        fontSize: 14,
                                        fontWeight: 500,
                                    }} variant="contained">Cancel</Button>
                                    {showModal && (
                                        <Button
                                            disabled={createUpdateLoading}
                                            onClick={() => createAdminHandler()}
                                            style={{
                                                borderRadius: 4,
                                                backgroundColor: '#e45d33',
                                                height: 56,
                                                width: '25%',
                                                color: '#ffffff',
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                opacity: createUpdateLoading ? 0.5 : 1
                                            }}
                                            variant="contained">
                                            {`${isEdit ? 'Update' : 'Create'}`}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '56px',
        paddingBottom: '50px',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    container: {
        overflow: 'auto',
        display: 'flex',
        width: 800,
        height: 'auto',
        maxHeight: '100%',
        borderRadius: 2,
        boxShadow: '0 7px 8px 4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
        backgroundColor: '#fafafa'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, 0.32)',
        paddingRight: 12,
        paddingLeft: 16,
    },
    toggleTrack: {
        backgroundColor: '#e45d33 !important'
    },
    inactiveToggleTrack: {
        backgroundColor: 'rgba(0, 0, 0, 0.38) !important'
    },
    toggleRoot: {
        marginLeft: 10
    },
});

export default withStyles(styles)(AdminPreviewModal)
// Customizable Area End