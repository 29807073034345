//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React, { Fragment } from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Storage from "../../../framework/src/StorageProvider.web";
import Loader from "../../../components/src/Loader";
import InfoPageBlock from "../../info-page/src/InfoPageBlock";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
import { Formik } from 'formik';
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";


export default class EmailAccountLoginBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.email) && this.state.email != "";
    const isPasswordValid = this.passwordReg.test(this.state.password) && this.state.password != "";
    const isConfirmPasswordValid = this.state.password === this.state.reTypePassword;
    const hideForm = this.state.showPrivacyPolicy || this.state.showTermsConditions;
    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading} />
        {this.state.showPrivacyPolicy && <InfoPageBlock title={"Privacy Policy"} onBack={() => { window.history.pushState(undefined, "Register", "/Register"); this.setState({ showPrivacyPolicy: false }); }} navigation={this.props.navigation} id={this.props.id} />}
        {this.state.showTermsConditions && <InfoPageBlock title={"Terms And Conditions"} onBack={() => { window.history.pushState(undefined, "Register", "/Register"); this.setState({ showTermsConditions: false }); }} navigation={this.props.navigation} id={this.props.id} />}

        <View style={{ height: "100%", width: "100%",flexDirection:"row", display: hideForm ? "none" : "flex" }}>
          <View style={styles.leftContainer}>
            <View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
                <TouchableOpacity {...this.btnHomeProps}>
                  <Text style={[styles.text, { fontWeight: "500", fontSize: 14 }]}>Home</Text>
                </TouchableOpacity>
              </View>
              <Text style={styles.leftTitle}>Let’s Get Started</Text>
              <Text style={[styles.text, { marginTop: 8, fontWeight: "500" }]}>Lorem Ipsum has been the industry's standard dummy text ever since</Text>

              <Formik
                initialValues={{ email: "", password: "", confirmPassword: "", agree: false }}
                onSubmit={(values) => { this.setState({ loading: true }, () => this.createAccount(values)); }}
                validationSchema={this.getFormValidations}
              >
                {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => (
                  <Fragment>

                    <View style={[styles.formRow, { marginTop: 56 }]}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <TextField {...this.txtInputEmailPrpos} name="email" onChange={handleChange} onBlur={() => setFieldTouched('email')} variant={"outlined"} label={"Email Address"} error={!!(errors.email && touched.email)} helperText={touched.email && errors.email} style={styles.plainTextInput} value={values.email} />
                      </View>
                      <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                        {!errors.email && touched.email && <FaCheckCircle size={24} color="green" style={styles.textInputIcon} />}
                      </View>
                    </View>


                    <View style={[styles.formRow]}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <TextField {...this.txtInputPasswordProps} name="password" onChange={handleChange} onBlur={() => setFieldTouched('password')} variant={"outlined"} label={"New Password"} type={this.state.enablePasswordField ? "password" : "text"} error={!!(errors.password && touched.password)}
                          helperText={(touched.password && errors.password) || "Password must contain atleast a capital letter, a lowercase letter, a number and a special character."}
                          style={styles.plainTextInput} value={values.password} />
                      </View>
                      <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                        <TouchableOpacity {...this.btnPasswordShowHideProps} style={{ height: 26 }}>
                          {this.state.enablePasswordField ?
                            <AiFillEye size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                            :
                            <AiFillEyeInvisible size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                          }
                        </TouchableOpacity>
                      </View>
                    </View>


                    <View style={[styles.formRow]}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <TextField {...this.txtInputConfirmPasswordProps} name="confirmPassword" onChange={handleChange} onBlur={() => setFieldTouched('confirmPassword')} variant={"outlined"} label={"Confirm Password"} type={this.state.enableReTypePasswordField ? "password" : "text"} error={!!(errors.confirmPassword && touched.confirmPassword)} helperText={touched.confirmPassword && errors.confirmPassword} style={styles.plainTextInput} value={values.confirmPassword} />
                      </View>
                      <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                        <TouchableOpacity {...this.btnConfirmPasswordShowHideProps} style={{ height: 26 }}>
                          {this.state.enableReTypePasswordField ?
                            <AiFillEye size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                            :
                            <AiFillEyeInvisible size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                          }
                        </TouchableOpacity>
                      </View>
                    </View>



                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 18 }}>
                      <CheckBox value={values.agree} onChange={() => setFieldValue("agree", !values.agree)} />

                      <Text style={[styles.text]}>  I agree to all the <TouchableOpacity {...this.btnLegalTermsAndConditionProps}><Text style={{ color: "#e45d33" }} >terms</Text></TouchableOpacity> & <TouchableOpacity {...this.btnLegalPrivacyPolicyProps}><Text style={{ color: "#e45d33" }} >privacy policy</Text></TouchableOpacity></Text>

                    </View>
                    {errors.agree && touched.agree &&
                      <Text style={[styles.textInputErrorMessage, { position: "relative" }]} >{errors.agree}</Text>
                    }
                    <TouchableOpacity {...this.btnSignUpProps} onPress={() => { handleSubmit() }} style={styles.button}>
                      <Text style={styles.buttonText}>{this.btnTextSignUp}</Text>
                    </TouchableOpacity>

                  </Fragment>
                )}
              </Formik>


              <Text style={[styles.text, { marginTop: 20 }]}>Already have an account? <TouchableOpacity {...this.btnLoginProps}><Text style={{ color: "#e45d33" }} >Log In</Text></TouchableOpacity></Text>
            </View>

            <Text style={[styles.text, { marginTop: 50 }]}>© 2021 RMS. All right Reserved.</Text>

          </View>
          <View style={styles.rightContainer}>
            <ImageBackground source={assets.rightSectionBackgroundImage} style={styles.rightSectionBackgroundImage} >
              <View style={styles.rightContentContainer}>
                <Image source={assets.rightSectionPeopleImage} style={styles.rightSectionPeopleImage} resizeMode={"contain"} />
                <Text style={styles.rightImageTitle}>Lorem Ipsum has been the industry's standard</Text>
                <Text style={styles.rightImageDescription}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Text>

                <View style={{ marginTop: 50 }}>
                  <PaginationIndicator count={3} selected={1} />
                </View>
              </View>
            </ImageBackground>
          </View>
        </View>
      </View>
    );
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 20,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "#ffffff",
    width: "50%",
    minHeight: "100%",
    paddingHorizontal: "5.5%",
    paddingVertical: "3%",
    justifyContent: "space-between"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  leftTitle: {
    fontSize: 30,
    color: "#1a181d",
    marginTop: 50,
    fontWeight: 700
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 28
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "50%",
    minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 18
  },
  inputWrapper: {
    width: "49%"
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);
