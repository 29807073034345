// App.js - WEB
import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import fetcher from './fetcher';
import * as Utilities from '../../framework/src/Utilities';
import { withRouter } from 'react-router-dom';

import SessionChecker from './session-checker';
import { IBlock } from '../../framework/src/IBlock';
import { runEngine } from '../../framework/src/RunEngine';
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { Keyboard } from 'react-native';
import { ToastProvider } from 'react-toast-notifications';
import ScrollToTopButton from 'react-scroll-to-top';

import Storage from '../../framework/src/StorageProvider.web';
const configJson = require('../../framework/src/config');
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import './app.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import FullTeamVisibility from '../../blocks/FullTeamVisibility/src/FullTeamVisibility';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm.web';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole.web';
import RecruiterConsole from '../../blocks/AdminConsole/src/RecruiterConsole.web';
import NaukriRmsIntegration from '../../blocks/NaukriRmsIntegration/src/NaukriRmsIntegration';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachment';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus.web';
import ActivityLog from '../../blocks/CustomisedOrderStatus/src/activityLog.web';
import Attribute from '../../blocks/categoriessubcategories/src/Attribute.web';
import ProfileSource from '../../blocks/categoriessubcategories/src/ProfileSource.web';
import Favourites from '../../blocks/Favourites/src/Favourites';
import Sorting from '../../blocks/sorting/src/Sorting';
import JobManagement from '../../blocks/JobManagement/src/JobManagement.web';
import PostDetailsForm from '../../blocks/JobManagement/src/PostDetialsForm.web';
import SearchStrategy from '../../blocks/JobManagement/src/SearchStrategy.web';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import UserProfileBasicEditBlock from '../../blocks/user-profile-basic/src/UserProfileBasicEditBlock.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import DocumentDistribution from '../../blocks/DocumentDistribution/src/DocumentDistribution';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import Chat from '../../blocks/Chat/src/Chat';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import PeopleManagement from '../../blocks/PeopleManagement/src/PeopleManagement';
import CvresumeCandidateManagement from '../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement';
import Download from '../../blocks/Download/src/Download';
import Settings from '../../blocks/Settings/src/Settings';
import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Notes from '../../blocks/Notes/src/Notes';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder';
import WhatsappIntegration from '../../blocks/WhatsappIntegration/src/WhatsappIntegration';
import CreateDifferentTemplatesForDifferentRoles from '../../blocks/CreateDifferentTemplatesForDifferentRoles/src/CreateDifferentTemplatesForDifferentRoles';
import ShareCalendar from '../../blocks/ShareCalendar/src/ShareCalendar';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import AutomaticReminders from '../../blocks/AutomaticReminders/src/AutomaticReminders';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import ZoomIntegration from '../../blocks/ZoomIntegration/src/ZoomIntegration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Notifications from '../../blocks/Notifications/src/Notifications';
import LinkedinIntegration from '../../blocks/LinkedinIntegration/src/LinkedinIntegration';
import Sms from '../../blocks/Sms/src/Sms';
import UploadMedia from '../../blocks/UploadMedia/src/UploadMedia';
import LiveChatSummary from '../../blocks/LiveChatSummary/src/LiveChatSummary';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import StatisticsReports from '../../blocks/StatisticsReports/src/StatisticsReports';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import Documentation from '../../blocks/Documentation/src/Documentation';
import SaveAsPdf from '../../blocks/SaveAsPdf/src/SaveAsPdf';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import WarrantyManagement from '../../blocks/WarrantyManagement/src/WarrantyManagement';
import JobListing from '../../blocks/JobListing/src/JobListing.web';
import JobListingEngine from '../../blocks/JobListing/src/JobListingEngine.web';
import JobListingFullJob from '../../blocks/JobListing/src/JobListingFullJob.web';
import JobRequirements from '../../blocks/JobListing/src/JobRequirements.web';
import PdfDemo from '../../blocks/JobManagement/src/JobPostModal/PdfDemo.web';
import Company from '../../blocks/categoriessubcategories/src/Company.web';
import Skill from '../../blocks/categoriessubcategories/src/Skill.web';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions.web';
const routeMap = {
  Skill: {
    component: Skill,
    path: '/admin/system-management/skill',
    exact: true
  },
  Company: {
    component: Company,
    path: '/admin/system-management/company',
    exact: true
  },

  PdfDemo: {
    component: PdfDemo,
    path: '/PdfDemo'
  },
  FullTeamVisibility: {
    component: FullTeamVisibility,
    path: '/FullTeamVisibility'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/admin/manage-users/admin'
  },
  RecruiterConsole: {
    component: AdminConsole,
    path: '/admin/manage-users/recruiter'
  },
  NaukriRmsIntegration: {
    component: NaukriRmsIntegration,
    path: '/NaukriRmsIntegration'
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/admin/system-management/status',
    exact: true
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/admin/system-management/status/activity-log',
    exact: true
  },
  ProfileSource: {
    component: ProfileSource,
    path: '/admin/system-management/profile-source',
    exact: true
  },
  Attribute: {
    component: Attribute,
    path: '/admin/system-management/attribute',
    exact: true
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  JobManagement: {
    component: JobManagement,
    path: '/JobManagement'
  },
  PostDetails: {
    component: PostDetailsForm,
    path: '/PostDetailsForm'
  },
  SearchStrategy: {
    component: SearchStrategy,
    path: '/SearchStrategy'
  },
  UserProfileBasicEditBlock: {
    component: UserProfileBasicEditBlock,
    path: '/Profile/Edit',
    exact: true
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/Profile',
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/Register'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  DocumentDistribution: {
    component: DocumentDistribution,
    path: '/DocumentDistribution'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: '/PeopleManagement'
  },
  CvresumeCandidateManagement: {
    component: CvresumeCandidateManagement,
    path: '/CvresumeCandidateManagement'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Notes: {
    component: Notes,
    path: '/Notes'
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder'
  },
  WhatsappIntegration: {
    component: WhatsappIntegration,
    path: '/WhatsappIntegration'
  },
  CreateDifferentTemplatesForDifferentRoles: {
    component: CreateDifferentTemplatesForDifferentRoles,
    path: '/CreateDifferentTemplatesForDifferentRoles'
  },
  ShareCalendar: {
    component: ShareCalendar,
    path: '/ShareCalendar'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: '/AutomaticReminders'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  ZoomIntegration: {
    component: ZoomIntegration,
    path: '/ZoomIntegration'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  LinkedinIntegration: {
    component: LinkedinIntegration,
    path: '/LinkedinIntegration'
  },
  Sms: {
    component: Sms,
    path: '/Sms'
  },
  UploadMedia: {
    component: UploadMedia,
    path: '/UploadMedia'
  },
  LiveChatSummary: {
    component: LiveChatSummary,
    path: '/LiveChatSummary'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  StatisticsReports: {
    component: StatisticsReports,
    path: '/StatisticsReports'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  // Dashboard: {
  //   component: Dashboard,
  //   path: '/Dashboard'
  // },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/Login'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: '/SaveAsPdf'
  },
  TwofactorAuthentication: {
    component: TwofactorAuthentication,
    path: '/TwofactorAuthentication'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  WarrantyManagement: {
    component: WarrantyManagement,
    path: '/WarrantyManagement'
  },
  JobListingFullJob: {
    component: JobListingFullJob,
    path: '/JobListing/:jobid',
    exact: true
  },
  JobListing: {
    component: JobListing,
    path: '/JobListing',
    exact: true
  },
  JobListingEngine: {
    component: JobListingEngine,
    path: '/JobListingEngine',
    exact: true
  },
  JobRequirements: {
    component: JobRequirements,
    path: '/JobRequirements',
    exact: true
  },
  Home: {
    component: Dashboard /*HomeScreen*/,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  TermsAndConditions: {
    component: InfoPage,
    path: '/Terms And Conditions'
  },
  PrivacyPolicy: {
    component: InfoPage,
    path: '/Privacy Policy'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  componentDidMount() {
    Dimensions.addEventListener('change', () => this.forceUpdate());
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', () => this.forceUpdate());
  }

  render() {
    const showTopNav = ![
      '/Login',
      '/Login/',
      '/Login/AlertWeb',
      '/login',
      '/login/',
      '/login/AlertWeb',
      '/Register',
      '/Register/',
      '/Register/AlertWeb',
      '/register',
      '/register/',
      '/register/AlertWeb',
      '/ForgotPassword',
      '/ForgotPassword/',
      '/ForgotPassword/AlertWeb',
      '/NewPassword',
      '/NewPassword/',
      '/NewPassword/AlertWeb',
      '/AdditionalDetailForm',
      '/AdditionalDetailForm/',
      '/AdditionalDetailForm/AlertWeb',
      '/InfoPage',
      '/InfoPage/',
      '/InfoPage/AlertWeb',
      '/Terms%20And%20Conditions',
      '/Terms%20And%20Conditions/',
      '/Terms%20And%20Conditions/AlertWeb',
      '/Privacy%20Policy',
      '/Privacy%20Policy/',
      '/Privacy%20Policy/AlertWeb',
      '/JobListingEngine',
      '/JobListingEngine/',
      '/JobListingEngine/AlertWeb'
    ].includes(window.location.pathname);

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        <ToastProvider autoDismissTimeout={10000} autoDismiss={true}>
          <SessionChecker root={this} />
          {showTopNav && <TopNav />}
          {WebRoutesGenerator({ routeMap })}
          <AlertBlock root={this} />
          <ModalContainer />
          <ScrollToTopButton smooth top={600} />
        </ToastProvider>
      </View>
    );
  }
}

export default App;

function DummyPage(props) {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      This is {props.name || 'Home'} page.
    </div>
  );
}
