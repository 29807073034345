import React, { Fragment, useEffect, useState, useRef } from 'react';
// Customizable Area Start
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';

import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import TextField from '@material-ui/core/TextField';

import { Formik } from 'formik';
//@ts-ignore
import classnames from "classnames";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { FaTimes, FaTimesCircle, FaPlus } from "react-icons/fa";
import { BsEyeSlash, BsThreeDots, BsCaretDownFill, BsDot, BsBriefcase, BsFilter } from "react-icons/bs";
import { BiShareAlt, BiCaretDown, BiRupee, BiMessage, BiBarChartAlt2 } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import { GiBackwardTime } from "react-icons/gi";
import { HiOutlineUser, HiLocationMarker } from "react-icons/hi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";



import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
//@ts-ignore
import _ from "lodash";

//@ts-ignore
import BarChart from "./bar-chart"

import "./style.css";
// Customizable Area End

import JobRequirementsController, {
  Props,
  configJSON
} from "./JobRequirementsController";

export default class JobListingFullJob extends JobRequirementsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

    this.setState({ loading: true }, () => {
      this.getJobRequirements();
    });
  }
  // Customizable Area End

  render() {

    const { navigation } = this.props;

    const styles = getStyles();
    const assets = require("./assets");


    return (
      <Fragment>

        <View style={styles.container}>

          <View style={styles.mainColumn}>
            <View style={{ minHeight: 75, backgroundColor: "whitesmoke", flexDirection: "row", padding: 16 }}>
              <View style={{ width: "70%" }} >
                <Text style={{ fontSize: 24 }}>List of Requirements</Text>
                <Text style={{ fontSize: 11, color: "gray", marginTop: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Text>

              </View>
              <View style={{ justifyContent: "flex-end", alignItems: "center", width: "30%", flexDirection: "row" }}>

                <View style={{ justifyContent: "center", width: "80%", backgroundColor: "white" }} >
                  <View style={{ justifyContent: "center", width: "100%", backgroundColor: "white" }} >
                    <TouchableOpacity onPress={() => this.getJobRequirements()} style={[styles.widgetSearchInputIcon, { zIndex: 1 }]}>
                      <AiOutlineSearch />
                    </TouchableOpacity>
                    <TextField style={styles.widgetSearchInput}
                      name="job_title" onChange={(e: any) => {
                        /*
                        if (e.target.value != "") {
                          this.getJobTitlesTags(e.target.value);
                        }*/
                        this.setState({ jobTitleSearch: e.target.value, showTitleTags: true }, () => {
                          this.getJobRequirements();
                        });
                      }}
                      variant={"outlined"} label={"Search Job Title"} value={this.state.jobTitleSearch} size={"small"} />
                  </View>

                  {this.state.showTitleTags && this.state.jobTitles.find((itm: string) => itm.toLowerCase().indexOf(this.state.jobTitleSearch.toLowerCase()) > -1) && this.state.jobTitleSearch != "" &&
                    <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="titleSearchTags">

                      {this.state.jobTitles.filter((itm: string) => itm.toLowerCase().indexOf(this.state.jobTitleSearch.toLowerCase()) > -1 || this.state.jobTitleSearch === "").map((item: string, index: number) => (
                        <div onClick={(e: any) => {
                          if (!this.state.selectedJobTitles.includes(item)) {
                            this.setState({ selectedJobTitles: [...this.state.selectedJobTitles, item], showTitleTags: false, loading: true }, () => {
                              this.getJobRequirements();
                            })
                          }
                        }} className="skill-item">{item}</div>
                      ))}
                    </div>
                  }
                </View>

                <View style={{ paddingLeft: 20 }}>
                  <TouchableOpacity onPress={(e) => this.onOpenFilter(e)}>
                    <BsFilter style={{ fontSize: 28 }} />
                  </TouchableOpacity>
                  <Menu
                    id="simple-menu"
                    open={this.state.showFilter}
                    onClose={(e) => this.onCloseFilter(e)}
                    anchorEl={this.state.anchorEl}
                  >
                    <MenuItem onClick={(e) => this.onCloseFilter(e)}>Profile</MenuItem>
                    <MenuItem onClick={(e) => this.onCloseFilter(e)}>My account</MenuItem>
                    <MenuItem onClick={(e) => this.onCloseFilter(e)}>Logout</MenuItem>
                  </Menu>
                </View>

              </View>
            </View>

            <View style={{ height: 50, backgroundColor: "white", paddingHorizontal: 16, justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <CheckBox value={true} style={{ marginRight: 10 }} />
                <Text style={{ fontSize: 12, color: "gray", marginTop: 2 }}>Search Selected Requirements  |  Set Visiblity</Text>
              </View>
              <TouchableOpacity onPress={() => this.onExpandAll()}>
                <Text style={{ fontSize: 12, marginTop: 2, color: "#e45d33" }}>Expand All</Text>
              </TouchableOpacity>
            </View>


            <View style={{ borderTopWidth: 1, borderBottomWidth: 1, borderColor: "silver" }}>


              {!(this.state.requirements.length > 0) &&
                <View style={{ alignItems: "center", paddingVertical: 20 }}>
                  <Text style={{ fontSize: 16, color: "black" }}>No Requirements Found related to you, try creating some.</Text>
                </View>
              }

              {this.state.requirements.map((item: any, index: number) => index < 10 && (
                <Fragment>
                    <View style={{ height: 1, backgroundColor: "#dddddd", marginLeft: 15, marginRight: 15 }} />
                  
                  <View style={{ flexDirection: "row", paddingVertical: 16, paddingHorizontal: 16 }}>
                    <View>
                      <CheckBox value={true} style={{ marginRight: 10 }} />
                    </View>
                    <View style={{ flex: 0.35 }}>
                      <Text style={{ fontSize: 12, color: "gray", marginTop: 2 }}>#QHS-{item.id}<GoPrimitiveDot style={{ fontSize: 12, verticalAlign: "middle", color: "silver", marginLeft: 5, marginRight: 5 }} />50MIN AGO</Text>
                      <Text style={{ fontSize: 20, color: item.showMore ? "#e45d33" : "#1a181d", marginTop: 2 }}>{item.attributes?.job_title}</Text>
                      <Text style={{ fontSize: 13, color: "gray", marginTop: 2 }}><Text style={{ color: "#e45d33" }}>1 Sources</Text> Created By <Text style={{ color: "black" }}>You</Text> Visible to <Text style={{ color: "#e45d33" }}>1 Recruiters</Text></Text>

                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <TouchableOpacity onPress={(e) => this.onOpenMenu(e, index, "showModifyMenu")}>
                          <Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Modify <BsCaretDownFill style={{ verticalAlign: "sub", marginLeft: 5, marginRight: 15, color: "rgba(0, 0, 0, 0.6)" }} /></Text>
                        </TouchableOpacity>
                        <Menu
                          id="simple-menu-modify"
                          open={this.state.requirements[index].showModifyMenu}
                          onClose={(e) => this.onCloseMenu(e, index, "showModifyMenu")}
                          anchorEl={this.state.anchorEl}
                        >
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showModifyMenu")}>Profile</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showModifyMenu")}>My account</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showModifyMenu")}>Logout</MenuItem>
                        </Menu>

                        <Text style={{ color: "silver", marginRight: 10 }}> | </Text>

                        <TouchableOpacity onPress={(e) => this.onOpenMenu(e, index, "showSearchMenu")}>
                          <Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Search <BsCaretDownFill style={{ verticalAlign: "sub", marginLeft: 5, marginRight: 15, color: "rgba(0, 0, 0, 0.6)" }} /></Text>
                        </TouchableOpacity>
                        <Menu
                          id="simple-menu-search"
                          open={this.state.requirements[index].showSearchMenu}
                          onClose={(e) => this.onCloseMenu(e, index, "showSearchMenu")}
                          anchorEl={this.state.anchorEl}
                        >
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showSearchMenu")}>Profile</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showSearchMenu")}>My account</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showSearchMenu")}>Logout</MenuItem>
                        </Menu>

                        <Text style={{ color: "silver", marginRight: 10 }}> | </Text>

                        <TouchableOpacity onPress={(e) => this.onOpenMenu(e, index, "showOpenMenu")}>
                          <Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Open <BsCaretDownFill style={{ verticalAlign: "sub", marginLeft: 5, marginRight: 15, color: "rgba(0, 0, 0, 0.6)" }} /></Text>
                        </TouchableOpacity>
                        <Menu
                          id="simple-menu-open"
                          open={this.state.requirements[index].showOpenMenu}
                          onClose={(e) => this.onCloseMenu(e, index, "showOpenMenu")}
                          anchorEl={this.state.anchorEl}
                        >
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showOpenMenu")}>Profile</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showOpenMenu")}>My account</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showOpenMenu")}>Logout</MenuItem>
                        </Menu>

                        <Text style={{ color: "silver", marginRight: 10 }}> | </Text>

                        <TouchableOpacity onPress={(e) => this.onOpenMenu(e, index, "showActionMenu")}>
                          <Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>More Action <BsCaretDownFill style={{ verticalAlign: "sub", marginLeft: 5, marginRight: 15, color: "rgba(0, 0, 0, 0.6)" }} /></Text>
                        </TouchableOpacity>
                        <Menu
                          id="simple-menu-action"
                          open={this.state.requirements[index].showActionMenu}
                          onClose={(e) => this.onCloseMenu(e, index, "showActionMenu")}
                          anchorEl={this.state.anchorEl}
                        >
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showActionMenu")}>Profile</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showActionMenu")}>My account</MenuItem>
                          <MenuItem onClick={(e) => this.onCloseMenu(e, index, "showActionMenu")}>Logout</MenuItem>
                        </Menu>

                        <Text style={{ color: "silver", marginRight: 10 }}> | </Text>

                        <span style={{ color: "rgba(0, 0, 0, 0.6)", whiteSpace: "nowrap" }}><BiMessage style={{ verticalAlign: "sub", marginLeft: 5, color: "rgba(0, 0, 0, 0.6)", fontSize: 18 }} /> 52</span>


                        <Text style={{ color: "silver", marginHorizontal: 10 }}> | </Text>

                        <BiBarChartAlt2 style={{ verticalAlign: "sub", marginLeft: 5, marginRight: 15, color: "rgba(0, 0, 0, 0.6)", fontSize: 18 }} />


                      </View>
                    </View>
                    <View style={{ flex: 0.65, height: 100, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

                      <View style={{ height: 1, width: 100 }}></View>
                      <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)", marginTop: 2 }}><HiOutlineUser style={{ verticalAlign: "sub", fontSize: 16 }} />  by {item.attributes?.account?.first_name} {item.attributes?.account?.last_name}</Text>

                      <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)", marginTop: 2 }}><HiLocationMarker style={{ verticalAlign: "sub", fontSize: 16 }} />  {!!item.attributes?.job_location_details ? Object.values(item.attributes?.job_location_details).filter((item: any) => !!item).reverse().join(", ") : "-"}</Text>

                      <TouchableOpacity onPress={() => this.onToggleMore(index)}>
                        <Text style={{ fontSize: 12, color: "#1a181d", marginTop: 2, fontWeight: "500" }}>400 Applicants  {item.showMore ? <RiArrowUpSLine style={{ verticalAlign: "sub", fontSize: 18 }} /> : <RiArrowDownSLine style={{ verticalAlign: "sub", fontSize: 18 }} />}</Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  {item.showMore &&
                    <Fragment>
                      <View style={{ flexDirection: "row", minHeight: 100, backgroundColor: "whitesmoke" }}>
                        <BarChart data={[
                          { name: "Prospect", value: 1000 },
                          { name: "Applied-Not Matching", value: 25 },
                          { name: "Rejected  - Prospect", value: 200 }
                        ]} />

                      </View>



                      <View style={{ backgroundColor: "whitesmoke", marginTop: 10, padding: 15 }}>
                        <View style={{ justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                          <Text style={{ fontSize: 14, color: "black" }}>Requirement posted as</Text>
                          <TouchableOpacity>
                            <FaTimes style={{ fontSize: 14, color: "black" }} />
                          </TouchableOpacity>
                        </View>

                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "15%", height: "100%" }}>
                            <Text style={{ fontSize: 13, color: "rgba(0, 0, 0, 0.6)", marginTop: 8 }}>RMS JOBS</Text>

                          </View>
                          <View style={{ width: "60%", height: "100%" }}>
                            <Text style={{ fontSize: 13, color: "#e45d33", marginTop: 8 }}>CFO_Temasek_Kolkata</Text>
                            <Text style={{ fontSize: 12, color: "#1a181d", marginTop: 8 }}>
                              <TouchableOpacity>
                                <Text>View </Text>
                              </TouchableOpacity> |
                              <TouchableOpacity>
                                <Text>  Remove </Text>
                              </TouchableOpacity> |
                              <TouchableOpacity>
                                <Text>  Create Employee Referral </Text>
                              </TouchableOpacity> |
                              <TouchableOpacity>
                                <Text>  Edit Job </Text>
                              </TouchableOpacity>
                            </Text>
                            <Text style={{ fontSize: 12, color: "#1a181d", marginTop: 8 }}>Posted by {item.attributes?.account?.last_name} on 22-03-2021</Text>

                          </View>
                          <View style={{ width: "25%", height: "100%", justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <Text style={{ fontSize: 13, color: "rgba(0, 0, 0, 0.87)", marginTop: 10, fontWeight: "500" }}>400 Applicants</Text>

                          </View>
                        </View>
                      </View>

                    </Fragment>
                  }

                </Fragment>
              ))}

            </View>


            <View style={{ height: 50, backgroundColor: "white", paddingHorizontal: 16, justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <CheckBox value={true} style={{ marginRight: 10 }} />
                <Text style={{ fontSize: 12, color: "gray", marginTop: 2 }}>Search Selected Requirements  |  Set Visiblity</Text>
              </View>
            </View>

          </View>

        </View>
      </Fragment>
    );
  }
}

// Customizable Area Start

// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  mainColumn: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "silver",
    borderRadius: 5
  },
  leftColumn: {
    width: "30%",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "silver",
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    paddingHorizontal: "5%",
    paddingVertical: 18,
    marginBottom: 2
  },
  widgetHead: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  widgetTitle: {
    fontSize: 14,
    color: "black",
    fontWeight: 500
  },
  widgetClear: {
    fontSize: 13,
    color: "silver",
    fontWeight: 500
  },
  widgetSearchInput: {
    width: "100%"
  },
  widgetSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0
  },
  jobSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0,
    width: "50%"
  },
  keySkillsBlock: {
    marginTop: 8
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 7,
    marginTop: 5
  },
  tagTimesIcon: {
    height: "100%",
    verticalAlign: "sub",
    marginLeft: 8
  },
  rightImage: {
    width: "100%",
    height: 210,
    marginTop: 40
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  text: {
    fontSize: 12,
    color: "black",
    lineHeight: 27
  },
  candidateItemContainer: {
    marginTop: 20,
    width: "25%"
  },
  candidateTitleText: {
    color: "rgba(65, 65, 65, 0.87)",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
  },
  candidateDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11,
    marginTop: 5,
    letterSpacing: 0.25
  },
  grayTitleText: {
    color: "black",
    fontSize: 14,
    lineHeight: 28
  },
  grayText: {
    color: "rgba(65, 65, 65, 0.87)",
    fontSize: 10,
    lineHeight: 22
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  626
  maxWidth(626, {
  }),
  // below  576
  maxWidth(576, {
  }),
);
// Customizable Area End
