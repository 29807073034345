export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const editIcon = require("../assets/edit_icon.png");
export const hideIcon = require("../assets/hide_icon.png");
export const showIcon = require("../assets/show_icon.png");
export const dragIcon = require("../assets/drag_icon.png");
