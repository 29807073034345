Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.companyApiContentType = "multipart/form-data";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.deleteCompanyAPIEndPoint = "companies?ids=";
exports.createNewCompanyAPIEndPoint = "companies";
exports.getOneCompanyAPIEndPoint = "companies/";
exports.updateCompanyAPIEndPoint = "companies/";

exports.skillApiContentType = "application/json";
exports.deleteSkillAPIEndPoint = "skills?ids=";
exports.createNewSkillAPIEndPoint = "skills";
exports.getOneSkillAPIEndPoint = "skills/";
exports.updateSkillAPIEndPoint = "skills/";

exports.profileSourceListApiContentType = "application/json";
exports.profileSourceListApiMethod = "GET";
exports.profileSourceListApiEndPoint = "profile_sources";

exports.profileSourceDeleteApiContentType = "application/json";
exports.profileSourceDeleteApiMethod = "Delete";
exports.profileSourceDeleteApiEndPoint = "profile_sources";

exports.profileSourceUpdateApiContentType = "application/json";
exports.profileSourceUpdateApiMethod = "PUT";
exports.profileSourceUpdateApiEndPoint = "profile_sources/";

exports.profileSourceCreateApiContentType = "application/json";
exports.profileSourceCreateApiMethod = "POST";
exports.profileSourceCreateApiEndPoint = "profile_sources";

exports.attributeListApiContentType = "application/json";
exports.attributeListApiMethod = "GET";
exports.attributeListApiEndPoint = "reference_attributes";

exports.attributeUpdateApiContentType = "application/json";
exports.attributeUpdateApiMethod = "PUT";
exports.attributeUpdateApiEndPoint = "reference_attributes";

exports.attributeCreateApiContentType = "application/json";
exports.attributeCreateApiMethod = "POST";
exports.attributeCreateApiEndPoint = "reference_attributes";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

// Customizable Area End
