import React, { Fragment, useEffect, useState, useRef } from 'react';
// Customizable Area Start
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';

import Loader from "../../../components/src/Loader";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import TextField from '@material-ui/core/TextField';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, convertFromRaw } from "draft-js";
import { Formik } from 'formik';
//@ts-ignore
import classnames from "classnames";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { FaTimes, FaTimesCircle, FaPlus } from "react-icons/fa";
import { BsEye, BsEyeSlash, BsThreeDots, BsCaretDownFill, BsCaretRightFill, BsDot, BsBriefcase } from "react-icons/bs";
import { BiShareAlt, BiCaretDown, BiRupee } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import { GiBackwardTime } from "react-icons/gi";
import parseHtml from "html-react-parser";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import Collapse from '@material-ui/core/Collapse';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

//@ts-ignore
import _ from "lodash";

import { rightImage, companyAvatar } from './assets'
import "./style.css";
// Customizable Area End

import JobListingController, {
  Props,
  configJSON
} from "./JobListingController";

export default class JobListing extends JobListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.hideSkillBox = this.hideSkillBox.bind(this);
    this.isEndReached = this.isEndReached.bind(this);
  }

  // Customizable Area Start
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getJobTitlesTags();
    this.getJobLocationsTags();
    this.getJobSkillsTags();

    this.setState({ loading: true, page: 1 }, () => {
      this.getJobListing();
    });

    window.document.addEventListener("click", this.hideSkillBox);
    window.document.addEventListener("scroll", this.isEndReached);



  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.document.removeEventListener("click", this.hideSkillBox);
    window.document.removeEventListener("scroll", this.isEndReached);
  }



  isEndReached(element: any) {
    if ((window.innerHeight + window.scrollY) >= window.document.body.scrollHeight) {
      console.log("you're at the bottom of the page");
      if (this.state.pagination.next_page != null) {
        this.setState({ loading: true, page: this.state.page + 1 }, () => {
          this.getJobListingPagination();
        })
      }
    }
  }


  hideSkillBox(e: any) {
    if (e.target.id !== "titleSearchTags") {
      this.setState({ showTitleTags: false });
    }
    if (e.target.id !== "locationSearchTags") {
      this.setState({ showLocationTags: false });
    }
    if (e.target.id !== "skillSearchTags") {
      this.setState({ showSkillTags: false });
    }
  }


  // Customizable Area End

  render() {

    const { navigation } = this.props;

    const styles = getStyles();
    const assets = require("./assets");

    const selectedCount = this.state.selectedJobs.length;

    const jobTitleFilterResults = this.state.jobTitles.filter((itm: string) => !this.state.selectedJobTitles.includes(itm) && (itm.toLowerCase().indexOf(this.state.jobTitleSearch.toLowerCase()) > -1 || this.state.jobTitleSearch === ""));
    const locationFilterResults = this.state.jobLocations.filter((itm: string) => !this.state.selectedJobLocations.includes(itm) && (itm.toLowerCase().indexOf(this.state.jobLocationSearch.toLowerCase()) > -1 || this.state.jobLocationSearch === ""));
    const skillFilterResults = this.state.jobSkills.filter((itm: string) => !this.state.selectedJobSkills.includes(itm) && (itm.toLowerCase().indexOf(this.state.jobSkillSearch.toLowerCase()) > -1 || this.state.jobSkillSearch === ""));


    console.log("joblisting ", this.state.jobListingData);

    function convertToJson(data: string) {
      var a = "";
      try {
        a = JSON.parse(data);
      } catch (e) {
        a = data;
      }

      return a;
    }

    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading} />
        <View style={styles.mainColumn}>
          <View style={[styles.card, { paddingHorizontal: "3%" }]}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "50%" }}>
                <Text style={{ fontSize: 16, color: "black" }}>{this.state.pagination.total_count} Jobs based on Your Desired Preferences</Text>
                {this.state.selectedJobLocations.length > 0 &&
                  <Text style={{ fontSize: 11, color: "black" }}><HiOutlineLocationMarker /> {this.state.selectedJobLocations.join(", ")}</Text>
                }
              </View>

              <View style={{ width: "50%", alignItems: "flex-end" }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: 11, color: "black", marginRight: 12 }}>You can select upto 5 jobs to apply</Text>
                  {selectedCount > 0 &&
                    <TouchableOpacity onPress={() => this.applyMultipleJobs()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>Apply {selectedCount} Now</Text></TouchableOpacity>
                  }
                </View>
                <View style={{ justifyContent: "center", marginTop: 18, width: "75%" }} >
                  <TouchableOpacity style={[styles.widgetSearchInputIcon, { zIndex: 1 }]} onPress={() => {
                    this.setState({ loading: true, page: 1 }, () => {
                      this.getJobListing();
                    });
                  }} >
                    <AiOutlineSearch />
                  </TouchableOpacity>
                  <TextField className={"jobsearch"} onChange={(e) => {
                    this.setState({ searchText: e.target.value, page: 1 }, () => {
                      this.getJobListing();
                    });

                  }} variant={"outlined"} label={"Search within Results"} value={this.state.searchText} size={"small"} />
                </View>
              </View>
            </View>

            <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke", marginVertical: 16 }} />


            {!(this.state.jobListingData.length > 0) &&
              <View style={{ alignItems: "center", paddingVertical: 20 }}>
                <Text style={{ fontSize: 16, color: "black" }}>No Jobs Found related to your search, try clearing filters.</Text>
              </View>
            }


            {Array.isArray(this.state.jobListingData) && this.state.jobListingData.map((item: any, index: number) => (
              <Fragment>

                <View style={{ flexDirection: "row" }} >
                  <View style={{ minHeight: 100, width: "12%" }}>
                    {!(this.state.appliedJobs.find(itm => itm.attributes?.job_details?.id === Number.parseInt(item.id))) &&
                      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <CheckBox value={this.state.selectedJobs.includes(item.id)} onChange={() => {
                          if (this.state.selectedJobs.includes(item.id)) {
                            this.setState({ selectedJobs: this.state.selectedJobs.filter((itm: any) => itm !== item.id) });
                          }
                          else if (this.state.selectedJobs.length < 5) {
                            this.setState({ selectedJobs: [...this.state.selectedJobs, item.id] });
                          } else {
                            this.showAlert("", "You can apply max 5 posts at one time", "OK")
                          }
                        }} />
                        <TouchableOpacity onPress={() => navigation.navigate("JobListingFullJob", { jobid: item.id })}>
                          <img src={companyAvatar} style={{ height: 50, width: 50, backgroundColor: "white", borderRadius: 50, marginRight: 10 }} />
                        </TouchableOpacity>
                      </View>
                    }
                  </View>
                  <View style={{ minHeight: 100, width: "68%" }}>

                    <TouchableOpacity onPress={() => navigation.navigate("JobListingFullJob", { jobid: item.id })}>
                      <Text style={styles.widgetTitle}>{item.attributes?.job_title} <Text style={{ borderRadius: 5, backgroundColor: "whitesmoke", padding: 3, fontSize: 11, marginLeft: 12 }}><GoPrimitiveDot style={{ fontSize: 12, verticalAlign: "middle" }} /> {_.capitalize((item.attributes?.employment_type ?? "_").replace(/_/g, " "))}</Text></Text>
                    </TouchableOpacity>
                    <Text style={styles.text}>{item.attributes?.companies?.length > 0 ? item.attributes?.companies.map((item: any) => item.name).join(", ") : "-"}</Text>
                    <Text style={styles.text}><BsBriefcase style={{ fontSize: 16, verticalAlign: "middle" }} /> {item.attributes?.work_experience_min} - {item.attributes?.work_experience_max} Years    <BiRupee style={{ fontSize: 16, verticalAlign: "middle" }} /> {(item.attributes?.min_salary || item.attributes?.max_salary) ? `${item.attributes?.min_salary} ${item.attributes?.currency?.toUpperCase()} - ${item.attributes?.max_salary} ${item.attributes?.currency?.toUpperCase()}` : "Not Disclosed"}   <HiOutlineLocationMarker style={{ fontSize: 16, verticalAlign: "middle" }} /> {!!item.attributes?.job_location_details ? Object.values(item.attributes?.job_location_details).filter((item: any) => !!item).reverse().join(", ") : "-"}</Text>
                    <Text style={styles.text}>
                      {Array.isArray(item.attributes?.keywords) && item.attributes?.keywords?.map((item: any, index: number) => index === 0 ? item.name : <span><GoPrimitiveDot style={{ fontSize: 12, verticalAlign: "middle", color: "silver", marginLeft: 5, marginRight: 5 }} />{item.name}</span>)}
                    </Text>
                    <Text style={styles.text}>{typeof convertToJson(item.attributes?.job_details) === "object" ? convertFromRaw(JSON.parse(item.attributes?.job_details)).getPlainText() : item.attributes?.job_details}</Text>
                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 22 }}>
                      {this.state.appliedJobs.find(itm => itm.attributes?.job_details?.id === Number.parseInt(item.id)) ?
                        <TouchableOpacity onPress={() => navigation.navigate("JobListingFullJob", { jobid: item.id })} style={styles.button}><Text style={styles.buttonText}>Applied</Text></TouchableOpacity>
                        :
                        <TouchableOpacity onPress={() => this.applyJob(item.id)} style={styles.button}><Text style={styles.buttonText}>APPLY NOW</Text></TouchableOpacity>
                      }
                      <Text style={{ fontSize: 11, color: "black", marginLeft: 22 }}><GiBackwardTime style={{ color: "black", fontSize: 18, verticalAlign: "bottom" }} /> 2 days ago</Text>
                    </View>
                  </View>
                  <View style={{ minHeight: 100, width: "20%" }}>
                    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                      {item.attributes?.visibility ?
                        <TouchableOpacity style={{ padding: 5, marginLeft: 10 }} onPress={() => this.updateJobIdDetails(item.id, { visibility: false })} >
                          <BsEyeSlash style={{ fontSize: 18 }} />
                        </TouchableOpacity>
                        :
                        <TouchableOpacity style={{ padding: 5, marginLeft: 10 }} onPress={() => this.updateJobIdDetails(item.id, { visibility: true })}>
                          <BsEye style={{ fontSize: 18 }} />
                        </TouchableOpacity>
                      }
                      <TouchableOpacity style={{ padding: 5, marginLeft: 10 }} onPress={() => this.showAlert("", <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <EmailShareButton url="#" translate={true}>
                          {/*@ts-ignore*/}
                          <EmailIcon size={32} round={true} style={{ paddingLeft: 10, paddingRight: 10 }} />
                        </EmailShareButton>
                        <LineShareButton url="#" translate={true}>
                          {/*@ts-ignore*/}
                          <LineIcon size={32} round={true} style={{ paddingLeft: 10, paddingRight: 10 }} />
                        </LineShareButton>
                        <FacebookShareButton url="#" translate={true}>
                          {/*@ts-ignore*/}
                          <FacebookIcon size={32} round={true} style={{ paddingLeft: 10, paddingRight: 10 }} />
                        </FacebookShareButton>
                        <TwitterShareButton url="#" translate={true}>
                          {/*@ts-ignore*/}
                          <TwitterIcon size={32} round={true} style={{ paddingLeft: 10, paddingRight: 10 }} />
                        </TwitterShareButton>
                        <WhatsappShareButton url="#" translate={true}>
                          {/*@ts-ignore*/}
                          <WhatsappIcon size={32} round={true} style={{ paddingLeft: 10, paddingRight: 10 }} />
                        </WhatsappShareButton>
                      </div>, "CLOSE")} >
                        <BiShareAlt style={{ fontSize: 18 }} />
                      </TouchableOpacity>
                      <TouchableOpacity style={{ padding: 5, marginLeft: 10 }}>
                        <BsThreeDots style={{ fontSize: 18 }} />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke", marginVertical: 16 }} />

              </Fragment>
            ))}


          </View>
        </View>
        <View style={styles.rightColumn}>
          <View style={[styles.card, { borderBottomEndRadius: 0, borderBottomStartRadius: 0, zIndex: 3 }]}>
            <View style={styles.widgetHead}>
              <Text style={styles.widgetTitle}>Job Titles</Text>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity onPress={() => {
                  this.setState({ jobTitleSearch: "", showTitleTags: false, selectedJobTitles: [], loading: true, page: 1 }, () => {
                    this.getJobListing();
                  })
                }}>
                  <Text style={styles.widgetClear}>CLEAR</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  this.setState({ collapseTitleTags: !this.state.collapseTitleTags });
                }}>
                  {this.state.collapseTitleTags ?
                    <Text style={styles.widgetClear}>    <BsCaretRightFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                    :
                    <Text style={styles.widgetClear}>    <BsCaretDownFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                  }
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <View style={{ justifyContent: "center", marginTop: 12 }} >
                <AiOutlineSearch style={styles.widgetSearchInputIcon} />
                <TextField style={styles.widgetSearchInput}
                  name="job_title" onChange={(e: any) => {
                    /*
                    if (e.target.value != "") {
                      this.getJobTitlesTags(e.target.value);
                    }*/
                    this.setState({ jobTitleSearch: e.target.value, showTitleTags: true });
                  }}
                  variant={"outlined"} label={"Search Job Title"} value={this.state.jobTitleSearch} size={"small"} />
              </View>

              {this.state.showTitleTags &&
                <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="titleSearchTags">

                  {Array.isArray(jobTitleFilterResults) && jobTitleFilterResults.map((item: string, index: number) => (
                    <div onClick={(e: any) => {
                      if (!this.state.selectedJobTitles.includes(item)) {
                        this.setState({ selectedJobTitles: [...this.state.selectedJobTitles, item], jobTitleSearch: "", showTitleTags: false, loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }
                    }} className="skill-item">{item}</div>
                  ))}

                  {!(jobTitleFilterResults.length > 0) &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <span style={{ fontSize: 14, color: "black" }}>No Results Found</span>
                    </div>
                  }
                </div>
              }
            </View>


            <Collapse in={this.state.collapseTitleTags} timeout="auto" unmountOnExit>
              {Array.isArray(this.state.selectedJobTitles) &&
                <View style={[styles.keySkillsBlock, { zIndex: -1 }]}>
                  <View style={styles.keySkillTagsContainer}>
                    {Array.isArray(this.state.selectedJobTitles) && this.state.selectedJobTitles.map((item: string, index: number) => (
                      <Text style={styles.keySkillTag}>{item} <TouchableOpacity style={styles.tagTimesIcon} onPress={(e) => {
                        this.setState({ selectedJobTitles: this.state.selectedJobTitles.filter((itm: string) => itm != item), loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }}><FaTimesCircle style={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 14 }} /></TouchableOpacity></Text>
                    ))}
                  </View>
                </View>
              }
            </Collapse>
          </View>

          <View style={[styles.card, { borderBottomEndRadius: 0, borderBottomStartRadius: 0, zIndex: 2 }]}>
            <View style={styles.widgetHead}>
              <Text style={styles.widgetTitle}>Locations</Text>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity onPress={() => {
                  this.setState({ jobLocationSearch: "", showLocationTags: false, selectedJobLocations: [], loading: true, page: 1 }, () => {
                    this.getJobListing();
                  })
                }}>
                  <Text style={styles.widgetClear}>CLEAR</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  this.setState({ collapseLocationTags: !this.state.collapseLocationTags });
                }}>
                  {this.state.collapseLocationTags ?
                    <Text style={styles.widgetClear}>    <BsCaretRightFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                    :
                    <Text style={styles.widgetClear}>    <BsCaretDownFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                  }
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <View style={{ justifyContent: "center", marginTop: 12 }} >
                <AiOutlineSearch style={styles.widgetSearchInputIcon} />
                <TextField style={styles.widgetSearchInput}
                  name="job_location" onChange={(e: any) => {
                    /*
                    if (e.target.value != "") {
                      this.getJobTitlesTags(e.target.value);
                    }*/
                    this.setState({ jobLocationSearch: e.target.value, showLocationTags: true });
                  }}
                  variant={"outlined"} label={"Search Locations"} value={this.state.jobLocationSearch} size={"small"} />
              </View>

              {this.state.showLocationTags &&
                <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="locationSearchTags">

                  {Array.isArray(locationFilterResults) && locationFilterResults.map((item: string, index: number) => (
                    <div onClick={(e: any) => {
                      if (!this.state.selectedJobLocations.includes(item)) {
                        this.setState({ selectedJobLocations: [...this.state.selectedJobLocations, item], jobLocationSearch: "", showLocationTags: false, loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }
                    }} className="skill-item">{item}</div>
                  ))}


                  {!(locationFilterResults.length > 0) &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <span style={{ fontSize: 14, color: "black" }}>No Results Found</span>
                    </div>
                  }
                </div>
              }
            </View>
            <Collapse in={this.state.collapseLocationTags} timeout="auto" unmountOnExit>
              {Array.isArray(this.state.selectedJobLocations) &&
                <View style={[styles.keySkillsBlock, { zIndex: -1 }]}>
                  <View style={styles.keySkillTagsContainer}>
                    {Array.isArray(this.state.selectedJobLocations) && this.state.selectedJobLocations.map((item: string, index: number) => (
                      <Text style={styles.keySkillTag}>{item} <TouchableOpacity style={styles.tagTimesIcon} onPress={(e) => {
                        this.setState({ selectedJobLocations: this.state.selectedJobLocations.filter((itm: string) => itm != item), loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }}><FaTimesCircle style={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 14 }} /></TouchableOpacity></Text>
                    ))}
                  </View>
                </View>
              }
            </Collapse>
          </View>


          <View style={[styles.card, { borderBottomEndRadius: 0, borderBottomStartRadius: 0, zIndex: 1 }]}>
            <View style={styles.widgetHead}>
              <Text style={styles.widgetTitle}>Skills</Text>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity onPress={() => {
                  this.setState({ jobSkillSearch: "", showSkillTags: false, selectedJobSkills: [], loading: true, page: 1 }, () => {
                    this.getJobListing();
                  })
                }}>
                  <Text style={styles.widgetClear}>CLEAR</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  this.setState({ collapseSkillTags: !this.state.collapseSkillTags });
                }}>
                  {this.state.collapseSkillTags ?
                    <Text style={styles.widgetClear}>    <BsCaretRightFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                    :
                    <Text style={styles.widgetClear}>    <BsCaretDownFill style={{ color: "darkgray", fontSize: 12, verticalAlign: "sub" }} /></Text>
                  }
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <View style={{ justifyContent: "center", marginTop: 12 }} >
                <AiOutlineSearch style={styles.widgetSearchInputIcon} />
                <TextField style={styles.widgetSearchInput}
                  name="job_skill" onChange={(e: any) => {
                    /*
                    if (e.target.value != "") {
                      this.getJobTitlesTags(e.target.value);
                    }*/
                    this.setState({ jobSkillSearch: e.target.value, showSkillTags: true });
                  }}
                  variant={"outlined"} label={"Search Skills"} value={this.state.jobSkillSearch} size={"small"} />
              </View>

              {this.state.showSkillTags &&
                <div style={{ width: "100%", height: 150, backgroundColor: "white", position: "absolute", top: 55, border: "1px solid silver", borderRadius: 5, overflowY: "auto", overflowX: "hidden" }} id="skillSearchTags">

                  {Array.isArray(skillFilterResults) && skillFilterResults.map((item: string, index: number) => (
                    <div onClick={(e: any) => {
                      if (!this.state.selectedJobSkills.includes(item)) {
                        this.setState({ selectedJobSkills: [...this.state.selectedJobSkills, item], jobSkillSearch: "", showSkillTags: false, loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }
                    }} className="skill-item">{item}</div>
                  ))}


                  {!(skillFilterResults.length > 0) &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <span style={{ fontSize: 14, color: "black" }}>No Results Found</span>
                    </div>
                  }
                </div>
              }
            </View>

            <Collapse in={this.state.collapseSkillTags} timeout="auto" unmountOnExit>
              {Array.isArray(this.state.selectedJobSkills) &&
                <View style={[styles.keySkillsBlock, { zIndex: -1 }]}>
                  <View style={styles.keySkillTagsContainer}>
                    {this.state.selectedJobSkills.map((item: string, index: number) => (
                      <Text style={styles.keySkillTag}>{item} <TouchableOpacity style={styles.tagTimesIcon} onPress={(e) => {
                        this.setState({ selectedJobSkills: this.state.selectedJobSkills.filter((itm: string) => itm != item), loading: true, page: 1 }, () => {
                          this.getJobListing();
                        })
                      }}><FaTimesCircle style={{ color: "rgba(0, 0, 0, 0.38)", fontSize: 14 }} /></TouchableOpacity></Text>
                    ))}
                  </View>
                </View>
              }
            </Collapse>
          </View>
          <Image source={assets.rightImage.default} resizeMode={"contain"} style={styles.rightImage} />

        </View>
      </View >
    );
  }
}

// Customizable Area Start

// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  mainColumn: {
    width: "74%",
  },
  rightColumn: {
    width: "24%"
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 5,
    paddingHorizontal: "5%",
    paddingVertical: 18,
    marginBottom: 2
  },
  widgetHead: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  widgetTitle: {
    fontSize: 14,
    color: "black",
    fontWeight: 500
  },
  widgetClear: {
    fontSize: 13,
    color: "silver",
    fontWeight: 500
  },
  widgetSearchInput: {
    width: "100%"
  },
  widgetSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0
  },
  jobSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0,
    width: "50%"
  },
  keySkillsBlock: {
    marginTop: 8
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 7,
    marginTop: 5
  },
  tagTimesIcon: {
    height: "100%",
    verticalAlign: "sub",
    marginLeft: 8
  },
  rightImage: {
    width: "100%",
    height: 210,
    marginTop: 40
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  text: {
    fontSize: 12,
    color: "black",
    lineHeight: 27
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  626
  maxWidth(626, {
  }),
  // below  576
  maxWidth(576, {
  }),
);
// Customizable Area End
