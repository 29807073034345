Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpPostType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.btnExampleTitle = "CLICK ME";

exports.adminListApiContentType = "application/json";
exports.adminListApiMethod = "GET";
exports.adminListApiEndPoint = "account/accounts";

exports.adminDeleteApiContentType = "application/json";
exports.adminDeleteApiMethod = "PUT";
exports.adminDeleteApiEndPoint = "account/accounts/disable";

exports.adminUpdateApiContentType = "application/json";
exports.adminUpdateApiMethod = "PUT";
exports.adminUpdateApiEndPoint = "account/accounts/";

exports.adminCreateApiContentType = "application/json";
exports.adminCreateApiMethod = "POST";
exports.adminCreateApiEndPoint = "account/accounts";

exports.defaultPermissionListApiContentType = "application/json";
exports.defaultPermissionListApiMethod = "GET";
exports.defaultPermissionListApiEndPoint =
  "roles_permissions/default_permissions";

exports.sendEmailApiContentType = "application/json";
exports.sendEmailApiMethod = "GET";
exports.sendEmailApiEndPoint = "account/accounts/";

// Customizable Area End
