import React, { FunctionComponent, ElementType } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    GestureResponderEvent,
    View
} from "react-native";


interface Props {
    count: number,
    selected: number,
    style?: object
}

const PaginationIndicator: FunctionComponent<Props> = (props) => {

    const indicators: Array<any> = [];

    for (let i = 1; i <= props.count; i++) {
        if (props.selected === i) {
            indicators.push(<View style={[styles.dot, styles.dotActive]}></View>);
        } else {
            indicators.push(<View style={styles.dot}></View>);
        }
    }

    return (
        <View style={[styles.container, props.style]}>
            {indicators}
        </View>
    );
};

export default PaginationIndicator;

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "row"
    },
    dot: {
        height: 5,
        width: 5,
        borderWidth: 1,
        borderColor: "white",
        borderRadius: 5,
        backgroundColor: "white",
        margin: 6
    },
    dotActive: {
        width: 40
    }
});
