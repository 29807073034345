//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";
import * as yup from 'yup';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  countryListApiContentType,
  countryListApiMethod,
  countryListApiEndPoint,
  jobDetailApiContentType,
  jobDetailApiMethod,
  jobDetailApiEndPoint,
  courseListApiContentType,
  courseListApiMethod,
  courseListApiEndPoint,
  postGraduationListApiContentType,
  postGraduationListApiMethod,
  postGraduationListApiEndPoint,
  doctorateListApiContentType,
  doctorateListApiMethod,
  doctorateListApiEndPoint,
  keywordListApiContentType,
  keywordListApiMethod,
  keywordListApiEndPoint,
  postDetailFormApiContentType,
  postDetailFormApiMethod,
  postDetailFormApiEndPoint,
  jobDetailsValuesApiContentType,
  jobDetailsValuesApiMethod,
  jobDetailValuesApiEndPoint,
  companiesApiContentType,
  companiesApiMethod,
  companiesApiEndPoint,
  functionalAreaListContentType,
  functionalAreaListEndPoint,
  functionalAreaListMethod,
  industryListApiContentType,
  industryListApiMethod,
  industryListApiEndPoint,
} from "./config";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  onChangeLayout?: (height: number) => void;
  updateStep?: () => void;
  updateNextStep?: () => void;
  backtoForm?: () => void;
  addMoreFiled?: () => void;
  // updateState?: (id: any, attributes: object) => void;
  // Customizable Area Start
  // Customizable Area End
}

interface jobDetailValueObject {
  requirement_title?: string,
  job_title?: string,
  reporting_to?: '',
  team?: string,
  employment_type?: string,
  job_details?: string,
  key_result_areas?: string,
  key_performance_indicators?: string,
  desired_candidate_profile?: string,
  min_salary?: any,
  max_salary?: any,
  other_salary_details?: string,
  work_experience_min?: any,
  work_experience_max?: any,
  keyword_list?: any,
  industry_list?: string,
  visibility?: string,
  recruiter_list?: string
  recruiter_name?: string,
  vacancies?: any,
  reference_code_list?: any,
  job_location_details?: {
    country: string,
    state: string,
    city: string
  },
  currency?: string,
}

interface S {
  // Customizable Area Start
  requrimentTitle: string;
  txtSavedValue: string;
  enableField: boolean;
  sidemenuHeight: any;
  jobDetailInformation: object;
  countries: Array<any>;
  successJobDetailId: any;
  // Customizable Area End
  formStep: number,
  courses: Array<any>;
  postGraduationList: Array<any>;
  doctorateList: Array<any>;
  keywordList: Array<any>;
  jobDetailValue: jobDetailValueObject;
  isAddField: boolean;
  stateList: Array<any>;
  countryCode: string;
  cityList: Array<any>;
  nextStep: boolean;
  isFormLoading: boolean;
  questions: any[];
  location: Array<any>;
  isEdit: boolean;
  companies: Array<any>;
  empName: string;
  emailId: string;
  contactNo: number;
  hiring_for: Array<any>;
  company: string;
  functionalAreaList: Array<any>;
  industryList: Array<any>,
  editorData: any
}

interface SS {
  id: any;
}

export default class JobManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  jobDetailApiCallMessageRequestId: any
  countryListApiCallMessageRequestId: any
  courseListApiCallMessageRequestId: any
  postGraduationListApiCallMessageRequestId: any
  doctorateListApiCallMessageRequestId: any
  keywordListApiCallMessageRequestId: any
  postDetailFormApiCallMessageRequestId: any
  jobDetailValuesApiCallMessageRequestId: any
  updateJobDetailFormApiCallMessageRequestId: any
  companiesApiCallMessageRequestId: any
  functionalAreaListApiCallMessageRequestId: any
  industryListApiCallMessageRequestId: any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      requrimentTitle: '',
      txtSavedValue: "A",
      enableField: false,
      sidemenuHeight: "100%",
      jobDetailInformation: {},
      countries: [],
      successJobDetailId: "",
      formStep: 1,
      courses: [],
      postGraduationList: [],
      doctorateList: [],
      keywordList: [],
      jobDetailValue: {},
      isAddField: false,
      stateList: [],
      countryCode: "",
      cityList: [],
      nextStep: false,
      isFormLoading: false,
      questions: [],
      location: [],
      isEdit: false,
      companies: [],
      empName: "",
      emailId: "",
      contactNo: null,
      hiring_for: [],
      company: "",
      functionalAreaList: [],
      industryList: [],
      editorData: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async updateState(id: any, attributes: object) {
    this.setState({ successJobDetailId: id });
    this.setState({ jobDetailInformation: attributes })
    localStorage.setItem("jobDetailValues", JSON.stringify(attributes))
    localStorage.setItem("id", id)
  }

  async addMoreFiled() {
    this.setState({ isAddField: true })
  }

  onEdit = () => {
    this.setState({ isEdit: true })
  }

  fillQuestion = () => {
    if (this.state.jobDetailValue) {
      this.setState({ questions: this.state.jobDetailValue?.questions })
    }
  }

  addHiringDetails = (values: any) => {
    const email = values.email
    const employer_name = values.employer_name
    const company_name = values.company_name
    const contact = values.contact_no
    const name = this.state.company
    const data = {
      email, employer_name, company_name, contact, name
    }
    const ary = [...this.state.hiring_for, data]
    this.setState({ hiring_for: ary })
    this.setState({ empName: '', emailId: '', contactNo: '', company: '' })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (this.countryListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.countryListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {
        this.setState({ countries: responseJson.data });
      }
    }

    else if (this.jobDetailApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.jobDetailApiCallMessageRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {
        if (this.updateState) {
          this.updateState(responseJson.data.id, responseJson.data.attributes)
        }
        // this.setState({ successJobDetailId: responseJson.data.id });
        // this.setState({ jobDetailInformation: responseJson.data.attributes })
        if (this.state.formStep === 1) {
          this.setState({ formStep: 2 }, () => {
            // console.log('controller', this.state.formStep)
            if (this.props.updateStep) {
              this.props.updateStep();
            }
          })
        }
      }
    }

    else if (this.updateJobDetailFormApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateJobDetailFormApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {
        if (this.updateState) {
          this.updateState(responseJson.data.id, responseJson.data.attributes)
        }

        if (this.state.formStep === 1) {
          this.setState({ formStep: 2 }, () => {
            // console.log('controller', this.state.formStep)
            if (this.props.updateStep) {
              this.props.updateStep();
            }
          })
        }
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    else if (this.courseListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.courseListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)

      if (responseJson) {
        this.setState({ courses: responseJson.data })
      }
    }

    else if (this.postGraduationListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.postGraduationListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)
      if (responseJson) {
        this.setState({ postGraduationList: responseJson.data })
      }
    }

    else if (this.doctorateListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.doctorateListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)

      if (responseJson) {
        this.setState({ doctorateList: responseJson.data })
      }
    }

    else if (this.keywordListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.keywordListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)
      if (responseJson) {
        this.setState({ keywordList: responseJson.data })
      }
    }

    else if (this.postDetailFormApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.postDetailFormApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)
      if (responseJson && !responseJson.errors) {
        //@ts-ignore
        if (this.props.setNextForm) {
          //@ts-ignore
          this.props.setNextForm()
        }
      }
    }

    else if (this.jobDetailValuesApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.jobDetailValuesApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)
      if (responseJson && !responseJson.errors) {
        this.setState({ jobDetailValue: responseJson?.data?.attributes })
        this.fillQuestion()
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }

      this.setState({ isFormLoading: false })
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
    else if (this.companiesApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.companiesApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("errorReponse ", errorReponse)
      if (responseJson) {
        this.setState({ companies: responseJson.data })
      }
    }

    else if (this.functionalAreaListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.functionalAreaListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse);

      if (responseJson) {
        this.setState({ functionalAreaList: responseJson.data })
      }
    }

    else if (this.industryListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.industryListApiCallMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse);

      if (responseJson) {
        this.setState({ industryList: responseJson.data })
      }
    }
  }
  // Customizable Area Start
  txtInputRequrimentTitleWebProps = {
    onChangeText: (text: string) => {
      this.setState({ requrimentTitle: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputRequrimentTitleWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputRequrimentTitleWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.requrimentTitle
    );
    this.send(msg);
  }

  getindustryList = async () => {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": industryListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.industryListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      industryListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      industryListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFunctionalAreas = async () => {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": functionalAreaListContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.functionalAreaListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      functionalAreaListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      functionalAreaListMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompanies = async () => {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": companiesApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companiesApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      companiesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      companiesApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobDetailsValues = async () => {
    const authToken = await Storage.get("authToken");
    const jobDetailId = localStorage.getItem("id")

    const header = {
      "Content-Type": jobDetailsValuesApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobDetailValuesApiCallMessageRequestId = requestMessage.messageId;
    this.setState({ isFormLoading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      jobDetailValuesApiEndPoint + jobDetailId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      jobDetailsValuesApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getKeywordsList() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": keywordListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keywordListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      keywordListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      keywordListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getDoctorateList() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": doctorateListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doctorateListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      doctorateListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      doctorateListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPostGraduationList() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": postGraduationListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postGraduationListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      postGraduationListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      postGraduationListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getCourseList() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": courseListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.courseListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      courseListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      courseListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async getCountryList() {
    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": countryListApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      countryListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      countryListApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async formSubmit(job_detail: any) {

    const authToken = await Storage.get("authToken");

    const header = {
      "Content-Type": jobDetailApiContentType,
      token: authToken
    };
    // localStorage.setItem("jobdetail", job_detail);
    const httpBody = { job_detail: job_detail };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobDetailApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      jobDetailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      jobDetailApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async postDetailFormSubmit(values: any) {

    const jobDetailId = localStorage.getItem("id")
    const jobDetailValues = localStorage.getItem("jobDetailValues")
    if (jobDetailValues) {
      var data = JSON.parse(jobDetailValues)
    }
    const authToken = await Storage.get("authToken");
    const job_detail = { ...data, ...values }
    const httpBody = { job_detail: job_detail }

    const header = {
      "Content-Type": postDetailFormApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDetailFormApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${postDetailFormApiEndPoint}${jobDetailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      postDetailFormApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateJobDetailForm = async (job_detail: any) => {

    const jobDetailId = localStorage.getItem("id")
    const authToken = await Storage.get("authToken");
    const httpBody = { job_detail: job_detail }

    const header = {
      "Content-Type": postDetailFormApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateJobDetailFormApiCallMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${postDetailFormApiEndPoint}${jobDetailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      postDetailFormApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addMoreQuestions = (questions: Array<any>) => {
    this.setState({ questions })
  }

  // Customizable Area End()

  getJobDetailFormValidations() {
    // minVal = minVal.work_experience_min
    const validations = yup.object().shape({
      //   avatar: yup.mixed()
      //     .required("Avatar is required."),*/
      requirement_title: yup.string()
        .min(3, "Minimum 3 characters are required")
        .required("Field is required."),
      job_title: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      employment_type: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      job_details: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      key_result_areas: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      key_performance_indicators: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      desired_candidate_profile: yup.string()
        // .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required."),
      currency: yup.string()
        .required("Field is required."),
      min_salary: yup.number()
        // .min(3, "Minimum 3 characters are required")
        .typeError("Only Numbers are allowed")
        // .matches(/^[a-zA- .moreThan(yup.ref('min_salary'), "max Salary should be > min salary"), .moreThan(yup.ref('min_salary'), "max Salary should be > min salary"),Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required.")
        .lessThan(9999999999, "only ten digits are valid"),
      max_salary: yup.number()
        // .min(3, "Minimum 3 characters are required")
        .typeError("Only Numbers are allowed")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required.")
        .moreThan(yup.ref('min_salary'), "max Salary should be > min salary")
        .lessThan(9999999999, "only ten digits are valid"),
      work_experience_min: yup.number()
        // .min(3, "Minimum 3 characters are required")
        .typeError("Only Numbers are allowed")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required.")
        .min(0, "invalid")
        .lessThan(100, "work experince less than 100"),
      work_experience_max: yup.number()
        // .min(3, "Minimum 3 characters are required")
        .typeError("Only Numbers are allowed")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Field is required.")
        .moreThan(yup.ref('work_experience_min'), "max Experience should be > min Exp.")
        .lessThan(100, "work experince less than 100"),
      keyword_skill_ids: yup.string()
        .required("Field is required."),
      visibility: yup.string()
        .required("Field is required."),
      recruiter_list: yup.string()
        .required("Field is required."),
      vacancies: yup.number()
        .typeError("Only Numbers are allowed")
        .required("Field is required."),
      job_location_details: yup.object().shape({
        country: yup.string().required("Field is required."),
        state: yup.string().required("Field is required."),
        city: yup.string().required("Field is required.")
      })
    });

    return validations;
  }

  getPostDetailFormValidations() {
    const validations = yup.object().shape({
      graduation_id: yup.string()
        .required("Field is required."),
      post_graduation_id: yup.string()
        .required("Field is required."),
      doctorate_id: yup.string()
        .required("Field is required."),
      functional_area_id: yup.string()
        .required("Field is required."),
      industry_id: yup.string()
        .required("Field is required."),
    });
    return validations;
  }
}
