import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import _ from 'lodash';
import Storage from "../../../framework/src/StorageProvider.web";
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import csc, { ICountry, IState, ICity } from 'country-state-city';

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any;
  adminDetails: any;
  showModal: boolean
  closeModalHandler: any
  isEdit?: boolean
  avatarHandler?: any
  deleteAvatarHandler?: any
  errors?: any
  onChangeInputHandler?: any
  onBlurInputHandler?: any
  updatePermissionHandler?: any
  createAdminHandler?: any
  countries?: ICountry[]
  states?: IState[]
  cities?: ICity[]
  adminType?: string
  // changeTextHandler: any
  // showSwitchHandler: any
  // createAndUpdateHandler: any
  // validateProfileSourceName: any
  createUpdateLoading?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  hoveredStatusIndex: string;
  adminList: {
    error: string | null,
    loading: boolean,
    data: any[]
  };
  pagination: {
    perPage: string
    currentPage: string
    totalPages: string
    totalCount: string
  }
  searchKeyword: string
  order: string
  deletedAdmins: string[]
  adminDetails: any
  defaultPermissions: any
  showModal: boolean
  showDeleteConfirmation: boolean
  createUpdateLoading: boolean
  adminType: string
  adminToDelete?: string | null
  showPreviewModal: boolean
  isEdit: boolean
  errors: any
  countries: ICountry[]
  states: IState[]
  cities: ICity[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminConsleController extends BlockComponent<
  Props,
  S,
  SS
> {
  defaultPermissionListApiCallMessageRequestId: any;
  adminListApiCallMessageRequestId: any;
  adminDeleteApiCallMessageRequestId: any;
  adminUpdateApiCallMessageRequestId: any;
  adminCreateApiCallMessageRequestId: any;
  sendEmailApiCallMessageRequestId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      hoveredStatusIndex: "",
      adminList: {
        error: null,
        loading: false,
        data: []
      },
      pagination: {
        perPage: '40',
        currentPage: '0',
        totalPages: '0',
        totalCount: '0'
      },
      searchKeyword: "",
      order: "default",
      deletedAdmins: [],
      adminDetails: null,
      showModal: false,
      showDeleteConfirmation: false,
      createUpdateLoading: false,
      adminType: '',
      defaultPermissions: {},
      showPreviewModal: false,
      isEdit: false,
      errors: {},
      countries: [],
      states: [],
      cities: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (this.defaultPermissionListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.defaultPermissionListApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Default Permission List Response : ', responseJson)

        const defaultPermissionData: any = {};

        const permissionData = responseJson.data

        for (let i = 0; i < permissionData.length; i += 1) {
          defaultPermissionData[permissionData[i].attributes.name] = defaultPermissionData[permissionData[i].attributes.name] || {
            name: permissionData[i].attributes.name,
            id: permissionData[i].attributes.id,
            permissions: {}
          };

          const permissions = permissionData[i].attributes.permissions.data

          for (let j = 0; j < permissions.length; j += 1) {
            defaultPermissionData[permissionData[i].attributes.name].permissions[permissions[j].attributes.feature] = defaultPermissionData[permissionData[i].attributes.name].permissions[permissions[j].attributes.feature] || {
              ...permissions[j].attributes
            };
          }
        }

        this.setState({
          defaultPermissions: defaultPermissionData
        }, () => {
          this.getAdminList({
            page: 1,
            perPage: this.state.pagination.perPage
          })
        })
      } else {
        console.log('Default Permission Error : ', responseJson)
        this.setState({
          adminList: {
            loading: false,
            error: JSON.stringify(responseJson?.errors),
            data: []
          }
        })
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    } else if (this.adminListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.adminListApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Admin List Response : ', responseJson)

        const admins = _.cloneDeep(responseJson.data)

        for (let i = 0; i < admins.length; i++) {
          const permissions: any = {};

          for (let j = 0; j < admins[i].attributes.permissions.data.length; j += 1) {
            permissions[admins[i].attributes.permissions.data[j].attributes.feature] = permissions[admins[i].attributes.permissions.data[j].attributes.feature] || {
              ...admins[i].attributes.permissions.data[j].attributes
            };
          }

          admins[i].permissions = permissions
        }

        this.setState({
          adminList: {
            loading: false,
            error: null,
            data: admins
          },
          pagination: {
            perPage: responseJson.meta.pagination.per_page,
            currentPage: responseJson.meta.pagination.current_page,
            totalPages: responseJson.meta.pagination.total_pages,
            totalCount: responseJson.meta.pagination.total_count
          },
          deletedAdmins: []
        })
      } else {
        console.log('Admin List Error : ', responseJson)
        this.setState({
          adminList: {
            loading: false,
            error: JSON.stringify(responseJson?.errors),
            data: []
          }
        })
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    } else if (this.adminDeleteApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.adminDeleteApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Admin Delete Response : ', responseJson)
        this.getAdminList({
          page: this.state.pagination.currentPage,
          perPage: this.state.pagination.perPage
        })
      } else {
        console.log('Admin Delete Error : ', responseJson)
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    } else if (this.adminUpdateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.adminUpdateApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Admin Update Response : ', responseJson)

        const admins = [...this.state.adminList.data]
        const index = _.findIndex(admins, ['id', responseJson.data.id])

        const permissions: any = {};

        for (let j = 0; j < responseJson.data.attributes.permissions.data.length; j += 1) {
          permissions[responseJson.data.attributes.permissions.data[j].attributes.feature] = permissions[responseJson.data.attributes.permissions.data[j].attributes.feature] || {
            ...responseJson.data.attributes.permissions.data[j].attributes
          };
        }

        admins[index] = { ...responseJson.data, permissions }

        this.setState({
          adminList: {
            ...this.state.adminList,
            data: admins
          },
          createUpdateLoading: false,
          showModal: false,
          adminDetails: {},
        })

      } else {
        console.log('Admin Update Error : ', responseJson)
        const errors: any = {}
        const error = responseJson?.errors[0]

        if (error) {
          errors[Object.keys(error)[0]] = error[Object.keys(error)[0]]
        }

        this.setState({
          createUpdateLoading: false,
          errors
        })
        // this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    } else if (this.adminCreateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.adminCreateApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Admin Create Response : ', responseJson)

        this.getAdminList({
          page: this.state.pagination.currentPage,
          perPage: this.state.pagination.perPage
        })

        this.setState({
          createUpdateLoading: false,
          showModal: false,
          adminDetails: {},
        })
      } else {
        console.log('Admin Create Error : ', responseJson)
        const errors: any = {}
        const error = responseJson?.errors[0]

        if (error) {
          errors[Object.keys(error)[0]] = error[Object.keys(error)[0]]
        }

        console.log('Errors:', errors)

        this.setState({
          createUpdateLoading: false,
          errors
        })
        // this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    } else if (this.sendEmailApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.sendEmailApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        console.log('Send Email Response : ', responseJson)

        this.showAlert('Email sent successfully!', null)

      } else {
        console.log('Send Email Error : ', responseJson)
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  getDefaultPermissionList = async () => {
    try {
      this.setState((prevState: S) => ({
        adminList: {
          ...prevState.adminList,
          loading: true,
        }
      }))

      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.defaultPermissionListApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.defaultPermissionListApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.defaultPermissionListApiEndPoint,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.defaultPermissionListApiMethod,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState((prevState: S) => ({
        adminList: {
          ...prevState.adminList,
          loading: false,
        }
      }))
      console.log(error)
    }
  }

  getAdminList = async (pagination: any, keyword: any = this.state.searchKeyword, order: any = this.state.order) => {
    try {
      this.setState((prevState: S) => ({
        adminList: {
          ...prevState.adminList,
          loading: true,
        }
      }))

      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.adminListApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.adminListApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.adminListApiEndPoint}?section=${this.state.adminType}&page=${pagination.page}&per=${pagination.perPage}&search_keyword=${keyword}${order === 'default' ? '' : `&order_by=first_name&direction=${order}`}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.adminListApiMethod ,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState((prevState: S) => ({
        adminList: {
          ...prevState.adminList,
          loading: false,
        }
      }))
      console.log(error)
    }
  }

  deleteAdmin = async () => {
    try {
      let adminsToDelete = [...this.state.deletedAdmins]

      if (this.state.adminToDelete) {
        adminsToDelete.push(this.state.adminToDelete)
      }

      this.toggleConfirmationModalHandler();

      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.adminDeleteApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.adminDeleteApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.adminDeleteApiEndPoint}?ids=${JSON.stringify(adminsToDelete)}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.adminDeleteApiMethod ,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error)
    }
  }

  updateAdmin = async (admin: any, adminId: string) => {
    try {
      const authToken = await Storage.get("authToken");

      const header = {
        token: authToken
      };

      const formData = new FormData();

      formData.append('data[type]', 'email_account');
      formData.append('data[section]', this.state.adminType);
      if (admin.avatar && typeof admin.avatar !== 'string') {
        formData.append('data[attributes][avatar]', admin.avatar);
      }
      formData.append('data[attributes][first_name]', admin.first_name);
      formData.append('data[attributes][last_name]', admin.last_name);
      formData.append('data[attributes][new_phone_number]', `+91 ${admin.phone_number}`);
      if (admin.alternate_number?.trim()) {
        formData.append('data[attributes][new_alternate_number]', `+91 ${admin.alternate_number}`);
      }
      if (admin.whatsapp_number?.trim()) {
        formData.append('data[attributes][new_whatsapp_number]', `+91 ${admin.whatsapp_number}`);
      }
      formData.append('data[attributes][email]', admin.email);
      formData.append('data[attributes][country]', admin.country);
      formData.append('data[attributes][state]', admin.state);
      formData.append('data[attributes][city_district]', admin.city_district);
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.dashboard.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.dashboard.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.dashboard.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.dashboard.id)
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.requirements.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.requirements.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.requirements.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.requirements.id)
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.profile.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.profile.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.profile.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.profile.id)
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.reports.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.reports.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.reports.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.reports.id)
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.roles_and_permissions.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.roles_and_permissions.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.roles_and_permissions.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.roles_and_permissions.id)
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.manage_user.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.manage_user.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.manage_user.delete_permission)
      formData.append('data[attributes][permissions_attributes][][id]', admin.permissions.manage_user.id)


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.adminUpdateApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.adminUpdateApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.adminUpdateApiEndPoint + adminId ,
      );

      console.log('requestMessage', requestMessage)

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error)
      this.setState({
        createUpdateLoading: false
      })
    }
  }

  createAdmin = async (admin: any) => {
    try {
      const authToken = await Storage.get("authToken");

      const header = {
        token: authToken
      };

      const formData = new FormData();

      formData.append('data[type]', 'email_account');
      formData.append('data[section]', this.state.adminType);
      if (admin.avatar) {
        formData.append('data[attributes][avatar]', admin.avatar);
      }
      formData.append('data[attributes][first_name]', admin.first_name);
      formData.append('data[attributes][last_name]', admin.last_name);
      formData.append('data[attributes][full_phone_number]', `+91 ${admin.phone_number}`);
      formData.append('data[attributes][full_alternate_number]', `+91 ${admin.alternate_number}`);
      formData.append('data[attributes][full_whatsapp_number]', `+91 ${admin.whatsapp_number}`);
      formData.append('data[attributes][email]', admin.email);
      formData.append('data[attributes][country]', admin.country);
      formData.append('data[attributes][state]', admin.state);
      formData.append('data[attributes][city_district]', admin.city_district);
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.dashboard.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.dashboard.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.dashboard.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'dashboard')
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.requirements.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.requirements.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.requirements.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'requirements')
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.profile.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.profile.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.profile.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'profile')
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.reports.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.reports.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.reports.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'reports')
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.roles_and_permissions.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.roles_and_permissions.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.roles_and_permissions.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'roles_and_permissions')
      formData.append('data[attributes][permissions_attributes][][read_permission]', admin.permissions.manage_user.read_permission)
      formData.append('data[attributes][permissions_attributes][][write_permission]', admin.permissions.manage_user.write_permission)
      formData.append('data[attributes][permissions_attributes][][delete_permission]', admin.permissions.manage_user.delete_permission)
      formData.append('data[attributes][permissions_attributes][][feature]', 'manage_user')


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.adminCreateApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.adminCreateApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.adminCreateApiEndPoint,
      );

      console.log('requestMessage', requestMessage)

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error)
      this.setState({
        createUpdateLoading: false
      })
    }
  }

  resendEmail = async (adminId: string) => {
    try {
      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.sendEmailApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sendEmailApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sendEmailApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.sendEmailApiEndPoint}${adminId}/resend_verification_link`,
      );

      console.log('requestMessage', requestMessage)

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error)
    }
  }

  getCountryList = async () => {
    try {
      const countries = csc.getAllCountries()
      this.setState({ countries })
    } catch (error) {
      console.log(error)
    }
  }

  handlePerpageSelect = (e: any) => {
    this.getAdminList({
      page: 1,
      perPage: e.target.value
    })
  }

  handlePagination = (page: any) => {
    this.getAdminList({
      page,
      perPage: this.state.pagination.perPage
    })
  }

  handleSearch = (keyword: any) => {
    this.setState({ searchKeyword: keyword })
    this.getAdminList({
      page: 1,
      perPage: this.state.pagination.perPage
    }, keyword)
  }

  handleOrder = (e: any) => {
    this.setState({ order: e.target.value })
    this.getAdminList({
      page: 1,
      perPage: this.state.pagination.perPage
    }, this.state.searchKeyword, e.target.value)
  }

  handleRowHover = (index: string) => {
    this.setState({ hoveredStatusIndex: index })
  }

  handleAllCheck = () => {
    this.setState((prevState: S) => ({ deletedAdmins: prevState.deletedAdmins.length === prevState.adminList.data.length ? [] : prevState.adminList.data.map(admin => admin.id) }))
  }

  handleCheck = (adminId: string) => {
    const deletedAdmins = [...this.state.deletedAdmins]
    const index = _.indexOf(deletedAdmins, adminId)

    if (index > -1) {
      deletedAdmins.splice(index, 1)
    } else {
      deletedAdmins.push(adminId)
    }

    this.setState({ deletedAdmins })
  }

  openModalHandler = (admin?: any, isEdit: boolean = false) => {
    const { defaultPermissions, adminType } = this.state

    let states: IState[] = []
    let cities: ICity[] = []

    if (admin) {
      const country = _.find(this.state.countries, ['name', admin.attributes.country]) as ICountry
      states = csc.getStatesOfCountry(country.isoCode)

      const state = _.find(states, ['name', admin.attributes.state]) as IState
      cities = csc.getCitiesOfState(state.countryCode, state.isoCode)
    }


    this.setState({
      adminDetails: admin ? { ...admin.attributes, id: admin.id, permissions: admin.permissions } : {
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        alternate_number: "",
        whatsapp_number: "",
        country: "",
        state: "",
        city_district: "",
        avatar: "",
        avatarUrl: "",
        company: "",
        permissions: defaultPermissions[adminType].permissions
      },
      showModal: true,
      errors: {},
      isEdit,
      states,
      cities
    })
  }

  openPreviewModalHandler = (admin: any = {}) => {
    this.setState({ adminDetails: admin, showPreviewModal: true })
  }

  closeModalHandler = () => {
    this.setState({ showModal: false })
  }

  closePreviewModalHandler = () => {
    this.setState({ showPreviewModal: false })
  }

  changeTextHandler = (value: string) => {
    // this.setState({ profileSource: { ...this.state.profileSource, name: value } })
  }

  showSwitchHandler = (value: boolean) => {
    // this.setState({ profileSource: { ...this.state.profileSource, enabled: value } })
  }

  createAndUpdateHandler = (profileSource: any) => {
    this.setState({
      createUpdateLoading: true
    })
    if (profileSource.isEdit) {
      this.updateAdmin({
        "name": profileSource?.name || '',
        "enabled": profileSource?.enabled || false
      }, profileSource.id)
    } else {
      this.createAdmin({
        "name": profileSource?.name || '',
        "enabled": profileSource?.enabled || false
      })
    }
  }

  validateProfileSourceName = () => {
    // let profileSourceList = _.cloneDeep(this.state.profileSourceList.data)
    // profileSourceList = profileSourceList.filter((item: any) => this.state.profileSource?.name ? item.attributes.name.toLowerCase() === this.state.profileSource.name.trim().toLowerCase() : false)
    // return this.state.profileSource?.isEdit ? (profileSourceList.length === 1 && +this.state.profileSource?.id !== +profileSourceList[0].id) : profileSourceList.length === 1
  }

  toggleConfirmationModalHandler = (adminId?: string) => {
    this.setState((prevState: S) => ({
      adminToDelete: !prevState.showDeleteConfirmation ? adminId || null : null,
      showDeleteConfirmation: !prevState.showDeleteConfirmation
    }))
  }

  avatarHandler = (file: any) => {
    const adminDetails = { ...this.state.adminDetails }

    const errors = { ...this.state.errors }

    if (errors.hasOwnProperty('avatar')) {
      delete errors['avatar']
    }

    if (file.size > 10485760) {
      return this.setState({
        errors: {
          avatar: 'File size should be less than 10 MB'
        }
      })
    }

    adminDetails.avatar = file
    adminDetails.avatarUrl = URL.createObjectURL(file)

    this.setState({ adminDetails, errors })
  }

  deleteAvatarHandler = () => {
    const adminDetails = { ...this.state.adminDetails }

    adminDetails.avatar = ''
    adminDetails.avatarUrl = ''

    this.setState({ adminDetails })
  }

  updatePermissionHandler = (feature: string, permission: string, value: boolean) => {

    const adminDetails = _.cloneDeep(this.state.adminDetails)

    const permissions = _.cloneDeep(adminDetails.permissions)

    if (permission === 'read_permission') {
      if (value) {
        permissions[feature].read_permission = value
      } else {
        permissions[feature].read_permission = value
        permissions[feature].write_permission = false
        permissions[feature].delete_permission = false
      }
    }

    if (permission === 'write_permission') {
      if (value) {
        permissions[feature].read_permission = true
        permissions[feature].write_permission = value
      } else {
        permissions[feature].write_permission = value
        permissions[feature].delete_permission = false
      }
    }

    if (permission === 'delete_permission') {
      if (value) {
        permissions[feature].read_permission = true
        permissions[feature].write_permission = true
        permissions[feature].delete_permission = value
      } else {
        permissions[feature].delete_permission = value
      }
    }

    adminDetails.permissions = permissions

    this.setState({ adminDetails })
  }

  onChangeInputHandler = (e: any) => {

    const adminDetails = { ...this.state.adminDetails }

    adminDetails[e?.target?.name] = e?.target?.value

    this.setState({ adminDetails })
  }

  onBlurInputHandler = (e: any) => {
    const adminDetails = { ...this.state.adminDetails }

    const errors = { ...this.state.errors }

    const stateObj: any = {}

    const fieldName = e?.target?.name

    if (fieldName === 'email' && errors.hasOwnProperty('account')) {
      delete errors['account']
    }

    if (errors.hasOwnProperty(fieldName)) {
      delete errors[fieldName]
    }

    if (fieldName === 'first_name' && !adminDetails.first_name.trim()) {
      errors[fieldName] = 'This field is required'
    }

    if (fieldName === 'last_name' && !adminDetails.last_name.trim()) {
      errors[fieldName] = 'This field is required'
    }

    if (fieldName === 'email') {
      if (!adminDetails.email.trim()) {
        errors[fieldName] = 'This field is required'
      } else if (!isEmail(adminDetails.email.trim())) {
        errors[fieldName] = 'Invalid email address'
      }
    }

    if (fieldName === 'phone_number') {
      if (!adminDetails.phone_number.trim()) {
        errors[fieldName] = 'This field is required'
      } else if (!isMobilePhone('+91' + adminDetails.phone_number.trim(), 'any', { strictMode: true })) {
        errors[fieldName] = 'Invalid phone number'
      }
    }

    if (fieldName === 'alternate_number' && adminDetails.alternate_number.trim() && !isMobilePhone('+91' + adminDetails.alternate_number.trim(), 'any', { strictMode: true })) {
      errors[fieldName] = 'Invalid alternate phone number'
    }

    if (fieldName === 'whatsapp_number' && adminDetails.whatsapp_number.trim() && !isMobilePhone('+91' + adminDetails.whatsapp_number.trim(), 'any', { strictMode: true })) {
      errors[fieldName] = 'Invalid whatsapp number'
    }

    if (fieldName === 'country') {
      if (!adminDetails.country.trim()) {
        errors[fieldName] = 'This field is required'

        adminDetails.state = "";
        adminDetails.city_district = "";

        delete errors['state']
        delete errors['city_distict']

        stateObj.states = []
        stateObj.adminDetails = adminDetails
      } else {
        const country = _.find(this.state.countries, ['name', adminDetails.country]) as ICountry
        const states = csc.getStatesOfCountry(country?.isoCode)
        adminDetails.state = "";
        adminDetails.city_district = "";

        stateObj.states = states
        stateObj.adminDetails = adminDetails
      }
    }

    if (fieldName === 'state') {
      if (!adminDetails.state.trim()) {
        errors[fieldName] = 'This field is required'
        adminDetails.city_district = "";
        stateObj.cities = []
        stateObj.adminDetails = adminDetails

        delete errors['city_district']
      } else {
        const state = _.find(this.state.states, ['name', adminDetails.state]) as IState
        const cities = csc.getCitiesOfState(state.countryCode, state.isoCode)
        adminDetails.city_district = "";
        stateObj.cities = cities
        stateObj.adminDetails = adminDetails
      }
    }

    if (fieldName === 'city_district') {
      if (!adminDetails.city_district.trim()) {
        errors[fieldName] = 'This field is required'
      }
    }

    stateObj.errors = errors

    this.setState(stateObj)
  }


  createAdminHandler = () => {
    this.setState({
      createUpdateLoading: true
    })


    const adminDetails = _.cloneDeep(this.state.adminDetails)

    console.log(adminDetails)

    const errors = { ...this.state.errors }

    const stateObj: any = {}

    if (!adminDetails.first_name.trim()) {
      errors['first_name'] = 'This field is required'
    }

    if (!adminDetails.last_name.trim()) {
      errors['last_name'] = 'This field is required'
    }

    if (!adminDetails.email.trim()) {
      errors['email'] = 'This field is required'
    } else if (!isEmail(adminDetails.email.trim())) {
      errors['email'] = 'Invalid email address'
    }

    if (!adminDetails.phone_number.trim()) {
      errors['phone_number'] = 'This field is required'
    } else if (!isMobilePhone('+91' + adminDetails.phone_number.trim(), 'any', { strictMode: true })) {
      errors['phone_number'] = 'Invalid phone number'
    }

    if (adminDetails.alternate_number?.trim() && !isMobilePhone('+91' + adminDetails.alternate_number.trim(), 'any', { strictMode: true })) {
      errors['alternate_number'] = 'Invalid alternate phone number'
    }

    if (adminDetails.whatsapp_number?.trim() && !isMobilePhone('+91' + adminDetails.whatsapp_number.trim(), 'any', { strictMode: true })) {
      errors['whatsapp_number'] = 'Invalid whatsapp number'
    }

    if (!adminDetails.country.trim()) {
      errors['country'] = 'This field is required'
    }

    if (!adminDetails.state.trim() && !errors.hasOwnProperty('country')) {
      errors['state'] = 'This field is required'
    }

    if (!adminDetails.city_district.trim() && !errors.hasOwnProperty('state') && !errors.hasOwnProperty('country')) {
      errors['city_district'] = 'This field is required'
    }

    if (errors.hasOwnProperty('avatar')) {
      delete errors['avatar']
    }

    stateObj.errors = errors

    if (Object.values(errors).length > 0) {
      stateObj.createUpdateLoading = false
      return this.setState(stateObj)
    }

    if (this.state.isEdit) {
      this.updateAdmin(this.state.adminDetails, this.state.adminDetails.id)
    } else {
      this.createAdmin(this.state.adminDetails)
    }

  }

  // Customizable Area End
}
