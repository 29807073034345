import React, { Fragment } from "react";
// Customizable Area Start
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Link, Button, IconButton, Icon, CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { KeyboardBackspace } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import { editIcon, showIcon, hideIcon, dragIcon } from "./assets";
// Customizable Area End

import AttributeController, {
    Props,
    configJSON
} from "./AttributeController";


class AttributeModal extends AttributeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            classes,
            attributes,
            showModal,
            isEdit,
            showNewAttribute,
            createUpdateLoading,
            closeModalHandler,
            changeTextHandler,
            newAttribute,
            createAndUpdateHandler,
            updateIndexHandler,
            updateVisibilityHandler,
            showNewAttributeFieldHandler,
            newAttributeBlurHandler,
            newAttributeChangeHandler,
            validateAttributeName,
        } = this.props

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModal}
                onClose={closeModalHandler}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes: {
                        root: classes.backdrop
                    }
                }}
            >
                <Fade in={showModal}>
                    <Box className={classes.container} >
                        <Grid sm={12} container={true} style={{ display: 'block' }} >
                            <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ height: 60, backgroundColor: "#1a181d" }} >
                                <Box display="flex" alignItems='center' pl='26px' >
                                    <KeyboardBackspace style={{ height: 24, width: 24, marginRight: 18, color: '#ffffff' }} />
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#ffffff'
                                    }} >{`${isEdit ? 'Edit' : 'Create'} Attributes`}</Typography>
                                </Box>
                                <IconButton disabled={createUpdateLoading} onClick={closeModalHandler} style={{ marginRight: 12, }} >
                                    <Icon>
                                        <CloseIcon style={{ height: 24, width: 24, opacity: 0.7, color: '#ffffff' }} />
                                    </Icon>
                                </IconButton>
                            </Grid>
                            <Grid sm={12} container={true} style={{ padding: '0px 24px 38px 24px', flexDirection: 'column' }} >
                                <DragDropContext onDragEnd={updateIndexHandler} >
                                    <Droppable droppableId={'updateAttributeIndex'}>
                                        {(provided, snapshot) => (
                                            <Grid sm={12} container={true}  {...provided.droppableProps} ref={provided.innerRef} style={{ marginTop: '24px', flexDirection: 'column' }} >
                                                <Typography style={{
                                                    fontFamily: 'Poppins',
                                                    fontSize: 16,
                                                    fontWeight: 'normal',
                                                    color: 'rgba(0, 0, 0, 0.87)'
                                                }} >Attribute Name</Typography>

                                                {!isEdit && (
                                                    <Fragment>

                                                        <Box className={classes.statusContainer} mt='16px' >
                                                            <input required style={{
                                                                padding: 0,
                                                                border: 'none',
                                                                outline: 'none',
                                                                width: '100%',
                                                                height: '100%',
                                                                fontFamily: 'Poppins',
                                                                fontSize: 16,
                                                                fontWeight: 'normal',
                                                                color: 'rgba(0, 0, 0, 0.6)'
                                                            }}
                                                                onChange={(e: any) => newAttributeChangeHandler(e.target.value)}
                                                                type='text'
                                                                value={newAttribute}
                                                                placeholder='Type Here'
                                                            />
                                                        </Box>
                                                        {validateAttributeName(newAttribute) && (
                                                            <Typography style={{
                                                                fontFamily: 'Poppins',
                                                                fontSize: 12,
                                                                fontWeight: 500,
                                                                color: '#e45d33'
                                                            }} >Attribute with this name already exists</Typography>
                                                        )}
                                                    </Fragment>
                                                )}

                                                {isEdit && attributes.map((attribute: any, index: number) => (
                                                    <Draggable key={index} draggableId={`${attribute.id || ''}-${index}`} index={index}>
                                                        {(provided) => (
                                                            <Grid ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps} sm={12} container={true} style={{ flexDirection: 'column', marginTop: index === 0 ? '16px' : '20px', backgroundColor: 'white', ...provided.draggableProps.style }}>
                                                                <Box className={classes.statusContainer}  >
                                                                    <input required style={{
                                                                        padding: 0,
                                                                        border: 'none',
                                                                        outline: 'none',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: 16,
                                                                        fontWeight: 'normal',
                                                                        color: 'rgba(0, 0, 0, 0.6)'
                                                                    }} onChange={(e: any) => { changeTextHandler(e.target.value, index) }} type='text' value={attribute.attributes.name} />
                                                                    <Box display='flex' alignItems='center' >
                                                                        <IconButton onClick={() => updateVisibilityHandler(!attribute.attributes.enabled, index)} style={{ padding: 6 }}>
                                                                            <Icon>
                                                                                <img style={{ height: 18, width: 18, opacity: 0.37 }} src={attribute.attributes.enabled ? showIcon : hideIcon} />
                                                                            </Icon>
                                                                        </IconButton>
                                                                        <img style={{ height: 18, width: 18, opacity: 0.37, marginLeft: 4, cursor: 'grab' }} src={dragIcon} />
                                                                    </Box>

                                                                </Box>
                                                                {!attribute.attributes.name.trim() && (
                                                                    <Typography style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: 12,
                                                                        fontWeight: 500,
                                                                        color: '#e45d33'
                                                                    }} >This field is required</Typography>
                                                                )}
                                                                {validateAttributeName(attribute.attributes.name) && (
                                                                    <Typography style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: 12,
                                                                        fontWeight: 500,
                                                                        color: '#e45d33'
                                                                    }} >Attribute with this name already exists</Typography>
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}

                                                {showNewAttribute && isEdit && (
                                                    <Grid sm={12} container={true} style={{ marginTop: snapshot.isDraggingOver ? '112px' : '36px' }} >
                                                        <Typography style={{
                                                            fontFamily: 'Poppins',
                                                            fontSize: 16,
                                                            fontWeight: 'normal',
                                                            color: 'rgba(0, 0, 0, 0.87)'
                                                        }} >Create Subhead</Typography>
                                                        <Box className={classes.statusContainer} style={{ width: '100%', marginTop: '8px' }}  >
                                                            <input required style={{
                                                                width: '100%',
                                                                padding: 0,
                                                                border: 'none',
                                                                outline: 'none',
                                                                height: '100%',
                                                                fontFamily: 'Poppins',
                                                                fontSize: 16,
                                                                fontWeight: 'normal',
                                                                color: 'rgba(0, 0, 0, 0.6)'
                                                            }}
                                                                type='text'
                                                                value={newAttribute}
                                                                onChange={(e: any) => newAttributeChangeHandler(e.target.value)}
                                                                onBlur={(e: any) => newAttributeBlurHandler(e.target.value)}
                                                                placeholder='Type attribute'
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}

                                                {isEdit && (
                                                    <Grid sm={12} container={true} style={{ marginTop: !showNewAttribute && snapshot.isDraggingOver ? '98px' : '22px' }} >
                                                        <Button onClick={showNewAttributeFieldHandler} style={{
                                                            color: '#e45d33',
                                                            fontFamily: 'Roboto',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                        }}>+ Add New Attribute</Button>
                                                    </Grid>
                                                )}

                                                <Grid sm={12} container={true} justify='space-between' style={{ marginTop: isEdit ? '36px' : '90px' }} >
                                                    <Button disabled={createUpdateLoading} onClick={closeModalHandler} style={{
                                                        borderRadius: 4,
                                                        backgroundColor: 'rgba(98, 2, 238, 0)',
                                                        height: 56,
                                                        width: '25%',
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontFamily: 'Poppins',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                    }} variant="contained">Cancel</Button>
                                                    <Button disabled={(!isEdit && (newAttribute.trim().length === 0 || validateAttributeName(newAttribute))) || createUpdateLoading} onClick={createAndUpdateHandler} style={{
                                                        borderRadius: 4,
                                                        backgroundColor: '#e45d33',
                                                        height: 56,
                                                        width: '25%',
                                                        color: '#ffffff',
                                                        fontFamily: 'Poppins',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        opacity: ((!isEdit && (newAttribute.trim().length === 0 || validateAttributeName(newAttribute))) || createUpdateLoading) ? 0.5 : 1
                                                    }} variant="contained">{isEdit ? 'Save & Update' : 'Create'}</Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: '56px',
        paddingBottom: '50px',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    container: {
        overflow: 'auto',
        display: 'flex',
        width: 800,
        height: 'auto',
        maxHeight: '100%',
        borderRadius: 2,
        boxShadow: '0 7px 8px 4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
        backgroundColor: '#fafafa'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, 0.32)',
        paddingRight: 12,
        paddingLeft: 16,
    }
});

export default withStyles(styles)(AttributeModal)
// Customizable Area End