import React, { Component } from "react";
import SkillController from "./SkillController.web";
import map from "lodash/map";
import Modal from "react-modal";
import "../scss/style.scss";
Modal.setAppElement("#root");
export default class Skill extends SkillController {
  render() {
    //console.log("this.state.searchSkill=============>",this.state.searchSkill)
    console.log("this.state.disableButton=============>",this.state.disableButton,this.state.searchSkill)
    return (
      <>
        <div className="main-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <section className="main-table-wrapper">
                  <div className="top-section flex-between">
                    <div className="headings">
                      <h1 className="mb-1">Manage Skills</h1>
                      <p>
                        Lorem Ipsume demo contetn should be here so we can see
                        how it works.
                      </p>
                    </div>
                    <div className="search-links">
                      <div className="links mb-1">
                        <button className="link" onClick={()=>{this.setState({createOrEditOpen:true ,searchSkill:""})}}>
                          Create
                        </button>
                      </div>
                      <div className="search-box">
                        <input
                          type="search"
                          id="search"
                          className="input"
                          value={this.state?.search_keyword||""}
                          onChange={this.onChangeHandler}
                        required/>
                        <label htmlFor="search">Search Here</label>
                        <img
                        className="search-icon"
                        src={require("../assets/search-icon.svg")}
                        alt="Icon"
                      />
                      </div>
                    </div>
                  </div>
                  <div className="table-nav">
                    <div className={this.state?.skillDetailArray.length!==0 ?"flex-center":"flex-center visibility-hidden"}>
                    <label className="checkbox-lbl">
                     <input type="checkbox" checked={this.state.allChecked}  onClick={this.selectAllHandler}/>
                      <span className="checkmark"></span>
                    </label>
                    
                    <button className="link" disabled={this.state.isDisableOrEnable} onClick={()=>{this.setState({deleteOpen:true})}}>
                      <img
                        className="delete-item ml-1"
                        src={require("../assets/delete-icon.png")}
                        alt="Icon"
                      />
                      </button>
                    </div>
                    <div className="record-counter flex-center">
                      <p>Show</p>
                      <select name="record" id="record" value={this.state.perPageRecord} onChange={this.perPageRecordHandler}  className="select-options">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                      {
                        this.state?.skillDetailArray.length!==0 ?  
                        <p>Showing {this.state.paginationDetails.current_page} to {this.state.paginationDetails.total_pages} of {this.state.paginationDetails.total_count}</p>
                        :
                        <p>Showing {this.state.paginationDetails.current_page} to {this.state.paginationDetails.current_page} of {this.state.paginationDetails.total_count}</p>
                      }
                      {/* {console.log("this.state.paginationDetails.prev_page",this.state.paginationDetails.prev_page)} */}
                      <div className="prev-page page-nav" ><button className="icon-btn" onClick ={this.prevPageHandler} disabled={this.state.paginationDetails.prev_page === null ?true:false} ><img className="" src={require("../assets/right-arrow.svg")} alt="icon"  /></button></div>
                      <div className="next-page page-nav" ><button className="icon-btn" onClick ={this.nextPageHandler} disabled={this.state.paginationDetails.next_page === null ?true:false}><img className="" src={require("../assets/right-arrow.svg")} alt="icon" /></button></div>
                    </div>
                  </div>

                  <div className="table-head">
                    <div className="t-row">
                      <div className="th width-225px">
                        Sort By :  <select name="sort" id="sort" value={this.state.direction} onChange={this.directionHandler} className="select-simple">
                        <option value="default">Default</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      
                      </div>
                      <div className="t-div flex-between-center">
                      <div className="td min-width-30">Email address</div>
                        <div className="td min-width-25">Created By</div>
                        <div className="td min-width-23">Role</div>
                        <div className="td min-width-10">Count</div>
                        <div className="td min-width-12"/>
                      </div>
                    </div>
                  </div>
                  {
                    this.state?.skillDetailArray.length!==0  ?
                    <div className="table-body">
                    {map(
                      this.state.skillDetailArray,
                      (skillDetail: any, index: any) => {
                        // console.log(
                        //   "skillDetail?.attributes?.id =======>",
                        //   skillDetail
                        // );
                        return (
                          <div className="t-row" key={skillDetail?.id} >
                            <div className="th ">
                            <label className="checkbox-lbl mr-1">
                              <input type="checkbox" disabled={this.state.allChecked} checked={skillDetail.isChecked} onChange={()=>{this.multiSelectHandler(index ,skillDetail?.id)}} />
                              <span className="checkmark"></span>
                            </label>
                              {skillDetail.attributes.name}
                            </div>
                            <div className="t-div flex-between-center">
                              <div className="td w-30 min-width-30">
                                {skillDetail.attributes.creator?.data.attributes?.email}
                              </div>
                              <div className="td min-width-25">
                                {skillDetail.attributes.creator?.data.attributes?.created_by}
                              </div>
                              <div className="td min-width-23">
                                {skillDetail.attributes.creator?.data.attributes.role?.name}
                              </div>
                              <div className="td min-width-10">
                                {skillDetail.attributes?.count}
                              </div>
                              <div className="td icons min-width-12">
                                <span className="edit-row" id={skillDetail?.id}>
                                  <img
                                    className="edit-item"
                                    src={require("../assets/edit-icon.png")}
                                    alt="Icon"
                                    onClick={()=>{this.getOneSkill(skillDetail?.id) }}
                                  />
                                </span>
                                <span className="delete-row" id={skillDetail?.id}>
                                  <img
                                    className="delete-item"
                                    src={require("../assets/delete-icon.png")}
                                    alt="Icon"
                                    onClick={()=>{this.setState({deleteOpen:true,deleteOneId:skillDetail?.id })}}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                   :
                   <div className="table-body no-results-found">
                   <p>No records to display</p>
                   </div>
                  }
                </section>
              </div>
            </div>
          </div>
        </div>

        <Modal className="alert-popup mt-5"
        isOpen={this.state.deleteOpen}
      >
        <div className="popup-wrap">
          <div>
              <h2 className="mb-3">Confirm Do You Want To Delete ?</h2>
              <div className="flex-between">
                <button className="btn-primary" onClick={()=>{this.setState({deleteOpen:false, deleteOneId:null })}}>Cancel</button>
                <button className="btn-orange" onClick={this.deleteCompany}>Confirm</button>
              </div>
          </div>
        </div>
      </Modal>

      <Modal className="skills-popup"
        isOpen={this.state.createOrEditOpen}
        onRequestClose={()=>{this.setState({createOrEditOpen:false ,searchSkill:"",editOpen:false, })}}
      
      >
        <div className="popup-box">
        <div className="header flex-between-center">
            <h2 className="flex-center"><img className="mr-1 link" src={require("../assets/left-arrow.svg")} onClick={()=>{this.setState({createOrEditOpen:false,searchSkill:"",editOpen:false,})}} alt=""/>Create Skills</h2>
            <div className="close">
              <img className="" src={require("../assets/close-icon.svg")} onClick={()=>{this.setState({createOrEditOpen:false,searchSkill:"",editOpen:false, })}}  alt=""/>
            </div>
          </div>
          <div className="popup-body">
            <div className="enter-skills">
              <label>Skill Name</label>
                      <div className="skill-Name input-box">
                        <div>
                          <input className="input" type="text" placeholder="Skill Name" onChange={(evt)=>{this.searchSkillHandler(evt)}} value={this.state.searchSkill}></input>
                           
                           {
                             this.state.disableButton || this.state.searchSkill ==="" || this.state.firstTimeEdit ?
                             <p>Type skill name and we will suggest you reference</p>
                             :
                             <p className="error">Skill already exist"</p>
                          }
                          
                        </div>
                        {
                          this.state.searchSkill !=="" && this.state.displayNone?(
                           
                             <div className="bottom-division"> 
                            { 
                              map(this.state?.searchSkillData,(searchSkill:any,index:any)=>{
                                 return(
                                   <ul key ={searchSkill?.id}>
                                  <li onClick={()=>{this.serachClickHandler(searchSkill.attributes?.name)}} key ={searchSkill?.id} >{searchSkill.attributes?.name}</li>
                                  </ul>)
                              })
                            }
                          </div>)
                          :null
                        }
                        </div>
              </div>
          <div className="flex-between margin-top-145px">
          <button className="btn-primary" onClick={()=>{this.setState({createOrEditOpen:false ,searchSkill:"" ,editOpen:false,})}}>Cancel</button>
          {this.state.editOpen ?  
          <button  className="btn-orange" disabled={!this.state.disableButton} onClick={()=>{this.upateCompanyId(this.state.searchSkill)}}>Update</button>
          :  
          <button  className="btn-orange" disabled={!this.state.disableButton} onClick={()=>{this.createNewCompany(this.state.searchSkill)}} >Create</button>
          }
          </div>
          </div>        
        </div>
      </Modal>
      </>
    );
  }
}