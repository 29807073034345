import React, { Fragment, useEffect, useState, useRef } from 'react';
// Customizable Area Start
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';

import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import TextField from '@material-ui/core/TextField';

import { Formik } from 'formik';
//@ts-ignore
import classnames from "classnames";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { FaTimes, FaTimesCircle, FaPlus } from "react-icons/fa";
import { BsEyeSlash, BsThreeDots, BsCaretDownFill, BsDot, BsBriefcase } from "react-icons/bs";
import { BiShareAlt, BiCaretDown, BiRupee } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import { GiBackwardTime } from "react-icons/gi";
import Loader from "../../../components/src/Loader";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//@ts-ignore
import _ from "lodash";

import "./style.css";
// Customizable Area End

import JobListingController, {
  Props,
  configJSON
} from "./JobListingController";

export default class JobListingFullJob extends JobListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.setState({ loading: true }, () => {
      this.getJobListing();
    });

  }
  // Customizable Area End

  render() {

    const { navigation } = this.props;

    const styles = getStyles();
    const assets = require("./assets");

    const jobId = navigation.getParam("jobid");

    if (jobId && jobId !== this.state.jobIdDetails.id) {
      this.getJobIdDetails(jobId);
    }

    var job: any = {};
    if (this.state.jobIdDetails.id) {
      job = this.state.jobIdDetails.attributes;
    }


    return (
      <Fragment>
        <Loader loading={this.state.loading} />
        <View style={{ height: 65, backgroundColor: "white", width: "100%", marginTop: 95, borderBottomColor: "silver", borderBottomWidth: 1, alignItems: "center", flexDirection: "row", paddingHorizontal: "6%" }}>
          <Text style={{ color: "black", fontSize: 18, fontWeight: "500" }}><BsBriefcase style={{ fontSize: 22, verticalAlign: "middle" }} />  120 Jobs Found</Text>

          <FormControl variant="outlined" style={{ minWidth: 140, marginLeft: 30 }}
            size="small" >
            <InputLabel id="demo-simple-select-outlined-label">Filter By City</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={""}
              onChange={(age) => alert(age)}
              label="Filter By City"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 175, marginLeft: 30 }}
            size="small" >
            <InputLabel id="demo-simple-select-outlined-label">Filter By Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={""}
              onChange={(age) => alert(age)}
              label="Filter By Category"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 175, marginLeft: 30 }}
            size="small" >
            <InputLabel id="demo-simple-select-outlined-label">Filter By Job Type</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={""}
              onChange={(age) => alert(age)}
              label="Filter By Job Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 140, marginLeft: 30 }}
            size="small" >
            <InputLabel id="demo-simple-select-outlined-label">Salary Range</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={""}
              onChange={(age) => alert(age)}
              label="Salary Range"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 140, marginLeft: 30 }}
            size="small" >
            <InputLabel id="demo-simple-select-outlined-label">More Filters</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={""}
              onChange={(age) => alert(age)}
              label="More Filters"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>


        </View>

        <View style={styles.container}>
          <View style={styles.leftColumn}>
            <View style={styles.card}>
              <Text style={{ fontSize: 16, color: "black" }}>{this.state.selectedJobSkills.join(",") || "Similar Jobs"}</Text>
              <Text style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12 }}>{this.state.jobListingData.length} results</Text>
            </View>


            <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke" }} />

            {Array.isArray(this.state.jobListingData) && this.state.jobListingData.map((item: any, index: number) => index < 10 && (
              <Fragment>
                <div className="jobhoverableItem">
                  <TouchableOpacity onPress={() => navigation.navigate("JobListingFullJob", { jobid: item.id })} style={{ flexDirection: "row", borderRightWidth: 3, borderRightColor: jobId === item.id ? "#e45d33" : "white", paddingVertical: 16, paddingRight: 10 }}>
                    <View style={{ width: "25%", alignItems: "center" }}>
                      <Image source={assets.rightImage} resizeMode={"contain"} style={{ height: 50, width: 50, backgroundColor: "red", borderRadius: 50 }} />

                    </View>
                    <View style={{ width: "75%" }}>
                      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Text style={{ fontSize: 15, color: "black" }}>{item.attributes?.job_title} </Text>
                        <Text style={{ fontSize: 9, color: "silver" }}>3 days ago</Text>
                      </View>
                      <Text style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 11, marginTop: 5 }}>{item.attributes?.companies?.length > 0 ? item.attributes?.companies.map((item: any) => item.name).join(", ") : "-"}</Text>
                      <Text style={{ color: "black", fontSize: 11, marginTop: 7 }}><BsBriefcase style={{ fontSize: 14, verticalAlign: "middle" }} /> {item.attributes?.work_experience_min} - {item.attributes?.work_experience_max} Years <BiRupee style={{ fontSize: 14, verticalAlign: "middle" }} /> {(item.attributes?.min_salary || item.attributes?.max_salary) ? `${item.attributes?.min_salary} ${item.attributes?.currency?.toUpperCase()} - ${item.attributes?.max_salary} ${item.attributes?.currency?.toUpperCase()}` : "Not Disclosed"} <HiOutlineLocationMarker style={{ fontSize: 14, verticalAlign: "middle" }} /> {!!item.attributes?.job_location_details ? Object.values(item.attributes?.job_location_details).filter((item: any) => !!item).reverse().join(", ") : "-"}</Text>

                    </View>
                  </TouchableOpacity>
                </div>
                <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke" }} />

              </Fragment>
            ))}

          </View>

          <View style={styles.mainColumn}>
            <View style={{ minHeight: 130, backgroundColor: "whitesmoke", flexDirection: "row", padding: 30 }}>
              <View style={{ width: "70%" }} >
                <Text style={{ fontSize: 24 }}>{job.job_title}</Text>
                <Text style={{ fontSize: 11, color: "gray", marginTop: 18 }}>{job?.companies?.length > 0 ? job?.companies.map((item: any) => item.name).join(", ") : "-"}  |  {!!job?.job_location_details ? Object.values(job?.job_location_details).filter((item: any) => !!item).reverse().join(", ") : "-"}</Text>

              </View>
              <View style={{ alignItems: "flex-end", width: "30%" }}>
                <TouchableOpacity onPress={() => this.applyJob(this.state.jobIdDetails.id)} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>{this.state.appliedJobs.find(itm => itm.attributes?.job_details?.id === Number.parseInt(this.state.jobIdDetails.id)) ? "Applied" : "APPLY NOW"}</Text></TouchableOpacity>

                <Text style={{ fontSize: 11, color: "gray", marginTop: 10 }}>Posted 1 week ago - <Text style={{ color: "black" }}>12 Applicants</Text></Text>

              </View>
            </View>

            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", width: "100%", paddingHorizontal: 30, paddingBottom: 15 }}>

              {job.work_experience_min != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>EXPERIENCE</Text>
                  <Text style={styles.candidateDescriptionText}>{job.work_experience_min} - {job.work_experience_max} Years</Text>
                </View>
              }

              {job.min_salary != null && job.max_salary != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>SALARY</Text>
                  <Text style={styles.candidateDescriptionText}>{job.min_salary} {job.currency?.toUpperCase()} - {job.max_salary} {job.currency?.toUpperCase()}</Text>
                </View>
              }

              {job.employment_type != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>EMPLOYMENT</Text>
                  <Text style={styles.candidateDescriptionText}>{_.capitalize((job.employment_type ?? "_").replace(/_/g, " "))}</Text>
                </View>
              }

              {job.vacancies != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>OPENINGS</Text>
                  <Text style={styles.candidateDescriptionText}>{job.vacancies}</Text>
                </View>
              }

              {job.reporting_to != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>REPORTING TO</Text>
                  <Text style={styles.candidateDescriptionText}>{job.reporting_to}</Text>
                </View>
              }

              {job.team != null &&
                <View style={styles.candidateItemContainer}>
                  <Text style={styles.candidateTitleText}>REPORTEES/TEAM</Text>
                  <Text style={styles.candidateDescriptionText}>{job.team}</Text>
                </View>
              }
            </View>


            <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke" }} />


            <View style={{ paddingHorizontal: 30, paddingTop: 10 }}>

              {job.job_details != null &&
                <View style={{ marginBottom: 15 }}>
                  <Text style={styles.grayTitleText}>Job Description</Text>
                  <Text style={styles.grayText}>{job.job_details}</Text>
                </View>
              }

              {job.desired_candidate_profile != null &&
                <View style={{ marginBottom: 15 }}>
                  <Text style={styles.grayTitleText}>Desired Candidate Profile</Text>
                  <Text style={styles.grayText}>{job.desired_candidate_profile}</Text>
                </View>
              }
              {job.key_performance_indicators != null &&
                <View style={{ marginBottom: 15 }}>
                  <Text style={styles.grayTitleText}>Key Performance Indicators</Text>
                  <Text style={styles.grayText}>{job.key_performance_indicators}</Text>
                </View>
              }
            </View>


            <View style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
              {job.industry?.length > 0 &&
                <View style={{ flexDirection: "row", marginTop: 0 }}>
                  <Text style={[styles.grayText, { width: 130 }]}>Industry Type</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{job.industry?.map((item: any, index: number) => item.name).join(" / ")}</Text>
                </View>
              }
              {job.functional_area?.length > 0 &&
                <View style={{ flexDirection: "row", marginTop: 7 }}>
                  <Text style={[styles.grayText, { width: 130 }]}>Functional Area</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{job.functional_area?.map((item: any, index: number) => item.name).join(", ")}</Text>
                </View>
              }
            </View>


            <View style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
              <View style={{ marginBottom: job?.graduation ? 7 : 0 }}>
                <Text style={styles.grayTitleText}>Education</Text>
              </View>
              {job?.graduation &&
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.grayText, { width: 130 }]}>UG</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{job?.graduation?.name}</Text>
                </View>
              }
              {job?.post_graduation &&
                <View style={{ flexDirection: "row", marginTop: 7 }}>
                  <Text style={[styles.grayText, { width: 130 }]}>PG</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{job?.post_graduation?.name}</Text>
                </View>
              }
              {job?.doctorate &&
                <View style={{ flexDirection: "row", marginTop: 7 }}>
                  <Text style={[styles.grayText, { width: 130 }]}>Doctorate</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{job?.doctorate?.name}</Text>
                </View>
              }
            </View>

            {job.keywords != null &&
              <View style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
                <View style={{ marginBottom: 7 }}>
                  <Text style={styles.grayTitleText}>Keywords</Text>
                </View>
                <View style={styles.keySkillTagsContainer}>
                  {Array.isArray(job.keywords) && job.keywords?.map((item: any, index: number) => (
                    <Text style={styles.keySkillTag}>{item.name}</Text>
                  ))}
                </View>
              </View>
            }

            <View style={{ flexDirection: "row" }}>
              <View style={{ paddingHorizontal: 30, paddingVertical: 10, width: "50%" }}>
                <View style={{ marginBottom: 7 }}>
                  <Text style={styles.grayTitleText}>Reference Code</Text>
                </View>
                <View style={styles.keySkillTagsContainer}>
                  {["Figma", "UX", "Designe"]?.map((item: string, index: number) => (
                    <Text style={styles.keySkillTag}>{item}</Text>
                  ))}
                </View>
              </View>


              <View style={{ paddingHorizontal: 30, paddingVertical: 15, width: "50%" }}>
                <View style={{ marginBottom: 7 }}>
                  <Text style={styles.grayTitleText}>Hiring For</Text>
                </View>
                <View style={styles.keySkillTagsContainer}>
                  {["Mr. Amit Sharma"]?.map((item: string, index: number) => (
                    <Text style={styles.keySkillTag}>{item}</Text>
                  ))}
                </View>
              </View>
            </View>


            <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke" }} />

            <View style={{ paddingHorizontal: 30, paddingVertical: 10, marginBottom: 10 }}>
              <View style={{ marginBottom: 7 }}>
                <Text style={styles.grayTitleText}>Contact Person</Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Image source={assets.rightImage} resizeMode={"contain"} style={{ height: 40, width: 40, backgroundColor: "red", borderRadius: 35 }} />
                <View style={{ justifyContent: "space-between", marginLeft: 10 }}>
                  <Text style={[styles.grayText, { color: "black" }]}>Mr. Ravi Shastri</Text>
                  <Text style={styles.grayText}>+91 8475648754</Text>
                </View>
              </View>

            </View>

            <View style={{ height: 2, width: "100%", backgroundColor: "whitesmoke" }} />

            <View style={{ paddingHorizontal: 30, paddingTop: 10 }}>
              <View style={{ marginBottom: 15 }}>
                <Text style={styles.grayTitleText}>About Company</Text>
                <Text style={styles.grayText}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Text>
              </View>
            </View>

            {Array.isArray(job.companies) && job.companies?.map((item: any, index: number) => (
              <View style={{ paddingHorizontal: 30, paddingVertical: 10, marginBottom: 15 }}>
                <View style={{ marginBottom: 7 }}>
                  <Text style={styles.grayTitleText}>Company Info</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.grayText, { width: 130 }]}>Name</Text>
                  <Text style={[styles.grayText, { color: "black" }]}>{item.name || "-"}</Text>
                </View>
                {item.email &&
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.grayText, { width: 130 }]}>Email</Text>
                    <Text style={[styles.grayText, { color: "black" }]}>{item.email}</Text>
                  </View>
                }
                {item.website &&
                  <View style={{ flexDirection: "row", marginTop: 7 }}>
                    <Text style={[styles.grayText, { width: 130 }]}>Website</Text>
                    <Text style={[styles.grayText, { color: "#e45d33" }]}>{item.website}</Text>
                  </View>
                }
              </View>
            ))}
          </View>

        </View>
      </Fragment>
    );
  }
}

// Customizable Area Start

// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    paddingTop: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  mainColumn: {
    width: "70%",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "silver",
    borderTopWidth: 0
  },
  leftColumn: {
    width: "30%",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "silver",
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    paddingHorizontal: "5%",
    paddingVertical: 18,
    marginBottom: 2
  },
  widgetHead: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  widgetTitle: {
    fontSize: 14,
    color: "black",
    fontWeight: 500
  },
  widgetClear: {
    fontSize: 13,
    color: "silver",
    fontWeight: 500
  },
  widgetSearchInput: {
    width: "100%"
  },
  widgetSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0
  },
  jobSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0,
    width: "50%"
  },
  keySkillsBlock: {
    marginTop: 8
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 7,
    marginTop: 5
  },
  tagTimesIcon: {
    height: "100%",
    verticalAlign: "sub",
    marginLeft: 8
  },
  rightImage: {
    width: "100%",
    height: 210,
    marginTop: 40
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  text: {
    fontSize: 12,
    color: "black",
    lineHeight: 27
  },
  candidateItemContainer: {
    marginTop: 20,
    width: "25%"
  },
  candidateTitleText: {
    color: "rgba(65, 65, 65, 0.87)",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
  },
  candidateDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11,
    marginTop: 5,
    letterSpacing: 0.25
  },
  grayTitleText: {
    color: "black",
    fontSize: 14,
    lineHeight: 28
  },
  grayText: {
    color: "rgba(65, 65, 65, 0.87)",
    fontSize: 10,
    lineHeight: 22
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  626
  maxWidth(626, {
  }),
  // below  576
  maxWidth(576, {
  }),
);
// Customizable Area End
