//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React, { Fragment } from 'react';
import { Formik } from 'formik';
import Icon from 'react-native-vector-icons/FontAwesome';
import Storage from "../../../framework/src/StorageProvider.web";

import Loader from "../../../components/src/Loader";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
// Customizable Area End


import ForgotPasswordController, { Props } from "./ForgotPasswordController";


export default class EmailAccountLoginBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.emailValue) && this.state.emailValue != "";
    //const isPasswordValid = this.passwordReg.test(this.state.password) && this.state.password != "";
    //const isConfirmPasswordValid = this.state.password === this.state.reTypePassword;

    return this.state.emailSent ? (
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          <View>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
              <TouchableOpacity onPress={() => this.goToHome()}>
                <Text style={[styles.text, { fontWeight: "500", fontSize: 14 }]}>Home</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.leftTitle}>Check your email</Text>
            <Text style={[styles.text, { marginTop: 8, fontWeight: "500" }]}>We have sent a password recovery instructions on your email.</Text>


            <TouchableOpacity onPress={() => this.goToLogin()} style={[styles.button, { marginTop: 100 }]}>
              <Text style={styles.buttonText}>BACK TO LOGIN</Text>
            </TouchableOpacity>

          </View>

          <Text style={[styles.text, { marginTop: 50 }]}>© 2021 RMS. All right Reserved.</Text>

        </View>
        <View style={styles.rightContainer}>
          <ImageBackground source={assets.rightSectionBackgroundImage} style={styles.rightSectionBackgroundImage} >
            <View style={styles.rightContentContainer}>
              <Image source={assets.rightSectionPeopleImage} style={styles.rightSectionPeopleImage} resizeMode={"contain"} />
              <Text style={styles.rightImageTitle}>Lorem Ipsum has been the industry's standard</Text>
              <Text style={styles.rightImageDescription}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Text>
              <View style={{ marginTop: 50 }}>
                  <PaginationIndicator count={3} selected={1} />
                </View>
            </View>
          </ImageBackground>
        </View>
      </View>
    )
      :
      (
        <View style={styles.container}>
          <Loader loading={this.state.loading} />
          <View style={styles.leftContainer}>
            <View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
                <TouchableOpacity onPress={() => this.goToHome()}>
                  <Text style={[styles.text, { fontWeight: "500", fontSize: 14 }]}>Home</Text>
                </TouchableOpacity>
              </View>
              <Text style={styles.leftTitle}>Forgot Password?</Text>
              <Text style={[styles.text, { marginTop: 8, fontWeight: "500" }]}>Enter the email associated with your account and we we'll send an email with instructions to reset your password.</Text>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values) => { this.setState({loading:true},()=> this.goToOtpAfterEmailValidation(values)); }}
                validationSchema={this.getEmailRequestFormValidations}
              >
                {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
                  <Fragment>

                    <View style={styles.formRow}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <TextField name="email" onChange={handleChange} onBlur={() => setFieldTouched('email')} variant={"outlined"} label={"Email Address"} error={!!(errors.email && touched.email)} helperText={touched.email && errors.email} style={styles.plainTextInput} value={values.email} />
                      </View>
                      <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                        {touched.email && !errors.email && <FaCheckCircle size={24} color="green" style={styles.textInputIcon} />}
                      </View>
                    </View>

                    <TouchableOpacity onPress={() => { handleSubmit() }} style={styles.button}>
                      <Text style={styles.buttonText}>SEND INSTRUCTION</Text>
                    </TouchableOpacity>
                  </Fragment>
                )}
              </Formik>
              <Text style={[styles.text, { marginTop: 20 }]}>Remember Password? <TouchableOpacity onPress={() => this.goToLogin()}><Text style={{ color: "#e45d33" }} >Log In</Text></TouchableOpacity></Text>
            </View>

            <Text style={[styles.text, { marginTop: 50 }]}>© 2021 RMS. All right Reserved.</Text>

          </View>
          <View style={styles.rightContainer}>
            <ImageBackground source={assets.rightSectionBackgroundImage} style={styles.rightSectionBackgroundImage} >
              <View style={styles.rightContentContainer}>
                <Image source={assets.rightSectionPeopleImage} style={styles.rightSectionPeopleImage} resizeMode={"contain"} />
                <Text style={styles.rightImageTitle}>Lorem Ipsum has been the industry's standard</Text>
                <Text style={styles.rightImageDescription}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Text>
              <View style={{ marginTop: 50 }}>
                  <PaginationIndicator count={3} selected={1} />
                </View>
              </View>
            </ImageBackground>
          </View>
        </View>
      )
      ;
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 20,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "#ffffff",
    width: "50%",
    minHeight: "100%",
    paddingHorizontal: "5.5%",
    paddingVertical: "3%",
    justifyContent: "space-between"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  leftTitle: {
    fontSize: 30,
    color: "#1a181d",
    marginTop: 50,
    fontWeight: 700
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 28
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "50%",
    minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 56
  },
  inputWrapper: {
    width: "49%"
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);
