import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import _ from 'lodash';
import Storage from "../../../framework/src/StorageProvider.web";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: any;
    showModal: boolean
    closeModalHandler: any
    changeTextHandler: any
    updateIndexHandler: any
    updateVisibilityHandler: any
    createAndUpdateHandler: any
    showNewAttributeFieldHandler: any
    newAttributeChangeHandler: any
    newAttributeBlurHandler: any
    validateAttributeName: any
    createUpdateLoading: boolean
    attributes: any[]
    isEdit: boolean
    showNewAttribute: boolean
    newAttribute: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    categoriesArray: any;
    category: string;
    subCategory: string;
    isVisible: boolean;
    dropdownCategoryStatus: boolean;
    activeModalType: string;
    selectedCategoryID: any;
    hoveredStatusIndex: string;
    attributeList: {
        error: string | null,
        loading: boolean,
        data: any[]
    };
    defferedAttributes: any[],
    attributes: any[],
    searchKeyword: string
    profileSource: any
    showModal: boolean
    createUpdateLoading: boolean
    isEdit: boolean
    showNewAttribute: boolean
    newAttribute: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AttributeController extends BlockComponent<
    Props,
    S,
    SS
> {
    attributeListApiCallMessageRequestId: any;
    attributeUpdateApiCallMessageRequestId: any;
    attributeCreateApiCallMessageRequestId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            categoriesArray: [],
            category: "",
            subCategory: "",
            isVisible: false,
            dropdownCategoryStatus: false,
            activeModalType: "",
            selectedCategoryID: [],
            hoveredStatusIndex: "",
            attributeList: {
                error: null,
                loading: false,
                data: []
            },
            defferedAttributes: [],
            attributes: [],
            searchKeyword: "",
            profileSource: {},
            showModal: false,
            createUpdateLoading: false,
            isEdit: false,
            showNewAttribute: false,
            newAttribute: '',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (this.attributeListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.attributeListApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Attribute List Response : ', responseJson)
                this.setState({
                    attributeList: {
                        loading: false,
                        error: null,
                        data: responseJson.data
                    },
                })
            } else {
                console.log('Profile Source List Error : ', responseJson)
                this.setState({
                    attributeList: {
                        loading: false,
                        error: JSON.stringify(responseJson?.errors),
                        data: []
                    }
                })
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);

        } else if (this.attributeUpdateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.attributeUpdateApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Attribute Update Response : ', responseJson)

                let attributes = [...this.state.attributeList.data]

                if (attributes.length <= responseJson.data.length) {
                    attributes = responseJson.data
                } else {
                    for (let i = 0; i < responseJson.data.length; i += 1) {
                        const attributeIndex = _.findIndex(attributes, ['id', responseJson.data[i].id]);
                        if (attributeIndex > -1) {
                            attributes[attributeIndex] = responseJson.data[i]
                        } else {
                            attributes.push(responseJson.data[i])
                        }
                    }
                }

                this.setState({
                    attributeList: {
                        ...this.state.attributeList,
                        data: attributes
                    },
                    createUpdateLoading: false,
                    showModal: false
                })

            } else {
                console.log('Attribute Update Error : ', responseJson)
                let stateObj: any = {
                    createUpdateLoading: false
                }
                if (this.state.defferedAttributes.length > 0) {
                    stateObj.attributeList = {
                        ...this.state.attributeList,
                        data: this.state.defferedAttributes,
                        defferedAttributes: []
                    }
                }

                this.setState(stateObj)

                if (responseJson.errors[0].hasOwnProperty('errors')) {
                    this.parseApiErrorResponse(responseJson.errors[0]);
                } else {
                    this.parseApiErrorResponse(responseJson);
                }

            }

            this.parseApiCatchErrorResponse(errorReponse);
        } else if (this.attributeCreateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.attributeCreateApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Attribute Create Response : ', responseJson)

                const attributes = [...this.state.attributeList.data, responseJson.data]

                this.setState({
                    attributeList: {
                        ...this.state.attributeList,
                        data: attributes
                    },
                    createUpdateLoading: false,
                    showModal: false
                })

            } else {
                console.log('Attribute Create Error : ', responseJson)
                let stateObj: any = {
                    createUpdateLoading: false
                }
                if (this.state.defferedAttributes.length > 0) {
                    stateObj.attributeList = {
                        ...this.state.attributeList,
                        data: this.state.defferedAttributes,
                        defferedAttributes: []
                    }
                }

                this.setState(stateObj)

                if (responseJson.errors[0].hasOwnProperty('errors')) {
                    this.parseApiErrorResponse(responseJson.errors[0]);
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
            }

            this.parseApiCatchErrorResponse(errorReponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    setCategoryTxt = (text: string) => {
        this.setState({ category: text });
    };
    setSubCategoryTxt = (text: string) => {
        this.setState({ subCategory: text });
    };
    clickCategory = (item: any, Index: number) => {
        let array = this.state.categoriesArray;
        let idarray = this.state.selectedCategoryID;
        let index = idarray.indexOf(item.attributes.id);

        if (index > -1) {
            idarray.splice(index, 1);
            array[Index].Check = false;
            this.setState({ categoriesArray: array });
        } else {
            idarray.push(item.attributes.id);
            array[Index].Check = true;
            this.setState({ categoriesArray: array });
            this.setState({ selectedCategoryID: idarray });
        }
    };

    toggleModal = (type: string) => {
        this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
    };

    expandCategoryView = () => {
        this.setState({
            dropdownCategoryStatus: !this.state.dropdownCategoryStatus
        });
    };
    expand = (id: string) => {
        let array = this.state.categoriesArray;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                array[i].expand = !array[i].expand;
            }
        }
        this.setState({ categoriesArray: array });
    };

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    getAttributeList = async (keyword: any = this.state.searchKeyword) => {
        try {
            this.setState((prevState: S) => ({
                attributeList: {
                    ...prevState.attributeList,
                    loading: true,
                }
            }))

            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.attributeListApiContentType,
                token: authToken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.attributeListApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.attributeListApiEndPoint}?search_keyword=${keyword}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.attributeListApiMethod ,
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            this.setState((prevState: S) => ({
                attributeList: {
                    ...prevState.attributeList,
                    loading: false,
                }
            }))
            console.log(error)
        }
    }

    updateAttribute = async (attribute: any) => {
        try {
            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.attributeUpdateApiContentType,
                token: authToken
            };

            const httpBody = { reference_attribute: attribute };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.attributeUpdateApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.attributeUpdateApiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.attributeUpdateApiEndPoint,
            );

            console.log('requestMessage', requestMessage)

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error)
            this.setState({
                createUpdateLoading: false
            })
        }
    }

    createAttribute = async (attribute: any) => {
        try {
            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.attributeCreateApiContentType,
                token: authToken
            };

            const httpBody = { reference_attribute: attribute };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.attributeCreateApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.attributeCreateApiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.attributeCreateApiEndPoint,
            );

            console.log('requestMessage', requestMessage)

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error)
            this.setState({
                createUpdateLoading: false
            })
        }
    }

    handleSearch = (keyword: any) => {
        this.setState({ searchKeyword: keyword })
        this.getAttributeList(keyword)
    }

    handleRowHover = (index: string) => {
        this.setState({ hoveredStatusIndex: index })
    }

    openModalHandler = (isEdit: boolean = false) => {
        this.setState({ attributes: [...this.state.attributeList.data], isEdit, showModal: true })
    }

    closeModalHandler = () => {
        this.setState({ attributes: [], newAttribute: "", showNewAttribute: false, showModal: false })
    }

    changeTextHandler = (value: string, index: number) => {
        const attributeList = _.cloneDeep(this.state.attributes)
        attributeList[index].attributes.name = value
        this.setState({ attributes: attributeList })
    }

    createAndUpdateHandler = () => {
        this.setState({
            createUpdateLoading: true
        })
        if (this.state.isEdit) {
            const valueArr = this.state.attributes.map((item) => item.attributes.name.trim().toLowerCase());
            const isDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) != idx
            });
            if (isDuplicate) {
                return this.setState({
                    createUpdateLoading: false
                })
            }
            this.updateAttribute(this.state.attributes.map((attribute, index) => ({
                ...attribute.attributes
            })))
        } else {
            this.createAttribute({
                "name": this.state.newAttribute.trim()
            })
        }
    }

    handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
        if (result.destination.droppableId !== result.source.droppableId) return;
        if (result.destination.index === result.source.index) return;
        console.log(result)
        const attributeList = [...this.state.attributeList.data]
        const [reorderedItem] = attributeList.splice(result.source.index, 1);
        attributeList.splice(result.destination.index, 0, reorderedItem);

        this.setState((prevState: S) => ({
            defferedAttributes: prevState.attributeList.data,
            attributeList: {
                ...prevState.attributeList,
                data: attributeList
            }
        }))

        this.updateAttribute(attributeList.map((attribute, index) => ({
            ...attribute.attributes,
            "index_value": index,
        })))
    }

    updateIndexHandler = (result: any) => {
        if (!result.destination) return;
        if (result.destination.droppableId !== result.source.droppableId) return;
        if (result.destination.index === result.source.index) return;
        let attributeList = _.cloneDeep(this.state.attributes)

        const [reorderedItem] = attributeList.splice(result.source.index, 1);
        attributeList.splice(result.destination.index, 0, reorderedItem);

        attributeList = attributeList.map((data: any, i: number) => ({
            ...data,
            attributes: {
                ...data.attributes,
                index_value: i
            }
        }))

        this.setState({ attributes: attributeList })
    }

    updateVisibilityHandler = (value: boolean, index: number) => {
        const attributeList = _.cloneDeep(this.state.attributes)
        attributeList[index].attributes.enabled = value
        this.setState({ attributes: attributeList })
    }

    showNewAttributeFieldHandler = () => {
        this.setState({ showNewAttribute: true })
    }

    newAttributeChangeHandler = (value: string) => {
        this.setState({ newAttribute: value })
    }

    newAttributeBlurHandler = (value: string) => {
        if (!value || value.trim().length === 0) return
        const attributeList = _.cloneDeep(this.state.attributes)
        const newAttribute = {
            "attributes": {
                "name": value.trim(),
                "index_value": attributeList.length - 1,
                "enabled": true,
            }
        }
        attributeList.push(newAttribute)
        this.setState({ attributes: attributeList, showNewAttribute: false, newAttribute: '' })
    }

    validateAttributeName = (value: string) => {
        let attributeList = _.cloneDeep(this.state.attributes)
        attributeList = attributeList.filter((attribute: any) => attribute.attributes.name.toLowerCase() === value.trim().toLowerCase())

        return this.state.isEdit ? attributeList.length > 1 : attributeList.length === 1
    }
    // Customizable Area End
}
