import React, { useEffect, useState } from "react";
import fetcher from './fetcher';
import * as Utilities from "../../framework/src/Utilities";
import Storage from "../../framework/src/StorageProvider.web";
import { baseURL } from "../../framework/src/config";
import { useToasts } from 'react-toast-notifications';
import { withRouter } from "react-router-dom";

function SessionChecker(props) {

    const { addToast } = useToasts();

    const pathname = window.location.pathname.toLowerCase();

    useEffect(() => {
        refreshUser();
    }, [pathname]);



    async function refreshUser() {
        const authToken = await Storage.get("authToken");

        //logout user if user access auth screens after login
        if (authToken && ["/login", "/register", "/newpassword", "/forgotpassword"].includes(pathname)) {
            Utilities.logoutUser("");
            props.root.forceUpdate();
        }

        //ignore path where session check is not needed.
        else if (authToken && !["/login"].includes(pathname)) {

            const service = {
                method: "GET",
                url: `${baseURL}/profile/profile`,
                params: {},
                body: {},
                headers: {
                    token: authToken
                },
                bearerToken: ''
            }

            const { response, error } = await fetcher(service);
            console.log("After fetcher");
            if (error) {
                console.log("Error Occured");
                if (error.response?.data?.errors?.[0] !== undefined && "token" in error.response?.data?.errors?.[0]) {
                    console.log("Logging Out User");
                    Utilities.logoutUser("");
                    props.root.forceUpdate();
                    addToast("Your session has expired. Please login again to continue.", { appearance: 'error' })

                    if (["/profile", "/profile/edit"].includes(pathname)) {
                        props.history.push("/login");
                    }

                }

            } else if (response && response.data.data) {
                console.log("Success fetcher ", response.data.data);
                Storage.set("profileData", response.data.data).then(() => {
                    props.root.forceUpdate();
                });
            }

            //when user is not logged in but tried to access a user page
        } else if ([
            "/profile", "/profile/",
            "/profile/edit", "/profile/edit/",
            "/additionaldetailform", "/additionaldetailform/",
            "/jobmanagement", "/jobmanagement/",
            "/joblisting", "/joblisting/",
        ].includes(pathname)) {
            addToast("Your session has expired. Please login again to continue.", { appearance: 'error' })
            props.history.push("/login");
        }
    }





    return (null);
}

export default withRouter(SessionChecker);