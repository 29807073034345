import React, { Fragment } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  CheckBox
} from "react-native";

import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import Loader from "../../../components/src/Loader";
//@ts-ignore
import Download from "downloadjs";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { HiOutlineLocationMarker, HiOutlineTranslate } from "react-icons/hi";
import { GoPencil } from "react-icons/go";
import { BsArrowRight } from "react-icons/bs";
import { FiCamera, FiCircle } from "react-icons/fi";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";
//@ts-ignore
import SuspenseImage from "react-graceful-image";

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End


  navigateToSection(name: string) {
    switch (name) {
      case "Education Qualifications": this.props.navigation.navigate("UserProfileBasicEditBlock", {}, "#education"); break;
      case "It Skills": this.props.navigation.navigate("UserProfileBasicEditBlock", {}, "#itskills"); break;
      case "Project Details": this.props.navigation.navigate("UserProfileBasicEditBlock", {}, "#project"); break;
      case "Language Details": this.props.navigation.navigate("UserProfileBasicEditBlock", {}, "#language"); break;
      default: this.props.navigation.navigate("UserProfileBasicEditBlock");
    }
  }


  render() {
    const { navigation } = this.props;

    const styles = getStyles();
    const assets = require("./assets");

    const profile = this.state.profileData?.attributes ?? {};

    const employmentType = (profile.employment_type === "0" && "Full Time") ||
      (profile.employment_type === "1" && "Part Time") ||
      (profile.employment_type === "2" && "Freelancer") ||
      (profile.employment_type === "3" && "Remote");

    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading} />
        <View style={styles.leftColumn}>
          <View style={{ backgroundColor: "#ffffff", width: "100%", borderRadius: 5 }}>
            <View style={[styles.avatarBlock]}>
              <View style={[styles.avatarBackground]}>
                      {/*<Image source={typeof profile.avatar === "string" ? { uri: profile.avatar } : assets.userProfileImage} onError={() => { this.forceUpdate(); }} resizeMode={"cover"} style={styles.profilePicture} />*/}
                      <SuspenseImage
                  src={typeof profile.avatar === "string" ? profile.avatar : assets.userProfileImage}
                  alt='I'
                  resizeMode={"cover"}
                  retry={{ count: 4, delay: 2, accumulate: 'multiply' }}
                  style={styles.profilePicture}
                />
                </View>
              <View style={{ paddingTop: 50, alignItems: "center" }} >
                <Text style={styles.profileName}>{profile.first_name + " " + profile.last_name}</Text>
                <Text style={styles.profileOccupation}>{profile.role}</Text>
                <Text style={styles.profileLocation}><HiOutlineLocationMarker style={{ verticalAlign: "sub" }} />  {profile.current_location}</Text>
              </View>

              <TouchableOpacity onPress={() => { this.props.navigation.navigate("UserProfileBasicEditBlock") }} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40, width: "90%", marginHorizontal: "5%" }]}><Text style={[styles.buttonText, { color: "#ffffff", textAlignVertical: "bottom" }]}><GoPencil style={{ fontSize: 14 }} />{"     "}EDIT YOUR PROFILE</Text></TouchableOpacity>
              {typeof profile.resume === "string" &&
                <TouchableOpacity onPress={() => this.downloadFile(profile.resume)} style={[styles.button, { backgroundColor: "#ffffff", borderColor: "#ffffff", height: 40, width: "90%", marginHorizontal: "5%", marginTop: 10, marginBottom: 20, shadowColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#1a181d", fontWeight: 500 }]}>DOWNLOAD RESUME</Text></TouchableOpacity>
              }
            </View>
            <View style={styles.personalInfoBlock}>
              <Text style={styles.blockTitle}>Personal Information</Text>

              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Email</Text>
                <Text style={styles.personalInfoRightText}>{profile.email}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Contact No.</Text>
                <Text style={styles.personalInfoRightText}>{profile.phone_number ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>DOB</Text>
                <Text style={styles.personalInfoRightText}>{profile.dob ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Marital Status</Text>
                <Text style={styles.personalInfoRightText}>{profile.marital_status ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Gender</Text>
                <Text style={styles.personalInfoRightText}>{profile.gender ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Current Location</Text>
                <Text style={styles.personalInfoRightText}>{profile.current_location ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Employment Status</Text>
                <Text style={styles.personalInfoRightText}>{employmentType ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Category</Text>
                <Text style={styles.personalInfoRightText}>{profile.category ?? "-"}</Text>
              </View>
              <View style={styles.personalInfoRow}>
                <Text style={styles.personalInfoLeftText}>Physically Challenged</Text>
                <Text style={styles.personalInfoRightText}>{profile.physically_challenged ? "Yes" : "No"}</Text>
              </View>

            </View>

            <View style={styles.keySkillsBlock}>
              <Text style={styles.blockTitle}>Key Skills</Text>
              <View style={styles.keySkillTagsContainer}>
                {profile.skill_list?.map((item: string, index: number) => (
                  <Text style={styles.keySkillTag}>{item}</Text>
                ))}
              </View>
            </View>
          </View>
        </View>




        <View style={styles.mainColumn}>

          <View style={[styles.card, { paddingHorizontal: "3%" }]}>
            <Text style={styles.blockTitle}>Candidate Details</Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>

              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>EXPERIENCE</Text>
                <Text style={styles.candidateDescriptionText}>{profile.total_experience ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>SALARY</Text>
                <Text style={styles.candidateDescriptionText}>{profile.annual_salary ? "₹ " + profile.annual_salary + " L.P.A" : "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>AGE</Text>
                <Text style={styles.candidateDescriptionText}>{profile.dob ? moment().diff(moment(profile.dob, 'DD-MM-YYYY'), 'years') + " Years" : "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>ROLE</Text>
                <Text style={styles.candidateDescriptionText}>{profile.role ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>INDUSTRY</Text>
                <Text style={styles.candidateDescriptionText}>{profile.industry ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>FUNCTIONAL AREA</Text>
                <Text style={styles.candidateDescriptionText}>{profile.functional_area ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>CURRENT DESIGNATION</Text>
                <Text style={styles.candidateDescriptionText}>{profile.current_designation ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>HIGHEST DEGREE</Text>
                <Text style={styles.candidateDescriptionText}>{profile.highest_degree ?? "-"}</Text>
              </View>


              <View style={styles.candidateItemContainer}>
                <Text style={styles.candidateTitleText}>NOTICE PERIOD</Text>
                <Text style={styles.candidateDescriptionText}>{profile.notice_period ?? "-"}</Text>
              </View>

            </View>

          </View>



          {profile.employment_histories &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]}>
              <Text style={styles.blockTitle}>Work Experience</Text>

              {profile.employment_histories.data.map((item: any, index: number) => (
                <Fragment>
                  <View style={[{ height: 100, flexDirection: "row", padding: 15 }]} >
                    {typeof item.attributes.company_logo === "string" &&
                      <View style={{ flex: 0.2, height: "100%", justifyContent: "center", alignItems: "center" }}>
                     {/*<Image source={{ uri: item.attributes.company_logo } || assets.userProfileImage} resizeMode={"cover"} style={{ height: "100%", width: "100%" }} />*/}
                     <SuspenseImage
                          src={item.attributes.company_logo || assets.userProfileImage}
                          alt='I'
                          resizeMode={"cover"}
                          retry={{ count: 8, delay: 2, accumulate: 'multiply' }}
                          style={{ height: "100%", width: "100%" }}
                        />
                        </View>
                    }
                    <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                      <View>
                        <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "500" }}>{item.attributes.job_title}</Text>
                        <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "500" }}>{item.attributes.company}</Text>
                      </View>
                      <Text style={{ color: "gray", fontSize: 11, marginTop: 8 }}>{moment(item.attributes?.start_date, 'DD-MM-YYYY').format('MMM YYYY')} to {moment(item.attributes?.end_date, 'DD-MM-YYYY').format('MMM YYYY')} {item.attributes.duration && "(" + item.attributes.duration + ")"}</Text>

                    </View>
                  </View>

                  {index !== 0 &&
                    <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 0 }} />
                  }
                </Fragment>
              ))}


            </View>
          }




          {profile.education_qualifications &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]}>
              <Text style={[styles.blockTitle, { marginBottom: 10 }]}>Education Qualification</Text>



              <View style={{ flexDirection: "row", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>

                {profile.education_qualifications.data.map((item: any, index: number) => (
                  <View style={[{ height: 60, width: "49%", flexDirection: "row", padding: 8.5, borderWidth: 1, borderRadius: 5, borderColor: "#eeeeee", marginTop: 10 }]} >

                    <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                      <View>
                        <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "500" }}>{item.attributes.institute}</Text>
                        <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "500" }}>{item.attributes.degree}</Text>
                      </View>
                    </View>

                    {typeof item.attributes.university_logo === "string" &&
                      <View style={{ flex: 0.2, height: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Image source={{ uri: item.attributes.university_logo || assets.userProfileImage }} resizeMode={"cover"} style={{ height: "100%", width: "100%" }} />
                      </View>
                    }

                  </View>
                ))}

              </View>



            </View>
          }

          {profile.it_skills &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]}>
              <Text style={styles.blockTitle}>IT Skills</Text>

              <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12, marginTop: 20 }} />

              <View style={styles.itSkillsItemContainer}>
                <Text style={[styles.itSkillsTitleText, { flex: 1.6 }]}>SKILL NAME</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 1 }]}>VERSION</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 1 }]}>LAST USED</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 1 }]}>EXPERIENCE</Text>
              </View>

              <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12 }} />


              {profile.it_skills.data.map((item: any, index: number) => (
                <Fragment>
                  <View style={styles.itSkillsItemContainer}>
                    <Text style={[styles.itSkillsDescriptionText, { flex: 1.6 }]}>{item.attributes.skill_name}</Text>
                    <Text style={[styles.itSkillsDescriptionText, { flex: 1 }]}>{item.attributes.version}</Text>
                    <Text style={[styles.itSkillsDescriptionText, { flex: 1 }]}>{item.attributes.last_used}</Text>
                    <Text style={[styles.itSkillsDescriptionText, { flex: 1 }]}>{item.attributes.working_duration}</Text>
                  </View>

                  {index !== profile.it_skills.data.length - 1 &&
                    <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12 }} />
                  }
                </Fragment>
              ))}

            </View>
          }

          {profile.language_details &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]}>
              <Text style={styles.blockTitle}>Language Known</Text>

              <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12, marginTop: 20 }} />

              <View style={styles.itSkillsItemContainer}>
                <Text style={[styles.itSkillsTitleText, { flex: 1.4 }]}>LANGUAGE</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 1 }]}>PROFICIENCY</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 0.8 }]}>READ</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 0.8 }]}>WRITE</Text>
                <Text style={[styles.itSkillsTitleText, { flex: 0.8 }]}>SPEAK</Text>
              </View>

              <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12 }} />

              {profile.language_details.data.map((item: any, index: number) => (
                <Fragment>
                  <View style={styles.languageItemContainer}>
                    <Text style={[styles.languageDescriptionText, { flex: 1.4 }]}>{item.attributes.name}</Text>
                    <Text style={[styles.languageDescriptionText, { flex: 1 }]}>{item.attributes.proficiency}</Text>
                    <View style={{ flex: 0.8 }} >
                      {item.attributes.read &&
                        <CheckBox value={item.attributes.read} onChange={() => null} />
                      }
                    </View>
                    <View style={{ flex: 0.8 }}>
                      {item.attributes.write &&
                        <CheckBox value={item.attributes.write} onChange={() => null} />
                      }
                    </View>
                    <View style={{ flex: 0.8 }}>
                      {item.attributes.speak &&
                        <CheckBox value={item.attributes.speak} onChange={() => null} />
                      }
                    </View>
                  </View>
                  {index !== profile.language_details.data.length - 1 &&
                    <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginVertical: 12 }} />
                  }
                </Fragment>
              ))}

            </View>
          }


          {profile.project_details &&
            <View style={[styles.card, { paddingHorizontal: "3%", marginTop: 24 }]}>
              <Text style={[styles.blockTitle, { marginBottom: 20 }]}>Projects</Text>

              {profile.project_details.data.map((item: any, index: number) => (
                <Fragment>
                  {index !== 0 &&
                    <View style={{ width: "100%", height: 1, backgroundColor: "whitesmoke", marginBottom: 20, marginTop: 12 }} />
                  }
                  <View >
                    <Text style={styles.projectTitleText}>{item.attributes.title}</Text>
                    <Text style={[styles.projectDimText, { marginTop: 4 }]}>{item.attributes.role}</Text>
                    <Text style={[styles.projectDarkText, { marginTop: 8 }]}><Text style={styles.projectDimText}>Skill Used: </Text>{item.attributes.skill_list?.join(", ")}</Text>
                    <Text style={[styles.projectDarkText, { marginTop: 8 }]}><Text style={styles.projectDimText}>Role Description: </Text>{item.attributes.role_description}</Text>
                    <Text style={[styles.projectDarkText, { marginTop: 8 }]}><Text style={styles.projectDimText}>Project Description: </Text>{item.attributes.project_description}</Text>
                  </View>
                </Fragment>
              ))}

            </View>
          }


        </View>





        <View style={styles.rightColumn}>

          <View style={styles.card}>
            {profile.profile_completion_percentage >= 0 &&
              <PrettoSlider valueLabelDisplay="off" aria-label="pretto slider" value={profile.profile_completion_percentage} />
            }
            <Text style={styles.blockTitle}>Profile is completed in {profile.profile_completion_percentage}%</Text>

            {profile.profile_completion_pending?.length > 0 &&
              <Fragment>
                <Text style={{ fontSize: 10, marginTop: 4, color: "#1a181d" }}>Want to get better matched offer?</Text>
                <Text style={{ fontSize: 12, color: "rgba(26, 24, 29, 0.7)", marginTop: 15 }}><Text style={{ color: "#e45d33", fontSize: 12 }} onPress={() => this.props.navigation.navigate("UserProfileBasicEditBlock")}>Boost your profile</Text> by suggested below:</Text>
              </Fragment>
            }
          </View>


          {profile.profile_completion_pending?.length > 0 &&
            <View style={[styles.card, { marginTop: 24 }]}>
              <Text style={[styles.blockTitle, { marginBottom: 15 }]}>Add Information</Text>

              {profile.profile_completion_pending.map((item: any, index: number) => (
                <TouchableOpacity style={styles.addInfoContainer} onPress={() => this.navigateToSection(item)}>
                  <View style={styles.addInfoLeftWrapper}>
                    <FiCircle style={styles.addInfoLeftIcon} />
                    <Text style={styles.addInfoText}>Add {item}</Text>
                  </View>
                  <BsArrowRight style={styles.addInfoRightIcon} />
                </TouchableOpacity>
              ))}

            </View>
          }

        </View>
      </View >
    );




    return (
      //Required for all blocks
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        {/* Required for all blocks */}
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View>
              <Text style={styles.titleWhySignUp}>{this.labelHeader}</Text>

              <Text style={styles.title}>{this.labelFirstName}</Text>
              <TextInput
                testID={"txtInputFirstName"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.labelFirstName} //UI Engine::From Sketch
                {...this.txtInputFirstNameProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.lastName}</Text>
              <TextInput
                testID={"txtInputLastName"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.lastName} //UI Engine::From Sketch
                {...this.txtInputLastNameProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.labelArea}</Text>
              <CountryCodeSelector
                allowPropChange={true}     //Merge Engine::From BDS
                navigation={navigation}    //Merge Engine::From BDS
                id={"CountryCodeSelector"} //Merge Engine::From BDS
                placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                style={styles.bgRectBorder} //UI Engine::From Sketch
                disable={!this.state.countryCodeEnabled}  //Merge Engine::From BDS
                value={this.state.currentCountryCode} //Merge Engine::From BDS
              />

              <Text style={styles.title}>{this.labelMobile}</Text>
              <TextInput
                testID={"txtInputPhoneNumber"} //Merge Engine::From BDS
                style={styles.bgMobileInput} //UI Engine::From Sketch
                placeholder={this.labelMobile} //UI Engine::From Sketch
                {...this.txtInputPhoneNumberProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <Text style={styles.title}>{this.labelEmail}</Text>
              <TextInput
                testID={"txtInputEmail"} //Merge Engine::From BDS
                style={styles.bgInput} //UI Engine::From Sketch
                placeholder={this.labelEmail} //UI Engine::From Sketch
                {...this.txtInputEmailProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              {this.state.llChangePwdDummyShowContainerVisible ? (
                <View>
                  <Text style={styles.title}>{this.labelCurrentPassword}</Text>
                  <Text style={styles.bgDummyPassword}>*********</Text>

                  <View style={{ marginTop: 24, marginBottom: 16 }}>
                    <Button
                      testID={"btnEnableEditPassword"} //Merge Engine::From BDS
                      title={this.btnTextChangePassword}  //UI Engine::From Sketch
                      color="#6200EE"                     //UI Engine::From Sketch
                      {...this.btnEnableEditPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />
                  </View>
                </View>
              ) : null}

              {this.state.llDoChangePwdContainerVisible ? (
                <View>
                  <Text style={styles.title} //UI Engine::From Sketch
                  >
                    {this.labelCurrentPassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View
                    style={styles.bgPasswordContainer} //UI Engine::From Sketch
                  >
                    <TextInput
                      testID={"txtInputCurrentPassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelCurrentPassword} //UI Engine::From Sketch
                      {...this.txtInputCurrentPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btnPasswordShowHideButton"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnPasswordShowHideButtonProps} //Merge Engine::From BDS - {...this.testIDProps}
                    >
                      <Image
                        testID={"imgPasswordShowhide"}  //Merge Engine::From BDS
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgPasswordShowhideProps} //Merge Engine::From BDS - {...this.testIDProps}
                      />
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={styles.title} //UI Engine::From Sketch
                  >{this.labelNewPassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View style={styles.bgPasswordContainer} //UI Engine::From Sketch
                  >
                    <TextInput
                      testID={"txtInputNewPassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelNewPassword} //UI Engine::From Sketch
                      {...this.txtInputNewPasswordProps}  //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btntNewPasswordShowHideButton"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnNewPasswordShowHideButtonProps}
                    >
                      <Image
                        testID={"imgNewPasswordShowhide"}  //Merge Engine::From BDS
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgNewPasswordShowhideProps} //Merge Engine::From BDS - {...this.testIDProps}

                      />
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={styles.helperText} //UI Engine::From Sketch
                  >
                    {this.state.passwordHelperText} {/*UI Engine::From Sketch*/}
                  </Text>

                  <Text
                    style={styles.title} //UI Engine::From Sketch
                  >
                    {this.labelRePassword}
                    {/*UI Engine::From Sketch*/}
                  </Text>
                  <View style={styles.bgPasswordContainer}>
                    <TextInput
                      testID={"txtInputReTypePassword"} //Merge Engine::From BDS
                      style={styles.bgPasswordInput} //UI Engine::From Sketch
                      placeholder={this.labelRePassword} //UI Engine::From Sketch
                      {...this.txtInputReTypePasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />

                    <TouchableOpacity
                      testID={"btnReTypePasswordShowHide"} //Merge Engine::From BDS
                      style={styles.passwordShowHide} //UI Engine::From Sketch
                      {...this.btnReTypePasswordShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}}
                    >
                      <Image
                        testID="imgReTypePasswordShowhide"
                        style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                        {...this.imgReTypePasswordShowhideProps}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={{ marginTop: 24, marginBottom: 0 }}>
                    <Button
                      testID={"btnDisableEditPassword"} //Merge Engine::From BDS
                      title={this.btnTextCancelPasswordChange} //UI Engine::From Sketch
                      color="#767676" //UI Engine::From Sketch
                      {...this.btnDisableEditPasswordProps}
                    />
                  </View>
                </View>
              ) : null}

              <View style={{ marginTop: 24, marginBottom: 32 }}>
                <Button
                  testID={"btnSubmit"} //Merge Engine::From BDS
                  title={this.btnTextSaveChanges} //UI Engine::From Sketch
                  color="#6200EE"
                  disabled={this.state.saveButtonDisable}
                  onPress={() => this.validateMobileAndThenUpdateUserProfile()}
                />
              </View>
            </View>
            {/* Customizable Area End */}
            {/* Merge Engine UI Engine Code */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    //this.getValidations();
    //this.requestSessionData();

    this.setState({ loading: true }, () => {
      this.fetchProfile();
    });
  }
}
// Customizable Area Start


const PrettoSlider = withStyles({
  root: {
    color: '#299c14',
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -8,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#299c14"
  },
  rail: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "lightgray"
  },
})(Slider);









// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  leftColumn: {
    width: "24%"
  },
  mainColumn: {
    width: "48%"
  },
  rightColumn: {
    width: "24%"
  },
  avatarBlock: {
    borderBottomWidth: 1,
    borderBottomColor: "whitesmoke"
  },
  avatarBackground: {
    backgroundColor: "#141d3d",
    height: 120,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  profilePicture: {
    height: 100,
    width: 100,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "rgba(26, 24, 29, 0.32)",
    marginBottom: -40,
    backgroundColor: "white"
  },
  profileName: {
    fontSize: 20,
    color: "#1a181d"
  },
  profileOccupation: {
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: 5
  },
  profileLocation: {
    color: "rgba(0, 0, 0, 0.6)",
    alignItems: "center",
    textAlignVertical: "center",
    marginTop: 10,
    marginBottom: 30
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  personalInfoBlock: {
    paddingHorizontal: "5%",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: "whitesmoke"
  },
  personalInfoRow: {
    flexDirection: "row",
    marginTop: 12
  }
  ,
  personalInfoLeftText: {
    color: "rgba(65, 65, 65, 0.87)",
    flex: 1,
    fontSize: 12
  },
  personalInfoRightText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 12
  }
  ,
  blockTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 18
  },
  keySkillsBlock: {
    padding: "5%"
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    marginVertical: 18,
    flexWrap: "wrap"
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 5,
    marginTop: 5
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 5,
    paddingHorizontal: "5%",
    paddingVertical: 18
  },
  addInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 15,
    borderTopColor: "whitesmoke",
    borderTopWidth: 1
  },
  addInfoLeftWrapper: {
    flexDirection: "row",
    alignItems: "center"
  },
  addInfoLeftIcon: {
    fontSize: 18,
    color: "#e45d33"
  },
  addInfoRightIcon: {
    fontSize: 16,
    color: "#1a181d",
    float: "right"
  },
  addInfoText: {
    marginLeft: 10,
    fontSize: 14,
    color: "#e45d33"
  },
  candidateItemContainer: {
    marginTop: 20,
    width: "33.333333%"
  },
  candidateTitleText: {
    color: "rgba(0, 0, 0, 0.38)",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
  },
  candidateDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11,
    marginTop: 5,
    letterSpacing: 0.25
  },
  projectDimText: {
    color: "rgba(0, 0, 0, 0.6)",
    flex: 1,
    fontSize: 10
  },
  projectDarkText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    marginTop: 5
  },
  projectTitleText: {
    fontSize: 14
  },
  itSkillsItemContainer: {
    width: "100%",
    flexDirection: "row"
  },
  itSkillsTitleText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
    fontWeight: "500"
  },
  itSkillsDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 11
  },
  languageItemContainer: {
    width: "100%",
    flexDirection: "row"
  },
  languageTitleText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 10,
    letterSpacing: 2,
    fontWeight: "500"
  },
  languageDescriptionText: {
    color: "#1a181d",
    flex: 1,
    fontSize: 12,
    fontWeight: "500"
  }

}

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {

  }),
  // below  768
  maxWidth(768, {
    leftColumn: {
      width: "100%"
    },
    mainColumn: {
      width: "100%",
      order: 3
    },
    rightColumn: {
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
  }),
);

// Customizable Area End