import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageNumber: any;
  perPageRecord: any;
  search_keyword: any;
  searchSkill: any;
  order_by: any;
  direction: any;
  paginationDetails: any;
  editOpen: boolean;
  deleteOpen: boolean;
  createOrEditOpen: boolean;
  firstTimeEdit:boolean;
  deleteOneId: any;
  deleteAllId: any;
  multiSelectArray: any;
  isDisableOrEnable: any;
  allChecked: boolean;
  displayNone: boolean;
  selectedFile: any;
  imageUrl: any;
  fileName: string;
  sizeAndImage: boolean;
  disableButton: boolean;
  token: string;
  skillDetailArray: any;
  getOneSkillData: any;
  searchSkillData: any;
  updateId:any;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  auth: string | null | undefined;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SkillController extends BlockComponent<Props, S, SS> {
  auth: string | null | undefined = window.localStorage.getItem("auth");
  getAllSkillApiCallId: any;
  getSearchSkillApiCallId: any;
  getOneSkillApiCallId: any;
  deleteSkillApiCallId: any;
  createNewSkillApiCallId: any;
  updateSkillApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      pageNumber: 1,
      perPageRecord: 40,
      search_keyword: "",
      order_by: "name",
      direction: "default",
      paginationDetails: {},
      editOpen: false,
      createOrEditOpen: false,
      deleteOpen: false,
      allChecked: false,
      deleteOneId: null,
      deleteAllId: [],
      multiSelectArray: [],
      isDisableOrEnable: true,
      selectedFile: "",
      imageUrl: null,
      fileName: "",
      sizeAndImage: false,
      searchSkill: "",
      updateId:'',
      token: "",
      skillDetailArray: [],
      getOneSkillData: {},
      searchSkillData: [],
      disableButton: false,
      categoriesArray: [],
      firstTimeEdit:false,
      displayNone: true,
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      auth: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.getAllSkill(
      this.state.pageNumber,
      this.state.perPageRecord,
      this.state.search_keyword,
      this.state.order_by,
      this.state.direction
    );
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.perPageRecord !== this.state.perPageRecord ||
      prevState.search_keyword !== this.state.search_keyword ||
      prevState.order_by !== this.state.order_by ||
      prevState.direction !== this.state.direction
    ) {
      // console.log(prevState);
      this.getAllSkill(
        this.state.pageNumber,
        this.state.perPageRecord,
        this.state.search_keyword,
        this.state.order_by,
        this.state.direction
      );
    } else if (
      prevState.searchSkill !== this.state.searchSkill
    ) {
      this.getSearchSkill(this.state.searchSkill);
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data || responseJson.deleted_skills) {
        if (apiRequestCallId === this.getAllSkillApiCallId) {
          let array = responseJson.data;
          let pagination = responseJson.meta.pagination;

          console.log(pagination);
          array.map((val: any) => {
            val.isChecked = false;
          });
          console.log("array check =====>", array);
          this.setState({
            skillDetailArray: array,
            paginationDetails: pagination,
          });
        } else if (apiRequestCallId === this.getOneSkillApiCallId) {
          console.log("getOneCompanyApiCallId========> ", responseJson.data);
          this.setState({
            editOpen: true,
            firstTimeEdit:true,
            createOrEditOpen: true, 
            searchSkill:responseJson.data.attributes?.name, 
            disableButton:false
          });
        } else if (apiRequestCallId === this.deleteSkillApiCallId) {
          console.log(
            "deleteSkillApiCallId ========> ",
            responseJson.deleted_companies
          );
          this.setState({
            deleteOpen: false,
          });
          this.getAllSkill(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        } else if (apiRequestCallId === this.createNewSkillApiCallId) {
          console.log("createNewSkillApiCallId========> ", responseJson.data);
          this.setState({
            createOrEditOpen: false,
            searchSkill: "",
          });
          this.getAllSkill(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        } else if (apiRequestCallId === this.updateSkillApiCallId) {
          console.log("updateSkillApiCallId========> ", responseJson.data);
          this.setState({
            createOrEditOpen: false,
            editOpen:false,
          });
          this.getAllSkill(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        } else if (apiRequestCallId === this.getSearchSkillApiCallId) {
          console.log("getSearchSkillApiCallId========> ", responseJson.data);
          let data: any = responseJson.data;
          let searchableWord = this.state.searchSkill.toUpperCase();
          let disable: boolean = true;
          console.log("disable searchableWord========> ", searchableWord);
          if(searchableWord===''){
            disable=false;
            this.setState({
              disableButton: disable,
            });
          }
          else{
            data.map((val: any) => {
              let value=val.attributes.name.toUpperCase()
              if (value === searchableWord) {
               disable=false
                this.setState({
                  disableButton: disable,
                });
              }
            });
            console.log("disable========> ", disable);
            if (disable) {
              this.setState({
                disableButton: disable,
              });
            }
            this.setState({
              searchSkillData: data,
            });
          }
          
        }
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  serachClickHandler = (value: any) => {
    console.log("serachClickHandler+++++++++++++++++++++>", value);
    this.setState({
      searchSkill: value,
      displayNone: false,
      disableButton: false,
    });
  };

  searchSkillHandler = (evt: any) => {
    let temp = evt.target.value;
    console.log("searchSkillHandler ======================>", temp);
    this.setState({
      searchSkill: temp,
      firstTimeEdit:false,
      displayNone: true,
    });

    this.getSearchSkill(this.state.searchSkill)
  };
  onChangeHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("serach_by ======================>", temp);
    this.setState({
      pageNumber: 1,
      perPageRecord: 40,
      search_keyword: temp,
    });
  };
  perPageRecordHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("perPageRecord ======================>", temp);
    this.setState({
      perPageRecord: temp,
    });
  };

  directionHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("direction ======================>", temp);
    this.setState({
      direction: temp,
    });
  };
  prevPageHandler = () => {
    const temp = this.state.paginationDetails.prev_page;
    console.log("prevPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };

  nextPageHandler = () => {
    const temp = this.state.paginationDetails.next_page;
    console.log("nextPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };

  // Customizable Area Start
  isDeleteButtonDisable = () => {
    let skillDetailArray = [...this.state.skillDetailArray];
    for (let index = 0; index < skillDetailArray.length; index++) {
      const element = skillDetailArray[index];
      if (element.isChecked) {
        return false;
      }
    }
    return true;
  };
  selectAllHandler = () => {
    let arrayIds: any = [];
    let isDisableOrEnable: any;
    this.state.skillDetailArray.map((val: any) => {
      val.isChecked = this.state.allChecked ? false : true;
      if (!this.state.allChecked) {
        arrayIds.push(val.id);
      }
    });
    console.log(
      "this.state.allChecked=====>)",
      this.state.allChecked,
      arrayIds
    );
    if (this.state.allChecked) {
      isDisableOrEnable = true;
    } else {
      isDisableOrEnable = false;
    }
    this.setState({
      skillDetailArray: this.state.skillDetailArray,
      allChecked: !this.state.allChecked,
      multiSelectArray: arrayIds,
      isDisableOrEnable,
    });
  };

  multiSelectHandler = (index: any, id: any) => {
    let isDisableOrEnable: any;
    let array: any;
    array = [...this.state.multiSelectArray];
    let idCheckedOrNot: any = array.indexOf(id);
    console.log("idCheckedOrNot ====>", idCheckedOrNot);
    if (idCheckedOrNot === -1) {
      array.push(id);
      console.log("array push ====>", array);
    } else {
      array = array.filter((ids: any) => ids != id);
      console.log("array pop ====>", array);
    }
    let skillDetailArray = [...this.state.skillDetailArray];
    skillDetailArray[index].isChecked = !skillDetailArray[index].isChecked;
    if (this.isDeleteButtonDisable()) {
      isDisableOrEnable = true;
    } else {
      isDisableOrEnable = false;
    }
    this.setState({
      skillDetailArray,
      multiSelectArray: array,
      isDisableOrEnable,
    });
  };

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus,
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  deleteCompany = () => {
    console.log(
      "id of array delete  this.state.multiSelectArray=========>",
      this.state.multiSelectArray
    );
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSkillApiCallId = requestMessage.messageId;
    if (this.state.deleteOneId) {
      let id = [];
      id.push(this.state.deleteOneId);
      this.setState({ deleteOneId: null });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteSkillAPIEndPoint + `[${id}]`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteSkillAPIEndPoint + `[${this.state.multiSelectArray}]`
      );
      this.setState({
        multiSelectArray: [],
        allChecked: false,
        isDisableOrEnable: true,
      });
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  createNewCompany = (value: any) => {
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    console.log("values create==================>", value);

    const httpBody = {
      skill: {
        name: value,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewSkillApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewSkillAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  upateCompanyId = (value: any) => {
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    console.log("update api  values==================>", value);
   
    const httpBody = {
      skill: {
        name: value,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSkillApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSkillAPIEndPoint + `${this.state.updateId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getOneSkill = (id: any) => {
    this.setState({updateId:id})
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOneSkillApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOneSkillAPIEndPoint + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSkill = (
    pageNumber: any,
    perPageRecord: any,
    search_keyword: any,
    order_by: any,
    direction: any
  ) => {
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSkillApiCallId = requestMessage.messageId;
    let getAllSkillAPIEndPoint;
    if (search_keyword !== "" && direction === "default") {
      console.log(
        "search_keyword!==emtpy && direction === default",
        direction,
        this.state.search_keyword
      );
      getAllSkillAPIEndPoint = `skills?page=${pageNumber}&per=${perPageRecord}&search_keyword=${search_keyword}`;
    } else if (search_keyword === "" && direction === "default") {
      console.log(
        "search_keyword ==emtpy && direction === default",
        direction,
        this.state.direction
      );
      getAllSkillAPIEndPoint = `skills?page=${pageNumber}&per=${perPageRecord}`;
    } else {
      getAllSkillAPIEndPoint = `skills?page=${pageNumber}&per=${perPageRecord}&search_keyword=${search_keyword}&order_by=${order_by}&direction=${direction}`;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAllSkillAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSearchSkill = (searchSkill: any) => {
    const header = {
      "Content-Type": configJSON.skillApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchSkillApiCallId = requestMessage.messageId;

    let getSearchSkillAPIEndPoint;
    getSearchSkillAPIEndPoint = `skills?search_keyword=${searchSkill}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSearchSkillAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}