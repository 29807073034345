import React, { Fragment } from "react";
// Customizable Area Start
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Link, Button, IconButton, Icon, CircularProgress } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import UpdateStatusModal from './UpdateStatusModal.web';
import { editIcon, showIcon, hideIcon, dragIcon } from "./assets";
// Customizable Area End

import CustomisedOrderStatusController, {
    Props,
    configJSON
} from "./CustomisedOrderStatusController";


class CustomisedOrderStatus extends CustomisedOrderStatusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { statusList, hoveredStatusIndex } = this.state
        return (
            <Box className={classes.container} >
                <Box className={classes.statusListContainer} >
                    <Grid sm={12} container={true} style={{ height: '100%' }} >
                        <Grid sm={12} container={true} >
                            <Grid sm={9} container={true} >
                                <Box style={{ padding: 24 }} >
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#1a181d'
                                    }} >Status</Typography>
                                    <Typography style={{
                                        opacity: 0.4,
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }}>Lorem Ipsume demo contetn should be here so we can see how it works.</Typography>
                                </Box>
                            </Grid>
                            <Grid justify='flex-end' sm={3} container={true} >
                                <Box style={{
                                    paddingTop: 24,
                                    paddingRight: 24,
                                }} >
                                    <Link href='/admin/system-management/status/activity-log' underline='none' style={{
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: '#e45d33'
                                    }}>Activity log</Link>
                                </Box>
                            </Grid>
                        </Grid>
                        {!statusList.loading && statusList.data.length > 0 && (
                            <DragDropContext onDragEnd={this.handleOnDragEnd}>
                                <Grid sm={12} container={true} style={{ minHeight: '78%' }} >
                                    {statusList.data.map((status: any, index: number) => (
                                        <Droppable key={status.id} droppableId={status.attributes.status_heading_type}>
                                            {(provided, snapshot) => (
                                                <Grid  {...provided.droppableProps} ref={provided.innerRef} md={4} container={true} style={{ height: 'auto', display: 'block', borderRight: statusList.data.length - 1 === index ? 'none' : '1px solid rgba(221, 221, 221,0.57)', borderTop: '1px solid rgba(221, 221, 221,0.57)', }} >
                                                    <Grid sm={12} container={true} style={{ height: 56, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} >
                                                        <Grid alignItems='center' sm={6} container={true} style={{ paddingLeft: 24 }} >
                                                            <Typography style={{
                                                                fontFamily: 'Poppins',
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                color: 'rgba(0, 0, 0, 0.87)'
                                                            }} >{status.attributes.status_heading_type_humanized || this.capitalize(status.attributes.status_heading_type, 1)}</Typography>
                                                        </Grid>
                                                        <Grid alignItems='center' sm={2} container={true}  >
                                                            <Typography style={{
                                                                fontFamily: 'Poppins',
                                                                fontSize: 12,
                                                                fontWeight: 'normal',
                                                                color: 'rgba(0, 0, 0, 0.6)'
                                                            }} >Index</Typography>
                                                        </Grid>
                                                        <Grid alignItems='center' sm={2} container={true}  >
                                                            <Typography style={{
                                                                fontFamily: 'Poppins',
                                                                fontSize: 12,
                                                                fontWeight: 'normal',
                                                                color: 'rgba(0, 0, 0, 0.6)'
                                                            }} >Status</Typography>
                                                        </Grid>
                                                        <Grid alignItems='center' sm={2} container={true} style={{ opacity: 0.7 }} >
                                                            <IconButton onClick={() => this.setState({ updateStatusObj: _.cloneDeep(status) })} >
                                                                <Icon >
                                                                    <img style={{ height: 18, width: 18 }} src={editIcon} />
                                                                </Icon>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    {status.attributes.status_heads.data.map((statusHead: any, i: number) => (
                                                        <Draggable key={`${index}-${i}`} draggableId={`${index}-${i}`} index={i}>
                                                            {(provided, snapshot) => (
                                                                <Grid ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps} onMouseOver={() => { this.setState({ hoveredStatusIndex: `${index}-${i}` }) }} onMouseLeave={() => { this.setState({ hoveredStatusIndex: "" }) }} sm={12} container={true} style={{ cursor: 'default', minHeight: 52, marginTop: i === 0 ? 0 : 8, marginBottom: i === status.attributes.status_heads.data.length - 1 ? 2 : 0, backgroundColor: hoveredStatusIndex === `${index}-${i}` ? '#f1f1f1' : "#fcfcfc", ...provided.draggableProps.style }} >
                                                                    <Grid alignItems='center' sm={hoveredStatusIndex === `${index}-${i}` ? 5 : 6} container={true} style={{ paddingLeft: 24 }} >
                                                                        <Typography style={{
                                                                            fontFamily: 'Poppins',
                                                                            fontSize: 14,
                                                                            fontWeight: 'normal',
                                                                            color: 'rgba(0, 0, 0, 0.87)'
                                                                        }} >{statusHead.attributes.name}</Typography>
                                                                    </Grid>
                                                                    {
                                                                        hoveredStatusIndex === `${index}-${i}` ? (
                                                                            <Grid alignItems='center' justify='space-between' style={{ width: '100%' }} sm={7} container={true}  >
                                                                                <Grid alignItems='center' sm={12} container={true}  >
                                                                                    <Grid alignItems='center' sm={7} container={true} justify='space-evenly' >
                                                                                        <Button onClick={() => this.handleStartEnd(status.id, statusHead.id, true)} style={{
                                                                                            fontFamily: 'Poppins',
                                                                                            fontSize: 12,
                                                                                            fontWeight: 'normal',
                                                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                                                            padding: 3,
                                                                                            textTransform: 'none'
                                                                                        }} >Mark Start</Button>
                                                                                        <Button onClick={() => this.handleStartEnd(status.id, statusHead.id, false)} style={{
                                                                                            fontFamily: 'Poppins',
                                                                                            fontSize: 12,
                                                                                            fontWeight: 'normal',
                                                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                                                            padding: 3,
                                                                                            textTransform: 'none',
                                                                                        }} >Mark End</Button>
                                                                                    </Grid>
                                                                                    <Grid alignItems='center' sm={5} container={true} justify='space-evenly'  >
                                                                                        <IconButton onClick={() => this.handleShowHide(status.id, statusHead.id, !statusHead.attributes.is_visible)} style={{ padding: 0 }} >
                                                                                            <Icon >
                                                                                                <img style={{ height: 18, width: 18, opacity: statusHead.attributes.is_visible ? 1 : 0.67 }} src={statusHead.attributes.is_visible ? showIcon : hideIcon} />
                                                                                            </Icon>
                                                                                        </IconButton>
                                                                                        <img style={{ height: 18, width: 18, opacity: 0.57, cursor: 'grab' }} src={dragIcon} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <Grid alignItems='center' sm={2} container={true}  >
                                                                                    <Typography style={{
                                                                                        fontFamily: 'Poppins',
                                                                                        fontSize: 12,
                                                                                        fontWeight: 'normal',
                                                                                        color: 'rgba(0, 0, 0, 0.6)'
                                                                                    }} >{i}</Typography>
                                                                                </Grid>
                                                                                <Grid alignItems='center' sm={4} container={true}  >
                                                                                    {(statusHead.attributes.start || statusHead.attributes.end) && (
                                                                                        <Box display='flex' justifyContent='center' alignItems='center' height={18} width={statusHead.attributes.start ? 73 : 50} bgcolor='#e45d33' borderRadius={4}  >
                                                                                            <Box height={4} width={4} bgcolor='#ffffff' borderRadius={2} marginRight='6px' />
                                                                                            <Typography style={{
                                                                                                fontFamily: 'Poppins',
                                                                                                fontSize: 12,
                                                                                                fontWeight: 'normal',
                                                                                                color: 'rgba(255, 255, 255, 0.87)'
                                                                                            }} >{statusHead.attributes.start ? 'Started' : 'End'}</Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                </Grid>
                                                                            </Fragment>
                                                                        )
                                                                    }

                                                                </Grid>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    ))}
                                </Grid>
                                <UpdateStatusModal
                                    updateStatusObj={this.state.updateStatusObj}
                                    newStatusHead={this.state.newStatusHead}
                                    showNewStatusHead={this.state.showNewStatusHead}
                                    updateLoading={this.state.updateLoading}
                                    updateIndexHandler={this.updateIndexHandler}
                                    updateVisibilityHandler={this.updateVisibilityHandler}
                                    changeTextHandler={this.changeTextHandler}
                                    closeHandler={this.modalCloseHandler}
                                    showNewStatusFieldHandler={this.showNewStatusFieldHandler}
                                    newStatusChangeHandler={this.newStatusChangeHandler}
                                    newStatusBlurHandler={this.newStatusBlurHandler}
                                    updateStatusHandler={this.updateStatusHandler}
                                />
                            </DragDropContext>
                        )}
                        {statusList.loading && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='78%' >
                                <CircularProgress style={{ color: "#e45d33" }} />
                            </Box>
                        )}
                    </Grid>
                </Box>
            </Box>
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: "9.5rem",
        paddingBottom: "7.8%",
        backgroundColor: "#fcfcfc",
    },
    statusListContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '85%',
        borderRadius: 2,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        overflow: 'hidden'
    }
});


export default withStyles(styles)(CustomisedOrderStatus)
// Customizable Area End
