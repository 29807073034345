import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as yup from 'yup';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageNumber: any;
  perPageRecord: any;
  search_keyword: any;
  order_by: any;
  direction: any;
  paginationDetails: any;
  editOpen: boolean;
  deleteOpen: boolean;
  createOrEditOpen: boolean;
  deleteOneId: any;
  deleteAllId: any;
  multiSelectArray:any;
  isDisableOrEnable:any;
  allChecked: boolean;
  selectedFile:any;
  imageUrl:any;
  fileName:string;
  sizeAndImage:boolean;
  token: string;
  companyDetailArray: any;
  getOneCompanyData:any;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  auth: string | null | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CompanyController extends BlockComponent<Props, S, SS> {
  auth: string | null | undefined = window.localStorage.getItem("auth");
  getCategoriesApiCallId: any;
  getOneCompanyApiCallId:any;
  getAllCompanyApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteCompanyApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  createNewCompanyApiCallId:any;
  updateCompanyApiCallId:any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      pageNumber: 1,
      perPageRecord: 40,
      search_keyword:"",
      order_by: "name",
      direction: "default",
      paginationDetails: {},
      editOpen: false,
      createOrEditOpen: false,
      deleteOpen: false,
      allChecked: false,
      deleteOneId: null,
      deleteAllId: [],
      multiSelectArray:[],
      isDisableOrEnable:true,
      selectedFile:'',
      imageUrl:null,
      fileName:'',
      sizeAndImage:false,
      token: "",
      companyDetailArray: [],
      getOneCompanyData:{},
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      auth: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();

    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.getAllCompany(
      this.state.pageNumber,
      this.state.perPageRecord,
      this.state.search_keyword,
      this.state.order_by,
      this.state.direction
    );
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.perPageRecord !== this.state.perPageRecord ||
      prevState.search_keyword !== this.state.search_keyword ||
      prevState.order_by !== this.state.order_by ||
      prevState.direction !== this.state.direction
    ) {
      // console.log(prevState);
      this.getAllCompany(
        this.state.pageNumber,
        this.state.perPageRecord,
        this.state.search_keyword,
        this.state.order_by,
        this.state.direction
      );
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data || responseJson.deleted_companies) {
        if (apiRequestCallId === this.getAllCompanyApiCallId) {
          // alert(JSON.stringify(responseJson.data.faqs))
          let array = responseJson.data;
          let pagination = responseJson.meta.pagination;

          console.log(pagination);
          array.map((val: any) => {
            val.isChecked = false;
          });
          console.log("array check =====>", array);
          this.setState({
            companyDetailArray: array,
            paginationDetails: pagination,
          });
        } else if (apiRequestCallId === this.deleteCompanyApiCallId) {
          console.log(
            "deleteCompanyApiCallId ========> ",
            responseJson.deleted_companies , responseJson.data
          );
          this.setState({
            deleteOpen: false,
          });
          this.getAllCompany(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        } else if (apiRequestCallId === this.createNewCompanyApiCallId) {
          console.log("createNewCompanyApiCallId========> ",responseJson.data);
          this.setState({
            createOrEditOpen:false,
            imageUrl:null
          })
          this.getAllCompany(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        }
        else if (apiRequestCallId === this.getOneCompanyApiCallId) {
          console.log("getOneCompanyApiCallId========> ",responseJson.data);
          this.setState({
            editOpen:true,
            createOrEditOpen:true,
            getOneCompanyData: responseJson.data,
            imageUrl:responseJson.data.attributes?.logo
          })
        }
        else if (apiRequestCallId === this.updateCompanyApiCallId) {
          console.log("updateCompanyApiCallId========> ",responseJson.data);
          this.setState({
            createOrEditOpen:false,
            getOneCompanyData:{},
          })
          this.getAllCompany(
            this.state.pageNumber,
            this.state.perPageRecord,
            this.state.search_keyword,
            this.state.order_by,
            this.state.direction
          );
        }
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories(this.state.token);
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories(this.state.token);
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (apiRequestCallId === this.createNewCompanyApiCallId && responseJson.errors) {
        this.setState({
          createOrEditOpen:false,
          imageUrl:null,
          selectedFile:''
        })
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.updateCompanyApiCallId && responseJson.errors) {
        this.setState({
          createOrEditOpen:false,
          getOneCompanyData:{},
        })
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  selectAllHandler = () => {
    let arrayIds: any = [];
    let isDisableOrEnable:any ;

    this.state.companyDetailArray.map((val: any) => {
      val.isChecked = this.state.allChecked ? false : true;
      if (!this.state.allChecked) {
        arrayIds.push(val.id);
      }
    });
    console.log("this.state.allChecked)", this.state.allChecked, arrayIds);
    if(this.state.allChecked){
      isDisableOrEnable=true;
    }
    else {
      isDisableOrEnable=false ;
    }
    this.setState({
      companyDetailArray: this.state.companyDetailArray,
      allChecked: !this.state.allChecked,
     // deleteAllId: arrayIds,
     multiSelectArray:arrayIds,
      isDisableOrEnable
    });
  };

  isDeleteButtonDisable=()=>{
    let companyDetailArray =[...this.state.companyDetailArray]
    for (let index = 0; index < companyDetailArray.length; index++) {
      const element = companyDetailArray[index];
      if(element.isChecked){
        return false;
      }
      
    }
    return true;
  }
  multiSelectHandler = (index :any,id: any ) => {
    let isDisableOrEnable :any ;
    let array :any ;
    array  =[...this.state.multiSelectArray]
   let idCheckedOrNot :any = array.indexOf(id);
   console.log("idCheckedOrNot ====>",idCheckedOrNot)
    if(  idCheckedOrNot === -1){
      array.push(id)
    //  this.setState({ multiSelectArray :array})
      console.log("array push ====>",array)
    }
    else {  
      array = array.filter((ids:any)=> ids!=id)
      console.log("array pop ====>",array)
    //  this.setState({ multiSelectArray :array})
    }
    let companyDetailArray =[...this.state.companyDetailArray]
    companyDetailArray[index].isChecked = !companyDetailArray[index].isChecked;
    if(this.isDeleteButtonDisable()){
      isDisableOrEnable=true;
      }else{
        isDisableOrEnable=false;
      }
    this.setState({ companyDetailArray, multiSelectArray :array,isDisableOrEnable});
  };

  selectedFileHandler = (evt:any) => {
    const file = evt.target?.files[0];
    if((file.type === "image/jpeg"|| file.type ==="image/png"|| file.type === "image/jpg") && file.size <= 10e6 ){
      console.log("file=====================>",file.type,file.size, file.name);
      this.setState({ selectedFile: file });
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ imageUrl: reader.result , fileName : file.name , sizeAndImage:false});
        }
      };
      reader.readAsDataURL(evt.target?.files[0]);
    }
    else{
      this.setState({ sizeAndImage:true})
    }
  };

  onChangeHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("serach_by ======================>", temp);
    this.setState({
      pageNumber: 1,
      perPageRecord: 40,
      search_keyword: temp,
    });
  };
  perPageRecordHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("perPageRecord ======================>", temp);
    this.setState({
      perPageRecord: temp,
    });
  };

  directionHandler = (evt: any) => {
    const temp = evt.target.value;
    console.log("direction ======================>", temp);
    this.setState({
      direction: temp,
    });
  };
  prevPageHandler = () => {
    const temp = this.state.paginationDetails.prev_page;
    console.log("prevPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };

  nextPageHandler = () => {
    const temp = this.state.paginationDetails.next_page;
    console.log("nextPageHandler ======================>", temp);
    this.setState({
      pageNumber: temp,
    });
  };
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus,
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }],
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory,
        },
        parent_categories: this.state.selectedCategoryID,
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  createNewCompany = (values:any) => {
      const header = {
        token: this.auth,
      };
      console.log("values create==================>",values);
      const formData = new FormData();
     if(this.state.selectedFile){
      formData.append('company[logo]',this.state.selectedFile);
     }
      console.log("this.state.selectedFile==================>",this.state.selectedFile);
     
     formData.append('company[name]', values.company);
     formData.append('company[employer_name]', values.employer);
     formData.append('company[email]', values.email);
     formData.append('company[full_contact_number]', values.contact);
     formData.append('company[country]', values.country);
     formData.append('company[state]', values.state);
     formData.append('company[city]', values.city_district);

  const httpBody = formData;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createNewCompanyApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createNewCompanyAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

  };

  upateCompanyId = (values:any) => {
      const header = {
        token: this.auth,
      };
      console.log("update api  values==================>",values,this.state.selectedFile);
      const formData = new FormData();
      if(this.state.selectedFile){
        console.log("kuch he file me ===> ")
       formData.append('company[logo]',this.state.selectedFile);
      }
      console.log("empty ======> ",this.state.selectedFile)
     formData.append('company[name]', values.company);
     formData.append('company[employer_name]', values.employer);
     formData.append('company[email]', values.email);
     formData.append('company[full_contact_number]', values.contact);
     formData.append('company[country]', values.country);
     formData.append('company[state]', values.state);
     formData.append('company[city]', values.city_district);
    const httpBody = formData;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateCompanyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateCompanyAPIEndPoint + `${values.updateId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };
  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCompany = () => {
    console.log("id of array delete  this.state.multiSelectArray=========>", this.state.multiSelectArray);
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCompanyApiCallId = requestMessage.messageId;
    if(this.state.deleteOneId){
      let id = [];
      id.push(this.state.deleteOneId)
      this.setState({deleteOneId:null})
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCompanyAPIEndPoint + `[${id}]`
      );
    }else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCompanyAPIEndPoint + `[${this.state.multiSelectArray}]`
      );
      this.setState({multiSelectArray:[] , allChecked:false ,isDisableOrEnable:true})
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = (token: string) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOneCompany = (id : any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOneCompanyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOneCompanyAPIEndPoint + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllCompany = (
    pageNumber: any,
    perPageRecord: any,
    search_keyword: any,
    order_by: any,
    direction: any
  ) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.auth,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCompanyApiCallId = requestMessage.messageId;
    let getAllCompanyAPIEndPoint
    if( search_keyword!=="" && direction === "default"){
      console.log("search_keyword!==emtpy && direction === default",direction, this.state.search_keyword)
      getAllCompanyAPIEndPoint = `companies?page=${pageNumber}&per=${perPageRecord}&search_keyword=${search_keyword}`;
    }
   else if( search_keyword==="" && direction ==="default"){
    console.log("search_keyword ==emtpy && direction === default",direction, this.state.direction)
     getAllCompanyAPIEndPoint = `companies?page=${pageNumber}&per=${perPageRecord}`;
   }
   else{
    getAllCompanyAPIEndPoint = `companies?page=${pageNumber}&per=${perPageRecord}&search_keyword=${search_keyword}&order_by=${order_by}&direction=${direction}`;
   }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAllCompanyAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
  createInformationFormValidations() {
    const validations = yup.object().shape({
      /*
      avatar: yup.mixed()
        .required("Avatar is required."),*/
      company: yup.string()
        .min(3, "Minimum 3 characters are required")
        // .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("company Name is required."),
      employer: yup.string()
        .min(3, "Minimum 3 characters are required")
        .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Employer Name is required."),
      email: yup
            .string()
            .email("Please enter a valid email address.")
            .required("Email is required."),
      contact: yup.number()
        .required("Contact number is required.")
        .typeError("Only numbers are allowed.")
        .positive("Negative numbers are not allowed.")
        .integer("Phone can't contain a decimal.")
        .min(1000000000, "Minimum 10 digits are required.")
        .max(9999999999, "Maximum 10 digits are allowed."),
      country: yup.string()
        .required("Country is required."),
      countryCode: yup.string(),
      state: yup.string()
        .required("State is required."),
      city_district: yup.string()
        .required("City is required."),
    });

    return validations;
  }

}
