//@ts-nocheck
import React, { Fragment } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BiArrowBack } from 'react-icons/bi';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { createStyles, maxWidth } from 'react-native-media-queries';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { grey } from '@material-ui/core/colors';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDemo from "./PdfDemo.web";

import html2pdf from 'html2pdf.js'
import Loader from "../../../../components/src/Loader";
import { companyLogo } from "../assets";
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';


import EditJobPostController, {
  Props,
  // configJSON
} from "./EditJobPostController";

class EditJobPost extends EditJobPostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.jobId = localStorage.getItem('id');
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ loading: true }, () => {
      this.getJobDetailsValues();
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpenEditPost !== prevProps.isOpenEditPost) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { isOpenEditPost, editPostModal, closeEditPostModal } = this.props
    const styles = getStyles();
    const initialValues = {}


    var visibilityData = {};
    //this.state.post_preferences.forEach((item: any, index: number) => visibilityData = ({ ...visibilityData, [item.name]: item.value }));
    visibilityData = this.state.pdfVariables;
    console.log("Page refreshed ", this.state.post_preferences.length);

    const jobDetailFrist = {
      "Job Title": "job_title",
      "Reporting To": "reporting_to",
      "Reportees/Team": "team",
      "Employment Type": "employment_type",
      "Job Details": "job_details",
      "Key Result Areas": "key_result_areas",
      "Key Perfotmance Indicators": "key_performance_indicators",
      "Desired Candidate Profile": "desired_candidate_profile",
      "Annual CTC": "annual_ctc"
    }


    const jobDetailSecond = {
      "Other Salary Details": "other_salary_details",
      "Work Expreince": "work_experience",
      "Keywords": "keywords",
      "Job Located": "job_location_details",
      "Hiring For": "hiring_for",
      "Visibility Settings": "visibility",
      "Recuriter Name": "recruiter",
      "No. of Vacancies": "vacancies",
      "Refernce Code": "reference_code"
    }

    const postDetailsFirstList = {
      "Graduation": "graduation",
      "Post Graduation": "post_graduation",
      "Doctrate": "doctorate",
      "Industry": "industry"
    }

    const postDetailsSecondList = {
      "Functional Area": "functional_area",
      "Have any questions for the candidate": "any_questions_for_candidate",
      "Show contact details to jobseekers": "show_contact_details_to_jobseeker"
    }

    const searchStrategyFirstList = {
      "Brief": "brief",
      "Role Dynamics": "role_dynamics",
      "Required candidate skills": "required_candidate_skills"
    }

    const searchStrategySecondList = {
      "Target Pool": "target_pool",
      "Conclusion": "conclusion"
    }


    const allSelectedInJobDetails = !Object.values(jobDetailFrist).find((item: string) => !!visibilityData[item] === false) && !Object.values(jobDetailSecond).find((item: string) => !!visibilityData[item] === false);
    const allSelectedInPostDetails = !Object.values(postDetailsFirstList).find((item: string) => !!visibilityData[item] === false) && !Object.values(postDetailsSecondList).find((item: string) => !!visibilityData[item] === false);
    const allSelectedInSearchStrategyDetails = !Object.values(searchStrategyFirstList).find((item: string) => !!visibilityData[item] === false) && !Object.values(searchStrategySecondList).find((item: string) => !!visibilityData[item] === false);

    console.log("Fullkari ", allSelectedInJobDetails);

    function toggleSwitch(values = []) {

      values.forEach((item) => {
        this.state.pdfVariables[item.name] = item.value;
      });
      this.forceUpdate();
    }

    toggleSwitch = toggleSwitch.bind(this);

    const downloadPDF = () => {

      var element = document.getElementById('pdfpage');
      html2pdf(element,
        { margin: 0, jsPDF: { format: "a4" } }).set({ pagebreak: { mode: 'avoid-all' } });
    }

    return (
      <Fragment>
        <Loader loading={this.state.loading} />
        <Dialog
          classes={{
            root: styles.modalRoot,
            paper: styles.modal,
          }}
          scroll="body"
          open={isOpenEditPost}
          onClose={editPostModal}
          PaperProps={{
            style: {
              minWidth: "60%",
              overflow: "hidden"
            }
          }}
        >
          <DialogTitle style={styles.modalHeader}>
            <Button
              style={styles.modalCloseButton}
              onClick={editPostModal}
            >
              {" "}
              <Close style={{ color: "aliceblue" }} />
            </Button>
            <div style={styles.backPreviewButton} onClick={() => {
              if (this.props.openJobPreviewModal) {
                this.props.openJobPreviewModal()
              }
              this.props.closeEditPostModal()
            }}>
              <BiArrowBack style={{ fontSize: 20 }} />
              <Text style={styles.heading}>Back to preview</Text>
            </div>
          </DialogTitle>
          <DialogContentBox style={styles.modalBody}>
            <div key={"form-"}>
              <Grid style={{ margin: 30 }}>
                <div style={{ display: "grid" }}>
                  <CardAvatar alt="profile" src={companyLogo} />
                  <Text style={styles.secondBlockHeading}>Google Inc.</Text>
                  <Text style={{ paddingTop: 9, color: "gray" }}>
                    Akshya Nagar 1st block 1st Cross, Rammurithy nagar <br /> Bangalore-56097
                  </Text>
                </div>
                <View style={styles.formField}>
                  <View style={{ width: "100%" }} >
                    <TextField
                      // value={values.current_location}
                      name="job_title"
                      variant={"outlined"}
                      label="Enter Title here"
                      style={styles.plainTextInput}
                      onChange={(e) => this.state.title_input = e.target.value}
                      onBlur={(e) => this.forceUpdate()}
                    />
                  </View>
                </View>
              </Grid>
              <div>
                <Accordion>
                  <div style={styles.toggleWrapper}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "rgba(0, 0, 0, .03)", width: "100%" }}
                    >
                      <Typography style={{ fontSize: 20 }}>Job Details</Typography>

                    </AccordionSummary>
                    <div style={styles.accordionEndText} style={{ ...styles.toggleContainer, ...{ right: 140 } }}>
                      <AntSwitch checked={allSelectedInJobDetails} onChange={(e) => {
                        var values = [];

                        Object.keys(jobDetailFrist).forEach((item: string) => {
                          values.push({ name: jobDetailFrist[item], value: !allSelectedInJobDetails });
                        });

                        Object.keys(jobDetailSecond).forEach((item: string) => {
                          values.push({ name: jobDetailSecond[item], value: !allSelectedInJobDetails });
                        });

                        toggleSwitch(values);

                      }} />
                      <Typography style={styles.toggleText}>Select All</Typography>
                    </div>
                  </div>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={6} style={{ padding: 15 }}>
                        {Object.keys(jobDetailFrist).map((item: string) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[jobDetailFrist[item]]} onChange={() => toggleSwitch([{ name: jobDetailFrist[item], value: !visibilityData[jobDetailFrist[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                      <div style={styles.vertical}></div>
                      <Grid item xs={6} style={{ padding: 15, paddingLeft: 25 }}>
                        {Object.keys(jobDetailSecond).map((item: string) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[jobDetailSecond[item]]} onChange={() => toggleSwitch([{ name: jobDetailSecond[item], value: !visibilityData[jobDetailSecond[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <div style={styles.toggleWrapper}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "rgba(0, 0, 0, .03)", width: "100%" }}
                    >
                      <Typography style={{ fontSize: 20 }}>Posting Details</Typography>
                    </AccordionSummary>
                    <div style={styles.accordionEndText} style={styles.toggleContainer} >
                      <PostSwitch checked={allSelectedInPostDetails} onChange={(e) => {
                        var values = [];

                        Object.keys(postDetailsFirstList).forEach((item: string) => {
                          values.push({ name: postDetailsFirstList[item], value: !allSelectedInPostDetails });
                        });

                        Object.keys(postDetailsSecondList).forEach((item: string) => {
                          values.push({ name: postDetailsSecondList[item], value: !allSelectedInPostDetails });
                        });

                        toggleSwitch(values);

                      }} />
                      <Typography style={styles.toggleText} >Select All</Typography>
                    </div>
                  </div>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={6} style={{ padding: 15 }}>
                        {Object.keys(postDetailsFirstList).map((item: string) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[postDetailsFirstList[item]]} onChange={() => toggleSwitch([{ name: postDetailsFirstList[item], value: !visibilityData[postDetailsFirstList[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                      <div style={styles.vertical2}></div>
                      <Grid item xs={6} style={{ padding: 15, paddingLeft: 25 }}>
                        {Object.keys(postDetailsSecondList).map((item: string) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[postDetailsSecondList[item]]} onChange={() => toggleSwitch([{ name: postDetailsSecondList[item], value: !visibilityData[postDetailsSecondList[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <div style={styles.toggleWrapper}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "rgba(0, 0, 0, .03)", width: "100%" }}
                    >
                      <Typography style={{ fontSize: 20 }}>Search Strategy</Typography>

                    </AccordionSummary>
                    <div style={styles.accordionEndText} style={styles.toggleContainer} >
                      <PostSwitch checked={allSelectedInSearchStrategyDetails} onChange={(e) => {
                        var values = [];

                        Object.keys(searchStrategyFirstList).forEach((item: string) => {
                          values.push({ name: searchStrategyFirstList[item], value: !allSelectedInSearchStrategyDetails });
                        });

                        Object.keys(searchStrategySecondList).forEach((item: string) => {
                          values.push({ name: searchStrategySecondList[item], value: !allSelectedInSearchStrategyDetails });
                        });

                        toggleSwitch(values);

                      }} />
                      <Typography style={styles.toggleText} >Select All</Typography>
                    </div>
                  </div>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={6} style={{ padding: 15 }}>
                        {Object.keys(searchStrategyFirstList).map((item) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[searchStrategyFirstList[item]]} onChange={() => toggleSwitch([{ name: searchStrategyFirstList[item], value: !visibilityData[searchStrategyFirstList[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                      <div style={styles.vertical3}></div>
                      <Grid item xs={6} style={{ padding: 15, paddingLeft: 25 }}>
                        {Object.keys(searchStrategySecondList).map((item) => {
                          return (
                            <div style={styles.listBody}>
                              <p style={{ margin: 0, fontWeight: 500 }}>{item}</p>
                              <div>
                                <AntSwitch checked={!!visibilityData[searchStrategySecondList[item]]} onChange={() => toggleSwitch([{ name: searchStrategySecondList[item], value: !visibilityData[searchStrategySecondList[item]] }])} />
                              </div>
                            </div>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </DialogContentBox>
          <div style={styles.modalFooter}>
            <div style={{ padding: 20 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item xs={6}>
                  <TouchableOpacity
                    style={[styles.button, { width: '13rem', backgroundColor: "black", borderColor: "black" }]}
                    onPress={() => {
                      if (this.props.backToEdit) {
                        this.props.backToEdit()
                        this.props.onEdit()
                      }
                    }}
                  >
                    <Text style={[styles.buttonText, { color: "white" }]} >
                      BACK TO EDIT
                    </Text>
                  </TouchableOpacity>
                </Grid>
                <Grid item xs={3}>
                  {/*
                  <TouchableOpacity
                    style={[styles.button, { width: '13rem', backgroundColor: "#e45d33", borderColor: "#e45d33", marginLeft: -20 }]}
                    onPress={() => { downloadPDF(); }}
                  >
                    <Text style={[styles.buttonText, { color: "white" }]} >
                      EXPORT PDF
                    </Text>
                  </TouchableOpacity>*/}
                  <PDFDownloadLink
                    document={<PdfDemo toggleData={this.state.pdfVariables} jobTitle={this.state.title_input || "Job Post Title"} />}
                    fileName="job-detail.pdf"
                    style={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "white",
                      backgroundColor: "#e45d33",
                      border: "1px solid #e45d33",
                      borderRadius: 5,
                      position: "absolute",
                      marginLeft: "-14%",
                      width: '11rem',
                      fontSize: "smaller",
                      letterSpacing: 2,
                      fontWeight: 500,
                    }}
                  >
                    {({ blob, url, loading, error }) => {
                      console.log("gipps ", error);
                      return loading ? "loading" : 'EXPORT PDF';
                    }}
                  </PDFDownloadLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default EditJobPost

const baseStyles = {
  listBody: {
    display: "flex",
    justifyContent: "space-between",
    width: "94%",
    marginTop: 30,
  },
  vertical2: {
    borderLeft: "3px dashed rgba(0,0,0,0.12)",
    height: "15rem",
    position: "absolute",
    left: "50%"
  },
  vertical3: {
    borderLeft: "3px dashed rgba(0,0,0,0.12)",
    height: "12rem",
    position: "absolute",
    left: "50%"
  },
  vertical: {
    borderLeft: "3px dashed rgba(0,0,0,0.12)",
    height: "31rem",
    position: "absolute",
    left: "50%",
  },
  accordionEndText: {
    display: "contents",
  },
  formField: {
    width: "70%",
    paddingTop: 10,
  },
  modalRoot: {
    display: "block"
  },
  modal: {
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100rem",
    marginTop: "130px !important",
    marginLeft: "18%",
  },
  modalHeader: {
    borderBottom: "none",
    paddingRight: "24px",
    paddingLeft: "24px",
    minHeight: "16.43px",
    background: "black",
    color: "white",
    padding: 18
  },
  modalCloseButton: {
    color: "rgb(126, 126, 126)",
    "&, &:hover": {
      color: "rgb(126, 126, 126)",
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + "white",
    opacity: ".5"
  },
  modalBody: {
    position: "relative",
    overflow: "visible",
  },
  modalFooter: {
    textAlign: "center",
    margin: "0",
    background: "#80808036"
  },
  heading: {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "serif",
    color: "white",
    marginLeft: 20,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  headerLine: {
    borderTop: "1px solid grey",
    marginRight: -22,
    marginLeft: -24,
    borderBottom: 0,
  },
  topButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 15,
    paddingBottom: 18,
  },
  topButtonText: {
    color: "rgb(175 166 166)",
    fontSize: 18,
    fontWeight: "500",
    paddingRight: 30,
    '&:focus, &:hover, &$active': {
      color: 'rgb(228, 93, 51)',
    },
  },
  subHeadingText: {
    fontSize: 40,
  },
  buttonBelowText: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 1.5,
    marginLeft: -10,
  },
  postText: {
    display: "inline-flex",
    fontSize: 14,
    marginLeft: -35,
    marginTop: "20%",
    color: "#676060ed",
  },
  blockFirstTextHeading: {
    color: "rgb(128 128 128 / 88%)",
    fontSize: 17,
    letterSpacing: 2,
    fontWeight: 500,
  },
  blockFirstText: {
    fontSize: 16,
    marginTop: 12
  },
  contentLine: {
    borderTop: "1px solid #8080801a",
    marginLeft: -24,
    marginRight: -22,
    borderBottom: 0,
    marginTop: 26,
  },
  secondBlockHeading: {
    fontSize: 22,
    color: "#545151",
    paddingTop: 10,
  },
  thirdBlock: {
    width: "40%",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  fourthBlock: {
    justifyContent: "space-between",
    paddingTop: 10,
    width: "35%",
  },
  keywordText: {
    border: "1px solid #8080805c",
    padding: 5,
    borderRadius: 6,
    fontSize: 16,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 10,
    marginTop: 15,
  },
  userNameText: {
    display: "grid",
    position: "absolute",
    marginLeft: "8%",
  },
  sendMessageText: {
    color: 'rgb(228, 93, 51)',
    letterSpacing: 1.5,
    fontWeight: 500,
    fontSize: 16
  },
  backPreviewButton: {
    width: "fit-content",
    cursor: "pointer",
  },
  toggleContainer: {
    display: "flex",
    position: "absolute",
    zIndex: 1,
    right: 134
  },
  toggleText: {
    whiteSpace: "nowrap",
    marginLeft: 16
  },
  toggleWrapper: {
    display: "flex",
    alignItems: "center"
  }
}

const getStyles = () => createStyles(
  baseStyles,
)

const DialogContentBox = withStyles({
  root: {
    padding: 0,
  }
})(DialogContent)

const CardAvatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(Avatar)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    border: "none",
    '&$expanded': {
      backgroundColor: "white",
      margin: 0,
      marginBottom: 0
    },
  },
  content: {
    width: 'auto',
    justifyContent: "space-between",
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 16,
  },
})(MuiAccordionDetails)

const AntSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    marginTop: 5,
    marginLeft: "70%",
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    '&$checked': {
      color: "#06d606f2",
      marginLeft: -10,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: "white",
      borderColor: "#06d606f2",
    },
  },
  checked: {},
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: 13,
    width: 25,
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
})(Switch);

const PostSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    marginTop: 5,
    marginLeft: "65%",
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    '&$checked': {
      color: "#06d606f2",
      marginLeft: -10,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: "white",
      borderColor: "#06d606f2",
    },
  },
  checked: {},
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: 13,
    width: 25,
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
})(Switch);
