//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Loader from "../../../../components/src/Loader";
import PaginationIndicator from "../../../../components/src/PaginationIndicator.web";
import Storage from "../../../../framework/src/StorageProvider.web";
import BasicInformationForm from "./BasicInformationForm.web";
import EmploymentHistoryForm from "./EmploymentHistoryForm.web";
import VerificationAndStatusForm from "./VerificationAndStatusForm.web";
import { FaCheck, FaPlus, FaThList } from "react-icons/fa";

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import AdditionalDetailFormController, {
  Props
} from "./AdditionalDetailFormController";


export default class AdditionalDetailForm extends AdditionalDetailFormController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.email) && this.state.email != "";
    const isPasswordValid = this.state.password != "";
    const isStep1Complete = true;
    const isStep2Complete = this.state.isStep2Completed;
    const isStep3Complete = this.state.isStep3Completed;
    const isStep4Complete = false;

    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading} />
        <View style={[styles.leftContainer, { backgroundColor: "white" }]} />
        <View style={[styles.leftContainer, { position: "fixed" }]}>

          <View>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
            </View>

            <View style={styles.stepsContainer}>
              <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }} >
                <View style={[styles.stepIconContainer, (this.state.step === 1 || isStep1Complete) && styles.stepIconContainerActive, isStep1Complete && { height: 13, width: 13 }]}><Text style={[styles.stepIcon, (this.state.step === 1 || isStep1Complete) && styles.stepIconActive]}>{isStep1Complete ? <FaCheck size={8} /> : "1"}</Text></View>
                <Text style={[styles.stepText, this.state.step === 1 && styles.stepTextActive]}>Sign up</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                {/*<TouchableOpacity style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }} onPress={() => this.setState({ step: 2 })}>*/}
                <View style={[styles.stepIconContainer, (this.state.step === 2 || isStep2Complete) && styles.stepIconContainerActive, isStep2Complete && { height: 13, width: 13 }]}><Text style={[styles.stepIcon, (this.state.step === 2 || isStep2Complete) && styles.stepIconActive]}>{isStep2Complete ? <FaCheck name="check" size={8} /> : "2"}</Text></View>
                <Text style={[styles.stepText, this.state.step === 2 && styles.stepTextActive]}>Basic Information</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }} >
                {/*<TouchableOpacity style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }} onPress={() => this.setState({ step: 3 })}>*/}
                <View style={[styles.stepIconContainer, (this.state.step === 3 || isStep3Complete) && styles.stepIconContainerActive, isStep3Complete && { height: 13, width: 13 }]}><Text style={[styles.stepIcon, (this.state.step === 3 || isStep3Complete) && styles.stepIconActive]}>{isStep3Complete ? <FaCheck name="check" size={8} /> : "3"}</Text></View>
                <Text style={[styles.stepText, this.state.step === 3 && styles.stepTextActive]}>Employment History</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                {/*<TouchableOpacity style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }} onPress={() => this.setState({ step: 4 })}>*/}
                <View style={[styles.stepIconContainer, (this.state.step === 4 || isStep4Complete) && styles.stepIconContainerActive, isStep4Complete && { height: 13, width: 13 }]}><Text style={[styles.stepIcon, (this.state.step === 4 || isStep4Complete) && styles.stepIconActive]}>{isStep4Complete ? <FaCheck name="check" size={8} /> : "4"}</Text></View>
                <Text style={[styles.stepText, this.state.step === 4 && styles.stepTextActive]}>Verification & Finish</Text>
              </View>
            </View>
          </View>

          <View>

            <Text style={styles.leftTitle}>Lorem Ipsum has been the industry's standard</Text>
            <Text style={[styles.text, { marginTop: 8, color: "rgba(255,255,255,0.3)" }]}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</Text>


            <View style={{ marginTop: 50 }}>
              <PaginationIndicator count={3} selected={1} style={{ justifyContent: "flex-start" }} />
            </View>
            {/*<Text style={[styles.text, { marginTop: 50, color: "#ffffff" }]}>© 2021 RMS. All right Reserved.</Text>*/}
          </View>
          {/** 
          <View>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
              <TouchableOpacity {...this.btnHomeProps}>
                <Text style={[styles.text, { fontWeight: 500, fontSize: 14 }]}>Home</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.leftTitle}>Hi, Welcome Back!</Text>
            <Text style={[styles.text, { marginTop: 8 }]}>Lorem Ipsum has been the industry's standard dummy text ever since</Text>


            <View style={[styles.textInputContainer, !isEmailValid && this.state.isEmailTouched && styles.textInputError]}>
              <TextInput {...this.txtInputEmailProps} style={styles.textInput} placeholder={this.state.placeHolderEmail} value={this.state.email} />
              {isEmailValid && <Icon name="check-circle" size={24} color="green" style={styles.textInputIcon} />}
            </View>
            <View style={styles.textInputErrorMessageContainer}>
              {!isEmailValid && this.state.isEmailTouched &&
                <Text style={styles.textInputErrorMessage}>Email Required</Text>
              }
            </View>
            <View style={{ alignItems: "flex-end" }}>
              <TouchableOpacity {...this.btnForgotPasswordProps}><Text style={[styles.text, { textAlign: "right", marginTop: 10 }]}>Forgot Password?</Text></TouchableOpacity>
            </View>
            <View style={[styles.textInputContainer, !isPasswordValid && this.state.isPasswordTouched && styles.textInputError, { marginTop: 5 }]}>
              <TextInput {...this.txtInputPasswordProps} style={styles.textInput} placeholder={this.state.placeHolderPassword} />
              <TouchableOpacity {...this.btnPasswordShowHideProps} style={{ height: 26 }}>
                {this.state.enablePasswordField ?
                  <Icon name="eye" size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                  :
                  <Icon name="eye-slash" size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                }
              </TouchableOpacity>
            </View>
            <View style={styles.textInputErrorMessageContainer}>
              {!isPasswordValid && this.state.isPasswordTouched &&
                <Text style={styles.textInputErrorMessage}>{this.state.errorMessages.errorPasswordRequired}</Text>
              }
            </View>


            <TouchableOpacity {...this.btnEmailLogInProps} style={styles.button}>
              <Text style={styles.buttonText}>{this.state.btnTxtLogin}</Text>
            </TouchableOpacity>

            <Text style={[styles.text, { marginTop: 20 }]}>Not registered yet? <TouchableOpacity {...this.btnCreateAnAccountProps}><Text style={{ color: "#e45d33" }} >Create an account</Text></TouchableOpacity></Text>
          </View>

      */}
        </View>
        <View style={styles.rightContainer}>
          {this.state.step === 2 &&
            <BasicInformationForm navigation={this.props.navigation} id={this.props.id} parent={this} />
          }
          {this.state.step === 3 &&
            <EmploymentHistoryForm navigation={this.props.navigation} id={this.props.id} parent={this} />
          }
          {this.state.step === 4 &&
            <VerificationAndStatusForm navigation={this.props.navigation} id={this.props.id} parent={this} />
          }
          {/*
          <ImageBackground source={assets.rightSectionBackgroundImage} style={styles.rightSectionBackgroundImage} >
            <View style={styles.rightContentContainer}>
              <Image source={assets.rightSectionPeopleImage} style={styles.rightSectionPeopleImage} resizeMode={"contain"} />
              <Text style={styles.rightImageTitle}>Lorem Ipsum has been the industry's standard</Text>
              <Text style={styles.rightImageDescription}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </Text>
            </View>
          </ImageBackground>
        */}
        </View>
      </View>
    );
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 30,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "20%",
    minHeight: "100%",
    padding: "1.3%",
    justifyContent: "space-between"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 15,
    width: 15,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    width: "80%",
    minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
    leftContainer: {
      display: "none"
    },
    rightContainer: {
      width: "100%"
    }
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);
