import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";
import * as yup from 'yup';
//@ts-ignore
import Download from "downloadjs";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  history: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  profileData: any;
  loading: boolean;
  languagesData: Array<any>;
  languagesLoading: boolean;
  projectsData: Array<any>;
  projectsLoading: boolean;
  educationsData: Array<any>;
  educationsLoading: boolean;
  itSkillsData: Array<any>;
  itSkillsLoading: boolean;
  employmentHistory: Array<any>;
  employmentHistoryLoading: boolean;
  skillList: Array<any>;
  showProfileSkillList: boolean;
  profileSkillInput: string;
  projectSkillInput: any;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageGetProfileRequestId: any;
  apiCallMessageUpdateProfileRequestId: any;
  apiCallMessageGetLanguagesRequestId: any;
  apiCallMessageDeleteLanguageRequestId: any;
  apiCallMessageAddLanguageRequestId: any;
  apiCallMessageUpdateLanguageRequestId: any;
  apiCallMessageGetProjectRequestId: any;
  apiCallMessageDeleteProjectRequestId: any;
  apiCallMessageAddProjectRequestId: any;
  apiCallMessageUpdateProjectRequestId: any;
  apiCallMessageGetEducationRequestId: any;
  apiCallMessageDeleteEducationRequestId: any;
  apiCallMessageAddEducationRequestId: any;
  apiCallMessageUpdateEducationRequestId: any;
  apiCallMessageGetItSkillRequestId: any;
  apiCallMessageDeleteItSkillRequestId: any;
  apiCallMessageAddItSkillRequestId: any;
  apiCallMessageUpdateItSkillRequestId: any;
  apiCallMessageGetEmployeHistoryRequestId: any;
  apiCallMessageDeleteEmployeHistoryRequestId: any;
  apiCallMessageAddEmployeHistoryRequestId: any;
  apiCallMessageUpdateEmployeHistoryRequestId: any;
  apiCallMessageGetProfileSkillTagsRequestId: any;

  apiCallMessageUploadProfilePhotoRequestId: any;
  apiCallMessageUploadResumeFileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      profileData: undefined,
      loading: false,
      languagesData: [],
      languagesLoading: true,
      projectsData: [],
      projectsLoading: true,
      educationsData: [],
      educationsLoading: true,
      itSkillsData: [],
      itSkillsLoading: true,
      employmentHistory: [],
      employmentHistoryLoading: true,
      skillList: [],
      showProfileSkillList: false,
      profileSkillInput: "",
      projectSkillInput: {}
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (this.apiCallMessageUploadResumeFileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUploadResumeFileRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        Storage.set("profileData", responseJson.data);
        this.setState({ profileData: responseJson.data });
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ loading: false });
    }
    else if (this.apiCallMessageUploadProfilePhotoRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUploadProfilePhotoRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        Storage.set("profileData", responseJson.data);
        this.setState({ profileData: responseJson.data });
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ loading: false });
    }
    else if (this.apiCallMessageUpdateEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        this.setState({ employmentHistoryLoading: true }, () => {
          this.getEmploymentHistory();
          this.showAlert("", "Employement history updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ employmentHistoryLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ employmentHistoryLoading: false });
      }


    }

    else if (this.apiCallMessageAddEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ employmentHistoryLoading: true }, () => {
          this.getEmploymentHistory();
          this.showAlert("", "Employement history added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ employmentHistoryLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ employmentHistoryLoading: false });
      }

    }
    else if (this.apiCallMessageDeleteEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson === undefined || !responseJson.errors) {

        this.setState({ employmentHistoryLoading: true }, () => {
          this.getEmploymentHistory();

          this.showAlert("", "Employement history deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ employmentHistoryLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ employmentHistoryLoading: false });
      }

    }
    else if (this.apiCallMessageGetProfileSkillTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetProfileSkillTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        this.setState({ skillList: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (Array.isArray(responseJson.data)) {
          this.setState({ employmentHistory: responseJson.data });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ employmentHistoryLoading: false });
    }
    else if (this.apiCallMessageUpdateItSkillRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateItSkillRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ itSkillsLoading: true }, () => {
          this.fetchItSkills();
          this.showAlert("", "IT Skills updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ itSkillsLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ itSkillsLoading: false });
      }

    }

    else if (this.apiCallMessageAddItSkillRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddItSkillRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ itSkillsLoading: true }, () => {
          this.fetchItSkills();
          this.showAlert("", "IT Skill added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    else if (this.apiCallMessageDeleteItSkillRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteItSkillRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson === undefined || !responseJson.errors) {

        this.setState({ itSkillsLoading: true }, () => {
          this.fetchItSkills();

          this.showAlert("", "IT Skill deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ itSkillsLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ itSkillsLoading: false });
      }

    }

    else if (this.apiCallMessageGetItSkillRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetItSkillRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const itSkillsData = responseJson.data;

        if (Array.isArray(itSkillsData)) {
          this.setState({ itSkillsData });
        }
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ itSkillsLoading: false });
    }

    else if (this.apiCallMessageUpdateEducationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateEducationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ educationsLoading: true }, () => {
          this.fetchEducations();
          this.showAlert("", "Education updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }


      this.setState({ educationsLoading: false });
    }

    else if (this.apiCallMessageAddEducationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddEducationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ educationsLoading: true }, () => {
          this.fetchEducations();
          this.showAlert("", "Education added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.setState({ educationsLoading: false });

    }
    else if (this.apiCallMessageDeleteEducationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteEducationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson === undefined || !responseJson.errors) {

        this.setState({ educationsLoading: true }, () => {
          this.fetchEducations();

          this.showAlert("", "Education deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.setState({ educationsLoading: false });

    }

    else if (this.apiCallMessageGetEducationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetEducationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (Array.isArray(responseJson.data)) {
          this.setState({ educationsData: responseJson.data });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ educationsLoading: false });
    }


    else if (this.apiCallMessageUpdateProjectRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateProjectRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ projectsLoading: true }, () => {
          this.fetchProjects();
          this.showAlert("", "Project updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }


      this.setState({ projectsLoading: false });
    }

    else if (this.apiCallMessageAddProjectRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddProjectRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ projectsLoading: true }, () => {
          this.fetchProjects();
          this.showAlert("", "Project added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.setState({ loading: false });

    }
    else if (this.apiCallMessageDeleteProjectRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteProjectRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson === undefined || !responseJson.errors) {

        this.setState({ projectsLoading: true }, () => {
          this.fetchProjects();

          this.showAlert("", "Project deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ projectsLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.setState({ projectsLoading: false });

    }

    else if (this.apiCallMessageGetProjectRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetProjectRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (Array.isArray(responseJson.data)) {
          this.setState({ projectsData: responseJson.data, projectSkillInput: {} });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ projectsLoading: false });
    }


    else if (this.apiCallMessageGetLanguagesRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetLanguagesRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const languagesData = responseJson.data;

        if (Array.isArray(languagesData)) {
          this.setState({ languagesData });
        }
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ languagesLoading: false });
    }

    else if (this.apiCallMessageGetProfileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetProfileRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const profileData = responseJson.data;

        this.setState({ profileData });

        Storage.set("profileData", profileData);
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ loading: false });
    }


    else if (this.apiCallMessageUpdateLanguageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateLanguageRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ languagesLoading: true }, () => {
          this.fetchLanguages();
          this.showAlert("", "Language updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false });
      }

    }

    else if (this.apiCallMessageAddLanguageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddLanguageRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ languagesLoading: true }, () => {
          this.fetchLanguages();
          this.showAlert("", "Language added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    else if (this.apiCallMessageDeleteLanguageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteLanguageRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson === undefined || !responseJson.errors) {

        this.setState({ languagesLoading: true }, () => {
          this.fetchLanguages();

          this.showAlert("", "Language deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ languagesLoading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ languagesLoading: false });
      }

    }

    else if (this.apiCallMessageUpdateProfileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateProfileRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const profileData = responseJson.data;

        Storage.set("profileData", profileData);

      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.setState({ loading: false });
    }
    else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );

        const authToken = await Storage.get("authToken");
        this.authToken = authToken;

        this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }



  async updateProfile(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    let data: any = { ...values };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async fetchProfile() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateProfilePhoto(file: any) {

    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      //"Content-Type": "multipart/form-data",
      token: authToken
    };

    const formData = new FormData();

    formData.append('data[avatar]', file);

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUploadProfilePhotoRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  async updateResumeFile(file: any) {

    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      //"Content-Type": "multipart/form-data",
      token: authToken
    };

    const formData = new FormData();

    formData.append('data[resume]', file);

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUploadResumeFileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteResumeFile() {

    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const formData = { data: { resume: null } }

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUploadResumeFileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //work experience started


  async getEmploymentHistory() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/employment_histories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async addEmploymentHistory(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      // "Content-Type": contentTypeApiAddEmployeHistory,
      token: authToken
    };

    const formData = new FormData();

    formData.append('data[company_logo]', values.company_logo);
    formData.append('data[job_title]', values.job_title);
    formData.append('data[employment_type]', values.employment_type);
    formData.append('data[company]', values.company);
    formData.append('data[start_date]', values.start_date);
    formData.append('data[end_date]', values.end_date);

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/employment_histories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateEmploymentHistory(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      //"Content-Type": contentTypeApiUpdateEmployeHistory,
      token: authToken
    };

    let data: any = values;


    const formData = new FormData();

    if (!(typeof values.company_logo === "string")) {
      formData.append('data[company_logo]', values.company_logo);
    }
    formData.append('data[job_title]', values.job_title);
    formData.append('data[employment_type]', values.employment_type);
    formData.append('data[company]', values.company);
    formData.append('data[start_date]', values.start_date);
    formData.append('data[end_date]', values.end_date);

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/employment_histories/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteEmploymentHistory(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/employment_histories/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onAddMoreEmploymentHistory() {

    if (this.state.employmentHistory.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.employmentHistory.push(
      {
        "id": "",
        "type": "employment_history",
        "attributes": {
          "company_logo": undefined,
          "job_title": "",
          "employment_type": "Full Time",
          "company": "",
          "start_date": "",
          "end_date": ""
        },
        "edit": true
      }
    );

    this.forceUpdate();
  }

  onEditEmploymentHistory(index: number) {
    this.state.employmentHistory[index].edit = true;

    this.forceUpdate();
  }

  onCancelEmploymentHistory(index: number) {
    if (this.state.employmentHistory[index].id === "") {
      var employmentHistory = this.state.employmentHistory.filter((item, idx) => idx !== index);
      this.setState({ employmentHistory });
    } else {
      this.state.employmentHistory[index].edit = false;
      this.forceUpdate();
    }
  }

  //work experience started



  //project code start

  async fetchProjects() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetProjectRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/project_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async addProject(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const formData = { data: values };

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddProjectRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/project_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateProject(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const formData = { data: values };

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateProjectRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/project_details/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteProject(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteProjectRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/project_details/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onAddMoreProject() {

    if (this.state.projectsData.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.projectsData.push(
      {
        "id": "",
        "type": "project_detail",
        "attributes": {
          "title": "",
          "skill_list": [],
          "role": "",
          "role_description": "",
          "project_description": ""
        },
        edit: true
      }
    );

    this.forceUpdate();
  }

  onEditProject(index: number) {
    this.state.projectsData[index].edit = true;

    this.forceUpdate();
  }

  onCancelProject(index: number) {
    if (this.state.projectsData[index].id === "") {
      var projectsData = this.state.projectsData.filter((item, idx) => idx !== index);
      this.setState({ projectsData });
    } else {
      this.state.projectsData[index].edit = false;
      this.forceUpdate();
    }
  }

  //project code end



  //education code start

  async fetchEducations() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetEducationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/education_qualifications"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async addEducation(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const formData = { data: values };

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddEducationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/education_qualifications"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateEducation(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const formData = { data: values };

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateEducationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/education_qualifications/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteEducation(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteEducationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/education_qualifications/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onAddMoreEducation() {

    if (this.state.educationsData.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.educationsData.push(
      {
        "id": "",
        "type": "",
        "attributes": {
          "institute": "",
          "degree": "",
          "university_logo": null
        },
        edit: true
      }
    );

    this.forceUpdate();
  }

  onEditEducation(index: number) {
    this.state.educationsData[index].edit = true;

    this.forceUpdate();
  }

  onCancelEducation(index: number) {
    if (this.state.educationsData[index].id === "") {
      var educationsData = this.state.educationsData.filter((item, idx) => idx !== index);
      this.setState({ educationsData });
    } else {
      this.state.educationsData[index].edit = false;
      this.forceUpdate();
    }
  }

  //education code end

  //language code start
  async fetchLanguages() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetLanguagesRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/language_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async addLanguage(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const formData = { data: values };

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddLanguageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/language_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateLanguage(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const formData = { data: values };

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateLanguageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/language_details/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteLanguage(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteLanguageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/language_details/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  onAddMoreLanguage() {

    if (this.state.languagesData.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.languagesData.push(
      {
        "id": "",
        "type": "language_detail",
        "attributes": {
          "name": "",
          "proficiency": "",
          "read": true,
          "write": true,
          "speak": true
        },
        edit: true
      }
    );

    this.forceUpdate();
  }

  onEditLanguage(index: number) {

    this.state.languagesData.forEach((item, idx) => {
      item.edit = false;
    });

    this.state.languagesData[index].edit = true;

    this.forceUpdate();
  }

  onCancelLanguage() {
    this.state.languagesData.forEach((item, idx) => item.edit = false);
    this.forceUpdate();
  }

  //language code end


  //itskills code start
  async fetchItSkills() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetItSkillRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/it_skills"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async addItSkill(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const formData = { data: values };

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddItSkillRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/it_skills"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateItSkill(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const formData = { data: values };

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateItSkillRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/it_skills/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteItSkill(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteItSkillRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/it_skills/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  onAddMoreItSkill() {

    if (this.state.itSkillsData.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.itSkillsData.push(
      {
        "id": "",
        "type": "it_skill",
        "attributes": {
          "skill_name": "",
          "version": "",
          "last_used": "",
          "working_from": "",
          "working_to": ""
        },
        edit: true
      }
    );

    this.forceUpdate();
  }

  onEditItSkill(index: number) {

    this.state.itSkillsData.forEach((item, idx) => {
      item.edit = false;
    });

    this.state.itSkillsData[index].edit = true;

    this.forceUpdate();
  }

  onCancelItSkill() {
    this.state.itSkillsData.forEach((item, idx) => item.edit = false);
    this.forceUpdate();
  }

  //itskill code end

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }


  async getProfileSkillTags(q: string) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetProfileSkillTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/skill_tags?q=" + q
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getProjectSkillTags(q: string) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetProfileSkillTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/project_details/skill_tags?q=" + q
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getUserProfile() {

    const authToken = await Storage.get("authToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }


  downloadFile(url: string) {
    fetch(url).then((response) => {
      if (response.ok) {
        response.blob().then(function (myBlob) {
          Download(myBlob)
        })
      } else {
        throw new Error("Error occured, Please try again.");
      }
    })
      .catch((e) => {
        this.showAlert("", e.message, "OK");
      });
  }

  getPersonalInfoFormValidations() {
    const validations = yup.object().shape({


      first_name: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("First Name is required."),
      last_name: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Last Name is required."),
      email: yup
        .string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
      phone_number: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[0-9 .]+$/, "Only numbers are allowed.")
        .required("Phone is required."),
      dob: yup.string()
        .required("DOB is required."),
      marital_status: yup.string()
        .required("Marital status is required."),
      gender: yup.string()
        .required("Gender is required."),
      current_location: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Current location is required."),
      employment_type: yup.string()
        .matches(/^[a-zA-Z0-9 ._]+$/, "Only alphabets and numbers are allowed.")
        .required("Job Type is required."),
      employed: yup.string()
        .required("Employed status is required."),
      category: yup.string()
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Category is required."),
      physically_challenged: yup.string()
        .required("Physically challenged is required.")
    });

    return validations;
  }


  getCandidateDetailFormValidations() {
    const validations = yup.object().shape({
      role: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Role is required."),
      industry: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Industry is required."),
      current_designation: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Current designation is required."),
      preferred_location: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Preferred location is required."),
      total_experience: yup.string()
        .required("Total experience info is required."),
      annual_salary: yup.number()
        .typeError("Only digits are allowed")
        .min(5, "Minimum 5 characters are required")
        .required("Annual Salary is required."),
      functional_area: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Functional area is required."),
      notice_period: yup.string()
        .required("Notice Period is required."),
      highest_degree: yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Highest degree is required."),
      skill_list: yup.array().min(1, "Skill list is required.")
        .required("Skill list is required.")
    });

    return validations;
  }


  getProjectFormValidations() {

    const validations = yup.object().shape({
      "title": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Title logo is required."),
      skill_list: yup.array().min(1, "Skill list is required.")
        .required("Skill list is required."),
      "role": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Role is required."),
      "role_description": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Role description is required."),
      "project_description": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Project description is required.")
    });

    return validations;
  }

  getEmploymentHistoryFormValidations() {
    const validations = yup.object().shape({
      "company_logo": yup.mixed()
        .required("Company logo is required."),
      "job_title": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Job Title is required."),
      "employment_type": yup.string()
        .required("Employment Type is required."),
      "company": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Company is required."),
      "start_date": yup.string()
        .required("Start Date is required."),
      "end_date": yup.string()
        .required("End Date is required.")
    });

    return validations;
  }


  getItSkillFormValidations() {
    const validations = yup.object().shape({
      "skill_name": yup.string()
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Skill name is required."),
      "version": yup.string()
        .required("Version is required."),
      "last_used": yup.string()
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Last used info is required."),
      "working_from": yup.string()
        .required("Working from is required."),
      "working_to": yup.string()
        .required("Working to is required.")
    });

    return validations;
  }

  getEducationFormValidations() {
    const validations = yup.object().shape({
      "institute": yup.string()
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Last used info is required."),
      "degree": yup.string()
        .required("Working from is required.")
    });

    return validations;
  }


  getLanguageFormValidations() {
    const validations = yup.object().shape({
      "name": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Language name is required."),
      "proficiency": yup.string()
        .required("Proficiency is required.")
    });

    return validations;
  }

  // Customizable Area End

}
