import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import _ from 'lodash';

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

import Storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any
  updateStatusObj: any
  closeHandler: any
  changeTextHandler: any
  updateVisibilityHandler: any
  updateIndexHandler: any
  showNewStatusFieldHandler: any
  newStatusChangeHandler: any
  newStatusBlurHandler: any
  updateStatusHandler: any
  newStatusHead: string
  showNewStatusHead: boolean
  updateLoading: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  statusList: {
    error: string | null,
    loading: boolean,
    data: any[]
  };
  activityList: {
    error: string | null,
    loading: boolean,
    data: any[]
  };
  hoveredStatusIndex: string;
  updateStatusObj: any
  newStatusHead: string
  showNewStatusHead: boolean
  updateLoading: boolean
  pagination: {
    perPage: string
    currentPage: string
    totalPages: string
    totalCount: string
  }
  searchKeyword: string
  order: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisedOrderStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  statusListApiCallMessageRequestId: any
  statusUpdateApiCallMessageRequestId: any
  activityListApiCallMessageRequestId: any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      statusList: {
        error: null,
        loading: false,
        data: []
      },
      activityList: {
        error: null,
        loading: false,
        data: []
      },
      hoveredStatusIndex: '',
      updateStatusObj: null,
      newStatusHead: '',
      showNewStatusHead: false,
      updateLoading: false,
      pagination: {
        perPage: '40',
        currentPage: '0',
        totalPages: '0',
        totalCount: '0'
      },
      searchKeyword: "",
      order: "default"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    } else if (this.statusListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.statusListApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.data) {
        console.log('Status List Response : ', responseJson)
        this.setState({
          statusList: {
            loading: false,
            error: null,
            data: responseJson.data
          }
        })
      } else {
        console.log('Status List Error : ', responseJson)
        this.setState({
          statusList: {
            loading: false,
            error: JSON.stringify(responseJson.errors),
            data: []
          }
        })
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    } else if (this.statusUpdateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.statusUpdateApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.data) {
        console.log('Status Update Response : ', responseJson)

        const statusList = [...this.state.statusList.data]
        const statusIndex = _.findIndex(statusList, ['id', responseJson.data.id]);

        statusList[statusIndex] = responseJson.data

        this.setState({ statusList: { ...this.state.statusList, data: statusList }, updateStatusObj: null, newStatusHead: '', showNewStatusHead: false })

      } else {
        console.log('Status Update Error : ', responseJson)
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ updateLoading: false })
    } else if (this.activityListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.activityListApiCallMessageRequestId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.data) {
        console.log('Activity List Response : ', responseJson)
        this.setState({
          activityList: {
            loading: false,
            error: null,
            data: responseJson.data
          },
          pagination: {
            perPage: responseJson.meta.pagination.per_page,
            currentPage: responseJson.meta.pagination.current_page,
            totalPages: responseJson.meta.pagination.total_pages,
            totalCount: responseJson.meta.pagination.total_count
          }
        })
      } else {
        console.log('Activity List Error : ', responseJson)
        this.setState({
          activityList: {
            loading: false,
            error: JSON.stringify(responseJson.errors),
            data: []
          }
        })
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getStatusList()
  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  capitalize = (sentence: string | undefined, wordLimit?: number) => {
    if (!sentence) return 'N/A';
    const word = sentence.split('_');
    for (let i = 0; i < word.length; i += 1) {
      if (wordLimit) {
        word[i] =
          i < wordLimit ? word[i].charAt(0).toUpperCase() + word[i].slice(1).toLowerCase() : word[i].toLowerCase();
      } else {
        word[i] = word[i].charAt(0).toUpperCase() + word[i].slice(1).toLowerCase();
      }
    }
    return word.join(' ');
  };


  getStatusList = async () => {
    try {
      this.setState((prevState: S) => ({
        statusList: {
          ...prevState.statusList,
          loading: true,
        }
      }))

      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.statusListApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.statusListApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.statusListApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.statusListApiMethod ,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState((prevState: S) => ({
        statusList: {
          ...prevState.statusList,
          loading: false,
        }
      }))
      console.log(error)
    }
  }

  getActivityList = async (pagination: any, keyword: any = this.state.searchKeyword, order: any = this.state.order) => {
    try {
      this.setState((prevState: S) => ({
        activityList: {
          ...prevState.activityList,
          loading: true,
        }
      }))

      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.activityListApiContentType,
        token: authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.activityListApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.activityListApiEndPoint}?page=${pagination.page}&per=${pagination.perPage}&search_keyword=${keyword}${order === 'default' ? '' : `&order_by=name&direction=${order}`}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.activityListApiMethod ,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState((prevState: S) => ({
        activityList: {
          ...prevState.activityList,
          loading: false,
        }
      }))
      console.log(error)
    }
  }

  updateStatus = async (status: any, statusId: string) => {
    try {
      const authToken = await Storage.get("authToken");

      const header = {
        "Content-Type": configJSON.statusUpdateApiContentType,
        token: authToken
      };

      const httpBody = { status: { status_heads_attributes: status } };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.statusUpdateApiCallMessageRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.statusUpdateApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.statusUpdateApiEndPoint + statusId ,
      );

      console.log('requestMessage', requestMessage)

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error)
    }
  }

  handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.destination.droppableId !== result.source.droppableId) return;
    if (result.destination.index === result.source.index) return;
    console.log(result)
    const statusList = [...this.state.statusList.data]
    const statusIndex = _.findIndex(statusList, ['attributes.status_heading_type', result.destination.droppableId]);
    const statusHeads = [...statusList[statusIndex].attributes.status_heads.data]
    const [reorderedItem] = statusHeads.splice(result.source.index, 1);
    statusHeads.splice(result.destination.index, 0, reorderedItem);

    statusList[statusIndex].attributes.status_heads.data = statusHeads;

    this.updateStatus(statusHeads.map((statusHead, index) => ({
      "id": statusHead.id,
      "index_num": index,
    })), statusList[statusIndex].id)
  }

  handleStartEnd = (statusId: string, statusHeadId: string, isStart: boolean) => {
    this.updateStatus([
      {
        "id": statusHeadId,
        "start": isStart,
        "end": !isStart
      }
    ], statusId)
  }

  handleShowHide = (statusId: string, statusHeadId: string, show: boolean) => {
    this.updateStatus([
      {
        "id": statusHeadId,
        "is_visible": show,
      }
    ], statusId)
  }

  modalCloseHandler = () => {
    console.log(console.log(this.state.statusList))
    this.setState({
      updateStatusObj: null,
      statusList: this.state.statusList
    })
  }

  changeTextHandler = (value: string, index: number) => {
    const status = _.cloneDeep(this.state.updateStatusObj)
    const statusHead = _.cloneDeep(status.attributes.status_heads.data)

    statusHead[index].attributes.name = value

    status.attributes.status_heads.data = statusHead

    this.setState({ updateStatusObj: status })
  }

  updateVisibilityHandler = (value: boolean, index: number) => {
    const status = { ...this.state.updateStatusObj }
    const statusHead = [...status.attributes.status_heads.data]

    statusHead[index].attributes.is_visible = value

    status.attributes.status_heads.data = statusHead

    this.setState({ updateStatusObj: status })

  }

  updateIndexHandler = (result: any) => {
    if (!result.destination) return;
    if (result.destination.droppableId !== result.source.droppableId) return;
    if (result.destination.index === result.source.index) return;
    const status = { ...this.state.updateStatusObj }
    const statusHead = [...status.attributes.status_heads.data]

    const [reorderedItem] = statusHead.splice(result.source.index, 1);
    statusHead.splice(result.destination.index, 0, reorderedItem);

    status.attributes.status_heads.data = statusHead.map((data: any, i: number) => ({
      ...data,
      attributes: {
        ...data.attributes,
        index_num: i
      }
    }))

    this.setState({ updateStatusObj: status })

  }

  showNewStatusFieldHandler = () => {
    this.setState({ showNewStatusHead: true })
  }

  newStatusChangeHandler = (value: string) => {
    this.setState({ newStatusHead: value })
  }

  newStatusBlurHandler = (value: string) => {
    if (!value || value.trim().length === 0) return
    const status = { ...this.state.updateStatusObj }
    const statusHead = [...status.attributes.status_heads.data]
    const newStatusHead = {
      "attributes": {
        "name": value.trim(),
        "index_num": statusHead.length - 1,
        "is_visible": true,
      }
    }

    statusHead.push(newStatusHead)

    status.attributes.status_heads.data = statusHead

    this.setState({ updateStatusObj: status, showNewStatusHead: false, newStatusHead: '' })
  }

  updateStatusHandler = () => {
    this.setState({ updateLoading: true })
    const status = { ...this.state.updateStatusObj }
    const statusHead = [...status.attributes.status_heads.data]

    const error = statusHead.filter((status: any) => status.attributes.name.trim().length === 0)

    if (error.length > 0) {
      return this.setState({ updateLoading: false })
    }

    this.updateStatus(statusHead.map((status) => ({
      ...status.attributes,
      name: status.attributes.name.trim()
    })), status.id)
  }

  handlePerpageSelect = (e: any) => {
    this.getActivityList({
      page: 1,
      perPage: e.target.value
    })
  }

  handlePagination = (page: any) => {
    this.getActivityList({
      page,
      perPage: this.state.pagination.perPage
    })
  }

  handleSearch = (keyword: any) => {
    this.setState({ searchKeyword: keyword })
    this.getActivityList({
      page: 1,
      perPage: this.state.pagination.perPage
    }, keyword)
  }

  handleOrder = (e: any) => {
    this.setState({ order: e.target.value })
    this.getActivityList({
      page: 1,
      perPage: this.state.pagination.perPage
    }, this.state.searchKeyword, e.target.value)
  }
  // Customizable Area End
}
