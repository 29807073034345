import React, { Fragment } from "react";
// Customizable Area Start
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Avatar, Checkbox, IconButton, Icon, Switch, Modal, Backdrop, Fade } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { KeyboardBackspace, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import { editIcon, showIcon, hideIcon } from "./assets";
// Customizable Area End

import AdminConsoleController, {
    Props,
    configJSON
} from "./AdminConsoleController";


class AdminPreviewModal extends AdminConsoleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            classes,
            showModal,
            closeModalHandler,
            adminDetails
        } = this.props

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModal}
                onClose={closeModalHandler}
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes: {
                        root: classes.backdrop
                    }
                }}
            >
                <Fade in={showModal}>
                    <Box className={classes.container} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ height: 60, backgroundColor: "#1a181d" }} >
                                <Box display="flex" alignItems='center' pl='26px' >
                                    <KeyboardBackspace style={{ height: 24, width: 24, marginRight: 18, color: '#ffffff' }} />
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#ffffff'
                                    }} >Preview</Typography>
                                </Box>
                                <IconButton onClick={closeModalHandler} style={{ marginRight: 12, }} >
                                    <Icon>
                                        <CloseIcon style={{ height: 24, width: 24, opacity: 0.7, color: '#ffffff' }} />
                                    </Icon>
                                </IconButton>
                            </Grid>
                            <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ height: 141, backgroundColor: "#f1f1f1", paddingLeft: 24 }}>
                                <Box display='flex'>
                                    <Avatar alt={adminDetails?.attributes?.full_name} style={{ height: 93, width: 93, marginRight: 12 }} src={adminDetails?.attributes?.avatar} />
                                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='space-between' >
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 20,
                                            fontWeight: 500,
                                            color: 'rgba(0, 0, 0, 0.87)'
                                        }}>{adminDetails?.attributes?.full_name}</Typography>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 12,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            marginTop: 4
                                        }}>{`${adminDetails?.attributes?.email} | +${adminDetails?.attributes?.country_code} ${adminDetails?.attributes?.phone_number}`}</Typography>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 12,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            marginTop: 6
                                        }}>{`${adminDetails?.attributes?.city_district}, ${adminDetails?.attributes?.state}, ${adminDetails?.attributes?.country}`}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: 24, paddingTop: 24, paddingBottom: 32 }}>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff" }}>
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}>Permissions</Typography>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 16 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Dashboard</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.dashboard?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Requirements</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.requirements?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 16 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Profile</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.profile?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Reports</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.reports?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid sm={12} alignItems='center' justify='flex-start' container={true} style={{ backgroundColor: "#ffffff", marginTop: 16 }}>
                                    <Grid sm={12} md={4} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff" }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Manage User</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.manage_user?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sm={12} md={6} alignItems='center' justify='space-between' container={true} style={{ backgroundColor: "#ffffff", paddingLeft: '82px' }}>
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }}>Roles & Permissions</Typography>
                                        <Box display='flex' >
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2
                                            }}>R</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.read_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>W</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.write_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                            <Typography style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#1a181d',
                                                opacity: 0.57,
                                                marginRight: 2,
                                                marginLeft: 8,
                                            }}>D</Typography>
                                            <Checkbox
                                                checked={adminDetails?.permissions?.roles_and_permissions?.delete_permission}
                                                disabled={true}
                                                style={{ padding: 0 }}
                                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18, opacity: 0.57 }} />}
                                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '56px',
        paddingBottom: '50px',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    container: {
        overflow: 'auto',
        display: 'flex',
        width: 800,
        height: 'auto',
        maxHeight: '100%',
        borderRadius: 2,
        boxShadow: '0 7px 8px 4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
        backgroundColor: '#fafafa'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, 0.32)',
        paddingRight: 12,
        paddingLeft: 16,
    },
    toggleTrack: {
        backgroundColor: '#e45d33 !important'
    },
    inactiveToggleTrack: {
        backgroundColor: 'rgba(0, 0, 0, 0.38) !important'
    },
    toggleRoot: {
        marginLeft: 10
    }
});

export default withStyles(styles)(AdminPreviewModal)
// Customizable Area End