//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import Storage from "../../../../framework/src/StorageProvider.web";
import * as yup from 'yup';
import {
  allJobDetailApiContentType,
  allJobDetailApiMethod,
  allJobDetailApiEndPoint,
  postJobApiContentType,
  postJobApiMethod,
  postJobApiEndPoint,
} from "../config";

export interface Props {
  navigation: any;
  id: string;
  isJobPreview: boolean;
  jobPreviewModal: any;
  closeJobPreviewModal: any;
  openJobPreviewModal: any;
  backToEdit: any;
  onChangeLayout?: (height: number) => void;
  applyJob: () => void;
  // postJob: () => void;
  exportJob: () => void;
}

interface S {
  // Customizable Area Start
  requrimentTitle: string;
  txtSavedValue: string;
  enableField: boolean;
  sidemenuHeight: any;
  isOpenEditPost: boolean;
  jobDetails: Object;
  showDataAttribute: Array<any>
}

interface SS {
  id: any;
}

export default class JobPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {

  allJobDetailApiMessageId: any;
  postJobApiRequestMessageId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      requrimentTitle: '',
      txtSavedValue: "A",
      enableField: false,
      isOpenEditPost: false,
      jobDetails: {},
      showDataAttribute: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  editPostModal = () => {
    this.setState({ isOpenEditPost: !this.state.isOpenEditPost })
    this.props.closeJobPreviewModal()
  }

  closeEditPostModal = () => {
    this.setState({ isOpenEditPost: false })
  }

  applyJob = () => {
    if (this.props.isJobPreview) {
      this.props.closeJobPreviewModal()
      this.showAlert("", "In Progress", "OK");
    }
  }

  onPostJob = () => {
    if (this.props.isJobPreview) {
      this.props.closeJobPreviewModal()
      this.showAlert("", "Successfully Posted", "OK");
      this.props.navigation.navigate('JobRequirements');
    }
  }

  exportJob = () => {
    if (this.props.isJobPreview) {
      this.props.closeJobPreviewModal()
      this.showAlert("", "In Progress", "OK");
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (this.allJobDetailApiMessageId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.allJobDetailApiMessageId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {
        const data1 = responseJson.data?.attributes
        // const data2 = responseJson.data.attributes?.search_strategy.data?.attributes
        // const data3 = responseJson.data.attributes?.search_strategy.data?.attributes?.target_pool
        const jobDetails = data1
        let showDataAttribute = []
        if (responseJson.data.attributes?.search_strategy.data) {
          showDataAttribute = [...responseJson.data.attributes?.post_preferences, ...responseJson.data.attributes?.search_strategy.data?.attributes.post_preferences]
        }
        this.setState({ jobDetails, showDataAttribute });
      }
    }

    else if (this.postJobApiRequestMessageId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.postJobApiRequestMessageId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {
        // this.setState({ jobDetails: responseJson.data.attributes });
        this.onPostJob()
      }
    }
    // Customizable Area End
  }

  async getJobDetails() {
    const authToken = await Storage.get("authToken");
    const jobId = localStorage.getItem("id")

    const header = {
      "Content-Type": allJobDetailApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allJobDetailApiMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${allJobDetailApiEndPoint}${jobId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      allJobDetailApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postJob = async () => {
    const authToken = await Storage.get("authToken");
    const jobId = localStorage.getItem("id")
    const postJobDetailSuccess = { job_posted: true }
    const httpBody = postJobDetailSuccess

    const header = {
      "Content-Type": postJobApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postJobApiRequestMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${postJobApiEndPoint}${jobId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      postJobApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
