import React, { Fragment } from "react";
// Customizable Area Start
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Link, Select, MenuItem, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { KeyboardBackspace, Search, ArrowDropDown, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { editIcon, showIcon, hideIcon, dragIcon } from "./assets";
// Customizable Area End

import CustomisedOrderStatusController, {
    Props,
    configJSON
} from "./CustomisedOrderStatusController";


class ActivityLog extends CustomisedOrderStatusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getActivityList({
            page: 1,
            perPage: this.state.pagination.perPage
        })
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { activityList, pagination } = this.state
        return (
            <Box className={classes.container} >
                <Box className={classes.listContainer} >
                    <Grid sm={12} container={true} style={{ height: '100%', display: 'block' }} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={8} container={true} >
                                <Box style={{ padding: 24, }} >
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#1a181d'
                                    }} >Activity Log</Typography>
                                    <Typography style={{
                                        opacity: 0.4,
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }}>Lorem Ipsume demo contetn should be here so we can see how it works.</Typography>
                                </Box>
                            </Grid>
                            <Grid alignItems='flex-end' sm={4} container={true} style={{
                                paddingTop: 24,
                                paddingRight: 24,
                                paddingBottom: 24,
                                flexDirection: 'column'
                            }} >
                                <Link href='/admin/system-management/status' underline='none' style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#e45d33',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}> <KeyboardBackspace style={{ color: '#e45d33', width: 18, height: 18, marginRight: 6 }} /> BACK</Link>

                                <Box className={classes.searchBoxContainer} >
                                    <input required style={{
                                        width: '100%',
                                        padding: 0,
                                        border: 'none',
                                        outline: 'none',
                                        height: '100%',
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        paddingLeft: 14
                                    }}
                                        type='text'
                                        value={this.state.searchKeyword}
                                        onChange={(e: any) => this.handleSearch(e.target.value)}
                                        placeholder='Search Here'
                                    />
                                    <Search style={{ color: '#000000', width: 17, opacity: 0.57, height: 17, marginRight: 22 }} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sm={12} justify='flex-end' alignItems='center' container={true} style={{
                            backgroundColor: '#f1f1f1',
                            height: 52,
                            borderRadius: 2,
                            // border: '1px solid rgba(0, 0, 0, 0.12)',
                            paddingRight: 24,
                        }} >

                            <Typography style={{
                                fontFamily: 'Poppins',
                                fontSize: 12,
                                fontWeight: 'normal',
                                color: 'rgba(0, 0, 0, 0.6)'
                            }} >Show</Typography>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={pagination.perPage}
                                onChange={this.handlePerpageSelect}
                                IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                                classes={{ select: classes.selectContainer }}
                                style={{
                                    fontFamily: 'Poppins',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    borderRadius: 4,
                                    borderBottom: 'none',
                                    border: '1px solid #dddddd',
                                    backgroundColor: '#ffffff',
                                    width: 44,
                                    height: 28,
                                    marginRight: 6,
                                    marginLeft: 8,
                                    padding: 0
                                }}
                                disableUnderline={true}
                                defaultValue={pagination.perPage}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>

                            <Typography style={{
                                fontFamily: 'Poppins',
                                fontSize: 12,
                                fontWeight: 'normal',
                                color: 'rgba(0, 0, 0, 0.6)',
                                marginRight: 12,
                            }} >{`Showing ${+pagination.totalCount === 0 ? 1 : pagination.currentPage} to ${+pagination.totalPages === 0 ? 1 : pagination.totalPages} of ${pagination.totalCount}`}</Typography>

                            <IconButton onClick={() => this.handlePagination(+pagination.currentPage - 1)} disabled={+pagination.currentPage === 1} style={{ padding: 0, opacity: +pagination.currentPage === 1 ? 0.2 : 1 }}>
                                <ChevronLeft style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                            </IconButton>

                            <IconButton onClick={() => this.handlePagination(+pagination.currentPage + 1)} disabled={+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages} style={{ padding: 0, opacity: (+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages) ? 0.2 : 1 }}>
                                <ChevronRight style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                            </IconButton>

                        </Grid>
                        {!activityList.loading && activityList.data.length > 0 && (
                            <Grid sm={12} container={true} style={{ display: 'block' }} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead style={{ borderBottom: '1px solid rgba(221, 221, 221, 0.57)' }}>
                                        <TableRow>
                                            <TableCell >
                                                <Box display='flex' >
                                                    <Typography style={{
                                                        fontFamily: 'Roboto',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        color: 'rgba(0, 0, 0, 0.6)'
                                                    }} >Sort By : </Typography>

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.order}
                                                        onChange={this.handleOrder}
                                                        IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                                                        classes={{ select: classes.selectContainer }}
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                            marginLeft: 5,
                                                            marginTop: 2,
                                                            padding: 0
                                                        }}
                                                        disableUnderline={true}
                                                        defaultValue={'default'}
                                                    >
                                                        <MenuItem value={'default'}>Default</MenuItem>
                                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                                    </Select>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.clolumnHeading}>Status Head</TableCell>
                                            <TableCell className={classes.clolumnHeading}>Name</TableCell>
                                            <TableCell className={classes.clolumnHeading}>Last Updated</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {activityList.data.map((activity: any, i: number) => (
                                            <TableRow key={activity.id}>
                                                <TableCell className={classes.heading} style={activityList.data.length - 1 === i ? { borderBottom: 'none' } : {}} >{activity.attributes.name}</TableCell>
                                                <TableCell className={classes.clolumnHeading} style={activityList.data.length - 1 === i ? { borderBottom: 'none' } : {}}>{activity.attributes.status_head}</TableCell>
                                                <TableCell className={classes.clolumnHeading} style={activityList.data.length - 1 === i ? { borderBottom: 'none' } : {}}>{activity.attributes.user.data.attributes.name}</TableCell>
                                                <TableCell className={classes.clolumnHeading} style={activityList.data.length - 1 === i ? { borderBottom: 'none' } : {}}>{activity.attributes.last_updated}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        )}
                        {activityList.loading && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='65%' >
                                <CircularProgress style={{ color: "#e45d33" }} />
                            </Box>
                        )}
                        {!activityList.loading && activityList.data.length === 0 && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='55%' >
                                <Typography style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: 'rgba(0, 0, 0, 0.6)'
                                }} >No records to display</Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
            </Box >
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: "9.5rem",
        paddingBottom: "7.8%",
        backgroundColor: "#fcfcfc",
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '85%',
        borderRadius: 2,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        overflow: 'hidden'
    },
    searchBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ffffff',
        height: 40,
        marginTop: 21,
        width: '100%',
        maxWidth: 414
    },
    selectContainer: {
        padding: '0px !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus': {
            backgroundColor: '#ffffff',
        }
    },
    clolumnHeading: {
        fontFamily: 'Poppins',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    heading: {
        fontFamily: 'Poppins',
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    }
});


export default withStyles(styles)(ActivityLog)
// Customizable Area End
