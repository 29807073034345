//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { HiOutlinePencil } from 'react-icons/hi';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiShoppingBag } from 'react-icons/bi';
import Avatar from '@material-ui/core/Avatar';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { defaultProfileImg } from "../assets";
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import { withRouter } from "react-router-dom";

import EditJobPost from "./EditJobPost.web";
import PdfDemo from "./PdfDemo.web";
import JobPreviewController, {
  Props,
  // configJSON
} from "./JobPreviewController";

const baseStyles = {
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100rem",
    marginLeft: "18%",
    marginTop: "7rem",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "1px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    background: "black",
    color: "white",
  },
  modalCloseButton: {
    color: "rgb(126, 126, 126)",
    "&, &:hover": {
      color: "rgb(126, 126, 126)",
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + "white",
    opacity: ".5"
  },
  modalBody: {
    position: "relative",
    overflow: "visible",
  },
  modalFooter: {
    textAlign: "center",
    margin: "0",
    background: "#80808036"
  },
  heading: {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  headerLine: {
    borderTop: "1px solid grey",
    marginRight: -22,
    marginLeft: -24,
    borderBottom: 0,
  },
  topButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 15,
    paddingBottom: 18,
  },
  topButtonText: {
    color: "rgb(175 166 166)",
    fontSize: 14,
    fontWeight: "600",
    '&:hover': {
      color: 'rgb(228, 93, 51)',
    },
  },
  topButtonTextLast: {
    color: "rgb(175 166 166)",
    fontSize: 14,
    fontWeight: "500",
    paddingRight: 10,
    '&:hover': {
      color: 'rgb(228, 93, 51)',
    },
  },
  subHeadingText: {
    fontSize: 25,
  },
  buttonBelowText: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 1.5,
  },
  postText: {
    display: "inline-flex",
    fontSize: 12,
    marginTop: "17%",
    color: "#676060ed",
  },
  blockFirstTextHeading: {
    color: "rgb(128 128 128 / 88%)",
    fontSize: 12,
    letterSpacing: 2,
    fontWeight: 500,
  },
  blockFirstText: {
    fontSize: 12,
    marginTop: 12
  },
  contentLine: {
    borderTop: "1px solid #8080801a",
    marginLeft: -24,
    marginRight: -22,
    borderBottom: 0,
    marginTop: 26,
  },
  secondBlockHeading: {
    fontSize: 16,
    color: "#545151",
    paddingTop: 10,
  },
  thirdBlock: {
    paddingTop: 10,
  },
  fourthBlock: {
    justifyContent: "space-between",
    paddingTop: 10,
    width: "35%",
  },
  keywordText: {
    border: "1px solid #8080805c",
    padding: 5,
    borderRadius: 6,
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 10,
    marginTop: 15,
  },
  userNameText: {
    display: "grid",
    // position: "absolute",
    // marginLeft: "8%",
    fontSize: 12,
  },
  sendMessageText: {
    color: 'rgb(228, 93, 51)',
    letterSpacing: 1.5,
    fontWeight: 500,
    fontSize: 16
  },
  pdfText: {
    '&:hover': {
      color: 'rgb(228, 93, 51) !important'
    },
  }
}

const sapmleKey = [
  "work_experience_min", "min_salary", "employment_type", "vacancies", "reporting_to", "team",
  "visibility"
]

const reference = ["#1245564", "#74773665", "#6354266"]
class JobPreview extends JobPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isJobPreview !== prevProps.isJobPreview) {
      window.scrollTo(0, 0)
      this.getJobDetails()
    }
    // this.getJobDetails()
  }

  render() {
    const {
      props: { isJobPreview, jobPreviewModal, classes },
      state: { jobDetails, showDataAttribute }
    } = this

    const {
      work_experience_min,
      recruiters,
      reference_codes,
      reporting_to,
      requirement_title,
      show_contact_details,
      team,
      vacancies,
      visibility,
      work_experience_max,
      industry,
      job_details,
      job_location_details,
      job_posted,
      job_title,
      key_performance_indicators,
      key_result_areas,
      keyword_skill,
      max_salary,
      min_salary,
      other_salary_details,
      post_graduation,
      account,
      companies,
      currency,
      desired_candidate_profile,
      doctorate,
      employment_type,
      functional_area,
      graduation,
      brief,
      role_dynamics,
      conclusion,
      questions,
      all_keywords,
      annual_salary_max,
      annual_salary_min,
      any_keywords,
      candidate_age_from,
      candidate_age_to,
      comments,
      current_employer,
      current_location,
      designation_all_words,
      designation_current_employer,
      designation_type_her,
      employer_all_words,
      employer_type,
      exclude_current_employer,
      exclude_employer_all_words,
      exclude_employer_type,
      exclude_keywords,
      experience_max,
      experience_min,
      file_url,
      include_profile_with_zero_salary_or_not_mentioned,
      industry_type,
      notice_period,
      pg_qualification_any,
      pg_qualification_enforced,
      ppg_qualification_any,
      ppg_qualification_enforced,
      profile_source,
      resume_per_page_relevance,
      resume_processed_by,
      role,
      search_candidate_special_ability,
      search_in,
      search_women_candidate_only,
      show_cand_attached_resume,
      show_cand_email_id,
      show_cand_mobile_no,
      show_cand_search_only_premium_resume,
      show_candidate_seeking,
      sort_by_relevance,
      star_rating_from,
      star_rating_to,
      status,
    } = jobDetails

    console.log("job=============", jobDetails.employment_type, showDataAttribute)
    return (
      <>
        <DialogModal
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={isJobPreview}
          onClose={jobPreviewModal}
          PaperProps={{
            style: {
              minHeight: "80%",
              minWidth: "60%",
              width: "auto"
            }
          }}
        >
          <DialogTitle className={classes.modalHeader}>
            <Button
              className={classes.modalCloseButton}
              onClick={jobPreviewModal}
            >
              {" "}
              <Close style={{ color: "aliceblue" }} />
            </Button>
            <h3 className={classes.heading}>Job Preview</h3>
            <hr className={classes.headerLine} />
            <View className={classes.topButtonContainer}>
              <TouchableOpacity
                className={classes.textButtonContainer}
                onPress={() => {
                  if (this.props.backToEdit) {
                    this.props.backToEdit()
                    this.props.onEdit()
                  }
                }}
              >
                <Text className={classes.topButtonText}>
                  <HiOutlinePencil />  EDIT
                </Text>
              </TouchableOpacity>
              <Text style={{ color: "#ffffff", paddingLeft: 10, paddingRight: 10, opacity: 0.4 }}>|</Text>
              <TouchableOpacity
                className={classes.textButtonContainer}
                onPress={() => this.editPostModal()}
              >
                <Text className={classes.topButtonText}>
                  <AiOutlineDownload /> PDF EXPORT
                </Text>
              </TouchableOpacity>
              <Text style={{ color: "#ffffff", paddingLeft: 10, paddingRight: 10, opacity: 0.4 }}>|</Text>
              <TouchableOpacity
                onPress={this.postJob}
                className={classes.textButtonContainer}
              >
                <Text className={classes.topButtonTextLast}>
                  <BiShoppingBag />POST JOB
                </Text>
              </TouchableOpacity>
            </View>
          </DialogTitle>
          <DialogContentBox className={classes.modalBody}>
            <View style={{ background: "#80808036", paddingTop: 18, padding: 20, paddingBottom: 10, flexDirection: "row" }}>
              <Grid container style={{ display: "contents" }}>
                <Grid item xs={10}>
                  <div style={{ display: "grid" }}>
                    <Text className={classes.subHeadingText}>{requirement_title}</Text>
                    <Text style={{ color: "#676060ed", fontSize: 14, marginTop: 10 }}>Google Inc. | Mumbai India</Text>
                  </div>
                </Grid>
                <View>

                  <TouchableOpacity
                    className={classes.button}
                    style={{ backgroundColor: "#e45d33", borderColor: "#e45d33", width: '11rem', left: 11 }}
                  >
                    <Text className={classes.buttonText} style={{ color: "white" }}>
                      Apply Now
                    </Text>
                  </TouchableOpacity>
                  {/* <div style={{ marginTop: 14 }}>
                    <Text className={classes.buttonBelowText} style={{ color: "#e45d33", fontSize: 14 }}>
                      SEND ME JOB LIKE THIS
                  </Text>
                  </div> */}
                  <Text style={{ fontSize: 11, color: "gray", marginTop: 10, display: "-webkit-inline-box" }}>Posted 1 week ago - <Text style={{ color: "black", display: "flex" }}>12 Applicants</Text></Text>
                </View>
              </Grid>
            </View>
            <div style={{ margin: 30 }}>
              <Grid container>
                {showDataAttribute.filter(({ name, value }) => name === 'work_experience' && value == true).find(({ name }) => name === 'work_experience')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>EXPERINCE</Text>
                      <Text className={classes.blockFirstText}>{work_experience_min}-{work_experience_max} years</Text>
                      {/* <Text className={classes.blockFirstText}>3-8 years</Text> */}
                    </div>
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'annual_ctc' && value == true).find(({ name }) => name === 'annual_ctc')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>SALARY</Text>
                      <Text className={classes.blockFirstText}>{currency} {min_salary}-{max_salary}L.P.A</Text>
                      {/* <Text className={classes.blockFirstText}>₹ 3.25 - 4.75 L. P.A</Text> */}
                    </div>
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'employment_type' && value == true).find(({ name }) => name === 'employment_type')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>EMPLOYMENT</Text>
                      <Text className={classes.blockFirstText}>{employment_type}</Text>
                      {/* <Text className={classes.blockFirstText}>Full Time</Text> */}
                    </div>
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'vacancies' && value == true).find(({ name }) => name === 'vacancies')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>OPENINGS</Text>
                      <Text className={classes.blockFirstText}>{vacancies}</Text>
                      {/* <Text className={classes.blockFirstText}>05</Text> */}
                    </div>
                  </Grid>
                }
              </Grid>
              <Grid container style={{ marginTop: 22 }}>
                {showDataAttribute.filter(({ name, value }) => name === 'reporting_to' && value == true).find(({ name }) => name === 'reporting_to')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>REPORTING TO</Text>
                      <Text className={classes.blockFirstText}>{reporting_to}</Text>
                      {/* <Text className={classes.blockFirstText}>Rahul Sahu</Text> */}

                    </div>
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'team' && value == true).find(({ name }) => name === 'team')?.value &&
                  <Grid item xs={3}>
                    <div style={{ display: "grid" }}>
                      <Text className={classes.blockFirstTextHeading}>REPROTEES/TEAM</Text>
                      <Text className={classes.blockFirstText}>{team}</Text>
                      {/* <Text className={classes.blockFirstText}>Creative Design Agency</Text> */}
                    </div>
                  </Grid>
                }
                {/* {showDataAttribute.find(({name}) => name === 'visibility')?.value && */}
                {/* <Grid item xs={3}>
                  <div style={{ display: "grid" }}>
                    <Text className={classes.blockFirstTextHeading}>VISIBILITY SETTING</Text>
                    <Text className={classes.blockFirstText}>{visibility}</Text>
                  </div>
                </Grid> */}
                {/* } */}
              </Grid>
              <hr className={classes.contentLine} />
              {showDataAttribute.filter(({ name, value }) => name === 'job_details' && value == true).find(({ name }) => name === 'job_details')?.value &&
                <Grid container>
                  <div style={{ display: "grid" }}>
                    <Text className={classes.secondBlockHeading}>Job Description</Text>
                    <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>{job_details}</Text>
                    {/* <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </Text> */}
                  </div>
                </Grid>
              }
              {showDataAttribute.filter(({ name, value }) => name === 'desired_candidate_profile' && value == true).find(({ name }) => name === 'desired_candidate_profile')?.value &&
                <Grid container style={{ paddingTop: 20 }}>
                  <div style={{ display: "grid" }}>
                    <Text className={classes.secondBlockHeading}>Desired Candidate Profile</Text>
                    <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>{desired_candidate_profile}</Text>
                    {/* <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </Text> */}
                  </div>
                </Grid>
              }
              {showDataAttribute.filter(({ name, value }) => name === 'key_performance_indicators' && value == true).find(({ name }) => name === 'key_performance_indicators')?.value &&
                <Grid container style={{ paddingTop: 20 }}>
                  <div style={{ display: "grid" }}>
                    <Text className={classes.secondBlockHeading}>Key Performance Indicators</Text>
                    <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>{key_performance_indicators}</Text>
                    {/* <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </Text> */}
                  </div>
                </Grid>
              }
              <div style={{ paddingTop: 25 }}>
                {showDataAttribute.filter(({ name, value }) => name === 'industry' && value == true).find(({ name }) => name === 'industry')?.value &&
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>
                      <Text style={{ color: "gray", fontSize: 12 }}>Industry Type</Text>
                    </Grid>
                    {/* <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>IT-Software / Software Services</Text> */}
                    <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>{industry?.name}
                      {/* {industry?.map((item) => (item?.name))} */}
                    </Text>
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'functional_area' && value == true).find(({ name }) => name === 'functional_area')?.value &&
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>
                      <Text style={{ color: "gray", fontSize: 12 }}>Functional Area</Text>
                    </Grid>
                    {/* <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>Design, Creative, User Experience</Text> */}
                    <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>{functional_area?.name}
                      {/* {functional_area?.map((item) => {
                      return (
                  {functional_area?.name}
                  )
                    })} */}
                    </Text>
                  </Grid>
                }
              </div>
              <div style={{ paddingTop: 25 }}>
                {(showDataAttribute.filter(({ name, value }) => name === 'graduation' && value == true).find(({ name }) => name === 'graduation')?.value ||
                  showDataAttribute.filter(({ name, value }) => name === 'post_graduation' && value == true).find(({ name }) => name === 'post_graduation')?.value ||
                  showDataAttribute.filter(({ name, value }) => name === 'doctorate' && value == true).find(({ name }) => name === 'doctorate')?.value)
                  &&
                  <Text className={classes.secondBlockHeading}>Education</Text>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'graduation' && value == true).find(({ name }) => name === 'graduation')?.value &&
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>
                      <Text style={{ color: "gray", fontSize: 12 }}>UG</Text>
                    </Grid>
                    <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>{graduation?.name}</Text>
                    {/* <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>Graduation Not Required</Text> */}

                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'post_graduation' && value == true).find(({ name }) => name === 'post_graduation')?.value &&
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>
                      <Text style={{ color: "gray", fontSize: 12 }}>PG</Text>
                    </Grid>
                    <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>{post_graduation?.name}</Text>
                    {/* <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>Graduation Not Required</Text> */}
                  </Grid>
                }
                {showDataAttribute.filter(({ name, value }) => name === 'doctorate' && value == true).find(({ name }) => name === 'doctorate')?.value &&
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>
                      <Text style={{ color: "gray", fontSize: 12 }}>Doctorate</Text>
                    </Grid>
                    <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>{doctorate?.name}</Text>
                    {/* <Text style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.8)", paddingTop: 4 }}>Graduation Not Required</Text> */}

                  </Grid>
                }
              </div>
              {showDataAttribute.filter(({ name, value }) => name === 'keywords' && value == true).find(({ name }) => name === 'keywords')?.value &&
                <div style={{ paddingTop: 25 }}>
                  <Text className={classes.secondBlockHeading}>Keywords</Text>
                  <Grid container>
                    {/* {keywords &&  */}
                    {keyword_skill?.data.map((item) => (
                      <Text className={classes.keywordText}>{item?.attributes?.name}</Text>
                    ))}
                    {/* <Text className={classes.keywordText}>Figma</Text>
                  <Text className={classes.keywordText}>User interface designing</Text>
                  <Text className={classes.keywordText}>UX</Text>
                  <Text className={classes.keywordText}>Photoshop</Text>
                  <Text className={classes.keywordText}>User Experience Design</Text> */}
                    {/* // } */}
                  </Grid>
                </div>
              }
              {showDataAttribute.filter(({ name, value }) => name === 'any_questions_for_candidate' && value == true).find(({ name }) => name === 'any_questions_for_candidate')?.value &&
                <div style={{ paddingTop: 25, display: "grid" }}>
                  <Text className={classes.secondBlockHeading}>Question</Text>
                  {questions?.map((item) => {
                    return (
                      <Text style={{ paddingTop: 9, color: "gray" }}>
                        {item?.question}
                      </Text>
                    )
                  })}
                </div>
              }
              <div style={{ paddingTop: 20 }}>
                <Grid container direction="row" alignItems="flex-start">
                  {showDataAttribute.filter(({ name, value }) => name === 'reference_codes' && value == true).find(({ name }) => name === 'reference_codes')?.value &&
                    <Grid item xs={6}>
                      <Grid container direction="column">
                        <Grid item>
                          <Text className={classes.secondBlockHeading}>Reference Code</Text>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" alignItems="center">
                            {reference?.map((item) => {
                              return (
                                <Text className={classes.keywordText}>{item}</Text>
                              )
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {showDataAttribute.filter(({ name, value }) => name === 'recruiter' && value == true).find(({ name }) => name === 'recruiter')?.value &&
                    <Grid item xs={6}>
                      <Grid container direction="column" style={{ display: "contents" }}>
                        <Grid item>
                          <Text className={classes.secondBlockHeading}>Hiring For</Text>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" alignItems="center">
                            <Text className={classes.keywordText}>Mr. Amit Sharma</Text>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {showDataAttribute.filter(({ name, value }) => name === 'reference_codes' && value == true).find(({ name }) => name === 'reference_codes')?.value &&
                    <Grid item xs={8}>
                      <div style={{ display: "grid" }}>
                        <Grid>
                          <Text className={classes.secondBlockHeading}>Reference Code</Text>
                        </Grid>
                        <div style={{ marginTop: 15 }}>
                          {reference?.map((item) => {
                            return (
                              <Text className={classes.keywordText}>{item}</Text>
                            )
                          })}
                        </div>
                      </div>
                    </Grid>
                  }
                  {/* <Grid item xs={6}>
                    <div style={{ display: "grid" }}>
                      <Grid>
                        <Text className={classes.secondBlockHeading}>Hiring For</Text>
                      </Grid>
                      <div style={{ marginTop: 15 }}> */}
                  {/* {recruiters && 
                            <Text className={classes.keywordText}>{recruiters}</Text>
                          } */}
                  {/* <Text className={classes.keywordText}>Mr. Amit Sharma</Text>
                      </div>
                    </div>
                  </Grid> */}
                </Grid>
              </div>
              {/* {show_contact_details && */}
              <>
                <hr className={classes.contentLine} />
                <div style={{ paddingTop: 20 }}>
                  <Text className={classes.secondBlockHeading}>Contact Person</Text>
                  <Grid container style={{ paddingTop: 10 }}>
                    <Grid item xs={2}>
                      <CardAvatar alt="profile" src={defaultProfileImg} />
                    </Grid>
                    <Grid item style={{ position: "absolute", left: 100, paddingTop: 5 }}>
                      <div className={classes.userNameText}>
                        <Text>Mr. Ravi Shastri</Text>
                        <Text style={{ paddingTop: 9, color: "gray" }}>+91 700987655</Text>
                      </div>
                    </Grid>
                    <Grid item style={{ position: "absolute", right: 25 }}>
                      <TouchableOpacity>
                        <Text className={classes.sendMessageText}>
                          SEND MESSAGE
                        </Text>
                      </TouchableOpacity>
                    </Grid>
                  </Grid>
                </div>
              </>
              {/* } */}
              <hr className={classes.contentLine} />
              <Grid container>
                <div style={{ display: "grid" }}>
                  <Text className={classes.secondBlockHeading}>About Company</Text>
                  <Text style={{ paddingTop: 9, color: "gray", fontSize: 12 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </Text>
                </div>
              </Grid>
              <div style={{ paddingTop: 25 }}>
                <Text className={classes.secondBlockHeading}>Company Info</Text>
                <Grid container style={{ paddingTop: 10 }}>
                  <Grid item xs={3}>
                    <Text style={{ color: "gray", fontSize: 12 }}>Name</Text>
                  </Grid>
                  <Text style={{ fontSize: 12 }}>Google Inc.</Text>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                  <Grid item xs={3}>
                    <Text style={{ color: "gray", fontSize: 12 }}>Website</Text>
                  </Grid>
                  <Text style={{ color: "#e45d33", fontSize: 12 }}>www.google.com</Text>
                </Grid>
              </div>
            </div>
          </DialogContentBox>
          <div className={classes.modalFooter}>
            <div style={{ padding: 20 }}>
              <Grid container>
                <Grid item>
                  <TouchableOpacity
                    onPress={() => this.editPostModal()}
                    className={classes.button}
                    style={{ marginRight: "2%", backgroundColor: "black", borderColor: "black" }}
                  >
                    <Text className={classes.buttonText} style={{ color: "white" }}>
                      EXPORT PDF
                    </Text>
                  </TouchableOpacity>
                </Grid>
                <View style={{ display: "contents" }}>
                  <TouchableOpacity
                    onPress={() => {
                      if (this.props.backToEdit) {
                        this.props.backToEdit()
                        this.props.onEdit()
                      }
                    }}
                    className={classes.button}
                    style={{ marginRight: "2%", backgroundColor: "black", borderColor: "black", marginLeft: "auto" }}
                  >
                    <Text className={classes.buttonText} style={{ color: "white" }}>
                      EDIT
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={this.postJob}
                    className={classes.button}
                    style={{ backgroundColor: "#e45d33", borderColor: "#e45d33" }}
                  >
                    <Text className={classes.buttonText} style={{ color: "white" }}>
                      POST JOB
                    </Text>
                  </TouchableOpacity>
                </View>
              </Grid>
            </div>
          </div>
        </DialogModal>
        <EditJobPost
          editPostModal={this.editPostModal}
          isOpenEditPost={this.state.isOpenEditPost}
          openJobPreviewModal={this.props.openJobPreviewModal}
          closeEditPostModal={this.closeEditPostModal}
          backToEdit={this.props.backToEdit}
          onEdit={this.props.onEdit}
        />
      </>
    );
  }
}

export default withStyles(baseStyles)(JobPreview)

const DialogContentBox = withStyles({
  root: {
    padding: 0,
  }
})(DialogContent)

const CardAvatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(Avatar)

const DialogModal = withStyles({
  container: {
    height: "auto",
  }
})(Dialog)