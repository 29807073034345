// @ts-nocheck
import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
} from "react-native";
// Customizable Area End
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Loader from "../../../components/src/Loader";
import "./style.css";

import RolesPermissionsController, {
  Props,
  configJSON
} from "./RolesPermissionsController";

export default class RolesPermissions extends RolesPermissionsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({loading: true})
    await this.getRolesAndPermissions();
  }

  handleChange = (event, value) => {
    this.updateRolesAndPermissions( value.attributes.id, event.target.name, event.target.checked)
  };

  render() {
    const rolesPermissions = this.state.rolesAndPermissions;

    const colouns = rolesPermissions[0]?.attributes?.permissions.data.map((a) => {
      return a.attributes.feature_humanized;
    });

    return (
      <View style={styles.container}>
        <Loader loading={this.state.loading} />
        <span className="table-heading-subheading">
        <Typography className="tableHeading">
          Roles & Permissions
        </Typography>
        <Typography className="tableSubHeading">
         Lorem Ipsume demo contetn should be here so we can see how it works.
        </Typography>
        </span>
        <Table className="tableBorder">
        <TableHead>
          <TableRow>
           <TableCell></TableCell>
            {colouns?.map((temp, index) => {
              return <TableCell key={index} className="featureTitle">{temp}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesPermissions?.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                Default {row.attributes.name.charAt(0).toUpperCase() + row.attributes.name.slice(1)}
              </TableCell>
              {colouns.map((temp, index) => {
                const columnsData = row?.attributes?.permissions?.data;
                const columnData = columnsData.find((ab) => {
                  return ab.attributes?.feature_humanized === temp;
                });
                return (
                  <TableCell key={columnData?.id}>
                    R<input type="checkbox"  
                      className="commonCheckbox"
                       onChange={(event) => {
                        this.handleChange(event, columnData);
                        }}
                      
                    name="read_permission" checked={columnData?.attributes?.read_permission}/>
                    W<input type="checkbox" 
                      className="commonCheckbox"
                      onChange={(event) => {
                        this.handleChange(event, columnData);
                        }} 
                      name="write_permission" checked={columnData?.attributes?.write_permission}/>
                    D<input type="checkbox" 
                      className="commonCheckbox"
                      onChange={(event) => {
                        this.handleChange(event, columnData);
                        }}
                    name="delete_permission" checked={columnData?.attributes?.delete_permission}/>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
});
// Customizable Area End
