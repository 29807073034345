//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
// import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { HiOutlinePencil } from 'react-icons/hi';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiShoppingBag } from 'react-icons/bi';
import Avatar from '@material-ui/core/Avatar';
import { defaultProfileImg, companyLogoPNG, checkedPNG, uncheckedPNG } from "../assets";
import {
  TouchableOpacity,
} from 'react-native';
import JobPreviewController, {
  Props,
  // configJSON
} from "./JobPreviewController";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Editor, EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';


const styles = StyleSheet.create({
  body: {
    width: 595,
    minHeight: 842,
    background: "aliceblue",
  },
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100rem",
    marginTop: "130px !important"
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "1px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    background: "black",
    color: "white",
  },
  modalCloseButton: {
    color: "rgb(126, 126, 126)",
    "&, &:hover": {
      color: "rgb(126, 126, 126)",
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + "white",
    opacity: ".5"
  },
  modalBody: {
    position: "relative",
    overflow: "visible",
  },
  modalFooter: {
    textAlign: "center",
    margin: "0",
    background: "#80808036"
  },
  heading: {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "serif",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  headerLine: {
    borderTop: "1px solid grey",
    marginRight: -22,
    marginLeft: -24,
    borderBottom: 0,
  },
  topButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 15,
    paddingBottom: 18,
  },
  topButtonText: {
    color: "rgb(175 166 166)",
    fontSize: 18,
    fontWeight: "500",
    paddingRight: 30,
    '&:focus, &:hover, &$active': {
      color: 'rgb(228, 93, 51)',
    },
  },
  subHeadingText: {
    fontSize: 40,
  },
  buttonBelowText: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 1.5,
    marginLeft: -10,
  },
  postText: {
    display: "inline-flex",
    fontSize: 14,
    marginLeft: -35,
    marginTop: "20%",
    color: "#676060ed",
  },
  blockFirstTextHeading: {
    color: "rgb(128 128 128 / 88%)",
    fontSize: 17,
    letterSpacing: 2,
    fontWeight: 500,
  },
  blockFirstText: {
    fontSize: 16,
    marginTop: 12
  },
  contentLine: {
    borderTop: "1px solid #8080801a",
    marginLeft: -24,
    marginRight: -22,
    borderBottom: 0,
    marginTop: 26,
  },
  secondBlockHeading: {
    fontSize: 22,
    color: "#545151",
    paddingTop: 10,
  },
  thirdBlock: {
    width: "40%",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  fourthBlock: {
    justifyContent: "space-between",
    paddingTop: 10,
    width: "35%",
  },
  keywordText: {
    border: "1px solid #8080805c",
    padding: 5,
    borderRadius: 6,
    fontSize: 16,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 10,
    marginTop: 15,
  },
  userNameText: {
    display: "grid",
    position: "absolute",
    marginLeft: "8%",
  },
  sendMessageText: {
    color: 'rgb(228, 93, 51)',
    letterSpacing: 1.5,
    fontWeight: 500,
    fontSize: 16
  }
})

class PdfDemo extends JobPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const jobTitle = this.props.jobTitle;
    const toggleData = this.props.toggleData;
    console.log("beek ", jobTitle);

    const jobDetailFrist = {
      "Job Title": "job_title",
      "Reporting To": "reporting_to",
      "Reportees/Team": "team",
      "Employment Type": "employment_type",
      "Job Details": "job_details",
      "Key Result Areas": "key_result_areas",
      "Key Perfotmance Indicators": "key_performance_indicators",
      "Desired Candidate Profile": "desired_candidate_profile",
      "Annual CTC": "annual_ctc"
    }


    const jobDetailSecond = {
      "Other Salary Details": "other_salary_details",
      "Work Expreince": "work_experience",
      "Keywords": "keywords",
      "Job Located": "job_location_details",
      "Hiring For": "hiring_for",
      "Visibility Settings": "visibility",
      "Recuriter Name": "recruiter",
      "No. of Vacancies": "vacancies",
      "Refernce Code": "reference_code"
    }

    const postDetailsFirstList = {
      "Graduation": "graduation",
      "Post Graduation": "post_graduation",
      "Doctrate": "doctorate",
      "Industry": "industry"
    }

    const postDetailsSecondList = {
      "Functional Area": "functional_area",
      "Have any questions for the candidate": "any_questions_for_candidate",
      "Show contact details to jobseekers": "show_contact_details_to_jobseeker"
    }

    const searchStrategyFirstList = {
      "Brief": "brief",
      "Role Dynamics": "role_dynamics",
      "Required candidate skills": "required_candidate_skills"
    }

    const searchStrategySecondList = {
      "Target Pool": "target_pool",
      "Conclusion": "conclusion"
    }
    return (
      //@ts-ignore
      <Document>
        <Page>
          <View>
            <View style={pdstyles.companyBlock} />
            <View style={{ flexDirection: "row" }}>
              <View style={pdstyles.companyLogoContainer}>
                <Image source={companyLogoPNG.default || { uri: "https://robohash.org/temporeminimadebitis.png?size=100x100&set=set1" }} style={pdstyles.companyLogo} />
              </View>
              <View style={pdstyles.companyInfoContainer}>
                <Text style={pdstyles.companyTitle}>Google Inc.</Text>
                <Text style={pdstyles.companyAddress} >Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</Text>
              </View>
            </View>
          </View>
          <View style={pdstyles.contentContainer}>
            {toggleData.job_title &&
              <Text style={pdstyles.jobTitle}>{jobTitle || "---"}</Text>
            }
            {toggleData.job_location_details &&
              <Text style={pdstyles.jobAddress}>Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</Text>
            }
            {toggleData.required_candidate_skills &&
              <View style={pdstyles.tagsContainer}>
                <View style={pdstyles.tagItem}>
                  <Text style={pdstyles.tagText}>
                    Figma
                  </Text>
                </View>
                <View style={pdstyles.tagItem}>
                  <Text style={pdstyles.tagText}>
                    User Interface Designing
                  </Text>
                </View>
                <View style={pdstyles.tagItem}>
                  <Text style={pdstyles.tagText}>
                    UX
                  </Text>
                </View>
                <View style={pdstyles.tagItem}>
                  <Text style={pdstyles.tagText}>
                    Photoshop
                  </Text>
                </View>

              </View>
            }
            <View style={[pdstyles.separator, { marginTop: "16px" }]} />
            <View style={pdstyles.attContainer}>
              {toggleData.work_experience &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Experience
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    3 - 8 years
                  </Text>
                </View>
              }
              {toggleData.annual_ctc &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Salary
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    3.25 - 4.75 L. P.A
                  </Text>
                </View>
              }
              {toggleData.employment_type &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Employment
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Full Time
                  </Text>
                </View>
              }
              {toggleData.reporting_to &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Reporting To
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Rahul Sahu
                  </Text>
                </View>
              }
              {toggleData.team &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Reportees/Team
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Behance Team
                  </Text>
                </View>
              }
              {toggleData.vacancies &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Openings
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    05
                  </Text>
                </View>
              }
              {toggleData.visibility &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Visibility Settings
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Visibile for all
                  </Text>
                </View>
              }
              {toggleData.recruiter &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Recruiter Name
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Rohit Gupta
                  </Text>
                </View>
              }
              {toggleData.hiring_for &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Hiring For
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Team Behance
                  </Text>
                </View>
              }
              {toggleData.industry &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Industry Type
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    IT-Software / Software Services
                  </Text>
                </View>
              }
              {toggleData.functional_area &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Functional Area
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Design, Creative, User Experience
                  </Text>
                </View>
              }
              {toggleData.vacancies &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    No. of Vacancies
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    50
                  </Text>
                </View>
              }
            </View>

            <View style={[pdstyles.separator, { marginTop: "0px" }]} />
            <Text style={pdstyles.blockTitle}>Education</Text>
            <View style={pdstyles.attContainer}>

              {toggleData.graduation &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    UG
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Graduation Not Required
                  </Text>
                </View>
              }

              {toggleData.post_graduation &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    PG
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Graduation Not Required
                  </Text>
                </View>
              }

              {toggleData.doctorate &&
                <View style={pdstyles.attItem}>
                  <Text style={pdstyles.attKeyText}>
                    Doctorate
                  </Text>
                  <Text style={pdstyles.attValueText}>
                    Graduation Not Required
                  </Text>
                </View>
              }
            </View>


            <View style={[pdstyles.separator, { marginTop: "0px" }]} />
            {toggleData.job_details &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Job Description</Text>
                <Text style={pdstyles.blockText}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Text>
              </Fragment>
            }
            {toggleData.desired_candidate_profile &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Desired Candidate Profile</Text>
                <Text style={pdstyles.blockText}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Text>
              </Fragment>
            }
            {toggleData.key_performance_indicators &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Key Performance Indicators</Text>
                <Text style={pdstyles.blockText}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Text>
              </Fragment>
            }

            <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Question</Text>
            <Text style={[pdstyles.blockText, { color: "#1a181d", opacity: 1 }]}>
              What is best online solutions for marketing business?</Text>


            <Text style={[pdstyles.blockTitle, { marginBottom: "0px" }]}>Reference Code</Text>
            <View style={pdstyles.tagsContainer}>
              <View style={pdstyles.tagItem}>
                <Text style={pdstyles.tagText}>
                  #123456
                </Text>
              </View>
              <View style={pdstyles.tagItem}>
                <Text style={pdstyles.tagText}>
                  #123456
                </Text>
              </View>
              <View style={pdstyles.tagItem}>
                <Text style={pdstyles.tagText}>
                  #123456
                </Text>
              </View>
            </View>

            <View style={[pdstyles.separator]} />
            {toggleData.brief &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Brief</Text>
                <Text style={pdstyles.blockText}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Text>
              </Fragment>
            }
            {toggleData.role_dynamics &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Role Dynamics</Text>
                <Text style={pdstyles.blockText}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Text>
              </Fragment>
            }
          </View>
        </Page>
        <Page>
          <View style={[pdstyles.contentContainer, { marginTop: "24px" }]}>
            {toggleData.target_pool &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Target Pool</Text>
                <View style={pdstyles.targetPoolBox}>
                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Basic Details</Text>
                  {toggleData.required_candidate_skills &&
                    <Fragment>
                      <Text style={pdstyles.targetKey}>
                        Required Skills
                      </Text>
                      <View style={[pdstyles.tagsContainer, { marginTop: "0px", marginBottom: "24px" }]}>
                        <View style={pdstyles.tagItem}>
                          <Text style={pdstyles.tagText}>
                            Figma
                          </Text>
                        </View>
                        <View style={pdstyles.tagItem}>
                          <Text style={pdstyles.tagText}>
                            User Interface Designing
                          </Text>
                        </View>
                        <View style={pdstyles.tagItem}>
                          <Text style={pdstyles.tagText}>
                            UX
                          </Text>
                        </View>
                        <View style={pdstyles.tagItem}>
                          <Text style={pdstyles.tagText}>
                            Photoshop
                          </Text>
                        </View>

                      </View>
                    </Fragment>
                  }
                  <View style={[pdstyles.targetContainer, { flexDirection: "row" }]}>

                    {toggleData.work_experience &&
                      <View style={pdstyles.targetItem}>
                        <Text style={pdstyles.targetKey}>
                          Experience
                        </Text>
                        <Text style={pdstyles.targetValue}>
                          3 - 8 years
                        </Text>
                      </View>
                    }
                    {toggleData.annual_ctc &&
                      <View style={pdstyles.targetItem}>
                        <Text style={pdstyles.targetKey}>
                          Salary
                        </Text>
                        <Text style={pdstyles.targetValue}>
                          3.25 - 4.75 L.P.A
                        </Text>
                      </View>
                    }
                  </View>


                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.targetKey}>
                      Candidate Current Locations
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016
                    </Text>
                  </View>

                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Search Candidates In</Text>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.attValueText}>
                      Requirements:
                    </Text>
                    <Text style={[pdstyles.blockText, { marginBottom: "0px" }]}>
                      Lorem Ipsum is simply dummy text of the printing.
                    </Text>
                  </View>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.attValueText}>
                      Status:
                    </Text>
                    <Text style={[pdstyles.blockText, { marginBottom: "0px" }]}>
                      Lorem Ipsum is simply dummy text of the printing.
                    </Text>
                  </View>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.attValueText}>
                      Profile Sources:
                    </Text>
                    <Text style={[pdstyles.blockText, { marginBottom: "0px" }]}>
                      Lorem Ipsum is simply dummy text of the printing.
                    </Text>
                  </View>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.attValueText}>
                      Comments:
                    </Text>
                    <Text style={[pdstyles.blockText, { marginBottom: "0px" }]}>
                      Lorem Ipsum is simply dummy text of the printing.
                    </Text>
                  </View>

                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Employment Details</Text>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Functional Areas and Roles:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Creative Design  |  UI Designer
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>
                    {toggleData.industry &&
                      <Fragment>
                        <Text style={pdstyles.targetKey}>
                          Industry Type:
                        </Text>
                        <Text style={pdstyles.targetValue}>
                          Product Designer
                        </Text>
                      </Fragment>
                    }
                  </View>
                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Employers:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Lorem Ipusme Demo  |  Current Employer  |  All Words
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Exclude Employers:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Lorem Ipusme Demo  |  Current Employer  |  All Words
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>

                    <Text style={pdstyles.targetKey}>
                      Designation:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Lorem Ipusme Demo  |  Current Employer  |  All Words
                    </Text>

                  </View>


                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>

                    <Text style={pdstyles.targetKey}>
                      Notice Period:
                    </Text>
                    <Text style={[pdstyles.targetValue, { marginBottom: "0px" }]}>
                      1 Months
                    </Text>

                  </View>


                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Education Details</Text>
                  <View style={[pdstyles.targetContainer, { flexDirection: "row", flexWrap: "wrap", marginBottom: "0px" }]}>

                    {toggleData.graduation &&
                      <View style={pdstyles.targetItem}>
                        <Text style={pdstyles.targetKey}>
                          UG Qualification:
                        </Text>
                        <Text style={pdstyles.targetValue}>
                          Any UG Qualification
                        </Text>
                      </View>
                    }

                    {toggleData.post_graduation &&
                      <View style={pdstyles.targetItem}>
                        <Text style={pdstyles.targetKey}>
                          PG Qualification:
                        </Text>
                        <Text style={pdstyles.targetValue}>
                          Any PG Qualification
                        </Text>
                      </View>
                    }

                    {toggleData.doctorate &&
                      <View style={[pdstyles.targetItem, { marginTop: "24px" }]}>
                        <Text style={pdstyles.targetKey}>
                          Doctorate:
                        </Text>
                        <Text style={[pdstyles.targetValue, { marginBottom: "0px" }]}>
                          Any Doctorate Qualification
                        </Text>
                      </View>
                    }
                  </View>



                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Additional Filters</Text>
                  <View style={[pdstyles.targetContainer, { flexDirection: "row", flexWrap: "wrap", marginBottom: "0px" }]}>

                    <View style={pdstyles.targetItem}>
                      <Text style={pdstyles.targetKey}>
                        Resume Processed By:
                      </Text>
                      <Text style={pdstyles.targetValue}>
                        Anyone
                      </Text>
                    </View>

                    <View style={pdstyles.targetItem}>
                      <Text style={pdstyles.targetKey}>
                        Candidate Age:
                      </Text>
                      <Text style={pdstyles.targetValue}>
                        23 - 60 Years
                      </Text>
                    </View>

                    <View style={[pdstyles.targetItem, { marginTop: "24px" }]}>
                      <Text style={pdstyles.targetKey}>
                        Star Rating:
                      </Text>
                      <Text style={pdstyles.targetValue}>
                        From 2 To 5
                      </Text>
                    </View>

                    <View style={[pdstyles.targetItem, { marginTop: "24px" }]}>
                      <Text style={pdstyles.targetKey}>
                        Work Status For:
                      </Text>
                      <Text style={[pdstyles.targetValue, { marginBottom: "0px" }]}>
                        USA
                      </Text>
                    </View>

                  </View>


                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Affirmative Actions</Text>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>

                    <View style={pdstyles.targetItem}>
                      <Text style={pdstyles.targetKey}>
                        Category:
                      </Text>
                      <Text style={pdstyles.targetValue}>
                        SC
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <Image source={false ? checkedPNG.default : uncheckedPNG} style={pdstyles.checkIcon} />
                        <Text style={[pdstyles.targetValue]}>
                          Search women candidates only
                        </Text>
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        <Image source={false ? checkedPNG.default : uncheckedPNG} style={pdstyles.checkIcon} />
                        <Text style={[pdstyles.targetValue, { marginBottom: "0px" }]}>
                          Search candidates with special abilities only
                        </Text>
                      </View>
                    </View>
                  </View>


                  <View style={[pdstyles.separator]} />

                  <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Display Options</Text>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Show Candidates Seeking:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Any job
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Employment Type:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Any
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Show candidates only with:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Verified mobile no.  |  Verified e-mail id  |  Attached Resume  |  Search only Premium Resumes
                    </Text>
                  </View>

                  <View style={pdstyles.targetContainer}>
                    <Text style={pdstyles.targetKey}>
                      Sort By:
                    </Text>
                    <Text style={pdstyles.targetValue}>
                      Relevence
                    </Text>
                  </View>

                  <View style={[pdstyles.targetContainer, { marginBottom: "0px" }]}>
                    <Text style={pdstyles.targetKey}>
                      Resume per page:
                    </Text>
                    <Text style={[pdstyles.targetValue, { marginBottom: "0px" }]}>
                      40
                    </Text>
                  </View>
                </View>
              </Fragment>
            }
            {toggleData.conclusion &&
              <Fragment>
                <Text style={[pdstyles.blockTitle, { marginBottom: "8px" }]}>Conclusion</Text>
                <Text style={pdstyles.blockText}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Text>
              </Fragment>
            }
          </View>
        </Page>
      </Document >
    );
  }
}

export default PdfDemo

const CardAvatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(Avatar)

const pdstyles = StyleSheet.create({
  companyBlock: {
    height: "20.168vw",
    width: "100vw",
    opacity: 0.05,
    backgroundColor: "#1a181d",
    position: "absolute"
  },
  companyLogoContainer: {
    height: "20.168vw",
    width: "120px",
    padding: "24px",
    paddingRight: "16px"
  },
  companyLogo: {
    width: "72px",
    height: "72px",
    backgroundColor: "#ffffff",
    borderRadius: "50%"
  },
  companyInfoContainer: {
    height: "20.168vw",
    width: "300px",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  companyTitle: {
    height: "23px",
    width: "100%",
    textAlign: "left",
    marginTop: "30px",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    opacity: 0.87,
    color: "rgb(0, 0, 0)"
  },
  companyAddress: {
    width: "100%",
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    opacity: 0.6,
    color: "rgb(0, 0, 0)"
  },
  contentContainer: {
    padding: "13px 24px 16px 24px"
  },
  jobTitle: {
    height: "33px",
    width: "100%",
    marginBottom: "4px",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    opacity: 0.87,
    color: "rgb(0, 0, 0)"
  },
  jobAddress: {
    height: "16px",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    opacity: 0.57,
    color: "rgb(0, 0, 0)"
  },
  tagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "16px",
  },
  tagItem: {
    height: "32px",
    borderColor: "silver",
    borderRadius: "5px",
    marginRight: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    paddingHorizontal: "13px",
    justifyContent: "center",
    alignItem: "center",
    marginBottom: "8px"
  },
  tagText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    opacity: 0.87,
    color: "rgb(0, 0, 0)",
  },
  separator: {
    backgroundColor: "lightgray",
    height: "1px",
    width: "100%",
    marginVertical: "24px"
  },
  attContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  attItem: {
    width: "33.33333%",
    marginBottom: "24px"
  },
  attKeyText: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    opacity: 0.38,
    color: "rgb(0, 0, 0)"
  },
  attValueText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.25px",
    opacity: 0.87,
    color: "rgb(0, 0, 0)",
    marginTop: "7px"
  },
  blockTitle: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    opacity: 0.87,
    color: "rgb(0, 0, 0)",
    marginBottom: "16px"
  },
  blockText: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "0.11px",
    opacity: 0.87,
    color: "rgb(65, 65, 65)",
    marginBottom: "24px"
  },
  targetPoolBox: {
    border: "1px solid silver",
    borderRadius: "5px",
    padding: "24px",
    marginBottom: "30px",
  },
  targetKey: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    opacity: 0.38,
    color: "rgb(0, 0, 0)",
    marginBottom: "8px"
  },
  targetValue: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "0.21px",
    opacity: 0.87,
    color: "rgb(0, 0, 0)"
  },
  targetContainer: {
    marginBottom: "24px"
  },
  targetItem: {
    width: "50%"
  },
  checkIcon: {
    height: "12px",
    width: "12px",
    marginRight: "6px",
    marginTop: "-1px"
  }
});

const sampleDraftData = { "blocks": [{ "key": "5hqi9", "text": "its job detailsdfdf", "type": "unstyled", "depth": 0, "inlineStyleRanges": [{ "offset": 8, "length": 11, "style": "BOLD" }], "entityRanges": [], "data": {} }], "entityMap": {} };