import React, { Fragment } from "react";
// Customizable Area Start
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Link, Button, IconButton, Icon, Switch, Modal, Backdrop, Fade } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { KeyboardBackspace } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import { editIcon, showIcon, hideIcon } from "./assets";
// Customizable Area End

import ProfileSourceController, {
    Props,
    configJSON
} from "./ProfileSourceController";


class ProfileSourceModal extends ProfileSourceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            classes,
            profileSource,
            showModal,
            createUpdateLoading,
            closeModalHandler,
            changeTextHandler,
            showSwitchHandler,
            createAndUpdateHandler,
            validateProfileSourceName
        } = this.props

        const isEdit = profileSource.isEdit

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModal}
                onClose={closeModalHandler}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes: {
                        root: classes.backdrop
                    }
                }}
            >
                <Fade in={showModal}>
                    <Box className={classes.container} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={12} alignItems='center' justify='space-between' container={true} style={{ height: 60, backgroundColor: "#1a181d" }} >
                                <Box display="flex" alignItems='center' pl='26px' >
                                    <KeyboardBackspace style={{ height: 24, width: 24, marginRight: 18, color: '#ffffff' }} />
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#ffffff'
                                    }} >{`${isEdit ? 'Edit' : 'Create'} Profile Source`}</Typography>
                                </Box>
                                <IconButton disabled={createUpdateLoading} onClick={closeModalHandler} style={{ marginRight: 12, }} >
                                    <Icon>
                                        <CloseIcon style={{ height: 24, width: 24, opacity: 0.7, color: '#ffffff' }} />
                                    </Icon>
                                </IconButton>
                            </Grid>
                            <Grid sm={12} container={true} style={{ padding: '0px 24px 38px 24px', }} >
                                <Grid sm={12} container={true} style={{ marginTop: '24px', flexDirection: 'column' }} >
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }} >Profile Source</Typography>
                                    <Box className={classes.statusContainer} mt='16px' >
                                        <input required style={{
                                            padding: 0,
                                            border: 'none',
                                            outline: 'none',
                                            width: '100%',
                                            height: '100%',
                                            fontFamily: 'Poppins',
                                            fontSize: 16,
                                            fontWeight: 'normal',
                                            color: 'rgba(0, 0, 0, 0.6)'
                                        }}
                                            onChange={(e: any) => changeTextHandler(e.target.value)}
                                            type='text'
                                            value={profileSource?.name || ''}
                                            placeholder='Type Here'
                                        />
                                    </Box>
                                    {validateProfileSourceName() && (
                                        <Typography style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#e45d33'
                                        }} >Profile source with this name already exists</Typography>
                                    )}
                                </Grid>
                                <Grid sm={12} container={true} style={{ marginTop: '24px' }} >
                                    <Typography style={{
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }} >Show</Typography>
                                    <Switch
                                        checked={profileSource?.enabled || false}
                                        onChange={(e: any) => showSwitchHandler(e.target.checked)}
                                        size='small'
                                        style={{ color: profileSource?.enabled ? '#e45d33' : 'white' }}
                                        classes={{ track: profileSource?.enabled ? classes.toggleTrack : classes.inactiveToggleTrack, root: classes.toggleRoot }}
                                    />
                                </Grid>

                                <Grid sm={12} container={true} justify='space-between' style={{ marginTop: '46px' }} >
                                    <Button onClick={closeModalHandler} disabled={createUpdateLoading} style={{
                                        borderRadius: 4,
                                        backgroundColor: 'rgba(98, 2, 238, 0)',
                                        height: 56,
                                        width: '25%',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        fontFamily: 'Poppins',
                                        fontSize: 14,
                                        fontWeight: 500,
                                    }} variant="contained">Cancel</Button>
                                    {showModal && (
                                        <Button
                                            disabled={(!profileSource.hasOwnProperty('name') || (profileSource.hasOwnProperty('name') && profileSource.name.trim().length === 0) || validateProfileSourceName() || createUpdateLoading)}
                                            onClick={() => createAndUpdateHandler(profileSource)}
                                            style={{
                                                borderRadius: 4,
                                                backgroundColor: '#e45d33',
                                                height: 56,
                                                width: '25%',
                                                color: '#ffffff',
                                                fontFamily: 'Poppins',
                                                fontSize: 14,
                                                fontWeight: 500,
                                                opacity: (!profileSource.hasOwnProperty('name') || (profileSource.hasOwnProperty('name') && profileSource.name.trim().length === 0) || validateProfileSourceName() || createUpdateLoading) ? 0.5 : 1
                                            }}
                                            variant="contained">
                                            {`${isEdit ? 'Update' : 'Create'}`}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: '56px',
        paddingBottom: '50px',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    container: {
        overflow: 'auto',
        display: 'flex',
        width: 800,
        height: 'auto',
        maxHeight: '100%',
        borderRadius: 2,
        boxShadow: '0 7px 8px 4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
        backgroundColor: '#fafafa'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, 0.32)',
        paddingRight: 12,
        paddingLeft: 16,
    },
    toggleTrack: {
        backgroundColor: '#e45d33 !important'
    },
    inactiveToggleTrack: {
        backgroundColor: 'rgba(0, 0, 0, 0.38) !important'
    },
    toggleRoot: {
        marginLeft: 10
    }
});

export default withStyles(styles)(ProfileSourceModal)
// Customizable Area End