//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// @ts-ignore
//@ts-nocheck
import { View, Text, Image } from 'react-native';
import React, { Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { FormikProps, Formik } from 'formik';
import Icon from 'react-native-vector-icons/FontAwesome';
// import Loader from "../../../../components/src/Loader";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
// import Storage from "../../../../framework/src/StorageProvider.web";
import { FaCheck, FaPlus, FaThList } from "react-icons/fa";
import SearchStrategy from './SearchStrategy.web';
import classnames from 'classnames';

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import PostDetailFormFiled from './PostDetialFormFiled.web';

import JobManagementController, {
  Props,
} from "./JobManagementController";

const stepsValue = null;

const customStyles = {
  textHeading: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textActive: {
    color: "#fff",
    marginLeft: 55
  },
  lightstepText: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textError: {
    color: "#e45d33",
    marginLeft: 55
  }
}

class PostDetailsForm extends JobManagementController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCourseList();
    this.getPostGraduationList();
    this.getDoctorateList();
    this.getJobDetailsValues();
    this.getFunctionalAreas();
    this.getindustryList();
  }

  getSidebarValue = (steps: any, values: any, touched: any) => {
    this.stepsValue = Object.keys(steps)
    this.touched = touched
    this.values = values
    console.log(this.values, "Values", this.touched, "Touched")
  }

  getSideMenu = (currentHash: any, formikProps: FormikProps) => {
    const styles = getStyles();
    const assets = require("./assets");

    const { values, touched, errors } = formikProps;

    const getClassName = (field: string) => {
      let className = this.props.classes.textHeading;

      if (touched.hasOwnProperty(field) && touched[field]) {
        className = errors.hasOwnProperty(field) ? this.props.classes.textError : this.props.classes.textActive;
      }

      // if (values.hasOwnProperty(field) && values[field]) {
      //   className = this.props.classes.textActive
      // }

      if (touched.hasOwnProperty(field) && !touched[field]) {
        className = this.props.classes.textError
      }

      return className;

    }

    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
        </View>
        <View style={styles.stepsContainer}>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[values?.graduation_id !== "" && values?.post_graduation_id !== "" && values?.doctorate_id !== "" ? styles.stepIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepIconActive]} style={{ display: 'inline-flex' }}>
                {values?.graduation_id !== "" && values?.post_graduation_id !== "" && values?.doctorate_id !== "" ? <FaCheck size={10} /> : 1}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Education</Text>
          </View>

          <View style={[(currentHash === "#graduation_id" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#graduation_id")}>
            <Text style={[(currentHash === "#graduation_id" && currentHash === "")]} className={getClassName("graduation_id")}>Graduation</Text>
          </View>

          <View style={[(currentHash === "#post_graduation_id" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#post_graduation_id")}>
            <Text style={[(currentHash === "#post_graduation_id" && currentHash === "")]} className={getClassName("post_graduation_id")}>Post Graduation</Text>
          </View>

          <View style={[(currentHash === "#doctorate_id" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#doctorate_id")}>
            <Text style={[(currentHash === "#doctorate_id" && currentHash === "")]} className={getClassName("doctorate_id")}>Doctorate</Text>
          </View>

          {/* Job */}
          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[values?.industry_id !== "" && values?.functional_area_id !== "" ? styles.stepAdditionalIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepAdditionalIconActive]} style={{ display: 'inline-flex' }}>
                {values?.industry_id !== "" && values?.functional_area_id !== "" ? <FaCheck size={10} /> : 2}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Job</Text>
          </View>

          <View style={[(currentHash === "#industry_id" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#industry_id")}>
            <Text style={[(currentHash === "#industry_id" && currentHash === "")]} className={getClassName("industry_id")}>Industry</Text>
          </View>

          <View style={[(currentHash === "#functional_area_id" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#functional_area_id")}>
            <Text style={[(currentHash === "#functional_area_id" && currentHash === "")]} className={getClassName("functional_area_id")}>Functional Area</Text>
          </View>

          {/* Question */}
          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[(values?.question || values?.questions.length > 0) ? styles.stepAdditionalIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepAdditionalIconActive]} style={{ display: 'inline-flex' }}>
                {(values?.question || values?.questions.length > 0) ? <FaCheck size={10} /> : 3}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Question</Text>
          </View>

          <View style={[(currentHash === "#question" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#question")}>
            <Text style={[(currentHash === "#question" && currentHash === "")]} className={getClassName("question")}>Question</Text>
          </View>

          {/* Contact Detail */}
          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[values?.post_preferences_attributes[6].value === true ? styles.stepAdditionalIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepAdditionalIconActive]} style={{ display: 'inline-flex' }}>
                {values?.post_preferences_attributes[6].value === true ? <FaCheck size={10} /> : 4}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Contact Detail</Text>
          </View>

          <View style={[(currentHash === "#show_contact_details" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#show_contact_details")}>
            <Text style={[(currentHash === "#show_contact_details" && currentHash === "")]} className={getClassName("show_contact_details")}>Contact Details</Text>
          </View>
        </View>
      </View>
    )
  }

  getSidemenuFooter = () => {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      <View style={{ marginTop: "100%" }}>

        <Text style={styles.leftTitle}>Lorem Ipsum has been the industry's standard</Text>
        <Text style={[styles.text, { marginTop: 8, color: "rgba(255,255,255,0.3)" }]}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</Text>


        <View style={{ marginTop: 50 }}>
          <PaginationIndicator count={3} selected={1} style={{ justifyContent: "flex-start" }} />
        </View>
        {/*<Text style={[styles.text, { marginTop: 50, color: "#ffffff" }]}>© 2021 RMS. All right Reserved.</Text>*/}
      </View>
    )
  }

  onLayoutChange = (h: any) => {
    console.log('onLayoutChange >>>>> ', h)
  }

  getIndustryValue = (data) => {
    if (data && data.industry && data.industry.length > 0) {
      return data.industry
    }
    return ''
  }

  updateQuestion = (queRef) => {
    this.setState({ questions: queRef})
  }

  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const { jobDetailValue, isFormLoading, functionalAreaList, industryList } = this.state
    const currentHash = window.location.hash;

    const initialValues = {
      graduation_id: jobDetailValue?.graduation?.id || '',
      post_graduation_id: jobDetailValue?.post_graduation?.id || "",
      functional_area_id: jobDetailValue?.functional_area?.id || "",
      // industry_id: jobDetailValue?.industry || "",
      industry_id: jobDetailValue?.industry?.id || "",
      questions_attributes: this.state.questions,
      doctorate_id: jobDetailValue?.doctorate?.id || '',
      post_preferences_attributes: [
        { name: "graduation", value: false },
        { name: "post_graduation", value: false },
        { name: "functional_area", value: false },
        { name: "industry", value: false },
        { name: "any_questions_for_candidate", value: false },
        { name: "doctorate", value: false },
        { name: "show_contact_details_to_jobseeker", value: false },
      ],
      question: '',
      questions: this.state.questions || [],
    }

    if (this.state.isFormLoading) return 'Fetching ...'

    return (
      <View style={[styles.container]}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            let data = { ...values }
            if (values.question || values.questions.length !== 0) {
              data = {
                ...data,
                questions_attributes: values.questions.length === 0 ?
                  [{ question: values.question }] :
                  [...values.questions].map(item => ({ question: item }))
              }
              this.postDetailFormSubmit(
                data
                // industry_id: Array.isArray(data.industry_id) && data.industry_id.join(","),
                // functional_area_id: Array.isArray(data.functional_area_id) && data.functional_area_id.join(","),
              )
            }
            else {
              this.showAlert(
                "",
                "Questions are requried",
                "OK"
              );
            }
          }}
          validationSchema={this.getPostDetailFormValidations}
        >

          {(formikProps: FormikProps) => {

            const { values } = formikProps

            // const preSelectedIndustry = industriesList.filter(obj =>
            // (
            //   Array.isArray(values.industry_id) && values.industry_id.some((item) => (item.name === obj.value?.toLowerCase()))
            // )
            // )

            // const preSelectedFuntionalArea = functionalList.filter(obj =>
            //   Array.isArray(values.functional_area_id) && (values.functional_area_id.some((item) => (
            //     item.name === obj.value?.toLowerCase()
            //   )))
            // )

            const courses = this.state.courses.map((item) => ({ value: item.id, label: item.attributes.name }))

            const postGraduationList = this.state.postGraduationList.map((item) => ({
              value: item.id,
              label: item.attributes.name
            }))

            const doctrateList = this.state.doctorateList.map((item) => ({
              value: item.id,
              label: item.attributes.name
            }))

            const industryList = this.state.industryList.map((item) => ({
              value: item.id,
              label: item.attributes.name
            }))

            const functionalAreaList = this.state.functionalAreaList.map((item) => ({
              value: item.id,
              label: item.attributes.name
            }))

            return (
              <Fragment>
                <View style={[styles.leftContainer]}>
                  {this.getSideMenu(currentHash, formikProps)}
                  {this.getSidemenuFooter()}
                </View>
                <View style={[styles.rightContainer,]}>
                  <PostDetailFormFiled
                    {...this.props}
                    {...formikProps}
                    courses={courses}
                    postGraduationList={postGraduationList}
                    doctrateList={doctrateList}
                  // preSelectedIndustry={preSelectedIndustry}
                    industryList={industryList}
                    functionalAreaList={functionalAreaList}
                    questions={this.state.questions}
                    updateQuestion={this.updateQuestion}
                    // preSelectedFuntionalArea={preSelectedFuntionalArea}
                    backtoForm={this.props.backtoForm} />
                </View>
              </Fragment>
            )
          }}
        </Formik>

      </View>
    );
  }

}

export default withStyles(customStyles)(PostDetailsForm)


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 30,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "320px",
    // minHeight: "100%",
    padding: "27px",
    justifyContent: "space-between"
  },
  logoImage: {
    marginTop: 100,
    width: 45,
    height: 45
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "#fff",
    marginLeft: 30
  },
  stepAdditionalTextActive: {
    color: "rgb(126, 126, 126)",
    marginLeft: 30
  },
  stepIconContainer: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "5px"
  },
  stepIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepAdditionalIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepIcon: {
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    color: "rgb(20,29,61)"
  },
  stepAdditionalIconActive: {
    fontSize: "14px",
    color: "#fff"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    width: "calc(100% - 320px)",
    // minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  headingForm: {
    fontSize: "larger",
    marginBottom: 15,
    color: "white",
  },
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  // maxWidth(992, {
  //   leftContainer: {
  //     display: "none"
  //   },
  //   rightContainer: {
  //     width: "100%"
  //   }
  // }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);