import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  showFilter: boolean;
  anchorEl: any;
  requirements: Array<any>;
  loading: boolean;

  jobTitles: Array<any>;
  jobLocations: Array<any>;
  jobSkills: Array<any>;
  selectedJobTitles: Array<any>;
  selectedJobLocations: Array<any>;
  selectedJobSkills: Array<any>;
  jobTitleSearch: string;
  jobLocationSearch: string;
  jobSkillSearch: string;
  showTitleTags: boolean,
  showLocationTags: boolean,
  showSkillTags: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class JobListingController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiCallMessageGetJobRequirementsRequestId: any;
  apiCallMessageGetJobTitlesTagsRequestId: any;
  apiCallMessageGetJobLocationsTagsRequestId: any;
  apiCallMessageGetJobSkillsTagsRequestId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showFilter: false,
      anchorEl: null,
      requirements: [],
      loading: false,


      jobTitles: [],
      jobLocations: [],
      jobSkills: [],
      selectedJobTitles: [],
      selectedJobLocations: [],
      selectedJobSkills: [],
      jobTitleSearch: "",
      jobLocationSearch: "",
      jobSkillSearch: "",
      showTitleTags: false,
      showLocationTags: false,
      showSkillTags: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (this.apiCallMessageGetJobTitlesTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobTitlesTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobTitles: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobLocationsTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobLocationsTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobLocations: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }

    else if (this.apiCallMessageGetJobSkillsTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobSkillsTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobSkills: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobRequirementsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobRequirementsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson.data)) {
          this.setState({ requirements: responseJson.data, loading: false });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  onCloseFilter(e: any) {
    this.setState({ showFilter: false, anchorEl: null });
  }
  onOpenFilter(e: any) {
    this.setState({ showFilter: true, anchorEl: e.target });
  }


  onCloseMenu(e: any, index: number, name: string) {
    this.state.requirements[index][name] = false;
    this.setState({ anchorEl: null });
  }
  onOpenMenu(e: any, index: number, name: string) {
    this.state.requirements[index][name] = true;
    this.setState({ anchorEl: e.target });
  }

  onExpandAll() {
    this.state.requirements.forEach((item: any) => {
      item.showMore = true;
    });
    this.forceUpdate();
  }


  onCollapseAll() {
    this.state.requirements.forEach((item: any) => {
      item.showMore = false;
    });
    this.forceUpdate();
  }

  onToggleMore(index: number) {
    this.state.requirements[index].showMore = !this.state.requirements[index].showMore;
    this.forceUpdate();
  }


  async getJobRequirements() {
    const authToken = await Storage.get("authToken");


    var apiEndPointParams = "";

    if (this.state.jobTitleSearch) {
      // apiEndPointParams = "?job_titles=" + this.state.jobTitleSearch;
      apiEndPointParams = "?keyword=" + this.state.jobTitleSearch;


      //job_titles[]=
      //locations[]=
      //skills[]=
    }



    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobRequirementsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/job_details` + apiEndPointParams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getJobTitlesTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobTitlesTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_job_titles"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getJobLocationsTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobLocationsTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_locations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getJobSkillsTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobSkillsTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_skills"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
