//@ts-nocheck
import React, { Fragment, useEffect } from 'react';
import {
  Dimensions,
  PixelRatio,
  View,
  Text,
  FlatList,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  CheckBox,
  Platform,
  Image,
  TextInput,
  Picker,
  ActivityIndicator,
  Alert,
  ImageBackground
} from 'react-native';
//@ts-ignore

import { createStyles, maxWidth } from 'react-native-media-queries';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';

import TextField from '@material-ui/core/TextField';
import classnames from "classnames";
import Select from 'react-select'
import { FaPlus, FaTimes } from "react-icons/fa";
import { ImPencil } from "react-icons/im";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MUIRichTextEditor from "mui-rte";
import { Editor, EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import csc from 'country-state-city';
import { formBottomImage } from './assets';
import Grid from '@material-ui/core/Grid';
import "./index.css";

import _ from "lodash";

// Customizable Area End

import JobManagementController, {
  Props,
  // configJSON
} from "./JobManagementController";

export default class JobDetailForm extends JobManagementController {
  hiringForRef: any

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // async componentDidMount() {
  //   super.componentDidMount();
  //   this.getCountryList();
  //   this.getKeywordsList();
  //   // this.getJobDetailsValues();
  //   this.getCompanies()

  //   if (localStorage.getItem('id')) {
  //     this.getJobDetailsValues();
  //   }
  // }

  // componentDidUpdate = (prevProps) => {
  //   if (this.props.isEdit !== prevProps.isEdit) {
  //     this.getJobDetailsValues();
  //   }
  // }

  getReferenceCode = (data) => {
    if (data && data.reference_codes && data.reference_codes.length > 0) {
      const obj = data.reference_codes[0]
      if (obj && obj.name) return obj.name
    }
    return ''
  }

  getIndustryValue = (data) => {
    if (data && data.industry && data.industry.length > 0) {
      const obj = data.industry[0]
      if (obj && obj.name) return obj.name
    }
    return ''
  }

  addMoreLocation = () => {
    const { values, errors, setFieldValue, setFieldError, setFieldTouched } = this.props

    if (!values.job_location_details['country'] && !values.job_location_details['state'] && !values.job_location_details['city']) {
      setFieldTouched('job_location_details.country', true, false)
      setFieldError('job_location_details.country', 'Field is required')
      setFieldTouched('job_location_details.state', true, false)
      setFieldError('job_location_details.state', 'Field is required')
      setFieldTouched('job_location_details.city', true, false)
      setFieldError('job_location_details.city', 'Field is required')
      return
    }

    if (!values.job_location_details['country']) {
      setFieldTouched('job_location_details.country', true, false)
      return setFieldError('job_location_details.country', 'Field is required')
    }

    if (!values.job_location_details['state']) {
      setFieldTouched('job_location_details.state', true, false)
      return setFieldError('job_location_details.state', 'Field is required')
    }

    if (!values.job_location_details['city']) {
      setFieldTouched('job_location_details.city', true, false)
      return setFieldError('job_location_details.city', 'Field is required')
    }


    if (values.job_location_details['country'] != "" && values.job_location_details['state'] != "" && values.job_location_details['city'] != "") {
      console.log("Errors ", errors.job_location_details)
      const locationRef = [...this.state.location, values.job_location_details]
      this.setState({ location: locationRef }, () => {
        // setFieldValue('job_location_details.country', "")
      })
    }
    else {
      this.showAlert(
        "",
        "Job location should not be empty",
        "OK"
      );
    }
  }

  deleteHiringFor = (index: number) => {
    console.log(index)
    const hiringFor = [...this.state.hiring_for];
    hiringFor.splice(index, 1)
    this.setState({ hiring_for: hiringFor })
  }

  editHiringFor = (index: number) => {
    console.log(index)
    const { setFieldValue } = this.props
    const hiringFor = [...this.state.hiring_for]
    const hiringForObj = hiringFor[index]
    setFieldValue('employer_name', hiringForObj.employer_name)
    setFieldValue('email', hiringForObj.email)
    setFieldValue('contact_no', hiringForObj.contact)
    setFieldValue('company_name', hiringForObj.company_name)
    hiringFor.splice(index, 1)
    this.setState({ hiring_for: hiringFor })
  }

  render() {
    const styles = getStyles();
    const { isFormLoading, location, hiring_for } = this.state
    const {
      countries,
      companies,
      keywordsList,
      recruiterList,
      companiesRef,
      values,
      handleChange,
      errors,
      setFieldTouched,
      setFieldValue,
      touched,
      handleSubmit,
      resetForm,
      preSelectedKeywords,
      preSelectedRecruiter,
    } = this.props

    return (
      <View style={[styles.container]}>
        <View onLayout={(event) => {
          var { x, y, width, height } = event.nativeEvent.layout;
          if (this.props.onChangeLayout) {
            this.props.onChangeLayout(height)
          };
        }} >

          <View>
            <div key={"form-"}>
              <Fragment>
                <View style={[styles.topContainer]}>
                  <View style={styles.topContainerRadioButton}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                      <FormControlLabel value="end" control={<Radio color="primary" />} label="Job Details" />
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <RadioButtonUncheckedIcon style={{ marginRight: 12 }} />
                        <RadioButtonUncheckedIcon style={{ marginRight: 12 }} />
                        <CloseIcon />
                        <p style={{ margin: 0, marginLeft: 25, marginRight: 15, color: "rgb(126, 126, 126)" }}>---------</p>
                      </View>
                      <FormControlLabel value="end" control={<Radio color="primary" />} label="Posting Details" />
                      <p style={{ margin: 0, marginLeft: 25, marginRight: 15, marginTop: 10, color: "rgb(126, 126, 126)" }}>---------</p>
                      <FormControlLabel value="end" control={<Radio color="primary" />} label="Search Strategy" />
                    </RadioGroup>
                  </View>

                  <View style={[styles.topContainerButton]}>
                    <TouchableOpacity
                      onPress={() => { window.location = '/JobRequirements' }}
                      style={[styles.button, { marginRight: "16px", boxShadow: "none", borderWidth: "0px" }]}
                    >
                      <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => handleSubmit()}
                      style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", paddingHorizontal: 16, marginRight: "24px", }]}
                    >
                      <Text style={[styles.buttonText, { color: "white" }]}>
                        Next Step
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <Grid container spacing={3} style={{ marginBottom: "0" }}>
                  <Grid item sm={7} style={{ paddingBottom: "0" }}>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text style={[styles.rightTitle, { marginTop: 70 }]}>Job Details</Text>
                      <Text style={styles.rightSubTitle}>Lorem ipsumdemo content here.</Text>
                    </div>

                    <div id={"requirement_title"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Requirement Title</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${0}.value`}
                                checked={values[`post_preferences_attributes.${0}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <TextField
                            value={values.requirement_title}
                            name="requirement_title"
                            onChange={handleChange}
                            variant={"outlined"}
                            // disabled={this.state.otpVerified}
                            label="Type Here"
                            onFocus={() => setFieldTouched('requirement_title', false, false)}
                            onBlur={() => setFieldTouched('requirement_title')}
                            error={!!(errors.requirement_title && touched.requirement_title)}
                            helperText={touched.requirement_title && errors.requirement_title}
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"job_title"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Job Title</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${1}.value`}
                                checked={values[`post_preferences_attributes.${1}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <TextField
                            value={values.job_title}
                            onChange={handleChange}
                            variant={"outlined"}
                            // disabled={this.state.otpVerified}
                            label="Type Here"
                            name="job_title"
                            onFocus={() => setFieldTouched('job_title', false, false)}
                            onBlur={() => setFieldTouched('job_title')}
                            error={!!(errors.job_title && touched.job_title)}
                            helperText={touched.job_title && errors.job_title}
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"reporting_to"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Reporting To</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${2}.value`}
                                checked={values[`post_preferences_attributes.${2}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <TextField
                            value={values.reporting_to}
                            variant={"outlined"}
                            onChange={handleChange}
                            name="reporting_to"
                            onFocus={() => setFieldTouched('reporting_to', false, false)}
                            onBlur={() => setFieldTouched('reporting_to')}
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"team"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Reportees/Team</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${3}.value`}
                                checked={values[`post_preferences_attributes.${3}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <TextField
                            value={values.team}
                            onChange={handleChange}
                            variant={"outlined"}
                            name="team"
                            label="Type Here"
                            style={styles.plainTextInput}
                            onFocus={() => setFieldTouched('team', false, false)}
                            onBlur={() => setFieldTouched('team')}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"employment_type"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Employment Type</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${4}.value`}
                                checked={values[`post_preferences_attributes.${4}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="Employment Type"
                            options={[
                              { value: 'full_time', label: 'Full Time' },
                              { value: 'part_time', label: 'Part Time' },
                              { value: 'intern', label: 'Intern' }
                            ]}

                            defaultInputValue={values.employment_type}
                            name="employment_type"
                            onChange={(option: any) => setFieldValue('employment_type', option.value)}
                            onFocus={() => setFieldTouched('employment_type', false, false)}
                            onBlur={() => setFieldTouched('employment_type')}
                            className={classnames("", { "select-error-input": touched.employment_type && errors.employment_type })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.employment_type && errors.employment_type &&
                              <Text style={styles.textInputErrorMessage}>{errors.employment_type}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"job_details"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Job Details</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${5}.value`}
                                checked={values[`post_preferences_attributes.${5}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Mention all job Roles & responsibilities here</Text>
                      </View>
                      <MUIRichTextEditor
                        label="Type something here..."
                        // onSave={save}
                        defaultValue={values.job_details}
                        onChange={(data) => {
                          var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                          if (values.job_details !== json) {
                            this.state.editorData["job_details"] = json;
                          }
                        }}
                        onFocus={() => setFieldTouched('job_details', false, false)}
                        onBlur={() => { setFieldValue("job_details", this.state.editorData["job_details"]); setFieldTouched('job_details'); }}
                        inlineToolbar={true}
                        controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                      />
                      {/*
                              <MUIRichTextEditor
                                label="Type something here..."
                                // onSave={save}
                                defaultValue={values.job_details}
                                onChange={(data) => {
                                  var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                                  if (values.job_details !== json) {
                                    this.state.editorData["job_details"] = json;
                                  }
                                  console.log("bulu ", json);
                                }}
                                onBlur={() => setFieldValue("job_details", this.state.editorData["job_details"])}
                                inlineToolbar={true}
                                controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                              />
                              {/*
                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField
                                    value={values.job_details}
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched('job_details')}
                                    variant={"outlined"}
                                    error={!!(errors.job_details && touched.job_details)}
                                    helperText={touched.job_details && errors.job_details}
                                    // disabled={this.state.otpVerified}
                                    label="Type Here"
                                    name="job_details"
                                    // error={this.state.showActivationError}
                                    // helperText={this.state.showActivationError && "Email verification is required."} 
                                    style={styles.plainTextInput}
                                    multiline
                                    rows={4}
                                  />
                                </View>
                              </View>
                              */}
                    </div>

                    <div id={"key_result_areas"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Key Result Areas</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${6}.value`}
                                checked={values[`post_preferences_attributes.${6}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>
                      <MUIRichTextEditor
                        label="Type something here..."
                        // onSave={save}
                        defaultValue={values.key_result_areas}
                        onChange={(data) => {
                          var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                          if (values.key_result_areas !== json) {
                            this.state.editorData["key_result_areas"] = json;
                          }
                        }}
                        onFocus={() => setFieldTouched('key_result_areas', false, false)}
                        onBlur={() => { setFieldValue("key_result_areas", this.state.editorData["key_result_areas"]); setFieldTouched('key_result_areas'); }}
                        inlineToolbar={true}
                        controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                      />
                      {/*
                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField
                                    // onChange={(e) => this.setState({ otpCode: e.target.value })}
                                    value={values.key_result_areas}
                                    name="key_result_areas"
                                    variant={"outlined"}
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched('key_result_areas')}
                                    error={!!(errors.key_result_areas && touched.key_result_areas)}
                                    helperText={touched.key_result_areas && errors.key_result_areas}
                                    // disabled={this.state.otpVerified}
                                    label="Type Here"
                                    // error={this.state.showActivationError}
                                    // helperText={this.state.showActivationError && "Email verification is required."} 
                                    style={styles.plainTextInput}
                                    multiline
                                    rows={4}
                                  />
                                </View>
                              </View>
                              */}
                    </div>

                    <div id={"key_performance_indicators"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Key Performance Indicators</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${7}.value`}
                                checked={values[`post_preferences_attributes.${7}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>
                      <MUIRichTextEditor
                        label="Type something here..."
                        // onSave={save}
                        defaultValue={values.key_performance_indicators}
                        onChange={(data) => {
                          var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                          if (values.key_performance_indicators !== json) {
                            this.state.editorData["key_performance_indicators"] = json;
                          }
                        }}
                        onFocus={() => setFieldTouched('key_performance_indicators', false, false)}
                        onBlur={() => { setFieldValue("key_performance_indicators", this.state.editorData["key_performance_indicators"]); setFieldTouched('key_performance_indicators'); }}
                        inlineToolbar={true}
                        controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                      />
                      {/*
                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField
                                    // onChange={(e) => this.setState({ otpCode: e.target.value })}
                                    value={values.key_performance_indicators}
                                    name="key_performance_indicators"
                                    variant={"outlined"}
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched('key_performance_indicators')}
                                    error={!!(errors.key_performance_indicators && touched.key_performance_indicators)}
                                    helperText={touched.key_performance_indicators && errors.key_performance_indicators}
                                    // disabled={this.state.otpVerified}
                                    label="Type Here"
                                    style={styles.plainTextInput}
                                    multiline
                                    rows={4}
                                  />
                                </View>
                              </View>
                              */}
                    </div>

                    <div id={"desired_candidate_profile"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Desired Candidate profile</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${8}.value`}
                                checked={values[`post_preferences_attributes.${8}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>
                      <MUIRichTextEditor
                        label="Type something here..."
                        // onSave={save}
                        defaultValue={values.desired_candidate_profile}
                        onChange={(data) => {
                          var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                          if (values.desired_candidate_profile !== json) {
                            this.state.editorData["desired_candidate_profile"] = json;
                          }
                        }}
                        onFocus={() => setFieldTouched('desired_candidate_profile', false, false)}
                        onBlur={() => { setFieldValue("desired_candidate_profile", this.state.editorData["desired_candidate_profile"]); setFieldTouched('desired_candidate_profile'); }}
                        inlineToolbar={true}
                        controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                      />
                      {/*
                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField
                                    value={values.desired_candidate_profile}
                                    name="desired_candidate_profile"
                                    variant={"outlined"}
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched('desired_candidate_profile')}
                                    error={!!(errors.desired_candidate_profile && touched.desired_candidate_profile)}
                                    helperText={touched.desired_candidate_profile && errors.desired_candidate_profile}
                                    // disabled={this.state.otpVerified}
                                    label="Type Here"
                                    style={styles.plainTextInput}
                                    multiline
                                    rows={4}
                                  />
                                </View>
                              </View>
                              */}
                    </div>

                    <div id={"currency"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Annual CTC</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${9}.value`}
                                checked={values[`post_preferences_attributes.${9}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>

                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "fit-content" }]} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="₹"
                            options={[
                              { value: "inr", label: "₹" },
                              { value: "usd", label: "$" },
                              { value: "eur", label: "€" },
                              { value: "jpy", label: "¥" },
                            ]}
                            defaultValue={{ value: "inr", label: "₹" }}
                            name="currency"
                            onChange={(option: any) => setFieldValue('currency', option.value)}
                            onFocus={() => setFieldTouched('currency', false, false)}
                            onBlur={() => { setFieldTouched('currency') }}
                            className={classnames("", { "select-error-input": touched.currency && errors.currency })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.currency && errors.currency &&
                              <Text style={styles.textInputErrorMessage}>{errors.currency}</Text>
                            }
                          </View>
                        </View>
                        <View style={[styles.inputWrapper, { marginLeft: 8, width: "42%" }]}  >
                          <TextField
                            value={values.min_salary}
                            type="number"
                            name="min_salary"
                            variant={"outlined"}
                            onChange={handleChange}
                            onFocus={() => setFieldTouched('currency', false, false)}
                            onBlur={() => { setFieldTouched('currency', true, false); setFieldTouched('min_salary') }}
                            error={!!(errors.min_salary && touched.min_salary)}
                            helperText={touched.min_salary && errors.min_salary}
                            // disabled={this.state.otpVerified}
                            label="Minimum Salary"
                            style={styles.plainTextInput}
                          />
                        </View>
                        <View style={[styles.inputWrapper, { marginLeft: 8, width: "42%" }]}  >
                          <TextField
                            value={values.max_salary}
                            name="max_salary"
                            type="number"
                            onChange={handleChange}
                            variant={"outlined"}
                            onFocus={() => setFieldTouched('currency', false, false)}
                            onBlur={() => { setFieldTouched('currency', true, false); setFieldTouched('max_salary') }}
                            error={!!(errors.max_salary && touched.max_salary)}
                            helperText={touched.max_salary && errors.max_salary}
                            // disabled={this.state.otpVerified}
                            label="Maximum Salary"
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>

                      <View style={{ flexDirection: "row", marginLeft: -30 }}>
                        <View style={styles.switchButton}>
                          <AntSwitch
                            name={`post_preferences_attributes.${20}.value`}
                            checked={values[`post_preferences_attributes.${20}.value`]}
                            onChange={handleChange}
                          />
                        </View>
                        <Text style={styles.subText}>Show this to jobseekers</Text>
                      </View>
                    </div>

                    <div id={"other_salary_details"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Other Salary Details</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${10}.value`}
                                checked={values[`post_preferences_attributes.${10}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here</Text>
                      </View>
                      <MUIRichTextEditor
                        label="Type something here..."
                        // onSave={save}
                        defaultValue={values.other_salary_details}
                        onChange={(data) => {
                          var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                          if (values.other_salary_details !== json) {
                            this.state.editorData["other_salary_details"] = json;
                          }
                        }}
                        onFocus={() => setFieldTouched('other_salary_details', false, false)}
                        onBlur={() => { setFieldValue("other_salary_details", this.state.editorData["other_salary_details"]); setFieldTouched('other_salary_details'); }}
                        inlineToolbar={true}
                        controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                      />
                      {/*
                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField
                                    value={values.other_salary_details}
                                    name="other_salary_details"
                                    variant={"outlined"}
                                    onChange={handleChange}
                                    label="Type Here"
                                    style={styles.plainTextInput}
                                    multiline
                                    rows={4}
                                  />
                                </View>
                              </View>
                              */}
                    </div>

                    <div id={"work_experience_min"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Work Experience</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${11}.value`}
                                checked={values[`post_preferences_attributes.${11}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Minimum Years of Experience Required</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={styles.inputWrapper}>
                          <TextField
                            value={values.work_experience_min}
                            name="work_experience_min"
                            type="number"
                            variant={"outlined"}
                            onChange={handleChange}
                            onFocus={() => setFieldTouched('work_experience_min', false, false)}
                            onBlur={() => { setFieldTouched('work_experience_min') }}
                            error={!!(errors.work_experience_min && touched.work_experience_min)}
                            helperText={touched.work_experience_min && errors.work_experience_min}
                            // disabled={this.state.otpVerified}
                            label="Minimum Experience"
                            style={styles.plainTextInput}
                          />
                        </View>
                        <View style={[styles.inputWrapper, { marginLeft: 10 }]}  >
                          <TextField
                            value={values.work_experience_max}
                            name="work_experience_max"
                            type="number"
                            variant={"outlined"}
                            onChange={handleChange}
                            // disabled={this.state.otpVerified}
                            label="Maximum Experience"
                            onFocus={() => setFieldTouched('work_experience_min', false, false)}
                            onBlur={() => { setFieldTouched('work_experience_min', true, false); setFieldTouched('work_experience_max') }}
                            error={!!(errors.work_experience_max && touched.work_experience_max)}
                            helperText={touched.work_experience_max && errors.work_experience_max}
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"keyword_skill_ids"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Keywords</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${12}.value`}
                                checked={values[`post_preferences_attributes.${12}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Choose upto 5 skills</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]}  >
                          <Select
                            styles={selectMultipleStyle}
                            isSearchable={true}
                            isMulti
                            placeholder="Use Complete words. Eg: Type Senior Manager, not Sr. Mgr"
                            options={keywordsList}
                            name="keyword_skill_ids"
                            // defaultInputValue={keywordList.filter(obj => values.keyword_list.includes(obj.value))}
                            defaultValue={preSelectedKeywords}
                            onFocus={() => setFieldTouched('keyword_skill_ids', false, false)}
                            onBlur={() => { setFieldTouched('keyword_skill_ids') }}
                            onChange={(e: any) =>
                              setFieldValue('keyword_skill_ids', Array.isArray(e) ? e.map(x => x.value) : [])}
                            className={classnames("keyword_skill_ids", { "select-error-input": touched.keyword_list && errors.keyword_list })}
                            classNamePrefix={"keyword_skill_ids"}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.keyword_skill_ids && errors.keyword_skill_ids &&
                              <Text style={styles.textInputErrorMessage}>{errors.keyword_skill_ids}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"job_location_details"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Job located</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${13}.value`}
                                checked={values[`post_preferences_attributes.${13}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                          <View>
                            <TouchableOpacity
                              style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}
                              onPress={() => this.addMoreLocation()}
                            >
                              <Text style={[styles.buttonText, { color: "#e45d33" }]}>
                                <FaPlus /> ADD MORE
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Enter City, State, Country</Text>
                      </View>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder={"Country"}
                            onChange={(option: any) => {
                              const list = csc.getStatesOfCountry(option.value)
                              this.setState({
                                stateList: list.map((item) => ({
                                  value: item.isoCode,
                                  label: item.name
                                }))
                              }, () => {
                                setFieldValue('job_location_details.country', option.label)
                              })
                              this.setState({ countryCode: option.value })
                            }}
                            // onChange={handleChange}
                            className={classnames("", { "select-error-input": touched.job_location_details?.country && errors.job_location_details?.country })}
                            defaultInputValue={values.job_location_details?.country}
                            options={countries}
                            name={"job_location_details.country"}
                            onFocus={() => setFieldTouched('job_location_details.country', false, false)}
                            onBlur={() => { setFieldTouched('job_location_details.country') }}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.job_location_details?.country && errors.job_location_details?.country &&
                              <Text style={styles.textInputErrorMessage}>{errors.job_location_details?.country}</Text>
                            }
                          </View>
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="State"
                            options={this.state.stateList}
                            name={"job_location_details.state"}
                            onChange={(option: any) => {
                              const list = csc.getCitiesOfState(this.state.countryCode, option.value)
                              this.setState({
                                cityList: list.map((item) => ({
                                  value: item.name,
                                  label: item.name
                                }))
                              }, () => {
                                setFieldValue('job_location_details.state', option.label)
                              })
                            }}
                            defaultInputValue={values.job_location_details?.state}
                            onFocus={() => setFieldTouched('job_location_details.state', false, false)}
                            onBlur={() => { setFieldTouched('job_location_details.state') }}
                            className={classnames("", { "select-error-input": touched.job_location_details?.state && errors.job_location_details?.state })}
                          // className={classnames("", { "select-error-input": touched.currency && errors.employment_type })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.job_location_details?.state && errors.job_location_details?.state &&
                              <Text style={styles.textInputErrorMessage}>{errors.job_location_details?.state}</Text>
                            }
                          </View>
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="City"
                            options={this.state.cityList}
                            name={"job_location_details.city"}
                            onChange={(option: any) => {
                              setFieldValue('job_location_details.city', option.value)
                            }}
                            defaultInputValue={values.job_location_details?.city}
                            onFocus={() => setFieldTouched('job_location_details.city', false, false)}
                            onBlur={() => { setFieldTouched('job_location_details.city') }}
                            className={classnames("", { "select-error-input": touched.job_location_details?.city && errors.job_location_details?.city })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.job_location_details?.city && errors.job_location_details?.city &&
                              <Text style={styles.textInputErrorMessage}>{errors.job_location_details?.city}</Text>
                            }
                          </View>
                        </Grid>
                      </Grid>
                      {this.state.location && this.state.location.map((item) => {
                        return (
                          <div style={{ display: "flex" }}>
                            <p style={styles.locationText}>{item.country}</p>
                            <p style={styles.locationText}>{item.state}</p>
                            <p style={styles.locationText}>{item.city}</p>
                          </div>
                        )
                      })}
                    </div>

                    <View style={{ marginTop: 30 }}>
                      <Text style={styles.rightTitle}>Additional Details</Text>
                      <Text style={styles.rightSubTitle}>Lorem ipsum demo content here.</Text>
                    </View>

                    <div id={"hiring_for"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Hiring For</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${14}.value`}
                                checked={values[`post_preferences_attributes.${14}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Mention this who you want to show this.</Text>
                      </View>
                      <Grid container>
                        {hiring_for &&
                          hiring_for.map((item, index) => {
                            return (
                              <Grid item xs={6}>
                                <div style={styles.hiringContainer}>
                                  <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                                    <p style={{ fontSize: 16, margin: "0px", color: "rgba(0, 0, 0, 0.87)" }}>{item.name}</p>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", color: "#1a181d", opacity: 0.6 }}>
                                      <ImPencil onClick={() => this.editHiringFor(index)} />
                                      <DeleteOutlineIcon onClick={() => this.deleteHiringFor(index)} style={{ marginLeft: 25 }} />
                                    </div>
                                  </div>
                                  <p style={{ fontSize: 12, margin: 0, color: "#000000" }}>{item.employer_name}</p>
                                  <div style={{ display: "flex", alignItems: "center", color: "rgba(0, 0, 0, 0.6)" }}>
                                    <p style={{ margin: 0, fontSize: 12 }}>{item.email}</p>
                                    <p style={{ margin: 0, paddingLeft: 10, paddingRight: 10 }}>|</p>
                                    <p style={{ margin: 0, fontSize: 12 }}>{item.contact}</p>
                                  </div>
                                </div>
                              </Grid>
                            )
                          })
                        }
                      </Grid>

                      <View style={{ flexDirection: "row", marginLeft: -30 }}>
                        <View style={styles.switchButton}>
                          <AntSwitch
                            name={`post_preferences_attributes.${15}.value`}
                            checked={values[`post_preferences_attributes.${15}.value`]}
                            onChange={handleChange}
                          />
                        </View>
                        <Text style={styles.subText}>Show this to jobseekers</Text>
                      </View>
                    </div>

                    <View>
                      <Text style={[styles.belowText, { marginTop: 40 }]}>Add an Employer</Text>
                    </View>

                    <View style={styles.formRow}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <Select
                          ref={(ref) => this.hiringForRef = ref}
                          styles={selectStyles}
                          placeholder="Company Name"
                          options={companiesRef}
                          defaultInputValue={values.company_name}
                          name="company_name"
                          onChange={(e: any) => {
                            if (!e?.value) return
                            const company = companies.find((item) => +item.id === +e.value)
                            console.log(company)
                            this.setState({ empName: company?.attributes?.employer_name, emailId: company?.attributes?.email, contactNo: company?.attributes?.contact_number, company: e?.label })
                            setFieldValue('company_name', e?.value)
                            setFieldValue('employer_name', company?.attributes?.employer_name)
                            setFieldValue('email', company?.attributes?.email)
                            setFieldValue('contact_no', company?.attributes?.contact_number)

                          }}
                          onFocus={() => setFieldTouched('company_name', false, false)}
                          onBlur={() => { setFieldTouched('company_name') }}
                          className={classnames("", { "select-error-input": touched.company_name && errors.company_name })}
                        />
                        <View style={styles.textInputErrorMessageContainer}>
                          {touched.company_name && errors.company_name &&
                            <Text style={styles.textInputErrorMessage}>{errors.company_name}</Text>
                          }
                        </View>
                      </View>
                    </View>
                    <View style={styles.formRow}>
                      <View style={[styles.inputWrapper, { width: "100%" }]} >
                        <TextField
                          value={values.employer_name}
                          onChange={handleChange}
                          variant={"outlined"}
                          name={"employer_name"}
                          onFocus={() => setFieldTouched('company_name', false, false)}
                          onBlur={() => { setFieldTouched('company_name', true, false); setFieldTouched('employer_name') }}
                          error={!!(errors.employer_name && touched.employer_name)}
                          helperText={touched.employer_name && errors.employer_name}
                          label="Employer Name"
                          style={styles.plainTextInput}
                          disabled={values.employer_name}
                        />
                      </View>
                    </View>
                    <View style={styles.formRow}>
                      <View style={styles.inputWrapper}>
                        <TextField
                          value={values.email}
                          name="email"
                          variant={"outlined"}
                          onChange={handleChange}
                          onFocus={() => setFieldTouched('company_name', false, false)}
                          onBlur={() => { setFieldTouched('company_name', true, false); setFieldTouched('email') }}
                          error={!!(errors.email && touched.email)}
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          style={styles.plainTextInput}
                          disabled={values.employer_name}
                        />
                      </View>
                      <View style={[styles.inputWrapper, { marginLeft: 10 }]}>
                        <TextField
                          value={values.contact_no}
                          name="contact_no"
                          type="number"
                          variant={"outlined"}
                          onChange={handleChange}
                          label="Contact no"
                          onFocus={() => setFieldTouched('company_name', false, false)}
                          onBlur={() => { setFieldTouched('company_name', true, false); setFieldTouched('contact_no') }}
                          error={!!(errors.contact_no && touched.contact_no)}
                          helperText={touched.contact_no && errors.contact_no}
                          style={styles.plainTextInput}
                          disabled={values.employer_name}
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onPress={() => {
                          this.addHiringDetails(values)
                          setFieldValue('employer_name', "")
                          setFieldValue('email', "")
                          setFieldValue('contact_no', "")
                          setFieldValue('company_name', "")
                          this.hiringForRef?.select?.clearValue();
                        }}
                        style={[styles.button, { marginRight: "2%", backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40 }]}
                      >
                        <Text style={[styles.buttonText, { color: "white" }]}>
                          Add
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setFieldValue('employer_name', "")
                          setFieldValue('email', "")
                          setFieldValue('contact_no', "")
                          setFieldValue('company_name', "")
                          this.hiringForRef?.select?.clearValue();
                        }}
                        style={[styles.button, { marginRight: "1%", height: 40, marginLeft: 5 }]}
                      >
                        <Text style={styles.buttonText}>Cancel</Text>
                      </TouchableOpacity>
                    </View>

                    <div id={"visibility"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Visibility Settings</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${16}.value`}
                                checked={values[`post_preferences_attributes.${16}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectStyles}
                            placeholder="Choose Visibility"
                            options={[
                              { value: 'true', label: 'True' },
                              { value: 'false', label: 'False' }
                            ]}
                            defaultInputValue={values.visibility}
                            name="visibility"
                            onChange={(option: any) => {
                              setFieldValue('visibility', option.value)
                            }}
                            onFocus={() => setFieldTouched('visibility', false, false)}
                            onBlur={() => { setFieldTouched('visibility') }}
                            className={classnames("", { "select-error-input": touched.visibility && errors.visibility })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.visibility && errors.visibility &&
                              <Text style={styles.textInputErrorMessage}>{errors.visibility}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"recruiter_list"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Recruiter Name</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${17}.value`}
                                checked={values[`post_preferences_attributes.${17}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectMultipleStyle}
                            isSearchable={true}
                            isMulti
                            placeholder="Type here"
                            options={recruiterList}
                            name="recruiter_list"
                            defaultValue={preSelectedRecruiter}
                            onChange={(e: any) =>
                              setFieldValue('recruiter_list', Array.isArray(e) ? e.map(x => x.value) : [])}
                            className={classnames("", { "select-error-input": touched.recruiter_list && errors.recruiter_list }
                            )}
                            onFocus={() => setFieldTouched('recruiter_list', false, false)}
                            onBlur={() => { setFieldTouched('recruiter_list') }}
                          />
                        </View>
                      </View>
                      <View style={styles.textInputErrorMessageContainer}>
                        {touched.recruiter_list && errors.recruiter_list &&
                          <Text style={styles.textInputErrorMessage}>{errors.recruiter_list}</Text>
                        }
                      </View>

                      <View style={{ flexDirection: "row", marginLeft: -30, marginTop: 40 }}>
                        <View style={styles.switchButton}>
                          <AntSwitch
                            name={`post_preferences_attributes.${21}.value`}
                            checked={values[`post_preferences_attributes.${21}.value`]}
                            onChange={handleChange}
                          />
                        </View>
                        <Text style={styles.subText}>Notify user about Assignment of Requriements</Text>
                      </View>
                    </div>

                    <div id={"vacancies"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>No. of Vacanices</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${18}.value`}
                                checked={values[`post_preferences_attributes.${18}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]}  >
                          <TextField
                            value={values.vacancies}
                            name={"vacancies"}
                            type="number"
                            variant={"outlined"}
                            onChange={handleChange}
                            // disabled={this.state.otpVerified}
                            label="Type here"
                            onFocus={() => setFieldTouched('vacancies', false, false)}
                            onBlur={() => { setFieldTouched('vacancies') }}
                            error={!!(errors.vacancies && touched.vacancies)}
                            helperText={touched.vacancies && errors.vacancies}
                            style={styles.plainTextInput}
                          />
                        </View>
                      </View>
                    </div>

                    <div id={"reference_code_list"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Reference Code</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${19}.value`}
                                checked={values[`post_preferences_attributes.${19}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Any reference code you want to generate</Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <div style={{ width: "100%", display: "contents" }} >
                          <p style={styles.referenceCodeText}>#BhDev1234</p>
                          <p style={styles.referenceCodeText}>#BhDev1234</p>
                          <p style={styles.referenceCodeText}>#BhDev1234</p>
                        </div>
                      </View>
                    </div>

                    <div style={{ display: "flex" }}>
                      <View style={styles.bottomContainer}>
                        <TouchableOpacity style={[styles.button], { textAlign: "center", minWidth: "150px", boxShadow: "none", borderWidth: "0px" }} onPress={() => { window.location = '/JobRequirements' }}
                        >
                          <Text style={styles.buttonText}>CANCEL</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => handleSubmit()}
                          style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", minWidth: "174px" }]}>
                          <Text style={[styles.buttonText, { color: "#ffffff" }]}>NEXT STEP</Text>
                        </TouchableOpacity>
                      </View>
                    </div>

                  </Grid>

                  <Grid item sm={5} style={{ paddingBottom: "0" }}>
                    <View style={[styles.rightImageView]}>
                      <img src={formBottomImage.default} alt="image" style={{ position: "absolute", bottom: "-40px" }} />
                    </View>
                  </Grid>
                </Grid>
              </Fragment>
            </div>
          </View>
        </View>
      </View>
    );
  }
}

// 1200px and up
const baseStyles = {
  rightImageView: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    alignItems: "flex-end",
    paddingRight: "38px"
  },
  container: {
    width: "100%",
    minHeight: "100%",
    padding: "12px",
    paddingTop: "95px",
    paddingLeft: "70px",
    paddingBottom: "40px"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 44,
    minWidth: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    textTransform: "uppercase"
  },
  smallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 120,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "40px"
  },
  topContainerButton: {
    flexDirection: "row",
    alignItems: "center",
    // marginRight: "13px"
    // position: "absolute",
    // marginTop: "-174px",
    // left: "64rem",
  },
  topContainerRadioButton: {
    color: "rgb(126, 126, 126)",
  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    paddingBottom: 15
  },
  hiringContainer: {
    border: "1px solid #e3e3e3",
    padding: "16px 24px",
    marginBottom: 20,
    marginRight: 15,
    borderRadius: 2,
  },
  referenceCodeText: {
    border: "1px solid lightgray",
    borderRadius: 6,
    width: "fit-content",
    padding: "4px 15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginRight: 10,
  },
  locationText: {
    border: "1px solid #8080807a",
    padding: 7,
    marginRight: 13,
    borderRadius: 5,
  },
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 15,
    width: 15,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  uploadTextInput: {
    width: "100%",
    marginBottom: 18,
    color: "silver"
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  },
  rightImage: {
    width: "80%",
    height: "50%",
    marginBottom: "3%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  historyContainerIcons: {
    fontSize: 20,
    color: "black"
  },
  company_logo: {
    height: 48,
    width: 48
  },
  subText: {
    marginLeft: 10,
    color: "rgb(126, 126, 126)",
    wordSpacing: 3,
  },
  switchButton: {
    marginLeft: 30,
  },
  belowText: {
    color: "rgb(126, 126, 126)",
    fontSize: 16,
    marginBottom: 20,
    marginTop: -20,
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {

  }),
  // below 1200
  maxWidth(1200, {

  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {

  }),
  // below  626
  maxWidth(626, {

  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);

const AntSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    '&$checked': {
      color: "rgb(245 123 34 / 84%)",
      marginLeft: -10,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: "white",
      borderColor: "rgb(245 123 34 / 84%)",
    },
  },
  checked: {},
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: 13,
    width: 25,
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
})(Switch);


const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
}

const selectMultipleStyle = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  multiValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: "11%",
      border: `1px solid ${grey[500]}`,
      backgroundColor: "white !important",
      borderRadius: 3
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      position: "fixed !important",
    }
  },
  input: (provided: any, state: any) => {
    return {
      ...provided,
    }
  },
  multiValueRemove: (provided: any, state: any) => {
    return {
      ...provided,
      background: "rgba(0, 0, 0, 0.38)",
      color: "white",
      borderRadius: "50%",
      width: 16,
      height: 16,
      margin: "auto",
      padding: "inherit",
      marginRight: 10,
      marginTop: 5,
      marginLeft: 10,
    }
  },
  // placeholder: (provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     position: "relative",
  //     // marginBottom: 20,
  //     display:'content'
  //   }
  // },
  // valueContainer:(provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     display:'content'
  //   }  }

}