import React, { Component } from "react";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { BsArrowLeft } from "react-icons/bs"
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import {
  StyleSheet,
  Text,
  View,
  Button,
  ScrollView,
  Platform,
  TouchableOpacity
} from "react-native";

import TermsAndCondition from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";


interface Props {
  navigation: any;
  id: string;
  title?: string;
  onBack?: any;
}

interface S {
  message: any;
  title: string;
  buttonText: string;
  buttonMessage: Message;
}

interface SS {
  id: any;
}

export default class InfoPageBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    // const title = this.props.navigation.getParam("page");
    const title = props.title || decodeURIComponent(window.location.pathname).slice(1);

    var message;

    switch (title) {
      case "Terms And Conditions": message = <TermsAndCondition />; break;
      case "Privacy Policy": message = <PrivacyPolicy />; break;
    }

    this.state = {
      message: message || "An error has occured.",
      buttonText: "Ok",
      buttonMessage: new Message(""),
      title: title || "ERROR"
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }



  render() {

    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => this.props.onBack ? this.props.onBack() : this.props.navigation.goBack()}>
            <BsArrowLeft color="black" size={34} style={{ paddingTop: 5, fontWeight: 500 }} />
          </TouchableOpacity>
          <Text style={styles.headerText}>{this.state.title}</Text>
        </View>
        <div>
          {this.state.message}
        </div>
      </View>
    );



  }

  async receive(from: String, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      var content = message.getData(getName(MessageEnum.InfoPageBodyMessage));

      var buttonText = message.getData(
        getName(MessageEnum.InfoPageButtonTextMessage)
      );

      var buttonMessage = message.getData(
        getName(MessageEnum.InfoPageNavigationScreenMessage)
      );
      /*
        this.setState({
          message: content,
          title: title,
          buttonMessage: buttonMessage,
          buttonText: buttonText
        });
        */
    }
  }

}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingHorizontal: "8%"
  },
  header: {
    height: 120,
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  headerText: {
    fontSize: 28,
    paddingLeft: 15,
    fontWeight: "500"
  }
});
