//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
// @ts-ignore
// @ts-nocheck
import { View, Text, Image } from 'react-native';
import React, { Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Icon from 'react-native-vector-icons/FontAwesome';
import { Formik, FormikProps } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
// import Loader from "../../../../components/src/Loader";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
// import Storage from "../../../../framework/src/StorageProvider.web";
import JobDetailForm from './JobDetailsForm.web';
import { FaCheck, FaPlus, FaThList } from "react-icons/fa";
import classnames from 'classnames';

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import JobManagementController, {
  Props,
} from "./JobManagementController";
import { boolean } from 'yup';

const stepsValue = null;

const customStyles = {
  textHeading: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textActive: {
    color: "#fff",
    marginLeft: 55
  },
  lightstepText: {
    color: "rgba(255,255,255,0.3)",
    marginLeft: 55
  },
  textError: {
    color: "#e45d33",
    marginLeft: 55
  }
}

class JobDetail extends JobManagementController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCountryList();
    this.getKeywordsList();
    // this.getJobDetailsValues();
    this.getCompanies()

    if (localStorage.getItem('id')) {
      this.getJobDetailsValues();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isEdit !== prevProps.isEdit) {
      this.getJobDetailsValues();
    }
  }


  getSidebarValue = (steps: any, values: any, touched: any) => {
    this.stepsValue = Object.keys(steps)
    this.touched = touched
    this.values = values
  }

  getSideMenu = (currentHash: any, formikProps: FormikProps) => {
    const styles = getStyles();
    const assets = require("./assets");
    const { values, touched, errors } = formikProps;

    const getClassName = (field: string) => {
      let className = this.props.classes.textHeading;

      if (touched.hasOwnProperty(field) && touched[field]) {
        className = errors.hasOwnProperty(field) ? this.props.classes.textError : this.props.classes.textActive;
      }

      // if (values.hasOwnProperty(field) && values[field]) {
      //   className = this.props.classes.textActive
      // }

      if (touched.hasOwnProperty(field) && !touched[field]) {
        className = this.props.classes.textError
      }

      if (field === 'job_location_details' && touched.hasOwnProperty(field)) {
        const values = Object.values(touched[field]);
        const val = values.find(val => !val)
        if (val !== undefined && !val) {
          className = this.props.classes.textError
        }
      }

      return className;

    }

    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
        </View>
        <View style={styles.stepsContainer}>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }} >
            <View style={[values?.requirement_title !== "" && values?.job_title !== "" && values?.reporting_to !== "" && values?.team !== "" && values?.employment_type !== "" && values?.job_details !== "" && values?.key_result_areas !== "" && values?.key_performance_indicators !== "" && values?.desired_candidate_profile !== "" && values?.currency !== "" && values?.other_salary_details !== "" && values?.work_experience_min !== "" && values?.keyword_skill_ids !== "" && values?.job_location_details !== "" ? styles.stepIconContainerActive : styles.stepIconContainer, { display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
              <View style={[styles.stepIcon, styles.stepIconActive]} style={{ display: 'inline-flex' }}>
                {values?.requirement_title !== "" && values?.job_title !== "" && values?.reporting_to !== "" && values?.team !== "" && values?.employment_type !== "" && values?.job_details !== "" && values?.key_result_areas !== "" && values?.key_performance_indicators !== "" && values?.desired_candidate_profile !== "" && values?.currency !== "" && values?.other_salary_details !== "" && values?.work_experience_min !== "" && values?.keyword_skill_ids !== "" && values?.job_location_details !== "" ? <FaCheck size={10} /> : 1}
              </View>
            </View>
            <Text style={[styles.headingForm]}>Job Details</Text>
          </View>

          <View style={[(currentHash === "#requirement_title" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#requirement_title")}>
            <Text style={[(currentHash === "#requirement_title" && currentHash === "")]} className={getClassName("requirement_title")}>Requirement Title</Text>
          </View>

          <View style={[(currentHash === "#job_title" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#job_title")}>
            <Text style={[(currentHash === "#job_title" && currentHash === "")]} className={getClassName("job_title")}>Job Title</Text>
          </View>

          <View style={[(currentHash === "#reporting_to" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#reporting_to")}>
            <Text style={[(currentHash === "#reporting_to" && currentHash === "")]} className={getClassName("reporting_to")}>Reporting To</Text>
          </View>

          <View style={[(currentHash === "#team" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#team")}>
            <Text style={[(currentHash === "#team" && currentHash === "")]} className={getClassName("team")}>Reportees/Team</Text>
          </View>

          <View style={[(currentHash === "#employment_type" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#employment_type")}>
            <Text style={[(currentHash === "#employment_type" && currentHash === "")]} className={getClassName("employment_type")}>Employment Type</Text>
          </View>

          <View style={[(currentHash === "#job_details" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#job_details")}>
            <Text style={[(currentHash === "#job_details" && currentHash === "")]} className={getClassName("job_details")}>Job Details</Text>
          </View>

          <View style={[(currentHash === "#key_result_areas" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#key_result_areas")}>
            <Text style={[(currentHash === "#key_result_areas" && currentHash === "")]} className={getClassName("key_result_areas")}>Key Result Areas</Text>
          </View>

          <View style={[(currentHash === "#key_performance_indicators" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#key_performance_indicators")}>
            <Text style={[(currentHash === "#key_performance_indicators" && currentHash === "")]} className={getClassName("key_performance_indicators")}>Key Performance Indicators</Text>
          </View>

          <View style={[(currentHash === "#desired_candidate_profile" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#desired_candidate_profile")}>
            <Text style={[(currentHash === "#desired_candidate_profile" && currentHash === "")]} className={getClassName("desired_candidate_profile")}>Designed Candidate profile</Text>
          </View>

          <View style={[(currentHash === "#currency" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#currency")}>
            <Text style={[(currentHash === "#currency" && currentHash === "")]} className={getClassName("currency")}>Annual CTC</Text>
          </View>

          <View style={[(currentHash === "#other_salary_details" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#other_salary_details")}>
            <Text style={[(currentHash === "#other_salary_details" && currentHash === "")]} className={getClassName("other_salary_details")}>Other Salary Details</Text>
          </View>

          <View style={[(currentHash === "#work_experience_min" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#work_experience_min")}>
            <Text style={[(currentHash === "#work_experience_min" && currentHash === "")]} className={getClassName("work_experience_min")}>Work Experience</Text>
          </View>

          <View style={[(currentHash === "#keyword_skill_ids" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#keyword_skill_ids")}>
            <Text style={[(currentHash === "#keyword_skill_ids" && currentHash === "")]} className={getClassName("keyword_skill_ids")}>Keywords</Text>
          </View>

          <View style={[(currentHash === "#job_location_details" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#job_location_details")}>
            <Text style={[(currentHash === "#job_location_details" && currentHash === "")]} className={getClassName("job_location_details")}>Job located</Text>
          </View>

          {/* Additional Details */}
          <View style={{ flexDirection: "row", alignItems: "flex-start", marginBottom: 10 }} >
            <View style={[values?.hiring_for !== "" && values?.visibility !== "" && values?.recruiter_list !== "" && values?.vacancies !== "" && values?.reference_code_list !== "" ? styles.stepAdditionalIconContainerActive : styles.stepIconContainer]}>
              <View style={[styles.stepIcon, styles.stepAdditionalIconActive]} style={{ display: 'inline-flex' }}>{values?.hiring_for !== "" && values?.visibility !== "" && values?.recruiter_list !== "" && values?.vacancies !== "" && values?.reference_code_list !== "" ? <FaCheck size={10} /> : 2}</View>
            </View>
            <Text style={[styles.headingForm]}>Additional Details</Text>
          </View>

          <View style={[(currentHash === "#hiring_for" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#hiring_for")}>
            <Text style={[(currentHash === "#hiring_for" && currentHash === "")]} className={getClassName("company_name")}>Hiring For</Text>
          </View>

          <View style={[(currentHash === "#visibility" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#visibility")}>
            <Text style={[(currentHash === "#visibility" && currentHash === "")]} className={getClassName("visibility")}>Visibility Setting</Text>
          </View>

          <View style={[(currentHash === "#recruiter_list" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#recruiter_list")}>
            <Text style={[(currentHash === "#recruiter_list" && currentHash === "")]} className={getClassName("recruiter_list")}>Recuriter Name</Text>
          </View>

          <View style={[(currentHash === "#vacancies" && currentHash === ""), { flexDirection: "row", alignItems: "center", marginBottom: 20 }]} onClick={() => window.location.replace("#vacancies")}>
            <Text style={[(currentHash === "#vacancies" && currentHash === "")]} className={getClassName("vacancies")}>No. of Vacancies</Text>
          </View>

          <View style={[(currentHash === "#reference_code_list" && currentHash === ""), { flexDirection: "row", alignItems: "center" }]} onClick={() => window.location.replace("#reference_code_list")}>
            <Text style={[(currentHash === "#reference_code_list" && currentHash === "")]} className={getClassName("reference_code_list")}>Refference code</Text>
          </View>
        </View>
      </View>
    )
  }

  getSidemenuFooter = () => {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      <View style={{ marginTop: "100%" }}>

        <Text style={styles.leftTitle}>Lorem Ipsum has been the industry's standard</Text>
        <Text style={[styles.text, { marginTop: 8, color: "rgba(255,255,255,0.3)" }]}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</Text>


        <View style={{ marginTop: 50 }}>
          <PaginationIndicator count={3} selected={1} style={{ justifyContent: "flex-start" }} />
        </View>
        {/*<Text style={[styles.text, { marginTop: 50, color: "#ffffff" }]}>© 2021 RMS. All right Reserved.</Text>*/}
      </View>
    )
  }

  onLayoutChange = (h: any) => {
    console.log('onLayoutChange >>>>> ', h)
  }

  getReferenceCode = (data) => {
    if (data && data.reference_codes && data.reference_codes.length > 0) {
      const obj = data.reference_codes[0]
      if (obj && obj.name) return obj.name
    }
    return ''
  }

  getIndustryValue = (data) => {
    if (data && data.industry && data.industry.length > 0) {
      const obj = data.industry[0]
      if (obj && obj.name) return obj.name
    }
    return ''
  }

  getKeySkill = (data) => {
    if (data && data?.keyword_skill?.data && data?.keyword_skill?.data.length > 0) {
      const newList = data?.keyword_skill?.data.map((item) => (item.id))

      return newList
    }
    return ''
  }

  recruiter_list_value = (jobDetailValue: any) => {
    if (jobDetailValue?.recruiters && typeof jobDetailValue.recruiters === 'string') {
      return jobDetailValue.recruiters
      return jobDetailValue.recruiters.split(',')
    }
    return ''
  }

  render() {
    const styles = getStyles();
    const currentHash = window.location.hash;
    const jobDetailValue = JSON.parse(localStorage.getItem("jobDetailValues"))
    const { companies, } = this.state;
    const emptyData = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
    const initialValues = {
      //avatar: profileData?.attributes?.avatar || "",
      requirement_title: jobDetailValue && jobDetailValue.requirement_title || "",
      job_title: jobDetailValue?.job_title || "",
      reporting_to: jobDetailValue?.reporting_to || '',
      team: jobDetailValue?.team || "",
      employment_type: jobDetailValue?.employment_type || "",
      job_details: jobDetailValue?.job_details || emptyData,
      key_result_areas: jobDetailValue?.key_result_areas || emptyData,
      key_performance_indicators: jobDetailValue?.key_performance_indicators || emptyData,
      desired_candidate_profile: jobDetailValue?.desired_candidate_profile || emptyData,
      min_salary: jobDetailValue?.min_salary || null,
      max_salary: jobDetailValue?.max_salary || null,
      other_salary_details: jobDetailValue?.other_salary_details || emptyData,
      work_experience_min: jobDetailValue?.work_experience_min || null,
      work_experience_max: jobDetailValue?.work_experience_max || null,
      keyword_skill_ids: this.getKeySkill(jobDetailValue),
      hiring_for: jobDetailValue?.hiring_for || '',
      visibility: JSON.stringify(jobDetailValue?.visibility) || "",
      recruiter_list: this.recruiter_list_value(jobDetailValue),
      vacancies: jobDetailValue?.vacancies || null,
      reference_code_list: this.getReferenceCode(jobDetailValue),
      job_location_details: {
        "country": jobDetailValue?.job_location_details?.country || '',
        "state": jobDetailValue?.job_location_details?.state || "",
        "city": jobDetailValue?.job_location_details?.city || ""
      },
      currency: jobDetailValue?.currency || "inr",
      company_name: "",
      employer_name: this.state.empName || "",
      email: this.state.emailId || "",
      contact_no: this.state.contactNo || "",
      post_preferences_attributes: [
        { name: "requirement_title", value: false },
        { name: "job_title", value: false },
        { name: "reporting_to", value: false },
        { name: "team", value: false },
        { name: "employment_type", value: false },
        { name: "job_details", value: false },
        { name: "key_result_areas", value: false },
        { name: "key_performance_indicators", value: false },
        { name: "desired_candidate_profile", value: false },
        { name: "annual_ctc", value: false },
        { name: "other_salary_details", value: false },
        { name: "work_experience", value: false },
        { name: "keywords", value: false },
        { name: "job_location_details", value: false },
        { name: "hiring_for", value: false },
        { name: "hiring_for_show_to_job_seeker", value: false },
        { name: "visibility", value: false },
        { name: "recruiter", value: false },
        { name: "vacancies", value: false },
        { name: "reference_code", value: false },
        { name: "annual_ctc_show_to_job_seeker", value: false },
        { name: "notify_user_about_assigment_of_requirements", value: false },
      ]
    }

    const countries = this.state.countries.map((item) => (
      { value: item?.id, label: item.attributes?.name }));

    const keywordsList = this.state.keywordList.map((item) => ({
      value: item.id,
      label: item.attributes.name
    }))

    const companiesRef = companies.map((item) => ({
      value: item.id,
      label: item.attributes.name
    }))

    const recruiterList = [
      { value: "mack", label: "Mack" },
      { value: "ally", label: "Ally" }
    ]

    if (this.state.isFormLoading) return 'Fetching ...'

    return (
      <View style={[styles.container]}>
        <Formik
          validateOnMount={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            if (this.props.isEdit) {
              this.updateJobDetailForm({
                ...values,
                recruiter_list: Array.isArray(values.recruiter_list) ? values.recruiter_list.join(",") : values.recruiter_list,
              })
            }
            else {
              this.formSubmit(
                {
                  ...values,
                  recruiter_list: Array.isArray(values.recruiter_list) && values.recruiter_list.join(",") || '',
                }
              )
            }
          }}
          validationSchema={() => this.getJobDetailFormValidations()}
        >
          {(formikProps) => {

            const { values } = formikProps;


            const preSelectedKeywords = keywordsList.filter(obj =>
            (Array.isArray(values.keyword_skill_ids) &&
              values.keyword_skill_ids.some((item) => (item == obj.value))
            )
            )

            const preSelectedRecruiter = recruiterList.filter(item => values.recruiter_list.includes(item.value.toLowerCase()))
            return (
              <Fragment>
                <View style={[styles.leftContainer]}>
                  {this.getSideMenu(currentHash, formikProps)}
                  {this.getSidemenuFooter()}
                </View>
                <View style={[styles.rightContainer]} >
                  <JobDetailForm
                    {...this.props}
                    {...formikProps}
                    preSelectedKeywords={preSelectedKeywords}
                    preSelectedRecruiter={preSelectedRecruiter}
                    keywordsList={keywordsList}
                    countries={countries}
                    recruiterList={recruiterList}
                    companiesRef={companiesRef}
                    companies={this.state.companies}
                  />
                </View>
              </Fragment>
            )
          }}
        </Formik>
      </View>
    );
  }

}

export default withStyles(customStyles)(JobDetail)


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 30,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "320px",
    // minHeight: "100%",
    padding: "27px",
    justifyContent: "space-between"
  },
  logoImage: {
    marginTop: 100,
    width: 45,
    height: 45
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "#fff",
    marginLeft: 30
  },
  stepAdditionalTextActive: {
    color: "rgb(126, 126, 126)",
    marginLeft: 30
  },
  stepIconContainer: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "5px"
  },
  stepIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepAdditionalIconContainerActive: {
    marginRight: 25,
    height: 17,
    width: 17,
    borderWidth: 1,
    color: "#141d3d",
    borderColor: "#ffffff",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginTop: "7px"
  },
  stepIcon: {
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    color: "rgb(20,29,61)"
  },
  stepAdditionalIconActive: {
    fontSize: "14px",
    color: "#fff"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    width: "calc(100% - 320px)",
    // minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  headingForm: {
    fontSize: "larger",
    marginBottom: 20,
    color: "white",
  },
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  // maxWidth(992, {
  //   leftContainer: {
  //     display: "none"
  //   },
  //   rightContainer: {
  //     width: "100%"
  //   }
  // }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);