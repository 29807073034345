import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  jobTitles: Array<any>;
  jobLocations: Array<any>;
  jobSkills: Array<any>;
  selectedJobTitles: Array<any>;
  selectedJobLocations: Array<any>;
  selectedJobSkills: Array<any>;
  jobTitleSearch: string;
  jobLocationSearch: string;
  jobSkillSearch: string;
  showTitleTags: boolean,
  showLocationTags: boolean,
  showSkillTags: boolean,

  collapseTitleTags: boolean,
  collapseLocationTags: boolean,
  collapseSkillTags: boolean,

  appliedJobs: Array<any>,

  jobId: string,
  jobIdDetails: any,
  jobListingData: Array<any>,

  searchText: string,

  selectedJobs: Array<any>,
  loading: boolean,
  page: number,
  perPage: number,
  pagination: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class JobListingController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiCallMessageGetJobTitlesTagsRequestId: any;
  apiCallMessageGetJobLocationsTagsRequestId: any;
  apiCallMessageGetJobSkillsTagsRequestId: any;
  apiCallMessageGetJobIdDetailsRequestId: any;
  apiCallMessageGetJobListingRequestId: any;
  apiCallMessageGetJobListingPaginationRequestId: any;
  apiCallMessageUpdateJobIdDetailsRequestId: any;
  apiCallMessageGetJobIdDetailsForListingRequestId: any;
  apiCallMessageGetAppliedJobsRequestId: any;
  apiCallMessageApplyJobMessageId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      jobTitles: [],
      jobLocations: [],
      jobSkills: [],
      selectedJobTitles: [],
      selectedJobLocations: [],
      selectedJobSkills: [],
      jobTitleSearch: "",
      jobLocationSearch: "",
      jobSkillSearch: "",
      showTitleTags: false,
      showLocationTags: false,
      showSkillTags: false,
      collapseTitleTags: false,
      collapseLocationTags: false,
      collapseSkillTags: false,
      appliedJobs: [],
      jobId: "",
      jobIdDetails: {},
      jobListingData: [],
      searchText: "",
      selectedJobs: [],
      loading: false,
      page: 1,
      perPage: 10,
      pagination: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    this.getAppliedJobs();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (this.apiCallMessageApplyJobMessageId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageApplyJobMessageId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (typeof responseJson.data === "object") {

          responseJson.data.forEach((itm: any, idx: number) => {

            var index = this.state.appliedJobs.findIndex((item) => {
              return item.attributes?.job_details?.id === itm?.attributes?.job_details?.id;
            });

            if (index > 0) {
              this.state.appliedJobs[index] = itm;
            } else {
              this.state.appliedJobs.push(itm);
            }

          });

          this.setState({ selectedJobs: [] });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetAppliedJobsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetAppliedJobsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (Array.isArray(responseJson.data)) {
          this.setState({ appliedJobs: responseJson.data });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobIdDetailsForListingRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobIdDetailsForListingRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);

        var index = this.state.jobListingData.findIndex((item: any) => responseJson.data.id === item.id);
        if (index > -1) {
          this.state.jobListingData[index] = responseJson.data;
          this.setState({ loading: false });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageUpdateJobIdDetailsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateJobIdDetailsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        this.setState({ loading: true }, () => {
          this.getJobIdDetailForListing(responseJson.data.id);
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobListingPaginationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobListingPaginationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (Array.isArray(responseJson.data)) {

          var pagination = typeof responseJson.meta?.pagination === "object" ? responseJson.meta?.pagination : {};
          this.setState({ jobListingData: this.state.jobListingData.concat(responseJson.data), pagination: pagination, loading: false }, () => {

          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobListingRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobListingRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson.data)) {
          //this.state.jobListingData.concat(responseJson.data)
          var pagination = typeof responseJson.meta?.pagination === "object" ? responseJson.meta?.pagination : {};
          this.setState({ jobListingData: responseJson.data, pagination: pagination, loading: false }, () => {

          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobIdDetailsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobIdDetailsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (typeof responseJson.data === "object") {
          var skill_list = [];
          if (Array.isArray(responseJson.data?.attributes?.account?.skill_list)) {
            skill_list = responseJson.data?.attributes?.account?.skill_list;
          }

          this.setState({ jobIdDetails: responseJson.data, selectedJobSkills: skill_list }, () => {
            this.getJobListing();
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobTitlesTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobTitlesTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobTitles: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (this.apiCallMessageGetJobLocationsTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobLocationsTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobLocations: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }

    else if (this.apiCallMessageGetJobSkillsTagsRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetJobSkillsTagsRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        console.log("skills ,", responseJson);
        if (Array.isArray(responseJson)) {
          this.setState({ jobSkills: responseJson.filter((item) => !!item) });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

    }
    else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  async getJobTitlesTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobTitlesTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_job_titles"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getJobLocationsTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobLocationsTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_locations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getJobSkillsTags() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobSkillsTagsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "candidate_job/available_skills"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getJobListing() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const body = {
      "job_titles": this.state.selectedJobTitles,
      "locations": this.state.selectedJobLocations,
      "skills": this.state.selectedJobSkills,
      "keyword": this.state.searchText,
      "page": this.state.page,
      "per": this.state.perPage
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobListingRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `candidate_job`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async getJobListingPagination() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const body = {
      "job_titles": this.state.selectedJobTitles,
      "locations": this.state.selectedJobLocations,
      "skills": this.state.selectedJobSkills,
      "keyword": this.state.searchText,
      "page": this.state.page,
      "per": this.state.perPage
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobListingPaginationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `candidate_job`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getJobIdDetails(id: string) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobIdDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `candidate_job/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAppliedJobs() {
    const authToken = await Storage.get("authToken");
    const profileData = await Storage.get("profileData");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetAppliedJobsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/applied_job/${profileData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async applyJob(jobId: any) {
    const authToken = await Storage.get("authToken");
    const profileData = await Storage.get("profileData");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageApplyJobMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/applied_job`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "apply_job": [
          { "job_detail_id": jobId }
        ]
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async applyMultipleJobs() {
    const authToken = await Storage.get("authToken");
    const profileData = await Storage.get("profileData");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageApplyJobMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/applied_job`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "apply_job": this.state.selectedJobs.map((item) => ({ "job_detail_id": item }))
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getJobIdDetailForListing(id: string) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetJobIdDetailsForListingRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `candidate_job/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateJobIdDetails(id: string, values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };


    const body = {
      "job_detail": values
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateJobIdDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `jobmanagement/job_details/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
