import React, { Fragment }  from "react";
// Customizable Area Start
import {View, Text, TouchableOpacity} from 'react-native';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import "./style.css";


export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    await this.getUserProfile();
  }


  render() {
    const defaultPAsswordCheck = this.state.defaultPasswordReset;
    const role = this.state.role;
    const styles = getStyles();
    console.log(this.state.errorResponse, "errorResponse")
    return (
     <View style={styles.container}>
       {defaultPAsswordCheck == null && role != "candidate" ?
      <Dialog
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modalContainer modalshow modalwap2"
      >
      <Text style={styles.leftTitle}>Set your Password</Text>
      <Text style={[styles.text, { marginTop: 8, fontWeight: "500" }]}>Lorem Ipsum has been the industry's standard dummy text ever since</Text>

      <Formik
        initialValues={{ currentPassword: "", password: "", confirmPassword: ""}}
        onSubmit={(values) => { this.setState({ loading: true }, () => this.updatePassword(values)); }}
        validationSchema={this.getFormValidations}
      >
        {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => (
          <Fragment>
<            View style={[styles.formRow]}>
              <View style={[styles.inputWrapper, { width: "100%" }]} >
                <TextField {...this.txtInputCurrentPasswordProps} name="currentPassword" onChange={handleChange} onBlur={() => setFieldTouched('currentPassword')} variant={"outlined"} label={"Enter Current Password"} type={this.state.enableCurrentTypePasswordField ? "password" : "text"} error={!!(errors.currentPassword && touched.currentPassword)} 
                helperText={touched.currentPassword && errors.currentPassword} style={styles.plainTextInput} value={values.currentPassword} />
              </View>
              <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                <TouchableOpacity {...this.btnCurrentPasswordShowHideProps} style={{ height: 26 }}>
                  {this.state.enableCurrentTypePasswordField ?
                    <AiFillEye size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                    :
                    <AiFillEyeInvisible size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                  }
                </TouchableOpacity>
              </View>
            </View>
          
            <Text style={styles.credeError}>
              {this.state.errorResponse == "Invalid credentials" ? this.state.errorResponse: null}
            </Text>

            <View style={[styles.formRow]}>
              <View style={[styles.inputWrapper, { width: "100%" }]} >
                <TextField {...this.txtInputPasswordProps} name="password" onChange={handleChange} onBlur={() => setFieldTouched('password')} variant={"outlined"} label={"Enter New Password"} type={this.state.enablePasswordField ? "password" : "text"} error={!!(errors.password && touched.password)} 
                helperText={touched.password && errors.password} style={styles.plainTextInput} value={values.password} />
              </View>
              <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                <TouchableOpacity {...this.btnPasswordShowHideProps} style={{ height: 26 }}>
                  {this.state.enablePasswordField ?
                    <AiFillEye size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                    :
                    <AiFillEyeInvisible size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                  }
                </TouchableOpacity>
              </View>
            </View>

            <Text style={styles.credeError}>
              {this.state.errorResponse == "New password can't be same as current password" ? this.state.errorResponse: null}
            </Text>

            <View style={[styles.formRow]}>
              <View style={[styles.inputWrapper, { width: "100%" }]} >
                <TextField {...this.txtInputConfirmPasswordProps} name="confirmPassword" onChange={handleChange} onBlur={() => setFieldTouched('confirmPassword')} variant={"outlined"} label={"Confirm New Password"} type={this.state.enableReTypePasswordField ? "password" : "text"} error={!!(errors.confirmPassword && touched.confirmPassword)} helperText={touched.confirmPassword && errors.confirmPassword} style={styles.plainTextInput} value={values.confirmPassword} />
              </View>
              <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                <TouchableOpacity {...this.btnConfirmPasswordShowHideProps} style={{ height: 26 }}>
                  {this.state.enableReTypePasswordField ?
                    <AiFillEye size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                    :
                    <AiFillEyeInvisible size={24} color="rgba(0, 0, 0, 0.6)" style={styles.textInputIcon} />
                  }
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity {...this.btnUpdatePasswordProps} onPress={() => { handleSubmit() }}  style={styles.button}>
              <Text style={styles.buttonText}>{this.btnTextSignUp}</Text>
            </TouchableOpacity>
          </Fragment>
        )}
      </Formik>
      </Dialog>
      : null }
     </View>
    );
  }
}

// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },

  dialogContainer: {
    width: "800px"
  },

  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 20,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "#ffffff",
    width: "50%",
    minHeight: "100%",
    paddingHorizontal: "5.5%",
    paddingVertical: "3%",
    justifyContent: "space-between"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  leftTitle: {
    fontSize: 30,
    color: "#1a181d",
    fontWeight: 700
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 28
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "50%",
    minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 18
  },
  inputWrapper: {
    width: "49%"
  },
  credeError: {
    color: "#f44336"
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  // maxWidth(1200, {
  // }),
  // below 992
  maxWidth(1026, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);
