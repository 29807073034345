//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import isEmpty from 'lodash/isEmpty'
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Storage from "../../../framework/src/StorageProvider.web";
import * as yup from 'yup';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
    submitSearchStrategyContentType,
    submitSearchStrategyMethod,
    submitSearchStrategyEndPoint,
    functionalAreaListContentType,
    functionalAreaListMethod,
    functionalAreaListEndPoint,
    courseListApiContentType,
    courseListApiMethod,
    courseListApiEndPoint,
    postGraduationListApiContentType,
    postGraduationListApiMethod,
    postGraduationListApiEndPoint,
    doctorateListApiContentType,
    doctorateListApiMethod,
    doctorateListApiEndPoint,
    jobDetailsValuesApiContentType,
    jobDetailsValuesApiMethod,
    jobDetailValuesApiEndPoint,
    keywordListApiContentType,
    keywordListApiEndPoint,
    keywordListApiMethod,
} from "./config";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    onChangeLayout?: (height: number) => void;
    addFile: () => void;
    closeJobPreviewModal: () => void;
    backToEdit: any;
}

interface S {
    // Customizable Area Start
    requrimentTitle: string;
    txtSavedValue: string;
    enableField: boolean;
    sidemenuHeight: any;
    isJobPreview: boolean;
    files: Array<any>;
    functionalAreaList: Array<any>;
    courses: Array<any>;
    postGraduationList: Array<any>;
    doctorateList: Array<any>;
    fileObject: object;
    isFormLoading: boolean;
    searchStrategyValues: object;
    basicDetailObj: object;
    showJobDetailsSubAccordian: boolean;
    editorData: any;
    keywordList: Array<any>;
}

interface SS {
    id: any;
}

export default class SearchStrategyController extends BlockComponent<
    Props,
    S,
    SS
> {

    submitSearchStrategyCallMessageRequestId: any;
    functionalAreaListApiCallMessageRequestId: any;
    courseListApiCallMessageRequestId: any;
    postGraduationListApiCallMessageRequestId: any;
    doctorateListApiCallMessageRequestId: any;
    SearchStrategyDetailsApiCallMessageRequestId: any;
    keywordListApiCallMessageRequestId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            requrimentTitle: '',
            txtSavedValue: "A",
            enableField: false,
            sidemenuHeight: "100%",
            isJobPreview: false,
            files: [],
            functionalAreaList: [],
            courses: [],
            postGraduationList: [],
            doctorateList: [],
            fileObject: {},
            isFormLoading: false,
            searchStrategyValues: {},
            basicDetailObj: {},
            showJobDetailsSubAccordian: false,
            editorData: {},
            keywordList: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    jobPreviewModal = () => {
        this.setState({ isJobPreview: !this.state.isJobPreview })
    }

    closeJobPreviewModal = () => {
        this.setState({ isJobPreview: false })
    }

    openJobPreviewModal = () => {
        this.setState({ isJobPreview: true })
    }

    convertArray = (files: any) => {
        // if (this.state.files) {
        //   const createFilesObect = {...[this.state.files]}
        this.setState({ fileObject: files })
    }

    setFormData = (values: any) => {
        const formData = new FormData();

        this.getFormData(values, formData);
        return formData;
    }

    getFormData = (values: any, formData: any, parentKey: any) => {

        if (values && typeof values === 'object' && !(values instanceof Date) && !(values instanceof File) && !(values instanceof Array)) {
            Object.keys(values).forEach(key => {
                this.getFormData(values[key], formData, parentKey ? `${parentKey}[${key}]` : key);
            });
        }
        else if (values instanceof Array) {
            values.forEach((item) => formData.append(`${parentKey}[]`, item))
        }
        else {
            const value = values == null ? '' : values;
            formData.append(parentKey, value);
        }
        // Object.keys(values).forEach(key => formData.append(key, JSON.stringify(values[key])));
        // return formData;
    }

    addFile = (files: any) => {
        const allFiles = this.state.files.length === 0 ? [...files] :
            [...this.state.files, ...files]
        if (files.length > 0) {
            console.log("Filesext ", files[0]);
            if (!["doc", "jpeg", "png", "svg", "jpg", "pdf", "gif", "m4b", "mp3", "m4a", "org", "mp4", "mkv", "swf", "flv", "mpeg-2", "mov", "webm"].includes(files[0].name.split(".").pop() || "")) {
                this.showAlert(
                    "",
                    "Only doc, image, pdf, audio, video are allowed",
                    "OK"
                );
                return;
            }
        }

        if (files[0].size > 10485760) {
            this.showAlert(
                "",
                "Max file size allowed is 10mb",
                "OK"
            );
            return;
        }

        this.setState({ files: allFiles })
        this.convertArray(files)
    }

    removeFile = (file) => {
        const filesRef = [...this.state.files]
        filesRef.splice(filesRef.indexOf(filesRef.find(item => item.name === file.name)), 1)
        this.setState({ files: filesRef })
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        else if (this.SearchStrategyDetailsApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.SearchStrategyDetailsApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && !responseJson.errors) {
                this.setState({ searchStrategyValues: responseJson.data.attributes?.search_strategy.data?.attributes })
            }
            this.setState({ isFormLoading: false })

        }

        else if (this.submitSearchStrategyCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.submitSearchStrategyCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse);

            if (responseJson && !responseJson.errors) {
                this.jobPreviewModal()
            }
            else {
                this.jobPreviewModal()
            }
        }

        else if (this.functionalAreaListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.functionalAreaListApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse);
            if (responseJson) {
                this.setState({ functionalAreaList: responseJson.data })
            }
        }

        else if (this.courseListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.courseListApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse)

            if (responseJson) {
                this.setState({ courses: responseJson.data })
            }
        }

        else if (this.postGraduationListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.postGraduationListApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse)
            if (responseJson) {
                this.setState({ postGraduationList: responseJson.data })
            }
        }

        else if (this.doctorateListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.doctorateListApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse)

            if (responseJson) {
                this.setState({ doctorateList: responseJson.data })
            }
        }

        else if (this.keywordListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.keywordListApiCallMessageRequestId) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            console.log("errorReponse ", errorReponse)

            if (responseJson) {
                this.setState({ keywordList: responseJson.data })
            }
        }

        // Customizable Area End
    }
    // Customizable Area Start
    txtInputRequrimentTitleWebProps = {
        onChangeText: (text: string) => {
            this.setState({ requrimentTitle: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputRequrimentTitleWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb() ? this.txtInputRequrimentTitleWebProps : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    }
    // Customizable Area Start
    txtInputRequrimentTitleWebProps = {
        onChangeText: (text: string) => {
            this.setState({ requrimentTitle: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputRequrimentTitleWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputRequrimentTitleWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible
    };


    async getKeywordsList() {
        const authToken = await Storage.get("authToken");
    
        const header = {
          "Content-Type": keywordListApiContentType,
          token: authToken
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.keywordListApiCallMessageRequestId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          keywordListApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          keywordListApiMethod,
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    async getSearchStrategyDetails() {
        const authToken = await Storage.get("authToken");
        const jobDetailId = localStorage.getItem("id")

        const header = {
            "Content-Type": jobDetailsValuesApiContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.SearchStrategyDetailsApiCallMessageRequestId = requestMessage.messageId;
        this.setState({ isFormLoading: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${jobDetailValuesApiEndPoint}${jobDetailId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            jobDetailsValuesApiMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async getDoctorateList() {
        const authToken = await Storage.get("authToken");

        const header = {
            "Content-Type": doctorateListApiContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.doctorateListApiCallMessageRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            doctorateListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            doctorateListApiMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async getPostGraduationList() {
        const authToken = await Storage.get("authToken");

        const header = {
            "Content-Type": postGraduationListApiContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postGraduationListApiCallMessageRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            postGraduationListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            postGraduationListApiMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async getCourseList() {
        const authToken = await Storage.get("authToken");

        const header = {
            "Content-Type": courseListApiContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.courseListApiCallMessageRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            courseListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            courseListApiMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    async getFunctionalAreas() {
        const authToken = await Storage.get("authToken");

        const header = {
            "Content-Type": functionalAreaListContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.functionalAreaListApiCallMessageRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            functionalAreaListEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            functionalAreaListMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async submitSearchStrategy(values: any, attachments: any) {
        const jobDetailId = localStorage.getItem("id")
        const authToken = await Storage.get("authToken");
        const params = this.setFormData(values)
        if (!isEmpty(this.state.fileObject)) {
            debugger
            params.append("detail_attachment", this.state.fileObject[0])
        }
        const httpBody = params
        const header = {
            // "Content-Type": submitSearchStrategyContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitSearchStrategyCallMessageRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${submitSearchStrategyEndPoint}${jobDetailId}/search_strategies`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            submitSearchStrategyMethod,
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End()

    getSearchStrategyFormValidations() {
        // minVal = minVal.work_experience_min
        const validations = yup.object().shape({
            //   avatar: yup.mixed()
            //     .required("Avatar is required."),*/
            brief: yup.string()
                .required("Field is required."),
            role_dynamics: yup.string()
                .required("Field is required."),
            // required_skill_ids: yup
            // .array()
            // .min(1, "Field is required")
            // .of(
            //   yup.string().required('Field is required')
            // ),
            required_skill_ids: yup.string().required("Field is required."),
            experience_min: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .min(0, "invalid")
                .lessThan(100, "work experince less than 100"),
            experience_max: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .moreThan(yup.ref('experience_min'), "max Experience should be > min Exp.")
                .lessThan(100, "work experince less than 100"),
            annual_salary_min: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .lessThan(9999999999, "only ten digits are valid"),
            annual_salary_max: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .moreThan(yup.ref('annual_salary_min'), "max Salary should be > min salary")
                .lessThan(9999999999, "only ten digits are valid"),
            current_location: yup.string()
                .required("Field is required."),
            requirements: yup.string()
                .required("Field is required."),
            status: yup.string()
                .required("Field is required."),
            profile_source: yup.string()
                .required("Field is required."),
            comments: yup.string()
                .required("Field is required."),
            functional_area_id: yup.string()
                .required("Field is required."),
            role: yup.string()
                .required("Field is required."),
            industry_type: yup.string()
                .required("Field is required."),
            employer_type: yup.string().required("Field is required."),
            current_employer: yup.string().required("Field is required."),
            employer_all_words: yup.string().required("Field is required."),
            exclude_employer_type: yup.string().required("Field is required."),
            exclude_current_employer: yup.string().required("Field is required."),
            exclude_employer_all_words: yup.string().required("Field is required."),
            designation_type_her: yup.string().required("Field is required."),
            designation_current_employer: yup.string().required("Field is required."),
            designation_all_words: yup.string().required("Field is required."),
            notice_period: yup.string()
                .required("Field is required."),
            ug_qualification_any: yup.string().required("Field is required."),
            // ug_qualification_enforced: yup.string().required("Field is required."),
            pg_qualification_any: yup.string().required("Field is required."),
            // pg_qualification_enforced: yup.string().required("Field is required."),
            ppg_qualification_any: yup.string().required("Field is required."),
            // ppg_qualification_enforced: yup.string().required("Field is required."),
            star_rating_from: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .lessThan(999999999999, "only 12 digits are valid"),
            star_rating_to: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .lessThan(999999999999, "only 12 digits are valid"),
            candidate_age_from: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .lessThan(100, "candidate age less than 100"),
            candidate_age_to: yup.number()
                .typeError("Only Numbers are allowed")
                .required("Field is required.")
                .lessThan(100, "candidate age less than 100"),
            work_status_for_usa: yup.string().required("Field is required."),
            work_status_for_other_country: yup.string().required("Field is required."),
            // affirmative_action_value_id: yup.string()
            //     .required("Field is required."),
            sort_by_relevance: yup.string()
                .required("Field is required."),
            resume_per_page_relevance: yup.string()
                .required("Field is required."),
            conclusion: yup.string()
                .required("Field is required."),
        });

        return validations;
    }

    // Customizable Area End()

    getBasicDetailValidation() {
        const validations = yup.object().shape({
            /* anyKeyword: yup.string()
               .required("Field is required."),
             allKeyword: yup.string()
             .required("Field is required."),
             exculdeKeyword: yup.string()
             .required("Field is required."),*/
        });
        return validations;
    }

}