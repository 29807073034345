import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Grid, Dialog, DialogContent, DialogActions, Select, MenuItem, CircularProgress, IconButton, Table, TableBody, TableCell, Checkbox, TableHead, TableRow, Icon, Button } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import { Search, ArrowDropDown, ChevronLeft, ChevronRight, DeleteOutline, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import ProfileSourceModal from './ProfileSourceModal.web'
import { editIcon, showIcon, hideIcon } from "./assets";
// Customizable Area End

import ProfileSourceController, {
    Props,
    configJSON
} from "./ProfileSourceController";


class ProfileSource extends ProfileSourceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getProfileSourceList({
            page: 1,
            perPage: this.state.pagination.perPage
        })
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const {
            profileSourceList,
            pagination,
            hoveredStatusIndex,
            canBeDeletedProfileSources,
            deletedProfileSources,
            showDeleteConfirmation
        } = this.state
        return (
            <Box className={classes.container} >
                <Box className={classes.listContainer} >
                    <Grid sm={12} container={true} style={{ height: '100%', display: 'block' }} >
                        <Grid sm={12} container={true}  >
                            <Grid sm={8} container={true} >
                                <Box style={{ padding: 24, }} >
                                    <Typography style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        color: '#1a181d'
                                    }} >Profile Source</Typography>
                                    <Typography style={{
                                        opacity: 0.4,
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }}>Lorem Ipsume demo contetn should be here so we can see how it works.</Typography>
                                </Box>
                            </Grid>
                            <Grid alignItems='flex-end' sm={4} container={true} style={{
                                paddingTop: 24,
                                paddingRight: 24,
                                paddingBottom: 24,
                                flexDirection: 'column'
                            }} >

                                <Button onClick={() => this.openModalHandler()} style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#e45d33',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }} >CREATE</Button>

                                <Box className={classes.searchBoxContainer} >
                                    <input required style={{
                                        width: '100%',
                                        padding: 0,
                                        border: 'none',
                                        outline: 'none',
                                        height: '100%',
                                        fontFamily: 'Poppins',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        paddingLeft: 14
                                    }}
                                        type='text'
                                        value={this.state.searchKeyword}
                                        onChange={(e: any) => this.handleSearch(e.target.value)}
                                        placeholder='Search Here'
                                    />
                                    <Search style={{ color: '#000000', width: 17, opacity: 0.57, height: 17, marginRight: 22 }} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sm={12} justify='space-between' alignItems='center' container={true} style={{
                            backgroundColor: '#f1f1f1',
                            height: 52,
                            borderRadius: 2,
                            paddingRight: 24,
                            paddingLeft: 16,
                        }} >
                            <Box display='flex' alignItems='center'>
                                {this.state.profileSourceList.data.length > 0 && (
                                    <Fragment>
                                        <Checkbox
                                            checked={canBeDeletedProfileSources.length > 0 ? canBeDeletedProfileSources.length === deletedProfileSources.length : false}
                                            onChange={() => this.handleAllCheck()}
                                            disabled={canBeDeletedProfileSources.length === 0}
                                            style={{ padding: 0, marginRight: 10 }}
                                            checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                                            icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                                        />
                                        <IconButton onClick={() => this.toggleConfirmationModalHandler()} disabled={deletedProfileSources.length === 0} style={{ padding: 0 }}>
                                            <DeleteOutline style={{ color: '#000000', width: 18, opacity: 0.6, height: 18 }} />
                                        </IconButton>
                                    </Fragment>
                                )}
                            </Box>

                            <Box display='flex' alignItems='center' >
                                <Typography style={{
                                    fontFamily: 'Poppins',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    color: 'rgba(0, 0, 0, 0.6)'
                                }} >Show</Typography>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={pagination.perPage}
                                    onChange={this.handlePerpageSelect}
                                    IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                                    classes={{ select: classes.selectContainer }}
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: 12,
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        borderRadius: 4,
                                        borderBottom: 'none',
                                        border: '1px solid #dddddd',
                                        backgroundColor: '#ffffff',
                                        width: 44,
                                        height: 28,
                                        marginRight: 6,
                                        marginLeft: 8,
                                        padding: 0
                                    }}
                                    disableUnderline={true}
                                    defaultValue={pagination.perPage}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>

                                <Typography style={{
                                    fontFamily: 'Poppins',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    marginRight: 12,
                                }} >{`Showing ${+pagination.totalCount === 0 ? 1 : pagination.currentPage} to ${+pagination.totalPages === 0 ? 1 : pagination.totalPages} of ${pagination.totalCount}`}</Typography>

                                <IconButton onClick={() => this.handlePagination(+pagination.currentPage - 1)} disabled={+pagination.currentPage === 1} style={{ padding: 0, opacity: +pagination.currentPage === 1 ? 0.2 : 1 }}>
                                    <ChevronLeft style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                                </IconButton>

                                <IconButton onClick={() => this.handlePagination(+pagination.currentPage + 1)} disabled={+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages} style={{ padding: 0, opacity: (+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages) ? 0.2 : 1 }}>
                                    <ChevronRight style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                                </IconButton>
                            </Box>


                        </Grid>
                        {!profileSourceList.loading && profileSourceList.data.length > 0 && (
                            <Grid sm={12} container={true} style={{ display: 'block' }} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead style={{ borderBottom: '1px solid rgba(221, 221, 221, 0.57)' }}>
                                        <TableRow>
                                            <TableCell >
                                                <Box display='flex' >
                                                    <Typography style={{
                                                        fontFamily: 'Roboto',
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        color: 'rgba(0, 0, 0, 0.6)'
                                                    }} >Sort By : </Typography>

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.order}
                                                        onChange={this.handleOrder}
                                                        IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                                                        classes={{ select: classes.selectContainer }}
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                            marginLeft: 5,
                                                            marginTop: 2,
                                                            padding: 0
                                                        }}
                                                        disableUnderline={true}
                                                        defaultValue={'default'}
                                                    >
                                                        <MenuItem value={'default'}>Default</MenuItem>
                                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                                    </Select>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {profileSourceList.data.map((profileSource: any, i: number) => (
                                            <TableRow style={{ backgroundColor: hoveredStatusIndex === `${i}` ? '#f1f1f1' : "#fcfcfc", }} onMouseOver={() => { this.handleRowHover(i.toString()) }} onMouseLeave={() => { this.handleRowHover("") }} key={profileSource.id}>
                                                <TableCell className={classes.heading} style={{ borderBottom: 'none' }} >
                                                    <Box display='flex' >
                                                        <Checkbox
                                                            checked={_.includes(deletedProfileSources, profileSource.attributes.id.toString())}
                                                            onChange={() => this.handleCheck(profileSource.attributes.id.toString())}
                                                            disabled={!profileSource.attributes.can_delete}
                                                            style={{ padding: 0, marginRight: 10 }}
                                                            checkedIcon={<CheckBox style={{ color: '#e45d33', width: 15, height: 15 }} />}
                                                            icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 15, height: 15 }} />}
                                                        />
                                                        <Typography className={classes.heading} >{profileSource.attributes.name.toUpperCase()}</Typography>
                                                    </Box>
                                                </TableCell>
                                                {hoveredStatusIndex === `${i}` && (
                                                    <TableCell className={classes.heading} style={{ borderBottom: 'none', paddingRight: 24 }} align='right' >
                                                        <Box display='flex' alignItems='center' justifyContent='flex-end' height='18px'>
                                                            <IconButton onClick={() => this.openModalHandler(profileSource.attributes)} style={{ padding: 0 }}>
                                                                <Icon>
                                                                    <img src={editIcon} style={{ width: 18, height: 18 }} />
                                                                </Icon>
                                                            </IconButton>
                                                            <IconButton onClick={() => this.updateProfileSource({ enabled: !profileSource.attributes.enabled }, profileSource.attributes.id)} style={{ padding: 0, marginLeft: 12 }}>
                                                                <Icon>
                                                                    <img src={profileSource.attributes.enabled ? showIcon : hideIcon} style={{ width: 18, height: 18 }} />
                                                                </Icon>
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        )}
                        {profileSourceList.loading && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='65%' >
                                <CircularProgress style={{ color: "#e45d33" }} />
                            </Box>
                        )}
                        {!profileSourceList.loading && profileSourceList.data.length === 0 && (
                            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='55%' >
                                <Typography style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: 'rgba(0, 0, 0, 0.6)'
                                }} >No records to display</Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
                <ProfileSourceModal
                    profileSource={this.state.profileSource}
                    showModal={this.state.showModal}
                    createUpdateLoading={this.state.createUpdateLoading}
                    closeModalHandler={this.closeModalHandler}
                    changeTextHandler={this.changeTextHandler}
                    showSwitchHandler={this.showSwitchHandler}
                    createAndUpdateHandler={this.createAndUpdateHandler}
                    validateProfileSourceName={this.validateProfileSourceName}
                />
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={showDeleteConfirmation}
                >
                    <DialogContent dividers>
                        <Typography style={{
                            fontFamily: 'Roboto',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.6)'
                        }} >{`Are you sure you want to delete ${deletedProfileSources.length > 1 ? 'these' : 'this'} profile source?`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus style={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => this.toggleConfirmationModalHandler()}>
                            No
                        </Button>
                        <Button style={{ color: '#e45d33' }} onClick={() => this.deleteProfileSource()} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box >
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: "9.5rem",
        paddingBottom: "7.8%",
        backgroundColor: "#fcfcfc",
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '85%',
        borderRadius: 2,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        overflow: 'hidden'
    },
    searchBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ffffff',
        height: 40,
        marginTop: 21,
        width: '100%',
        maxWidth: 414
    },
    selectContainer: {
        padding: '0px !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus': {
            backgroundColor: '#ffffff',
        }
    },
    clolumnHeading: {
        fontFamily: 'Poppins',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    heading: {
        fontFamily: 'Poppins',
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    }
});


export default withStyles(styles)(ProfileSource)
// Customizable Area End
