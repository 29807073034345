import React, { Fragment } from "react";
import "./barchart.css";


export default function BarChart(props) {

    var data = props.data ?? [];

    var largest = 0;

    data.forEach((item) => {
        if (item.value > largest) {
            largest = item.value;
        }
    });

    var yPoints = [0];
    var yPointGap = Math.ceil(largest / 8 / 10) * 10;
    //yPointGap = parseInt(String(yPointGap).charAt(0).padEnd(String(yPointGap).length, "0"));

    for (let i = 1; i < 9; i++) {
        yPoints.push(yPoints[i - 1] + yPointGap);
    }


    function getColor(index) {
        if (index === 0) {
            return "linear-gradient(#44bb1e, #207d0f)";
        } else if (index === 1) {
            return "linear-gradient(#f6d917, #9c750a)";
        } else {
            return "linear-gradient(#cb422d, #86170d)";
        }
    }

    return (
        <div style={{ padding: 25, backgroundColor: "#f1f1f1", width: "100%" }}>
            <div style={{ fontSize: 16, fontWeight: 500, color: "#1a181d", height: 35 }}>
                All Status
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: 50, marginRight: 10 }}>
                    {yPoints.reverse().map((item, index) => (
                        <div style={{ height: 40, color: "rgba(0, 0, 0, 0.6)", fontSize: 12, textAlign: "end", fontWeight: 500, display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <span>{item}</span>
                        </div>
                    ))}
                </div>
                <div style={{ width: "calc(100% - 60px)", borderLeft: "solid lightgray 1px", borderBottom: "solid lightgray 1px", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>

                    <div></div>
                    {
                        data.map((item, index) => (
                            <div style={{ backgroundImage: getColor(index), width: 70, height: (item.value * 40) / yPointGap, color: "white", fontWeight: 500, fontSize: 12, display: "flex", justifyContent: "center", alignItems: "flex-end" }}><span style={{ height: 30 }}>{item.value}</span></div>
                        ))
                    }
                    <div></div>
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <div style={{ width: 50, marginRight: 10 }}></div>
                <div style={{ width: "calc(100% - 60px)", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <div></div>
                    {
                        data.map((item, index) => (
                            <div style={{ color: "rgba(0, 0, 0, 0.6)", fontWeight: 500, fontSize: 12, marginTop: 10, width: 70, display: "flex", justifyContent: "center" }}><span style={{ height: 30, whiteSpace: "nowrap" }}>{item.name}</span></div>
                        ))
                    }
                    <div></div>

                </div>
            </div>
        </div>
    );

}