Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisedOrderStatus";
exports.labelBodyText = "CustomisedOrderStatus Body";

exports.statusListApiContentType = "application/json";
exports.statusListApiMethod = "GET";
exports.statusListApiEndPoint = "statusmanagement/statuses";

exports.activityListApiContentType = "application/json";
exports.activityListApiMethod = "GET";
exports.activityListApiEndPoint = "statusmanagement/statuses/activity_log";

exports.statusUpdateApiContentType = "application/json";
exports.statusUpdateApiMethod = "PUT";
exports.statusUpdateApiEndPoint = "statusmanagement/statuses/";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
