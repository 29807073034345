import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import _ from 'lodash';
import Storage from "../../../framework/src/StorageProvider.web";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: any;
    profileSource: any
    showModal: boolean
    closeModalHandler: any
    changeTextHandler: any
    showSwitchHandler: any
    createAndUpdateHandler: any
    validateProfileSourceName: any
    createUpdateLoading: boolean
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    categoriesArray: any;
    category: string;
    subCategory: string;
    isVisible: boolean;
    dropdownCategoryStatus: boolean;
    activeModalType: string;
    selectedCategoryID: any;
    hoveredStatusIndex: string;
    profileSourceList: {
        error: string | null,
        loading: boolean,
        data: any[]
    };
    pagination: {
        perPage: string
        currentPage: string
        totalPages: string
        totalCount: string
    }
    searchKeyword: string
    order: string
    canBeDeletedProfileSources: string[]
    deletedProfileSources: string[]
    profileSource: any
    showModal: boolean
    showDeleteConfirmation: boolean
    createUpdateLoading: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ProfileSourceController extends BlockComponent<
    Props,
    S,
    SS
> {
    profileSourceListApiCallMessageRequestId: any;
    profileSourceDeleteApiCallMessageRequestId: any;
    profileSourceUpdateApiCallMessageRequestId: any;
    profileSourceCreateApiCallMessageRequestId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            categoriesArray: [],
            category: "",
            subCategory: "",
            isVisible: false,
            dropdownCategoryStatus: false,
            activeModalType: "",
            selectedCategoryID: [],
            hoveredStatusIndex: "",
            profileSourceList: {
                error: null,
                loading: false,
                data: []
            },
            pagination: {
                perPage: '40',
                currentPage: '0',
                totalPages: '0',
                totalCount: '0'
            },
            searchKeyword: "",
            order: "default",
            canBeDeletedProfileSources: [],
            deletedProfileSources: [],
            profileSource: {},
            showModal: false,
            showDeleteConfirmation: false,
            createUpdateLoading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (this.profileSourceListApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.profileSourceListApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Profile Source Response : ', responseJson)
                this.setState({
                    profileSourceList: {
                        loading: false,
                        error: null,
                        data: responseJson.data
                    },
                    pagination: {
                        perPage: responseJson.meta.pagination.per_page,
                        currentPage: responseJson.meta.pagination.current_page,
                        totalPages: responseJson.meta.pagination.total_pages,
                        totalCount: responseJson.meta.pagination.total_count
                    },
                    canBeDeletedProfileSources: responseJson.data.filter((item: any) => item.attributes.can_delete).map((data: any) => data.attributes.id.toString()),
                    deletedProfileSources: []
                })
            } else {
                console.log('Profile Source List Error : ', responseJson)
                this.setState({
                    profileSourceList: {
                        loading: false,
                        error: JSON.stringify(responseJson?.errors),
                        data: []
                    }
                })
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);

        } else if (this.profileSourceDeleteApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.profileSourceDeleteApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Profile Source Delete Response : ', responseJson)
                this.getProfileSourceList({
                    page: this.state.pagination.currentPage,
                    perPage: this.state.pagination.perPage
                })
            } else {
                console.log('Profile Source Delete Error : ', responseJson)
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);
        } else if (this.profileSourceUpdateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.profileSourceUpdateApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Profile Source Update Response : ', responseJson)

                const profileSources = [...this.state.profileSourceList.data]
                const index = _.findIndex(profileSources, ['attributes.id', responseJson.data.attributes.id])

                profileSources[index] = responseJson.data

                this.setState({
                    profileSourceList: {
                        ...this.state.profileSourceList,
                        data: profileSources
                    },
                    createUpdateLoading: false,
                    showModal: false,
                    profileSource: {},
                    canBeDeletedProfileSources: profileSources.filter((item: any) => item.attributes.can_delete).map((data: any) => data.attributes.id.toString()),
                })

            } else {
                console.log('Profile Source Update Error : ', responseJson)
                this.setState({
                    createUpdateLoading: false
                })
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);
        } else if (this.profileSourceCreateApiCallMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.profileSourceCreateApiCallMessageRequestId) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data) {
                console.log('Profile Source Create Response : ', responseJson)

                this.getProfileSourceList({
                    page: this.state.pagination.currentPage,
                    perPage: this.state.pagination.perPage
                })

                this.setState({
                    createUpdateLoading: false,
                    showModal: false,
                    profileSource: {},
                })
            } else {
                console.log('Profile Source Create Error : ', responseJson)
                this.setState({
                    createUpdateLoading: false
                })
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    setCategoryTxt = (text: string) => {
        this.setState({ category: text });
    };
    setSubCategoryTxt = (text: string) => {
        this.setState({ subCategory: text });
    };
    clickCategory = (item: any, Index: number) => {
        let array = this.state.categoriesArray;
        let idarray = this.state.selectedCategoryID;
        let index = idarray.indexOf(item.attributes.id);

        if (index > -1) {
            idarray.splice(index, 1);
            array[Index].Check = false;
            this.setState({ categoriesArray: array });
        } else {
            idarray.push(item.attributes.id);
            array[Index].Check = true;
            this.setState({ categoriesArray: array });
            this.setState({ selectedCategoryID: idarray });
        }
    };

    toggleModal = (type: string) => {
        this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
    };

    expandCategoryView = () => {
        this.setState({
            dropdownCategoryStatus: !this.state.dropdownCategoryStatus
        });
    };
    expand = (id: string) => {
        let array = this.state.categoriesArray;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                array[i].expand = !array[i].expand;
            }
        }
        this.setState({ categoriesArray: array });
    };

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    getProfileSourceList = async (pagination: any, keyword: any = this.state.searchKeyword, order: any = this.state.order) => {
        try {
            this.setState((prevState: S) => ({
                profileSourceList: {
                    ...prevState.profileSourceList,
                    loading: true,
                }
            }))

            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.profileSourceListApiContentType,
                token: authToken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileSourceListApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.profileSourceListApiEndPoint}?page=${pagination.page}&per=${pagination.perPage}&search_keyword=${keyword}${order === 'default' ? '' : `&order_by=name&direction=${order}`}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.profileSourceListApiMethod ,
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            this.setState((prevState: S) => ({
                profileSourceList: {
                    ...prevState.profileSourceList,
                    loading: false,
                }
            }))
            console.log(error)
        }
    }

    deleteProfileSource = async () => {
        try {
            this.toggleConfirmationModalHandler();

            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.profileSourceDeleteApiContentType,
                token: authToken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileSourceDeleteApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.profileSourceDeleteApiEndPoint}?ids=${JSON.stringify(this.state.deletedProfileSources)}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.profileSourceDeleteApiMethod ,
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error)
        }
    }

    updateProfileSource = async (profileSource: any, profileSourceId: string) => {
        try {
            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.profileSourceUpdateApiContentType,
                token: authToken
            };

            const httpBody = { profile_source: profileSource };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileSourceUpdateApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.profileSourceUpdateApiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.profileSourceUpdateApiEndPoint + profileSourceId ,
            );

            console.log('requestMessage', requestMessage)

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error)
            this.setState({
                createUpdateLoading: false
            })
        }
    }

    createProfileSource = async (profileSource: any) => {
        try {
            const authToken = await Storage.get("authToken");

            const header = {
                "Content-Type": configJSON.profileSourceCreateApiContentType,
                token: authToken
            };

            const httpBody = { profile_source: profileSource };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.profileSourceCreateApiCallMessageRequestId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.profileSourceCreateApiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.profileSourceCreateApiEndPoint,
            );

            console.log('requestMessage', requestMessage)

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error)
            this.setState({
                createUpdateLoading: false
            })
        }
    }

    handlePerpageSelect = (e: any) => {
        this.getProfileSourceList({
            page: 1,
            perPage: e.target.value
        })
    }

    handlePagination = (page: any) => {
        this.getProfileSourceList({
            page,
            perPage: this.state.pagination.perPage
        })
    }

    handleSearch = (keyword: any) => {
        this.setState({ searchKeyword: keyword })
        this.getProfileSourceList({
            page: 1,
            perPage: this.state.pagination.perPage
        }, keyword)
    }

    handleOrder = (e: any) => {
        this.setState({ order: e.target.value })
        this.getProfileSourceList({
            page: 1,
            perPage: this.state.pagination.perPage
        }, this.state.searchKeyword, e.target.value)
    }

    handleRowHover = (index: string) => {
        this.setState({ hoveredStatusIndex: index })
    }

    handleAllCheck = () => {
        this.setState((prevState: S) => ({ deletedProfileSources: prevState.deletedProfileSources.length === prevState.canBeDeletedProfileSources.length ? [] : [...prevState.canBeDeletedProfileSources] }))
    }

    handleCheck = (profileSourceId: string) => {
        const deletedProfileSources = [...this.state.deletedProfileSources]
        const index = _.indexOf(deletedProfileSources, profileSourceId)

        if (index > -1) {
            deletedProfileSources.splice(index, 1)
        } else {
            deletedProfileSources.push(profileSourceId)
        }

        this.setState({ deletedProfileSources })
    }

    openModalHandler = (profileSource: any = {}) => {
        this.setState({ profileSource: { ...profileSource, isEdit: Object.values(profileSource).length > 0 }, showModal: true })
    }

    closeModalHandler = () => {
        this.setState({ profileSource: {}, showModal: false })
    }

    changeTextHandler = (value: string) => {
        this.setState({ profileSource: { ...this.state.profileSource, name: value } })
    }

    showSwitchHandler = (value: boolean) => {
        this.setState({ profileSource: { ...this.state.profileSource, enabled: value } })
    }

    createAndUpdateHandler = (profileSource: any) => {
        this.setState({
            createUpdateLoading: true
        })
        if (profileSource.isEdit) {
            this.updateProfileSource({
                "name": profileSource?.name || '',
                "enabled": profileSource?.enabled || false
            }, profileSource.id)
        } else {
            this.createProfileSource({
                "name": profileSource?.name || '',
                "enabled": profileSource?.enabled || false
            })
        }
    }

    validateProfileSourceName = () => {
        let profileSourceList = _.cloneDeep(this.state.profileSourceList.data)
        profileSourceList = profileSourceList.filter((item: any) => this.state.profileSource?.name ? item.attributes.name.toLowerCase() === this.state.profileSource.name.trim().toLowerCase() : false)
        return this.state.profileSource?.isEdit ? (profileSourceList.length === 1 && +this.state.profileSource?.id !== +profileSourceList[0].id) : profileSourceList.length === 1
    }

    toggleConfirmationModalHandler = () => {
        this.setState((prevState: S) => ({
            showDeleteConfirmation: !prevState.showDeleteConfirmation
        }))
    }
    // Customizable Area End
}
