//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React, { Fragment } from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { FaPlus, FaTimes } from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import Storage from "../../../../framework/src/StorageProvider.web";
import { Formik } from 'formik';
import moment from "moment";
//@ts-ignore
import _ from "lodash";

import * as Utilities from "../../../../framework/src/Utilities";
import './style.css';
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import Select from 'react-select'
//@ts-ignore
import classnames from "classnames";

import TextField from '@material-ui/core/TextField';
import AdditionalDetailFormController, {
  Props
} from "./AdditionalDetailFormController";


export default class BasicInformationForm extends AdditionalDetailFormController {
  constructor(props: Props) {
    super(props);
  }


  async componentDidMount() {
    super.componentDidMount();
    this.fetchEmploymentHistory();
  }

  fetchEmploymentHistory() {
    this.props.parent.setState({ loading: true }, () => {
      this.getEmploymentHistory();
    });
  }

  onPressNext() {


    if (this.state.employmentHistory.find((item) => item.edit == true) && !(this.state.employmentHistory.length > 1)) {
      this.showAlert("",
        "Aleast one employment record is required", "OK");
      return;
    } else if (!(this.state.employmentHistory.length > 0)) {
      this.showAlert("",
        "Aleast one employment record is required", "OK");
      return
    }

    this.props.parent.setState({ loading: true }, () => this.updateProfile({ step: 3 }));
    //this.props.parent.setState({ step: 4, isStep3Completed: true });
  }

  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.email) && this.state.email != "";
    const isPasswordValid = this.state.password != "";
    const isStep1Complete = true;
    const isStep2Complete = false;
    const isStep3Complete = false;
    const isStep4Complete = false;

    return (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <TouchableOpacity style={[styles.button, { marginRight: 80 }]} onPress={() => Utilities.logoutUser("force", () => this.goToLogin())}><Text style={styles.buttonText}>LOG OUT</Text></TouchableOpacity>
        </View>
        <View style={{ minHeight: "calc( 100vh - 116px )", width: "100%", flexDirection: "row" }}>
          <View style={[styles.leftContainer]}>

            <View >
              <Text style={styles.rightTitle}>Employment History</Text>
              <Text style={styles.rightSubTitle}>Before you looking for job, we need to know some information to get to know you.</Text>

              <Text style={styles.formLabel}>Employment Details</Text>



              <View>


                {this.state.employmentHistory.map<React.ReactNode>((item: any, index: number) => (
                  <View key={"form-" + index}>



                    {item.edit ?

                      <Formik
                        initialValues={{
                          "company_logo": item.attributes.company_logo || "",
                          "job_title": item.attributes.job_title,
                          "employment_type": _.lowerCase((item.attributes.employment_type ?? "_")).replace(/ /g, "_"),
                          "company": item.attributes.company,
                          "start_date": moment(item.attributes.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                          "end_date": moment(item.attributes.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        }}
                        onSubmit={(values) => { this.props.parent.setState({ loading: true }, () => item.id ? this.updateEmploymentHistory(values, item.id) : this.addEmploymentHistory(values)); }}
                        validationSchema={this.getEmploymentHistoryFormValidations}
                      >
                        {({ values, handleChange, errors, setFieldTouched, setFieldValue, touched, isValid, handleSubmit }) => {


                          const fileInputRef: any = React.createRef();
                          const onBrowseClick = () => {
                            fileInputRef.current.click()
                          }
                          const onDrop = (files: any) => {
                            onAddFiles(files);
                          }
                          const onFileInputChange = (event: any) => {
                            const { files } = event.target;
                            onAddFiles(files);
                          }
                          const onAddFiles = (newFiles: Array<File>) => {

                            if (newFiles.length > 0) {

                              console.log("Filesext ", newFiles[0]);
                              if (!newFiles[0].type.includes("image/")) {
                                this.showAlert(
                                  "",
                                  "Only Images are allowed",
                                  "OK"
                                );
                                return;
                              }
                              if (newFiles[0].size > 10485760) {
                                this.showAlert(
                                  "",
                                  "Max file size allowed is 10mb",
                                  "OK"
                                );
                                return;
                              }

                              setFieldValue("company_logo", newFiles[0]);
                            }
                          }

                          var fileName = ""
                          if (typeof values.company_logo === "string") {
                            if (values.company_logo.lastIndexOf("/") && values.company_logo.lastIndexOf("/") + 1 < values.company_logo.length) {
                              fileName = values.company_logo.substring(values.company_logo.lastIndexOf("/") + 1);
                            }
                          }

                          return (
                            <Fragment>

                              {values.company_logo ?
                                <View style={[styles.formRow, { marginTop: 15, marginBottom: 30 }]}>
                                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                      <Image source={typeof values.company_logo === "string" ? { uri: values.company_logo } : { uri: URL.createObjectURL(values.company_logo) }} resizeMode={"cover"} style={styles.company_logo} />
                                      <Text style={{ marginLeft: 10, fontWeight: "500" }}>{typeof values.company_logo === "string" ? fileName : values.company_logo.name}</Text>
                                    </View>
                                    <TouchableOpacity onPress={() => setFieldValue("company_logo", "")}>
                                      <FaTimes style={styles.historyContainerIcons} />
                                    </TouchableOpacity>
                                  </View>
                                </View>

                                :

                                <View style={[styles.formRow, { marginTop: 15 }]}>
                                  <View style={[styles.inputWrapper, { width: "100%" }]} >
                                    <TextField onChange={(e) => null} value={"Upload Company Logo"} variant={"outlined"} disabled={true} label={""} error={!!(errors.company_logo && touched.company)} helperText={touched.company_logo && errors.company_logo} style={styles.uploadTextInput} className={"upload-input"} />
                                    <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                                      <TouchableOpacity onPress={() => onBrowseClick()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40, width: 120, marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]} >UPLOAD LOGO</Text></TouchableOpacity>
                                    </View>
                                  </View>
                                  <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: "none" }}
                                    multiple={false}
                                    value=""
                                  />
                                </View>
                              }

                              <View style={[styles.formRow]} >
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="job_title" disabled={!item.edit} onChange={handleChange} onBlur={() => setFieldTouched('job_title')} variant={"outlined"} label={"Most Recent Job Title"} error={!!(errors.job_title && touched.job_title)} helperText={touched.job_title && errors.job_title} style={styles.plainTextInput} value={values.job_title} />
                                </View>
                              </View>

                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={false}
                                    placeholder="Employment Type"
                                    onChange={(option: any) => setFieldValue("employment_type", option.value)}
                                    value={values.employment_type ? { value: values.employment_type, label: _.capitalize(_.replace((values.employment_type ?? ""), /_/g, " ")) } : undefined}

                                    options={[
                                      { value: 'full_time', label: 'Full Time' },
                                      { value: 'part_time', label: 'Part Time' },
                                      { value: 'freelancer', label: 'Freelancer' },
                                      { value: 'remote', label: 'Remote' }
                                    ]}
                                    className={classnames("", { "select-error-input": touched.employment_type && errors.employment_type })}

                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.employment_type && errors.employment_type &&
                                      <Text style={[styles.textInputErrorMessage, { position: "relative", marginBottom: 20 }]}>{errors.employment_type}</Text>
                                    }
                                  </View>
                                </View>
                              </View>

                              <View style={styles.formRow}>
                                <View style={[styles.inputWrapper, { width: "100%" }]} >
                                  <TextField name="company" disabled={!item.edit} onChange={handleChange} onBlur={() => setFieldTouched('company')} variant={"outlined"} label={"Most Recent Company"} error={!!(errors.company && touched.company)} helperText={touched.company && errors.company} style={styles.plainTextInput} value={values.company} />
                                </View>
                              </View>

                              <View style={styles.formRow}>
                                <View style={styles.inputWrapper} >
                                  <TextField name="start_date" disabled={!item.edit} type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('start_date')} variant={"outlined"} label={"Working From"} error={!!(errors.start_date && touched.start_date)} helperText={touched.start_date && errors.start_date} style={styles.plainTextInput} value={values.start_date} />
                                </View>

                                <View style={styles.inputWrapper} >
                                  <TextField name="end_date" disabled={!item.edit} type={"date"} InputLabelProps={{ shrink: true }} onChange={handleChange} onBlur={() => setFieldTouched('end_date')} variant={"outlined"} label={"Working To"} error={!!(errors.end_date && touched.end_date)} helperText={touched.end_date && errors.end_date} style={styles.plainTextInput} value={values.end_date} />
                                </View>
                              </View>

                              {item.edit ?
                                <View style={{ width: "100%", flexDirection: "row" }}>
                                  <TouchableOpacity onPress={() => handleSubmit()} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>SAVE</Text></TouchableOpacity>
                                  <TouchableOpacity onPress={() => this.onCancelEmploymentHistory(index)} style={[styles.smallButton]}><Text style={[styles.buttonText]}>CANCEL</Text></TouchableOpacity>
                                </View>
                                :
                                <View style={{ width: "100%", flexDirection: "row" }}>
                                  <TouchableOpacity onPress={() => this.onEditEmploymentHistory(index)} style={[styles.smallButton, { backgroundColor: "#e45d33", borderColor: "#e45d33", marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>EDIT</Text></TouchableOpacity>
                                  <TouchableOpacity onPress={() => this.props.parent.setState({ loading: true }, () => this.deleteEmploymentHistory(item.id))} style={[styles.smallButton]}><Text style={[styles.buttonText]}>DELETE</Text></TouchableOpacity>
                                </View>
                              }

                              <View style={{ height: 20, width: "100%" }} />
                            </Fragment>
                          )
                        }
                        }
                      </Formik>
                      :
                      <View>
                        <View style={[styles.textInputContainer, { height: 100, flexDirection: "row", padding: 15 }]} >
                          {typeof item.attributes?.company_logo === "string" &&
                            <View style={{ flex: 0.15, height: "100%", justifyContent: "center", alignItems: "center" }}>
                              <Image source={{ uri: item.attributes?.company_logo }} resizeMode={"cover"} style={{ height: "100%", width: "100%" }} />
                            </View>
                          }
                          <View style={{ flex: 1, height: "100%", justifyContent: "space-between", marginLeft: 12 }}>
                            <View>
                              <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 14, fontWeight: "bold" }}>{item.attributes?.job_title}</Text>
                              <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 11, marginTop: 4, fontWeight: "bold" }}>{item.attributes?.company} | {item.attributes?.employment_type && (item.attributes?.employment_type)}</Text>
                            </View>
                            <Text style={{ color: "gray", fontSize: 11, marginTop: 10 }}>{moment(item.attributes?.start_date, 'DD-MM-YYYY').format('MMM YYYY')} to {moment(item.attributes?.end_date, 'DD-MM-YYYY').format('MMM YYYY')} {item.attributes.duration && "(" + item.attributes.duration + ")"}</Text>

                          </View>
                          <View style={{ flex: 0.15, height: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                            <TouchableOpacity onPress={() => this.onEditEmploymentHistory(index)} >
                              <BsPencil style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.props.parent.setState({ loading: true }, () => this.deleteEmploymentHistory(item.id))}>
                              <AiOutlineDelete style={styles.historyContainerIcons} />
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View style={{ height: 20, width: "100%" }} />

                      </View>

                    }



                  </View>
                ))
                }
                <View style={{ borderTopWidth: 2, borderStyle: "dashed", borderColor: "#ededed", marginTop: 10 }} />

                <View>
                  <TouchableOpacity onPress={() => this.onAddMoreEmploymentHistory()} style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}><Text style={[styles.buttonText, { color: "#e45d33" }]}><FaPlus /> ADD MORE</Text></TouchableOpacity>
                </View>
              </View>
            </View>


            <View style={styles.bottomContainer}>
              <TouchableOpacity onPress={() => { this.props.parent.setState({ step: 2 }); }} style={styles.button}><Text style={styles.buttonText}>PREVIOUS STEP</Text></TouchableOpacity>
              <TouchableOpacity onPress={() => this.onPressNext()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>NEXT STEP</Text></TouchableOpacity>
            </View>
          </View>
          <View style={styles.rightContainer}>
            <Image source={assets.rightPeople} style={styles.rightImage} resizeMode="contain" />
          </View>
        </View>
      </View>
    );
  }

}

// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "calc(100% - 116px)"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  smallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 120,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    height: 116,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center"

  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    paddingBottom: 15
  }
  ,
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 15,
    width: 15,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  uploadTextInput: {
    width: "100%",
    marginBottom: 18,
    color: "silver"
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  }
  ,
  leftContainer: {
    width: "70%",
    minHeight: "100%",
    justifyContent: "space-between",
    paddingHorizontal: "8%"
  },
  rightContainer: {
    width: "30%",
    minHeight: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  rightImage: {
    width: "21vw",
    height: "21vw"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  historyContainerIcons: {
    fontSize: 20,
    color: "black"
  },
  company_logo: {
    height: 48,
    width: 48
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {
    leftContainer: {
      paddingHorizontal: "4%"
    }
  }),
  // below 1200
  maxWidth(1200, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {

    leftContainer: {
      paddingHorizontal: "4%",
      width: "100%"
    }
  }),
  // below  626
  maxWidth(626, {
    leftContainer: {
      paddingHorizontal: "2%",
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);


const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14
    }
  }
}
