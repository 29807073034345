//@ts-nocheck
import React, { Fragment } from 'react';
import {
  Dimensions,
  PixelRatio,
  View,
  Text,
  FlatList,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  CheckBox,
  Platform,
  Image,
  TextInput,
  Picker,
  ActivityIndicator,
  Alert,
  ImageBackground
} from 'react-native';
//@ts-ignore

import { createStyles, maxWidth } from 'react-native-media-queries';
import { Formik, FieldArray, Field } from 'formik';
import { grey } from '@material-ui/core/colors';

import TextField from '@material-ui/core/TextField';
import classnames from "classnames";
import Select from 'react-select';
import { BsPencil } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import MUIRichTextEditor from "mui-rte";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import csc from 'country-state-city';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import { FaPlus, FaTimes, FaCheck } from "react-icons/fa";
import { FileDrop } from 'react-file-drop';
import { MdCloudUpload } from "react-icons/md";
import { RiUploadCloudLine } from "react-icons/ri";
import JobPreview from "./JobPostModal/JobPreview.web";
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { formBottomImage } from './assets';
import { Editor, EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import "./index.css";

// Customizable Area End

import SearchStrategyController, {
  Props,
  // configJSON
} from "./SearchStrategyController.web";

export default class SearchStrategyForm extends SearchStrategyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // async componentDidMount() {
  //   this.getFunctionalAreas()
  //   this.getCourseList();
  //   this.getPostGraduationList();
  //   this.getDoctorateList();
  //   this.getSearchStrategyDetails();
  // }

  setToggleValue = (e: any) => {
    const { values, setFieldValue } = this.props
    const setValue = values.post_preferences_attributes.find(item => item.name === e.target.name)
    console.log("setValue", setValue)
    setFieldValue("role_dynamic", setValue.value = e.target.value)
  }

  render() {
    const styles = getStyles();
    const { isFormLoading } = this.state
    const {
      values,
      handleChange,
      errors,
      setFieldTouched,
      setFieldValue,
      touched,
      handleSubmit,
      resetForm,
      data,
      functionalAreaList,
      courses,
      postGraduationList,
      doctorateList,
      basicDetail,
      onBrowseClick,
      onFileInputChange,
      onDrop,
      fileInputRef,
      preSelectedRequiredSkill
    } = this.props

    return (
      <>
        <View style={[styles.container]}>
          <View onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            if (this.props.onChangeLayout) {
              this.props.onChangeLayout(height)
            };
          }} >
            <View style={[styles.topContainer]}>
              <View style={styles.topContainerRadioButton}>
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <View style={{ flexDirection: "row", alignItems: "center", padding: 9, paddingLeft: 0 }} >
                    <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                      <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                    </View>
                    <Text style={[styles.stepText]}>Job Details</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <p style={{ margin: 0, marginLeft: 10, marginRight: 10, color: "rgb(126, 126, 126)" }}>-------</p>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center", padding: 9 }} >
                    <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                      <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                    </View>
                    <Text style={[styles.stepText]}>Posting Details</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <p style={{ margin: 0, marginLeft: 10, marginRight: 10, color: "rgb(126, 126, 126)" }}>-------</p>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center", padding: 9 }} >
                    <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                      <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                    </View>
                    <Text style={[styles.stepText]}>Search Strategy</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={[styles.stepIconContainer, styles.stepIconContainerActive, { height: 13, width: 13, marginRight: 15, marginLeft: 15 }]} />
                    <CloseIcon />
                  </View>
                </RadioGroup>
              </View>

              <View style={[styles.topContainerButton, { flexDirection: "row" }]}>
                <TouchableOpacity
                  onPress={() => {
                    { window.location = '/JobRequirements' }
                  }}
                  style={[styles.button, { marginRight: "16px", minWidth: "150px", boxShadow: "none", borderWidth: "0px" }]}
                >
                  <Text style={styles.buttonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleSubmit()}
                  style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", paddingHorizontal: 16, marginRight: "24px" }]}
                >
                  <Text style={[styles.buttonText, { color: "white" }]}>
                    Next Step
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={[styles.leftContainer]}>
                <Text style={[styles.rightTitle, { marginTop: 70 }]}>Search Strategy</Text>
                <Text style={styles.rightSubTitle}>Lorem ipsumdemo content here.</Text>
                <div>
                  {isFormLoading ? 'fetching data...' : (
                    <View key={"form-"}>
                      <Fragment>
                        <div id={"brief"} style={{ scrollMargin: 100 }}>
                          <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Brief</Text>
                                <View style={styles.switchButton}>
                                  <AntSwitch
                                    name={`post_preferences_attributes.${0}.value`}
                                    checked={values[`post_preferences_attributes.${0}.value`]}
                                    onChange={handleChange}
                                  />
                                </View>
                                <Text style={styles.subText}>Add to Job Post</Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                          </View>
                          <MUIRichTextEditor
                            label="Type something here..."
                            // onSave={save}
                            defaultValue={values.brief}
                            onChange={(data) => {
                              var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                              if (values.brief !== json) {
                                this.state.editorData["brief"] = json;
                              }
                            }}
                            onFocus={() => setFieldTouched('brief', false, false)}
                            onBlur={() => { setFieldValue("brief", this.state.editorData["brief"]); setFieldTouched('brief'); }}
                            inlineToolbar={true}
                            controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                          />
                          {/*
                                <View style={styles.formRow}>
                                  <View style={[styles.inputWrapper, { width: "100%" }]} >
                                    <TextField
                                      variant={"outlined"}
                                      onChange={handleChange}
                                      value={values.brief}
                                      name={"brief"}
                                      onBlur={() => setFieldTouched('brief')}
                                      error={!!(errors.brief && touched.brief)}
                                      helperText={touched.brief && errors.brief}
                                      label="Type Here"
                                      style={styles.plainTextInput}
                                      multiline
                                      rows={4}
                                    />
                                  </View>
                                </View>
                                */}
                        </div>

                        <div id={"role_dynamics"} style={{ scrollMargin: 100 }}>
                          <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Role Dynamics</Text>
                                <View style={styles.switchButton}>
                                  <AntSwitch
                                    name={`post_preferences_attributes.${1}.value`}
                                    checked={values[`post_preferences_attributes.${1}.value`]}
                                    onChange={handleChange}
                                  />
                                </View>
                                <Text style={styles.subText}>Add to Job Post</Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                          </View>
                          <MUIRichTextEditor
                            label="Type something here..."
                            // onSave={save}
                            defaultValue={values.role_dynamics}
                            onChange={(data) => {
                              var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                              if (values.role_dynamics !== json) {
                                this.state.editorData["role_dynamics"] = json;
                              }
                            }}
                            onFocus={() => setFieldTouched('role_dynamics', false, false)}
                            onBlur={() => { setFieldValue("role_dynamics", this.state.editorData["role_dynamics"]); setFieldTouched('role_dynamics'); }}
                            inlineToolbar={true}
                            controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                          />
                          {/*
                                <View style={styles.formRow}>
                                  <View style={[styles.inputWrapper, { width: "100%" }]} >
                                    <TextField
                                      name="role_dynamics"
                                      value={values.role_dynamics}
                                      variant={"outlined"}
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched('role_dynamics')}
                                      error={!!(errors.role_dynamics && touched.role_dynamics)}
                                      helperText={touched.role_dynamics && errors.role_dynamics}
                                      label="Type Here"
                                      style={styles.plainTextInput}
                                      multiline
                                      rows={4}
                                    />
                                  </View>
                                </View>
                                */}
                        </div>

                        <div id={"required_skill_ids"} style={{ scrollMargin: 100 }}>
                          <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Required candidate skills</Text>
                                <View style={styles.switchButton}>
                                  <AntSwitch />
                                </View>
                                <Text style={styles.subText}>Add to Job Post</Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            <Text style={styles.belowText}>Let's make it memorable</Text>
                          </View>
                          <View style={styles.formRow}>
                            <View style={[styles.inputWrapper, { width: "100%" }]} >
                              <Select
                                styles={selectMultipleStyle}
                                isSearchable={true}
                                isMulti
                                placeholder="Type here"
                                options={data}
                                defaultValue={preSelectedRequiredSkill}
                                name="required_skill_ids"
                                onChange={(e: any) => setFieldValue("required_skill_ids", Array.isArray(e) ? e.map(x => x.value) : [])}
                                onFocus={() => setFieldTouched('required_skill_ids', false, false)}
                                onBlur={() => setFieldTouched('required_skill_ids')}
                                className={classnames("", { "select-error-input": touched.required_skill_ids && errors.required_skill_ids })}
                              />
                              <View style={styles.textInputErrorMessageContainer}>
                                {touched.required_skill_ids && errors.required_skill_ids &&
                                  <Text style={styles.textInputErrorMessage}>{errors.required_skill_ids}</Text>
                                }
                              </View>
                            </View>
                          </View>
                        </div>

                        <div id={"basic_detail"} style={{ scrollMargin: 100 }}>
                          <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Target Pool</Text>
                                <View style={styles.switchButton}>
                                  <AntSwitch
                                    name={`post_preferences_attributes.${2}.value`}
                                    checked={values[`post_preferences_attributes.${2}.value`]}
                                    onChange={handleChange}
                                  />
                                </View>
                                <Text style={styles.subText}>Add to Job Post</Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            <Text style={styles.belowText}>Lorem ipsumdemo content here.</Text>
                          </View>
                          <Accordion
                            style={{ marginBottom: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Basic Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Formik
                                initialValues={basicDetail}
                                onSubmit={(values) => {
                                  console.log("===", values)
                                  this.setState({ basicDetailObj: values, showJobDetailsSubAccordian: false });
                                  setFieldValue('search_in', values.search_in)
                                  setFieldValue("all_keywords", values.all_keywords)
                                  setFieldValue("any_keywords", values.any_keywords)
                                  setFieldValue("exclude_keywords", values.exclude_keywords)
                                }}
                                validationSchema={() => this.getBasicDetailValidation()}

                              >
                                {({ values, handleChange, errors, submitForm, setFieldTouched, setFieldValue: setInnerFieldValue, touched, isValid, handleSubmit, resetForm }) => {
                                  return (
                                    <Fragment>


                                      <View style={{ width: "100%", padding: "0 0 0 16px" }}>
                                        <View style={[styles.formRow, { width: "100%" }]}>
                                          <View style={{ width: "100%", justifyContent: "center" }} >
                                            <TextField
                                              value={values.fff}
                                              name="fff"
                                              onChange={handleChange}
                                              variant={"outlined"}
                                              // disabled={this.state.otpVerified}
                                              label="Type Keyword"
                                              style={{ ...styles.plainTextInput, ...{ marginBottom: 0 } }}
                                              onBlur={() => setFieldTouched('fff')}
                                              error={!!(errors.fff && touched.fff)}
                                              helperText={touched.fff && errors.fff}
                                            />
                                            <TouchableOpacity style={{ position: "absolute", right: 10 }} onPress={() => this.setState({ showJobDetailsSubAccordian: !this.state.showJobDetailsSubAccordian })}>
                                              <Typography style={{ margin: "auto", marginInlineEnd: -5, color: "coral" }}>EXPAND <ExpandIcon style={{ verticalAlign: "sub" }} /></Typography>
                                            </TouchableOpacity>

                                          </View>
                                        </View>
                                        <div style={{ fontSize: 11, display: "flex" }}>
                                          <View>
                                            <p>Search in</p>
                                          </View>
                                          <Radio
                                            checked={values.search_in === 'entire_resume'}
                                            value="entire_resume"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'entire_resume' }}
                                            style={{ width: "10px", marginLeft: "10px" }}
                                            onChange={(event: any) => setInnerFieldValue('search_in', event.target.value)}
                                          />
                                          <View>
                                            <p>Entire Resume</p>
                                          </View>
                                          <Radio
                                            checked={values.search_in === 'resume_title_and_key_skill'}
                                            value="resume_title_and_key_skill"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'resume_title_and_key_skill' }}
                                            style={{ width: "10px", marginLeft: "10px" }}
                                            onChange={(event: any) => setInnerFieldValue('search_in', event.target.value)}
                                          />
                                          <View>
                                            <p>Resume Title & Key Skills</p>
                                          </View>
                                          <Radio
                                            checked={values.search_in === 'resume_synopsis'}
                                            value="resume_synopsis"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'resume_synopsis' }}
                                            style={{ width: "10px", marginLeft: "10px" }}
                                            onChange={(event: any) => setInnerFieldValue('search_in', event.target.value)}
                                          />
                                          <View>
                                            <p>Resume Synopsis</p>
                                          </View>
                                          <Radio
                                            checked={values.search_in === 'resume_title'}
                                            value="resume_title"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'resume_title' }}
                                            style={{ width: "10px", marginLeft: "10px" }}
                                            onChange={(event: any) => setInnerFieldValue('search_in', event.target.value)}
                                          />
                                          <View>
                                            <p>Resume Title</p>
                                          </View>
                                        </div>

                                        <CustomAccordion
                                          expanded={this.state.showJobDetailsSubAccordian}
                                        >
                                          {/* <AccordionHeader
                                            expandIcon={<ExpandIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Typography>Type Keyword</Typography>
                               
                                          </AccordionHeader>
                                    */}

                                          <View>

                                          </View>

                                          <DetailsBox style={{ marginTop: 10 }}>
                                            <View style={[styles.formRow, { width: "100%" }]}>
                                              <View style={{ width: "100%" }} >
                                                <TextField
                                                  value={values.any_keywords}
                                                  name="any_keywords"
                                                  onChange={handleChange}
                                                  variant={"outlined"}
                                                  // disabled={this.state.otpVerified}
                                                  label="Any of Keywords"
                                                  style={styles.plainTextInput}
                                                  onBlur={() => setFieldTouched('any_keywords')}
                                                  error={!!(errors.any_keywords && touched.any_keywords)}
                                                  helperText={touched.any_keywords && errors.any_keywords}
                                                />
                                              </View>
                                            </View>
                                          </DetailsBox>
                                          <DetailsBox>
                                            <View style={[styles.formRow, { width: "100%" }]}>
                                              <View style={{ width: "100%" }} >
                                                <TextField
                                                  value={values.all_keywords}
                                                  name="all_keywords"
                                                  onChange={handleChange}
                                                  variant={"outlined"}
                                                  // disabled={this.state.otpVerified}
                                                  label="All Keywords"
                                                  style={styles.plainTextInput}
                                                  onBlur={() => setFieldTouched('all_keywords')}
                                                  error={!!(errors.all_keywords && touched.all_keywords)}
                                                  helperText={touched.all_keywords && errors.all_keywords}
                                                />
                                              </View>
                                            </View>
                                          </DetailsBox>
                                          <DetailsBox>
                                            <View style={[styles.formRow, { width: "100%" }]}>
                                              <View style={{ width: "100%" }} >
                                                <TextField
                                                  value={values.exclude_keywords}
                                                  name="exclude_keywords"
                                                  onChange={handleChange}
                                                  variant={"outlined"}
                                                  // disabled={this.state.otpVerified}
                                                  label="Exclude Keywords"
                                                  style={styles.plainTextInput}
                                                  onBlur={() => setFieldTouched('exclude_keywords')}
                                                  error={!!(errors.exclude_keywords && touched.exclude_keywords)}
                                                  helperText={touched.exclude_keywords && errors.exclude_keywords}
                                                />
                                              </View>
                                            </View>
                                          </DetailsBox>
                                          <DetailsBox>
                                            <TouchableOpacity
                                              onPress={() => handleSubmit()}
                                              style={[styles.button, { marginRight: "5%", backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40 }]}
                                            >
                                              <Text style={[styles.buttonText, { color: "white" }]}>
                                                Done
                                              </Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                              onPress={() => {
                                                //resetForm();

                                                setInnerFieldValue("any_keywords", "");
                                                setInnerFieldValue("all_keywords", "");
                                                setInnerFieldValue("exclude_keywords", "");
                                                this.setState({ showJobDetailsSubAccordian: !this.state.showJobDetailsSubAccordian });
                                              }}
                                              style={[styles.button, { marginRight: "1%", height: 40, marginLeft: 40 }]}
                                            >
                                              <Text style={styles.buttonText}>Cancel</Text>
                                            </TouchableOpacity>
                                          </DetailsBox>
                                        </CustomAccordion>
                                      </View>
                                    </Fragment>
                                  )
                                }}
                              </Formik>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: 24 }}>
                              <View>
                                <p>Total Experience in Years</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <TextField
                                  value={values.experience_min}
                                  type="number"
                                  name="experience_min"
                                  variant={"outlined"}
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched('experience_min')}
                                  error={!!(errors.experience_min && touched.experience_min)}
                                  helperText={touched.experience_min && errors.experience_min}
                                  label="Minimum"
                                  style={styles.plainTextInput}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.experience_max}
                                  name="experience_max"
                                  type="number"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  onBlur={() => setFieldTouched('experience_max')}
                                  error={!!(errors.experience_max && touched.experience_max)}
                                  helperText={touched.experience_max && errors.experience_max}
                                  label="Maximum"
                                  style={styles.plainTextInput}
                                />
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Aunnal Salary:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "fit-content" }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="₹"
                                  options={[
                                    { value: "inr", label: "₹" },
                                    { value: "usd", label: "$" },
                                    { value: "eur", label: "€" },
                                    { value: "jpy", label: "¥" },
                                  ]}
                                  name="currency"
                                  defaultValue={{ value: "inr", label: "₹" }}
                                  onChange={(option: any) => setFieldValue('basic_detail', option.value)}
                                  onBlur={() => setFieldTouched('currency')}
                                  className={classnames("", { "select-error-input": touched.currency && errors.currency })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.currency && errors.currency &&
                                    <Text style={styles.textInputErrorMessage}>{errors.currency}</Text>
                                  }
                                </View>
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 13, width: "44%" }]}  >
                                <TextField
                                  value={values.annual_salary_min}
                                  // type="number"
                                  name="annual_salary_min"
                                  variant={"outlined"}
                                  onChange={handleChange}
                                  label="Minimum Salary"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('annual_salary_min')}
                                  error={!!(errors.annual_salary_min && touched.annual_salary_min)}
                                  helperText={touched.annual_salary_min && errors.annual_salary_min}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 13, width: "44%" }]}  >
                                <TextField
                                  value={values.annual_salary_max}
                                  name="annual_salary_max"
                                  type="number"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Maximum Salary"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('annual_salary_max')}
                                  error={!!(errors.annual_salary_max && touched.annual_salary_max)}
                                  helperText={touched.annual_salary_max && errors.annual_salary_max}
                                />
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 7px", marginBottom: -5, marginTop: -25 }}>
                              <Checkbox
                                checked={values.include_profile}
                                name='include_profile'
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                onChange={(event: any) => setFieldValue('include_profile', event.target.checked)}
                              />
                              <View style={{ fontSize: 12 }}>
                                <p>Include profiles who entered zero salary or have not mentioned their CTC.</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Candidate Current Location:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.current_location}
                                    name="current_location"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('current_location')}
                                    error={!!(errors.current_location && touched.current_location)}
                                    helperText={touched.current_location && errors.current_location}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, marginTop: -25 }}>
                              <View style={{ fontSize: 12 }}>
                                <p>Specify candidate's preferred locations</p>
                              </View>
                            </AccordionDetails>
                          </Accordion>


                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Search Candidates In</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: 5 }}>
                              <View>
                                <p>Requirements:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.requirements}
                                    name="requirements"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('requirements')}
                                    error={!!(errors.requirements && touched.requirements)}
                                    helperText={touched.requirements && errors.requirements}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Status:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.status}
                                    name="status"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('status')}
                                    error={!!(errors.status && touched.status)}
                                    helperText={touched.status && errors.status}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Profile Sources:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.profile_source}
                                    name="profile_source"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('profile_source')}
                                    error={!!(errors.profile_source && touched.profile_source)}
                                    helperText={touched.profile_source && errors.profile_source}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Comments:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.comments}
                                    name="comments"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('comments')}
                                    error={!!(errors.comments && touched.comments)}
                                    helperText={touched.comments && errors.comments}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Employment Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: 5 }}>
                              <View>
                                <p>Functional Areas and Roles:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="Choose Functional Area"
                                  options={functionalAreaList}
                                  name="functional_area_id"
                                  defaultInputValue={values.functional_area_id}
                                  onChange={(option: any) => setFieldValue('functional_area_id', option.value)}
                                  onBlur={() => setFieldTouched('functional_area_id')}
                                  className={classnames("", { "select-error-input": touched.functional_area_id && errors.functional_area_id })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.functional_area_id && errors.functional_area_id &&
                                    <Text style={styles.textInputErrorMessage}>{errors.functional_area_id}</Text>
                                  }
                                </View>
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.role}
                                  name="role"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Choose Role"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('role')}
                                  error={!!(errors.role && touched.role)}
                                  helperText={touched.role && errors.role}
                                />
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Industry Type:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <TextField
                                    value={values.industry_type}
                                    name="industry_type"
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    // disabled={this.state.otpVerified}
                                    label="Type here"
                                    style={styles.plainTextInput}
                                    onBlur={() => setFieldTouched('industry_type')}
                                    error={!!(errors.industry_type && touched.industry_type)}
                                    helperText={touched.industry_type && errors.industry_type}
                                  />
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Employers:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "32%" }]} >
                                <TextField
                                  value={values.employer_type}
                                  name="employer_type"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Type here"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('employer_type')}
                                  error={!!(errors.employer_type && touched.employer_type)}
                                  helperText={touched.employer_type && errors.employer_type}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="Current Employer"
                                  options={[
                                    { value: "current_employer", label: "Current Employer" },
                                    { value: "previous_employer", label: "Previous Employer" },
                                    { value: "current_previous", label: "Current/Previous" }
                                  ]}
                                  defaultInputValue={values.current_employer}
                                  name="current_employer"
                                  onChange={(option: any) => setFieldValue('current_employer', option.value)}
                                  onBlur={() => setFieldTouched('current_employer')}
                                  className={classnames("", { "select-error-input": touched.current_employer && errors.current_employer })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.current_employer && errors.current_employer &&
                                    <Text style={styles.textInputErrorMessage}>{errors.current_employer}</Text>
                                  }
                                </View>
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="All Words"
                                  options={[
                                    { value: "all_words", label: "All words" },
                                    { value: "any_words", label: "Any words" },
                                    { value: "exact_phrase", label: "Exact Phrase" },
                                    { value: "boolean", label: "Boolean" }
                                  ]}
                                  defaultInputValue={values.employer_all_words}
                                  name="employer_all_words"
                                  onChange={(option: any) => setFieldValue('employer_all_words', option.value)}
                                  onBlur={() => setFieldTouched('employer_all_words')}
                                  className={classnames("", { "select-error-input": touched.employer_all_words && errors.employer_all_words })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.employer_all_words && errors.employer_all_words &&
                                    <Text style={styles.textInputErrorMessage}>{errors.employer_all_words}</Text>
                                  }
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Exculed Employers:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "32%" }]} >
                                <TextField
                                  value={values.exclude_employer_type}
                                  name="exclude_employer_type"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Type here"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('exclude_employer_type')}
                                  error={!!(errors.exclude_employer_type && touched.exclude_employer_type)}
                                  helperText={touched.exclude_employer_type && errors.exclude_employer_type}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="Current Employer"
                                  options={[
                                    { value: "current_employer", label: "Current Employer" },
                                    { value: "previous_employer", label: "Previous Employer" },
                                    { value: "current_previous", label: "Current/Previous" }
                                  ]}
                                  defaultInputValue={values.exclude_current_employer}
                                  name="exclude_current_employer"
                                  onChange={(option: any) => setFieldValue('exclude_current_employer', option.value)}
                                  onBlur={() => setFieldTouched('exclude_current_employer')}
                                  className={classnames("", { "select-error-input": touched.exclude_current_employer && errors.exclude_current_employer })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.exclude_current_employer && errors.exclude_current_employer &&
                                    <Text style={styles.textInputErrorMessage}>{errors.exclude_current_employer}</Text>
                                  }
                                </View>
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="All Words"
                                  options={[
                                    { value: "all_words", label: "All words" },
                                    { value: "any_words", label: "Any words" },
                                    { value: "exact_phrase", label: "Exact Phrase" },
                                    { value: "boolean", label: "Boolean" }
                                  ]}
                                  defaultInputValue={values.exclude_employer_all_words}
                                  name="exclude_employer_all_words"
                                  onChange={(option: any) => setFieldValue('exclude_employer_all_words', option.value)}
                                  onBlur={() => setFieldTouched('exclude_employer_all_words')}
                                  className={classnames("", { "select-error-input": touched.exclude_employer_all_words && errors.exclude_employer_all_words })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.exclude_employer_all_words && errors.exclude_employer_all_words &&
                                    <Text style={styles.textInputErrorMessage}>{errors.exclude_employer_all_words}</Text>
                                  }
                                </View>
                              </View>
                            </AccordionDetails>

                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Designation:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "32%" }]} >
                                <TextField
                                  value={values.designation_type_her}
                                  name="designation_type_her"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  label="Type here"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('designation_type_her')}
                                  error={!!(errors.designation_type_her && touched.designation_type_her)}
                                  helperText={touched.designation_type_her && errors.designation_type_her}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="Current Employer"
                                  options={[
                                    { value: "current_employer", label: "Current Employer" },
                                    { value: "previous_employer", label: "Previous Employer" },
                                    { value: "current_previous", label: "Current/Previous" }
                                  ]}
                                  defaultInputValue={values.designation_current_employer}
                                  name="designation_current_employer"
                                  onChange={(option: any) => setFieldValue('designation_current_employer', option.value)}
                                  onBlur={() => setFieldTouched('designation_current_employer')}
                                  className={classnames("", { "select-error-input": touched.designation_current_employer && errors.designation_current_employer })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.designation_current_employer && errors.designation_current_employer &&
                                    <Text style={styles.textInputErrorMessage}>{errors.designation_current_employer}</Text>
                                  }
                                </View>
                              </View>
                              <View style={[styles.inputWrapper, { width: "32%", marginLeft: 9 }]} >
                                <Select
                                  styles={selectStyles}
                                  isSearchable={true}
                                  placeholder="All Words"
                                  options={[
                                    { value: "all_words", label: "All words" },
                                    { value: "any_words", label: "Any words" },
                                    { value: "exact_phrase", label: "Exact Phrase" },
                                    { value: "boolean", label: "Boolean" }
                                  ]}
                                  defaultInputValue={values.designation_all_words}
                                  name="designation_all_words"
                                  onChange={(option: any) => setFieldValue('designation_all_words', option.value)}
                                  onBlur={() => setFieldTouched('designation_all_words')}
                                  className={classnames("", { "select-error-input": touched.designation_all_words && errors.designation_all_words })}
                                />
                                <View style={styles.textInputErrorMessageContainer}>
                                  {touched.designation_all_words && errors.designation_all_words &&
                                    <Text style={styles.textInputErrorMessage}>{errors.designation_all_words}</Text>
                                  }
                                </View>
                              </View>
                            </AccordionDetails>

                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14 }}>
                              <View>
                                <p>Noticed Period:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Select in months"
                                    options={[
                                      { value: "less_than_one_month", label: "Less than 1 month" },
                                      { value: "one_month", label: "1 Month" },
                                      { value: "two_months", label: "2 Months" },
                                      { value: "three_months", label: "3 Months" }
                                    ]}
                                    defaultInputValue={values.notice_period}
                                    name="notice_period"
                                    onChange={(option: any) => setFieldValue('notice_period', option.value)}
                                    onBlur={() => setFieldTouched('notice_period')}
                                    className={classnames("", { "select-error-input": touched.notice_period && errors.notice_period })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.notice_period && errors.notice_period &&
                                      <Text style={styles.textInputErrorMessage}>{errors.notice_period}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Educational Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: 5 }}>
                              <View>
                                <p>UG Qualification:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Any UG Qualification"
                                    options={courses}
                                    defaultValue={courses.find(({ value }) => value == values.ug_qualification_any)}
                                    name="ug_qualification_any"
                                    onChange={(option: any) => setFieldValue('ug_qualification_any', option.value)}
                                    onBlur={() => setFieldTouched('ug_qualification_any')}
                                    className={classnames("", { "select-error-input": touched.ug_qualification_any && errors.ug_qualification_any })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.ug_qualification_any && errors.ug_qualification_any &&
                                      <Text style={styles.textInputErrorMessage}>{errors.ug_qualification_any}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Either UG and PG qualification will be enforced"
                                    options={[
                                      { value: "test", label: "test" }
                                    ]}
                                    defaultInputValue={values.ug_qualification_enforced}
                                    name="ug_qualification_enforced"
                                    onChange={(option: any) => setFieldValue('ug_qualification_enforced', option.value)}
                                    onBlur={() => setFieldTouched('ug_qualification_enforced')}
                                    className={classnames("", { "select-error-input": touched.ug_qualification_enforced && errors.ug_qualification_enforced })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.ug_qualification_enforced && errors.ug_qualification_enforced &&
                                      <Text style={styles.textInputErrorMessage}>{errors.ug_qualification_enforced}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>PG Qualification:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Any PG Qualification"
                                    options={postGraduationList}
                                    defaultValue={postGraduationList.find(({ value }) => value == values.pg_qualification_any)}
                                    name="pg_qualification_any"
                                    onChange={(option: any) => setFieldValue('pg_qualification_any', option.value)}
                                    onBlur={() => setFieldTouched('pg_qualification_any')}
                                    className={classnames("", { "select-error-input": touched.pg_qualification_any && errors.pg_qualification_any })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.pg_qualification_any && errors.pg_qualification_any &&
                                      <Text style={styles.textInputErrorMessage}>{errors.pg_qualification_any}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Either UG and PG qualification will be enforced"
                                    options={[
                                      { value: "test", label: "test" }
                                    ]}
                                    defaultInputValue={values.pg_qualification_enforced}
                                    name="pg_qualification.ug_pg"
                                    onChange={(option: any) => setFieldValue('pg_qualification.ug_pg', option.value)}
                                    onBlur={() => setFieldTouched('pg_qualification.ug_pg')}
                                    className={classnames("", { "select-error-input": touched.pg_qualification_enforced && errors.pg_qualification_enforced })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.pg_qualification_enforced && errors.pg_qualification_enforced &&
                                      <Text style={styles.textInputErrorMessage}>{errors.pg_qualification_enforced}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>PPG Qualification:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Any PPG Qualification"
                                    options={doctorateList}
                                    defaultValue={doctorateList.find(({ value }) => value == values.ppg_qualification_any)}
                                    name="ppg_qualification_any"
                                    onChange={(option: any) => setFieldValue('ppg_qualification_any', option.value)}
                                    onBlur={() => setFieldTouched('ppg_qualification_any')}
                                    className={classnames("", { "select-error-input": touched.ppg_qualification_any && errors.ppg_qualification_any })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.ppg_qualification_any && errors.ppg_qualification_any &&
                                      <Text style={styles.textInputErrorMessage}>{errors.ppg_qualification_any}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Either UG and PG qualification will be enforced"
                                    options={[
                                      { value: "test", label: "test" }
                                    ]}
                                    defaultInputValue={values.ppg_qualification_enforced}
                                    name="ppg_qualification_enforced"
                                    onChange={(option: any) => setFieldValue('ppg_qualification_enforced', option.value)}
                                    onBlur={() => setFieldTouched('ppg_qualification_enforced')}
                                    className={classnames("", { "select-error-input": touched.ppg_qualification_enforced && errors.ppg_qualification_enforced })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.ppg_qualification_enforced && errors.ppg_qualification_enforced &&
                                      <Text style={styles.textInputErrorMessage}>{errors.ppg_qualification_enforced}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Additional Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Resume Processed By:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ fontSize: 14 }}>
                              <Radio
                                checked={values.resume_processed_by === "anyone"}
                                value="anyone"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'anyone' }}
                                onChange={(event: any) => setFieldValue('resume_processed_by', event.target.value)}
                              />
                              <View>
                                <p>Anyone</p>
                              </View>
                              <Radio
                                checked={values.resume_processed_by === "me"}
                                value="me"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'me' }}
                                style={{ paddingLeft: 30 }}
                                onChange={(event: any) => setFieldValue('resume_processed_by', event.target.value)}
                              />
                              <View>
                                <p>Me</p>
                              </View>
                              <Radio
                                checked={values.resume_processed_by === "specific"}
                                value="specific"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'specific' }}
                                style={{ paddingLeft: 30 }}
                                onChange={(event: any) => setFieldValue('resume_processed_by', event.target.value)}
                              />
                              <View>
                                <p>Specific</p>
                              </View>
                            </AccordionDetails>

                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Star Rating:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <TextField
                                  value={values.star_rating_from}
                                  type="number"
                                  name="star_rating_from"
                                  variant={"outlined"}
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched('star_rating_from')}
                                  error={!!(errors.star_rating_from && touched.star_rating_from)}
                                  helperText={touched.star_rating_from && errors.star_rating_from}
                                  label="From"
                                  style={styles.plainTextInput}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.star_rating_to}
                                  type="number"
                                  name="star_rating_to"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="To"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('star_rating_to')}
                                  error={!!(errors.star_rating_to && touched.star_rating_to)}
                                  helperText={touched.star_rating_to && errors.star_rating_to}
                                />
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Candidate Age:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <TextField
                                  value={values.candidate_age_from}
                                  name="candidate_age_from"
                                  variant={"outlined"}
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched('candidate_age_from')}
                                  error={!!(errors.candidate_age_from && touched.candidate_age_from)}
                                  helperText={touched.candidate_age_from && errors.candidate_age_from}
                                  // disabled={this.state.otpVerified}
                                  label="From"
                                  style={styles.plainTextInput}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.candidate_age_to}
                                  name="candidate_age_to"
                                  type="number"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="To"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('candidate_age_to')}
                                  error={!!(errors.candidate_age_to && touched.candidate_age_to)}
                                  helperText={touched.candidate_age_to && errors.candidate_age_to}
                                />
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Work Status For:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <TextField
                                  value={values.work_status_for_usa}
                                  name="work_status_for_usa"
                                  variant={"outlined"}
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched('work_status_for_usa')}
                                  error={!!(errors.work_status_for_usa && touched.work_status_for_usa)}
                                  helperText={touched.work_status_for_usa && errors.work_status_for_usa}
                                  // disabled={this.state.otpVerified}
                                  label="Type Here"
                                  style={styles.plainTextInput}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.work_status_for_other_country}
                                  name="work_status_for_other_country"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Other Countries"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('work_status_for_other_country')}
                                  error={!!(errors.work_status_for_other_country && touched.work_status_for_other_country)}
                                  helperText={touched.work_status_for_other_country && errors.work_status_for_other_country}
                                />
                              </View>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Affirmative Actions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <View style={[styles.formRow, { width: "100%" }]}>
                                <View style={{ width: "100%" }} >
                                  <Select
                                    styles={selectStyles}
                                    isSearchable={true}
                                    placeholder="Select List From"
                                    options={[
                                      { value: "test", label: "test" }
                                    ]}
                                    defaultInputValue={values.affirmative_action_value_id}
                                    name="affirmative_action_value_id"
                                    onChange={(option: any) => setFieldValue('affirmative_action_value_id', option.value)}
                                    onBlur={() => setFieldTouched('affirmative_action_value_id')}
                                    className={classnames("", { "select-error-input": touched.affirmative_action_value_id && errors.affirmative_action_value_id })}
                                  />
                                  <View style={styles.textInputErrorMessageContainer}>
                                    {touched.affirmative_action_value_id && errors.affirmative_action_value_id &&
                                      <Text style={styles.textInputErrorMessage}>{errors.affirmative_action_value_id}</Text>
                                    }
                                  </View>
                                </View>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 7px", marginBottom: -5, marginTop: -20 }}>
                              <Checkbox
                                checked={values.search_women_candidate_only}
                                name="search_women_candidate_only"
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                onChange={(event: any) => setFieldValue('search_women_candidate_only', event.target.checked)}
                              />
                              <View style={{ fontSize: 12 }}>
                                <p>Search women candidates only.</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 7px", marginBottom: -5, marginTop: -20 }}>
                              <Checkbox
                                checked={values.search_candidate_special_ability}
                                name="search_candidate_special_ability"
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                onChange={(event: any) => setFieldValue('search_candidate_special_ability', event.target.checked)}
                              />
                              <View style={{ fontSize: 12 }}>
                                <p>Search candidates with special abilities only.</p>
                              </View>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: 0 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                            >
                              <Typography>Display Options</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Show Candidates Seeking:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ fontSize: 11 }}>
                              <Radio
                                checked={values.show_candidate_seeking === "any_job"}
                                value={"any_job"}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'any_job' }}
                                onChange={(event: any) => setFieldValue('show_candidate_seeking', event.target.value)}
                              />
                              <View>
                                <p>Any job</p>
                              </View>
                              <Radio
                                checked={values.show_candidate_seeking === "permanent_job"}
                                value={"permanent_job"}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'permanent_job' }}
                                onChange={(event: any) => setFieldValue('show_candidate_seeking', event.target.value)}
                                style={{ paddingLeft: 30 }}
                              />
                              <View>
                                <p>Premanent job</p>
                              </View>
                              <Radio
                                checked={values.show_candidate_seeking === "temporary_or_contract_job"}
                                value={"temporary_or_contract_job"}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'temporary_or_contract_job' }}
                                style={{ paddingLeft: 30 }}
                                onChange={(event: any) => setFieldValue('show_candidate_seeking', event.target.value)}
                              />
                              <View>
                                <p>Temporary/Contract job</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Employment Type</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ fontSize: 11 }}>
                              <Radio
                                checked={values.employment_type == 'any'}
                                value={'any'}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'any' }}
                                onChange={(event: any) => setFieldValue('employment_type', event.target.value)}
                              />
                              <View>
                                <p>Any</p>
                              </View>
                              <Radio
                                checked={values.employment_type == 'full_time'}
                                value={'full_time'}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'full_time' }}
                                style={{ paddingLeft: 30 }}
                                onChange={(event: any) => setFieldValue('employment_type', event.target.value)}
                              />
                              <View>
                                <p>Full Time</p>
                              </View>
                              <Radio
                                checked={values.employment_type == 'part_time'}
                                value={'part_time'}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'part_time' }}
                                style={{ paddingLeft: 30 }}
                                onChange={(event: any) => setFieldValue('employment_type', event.target.value)}
                              />
                              <View>
                                <p>Part Time</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Show Candidates only with</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, marginTop: -10, flexWrap: 'wrap' }}>
                              <Box display='flex' >
                                <Checkbox
                                  checked={values.show_cand_mobile_no}
                                  name="show_cand_mobile_no"
                                  color="default"
                                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                                  style={{ width: 10 }}
                                  onChange={(event: any) => setFieldValue('show_cand_mobile_no', event.target.checked)}
                                />
                                <View style={{ fontSize: 14, marginRight: 5 }}>
                                  <p>Verified mobile no.</p>
                                </View>
                              </Box>
                              <Box display='flex' >
                                <Checkbox
                                  checked={values.show_cand_email_id}
                                  name="show_cand_email_id"
                                  color="default"
                                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                                  style={{ width: 10 }}
                                  onChange={(event: any) => setFieldValue('show_cand_email_id', event.target.checked)}
                                />
                                <View style={{ fontSize: 14, marginRight: 5 }}>
                                  <p>Verified email-id.</p>
                                </View>
                              </Box>
                              <Box display='flex' >
                                <Checkbox
                                  checked={values.show_cand_attached_resume}
                                  name="show_cand_attached_resume"
                                  color="default"
                                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                                  style={{ width: 10 }}
                                  onChange={(event: any) => setFieldValue('show_cand_attached_resume', event.target.checked)}
                                />
                                <View style={{ fontSize: 14, marginRight: 5 }}>
                                  <p>Attached Resume.</p>
                                </View>
                              </Box>
                              <Box display='flex' >
                                <Checkbox
                                  checked={values.show_cand_search_only_premium_resume}
                                  name="show_cand_search_only_premium_resume"
                                  color="default"
                                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                                  style={{ width: 10 }}
                                  onChange={(event: any) => setFieldValue('show_cand_search_only_premium_resume', event.target.checked)}
                                />
                                <View style={{ fontSize: 14, marginRight: 5 }}>
                                  <p>Search only Premium Resume.</p>
                                </View>
                              </Box>
                            </AccordionDetails>
                            <AccordionDetails style={{ padding: "0 0 0 16px", marginBottom: -5, fontSize: 14, marginTop: -10 }}>
                              <View>
                                <p>Sort By:</p>
                              </View>
                              <View style={{ paddingLeft: "43%" }}>
                                <p>Resume per page:</p>
                              </View>
                            </AccordionDetails>
                            <AccordionDetails>
                              <View style={[styles.inputWrapper, { width: "50%" }]}  >
                                <TextField
                                  value={values.sort_by_relevance}
                                  name="sort_by_relevance"
                                  variant={"outlined"}
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched('sort_by_relevance')}
                                  error={!!(errors.sort_by_relevance && touched.sort_by_relevance)}
                                  helperText={touched.sort_by_relevance && errors.sort_by_relevance}
                                  // disabled={this.state.otpVerified}
                                  label="Relevance"
                                  style={styles.plainTextInput}
                                />
                              </View>
                              <View style={[styles.inputWrapper, { marginLeft: 8, width: "48%" }]}  >
                                <TextField
                                  value={values.resume_per_page_relevance}
                                  name="resume_per_page_relevance"
                                  onChange={handleChange}
                                  variant={"outlined"}
                                  // disabled={this.state.otpVerified}
                                  label="Relevance"
                                  style={styles.plainTextInput}
                                  onBlur={() => setFieldTouched('resume_per_page_relevance')}
                                  error={!!(errors.resume_per_page_relevance && touched.resume_per_page_relevance)}
                                  helperText={touched.resume_per_page_relevance && errors.resume_per_page_relevance}
                                />
                              </View>
                            </AccordionDetails>
                          </Accordion>

                        </div>

                        <div id={"conclusion"} style={{ scrollMargin: 100 }}>
                          <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontWeight: "500", fontSize: 20 }}>Conclusion</Text>
                                <View style={styles.switchButton}>
                                  <AntSwitch
                                    name={`post_preferences_attributes.${3}.value`}
                                    checked={values[`post_preferences_attributes.${3}.value`]}
                                    onChange={handleChange}
                                  />
                                </View>
                                <Text style={styles.subText}>Add to Job Post</Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                          </View>
                          <MUIRichTextEditor
                            label="Type something here..."
                            // onSave={save}
                            defaultValue={values.conclusion}
                            onChange={(data) => {
                              var json = JSON.stringify(convertToRaw(data.getCurrentContent()));
                              if (values.conclusion !== json) {
                                this.state.editorData["conclusion"] = json;
                              }
                            }}
                            onFocus={() => setFieldTouched("conclusion", false, false)}
                            onBlur={() => { setFieldValue("conclusion", this.state.editorData["conclusion"]); setFieldTouched("conclusion"); }}
                            inlineToolbar={true}
                            controls={["bold", 'italic', "underline", "numberList", "bulletList"]}
                          />
                          {/*
                                <View style={styles.formRow}>
                                  <View style={[styles.inputWrapper, { width: "100%" }]} >
                                    <TextField
                                      // onChange={(e) => this.setState({ otpCode: e.target.value })}
                                      value={values.conclusion}
                                      name="conclusion"
                                      variant={"outlined"}
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched('conclusion')}
                                      error={!!(errors.conclusion && touched.conclusion)}
                                      helperText={touched.conclusion && errors.key_performance_indicators}
                                      // disabled={this.state.otpVerified}
                                      label="Type Here"
                                      style={styles.plainTextInput}
                                      multiline
                                      rows={4}
                                    />
                                  </View>
                                </View>
                                */}
                        </div>

                        <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                          <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                              <Text style={{ fontWeight: "500", fontSize: 20 }}>Upload File</Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ height: 250, width: "100%", marginBottom: 20 }}>
                          <FileDrop onDrop={onDrop} style={styles.fileDrop}>
                            <div>
                              <RiUploadCloudLine style={{ fontSize: 52, marginTop: 10 }} />

                              <div style={{ color: "#1c1920", marginTop: 10, fontSize: 14 }}>
                                Click here to browse or drag and <br />drop a file<br /><br />
                                <TouchableOpacity onPress={onBrowseClick} style={[styles.button, { marginHorizontal: "auto" }]} ><Text style={styles.buttonText}>BROWSE</Text></TouchableOpacity>
                              </div>
                            </div>
                            <div style={styles.text}>
                              File can be an image, doc, audio or video <br /> format. The maximum file-size is 10 MB.
                            </div>
                          </FileDrop>
                          <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            multiple
                            onClick={(event) => {
                              event.target.value = null
                            }}
                          />
                        </View>
                        {
                          this.state.files &&
                          this.state.files.map((item) => {
                            return (
                              <View style={styles.bottomContainer}>
                                <FilterNoneIcon />
                                <Text style={{ color: "red", position: "absolute", marginLeft: "5%" }}>{item?.name}</Text>
                                <TouchableOpacity onPress={() => this.removeFile(item)}>
                                  <CloseIcon />
                                </TouchableOpacity>
                              </View>
                            )
                          })
                        }
                        <div style={{ display: "flex", marginBottom: -10 }}>
                          <View style={styles.bottomContainer}>
                            <TouchableOpacity
                              style={styles.button}
                              onPress={() => {
                                { window.location = '/JobRequirements' }
                              }}
                            >
                              <Text style={styles.buttonText}>CANCEL</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => handleSubmit()}
                              style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>NEXT STEP</Text></TouchableOpacity>
                          </View>
                        </div>


                      </Fragment>
                    </View>
                  )}
                </div>
              </View>
            </View>
          </View >
          <div style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: "relative",
            alignItems: "flex-end",
            paddingRight: "38px"
          }}>
            <img src={formBottomImage.default} style={{ position: "absolute", bottom: "-40px" }} />
          </div>
        </View >
      </>
    );
  }
}

// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    padding: "12px",
    paddingTop: "95px",
    paddingLeft: "70px",
    paddingBottom: "40px"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  fileDrop: {
    width: "100%",
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    borderRadius: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    textAlign: "center",
    height: "-webkit-fill-available",
    minHeight: "15rem",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 44,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    textTransform: "uppercase"
  },
  smallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 120,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "40px"
  },
  topContainerButton: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: "13px",
    // position: "absolute",
    // marginTop: "-174px",
    // left: "64rem",
  },
  topContainerRadioButton: {
    // marginLeft: "100px",
    color: "rgb(126, 126, 126)",
  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    paddingBottom: 15
  }
  ,
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgb(126, 126, 126)",
    fontSize: "1rem",
    fontWeight: "400",
    letterSpacing: "0.00938em"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerNotActive: {
    height: 25,
    width: 25,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepAdditionalIconContainerActive: {
    height: 20,
    width: 20,
    // backgroundColor: "#ffffff",
    borderWidth: 2,
    borderColor: "#299c14",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepIconContainerActive: {
    height: 25,
    width: 25,
    backgroundColor: "#299c14",
    borderWidth: 1,
    borderColor: "#299c14",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 17,
    color: "green"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  uploadTextInput: {
    width: "100%",
    marginBottom: 18,
    color: "silver"
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  }
  ,
  leftContainer: {
    width: "70%",
    minHeight: "100%",
    justifyContent: "space-between",
    // paddingLeft: "100px"
  },
  rightContainer: {
    width: "39%",
    minHeight: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  rightImage: {
    width: "80%",
    height: "50%",
    marginBottom: "3%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  historyContainerIcons: {
    fontSize: 20,
    color: "black"
  },
  company_logo: {
    height: 48,
    width: 48
  },
  subText: {
    marginLeft: 10,
    color: "rgb(126, 126, 126)",
    wordSpacing: 3,
  },
  switchButton: {
    marginLeft: 30,
  },
  belowText: {
    color: "rgb(126, 126, 126)",
    fontSize: 16,
    marginBottom: 20,
    marginTop: -20,
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {
    leftContainer: {
      width: "95%",
      paddingHorizontal: "4%"
    }
  }),
  // below 1200
  maxWidth(1200, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {

    leftContainer: {
      paddingHorizontal: "4%",
      width: "100%"
    }
  }),
  // below  626
  maxWidth(626, {
    leftContainer: {
      paddingHorizontal: "2%",
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);

const AntSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    '&$checked': {
      color: "rgb(245 123 34 / 84%)",
      marginLeft: -10,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: "white",
      borderColor: "rgb(245 123 34 / 84%)",
    },
  },
  checked: {},
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: 13,
    width: 25,
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
})(Switch);

const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none",
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 14,
    }
  },
}

const selectMultipleStyle = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  multiValue: (provided: any, state: any) => {
    return {
      ...provided,
      // marginTop: "11%",
      border: `1px solid ${grey[500]}`,
      backgroundColor: "white !important",
      borderRadius: 3
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      position: "fixed !important",
    }
  },
  input: (provided: any, state: any) => {
    return {
      ...provided,
      // margin: 0,
      // position: "absolute",
      // marginBottom: "11%",
      // marginLeft: "-11%",
    }
  },
  // placeholder: (provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     position: "relative",
  //     // marginBottom: 20,
  //     display:'content'
  //   }
  // },
  // valueContainer:(provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     display:'content'
  //   }  }

}

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    border: "none",
    '&$expanded': {
      backgroundColor: "white",
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CustomAccordion = withStyles({
  root: {
    border: "none",
    marginBottom: -18,
    width: "100%",
    boxShadow: "none",
  }
})(Accordion)

const AccordionHeader = withStyles({
  root: {
    border: "1px solid #80808047",
  },
  '&$expanded': {
    border: "1px solid #80808047",
    borderRadius: 6,
  }
})(MuiAccordionSummary)

const ExpandIcon = withStyles({
  root: {
    color: "coral",
  }
})(ExpandMoreIcon)

const DetailsBox = withStyles({
  root: {
    padding: 0
  }
})(AccordionDetails)