import React, { Fragment } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  CheckBox,
} from "react-native";
// Customizable Area End

import RecruiterConsoleController, {
  Props,
  configJSON,
} from "./RecruiterConsoleController";
//@ts-ignore
import { createStyles, maxWidth } from "react-native-media-queries";
import { Theme } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import TextField from "@material-ui/core/TextField";
import {
  HiCheck,
  HiLocationMarker,
  HiOutlineUser,
  HiX,
  HiEye,
  HiEyeOff,
  HiOutlinePencil,
  HiOutlineArrowNarrowLeft,
} from "react-icons/hi";

import Avatar from "@material-ui/core/Avatar";
import { MdSend } from "react-icons/md";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import map from "lodash/map";

import {
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
  Icon,
  CircularProgress,

  Backdrop,
  Fade,
} from "@material-ui/core";
import { CodeSharp } from "@material-ui/icons";

import Modal from "react-modal";
Modal.setAppElement("#root");
import { Formik, Form, Field, ErrorMessage } from 'formik';
import csc from 'country-state-city';

export default class RecruiterConsole extends RecruiterConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  handleActions(value: any) {
    this.setState({ showActions: value });
  }

  handleOpen = (modal: any, id: any) => {
    if (modal === "createModal") {
      this.setState({ createModal: true });

    }
    if (modal === "previewModal") {
      this.setState({ previewModal: true });
      this.setState({ previewId: id })
      this.setState({
        previewArray: this.state.recruiterDetailArray.filter((admin: any) => {
          return admin.id === id
        })
      })

    }
    if (modal === 'importModal') {
      this.setState({ importModal: true })
    }
    if (modal === 'editModal') {
      this.setState({ editModal: true });
      this.setState({
        previewArray: this.state.recruiterDetailArray.filter((admin: any) => {
          return admin.id === id
        })
      })
    }
  };

  handleClose = (modal: any) => {
    if (modal === "createModal") {
      this.setState({ createModal: false });
      this.setState({ editModal: false })
    }
    if (modal === "previewModal") {
      this.setState({ previewModal: false });
    }
    if (modal === 'importModal') {
      this.setState({ importModal: false })
    }
    if (modal === 'editModal') {
      this.setState({ editModal: false })
    }
  };

  render() {
    const styles = getStyles();

    var allCountryList = csc.getAllCountries();
    var allStateList = csc.getAllStates();
    var allCityList = csc.getAllCities();
    let states: any = {}
    let cities: any = {}
    let countries: any = {}
    let stateNames: any = {}
    for (let index = 0; index < allCountryList.length; index++) {
      const element: any = allCountryList[index];
      countries[element.name] = element.isoCode
    }
    for (let index = 0; index < allStateList.length; index++) {
      const element: any = allStateList[index];
      stateNames[element.name] = element.isoCode
    }

    for (let index = 0; index < allStateList.length; index++) {
      const element: any = allStateList[index];
      if (!states[element.countryCode]) {
        states[element.countryCode] = []
        states[element.countryCode].push(element)
      }
      else {
        states[element.countryCode] = [element, ...states[element.countryCode]]
      }
    }

    for (let index = 0; index < allCityList.length; index++) {
      const element: any = allCityList[index];
      if (!cities[element.stateCode]) {
        cities[element.stateCode] = []
        cities[element.stateCode].push(element)
      }
      else {
        cities[element.stateCode] = [element, ...cities[element.stateCode]]
      }
    }

    return (

      <div className="main-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <section className="main-table-wrapper">
                <div className="top-section flex-between">
                  <div className="headings">
                    <h1 className="mb-1">Manage Recruiter</h1>
                    <p>
                      Lorem Ipsume demo contetn should be here so we can see
                      how it works.
                    </p>
                  </div>
                  <div className="search-links">
                    <div className="links mb-1">
                      <button className="link"
                        onClick={() => {
                          this.handleOpen("importModal", 0);
                        }}>
                        IMPORT/EXPORT
                      </button>
                      <button className="link"
                        onClick={() => {
                          this.handleOpen("createModal", 0);
                        }}
                      >
                        CREATE
                      </button>
                    </div>
                    <div className="search-box">
                      <input
                        type="search"
                        id="search"
                        className="input"
                        // value={this.state?.search_keyword||""}
                        // onChange={this.onChangeHandler}
                        required />
                      <label htmlFor="search">Search Here</label>
                      <img
                        className="search-icon"
                        src={require("../assets/search-icon.svg")}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="table-nav">
                  <div className="flex-center">
                    <label className="checkbox-lbl">
                      <input type="checkbox" checked={this.state.allChecked} onClick={this.selectAllHandler} />
                      <span className="checkmark"></span>
                    </label>
                    <button className="link" disabled={this.state.isDisableOrEnable} onClick={() => { this.setState({ deleteOpen: true }) }}>
                      <img
                        className="delete-item ml-1"
                        src={require("../assets/delete-icon.png")}
                        alt="Icon"
                      />
                    </button>
                  </div>
                  <div className="record-counter flex-center">
                    <p>Show</p>
                    <select name="record" id="record" className="select-options">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>

                    <p>Showing {this.state.paginationDetails.current_page} to {this.state.paginationDetails.total_pages} of {this.state.paginationDetails.total_count}</p>
                    {/* {console.log("this.state.paginationDetails.prev_page",this.state.paginationDetails.prev_page)} */}
                    <div className="prev-page page-nav" ><button className="icon-btn" onClick={this.prevPageHandler} disabled={this.state.paginationDetails.prev_page === null ? true : false} ><img className="" src={require("../assets/right-arrow.svg")} alt="icon" /></button></div>
                    <div className="next-page page-nav" ><button className="icon-btn" onClick={this.nextPageHandler} disabled={this.state.paginationDetails.next_page === null ? true : false}><img className="" src={require("../assets/right-arrow.svg")} alt="icon" /></button></div>
                  </div>
                </div>
                <div className="table-head">
                  <div className="t-row-admin">
                    <div className="th">
                      SORT BY :  <select name="sort" id="sort" className="select-simple">
                        <option value="default">Default</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                    </div>
                    <div className="t-div-admin flex" style={{ marginLeft: '12%' }}>
                      <div className="td">Dashboard</div>
                      <div className="td">Requirements</div>
                      <div className="td">Reports</div>
                      <div className="td">Profile</div>
                      <div className="td">Mange User</div>
                      <div className="td">Roles & Permissions</div>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {map(
                    this.state.recruiterDetailArray,
                    (recruiterDetail: any, index: any) => {
                      //console.log("companyDetail?.attributes?.id =======>",companyDetail?.id);
                      return (
                        <div className="t-row-admin" key={recruiterDetail?.id}  >
                          <div className="th" style={{ width: '28%' }}>
                            <label className="checkbox-lbl ">
                              <input type="checkbox" disabled={this.state.allChecked} checked={recruiterDetail.isChecked} onChange={() => { this.multiSelectHandler(index, recruiterDetail?.id) }} />
                              <span className="checkmark"></span>
                            </label>
                            <Avatar
                              alt={recruiterDetail.attributes?.full_name}

                              src={recruiterDetail.attributes?.avatar}

                            />

                            <View style={{ marginLeft: '2%' }}>
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "rgba(0, 0, 0, 0.87)",
                                  marginTop: 2
                                }}
                              >
                                {recruiterDetail.attributes?.full_name}
                              </span>
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "rgba(0, 0, 0, 0.87)",
                                  marginTop: 2,
                                }}
                              >
                                {recruiterDetail.attributes?.email}| {recruiterDetail.attributes?.country_code} {recruiterDetail.attributes?.phone_number}
                              </span>
                            </View>
                          </div>
                          <div className="t-div-admin flex">
                            <div className="td">
                              {
                                recruiterDetail.attributes?.permissions?.data[0].attributes.feature === 'dashboard' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[0].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[0].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[0].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }

                            </div>
                            <div className="td">
                              {
                                recruiterDetail.attributes?.permissions?.data[5].attributes.feature === 'requirements' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[5].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[5].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[5].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }
                            </div>
                            <div className="td">
                              {
                                recruiterDetail.attributes?.permissions?.data[1].attributes.feature === 'reports' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[1].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[1].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[1].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }
                            </div>
                            <div className="td">
                              {
                                recruiterDetail.attributes?.permissions?.data[2].attributes.feature === 'profile' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[2].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[2].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[2].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }
                            </div>
                            <div className="td">
                              {
                                recruiterDetail.attributes?.permissions?.data[3].attributes.feature === 'manage_user' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[3].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[3].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[3].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }
                            </div>
                            <div className="td" style={{ minWidth: '11%' }}>
                              {
                                recruiterDetail.attributes?.permissions?.data[4].attributes.feature === 'roles_and_permissions' && (
                                  <>
                                    R{recruiterDetail.attributes?.permissions?.data[4].attributes.read_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    W{recruiterDetail.attributes?.permissions?.data[4].attributes.write_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                    D{recruiterDetail.attributes?.permissions?.data[4].attributes.delete_permission ? <HiCheck color="green" /> : <HiX color="red" />}
                                  </>
                                )
                              }
                            </div>
                            <div className="td icons" style={{ textAlign: 'left' }}>


                              <span style={{ padding: 5 }} >
                                <HiEye
                                  className="edit-item"
                                  onClick={() => this.handleOpen("previewModal", recruiterDetail.id)}
                                />
                              </span>

                              <span style={{ padding: 5 }} >

                                <MdSend style={{ flex: 0.35 }} />

                              </span>
                              <span style={{ padding: 5 }} >
                                <HiOutlinePencil style={{ flex: 0.35 }} onClick={() => this.handleOpen("editModal", recruiterDetail.id)} />

                              </span>
                              <span style={{ padding: 5 }} >
                                <AiOutlineDelete style={{ flex: 0.35 }} />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
        <div>
          {/* Delete Modal */}
          <Modal className="alert-popup mt-5"
            isOpen={this.state.deleteOpen}
          >
            <div className="popup-wrap">
              <div>
                <h2 className="mb-3">Confirm Do You Want To Delete/Disable ?</h2>
                <div className="flex-between">
                  <button className="btn-primary" onClick={() => { this.setState({ deleteOpen: false, deleteOneId: null }) }}>Cancel</button>
                  <button className="btn-orange" onClick={() => { this.deleteCompany() }}>Confirm</button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Create Modal */}
          <Modal
            isOpen={this.state.createModal || this.state.editModal}
            onRequestClose={() => { this.state.createModal ? this.handleClose('createModal') : this.handleClose('editModal') }}
          >
            <div className="popup-box">
              <div className="header flex-between-center">
                <h2 className="flex-center"><img className="mr-1" src={require("../assets/left-arrow.svg")} onClick={() => { this.handleClose('createModal') }} alt="" />Create Recruiter</h2>
                <div className="close">
                  <img className="" src={require("../assets/close-icon.svg")} onClick={() => { this.handleClose('createModal') }} alt="" />
                </div>
              </div>
              <div className="popup-body">
                {this.state.imageUrl === null ? (
                  <div className="input-file mb-2">
                    <input type="file" name="" onChange={(evt) => { this.selectedFileHandler(evt) }} />
                    <img className="upload-file" src={require("../assets/input-file.svg")} alt="" />
                    <p className="font-16">Click here to browse or drag and drop a file</p>
                    <button className="btn-primary" >Browse</button>
                    {this.state.sizeAndImage ? <span className="error">File is not image format or file-size is more than 10 MB.</span> : <p>File must be an image format. The maximum file-size is 10 MB.</p>}
                  </div>
                ) : (
                  <div className="uploaded-file flex-center mb-2">
                    <img className="upload-file" src={this.state.imageUrl} alt="" />
                    <p className="ml-2">{this.state.fileName}</p>
                    <div className="close ml-2" >
                      <img className="w-60" src={require("../assets/close.png")} onClick={() => { this.setState({ imageUrl: null, selectedFile: '', sizeAndImage: false }) }} alt="" />
                    </div>
                  </div>
                )
                }
                <Formik
                  initialValues={

                    this.state.editModal ?
                      {
                        user_id: this.state.previewArray[0]?.id,
                        company: this.state.previewArray[0]?.attributes.employment_histories?.data[0]?.attributes?.company,
                        email: this.state.previewArray[0]?.attributes.email,
                        contact: '',
                        country: this.state.previewArray[0]?.attributes.country,
                        state: this.state.previewArray[0]?.attributes.state,
                        city_district: this.state.previewArray[0]?.attributes.city_district,
                        firstName: this.state.previewArray[0]?.attributes.first_name,
                        lastName: this.state.previewArray[0]?.attributes.last_name,
                        phoneNumber: this.state.previewArray[0]?.attributes.phone_number,
                        alternateNumber: this.state.previewArray[0]?.attributes.alternate_number,
                        whatsappNumber: this.state.previewArray[0]?.attributes.whatsapp_number,
                        dashboardRead: this.state.previewArray[0]?.attributes.permissions.data[0]?.read_permission || false,
                        dashboardWrite: this.state.previewArray[0]?.attributes.permissions.data[0]?.write_permission || false,
                        dashboardDelete: this.state.previewArray[0]?.attributes.permissions.data[0]?.delete_permission || false,
                        requirementsRead: this.state.previewArray[0]?.attributes.permissions.data[1]?.read_permission || false,
                        requirementsWrite: this.state.previewArray[0]?.attributes.permissions.data[1]?.write_permission || false,
                        requirementsDelete: this.state.previewArray[0]?.attributes.permissions.data[1]?.delete_permission || false,
                        profileRead: this.state.previewArray[0]?.attributes.permissions.data[2]?.read_permission || false,
                        profileWrite: this.state.previewArray[0]?.attributes.permissions.data[2]?.write_permission || false,
                        profileDelete: this.state.previewArray[0]?.attributes.permissions.data[2]?.delete_permission || false,
                        reportsRead: this.state.previewArray[0]?.attributes.permissions.data[3]?.read_permission || false,
                        reportsWrite: this.state.previewArray[0]?.attributes.permissions.data[3]?.write_permission || false,
                        reportsDelete: this.state.previewArray[0]?.attributes.permissions.data[4]?.delete_permission || false
                      }
                      :
                      {
                        company: '',
                        email: '',
                        contact: '',
                        country: '',
                        state: '',
                        city_district: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        alternateNumber: '',
                        whatsappNumber: '',
                        dashboardRead: false,
                        dashboardWrite: false,
                        dashboardDelete: false,
                        requirementsRead: false,
                        requirementsWrite: false,
                        requirementsDelete: false,
                        profileRead: false,
                        profileWrite: false,
                        profileDelete: false,
                        reportsRead: false,
                        reportsWrite: false,
                        reportsDelete: false

                      }}

                  validationSchema={this.createAdminValidations}
                  onSubmit={(values) => {
                    this.state.editModal ? this.updateRecruiter(values) :
                      this.createNewRecruiter(values);
                  }}
               
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    //@ts-ignore
                    <Form>
                      <div className="flex-between">
                        <div className={errors.firstName && touched.firstName ? "input-box w-50 pr-1 error" : "input-box w-50 pr-1"}>
                          <Field className="input" type="text" id="firstName" name="firstName" required />
                          <label htmlFor="firstName">First Name</label>
                          <span className="error">
                            <ErrorMessage name="firstName" />
                          </span>
                        </div>
                        <div className={errors.lastName && touched.lastName ? "input-box w-50 ml-1 error" : "input-box w-50 ml-1"}>
                          <Field className="input" type="text" id="lastName" name="lastName" required />
                          <label htmlFor="lastName">Last Name</label>
                          <span className="error">
                            <ErrorMessage name="lastName" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className={errors.email && touched.email ? "input-box w-50 pr-1 error" : "input-box w-50 pr-1"}>
                          <Field className="input" type="email" id="email" name="email" required />
                          <label htmlFor="email">Email Address</label>
                          <span className="error">
                            <ErrorMessage name="email" />
                          </span>
                        </div>
                        <div className={errors.phoneNumber && touched.phoneNumber ? "input-box w-50 ml-1 error" : "input-box w-50 ml-1"}>
                          <Field className="input" type="number" id="phoneNumber" name="phoneNumber" required />
                          <label htmlFor="phoneNumber">Phone number</label>
                          <span className="error">
                            <ErrorMessage name="phoneNumber" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className={errors.alternateNumber && touched.alternateNumber ? "input-box w-50 pr-1 error" : "input-box w-50 pr-1"}>
                          <Field className="input" type="number" id="alternateNumber" name="alternateNumber" required />
                          <label htmlFor="alternateNumber">Alternate Number</label>
                          <span className="error">
                            <ErrorMessage name="alternateNumber" />
                          </span>
                        </div>
                        <div className={errors.whatsappNumber && touched.whatsappNumber ? "input-box w-50 ml-1 error" : "input-box w-50 ml-1"}>
                          <Field className="input" type="number" id="whatsappNumber" name="whatsappNumber" required />
                          <label htmlFor="whatsappNumber">Whatsapp Number</label>
                          <span className="error">
                            <ErrorMessage name="whatsappNumber" />
                          </span>
                        </div>
                      </div>
                      <div className={errors.company && touched.company ? "input-box" : "input-box"}>
                        <Field className="input" type="text" id="company" name="company" required />
                        <label htmlFor="company">Company</label>
                        <span className="error">
                          <ErrorMessage name="company" />
                        </span>
                      </div>
                      <h2 className="mt-1">Address and Location</h2>
                      <div className="flex-between">
                        <div className={errors.country && touched.country ? "input-box w-50 pr-1 error" : "input-box w-50 pr-1"}>
                          <select name="country" id="country" className="select-option" onChange={handleChange} onBlur={handleBlur} value={values.country} required>
                            <option value="">Country/Region</option>
                            {map(
                              allCountryList,
                              (country: any, index: any) => {

                                return (
                                  <option value={country.name} key={country.isoCode}>{country.name}</option>
                                )
                              }
                            )}
                          </select>
                          <span className="error">
                            <ErrorMessage name="country" />
                          </span>
                        </div>

                        <div className={errors.state && touched.state ? "input-box w-50 pl-1 error" : "input-box w-50 pl-1"}>
                          <select name="state" id="state" className="select-option" onChange={handleChange} onBlur={handleBlur} value={values.state} required>
                            <option value="">State</option>
                            {values.country && map(
                              states[countries[values.country]],
                              (state: any, index: any) => {
                                return (
                                  <option value={state.name} key={state.isoCode}>{state.name}</option>
                                )
                              }
                            )}
                          </select>
                          <span className="error">
                            <ErrorMessage name="state" />
                          </span>
                        </div>
                      </div>
                      <div className={errors.city_district && touched.city_district ? "input-box w-50 pr-1 error" : "input-box w-50 pr-1"}>
                        <select name="city_district" id="city_district" className="select-option" onChange={handleChange} onBlur={handleBlur} value={values.city_district} required>
                          <option value=""> City/District</option>
                          {values.state && map(
                            cities[stateNames[values.state]],
                            (city: any, index: any) => {
                              return (
                                <option value={city.name} key={city.name}>{city.name}</option>
                              )
                            }
                          )}
                        </select>
                        <span className="error">
                          <ErrorMessage name="city_district" />
                        </span>
                      </div>
                      <h2 className="mt-1">Set Permissions</h2>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <div>
                            <span className="pr-2">
                              Dashboard
                            </span>
                            R<Field style={{ margin: '5px' }} type="checkbox" checked={values.dashboardRead} name="dashboardRead" />
                            W<Field style={{ margin: '5px' }} type="checkbox" checked={values.dashboardWrite} name="dashboardWrite" />
                            D<Field style={{ margin: '5px' }} type="checkbox" checked={values.dashboardDelete} name="dashboardDelete" />
                          </div>
                        </div>
                        <div className="input-box w-50 pr-1">
                          <div>
                            <span className="pr-2">
                              Requirements
                            </span>
                            R<Field style={{ margin: '5px' }} type="checkbox" checked={values.requirementsRead} name="requirementsRead" />
                            W<Field style={{ margin: '5px' }} type="checkbox" checked={values.requirementsWrite} name="requirementsWrite" />
                            D<Field style={{ margin: '5px' }} type="checkbox" checked={values.requirementsDelete} name="requirementsDelete" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <div>
                            <span style={{ paddingRight: '20%' }}>
                              Profile
                            </span>
                            R<Field style={{ margin: '5px' }} type="checkbox" checked={values.profileRead} name="profileRead" />
                            W<Field style={{ margin: '5px' }} type="checkbox" checked={values.profileWrite} name="profileWrite" />
                            D<Field style={{ margin: '5px' }} type="checkbox" checked={values.profileDelete} name="profileDelete" />
                          </div>
                        </div>
                        <div className="input-box w-50 pr-1">
                          <div>
                            <span style={{ paddingRight: '22%' }}>
                              Reports
                            </span>
                            R<Field style={{ margin: '5px' }} type="checkbox" checked={values.reportsRead} name="reportsRead" />
                            W<Field style={{ margin: '5px' }} type="checkbox" checked={values.reportsWrite} name="reportsWrite" />
                            D<Field style={{ margin: '5px' }} type="checkbox" checked={values.reportsDelete} name="reportsDelete" />
                          </div>
                        </div>
                      </div>

                      <div className="flex-between mt-3">
                        <button className="btn-primary" onClick={() => { this.handleClose('createModal') }}>Back</button>
                        {this.state.editModal ? <button type="submit" className="btn-orange">Update</button> : <button type="submit" className="btn-orange">Create</button>}
                      </div>
                    </Form>
                  )}
                </Formik>

              </div>

            </div>
          </Modal>
          {/* Preview Modal */}
          <Modal
            isOpen={this.state.previewModal}
            onRequestClose={() => this.handleClose("previewModal")}
          >

            <div>
              <div>
                <View style={{ backgroundColor: "black", height: "60px" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 3.45 }}>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: "normal",
                          color: "#fff",
                          paddingLeft: "6%",
                          paddingTop: "15px",
                        }}
                      >
                        <HiOutlineArrowNarrowLeft
                          style={{ marginRight: "2%" }}
                          onClick={() => this.handleClose("previewModal")}
                        />
                        { }Preview
                      </Typography>
                    </View>
                    <View style={{ flex: 0.45, marginTop: "20px" }}>
                      <HiX
                        style={{ color: "white" }}
                        onClick={() => this.handleClose("previewModal")}
                      />
                    </View>
                  </View>
                </View>
                <View style={{ backgroundColor: "#f1f1f1", height: "141px" }}>
                  <View
                    style={{ flexDirection: "row", flex: 0.85, padding: "5%" }}
                  >
                    <Avatar
                      alt={this.state.previewArray[0]?.attributes.full_name}
                      src={this.state.previewArray[0]?.attributes.avatar || ""}
                      style={{ marginRight: 10, width: "75px", height: "75px" }}
                    />
                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          marginTop: 2,
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        {this.state.previewArray[0]?.attributes.full_name}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "rgba(0, 0, 0, 0.6)",
                          marginTop: 2,
                        }}
                      >
                        {this.state.previewArray[0]?.attributes.email} | + {this.state.previewArray[0]?.attributes.full_phone_number}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "rgba(0, 0, 0, 0.6)",
                          marginTop: 2,
                        }}
                      >
                        {this.state.previewArray[0]?.attributes.employment_histories?.data[0]?.attributes?.company}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "rgba(0, 0, 0, 0.6)",
                          marginTop: 2,
                        }}
                      >
                        {this.state.previewArray[0]?.attributes.city_district}, {this.state.previewArray[0]?.attributes.state} , {this.state.previewArray[0]?.attributes.country}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ backgroundColor: "#ffffff", height: "151px" }}>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: "normal",
                      color: "rgba(0, 0, 0, 0.87)",
                      paddingLeft: "6%",
                      paddingTop: "15px",
                    }}
                  >
                    Permissions
                  </Typography>
                  <View style={{ flexDirection: "row", paddingLeft: "5%" }}>
                    <View style={{ flex: 0.45 }}>
                      <View style={{ flexDirection: "row" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 12,
                            fontWeight: "normal",
                            color: "rgba(0, 0, 0, 0.87)",
                            paddingLeft: "6%",
                            paddingTop: "15px",
                          }}
                        >
                          Dashboard
                        </Typography>

                        <Text
                          style={{
                            fontSize: 12,
                            color: "rgba(0, 0, 0, 0.6)",
                            marginTop: 10,
                            marginLeft: 20,
                          }}
                        >
                          R <FaRegCheckSquare size="15" /> W{" "}
                          <FaRegCheckSquare size="15" /> D{" "}
                          <FaRegCheckSquare size="15" />
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 12,
                            fontWeight: "normal",
                            color: "rgba(0, 0, 0, 0.87)",
                            paddingLeft: "6%",
                            paddingTop: "15px",
                          }}
                        >
                          Profile
                        </Typography>
                        <Text
                          style={{
                            fontSize: 12,
                            color: "rgba(0, 0, 0, 0.6)",
                            marginTop: 10,
                            marginLeft: 50,
                          }}
                        >
                          R <FaRegCheckSquare size="15" /> W{" "}
                          <FaRegCheckSquare size="15" /> D{" "}
                          <FaRegCheckSquare size="15" />
                        </Text>
                      </View>
                    </View>
                    <View style={{ flex: 0.45 }}>
                      <View style={{ flexDirection: "row" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 12,
                            fontWeight: "normal",
                            color: "rgba(0, 0, 0, 0.87)",
                            paddingLeft: "6%",
                            paddingTop: "15px",
                          }}
                        >
                          Requirments
                        </Typography>
                        <Text
                          style={{
                            fontSize: 12,
                            color: "rgba(0, 0, 0, 0.6)",
                            marginTop: 10,
                            marginLeft: 20,
                          }}
                        >
                          R <FaRegSquare size="15" /> W{" "}
                          <FaRegSquare size="15" /> D <FaRegSquare size="15" />
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 12,
                            fontWeight: "normal",
                            color: "rgba(0, 0, 0, 0.87)",
                            paddingLeft: "6%",
                            paddingTop: "15px",
                          }}
                        >
                          Reports
                        </Typography>
                        <Text
                          style={{
                            fontSize: 12,
                            color: "rgba(0, 0, 0, 0.6)",
                            marginTop: 10,
                            marginLeft: 50,
                          }}
                        >
                          R <FaRegSquare size="15" /> W{" "}
                          <FaRegSquare size="15" /> D <FaRegSquare size="15" />
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </div>
            </div>
          </Modal>
          {/* Import/Export Modal */}
          <Modal
            isOpen={this.state.importModal}
            onRequestClose={() => { this.handleClose('importModal') }}
          >
            <div className="popup-box">
              <div className="header flex-between-center">
                <h2 className="flex-center"><img className="mr-1" src={require("../assets/left-arrow.svg")} onClick={() => { this.handleClose('importModal') }} alt="" />Import/Export</h2>
                <div className="close">
                  <img className="" src={require("../assets/close-icon.svg")} onClick={() => { this.handleClose('importModal') }} alt="" />
                </div>
              </div>
              <div className="popup-body">
                {this.state.fileUrl === null ? (
                  <div>
                    <span>Import</span> <span style={{ float: 'right', color: '#e45d33' }}>Download Template</span>
                    <div className="input-file mb-2">
                      <input type="file" name="" onChange={(evt) => { this.selectedFileHandler(evt) }} />
                      <img className="upload-file" src={require("../assets/input-file.svg")} alt="" />
                      <p className="font-16">Click here to browse or drag and drop a file</p>
                      <button className="btn-primary" >Browse</button>
                      {this.state.sizeAndImage ? <span className="error">File is not csv format or file-size is more than 10 MB.</span> : <p>File must be an csv format. The maximum file-size is 10 MB.</p>}
                    </div>
                  </div>
                ) : (
                  <div>
                    <span>Import</span> <span>Download Template</span>
                    <div className="uploaded-file flex-center mb-2">
                      <img className="upload-file" src={this.state.imageUrl} alt="" />
                      <p className="ml-2">{this.state.fileName}</p>
                      <div className="close ml-2" >
                        <img className="w-60" src={require("../assets/close.png")} onClick={() => { this.setState({ imageUrl: null, selectedFile: '', sizeAndImage: false }) }} alt="" />
                      </div>
                    </div>
                  </div>
                )
                }
                <Formik
                  initialValues={

                    {


                    }}
                  // validationSchema={this.createAdminValidations}
                  onSubmit={(values) => {
                    console.log(values)
                    // this.createNewAdmin(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    //@ts-ignore
                    <Form>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Name</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Dashboard</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Email</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Requirements</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Contact No</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Profile</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Company Name</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Reports</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Country</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Manage user</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>City/State</span>
                        </div>
                        <div className="input-box w-50 ml-1">
                          <span>Roles & Permissions</span>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="input-box w-50 pr-1">
                          <span>Reference ID</span>
                        </div>
                      </div>
                      <div className="flex-between mt-3">
                        <button className="btn-primary" onClick={() => { this.handleClose('importModal') }}>Cancel</button>
                        <button type="submit" className="btn-orange">Export</button>
                      </div>
                    </Form>
                  )}
                </Formik>

              </div>

            </div>
          </Modal>


        </div >
      </div >
    );
  }
}

// Customizable Area Start

// 1200px and up
const baseStyles = {
  container: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#f1f1f1",
    paddingHorizontal: "6%",
    paddingVertical: 130,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  mainColumn: {
    width: "100%",
  },
  rightColumn: {
    width: "24%",
  },
  card: {
    width: "100%",
    backgroundColor: "#ffffff",
    paddingHorizontal: "5%",
    paddingVertical: 18,
  },
  widgetHead: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widgetTitle: {
    fontSize: 14,
    color: "black",
    fontWeight: 500,
  },
  widgetClear: {
    fontSize: 13,
    color: "silver",
    fontWeight: 500,
  },
  widgetSearchInput: {
    width: "100%",
  },
  widgetSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0,
  },
  jobSearchInputIcon: {
    color: "slategray",
    fontSize: 20,
    position: "absolute",
    marginRight: 12,
    right: 0,
    width: "50%",
  },
  keySkillsBlock: {
    marginTop: 8,
  },
  keySkillTagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  keySkillTag: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "silver",
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginRight: 7,
    marginTop: 5,
  },
  tagTimesIcon: {
    height: "100%",
    verticalAlign: "sub",
    marginLeft: 8,
  },
  rightImage: {
    width: "100%",
    height: 210,
    marginTop: 40,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25,
  },
  text: {
    fontSize: 12,
    color: "black",
    lineHeight: 27,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#red",
    border: "2px solid #000",
    padding: "2 4 3 0",
    marginTop: "16%",
  },
};

const getStyles = () =>
  createStyles(
    baseStyles,
    // below 1200
    maxWidth(1200, {}),
    // below 992
    maxWidth(992, {}),
    // below  768
    maxWidth(768, {}),
    // below  626
    maxWidth(626, {}),
    // below  576
    maxWidth(576, {})
  );
// Customizable Area End
