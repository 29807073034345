Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "JobManagement";
exports.labelBodyText = "JobManagement Body";

exports.jobDetailApiContentType = "application/json";
exports.jobDetailApiMethod = "POST";
exports.jobDetailApiEndPoint = "jobmanagement/job_details";

exports.countryListApiContentType = "application/json";
exports.countryListApiMethod = "GET";
exports.countryListApiEndPoint = "account/accounts/country_code_and_flag";

exports.courseListApiContentType = "application/json";
exports.courseListApiMethod = "GET";
exports.courseListApiEndPoint = "education_courses?course_type=bachelors";

exports.postGraduationListApiContentType = "application/json";
exports.postGraduationListApiMethod = "GET";
exports.postGraduationListApiEndPoint = "education_courses?course_type=post_graduation";

exports.doctorateListApiContentType = "application/json";
exports.doctorateListApiMethod = "GET";
exports.doctorateListApiEndPoint = "education_courses?course_type=doctorate";

exports.keywordListApiContentType = "application/json";
exports.keywordListApiMethod = "GET";
exports.keywordListApiEndPoint = "skills";

exports.postDetailFormApiContentType = "application/json";
exports.postDetailFormApiMethod = "PUT";
exports.postDetailFormApiEndPoint = "jobmanagement/job_details/";

exports.jobDetailsValuesApiContentType = "application/json";
exports.jobDetailsValuesApiMethod = "GET";
exports.jobDetailValuesApiEndPoint = "jobmanagement/job_details/";

exports.submitSearchStrategyContentType = "multipart/form-data";
exports.submitSearchStrategyMethod = "POST";
exports.submitSearchStrategyEndPoint = "jobmanagement/job_details/";

exports.functionalAreaListContentType = "application/json";
exports.functionalAreaListMethod = "GET";
exports.functionalAreaListEndPoint = "functional_areas";

exports.allJobDetailApiContentType = "application/json";
exports.allJobDetailApiMethod = "GET";
exports.allJobDetailApiEndPoint = "jobmanagement/job_details/";

exports.postJobApiContentType = "application/json";
exports.postJobApiMethod = "PUT";
exports.postJobApiEndPoint = "jobmanagement/job_details/";

exports.downloadPdfApiContentType = "application/json";
exports.downloadPdfApiMethod = "GET";
exports.downloadPdfApiEndPoint = "jobmanagement/job_details/";

exports.companiesApiContentType = "application/json";
exports.companiesApiMethod = "GET";
exports.companiesApiEndPoint = "companies";

exports.industryListApiContentType = "application/json";
exports.industryListApiMethod = "GET";
exports.industryListApiEndPoint = "industries";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End