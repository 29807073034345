//@ts-nocheck
import React, { Fragment } from 'react';
import {
  Dimensions,
  PixelRatio,
  View,
  Text,
  FlatList,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  CheckBox,
  Platform,
  Image,
  TextInput,
  Picker,
  ActivityIndicator,
  Alert,
  ImageBackground
} from 'react-native';
//@ts-ignore

import { createStyles, maxWidth } from 'react-native-media-queries';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';

import TextField from '@material-ui/core/TextField';
import classnames from "classnames";
import Select from 'react-select'
import { FaPlus, FaTimes, FaCheck } from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MUIRichTextEditor from "mui-rte";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import { formBottomImage } from './assets';
import Grid from '@material-ui/core/Grid';

// Customizable Area End

import JobManagementController, {
  Props,
  // configJSON
} from "./JobManagementController";
import { valueContainerCSS } from 'react-select/src/components/containers';

export default class PostDetailFormFiled extends JobManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // async componentDidMount() {
  //   super.componentDidMount();
  //   this.getCourseList();
  //   this.getPostGraduationList();
  //   this.getDoctorateList();
  //   this.getJobDetailsValues();
  //   this.getFunctionalAreas();
  //   this.getindustryList();
  // }

  addMore = () => {
    const { values, setFieldValue } = this.props;
    if (values.question !== '') {
      const queRef = [...this.props.questions, values.question]
      this.setState({ questions: queRef }, () => {
        setFieldValue("question", '')
        setFieldValue("questions", queRef)
      })
      this.props.updateQuestion(queRef)
    }
    else {
      this.showAlert(
        "",
        "Filed is empty",
        "OK"
      );
    }
  }

  render() {
    const { isFormLoading } = this.state
    const {
      values,
      handleChange,
      errors,
      setFieldTouched,
      setFieldValue,
      touched,
      handleSubmit,
      resetForm,
      // preSelectedIndustry,
      // preSelectedFuntionalArea,
      courses,
      postGraduationList,
      doctrateList,
      industryList,
      functionalAreaList
    } = this.props
    const styles = getStyles();

    return (
      <View style={[styles.container]}>
        {/* <View style={[styles.topContainer]}>
          <View style={styles.topContainerRadioButton}>
            <RadioGroup row aria-label="position" name="position" defaultValue="top">
              <View style={{ flexDirection: "row", alignItems: "center" }} >
                <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                  <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                </View>
                <Text style={[styles.stepText]}>Job Details</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <p style={{ margin: 0, marginLeft: 10, marginRight: 10, color: "rgb(126, 126, 126)" }}>-------</p>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }} >
                <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                  <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                </View>
                <Text style={[styles.stepText]}>Posting Details</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={[styles.stepIconContainer, styles.stepIconContainerActive, { height: 13, width: 13, marginRight: 0, marginLeft: 20 }]} />
                <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 0, marginLeft: 15 }]} />
                <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 0, marginLeft: 15 }]} />
                <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 15, marginLeft: 15 }]} />
                <CloseIcon />
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <p style={{ margin: 0, marginLeft: 25, marginRight: 15, color: "rgb(126, 126, 126)" }}>---------</p>
              </View>
              <FormControlLabel value="end" control={<Radio color="primary" />} label="Search Strategy" />
            </RadioGroup>
          </View>

          <View style={styles.topContainerButton}>
            <TouchableOpacity
              style={[styles.button, { marginRight: "16px", minWidth: "150px", boxShadow: "none", borderWidth: "0px" }]}
              onPress={() => {
                resetForm()
                //@ts-ignore
                this.props.backtoForm()
                this.props.onEdit()
              }}
            >
              <Text style={styles.buttonText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleSubmit()}
              style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", minWidth: "174px" }]}
            >
              <Text style={[styles.buttonText, { color: "white" }]}>
                Next Step
              </Text>
            </TouchableOpacity>
          </View>
        </View> */}


        <View onLayout={(event) => {
          var { x, y, width, height } = event.nativeEvent.layout;
          if (this.props.onChangeLayout) {
            this.props.onChangeLayout(height)
          };
        }} >
          <View>
            <div key={"form-post"}>
              <Fragment>
                <View style={[styles.topContainer]}>
                  <View style={styles.topContainerRadioButton}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                      <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                          <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                        </View>
                        <Text style={[styles.stepText]}>Job Details</Text>
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <p style={{ margin: 0, marginLeft: 10, marginRight: 10, color: "rgb(126, 126, 126)" }}>-------</p>
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <View style={[styles.stepIconContainer, styles.stepAdditionalIconContainerActive]}>
                          <Text style={[styles.stepIcon, styles.stepAdditionalIconActive]}><FaCheck size={10} /></Text>
                        </View>
                        <Text style={[styles.stepText]}>Posting Details</Text>
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View style={[styles.stepIconContainer, styles.stepIconContainerActive, { height: 13, width: 13, marginRight: 0, marginLeft: 20 }]} />
                        <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 0, marginLeft: 15 }]} />
                        <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 0, marginLeft: 15 }]} />
                        <View style={[styles.stepIconContainer, styles.stepIconContainerNotActive, { height: 13, width: 13, marginRight: 15, marginLeft: 15 }]} />
                        <CloseIcon />
                      </View>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <p style={{ margin: 0, marginLeft: 25, marginRight: 15, color: "rgb(126, 126, 126)" }}>---------</p>
                      </View>
                      <FormControlLabel value="end" control={<Radio color="primary" />} label="Search Strategy" />
                    </RadioGroup>
                  </View>

                  <View style={[styles.topContainerButton]}>
                    <TouchableOpacity
                      style={[styles.button, { marginRight: "16px", minWidth: "150px", boxShadow: "none", borderWidth: "0px" }]}
                      onPress={() => {
                        { window.location = '/JobRequirements' }
                      }}
                    >
                      <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => handleSubmit()}
                      style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", minWidth: "174px" }]}
                    >
                      <Text style={[styles.buttonText, { color: "white" }]}>
                        Next Step
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <Grid container spacing={3} style={{ marginBottom: "0" }}>
                  <Grid item sm={7} style={{ paddingBottom: "0" }}>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text style={[styles.rightTitle, { marginTop: 70 }]}>Posting Details</Text>
                      <Text style={styles.rightSubTitle}>Lorem ipsumdemo content here.</Text>
                    </div>

                    <div id={"graduation_id"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Graduation</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${0}.value`}
                                checked={values[`post_preferences_attributes.${0}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsumdemo content here.</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="Any Graduation"
                            options={courses}
                            name="graduation_id"
                            defaultValue={courses.find(({ value }) => value == values.graduation_id)}
                            onChange={(option: any) => { setFieldValue('graduation_id', option.value) }}
                            onFocus={() => setFieldTouched('graduation_id', false, false)}
                            onBlur={() => setFieldTouched('graduation_id')}
                            className={classnames("", { "select-error-input": touched.graduation_id && errors.graduation_id })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.graduation_id && errors.graduation_id &&
                              <Text style={styles.textInputErrorMessage}>{errors.graduation_id}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"post_graduation_id"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Post Graduation</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${1}.value`}
                                checked={values[`post_preferences_attributes.${1}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsumdemo content here.</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="Any Post Graduation"
                            options={postGraduationList}
                            name="post_graduation_id"
                            defaultValue={postGraduationList.find(({ value }) => value == values.post_graduation_id)}
                            onChange={(option: any) => setFieldValue('post_graduation_id', option.value)}
                            onFocus={() => setFieldTouched('post_graduation_id', false, false)}
                            onBlur={() => setFieldTouched('post_graduation_id')}
                            className={classnames("", { "select-error-input": touched.post_graduation_id && errors.post_graduation_id })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.post_graduation_id && errors.post_graduation_id &&
                              <Text style={styles.textInputErrorMessage}>{errors.post_graduation_id}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"doctorate_id"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Doctorate</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${2}.value`}
                                checked={values[`post_preferences_attributes.${2}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Let's make it memorable</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectStyles}
                            isSearchable={true}
                            placeholder="Any Post Doctorate"
                            options={doctrateList}
                            name="doctorate_id"
                            defaultValue={doctrateList.find(({ value }) => value == values.doctorate_id)}
                            onChange={(option: any) => setFieldValue('doctorate_id', option.value)}
                            onFocus={() => setFieldTouched('doctorate_id', false, false)}
                            onBlur={() => setFieldTouched('doctorate_id')}
                            className={classnames("", { "select-error-input": touched.doctorate_id && errors.doctorate_id })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.doctorate_id && errors.doctorate_id &&
                              <Text style={styles.textInputErrorMessage}>{errors.doctorate_id}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <View style={{ paddingTop: 30 }}>
                      <Text style={styles.rightTitle}>Job Details</Text>
                      <Text style={styles.rightSubTitle}>Lorem ipsum demo content here.</Text>
                    </View>

                    <div id={"industry_id"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Industry</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${3}.value`}
                                checked={values[`post_preferences_attributes.${3}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectMultipleStyle}
                            isSearchable={true}
                            placeholder="Type Here"
                            options={industryList}
                            name="industry_id"
                            defaultValue={industryList.find(({ value }) => value == values.industry_id)}
                            onFocus={() => setFieldTouched('industry_id', false, false)}
                            onBlur={() => setFieldTouched('industry_id')}
                            onChange={(option: any) => setFieldValue('industry_id', option.value)}
                            className={classnames("", { "select-error-input": touched.industry_id && errors.industry_id })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.industry_id && errors.industry_id &&
                              <Text style={styles.textInputErrorMessage}>{errors.industry_id}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <div id={"functional_area_id"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 42, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Functional Area</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${4}.value`}
                                checked={values[`post_preferences_attributes.${4}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <Select
                            styles={selectMultipleStyle}
                            isSearchable={true}
                            placeholder="Type Here"
                            options={functionalAreaList}
                            name="functional_area_id"
                            defaultValue={functionalAreaList.find(({ value }) => value == values.functional_area_id)}
                            onFocus={() => setFieldTouched('functional_area_id', false, false)}
                            onBlur={() => setFieldTouched('functional_area_id')}
                            onChange={(option: any) => setFieldValue('functional_area_id', option.value)}
                            className={classnames("", { "select-error-input": touched.functional_area_id && errors.functional_area_id })}
                          />
                          <View style={styles.textInputErrorMessageContainer}>
                            {touched.functional_area_id && errors.functional_area_id &&
                              <Text style={styles.textInputErrorMessage}>{errors.functional_area_id}</Text>
                            }
                          </View>
                        </View>
                      </View>
                    </div>

                    <View style={{ marginTop: 42 }}>
                      <Text style={styles.rightTitle}>Questions</Text>
                      <Text style={styles.rightSubTitle}>Lorem ipsum demo content here.</Text>
                    </View>

                    <div id={"question"} style={{ scrollMargin: 100 }}>
                      <View style={[styles.formRow, { marginTop: 30, marginBottom: 30 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={{ fontWeight: "500", fontSize: 20 }}>Have any questions for the condidate?</Text>
                            <View style={styles.switchButton}>
                              <AntSwitch
                                name={`post_preferences_attributes.${5}.value`}
                                checked={values[`post_preferences_attributes.${5}.value`]}
                                onChange={handleChange}
                              />
                            </View>
                            <Text style={styles.subText}>Add to Job Post</Text>
                          </View>

                        </View>
                      </View>
                      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Text style={[styles.belowText, { marginBottom: 0, marginTop: 0 }]}>Lorem ipsum demo content here.</Text>
                        <TouchableOpacity
                          style={[styles.smallButton, { shadowColor: "#ffffff", borderColor: "#ffffff" }]}
                          onClick={() => this.addMore()}
                        >
                          <Text style={[styles.buttonText, { color: "#e45d33" }]}>
                            <FaPlus /> ADD MORE
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={styles.formRow}>
                        <View style={[styles.inputWrapper, { width: "100%" }]} >
                          <TextField
                            variant={"outlined"}
                            // disabled={this.state.otpVerified}
                            label="Type Here"
                            style={styles.plainTextInput}
                            name={"question"}
                            value={values.question}
                            onChange={handleChange}
                            onFocus={() => setFieldTouched('question', false, false)}
                            onBlur={() => setFieldTouched('question')}
                            error={!!(errors.question && touched.question)}
                            helperText={touched.question && errors.question}
                            multiline
                          />
                        </View>
                      </View>
                      <View style={{ paddingTop: 30 }}>
                        <Text style={styles.rightTitle}>Questions</Text>
                        <Text style={[styles.belowText, { marginTop: 20 }]}>Lorem ipsum demo content here.</Text>
                        {this.props.questions &&
                          this.props.questions.map((item) => {
                            if (typeof item === "object") {
                              if (item.question !== null) {
                                return (
                                  <View style={[styles.formRow], { marginBottom: 20 }}>
                                    <View style={[styles.inputWrapper, { width: "100%" }]} >
                                      <TextField
                                        value={item?.question}
                                        variant={"outlined"}
                                        style={[styles.plainTextInput], { pointerEvents: "none" }}
                                        readonly
                                      />
                                    </View>
                                  </View>
                                )
                              }
                            }
                            else {
                              return (
                                <View style={[styles.formRow], { marginBottom: 20 }}>
                                  <View style={[styles.inputWrapper, { width: "100%" }]} >
                                    <TextField
                                      value={item}
                                      variant={"outlined"}
                                      style={[styles.plainTextInput], { pointerEvents: "none" }}
                                      readonly
                                    />
                                  </View>
                                </View>
                              )
                            }
                          })
                        }
                      </View>
                    </div>

                    <div id={"show_contact_details"} style={{ scrollMargin: 100 }}>
                      <View style={{ marginTop: 10 }}>
                        <Text style={{ fontWeight: "500", fontSize: 20 }}>Show contact details to jobseekers</Text>
                      </View>

                      <View style={{ marginTop: 20 }}>
                        <Text style={styles.belowText}>Lorem ipsum demo content here.</Text>
                      </View>

                      <View style={{ flexDirection: "row", marginLeft: -30, marginTop: 10 }}>
                        <View style={styles.switchButton}>
                          <AntSwitch
                            name={`post_preferences_attributes.${6}.value`}
                            checked={values[`post_preferences_attributes.${6}.value`]}
                            onChange={handleChange}
                          />
                        </View>
                      </View>
                    </div>
                    <div style={{ display: "flex" }}>
                      <View style={styles.bottomContainer}>
                        <TouchableOpacity style={[styles.button], { textAlign: "center", minWidth: "150px", boxShadow: "none", borderWidth: "0px" }} onPress={() => {
                          { window.location = '/JobRequirements' }
                        }}>
                          <Text style={styles.buttonText}>CANCEL</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => handleSubmit()}
                          style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", minWidth: "174px" }]}>
                          <Text style={[styles.buttonText, { color: "#ffffff" }]}>NEXT STEP</Text>
                        </TouchableOpacity>
                      </View>
                    </div>
                  </Grid>

                  <Grid item sm={5} style={{ paddingBottom: "0" }}>
                    <View style={[styles.rightImageView]}>
                      <img src={formBottomImage.default} alt="image" style={{ position: "absolute", bottom: "-40px" }} />
                    </View>
                  </Grid>
                </Grid>
              </Fragment>
            </div>
          </View>
        </View>

      </View>
    );
  }
}

// 1200px and up
const baseStyles = {
  rightImageView: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    alignItems: "flex-end",
    paddingRight: "38px"
  },
  container: {
    width: "100%",
    minHeight: "100%",
    padding: "12px",
    paddingTop: "95px",
    paddingLeft: "70px",
    paddingBottom: "40px"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 44,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    textTransform: "uppercase"
  },
  smallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 120,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  stepIconContainer: {
    marginRight: 13,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 25,
    width: 25,
    backgroundColor: "#299c14",
    borderWidth: 1,
    borderColor: "#299c14",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerNotActive: {
    height: 25,
    width: 25,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepAdditionalIconContainerActive: {
    height: 20,
    width: 20,
    // backgroundColor: "#ffffff",
    borderWidth: 2,
    borderColor: "#299c14",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepIcon: {
    fontSize: 17,
    color: "green"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "40px"
  },
  topContainerButton: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: "13px",
    // position: "absolute",
    // marginTop: "-174px",
    // left: "64rem",
  },
  topContainerRadioButton: {
    color: "rgb(126, 126, 126)",
  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    paddingBottom: 15
  }
  ,
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgb(126, 126, 126)",
    fontSize: "1rem",
    fontWeight: "400",
    letterSpacing: "0.00938em"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  uploadTextInput: {
    width: "100%",
    marginBottom: 18,
    color: "silver"
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  },
  rightImage: {
    width: "80%",
    height: "50%",
    marginBottom: "3%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  historyContainerIcons: {
    fontSize: 20,
    color: "black"
  },
  company_logo: {
    height: 48,
    width: 48
  },
  subText: {
    marginLeft: 10,
    color: "rgb(126, 126, 126)",
    wordSpacing: 3,
  },
  switchButton: {
    marginLeft: 30,
  },
  belowText: {
    color: "rgb(126, 126, 126)",
    fontSize: 16,
    marginBottom: 20,
    marginTop: -20,
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {

  }),
  // below 1200
  maxWidth(1200, {

  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {

  }),
  // below  626
  maxWidth(626, {

  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);

const AntSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    '&$checked': {
      color: "rgb(245 123 34 / 84%)",
      marginLeft: -10,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: "white",
      borderColor: "rgb(245 123 34 / 84%)",
    },
  },
  checked: {},
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    height: 13,
    width: 25,
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
})(Switch);


const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  }
}

const selectMultipleStyle = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute"
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none"
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  multiValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: "11%",
      border: `1px solid ${grey[500]}`,
      backgroundColor: "white !important",
      borderRadius: 3
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      position: "fixed !important",
    }
  },
  input: (provided: any, state: any) => {
    return {
      ...provided,
    }
  },
  multiValueRemove: (provided: any, state: any) => {
    return {
      ...provided,
      background: "rgba(0, 0, 0, 0.38)",
      color: "white",
      borderRadius: "50%",
      width: 16,
      height: 16,
      margin: "auto",
      padding: "inherit",
      marginRight: 10,
      marginTop: 5,
      marginLeft: 10,
    }
  },
  // placeholder: (provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     position: "relative",
  //     // marginBottom: 20,
  //     display:'content'
  //   }
  // },
  // valueContainer:(provided: any, state: any) => {
  //   return {
  //     ...provided,
  //     display:'content'
  //   }  }

}
