//@ts-nocheck
//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
// import Loader from "../../../../components/src/Loader";
import PaginationIndicator from "../../../components/src/PaginationIndicator.web";
// import Storage from "../../../../framework/src/StorageProvider.web";
import { FaCheck, FaPlus, FaThList } from "react-icons/fa";
import JobDetail from './JobDetails.web';
import PostDetailForm from './PostDetialsForm.web';
import JobDetailForm from './JobDetailsForm.web';
import SearchStrategy from './SearchStrategy.web';

//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import JobManagementController, {
  Props,
} from "./JobManagementController";


export default class JobManagement extends JobManagementController {
  constructor(props: Props) {
    super(props);
  }

  async componentWillUnmount() {
    console.log("unmount-------------")
  }

  async componentDidMount() {
    const detailValue = localStorage.getItem('jobDetailValues')
    if (detailValue) {
      console.log("detailValue---------", JSON.parse(detailValue))
      const values = JSON.parse(detailValue)
      this.setState({ jobDetailValue: values })
    }
  }

  getSideMenu = () => {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
        </View>
      </View>
    )
  }

  getSidemenuFooter = () => {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      <View style={{ marginTop: "100%" }}>

        <Text style={styles.leftTitle}>Lorem Ipsum has been the industry's standard</Text>
        <Text style={[styles.text, { marginTop: 8, color: "rgba(255,255,255,0.3)" }]}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</Text>


        <View style={{ marginTop: 50 }}>
          <PaginationIndicator count={3} selected={1} style={{ justifyContent: "flex-start" }} />
        </View>
        {/*<Text style={[styles.text, { marginTop: 50, color: "#ffffff" }]}>© 2021 RMS. All right Reserved.</Text>*/}
      </View>
    )
  }

  onLayoutChange = (h: any) => {
    console.log('onLayoutChange >>>>> ', h)
  }

  updateStep = () => {
    this.setState({ formStep: 2 })
  }

  setNextForm = () => {
    if (this.state.formStep === 2) {
      this.setState({ formStep: 3 })
    }
  }

  backtoForm = () => {
    if (this.state.formStep === 2) {
      this.setState({ formStep: 1 })
    }
    else if (this.state.formStep === 3) {
      this.setState({ formStep: 2 })
    }
  }

  backToEdit = () => {
    if (this.state.formStep === 3) {
      this.setState({ formStep: 1 })
    }
  }

  render() {
    const styles = getStyles();
    const assets = require("./assets");

    const defaultTheme = createMuiTheme()

    Object.assign(defaultTheme, {
      overrides: {
        MUIRichTextEditor: {
          editor: {
            border: '1px solid silver',
            borderRadius: 4,
            padding: '14px 16px',
            height: 72,
            overflow: 'auto'
          },
          placeHolder: {
            padding: '14px 16px',
            position: 'absolute',
            color: 'rgba(0, 0, 0, 0.6)'
          }
        }
      }
    })

    console.log("formStep----------", this.state.formStep)

    return (
      <MuiThemeProvider theme={defaultTheme} >
        <View style={[styles.container]}>
          {this.state.formStep === 1 &&
            <JobDetail
              updateStep={this.updateStep}
              isEdit={this.state.isEdit}
            />
          }
          {this.state.formStep === 2 &&
            <PostDetailForm
              setNextForm={this.setNextForm}
              backtoForm={() => this.backtoForm()}
              onEdit={this.onEdit}
            />
          }
          {this.state.formStep === 3 &&
            <SearchStrategy
              backtoForm={() => this.backtoForm()}
              backToEdit={this.backToEdit}
              onEdit={this.onEdit}
              navigation={this.props.navigation}
            />
          }
        </View>
      </MuiThemeProvider>
    );
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 30,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "20%",
    // minHeight: "100%",
    padding: "1.3%",
    justifyContent: "space-between"
  },
  logoImage: {
    marginTop: 100,
    width: 45,
    height: 45
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "white",
    marginLeft: 30
  },
  stepAdditionalTextActive: {
    color: "rgb(126, 126, 126)",
    marginLeft: 30
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 25,
    width: 25,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepAdditionalIconContainerActive: {
    height: 15,
    width: 15,
    // backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  stepAdditionalIconActive: {
    fontSize: 10,
    color: "rgb(126, 126, 126)",
    // marginTop: 20,
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 26,
    height: 26,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    width: "80%",
    // minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  headingForm: {
    fontSize: "larger",
    marginBottom: 20,
    color: "white",
  },
  headingAdditionalForm: {
    fontSize: "larger",
    marginBottom: 20,
    color: "rgb(126, 126, 126)",
    marginTop: 20,
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  // maxWidth(992, {
  //   leftContainer: {
  //     display: "none"
  //   },
  //   rightContainer: {
  //     width: "100%"
  //   }
  // }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);