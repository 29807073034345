import React, { Fragment } from "react";
import { View, StyleSheet, Image, TextInput, TouchableOpacity, Text } from "react-native";
import { withRouter } from "react-router-dom";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import { AiOutlineSearch } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import Storage from "../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { AiOutlineCaretDown } from "react-icons/ai";

export interface Props {
  history: any;
  location: any;
  match: any;
  navigation: any;
}

export interface S {
  profileImage: string | undefined,
  profileData: any | undefined,
  anchorEl: any,
  showRightDrawer: boolean,
  showJobRequirementMenu: boolean,
  showSystemManagementMenu: boolean,
  showManageUserMenu: boolean
}

export interface SS {
  id: any;
}


class TopNav extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      profileImage: undefined,
      profileData: undefined,
      showRightDrawer: false,
      showJobRequirementMenu: false,
      showSystemManagementMenu: false,
      anchorEl: null,
      showManageUserMenu: false
    };
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  async componentDidUpdate() {
    const profileData = await Storage.get("profileData");
    if (profileData) {
      this.setState({ profileData, profileImage: profileData.attributes.avatar || undefined });
    } else if (this.state.profileData) {
      this.setState({ profileData: undefined });
    }
  }

  onCloseJobRequirementMenu(e?: any) {
    this.setState({ showJobRequirementMenu: false, anchorEl: null });
  }
  onCloseSystemManagementMenu() {
    this.setState({ showSystemManagementMenu: false, anchorEl: null })
  }
  onOpenJobRequirementMenu(e: any) {
    this.setState({ showJobRequirementMenu: true, anchorEl: e.target });
  }

  systemManagementNavigation(path: any) {
    this.onCloseSystemManagementMenu();
    window.location = path
  }

  manageUserNavigation(path: any) {
    this.onCloseManageUser();
    window.location = path
  }

  onCloseManageUser(e?: any) {
    this.setState({ showManageUserMenu: false, anchorEl: null });
  }
  onOpenManageUser(e?: any) {
    this.setState({ showManageUserMenu: true, anchorEl: e.target });
  }

  render() {
    const styles = getStyles();
    const assets = require("./assets");

    const jobRequirementMenuRef = React.createRef();

    const isHomePage = this.props.history.location.pathname === "/";
    const isJobPage = this.props.history.location.pathname.toLowerCase().startsWith("/joblisting");
    const isProfilePage = this.props.history.location.pathname.toLowerCase().startsWith("/profile");
    const isJobRequirementPage = this.props.history.location.pathname.toLowerCase().startsWith("/jobrequirements");
    const isJobManagmentPage = this.props.history.location.pathname.toLowerCase().startsWith("/jobmanagement");
    const isSystemManagmentPage = this.props.history.location.pathname.toLowerCase().startsWith("/admin/sm");
    const isRolesAndPermissionsPage = this.props.history.location.pathname.toLowerCase().startsWith("/rolespermissions");
    const isMangeUserPage = this.props.history.location.pathname.toLowerCase().startsWith('/AdminConsole')


    const onClickCreateRequirement = (e: any) => {
      this.onCloseJobRequirementMenu(e);
      localStorage.removeItem("jobDetailValues");
      localStorage.removeItem("id");
      this.props.history.push("/jobmanagement");
    }

    return (
      <Fragment>
        <View style={styles.container}>
          <View style={{ width: '100%', alignItems: 'center', backgroundColor: "#141d3d", }}>
            <View style={styles.appBar}>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image source={assets.logoImage} style={styles.logoImage} resizeMode="contain" />
                <View style={{ marginHorizontal: 24, justifyContent: "center" }}>
                  <AiOutlineSearch style={styles.searchInputIcon} />
                  <TextInput style={styles.searchInput} placeholder={"Search for profiles"} placeholderTextColor={"darkgray"} />
                </View>
                <TouchableOpacity>
                  <Text style={{ color: "darkgray" }}>Advanced Search</Text>
                </TouchableOpacity>
              </View>

              {this.state.profileData ?
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FiMessageSquare style={styles.rightNavIcon} />
                  <BsBell style={styles.rightNavIcon} />

                  <TouchableOpacity onPress={() => this.setState({ showRightDrawer: true })}>
                    <Image source={this.state.profileImage ? { uri: this.state.profileImage } : assets.userProfileImage} resizeMode={"cover"} style={styles.profilePicture} />
                  </TouchableOpacity>
                  <TemporaryDrawer {...this.props} openRight={this.state.showRightDrawer} closeRight={() => this.setState({ showRightDrawer: false })} />
                </View>
                :
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity onPress={() => { this.props.history.push("/Login") }} style={[styles.button, styles.loginButton]}><Text style={[styles.buttonText]}>LOGIN</Text></TouchableOpacity>
                </View>
              }
            </View>
          </View>
          <View style={{ width: '100%', alignItems: 'center', backgroundColor: "#ffffff", }}>
            <View style={styles.menuBar}>
              <View style={{ flexDirection: "row", alignItems: "center", zIndex: 1 }}>
                <TouchableOpacity onPress={() => this.props.history.push("/")} style={[styles.menuOptionContainer, isHomePage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isHomePage && styles.menuOptionActive]}>Home</Text></TouchableOpacity>
                <View style={{ flexDirection: "row" }}>
                  <span onMouseMove={(e) => isJobRequirementPage ? this.onOpenJobRequirementMenu(e) : this.onCloseJobRequirementMenu()}  >
                    <TouchableOpacity onPress={(e: any) => isJobRequirementPage ? this.onOpenJobRequirementMenu(e) : this.props.history.push("/JobRequirements")} style={[styles.menuOptionContainer, isJobRequirementPage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isJobRequirementPage && styles.menuOptionActive]}>Requirements<AiOutlineCaretDown style={{ marginLeft: 10 }} /></Text></TouchableOpacity>
                  </span>
                </View>

                <TouchableOpacity style={[styles.menuOptionContainer, false && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, false && styles.menuOptionActive]}>Reports</Text></TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.history.push("/JobListing")} style={[styles.menuOptionContainer, isJobPage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isJobPage && styles.menuOptionActive]}>Jobs</Text></TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.history.push("/profile")} style={[styles.menuOptionContainer, isProfilePage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isProfilePage && styles.menuOptionActive]}>Profile</Text></TouchableOpacity>
                <TouchableOpacity onPress={(e) => this.setState({ showSystemManagementMenu: true, anchorEl: e.target })} style={[styles.menuOptionContainer, isSystemManagmentPage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isSystemManagmentPage && styles.menuOptionActive]}>System Management</Text></TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.history.push("/rolespermissions")} style={[styles.menuOptionContainer, isRolesAndPermissionsPage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isRolesAndPermissionsPage && styles.menuOptionActive]}>Roles & Permissions</Text></TouchableOpacity>
                <TouchableOpacity onPress={(e) => this.setState({ showManageUserMenu: true, anchorEl: e.target })} style={[styles.menuOptionContainer, isMangeUserPage && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, isMangeUserPage && styles.menuOptionActive]}>Manage Users</Text></TouchableOpacity>

              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity style={[styles.menuOptionContainer, false && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, false && styles.menuOptionActive]}>Start Live Chat</Text></TouchableOpacity>
                <TouchableOpacity style={[styles.menuOptionContainer, { marginRight: 0 }, false && styles.menuOptionContainerActive]}><Text style={[styles.menuOption, false && styles.menuOptionActive]}>Help</Text></TouchableOpacity>
              </View>



            </View>
          </View>
        </View >

        <Menu
          id="job-requirement-menu"
          open={this.state.showJobRequirementMenu}
          onClose={(e) => this.onCloseJobRequirementMenu(e)}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          style={{ zIndex: 0, marginTop: 50 }}
          MenuListProps={{ onMouseLeave: () => this.onCloseJobRequirementMenu() }}
        >
          <MenuItem onClick={onClickCreateRequirement}>Create a Requirement</MenuItem>
          <MenuItem onClick={(e) => this.onCloseJobRequirementMenu(e)}>Bulk Upload</MenuItem>
        </Menu>

        <Menu
          id="manage-users-menu"
          open={this.state.showManageUserMenu}
          onClose={(e) => this.onCloseManageUser(e)}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          style={{ zIndex: 0, marginTop: 50 }}
          MenuListProps={{ onMouseLeave: () => this.onCloseManageUser() }}
        >
          <MenuItem onClick={() => this.manageUserNavigation('/admin/manage-users/admin')}>Admin</MenuItem>
          <MenuItem onClick={() => this.manageUserNavigation('/admin/manage-users/recruiter')}>Recruiter</MenuItem>
        </Menu>

        <Menu
          id="system-management-menu"
          open={this.state.showSystemManagementMenu}
          onClose={this.onCloseSystemManagementMenu}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          style={{ zIndex: 0, marginTop: 50 }}
          MenuListProps={{ onMouseLeave: () => this.onCloseSystemManagementMenu() }}
        >
          <MenuItem onClick={() => this.systemManagementNavigation("/admin/system-management/company")}>Company</MenuItem>
          <MenuItem onClick={() => this.systemManagementNavigation("/admin/system-management/skill")}>Skills</MenuItem>
          <MenuItem onClick={() => this.systemManagementNavigation("/admin/system-management/status")}>Status</MenuItem>
          <MenuItem onClick={() => this.systemManagementNavigation("/admin/system-management/attribute")}>Attributes</MenuItem>
          <MenuItem onClick={() => this.systemManagementNavigation("/admin/system-management/profile-source")}>Profile Source</MenuItem>
        </Menu>
      </Fragment >
    );
  }

};


// 1200px and up
const baseStyles = {
  container: {
    position: "fixed",
    height: 94,
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3
  },
  appBar: {
    height: 52,
    backgroundColor: "#141d3d",
    // paddingHorizontal: "6%",
    width: "85%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  menuBar: {
    height: 42,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "85%",
    alignSelf: 'center'
    // paddingHorizontal: "6%"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  searchInput: {
    backgroundColor: "#283255",
    height: 32,
    width: 450,
    borderRadius: 3,
    color: "darkgray",
    paddingLeft: 46,
    outlineStyle: "solid",
    outlineColor: "#141d3d"
  },
  searchInputIcon: {
    color: "darkgray",
    fontSize: 20,
    position: "absolute",
    marginLeft: 12
  },
  rightNavIcon: {
    color: "white",
    fontSize: 20,
    marginRight: 14
  },
  profilePicture: {
    height: 28,
    width: 28,
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "white"
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  loginButton: {
    height: 32,
    width: 100
  },
  menuOptionContainer: {
    height: 41,
    marginRight: 42,
    borderBottomWidth: 1,
    borderColor: "#ffffff",
    justifyContent: "center"
  }
  ,
  menuOptionContainerActive: {
    borderColor: "#e45d33"
  }
  ,
  menuOption: {
    color: "rgba(0, 0, 0, 0.6)"
  },
  menuOptionActive: {
    color: "#e45d33"
  }
}

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
  }),
);


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function TemporaryDrawer(props: any) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: any) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={() => props.history.push("/Login")} >
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
      {/*
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
        */}
    </div>
  );

  return (
    <span>
      <Drawer anchor="right" open={props.openRight} onClose={props.closeRight}>
        {list("right")}
      </Drawer>
    </span>
  );
}


export default withRouter(TopNav);