import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import Storage from "../../../../framework/src/StorageProvider.web";
import * as yup from 'yup';
import moment from "moment";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import {
  imgPasswordVisible,
  imgPasswordInVisible,
  labelHeader,
  errorTitle,
  errorBothPasswordsNotSame,
  errorEmailNotValid,
  validationApiContentType,
  urlGetValidations,
  validationApiMethodType,
  errorAllFieldsAreMandatory,
  contentTypeApiAddDetail,
  apiEndPointAddDetail,
  apiMethodTypeAddDetail,
  contentTypeApiUpdateUser,
  apiEndPointUpdateUser,
  apiUpdateUserType,
  labelFirstName,
  lastName,
  labelEmail,
  labelPassword,
  labelLegalText,
  labelRePassword,
  labelLegalTermCondition,
  labelLegalPrivacyPolicy,
  btnTextSignUp,
  errorPasswordNotValid,

  contentTypeApiGetEmployeHistory,
  apiEndPointEmployeHistory,
  apiEmployeHistoryType,

  contentTypeApiDeleteEmployeHistory,
  apiEndPointDeleteEmployeHistory,
  apiDeleteEmployeHistoryType,

  contentTypeApiAddEmployeHistory,
  apiEndPointAddEmployeHistory,
  apiAddEmployeHistoryType,

  contentTypeApiUpdateEmployeHistory,
  apiEndPointUpdateEmployeHistory,
  apiUpdateEmployeHistoryType
} from "./config";
import { throws } from "assert";

export interface Props {
  navigation: any;
  id: string;
  parent?: any;
}

export interface S {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  step: number;
  loading: boolean;
  isStep2Completed: boolean;
  isStep3Completed: boolean;
  employmentHistory: Array<any>;
  profileData: any;
  looking_for_job: boolean;
  showActivationError: boolean;
  whatsappCountryCode: string;
  otpTime: string | undefined;
  otpToken: string;
  otpCode: any;
  otpVerified: boolean;
  profileImage: any;
  profileImageError: string;
  countries: Array<any>;
  stepChecked: boolean;
}

export interface SS {
  id: any;
}

export default class AdditionalDetailFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  addAdditionalDetailApiCallId: any;
  apiCallMessageUpdateProfileRequestId: any;
  apiCallMessageGetCountryListRequestId: any;
  apiCallMessageGetEmployeHistoryRequestId: any;
  apiCallMessageDeleteEmployeHistoryRequestId: any;
  apiCallMessageAddEmployeHistoryRequestId: any;
  apiCallMessageUpdateEmployeHistoryRequestId: any;
  apiCallMessageUploadProfilePhotoRequestId: any;
  apiCallMessageFinishProfileRequestId: any;
  apiCallMessageDeleteProfilePhotoRequestId: any;
  apiCallMessageResendOTPRequestId: any;
  apiCallMessageVerifyOTPRequestId: any;
  apiCallMessageconfirmActivationRequestId: any;
  apiCallMessageGetProfileRequestId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      step: 2,
      loading: false,
      isStep2Completed: false,
      isStep3Completed: false,
      employmentHistory: [],
      profileData: undefined,
      looking_for_job: true,
      showActivationError: false,
      whatsappCountryCode: "91",
      otpTime: undefined,
      otpToken: "",
      otpCode: "",
      otpVerified: false,
      profileImage: undefined,
      profileImageError: "",
      countries: [],
      stepChecked: false
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = labelHeader;
    this.labelFirstName = labelFirstName;
    this.lastName = lastName;
    this.labelEmail = labelEmail;
    this.labelPassword = labelPassword;
    this.labelRePassword = labelRePassword;
    this.labelLegalText = labelLegalText;
    this.labelLegalTermCondition = labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = labelLegalPrivacyPolicy;
    this.btnTextSignUp = btnTextSignUp;
  }




  async receive(from: string, message: Message) {

    console.log("hoola ", message);


    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      console.log("Countrrrr ", selectedCode);

      if (selectedCode !== undefined) {
        this.setState({
          whatsappCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    else if (this.apiCallMessageResendOTPRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageResendOTPRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {


        this.setState({ otpTime: "02:00", otpToken: responseJson.meta.token }, () => {

          const interval = setInterval(() => {


            if (moment.duration(this.state.otpTime).asSeconds() > 0) {
              this.setState({ otpTime: moment(this.state.otpTime, 'mm:ss').subtract(1, 'seconds').format('mm:ss') });
            } else {
              clearInterval(interval);
              this.setState({ otpTime: undefined });
            }

          }, 1000);

        });

      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }

      this.props.parent?.setState({ loading: false });
    }


    else if (this.apiCallMessageUpdateEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {

        this.props.parent.setState({ loading: true }, () => {
          this.getEmploymentHistory();
          this.showAlert("", "Employement history updated successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.props.parent.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.props.parent.setState({ loading: false });
      }


    }

    else if (this.apiCallMessageAddEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageAddEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson && !responseJson.errors) {

        this.props.parent.setState({ loading: true }, () => {
          this.getEmploymentHistory();
          this.showAlert("", "Employement history added successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.props.parent.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.props.parent.setState({ loading: false });
      }

    }
    else if (this.apiCallMessageDeleteEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("responseJson ", responseJson);
      console.log("errorReponse ", errorReponse);

      if (responseJson === undefined || !responseJson.errors) {

        this.props.parent.setState({ loading: true }, () => {
          this.getEmploymentHistory();

          this.showAlert("", "Employement history deleted successfully", "OK");
        });


      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
        this.props.parent.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        this.props.parent.setState({ loading: false });
      }

    }

    else if (this.apiCallMessageGetEmployeHistoryRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetEmployeHistoryRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (responseJson.data?.length > 0) {
          this.setState({ employmentHistory: [] }, () => {
            this.setState({ employmentHistory: responseJson.data });
            this.props.parent.setState({ loading: false });
          });
        } else if (responseJson.data?.length === 0) {
          this.setState({ employmentHistory: [] }, () => {
            this.onAddMoreEmploymentHistory();
            this.props.parent.setState({ loading: false });
          });
        } else {
          this.props.parent.setState({ loading: false });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.props.parent.setState({ loading: false });
    }


    else if (this.apiCallMessageconfirmActivationRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageconfirmActivationRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        //this.goToHome();  
        window.location.href = "/";

      } else if (responseJson) {
        this.parseApiErrorResponse(responseJson);
      } else {

        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.props.parent.setState({ loading: false });

    }

    else if (this.apiCallMessageVerifyOTPRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageVerifyOTPRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ otpVerified: true, otpCode: "", otpToken: responseJson.meta.token });

      } else {
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.props.parent.setState({ loading: false });
    }

    else if (this.apiCallMessageUpdateProfileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUpdateProfileRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const profileData = responseJson.data;
        var step = profileData?.attributes?.step;
        var isStep2Completed = false;
        var isStep3Completed = false;
        if (step === 0) {
          this.goToHome();
        } else {
          step++;
        }

        if (2 < step) {
          isStep2Completed = true;
        }

        if (3 < step) {
          isStep3Completed = true;
        }


        this.props.parent.setState({ step, isStep2Completed, isStep3Completed });
        this.setState({ profileData })
        Storage.set("profileData", profileData);

      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.props.parent.setState({ loading: false });
    }


    else if (this.apiCallMessageGetCountryListRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetCountryListRequestId) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        this.setState({ countries: responseJson.data });

      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.props.parent.setState({ loading: false });
    }





    else if (this.apiCallMessageGetProfileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageGetProfileRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        const profileData = responseJson.data;
        var step = profileData?.attributes?.step;
        var isStep2Completed = false;
        var isStep3Completed = false;
        if (step === 0) {
          this.goToHome();
        } else {
          step++;
        }

        if (2 < step) {
          isStep2Completed = true;
        }

        if (3 < step) {
          isStep3Completed = true;
        }

        step = this.props.parent.state.stepChecked ? this.props.parent.state.step : step;

        this.props.parent.setState({ step, isStep2Completed, isStep3Completed, stepChecked: true });
        this.setState({ profileData })
        Storage.set("profileData", profileData);

      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.props.parent.setState({ loading: false });
    }



    else if (this.apiCallMessageDeleteProfilePhotoRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageDeleteProfilePhotoRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        Storage.set("profileData", responseJson.data);

        this.setState({ profileData: responseJson.data });
        //this.setState({ profileData: undefined }, () => this.setState({ profileData: responseJson.data }));

      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);

      this.props.parent.setState({ loading: false });
    }


    else if (this.apiCallMessageFinishProfileRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageFinishProfileRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        //this.goToHome();
        this.confirmActivation();
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    else if (this.apiCallMessageUploadProfilePhotoRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallMessageUploadProfilePhotoRequestId) {


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {

        Storage.set("profileData", responseJson.data);
        this.setState({ profileData: responseJson.data });
        //this.setState({ profileData: undefined }, () => this.setState({ profileData: responseJson.data }))
      } else {

        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
      this.props.parent.setState({ loading: false });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.addAdditionalDetailApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }


  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  async getEmploymentHistory() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiGetEmployeHistory,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointEmployeHistory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiEmployeHistoryType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async addEmploymentHistory(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      // "Content-Type": contentTypeApiAddEmployeHistory,
      token: authToken
    };

    const formData = new FormData();

    formData.append('data[company_logo]', values.company_logo);
    formData.append('data[job_title]', values.job_title);
    formData.append('data[employment_type]', values.employment_type);
    formData.append('data[company]', values.company);
    formData.append('data[start_date]', values.start_date);
    formData.append('data[end_date]', values.end_date);

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageAddEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointAddEmployeHistory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiAddEmployeHistoryType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateEmploymentHistory(values: any, id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      //"Content-Type": contentTypeApiUpdateEmployeHistory,
      token: authToken
    };

    let data: any = values;


    const formData = new FormData();

    if (!(typeof values.company_logo === "string")) {
      formData.append('data[company_logo]', values.company_logo);
    }
    formData.append('data[job_title]', values.job_title);
    formData.append('data[employment_type]', values.employment_type);
    formData.append('data[company]', values.company);
    formData.append('data[start_date]', values.start_date);
    formData.append('data[end_date]', values.end_date);

    const httpBody = formData;


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointUpdateEmployeHistory + "/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiUpdateEmployeHistoryType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteEmploymentHistory(id: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiDeleteEmployeHistory,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointDeleteEmployeHistory + "/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiDeleteEmployeHistoryType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onAddMoreEmploymentHistory() {

    if (this.state.employmentHistory.find((item) => item.edit == true)) {
      this.showAlert("",
        "Please save or cancel previous form first", "");
      return;
    }


    this.state.employmentHistory.push(
      {
        "id": "",
        "type": "employment_history",
        "attributes": {
          "company_logo": undefined,
          "job_title": "",
          "employment_type": "full_time",
          "company": "",
          "start_date": "",
          "end_date": ""
        },
        "edit": true
      }
    );

    this.forceUpdate();
  }

  onEditEmploymentHistory(index: number) {
    this.state.employmentHistory[index].edit = true;

    this.forceUpdate();
  }

  onCancelEmploymentHistory(index: number) {
    if (this.state.employmentHistory[index].id === "") {
      var employmentHistory = this.state.employmentHistory.filter((item, idx) => idx !== index);
      this.setState({ employmentHistory });
    } else {
      this.state.employmentHistory[index].edit = false;
      this.forceUpdate();
    }
  }


  async getProfile() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiGetEmployeHistory,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetEmployeHistoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointEmployeHistory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiEmployeHistoryType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async getCountryList() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetCountryListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/country_code_and_flag"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  async updateProfilePhoto(file: any) {

    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      //"Content-Type": "multipart/form-data",
      token: authToken
    };

    const formData = new FormData();

    formData.append('data[avatar]', file);

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUploadProfilePhotoRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointUpdateUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async updateProfile(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiUpdateUser,
      token: authToken
    };

    let data: any = { ...values, looking_for_job: true, "employment_type": "full_time" };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointUpdateUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async fetchProfile() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "profile/profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteProfilePhtoto() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiUpdateUser,
      token: authToken
    };

    let data: any = {
      "avatar": null
    };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageDeleteProfilePhotoRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointUpdateUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async resendVerificationOTP(email: string) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: authToken
    };

    let data: any = {
      "type": "email_account",
      "attributes": {
        "email": email || ""
      }
    };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageResendOTPRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account/accounts/send_otp'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async confirmActivation() {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: this.state.otpToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageconfirmActivationRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account/accounts/email_confirmation'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  async verifyOTP(otpCode: number) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": "application/json",
      token: this.state.otpToken
    };


    const httpBody = {
      "otp_code": otpCode
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageVerifyOTPRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account/accounts/email_otp_confirmation'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  async onFinishProfile(values: any) {
    const authToken = await Storage.get("authToken");

    //Call update API
    const header = {
      "Content-Type": contentTypeApiUpdateUser,
      token: authToken
    };

    let data: any = values;


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageFinishProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointUpdateUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  addAdditionalDetail(): boolean {

    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(errorTitle, errorAllFieldsAreMandatory);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(errorTitle, errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(errorTitle, errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(errorTitle, errorBothPasswordsNotSame);
      return false;
    }

    const header = {
      "Content-Type": contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdditionalDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  processOnClickMessage(messageID: string, values: any = null) {
    switch (messageID) {
      case 'btnLegalTermsAndCondition':
        this.goToTermsAndCondition();
        break;
      case 'btnLegalPrivacyPolicy':
        this.goToPrivacyPolicy();
        break;
      case 'btnSignUp':
        this.addAdditionalDetail();
        break;
      default:
        console.log("processOnClickMessage::Not Confifured for message " + messageID);
        break;
    }

  }

  public changeState(objectID: string, value: any = null) {
    switch (objectID) {
      case 'txtInputFirstName':
        this.setState({ firstName: value });
        break;
      case 'txtInputLastName':
        this.setState({ lastName: value });
        break;
      case 'txtInputEmail':
        this.setState({ email: value });
        break;
      case 'txtInputPassword':
        this.setState({ password: value });
        break;
      case 'btnPasswordShowHide':
        this.setState({ enablePasswordField: value });
        break;
      case 'txtInputConfirmPassword':
        this.setState({ reTypePassword: value });
        break;
      case 'btnConfirmPasswordShowHide':
        this.setState({ enableReTypePasswordField: value });
        break;
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  }

  getState(objectID: string) {
    switch (objectID) {
      case 'txtInputFirstName':
        return this.state.firstName;
      case 'enablePasswordField':
      case 'txtInputPassword':
      case 'imgEnablePasswordField':
      case 'txtInputConfirmPassword':
      case 'btnPasswordShowHide':
        return this.state.enablePasswordField;
      case 'enableConfirmPasswordField':
      case 'btnConfirmPasswordShowHide':
      case 'imgEnableRePasswordField':
        return this.state.enableReTypePasswordField;
      default:
        console.log("getState::Not Confifured for " + objectID);
        return null;
    }
  }

  geBasicInformationFormValidations() {
    const validations = yup.object().shape({
      /*
      avatar: yup.mixed()
        .required("Avatar is required."),*/
      first_name: yup.string()
        .min(3, "Minimum 3 characters are required")
        .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("First Name is required."),
      last_name: yup.string()
        .min(3, "Minimum 3 characters are required")
        .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed.")
        .required("Last Name is required."),
      new_phone_number: yup.number()
        .typeError("Only Numbers are allowed")
        .required("Phone is required."),
      new_alternate_number: yup.number()
        .typeError("Only Numbers are allowed")
        .notRequired(),
      new_whatsapp_number: yup.number()
        .typeError("Only Numbers are allowed"),
      country: yup.string()
        .required("Country is required."),
      state: yup.string()
        .required("State is required."),
      city_district: yup.string()
        .required("City is required."),
    });

    return validations;
  }

  getEmploymentHistoryFormValidations() {
    const validations = yup.object().shape({
      "company_logo": yup.mixed()
        .required("Company logo is required."),
      "job_title": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Job Title is required."),
      "employment_type": yup.string()
        .required("Employment Type is required."),
      "company": yup.string()
        .min(5, "Minimum 5 characters are required")
        .matches(/^[a-zA-Z0-9 .]+$/, "Only alphabets and numbers are allowed.")
        .required("Company is required."),
      "start_date": yup.string()
        .required("Start Date is required."),
      "end_date": yup.string()
        .required("End Date is required.")
    });

    return validations;
  }

}
