Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboard";
exports.dashboarContentType = "application/json";
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"
exports.patchAPiEndMethod = "PATCH"
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.endPointApiGetUserProfile = "profile/profile";
exports.endPointApiUpdatePassword = "profile/password";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.btnTextSignUp = "SAVE & CONTINUE";



// Customizable Area End
