import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Avatar, Grid, Dialog, DialogContent, DialogActions, Select, MenuItem, CircularProgress, IconButton, Table, TableBody, TableCell, Checkbox, TableHead, TableRow, Icon, Button } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import csc from 'country-state-city';
import { Search, ArrowDropDown, Send, ChevronLeft, Done, Close, ChevronRight, DeleteOutline, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { editIcon, hideIcon, showIcon } from './assets'
import AdminPreviewModal from './AdminPreviewModal.web'
import AdminModal from './AdminModal.web'
// Customizable Area End

import AdminConsoleController, {
  Props,
  configJSON,
} from "./AdminConsoleController";

class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    console.log('Navigation', this.props.navigation, window.location.pathname.split('/')[3])

    this.setState({
      adminType: window.location.pathname.split('/')[3]
    }, () => {
      this.getDefaultPermissionList()
      this.getCountryList()
    })

  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const {
      adminList,
      pagination,
      hoveredStatusIndex,
      deletedAdmins,
      showDeleteConfirmation,
      adminType
    } = this.state;

    return (
      <Box className={classes.container} >
        <Box className={classes.listContainer} >
          <Grid sm={12} container={true} style={{ height: '100%', display: 'block' }} >
            <Grid sm={12} container={true}  >
              <Grid sm={8} container={true} >
                <Box style={{ padding: 24, }} >
                  <Typography style={{
                    fontFamily: 'Poppins',
                    fontSize: 20,
                    fontWeight: 500,
                    color: '#1a181d'
                  }} >{`Manage ${adminType === 'admin' ? 'Admin' : 'Recruiters'}`}</Typography>
                  <Typography style={{
                    opacity: 0.4,
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}>Lorem Ipsume demo contetn should be here so we can see how it works.</Typography>
                </Box>
              </Grid>
              <Grid alignItems='flex-end' sm={4} container={true} style={{
                paddingTop: 24,
                paddingRight: 24,
                paddingBottom: 24,
                flexDirection: 'column'
              }} >

                <Button onClick={() => this.openModalHandler()} style={{
                  fontFamily: 'Roboto',
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#e45d33',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }} >CREATE</Button>

                <Box className={classes.searchBoxContainer} >
                  <input required style={{
                    width: '100%',
                    padding: 0,
                    border: 'none',
                    outline: 'none',
                    height: '100%',
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.6)',
                    paddingLeft: 14
                  }}
                    type='text'
                    value={this.state.searchKeyword}
                    onChange={(e: any) => this.handleSearch(e.target.value)}
                    placeholder='Search Here'
                  />
                  <Search style={{ color: '#000000', width: 17, opacity: 0.57, height: 17, marginRight: 22 }} />
                </Box>
              </Grid>
            </Grid>
            <Grid sm={12} justify='space-between' alignItems='center' container={true} style={{
              backgroundColor: '#f1f1f1',
              height: 52,
              borderRadius: 2,
              paddingRight: 24,
              paddingLeft: 16,
            }} >
              <Box display='flex' alignItems='center'>
                {this.state.adminList.data.length > 0 && (
                  <Fragment>
                    <Checkbox
                      checked={deletedAdmins.length === adminList.data.length}
                      onChange={() => this.handleAllCheck()}
                      style={{ padding: 0, marginRight: 10 }}
                      checkedIcon={<CheckBox style={{ color: '#e45d33', width: 18, height: 18 }} />}
                      icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 18, height: 18, opacity: 0.57 }} />}
                    />
                    <IconButton onClick={() => this.toggleConfirmationModalHandler()} disabled={deletedAdmins.length === 0} style={{ padding: 0 }}>
                      <DeleteOutline style={{ color: '#000000', width: 18, opacity: 0.6, height: 18 }} />
                    </IconButton>
                  </Fragment>
                )}
              </Box>

              <Box display='flex' alignItems='center' >
                <Typography style={{
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: 'rgba(0, 0, 0, 0.6)'
                }} >Show</Typography>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={pagination.perPage}
                  onChange={this.handlePerpageSelect}
                  IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                  classes={{ select: classes.selectContainer }}
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: 12,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: 4,
                    borderBottom: 'none',
                    border: '1px solid #dddddd',
                    backgroundColor: '#ffffff',
                    width: 44,
                    height: 28,
                    marginRight: 6,
                    marginLeft: 8,
                    padding: 0
                  }}
                  disableUnderline={true}
                  defaultValue={pagination.perPage}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>

                <Typography style={{
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginRight: 12,
                }} >{`Showing ${+pagination.totalCount === 0 ? 1 : pagination.currentPage} to ${+pagination.totalPages === 0 ? 1 : pagination.totalPages} of ${pagination.totalCount}`}</Typography>

                <IconButton onClick={() => this.handlePagination(+pagination.currentPage - 1)} disabled={+pagination.currentPage === 1} style={{ padding: 0, opacity: +pagination.currentPage === 1 ? 0.2 : 1 }}>
                  <ChevronLeft style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                </IconButton>

                <IconButton onClick={() => this.handlePagination(+pagination.currentPage + 1)} disabled={+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages} style={{ padding: 0, opacity: (+pagination.totalPages === 0 ? true : +pagination.currentPage === +pagination.totalPages) ? 0.2 : 1 }}>
                  <ChevronRight style={{ color: '#000000', width: 24, opacity: 0.57, height: 24 }} />
                </IconButton>
              </Box>


            </Grid>
            {!adminList.loading && adminList.data.length > 0 && (
              <Grid sm={12} container={true} style={{ display: 'block', overflowX: 'auto' }} >
                <Table className={classes.table} aria-label="simple table"  >
                  <TableHead style={{ borderBottom: '1px solid rgba(221, 221, 221, 0.57)' }}>
                    <TableRow>
                      <TableCell >
                        <Box display='flex' >
                          <Typography style={{
                            fontFamily: 'Roboto',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.6)'
                          }} >Sort By : </Typography>

                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.order}
                            onChange={this.handleOrder}
                            IconComponent={() => <ArrowDropDown style={{ color: '#000000', width: 18, opacity: 0.7, height: 18, cursor: 'pointer' }} />}
                            classes={{ select: classes.selectContainer }}
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'rgba(0, 0, 0, 0.6)',
                              marginLeft: 5,
                              marginTop: 2,
                              padding: 0
                            }}
                            disableUnderline={true}
                            defaultValue={'default'}
                          >
                            <MenuItem value={'default'}>Default</MenuItem>
                            <MenuItem value={'asc'}>Ascending</MenuItem>
                            <MenuItem value={'desc'}>Descending</MenuItem>
                          </Select>
                        </Box>
                      </TableCell>
                      <TableCell>Dashboard</TableCell>
                      <TableCell>Requirments</TableCell>
                      <TableCell>Reports</TableCell>
                      <TableCell>Profile</TableCell>
                      <TableCell>Manage User</TableCell>
                      <TableCell>Roles & Permissions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminList.data.map((admin: any, i: number) => (
                      <TableRow style={{ backgroundColor: hoveredStatusIndex === `${i}` ? '#f1f1f1' : "#fcfcfc", minHeight: 79 }} onMouseOver={() => { this.handleRowHover(i.toString()) }} onMouseLeave={() => { this.handleRowHover("") }} key={admin.id}>
                        <TableCell className={classes.heading} style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center'>
                            <Box display='flex' width={15} height={15} mr='16px'>
                              <Checkbox
                                checked={_.includes(deletedAdmins, admin.id.toString())}
                                onChange={() => this.handleCheck(admin.id.toString())}
                                style={{ padding: 0, marginRight: 16 }}
                                checkedIcon={<CheckBox style={{ color: '#e45d33', width: 15, height: 15 }} />}
                                icon={<CheckBoxOutlineBlank style={{ color: '#000000', width: 15, height: 15 }} />}
                              />
                            </Box>
                            <Avatar alt={admin.attributes.full_name} style={{ height: 48, width: 48, marginRight: 8 }} src={admin.attributes.avatar} />
                            <Box display='flex' justifyContent='center' flexDirection='column' >
                              <Typography className={classes.heading} >{admin.attributes.full_name.toUpperCase()}</Typography>
                              <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 16, marginTop: 3 }}>{`${admin.attributes.email} | +${admin.attributes.country_code} ${admin.attributes.phone_number}`}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center' >
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                            {admin.permissions['dashboard']?.read_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                            {admin.permissions['dashboard']?.write_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                            {admin.permissions['dashboard']?.delete_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center' >
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                            {admin.permissions['requirements']?.read_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                            {admin.permissions['requirements']?.write_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                            {admin.permissions['requirements']?.delete_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center' >
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                            {admin.permissions['reports']?.read_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                            {admin.permissions['reports']?.write_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                            {admin.permissions['reports']?.delete_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center' >
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                            {admin.permissions['profile']?.read_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                            {admin.permissions['profile']?.write_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                            {admin.permissions['profile']?.delete_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} >
                          <Box display='flex' alignItems='center' >
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                            {admin.permissions['manage_user']?.read_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                            {admin.permissions['manage_user']?.write_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                            <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                            {admin.permissions['manage_user']?.delete_permission ? (
                              <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                            ) : (
                              <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                            )}
                          </Box>
                        </TableCell>
                        {hoveredStatusIndex !== `${i}` && (

                          <TableCell style={{ borderBottom: 'none' }} >
                            <Box display='flex' alignItems='center' >
                              <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4 }}>R</Typography>
                              {admin.permissions['roles_and_permissions']?.read_permission ? (
                                <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                              ) : (
                                <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                              )}
                              <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>W</Typography>
                              {admin.permissions['roles_and_permissions']?.write_permission ? (
                                <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                              ) : (
                                <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                              )}
                              <Typography className={classes.heading} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14, marginRight: 4, marginLeft: 10 }}>D</Typography>
                              {admin.permissions['roles_and_permissions']?.delete_permission ? (
                                <Done style={{ color: '#299c14', width: 16, height: 16 }} />
                              ) : (
                                <Close style={{ color: '#9c1f14', width: 16, height: 16 }} />
                              )}
                            </Box>
                          </TableCell>
                        )}
                        {hoveredStatusIndex === `${i}` && (
                          <TableCell className={classes.heading} style={{ borderBottom: 'none' }} align='right' >
                            <Box display='flex' alignItems='center' justifyContent='flex-end' height='18px'>
                              <IconButton onClick={() => this.openPreviewModalHandler(admin)} style={{ padding: 0, marginRight: 10 }}>
                                <Icon>
                                  <img src={showIcon} style={{ width: 18, height: 18 }} />
                                </Icon>
                              </IconButton>
                              <IconButton onClick={() => this.resendEmail(admin.id)} style={{ padding: 0, marginRight: 10 }}>
                                <Send style={{ color: '#000000', width: 18, height: 18 }} />
                              </IconButton>
                              <IconButton onClick={() => this.openModalHandler(admin, true)} style={{ padding: 0, marginRight: 10 }}>
                                <Icon>
                                  <img src={editIcon} style={{ width: 18, height: 18 }} />
                                </Icon>
                              </IconButton>
                              <IconButton onClick={() => this.toggleConfirmationModalHandler(admin.id)} style={{ padding: 0 }}>
                                <DeleteOutline style={{ color: '#000000', width: 18, height: 18 }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}
            {adminList.loading && (
              <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='65%' >
                <CircularProgress style={{ color: "#e45d33" }} />
              </Box>
            )}
            {!adminList.loading && adminList.data.length === 0 && (
              <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='55%' >
                <Typography style={{
                  fontFamily: 'Roboto',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.6)'
                }} >No records to display</Typography>
              </Box>
            )}
          </Grid>
        </Box>
        <AdminPreviewModal
          adminDetails={this.state.adminDetails}
          showModal={this.state.showPreviewModal}
          closeModalHandler={this.closePreviewModalHandler}
        />
        <AdminModal
          adminDetails={this.state.adminDetails}
          showModal={this.state.showModal}
          isEdit={this.state.isEdit}
          errors={this.state.errors}
          countries={this.state.countries}
          states={this.state.states}
          cities={this.state.cities}
          createUpdateLoading={this.state.createUpdateLoading}
          adminType={this.state.adminType}
          closeModalHandler={this.closeModalHandler}
          avatarHandler={this.avatarHandler}
          deleteAvatarHandler={this.deleteAvatarHandler}
          onChangeInputHandler={this.onChangeInputHandler}
          onBlurInputHandler={this.onBlurInputHandler}
          updatePermissionHandler={this.updatePermissionHandler}
          createAdminHandler={this.createAdminHandler}
        />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={showDeleteConfirmation}
        >
          <DialogContent dividers>
            <Typography style={{
              fontFamily: 'Roboto',
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)'
            }} >{`Are you sure you want to delete ${deletedAdmins.length > 1 ? 'these' : 'this'} admin?`}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => this.toggleConfirmationModalHandler()}>
              No
            </Button>
            <Button style={{ color: '#e45d33' }} onClick={() => this.deleteAdmin()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box >
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: "9.5rem",
    paddingBottom: "7.8%",
    backgroundColor: "#fcfcfc",
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '85%',
    borderRadius: 2,
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    overflow: 'hidden'
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    borderRadius: 2,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    height: 40,
    marginTop: 21,
    width: '100%',
    maxWidth: 414
  },
  selectContainer: {
    padding: '0px !important',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      backgroundColor: '#ffffff',
    }
  },
  clolumnHeading: {
    fontFamily: 'Poppins',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  heading: {
    fontFamily: 'Poppins',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.87)'
  }
});

export default withStyles(styles)(AdminConsole)
// Customizable Area End
