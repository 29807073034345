//This an auto generated file for Artboard Name = Login And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React, { Fragment } from 'react';
import { Formik } from 'formik';
import Icon from 'react-native-vector-icons/FontAwesome';
import { FaCircle } from "react-icons/fa";
import Storage from "../../../../framework/src/StorageProvider.web";
import moment from "moment";
import { FaCheckCircle } from 'react-icons/fa';
import * as Utilities from "../../../../framework/src/Utilities";
import TextField from '@material-ui/core/TextField';
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import AdditionalDetailFormController, {
  Props
} from "./AdditionalDetailFormController";


export default class VerificationAndStatusForm extends AdditionalDetailFormController {
  constructor(props: Props) {
    super(props);

    Storage.get("profileData").then((profileData) => this.setState({ profileData }));
  }

  onFinish() {
    if (this.state.otpVerified) {
      this.props.parent.setState({ loading: true }, () => {
        this.onFinishProfile({ looking_for_job: this.state.looking_for_job, step: 0,"employment_type":"full_time" });
      });
    } else {
      this.setState({ showActivationError: true });
    }
  }


  onVerify() {

    if (this.state.otpCode == "") {

      this.showAlert(
        "",
        "Please enter verification code.",
        "OK"
      );
      return;
    }


    this.props.parent.setState({ loading: true }, () => this.verifyOTP(Number(this.state.otpCode)));
  }


  async componentDidMount() {
    super.componentDidMount();


    Storage.get("profileData").then((data) => {
      this.props.parent.setState({ loading: true }, () => this.resendVerificationOTP(data?.attributes?.email));
    });

  }


  render() {
    const styles = getStyles();
    const assets = require("./assets");
    const isEmailValid = this.emailReg.test(this.state.email) && this.state.email != "";
    const isPasswordValid = this.state.password != "";
    const isStep1Complete = true;
    const isStep2Complete = false;
    const isStep3Complete = false;
    const isStep4Complete = false;
    const profileData = this.state.profileData;

    return this.state.profileData ? (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <TouchableOpacity style={[styles.button, { marginRight: 80 }]} onPress={() => Utilities.logoutUser("force", () => this.goToLogin())}><Text style={styles.buttonText}>LOG OUT</Text></TouchableOpacity>
        </View>
        <View style={{ minHeight: "calc( 100vh - 116px )", width: "100%", flexDirection: "row" }}>
          <View style={styles.leftContainer}>

            <View>
              <Text style={styles.rightTitle}>Verification & Status</Text>
              <Text style={styles.rightSubTitle}>Enter the code sent to your email ID</Text>

              <Text style={styles.formLabel}>Confirm your email address</Text>

              <View style={styles.formRow}>
                <View style={[styles.inputWrapper, { width: "100%" }]} >
                  <TextField onChange={(e) => this.setState({ otpCode: e.target.value })} value={this.state.otpCode} variant={"outlined"} disabled={this.state.otpVerified} label={this.state.otpVerified ? "Email Verified" : "Verification Code"} error={this.state.showActivationError} helperText={this.state.showActivationError && "Email verification is required."} style={styles.plainTextInput} />
                  <View style={{ position: "absolute", right: 5, height: 56, justifyContent: "center" }}>
                    {this.state.otpVerified ?
                      <FaCheckCircle size={24} color="green" style={styles.textInputIcon} />
                      :
                      <TouchableOpacity onPress={() => this.onVerify()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33", height: 40, width: 120, marginRight: 10 }]}><Text style={[styles.buttonText, { color: "#ffffff" }]} >VERIFY CODE</Text></TouchableOpacity>
                    }
                  </View>
                </View>
              </View>
              {!this.state.otpVerified &&
                <View style={{ alignItems: "flex-end" }}>
                  {this.state.otpTime != undefined ?
                    <View>
                      <Text style={{ fontSize: 14, color: "red" }}>Resend Code in {this.state.otpTime}</Text>
                    </View>
                    :
                    <TouchableOpacity onPress={() => this.props.parent.setState({ loading: true }, () => this.resendVerificationOTP(this.state?.profileData?.attributes?.email))} ><Text style={{ fontSize: 14, color: "gray" }}>RESEND CODE</Text></TouchableOpacity>
                  }
                </View>
              }

              <Text style={styles.formLabel}>Are you looking for a job?</Text>

              <View style={[styles.formRow, { justifyContent: "flex-start" }]}>
                <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }} >
                  <TouchableOpacity style={styles.radio} onPress={() => this.setState({ looking_for_job: true })}>
                    {this.state.looking_for_job &&
                      <FaCircle size={8} color="#e45d33" />
                    }
                  </TouchableOpacity>
                  <Text style={[styles.text, { marginLeft: 10 }]} >Yes</Text>
                </View>
                <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }} >
                  <TouchableOpacity style={styles.radio} onPress={() => this.setState({ looking_for_job: false })}>
                    {!this.state.looking_for_job &&
                      <FaCircle size={8} color="#e45d33" />
                    }
                  </TouchableOpacity>
                  <Text style={[styles.text, { marginLeft: 10 }]} >No</Text>
                </View>
              </View>


            </View>

            <View style={styles.bottomContainer}>
              <TouchableOpacity onPress={() => { this.props.parent.setState({ step: 3 }); }} style={styles.button}><Text style={styles.buttonText}>PREVIOUS STEP</Text></TouchableOpacity>
              <TouchableOpacity onPress={() => this.onFinish()} style={[styles.button, { backgroundColor: "#e45d33", borderColor: "#e45d33" }]}><Text style={[styles.buttonText, { color: "#ffffff" }]}>FINISH</Text></TouchableOpacity>
            </View>
          </View>
          <View style={styles.rightContainer}>
            <Image source={assets.rightPeople} style={styles.rightImage} resizeMode="contain" />
          </View>
        </View>
      </View>
    ) :
      <View></View>;
  }

}


// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "calc(100% - 116px)"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    width: 140,
    backgroundColor: "#ffffff",
    borderColor: "#ededed",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#7e7e7e",
    letterSpacing: 1.25
  },
  topContainer: {
    width: "100%",
    height: 116,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center"

  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
  ,
  logoImage: {
    width: 35,
    height: 35
  },
  stepsContainer: {
    marginTop: 80
  },
  stepText: {
    color: "rgba(255,255,255,0.3)"
  },
  stepTextActive: {
    color: "#ffffff"
  },
  stepIconContainer: {
    marginRight: 15,
    height: 13,
    width: 13,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIconContainerActive: {
    height: 15,
    width: 15,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ffffff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepIcon: {
    fontSize: 8,
    color: "rgba(255,255,255,0.3)"
  },
  stepIconActive: {
    fontSize: 10,
    color: "rgb(20,29,61)"
  },
  leftTitle: {
    fontSize: 24,
    color: "#ffffff",
    marginTop: 50
  },
  rightTitle: {
    fontSize: 24,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
  },
  rightSubTitle: {
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: 5
  },
  formLabel: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 30,
    marginBottom: 10
  },
  textInputContainer: {
    alignItems: "center",
    height: 52,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#f44336",
    paddingLeft: 14,
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    marginTop: 3,
    fontWeight: "400",
    position: "absolute"
  }
  ,
  leftContainer: {
    width: "70%",
    minHeight: "100%",
    justifyContent: "space-between",
    paddingHorizontal: "8%",
    paddingBottom: "3%"
  },
  rightContainer: {
    width: "30%",
    minHeight: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  rightImage: {
    width: "21vw",
    height: "21vw"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWrapper: {
    width: "49%"
  },
  radio: {
    height: 20,
    width: 20,
    borderWidth: 2,
    borderColor: "silver",
    backgroundColor: "white",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1335
  maxWidth(1335, {
    leftContainer: {
      paddingHorizontal: "4%"
    }
  }),
  // below 1200
  maxWidth(1200, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below 992
  maxWidth(992, {
  }),
  // below  768
  maxWidth(768, {
    leftContainer: {
      paddingHorizontal: "4%",
      width: "100%"
    }
  }),
  // below  626
  maxWidth(626, {
    leftContainer: {
      paddingHorizontal: "2%",
      width: "100%"
    }
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);
