//This an auto generated file for Artboard Name = JobListing And Supports ReactNative Ver. = 0.62
import { Dimensions, PixelRatio, View, Text, FlatList, SectionList, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';


let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension 
let artBoardHeightOrg = 0;
let artBoardWidthOrg = 0;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
	return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
}

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0;

function deviceBasedDynamicDimension(originalDimen: number, compareWithWidth: boolean, resizeFactor: number): number | undefined {
	if (originalDimen != null) {
		if (resizeFactor != null) {
			originalDimen *= resizeFactor;
		}
		const compareArtBoardDimenValue = compareWithWidth ? artBoardWidth : artBoardHeight;
		const artBoardScreenDimenRatio = (originalDimen * 100) / compareArtBoardDimenValue;
		let compareCurrentScreenDimenValue = compareWithWidth ? screenWidth : screenHeight - extraSpace;
		return PixelRatio.roundToNearestPixel((artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100,);
	}
	return undefined;
}

export default class JobListing extends React.Component {
	constructor(props: any) {
		super(props);
	}

	state = {
	}


	componentDidMount() {
	}

	render() {
		return <View
			style={styles.JobListingView}>
			<View
				style={styles.view_shape382}>
			</View>
			<View
				style={styles.view_shape381}>
			</View>
			<View
				style={styles.view_shape380}>
			</View>
			<View
				style={styles.view_shape379}>
			</View>
			<Text
				style={styles.textlabel_text183Text}>Paul Klein
			</Text>
			<Text
				style={styles.textlabel_text182Text}>Ethel Rivera
			</Text>
			<Text
				style={styles.textlabel_text181Text}>Bradley Patrick
			</Text>
			<Text
				style={styles.textlabel_text180Text}>Alta Rice
			</Text>
			<Text
				style={styles.textlabel_text179Text}>Verna Potter
			</Text>
			<Text
				style={styles.textlabel_text178Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<Text
				style={styles.textlabel_text177Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<Text
				style={styles.textlabel_text176Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<Text
				style={styles.textlabel_text175Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<Text
				style={styles.textlabel_text174Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<View
				style={styles.view_shape378}>
			</View>
			<Text
				style={styles.textlabel_text173Text}>3.5
			</Text>
			<Text
				style={styles.textlabel_text172Text}>3.5
			</Text>
			<View
				style={styles.group_job5Group}>
				<Text
					style={styles.textlabel_text171Text}>3.5
				</Text>
				<View
					style={styles.group_job5Group2}>
					<View
						style={styles.group_group142Group}>
						<View
							style={styles.view_shape377}>
						</View>
						<View
							style={styles.view_shape376}>
						</View>
					</View>
					<View
						style={styles.view_shape375}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job36Group}>
				<Text
					style={styles.textlabel_text170Text}>3.5
				</Text>
				<View
					style={styles.group_job4Group}>
					<View
						style={styles.group_group141Group}>
						<View
							style={styles.view_shape374}>
						</View>
						<View
							style={styles.view_shape373}>
						</View>
					</View>
					<View
						style={styles.view_shape372}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job35Group}>
				<Text
					style={styles.textlabel_text169Text}>3.5
				</Text>
				<View
					style={styles.group_job3Group}>
					<View
						style={styles.group_group140Group}>
						<View
							style={styles.view_shape371}>
						</View>
						<View
							style={styles.view_shape370}>
						</View>
					</View>
					<View
						style={styles.view_shape369}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job34Group}>
				<Text
					style={styles.textlabel_text168Text}>3.5
				</Text>
				<View
					style={styles.group_job2Group}>
					<View
						style={styles.group_group139Group}>
						<View
							style={styles.view_shape368}>
						</View>
						<View
							style={styles.view_shape367}>
						</View>
					</View>
					<View
						style={styles.view_shape366}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job1Group}>
				<Text
					style={styles.textlabel_text167Text}>3.5
				</Text>
				<View
					style={styles.group_job1Group2}>
					<View
						style={styles.group_group138Group}>
						<View
							style={styles.view_shape365}>
						</View>
						<View
							style={styles.view_shape364}>
						</View>
					</View>
					<View
						style={styles.view_shape363}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job2Group2}>
				<View
					style={styles.group_group137Group}>
					<View
						style={styles.view_shape362}>
					</View>
					<View
						style={styles.view_shape361}>
					</View>
				</View>
				<View
					style={styles.view_shape360}>
				</View>
			</View>
			<View
				style={styles.group_job1Group3}>
				<View
					style={styles.group_group136Group}>
					<View
						style={styles.view_shape359}>
					</View>
					<View
						style={styles.view_shape358}>
					</View>
				</View>
				<View
					style={styles.view_shape357}>
				</View>
			</View>
			<View
				style={styles.view_shape356}>
			</View>
			<View
				style={styles.view_shape355}>
			</View>
			<View
				style={styles.view_shape354}>
			</View>
			<View
				style={styles.view_shape353}>
			</View>
			<View
				style={styles.view_shape352}>
			</View>
			<View
				style={styles.view_shape351}>
			</View>
			<View
				style={styles.view_shape350}>
			</View>
			<View
				style={styles.group_group135Group}>
				<View
					style={styles.view_shape349}>
				</View>
				<View
					style={styles.view_shape348}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text166Text}>Manufacturing
			</Text>
			<View
				style={styles.view_shape347}>
			</View>
			<View
				style={styles.view_shape346}>
			</View>
			<View
				style={styles.view_shape345}>
			</View>
			<View
				style={styles.group_group134Group}>
				<View
					style={styles.view_shape344}>
				</View>
				<View
					style={styles.view_shape343}>
				</View>
			</View>
			<View
				style={styles.group_group133Group}>
				<View
					style={styles.view_shape342}>
				</View>
				<View
					style={styles.view_shape341}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text165Text}>Search company or boolean
			</Text>
			<Text
				style={styles.textlabel_text164Text}>Search company or boolean
			</Text>
			<Text
				style={styles.textlabel_text163Text}>Destinations
			</Text>
			<Text
				style={styles.textlabel_text162Text}>Industries
			</Text>
			<Text
				style={styles.textlabel_text161Text}>Clear
			</Text>
			<Text
				style={styles.textlabel_text160Text}>Clear
			</Text>
			<View
				style={styles.group_group132Group}>
				<View
					style={styles.view_shape340}>
				</View>
				<View
					style={styles.view_shape339}>
				</View>
			</View>
			<View
				style={styles.group_group131Group}>
				<View
					style={styles.view_shape338}>
				</View>
				<View
					style={styles.view_shape337}>
				</View>
			</View>
			<View
				style={styles.group_group130Group}>
				<Text
					style={styles.textlabel_text159Text}>Prospect
				</Text>
				<Text
					style={styles.textlabel_text158Text}>1,24,512
				</Text>
			</View>
			<View
				style={styles.group_group129Group}>
				<Text
					style={styles.textlabel_text157Text}>All Candidates
				</Text>
				<Text
					style={styles.textlabel_text156Text}>60,24,512
				</Text>
			</View>
			<View
				style={styles.group_group128Group}>
				<Text
					style={styles.textlabel_text155Text}>Hired
				</Text>
				<Text
					style={styles.textlabel_text154Text}>9,524
				</Text>
			</View>
			<View
				style={styles.group_group127Group}>
				<Text
					style={styles.textlabel_text153Text}>Rejected
				</Text>
				<Text
					style={styles.textlabel_text152Text}>320
				</Text>
			</View>
			<Text
				style={styles.textlabel_text151Text}>Requirement : UI Designer
			</Text>
			<View
				style={styles.group_group126Group}>
				<View
					style={styles.view_shape336}>
				</View>
				<View
					style={styles.view_shape335}>
				</View>
			</View>
			<View
				style={styles.view_shape334}>
			</View>
			<View
				style={styles.group_group125Group}>
				<View
					style={styles.view_shape333}>
				</View>
				<View
					style={styles.view_shape332}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text150Text}>Search within Results
			</Text>
			<Text
				style={styles.textlabel_text149Text}>Expand
			</Text>
			<View
				style={styles.view_shape331}>
			</View>
			<View
				style={styles.view_shape330}>
			</View>
			<View
				style={styles.group_group124Group}>
				<View
					style={styles.view_shape329}>
				</View>
				<View
					style={styles.view_shape328}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text148Text}>Overview
			</Text>
			<Text
				style={styles.textlabel_text147Text}>Email
			</Text>
			<View
				style={styles.group_group123Group}>
				<View
					style={styles.view_shape327}>
				</View>
				<View
					style={styles.view_shape326}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text146Text}>Forward
			</Text>
			<View
				style={styles.group_group122Group}>
				<View
					style={styles.view_shape325}>
				</View>
				<View
					style={styles.view_shape324}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text145Text}>Download
			</Text>
			<View
				style={styles.group_group121Group}>
				<View
					style={styles.view_shape323}>
				</View>
				<View
					style={styles.view_shape322}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text144Text}>Change Status
			</Text>
			<View
				style={styles.group_group120Group}>
				<View
					style={styles.view_shape321}>
				</View>
				<View
					style={styles.view_shape320}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text143Text}>Add to Requirements
			</Text>
			<View
				style={styles.group_group119Group}>
				<View
					style={styles.view_shape319}>
				</View>
				<View
					style={styles.view_shape318}>
				</View>
			</View>
			<View
				style={styles.group_group118Group}>
				<View
					style={styles.view_shape317}>
				</View>
				<View
					style={styles.view_shape316}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text142Text}>Delete
			</Text>
			<View
				style={styles.group_group117Group}>
				<View
					style={styles.view_shape315}>
				</View>
				<View
					style={styles.view_shape314}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text141Text}>Search in RMS
			</Text>
			<View
				style={styles.view_shape313}>
			</View>
			<Text
				style={styles.textlabel_text140Text}>More
			</Text>
			<View
				style={styles.group_group116Group}>
				<View
					style={styles.view_shape312}>
				</View>
				<View
					style={styles.view_shape311}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text139Text}>Soc-001
			</Text>
			<View
				style={styles.view_shape310}>
			</View>
			<View
				style={styles.view_shape309}>
			</View>
			<View
				style={styles.view_shape308}>
			</View>
			<View
				style={styles.view_shape307}>
			</View>
			<View
				style={styles.view_shape306}>
			</View>
			<View
				style={styles.view_shape305}>
			</View>
			<View
				style={styles.view_shape304}>
			</View>
			<View
				style={styles.group_group115Group}>
				<View
					style={styles.view_shape303}>
				</View>
				<View
					style={styles.view_shape302}>
				</View>
			</View>
			<View
				style={styles.group_group114Group}>
				<View
					style={styles.view_shape301}>
				</View>
				<View
					style={styles.view_shape300}>
				</View>
			</View>
			<View
				style={styles.group_group113Group}>
				<View
					style={styles.view_shape299}>
				</View>
				<View
					style={styles.view_shape298}>
				</View>
			</View>
			<View
				style={styles.group_group112Group}>
				<View
					style={styles.view_shape297}>
				</View>
				<View
					style={styles.view_shape296}>
				</View>
			</View>
			<View
				style={styles.group_group111Group}>
				<View
					style={styles.view_shape295}>
				</View>
				<View
					style={styles.view_shape294}>
				</View>
			</View>
			<View
				style={styles.group_group110Group}>
				<View
					style={styles.view_shape293}>
				</View>
				<View
					style={styles.view_shape292}>
				</View>
			</View>
			<View
				style={styles.view_shape291}>
			</View>
			<View
				style={styles.view_shape290}>
			</View>
			<View style={styles.textinput_textboxsfTxtInput}>

				<TextInput
					defaultValue={'Type Here'}
					style={styles.SUBTITLE1COLORSTYLEText}
				/>

			</View>
			<Text
				style={styles.textlabel_text138Text}>Use @ to notify a team member
			</Text>
			<TouchableOpacity
				onPress={() => Alert.alert('I am Custom Button.')}
				style={styles.button_button1Button}>
				<Text
					style={styles.TEXTCOLORSTYLEText}>Add Comment
				</Text>
			</TouchableOpacity>
			<Text
				style={styles.textlabel_text137Text}>Activities
			</Text>
			<Text
				style={styles.attrbuted_textlabel_text136Text}>Comment Added to profile of
				<Text
					style={styles.attrbuted_textlabel_text136Text2}>Arvind Pahwa
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text136Text3}> by Upasona@qwazent.com
				</Text>

			</Text>
			<Text
				style={styles.textlabel_text135Text}>52min ago
			</Text>
			<Text
				style={styles.attrbuted_textlabel_text134Text}>Arvind Pahwa
				<Text
					style={styles.attrbuted_textlabel_text134Text2}> has been
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text134Text3}>Rejected
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text134Text4}> at
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text134Text5}>Prospect
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text134Text6}> by upasona@qwazent.com
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text133Text}>Arvind Pahwa
				<Text
					style={styles.attrbuted_textlabel_text133Text2}> has been
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text133Text3}>Rejected
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text133Text4}> at
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text133Text5}>Prospect
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text133Text6}> by upasona@qwazent.com
				</Text>

			</Text>
			<Text
				style={styles.textlabel_text132Text}>52min ago
			</Text>
			<Text
				style={styles.textlabel_text131Text}>52min ago
			</Text>
			<View
				style={styles.view_shape289}>
			</View>
			<View
				style={styles.view_shape288}>
			</View>
			<View
				style={styles.group_group109Group}>
				<View
					style={styles.view_shape287}>
				</View>
				<Text
					style={styles.textlabel_text130Text}>Prospect
				</Text>
				<View
					style={styles.view_shape286}>
				</View>
			</View>
			<View
				style={styles.group_group108Group}>
				<View
					style={styles.view_shape285}>
				</View>
				<Text
					style={styles.textlabel_text129Text}>Prospect
				</Text>
				<View
					style={styles.view_shape284}>
				</View>
			</View>
			<View
				style={styles.view_shape283}>
			</View>
			<View
				style={styles.view_shape282}>
			</View>
			<Text
				style={styles.textlabel_text128Text}>Rejected
			</Text>
			<Text
				style={styles.textlabel_text127Text}>Rejected
			</Text>
			<View
				style={styles.view_shape281}>
			</View>
			<View
				style={styles.view_shape280}>
			</View>
			<Text
				style={styles.textlabel_text126Text}>Showing 1 to 32 of 400
			</Text>
			<Text
				style={styles.textlabel_text125Text}>Show
			</Text>
			<Text
				style={styles.textlabel_text124Text}>40
			</Text>
			<View
				style={styles.group_group107Group}>
				<View
					style={styles.view_shape279}>
				</View>
				<View
					style={styles.view_shape278}>
				</View>
			</View>
			<View
				style={styles.group_group106Group}>
				<View
					style={styles.view_shape277}>
				</View>
				<View
					style={styles.view_shape276}>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text123Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text123Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text123Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text123Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text123Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text122Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text122Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text122Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text122Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text122Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text121Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text121Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text121Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text121Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text121Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text120Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text120Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text120Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text120Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text120Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text119Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text119Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text119Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text119Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text119Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text118Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text118Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text118Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text117Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text117Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text117Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text116Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text116Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text116Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text115Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text115Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text115Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text114Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text114Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text114Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<View
				style={styles.view_shape275}>
			</View>
			<View
				style={styles.view_shape274}>
			</View>
			<View
				style={styles.group_group105Group}>
				<View
					style={styles.view_shape273}>
				</View>
				<View
					style={styles.view_shape272}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text113Text}>Applied - Rejected
			</Text>
			<Text
				style={styles.textlabel_text112Text}>Rejected
			</Text>
			<View
				style={styles.group_job32Group}>
				<View
					style={styles.group_group104Group}>
					<View
						style={styles.view_shape271}>
					</View>
					<View
						style={styles.view_shape270}>
					</View>
					<View
						style={styles.view_shape269}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text111Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job31Group}>
				<Text
					style={styles.textlabel_text110Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group103Group}>
					<View
						style={styles.view_shape268}>
					</View>
					<View
						style={styles.view_shape267}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job30Group}>
				<Text
					style={styles.attrbuted_textlabel_text109Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text109Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group102Group}>
					<View
						style={styles.view_shape266}>
					</View>
					<View
						style={styles.view_shape265}>
					</View>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text108Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text108Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text108Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text108Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text107Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text107Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text107Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text107Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text106Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text106Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text106Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text106Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text105Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text105Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text105Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text105Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text104Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text104Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text104Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text104Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<View
				style={styles.group_group101Group}>
				<View
					style={styles.view_shape264}>
				</View>
				<View
					style={styles.view_shape263}>
				</View>
			</View>
			<View
				style={styles.group_group100Group}>
				<View
					style={styles.view_shape262}>
				</View>
				<View
					style={styles.view_shape261}>
				</View>
			</View>
			<View
				style={styles.group_group99Group}>
				<View
					style={styles.view_shape260}>
				</View>
				<View
					style={styles.view_shape259}>
				</View>
			</View>
			<View
				style={styles.group_group98Group}>
				<View
					style={styles.view_shape258}>
				</View>
				<View
					style={styles.view_shape257}>
				</View>
			</View>
			<View
				style={styles.group_group97Group}>
				<View
					style={styles.view_shape256}>
				</View>
				<View
					style={styles.view_shape255}>
				</View>
			</View>
			<View
				style={styles.group_group96Group}>
				<View
					style={styles.view_shape254}>
				</View>
				<View
					style={styles.view_shape253}>
				</View>
			</View>
			<View
				style={styles.group_group95Group}>
				<View
					style={styles.view_shape252}>
				</View>
				<View
					style={styles.view_shape251}>
				</View>
			</View>
			<View
				style={styles.group_group94Group}>
				<View
					style={styles.view_shape250}>
				</View>
				<View
					style={styles.view_shape249}>
				</View>
			</View>
			<View
				style={styles.group_group93Group}>
				<View
					style={styles.view_shape248}>
				</View>
				<View
					style={styles.view_shape247}>
				</View>
			</View>
			<View
				style={styles.group_group92Group}>
				<View
					style={styles.view_shape246}>
				</View>
				<View
					style={styles.view_shape245}>
				</View>
			</View>
			<View
				style={styles.view_shape244}>
			</View>
			<View
				style={styles.view_shape243}>
			</View>
			<View
				style={styles.view_shape242}>
			</View>
			<View
				style={styles.view_shape241}>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text103Text}>Last Modified
				<Text
					style={styles.attrbuted_textlabel_text103Text2}>: 22 Mar 21
				</Text>

			</Text>
			<View
				style={styles.group_group91Group}>
				<View
					style={styles.view_shape240}>
				</View>
				<View
					style={styles.view_shape239}>
				</View>
			</View>
			<View
				style={styles.group_job29Group}>
				<Text
					style={styles.attrbuted_textlabel_text102Text}>Last Modified
					<Text
						style={styles.attrbuted_textlabel_text102Text2}>: 22 Mar 21
					</Text>

				</Text>
				<View
					style={styles.group_group90Group}>
					<View
						style={styles.view_shape238}>
					</View>
					<View
						style={styles.view_shape237}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job28Group}>
				<Text
					style={styles.attrbuted_textlabel_text101Text}>Last Modified
					<Text
						style={styles.attrbuted_textlabel_text101Text2}>: 22 Mar 21
					</Text>

				</Text>
				<View
					style={styles.group_group89Group}>
					<View
						style={styles.view_shape236}>
					</View>
					<View
						style={styles.view_shape235}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job27Group}>
				<Text
					style={styles.attrbuted_textlabel_text100Text}>Last Modified
					<Text
						style={styles.attrbuted_textlabel_text100Text2}>: 22 Mar 21
					</Text>

				</Text>
				<View
					style={styles.group_group88Group}>
					<View
						style={styles.view_shape234}>
					</View>
					<View
						style={styles.view_shape233}>
					</View>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text99Text}>Last Modified
				<Text
					style={styles.attrbuted_textlabel_text99Text2}>: 22 Mar 21
				</Text>

			</Text>
			<View
				style={styles.group_group87Group}>
				<View
					style={styles.view_shape232}>
				</View>
				<View
					style={styles.view_shape231}>
				</View>
			</View>
			<View
				style={styles.group_job5Group3}>
				<View
					style={styles.view_shape230}>
				</View>
				<View
					style={styles.group_group86Group}>
					<View
						style={styles.view_shape229}>
					</View>
					<View
						style={styles.view_shape228}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text98Text}>Activity log
			</Text>
			<View
				style={styles.group_group85Group}>
				<View
					style={styles.view_shape227}>
				</View>
				<View
					style={styles.view_shape226}>
				</View>
			</View>
			<View
				style={styles.group_group84Group}>
				<View
					style={styles.view_shape225}>
				</View>
				<View
					style={styles.view_shape224}>
				</View>
			</View>
			<View
				style={styles.group_group83Group}>
				<View
					style={styles.view_shape223}>
				</View>
				<View
					style={styles.view_shape222}>
				</View>
			</View>
			<View
				style={styles.group_group82Group}>
				<View
					style={styles.view_shape221}>
				</View>
				<View
					style={styles.view_shape220}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text97Text}>20
			</Text>
			<Text
				style={styles.textlabel_text96Text}>Dennis Drake
			</Text>
			<Text
				style={styles.textlabel_text95Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<View
				style={styles.view_shape219}>
			</View>
			<View
				style={styles.group_group81Group}>
				<View
					style={styles.view_shape218}>
				</View>
				<View
					style={styles.view_shape217}>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text94Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text94Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text94Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text94Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text94Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text93Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text93Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text93Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<View
				style={styles.group_group80Group}>
				<View
					style={styles.view_shape216}>
				</View>
				<View
					style={styles.view_shape215}>
				</View>
			</View>
			<View
				style={styles.group_group79Group}>
				<View
					style={styles.view_shape214}>
				</View>
				<View
					style={styles.view_shape213}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text92Text}>20
			</Text>
			<View
				style={styles.group_job26Group}>
				<View
					style={styles.view_shape212}>
				</View>
				<View
					style={styles.view_shape211}>
				</View>
				<View
					style={styles.group_group78Group}>
					<View
						style={styles.view_shape210}>
					</View>
					<View
						style={styles.view_shape209}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text91Text}>Applied - Matching
				</Text>
				<Text
					style={styles.textlabel_text90Text}>Reject
				</Text>
			</View>
			<View
				style={styles.group_job25Group}>
				<View
					style={styles.group_group77Group}>
					<View
						style={styles.view_shape208}>
					</View>
					<View
						style={styles.view_shape207}>
					</View>
					<View
						style={styles.view_shape206}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text89Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job24Group}>
				<Text
					style={styles.textlabel_text88Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group76Group}>
					<View
						style={styles.view_shape205}>
					</View>
					<View
						style={styles.view_shape204}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job23Group}>
				<Text
					style={styles.attrbuted_textlabel_text87Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text87Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group75Group}>
					<View
						style={styles.view_shape203}>
					</View>
					<View
						style={styles.view_shape202}>
					</View>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text86Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text86Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text86Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text86Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<View
				style={styles.group_group74Group}>
				<View
					style={styles.view_shape201}>
				</View>
				<View
					style={styles.view_shape200}>
				</View>
			</View>
			<View
				style={styles.group_group73Group}>
				<View
					style={styles.view_shape199}>
				</View>
				<View
					style={styles.view_shape198}>
				</View>
			</View>
			<View
				style={styles.view_shape197}>
			</View>
			<View
				style={styles.group_job22Group}>
				<Text
					style={styles.attrbuted_textlabel_text85Text}>Last Modified
					<Text
						style={styles.attrbuted_textlabel_text85Text2}>: 22 Mar 21
					</Text>

				</Text>
				<View
					style={styles.group_group72Group}>
					<View
						style={styles.view_shape196}>
					</View>
					<View
						style={styles.view_shape195}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text84Text}>Celia Floyd
			</Text>
			<Text
				style={styles.textlabel_text83Text}>I am experienced, detailed-oriented, passionate front-end web designer & developer With 4 years of hands-on experience efficiently coding websites and applications
			</Text>
			<View
				style={styles.view_shape194}>
			</View>
			<View
				style={styles.group_group71Group}>
				<View
					style={styles.view_shape193}>
				</View>
				<View
					style={styles.view_shape192}>
				</View>
			</View>
			<Text
				style={styles.attrbuted_textlabel_text82Text}>Exp:
				<Text
					style={styles.attrbuted_textlabel_text82Text2}>
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text82Text3}>4 Year(s) 8 Month(s) |
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text82Text4}>CTC:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text82Text5}>Rs 4.8 Lakhs
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text81Text}>Current Designation:
				<Text
					style={styles.attrbuted_textlabel_text81Text2}>Senior Web Designer{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text3}>Current Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text4}>Webcontxt Pvt. LTD.{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text5}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text6}>Previous Designation:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text7}>Web Designer{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text8}>{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text9}>Previous Company:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text10}>Apps pool india{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text11}>Functional Area:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text12}>Web / Graphic Design / Visualiser{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text13}>Industry:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text81Text14}> IT-Software/Software Services
				</Text>

			</Text>
			<Text
				style={styles.attrbuted_textlabel_text80Text}>Current Location:
				<Text
					style={styles.attrbuted_textlabel_text80Text2}>Jaipur{'\n'}{'\n'}
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text80Text3}>Pref Location:
				</Text>

				<Text
					style={styles.attrbuted_textlabel_text80Text4}>New Delhi, Bangalore/Bengaluru{'\n'}{'\n'}      917737369948{'\n'}{'\n'}      hardenrahul@gmail.com
				</Text>

			</Text>
			<View
				style={styles.view_shape191}>
			</View>
			<View
				style={styles.group_job21Group}>
				<View
					style={styles.group_group70Group}>
					<View
						style={styles.view_shape190}>
					</View>
					<View
						style={styles.view_shape189}>
					</View>
					<View
						style={styles.view_shape188}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text79Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job20Group}>
				<Text
					style={styles.textlabel_text78Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group69Group}>
					<View
						style={styles.view_shape187}>
					</View>
					<View
						style={styles.view_shape186}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job19Group}>
				<Text
					style={styles.textlabel_text77Text}>In Progress
				</Text>
				<View
					style={styles.group_group68Group}>
					<View
						style={styles.view_shape185}>
					</View>
					<View
						style={styles.view_shape184}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job18Group}>
				<Text
					style={styles.attrbuted_textlabel_text76Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text76Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group67Group}>
					<View
						style={styles.view_shape183}>
					</View>
					<View
						style={styles.view_shape182}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job17Group}>
				<Text
					style={styles.attrbuted_textlabel_text75Text}>Last Modified
					<Text
						style={styles.attrbuted_textlabel_text75Text2}>: 22 Mar 21
					</Text>

				</Text>
				<View
					style={styles.group_group66Group}>
					<View
						style={styles.view_shape181}>
					</View>
					<View
						style={styles.view_shape180}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job4Group2}>
				<View
					style={styles.view_shape179}>
				</View>
				<View
					style={styles.group_group65Group}>
					<View
						style={styles.view_shape178}>
					</View>
					<View
						style={styles.view_shape177}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text74Text}>Activity log
			</Text>
			<View
				style={styles.group_group64Group}>
				<View
					style={styles.view_shape176}>
				</View>
				<View
					style={styles.view_shape175}>
				</View>
			</View>
			<View
				style={styles.group_group63Group}>
				<View
					style={styles.view_shape174}>
				</View>
				<View
					style={styles.view_shape173}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text73Text}>20
			</Text>
			<View
				style={styles.group_group1Group}>
				<View
					style={styles.view_shape172}>
				</View>
				<View
					style={styles.group_group62Group}>
					<View
						style={styles.view_shape171}>
					</View>
					<View
						style={styles.view_shape170}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text72Text}>Activity log
			</Text>
			<View
				style={styles.group_group61Group}>
				<View
					style={styles.view_shape169}>
				</View>
				<View
					style={styles.view_shape168}>
				</View>
			</View>
			<View
				style={styles.group_job33Group}>
				<View
					style={styles.view_shape167}>
				</View>
				<View
					style={styles.group_group60Group}>
					<View
						style={styles.view_shape166}>
					</View>
					<View
						style={styles.view_shape165}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text71Text}>Activity log
			</Text>
			<View
				style={styles.group_group59Group}>
				<View
					style={styles.view_shape164}>
				</View>
				<View
					style={styles.view_shape163}>
				</View>
			</View>
			<View
				style={styles.view_shape162}>
			</View>
			<View
				style={styles.view_shape161}>
			</View>
			<View
				style={styles.view_shape160}>
			</View>
			<View
				style={styles.view_shape159}>
			</View>
			<Text
				style={styles.textlabel_text70Text}>Rahul Kumar Sahu
			</Text>
			<Text
				style={styles.textlabel_text69Text}>Interview Round 3
			</Text>
			<Text
				style={styles.textlabel_text68Text}>Rohit Sharma
			</Text>
			<Text
				style={styles.textlabel_text67Text}>Interview Round 1
			</Text>
			<Text
				style={styles.textlabel_text66Text}>04:30PM
			</Text>
			<Text
				style={styles.textlabel_text65Text}>Latest Activities
			</Text>
			<Text
				style={styles.textlabel_text64Text}>Mar 23, 2021 | 04:30PM
			</Text>
			<Text
				style={styles.textlabel_text63Text}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
			</Text>
			<Text
				style={styles.textlabel_text62Text}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
			</Text>
			<Text
				style={styles.textlabel_text61Text}>Export
			</Text>
			<View
				style={styles.group_job16Group}>
				<View
					style={styles.view_shape158}>
				</View>
				<View
					style={styles.view_shape157}>
				</View>
				<View
					style={styles.group_group58Group}>
					<View
						style={styles.view_shape156}>
					</View>
					<View
						style={styles.view_shape155}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text60Text}>Applied - Matching
				</Text>
				<Text
					style={styles.textlabel_text59Text}>Reject
				</Text>
			</View>
			<View
				style={styles.group_job15Group}>
				<View
					style={styles.group_group57Group}>
					<View
						style={styles.view_shape154}>
					</View>
					<View
						style={styles.view_shape153}>
					</View>
					<View
						style={styles.view_shape152}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text58Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job14Group}>
				<Text
					style={styles.textlabel_text57Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group56Group}>
					<View
						style={styles.view_shape151}>
					</View>
					<View
						style={styles.view_shape150}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job13Group}>
				<Text
					style={styles.attrbuted_textlabel_text56Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text56Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group55Group}>
					<View
						style={styles.view_shape149}>
					</View>
					<View
						style={styles.view_shape148}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_group54Group}>
				<View
					style={styles.view_shape147}>
				</View>
				<View
					style={styles.view_shape146}>
				</View>
			</View>
			<View
				style={styles.group_group53Group}>
				<View
					style={styles.view_shape145}>
				</View>
				<View
					style={styles.view_shape144}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text55Text}>20
			</Text>
			<View
				style={styles.group_job12Group}>
				<View
					style={styles.view_shape143}>
				</View>
				<View
					style={styles.view_shape142}>
				</View>
				<View
					style={styles.group_group52Group}>
					<View
						style={styles.view_shape141}>
					</View>
					<View
						style={styles.view_shape140}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text54Text}>Applied - Matching
				</Text>
				<Text
					style={styles.textlabel_text53Text}>Reject
				</Text>
			</View>
			<View
				style={styles.group_job11Group}>
				<View
					style={styles.group_group51Group}>
					<View
						style={styles.view_shape139}>
					</View>
					<View
						style={styles.view_shape138}>
					</View>
					<View
						style={styles.view_shape137}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text52Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job10Group}>
				<Text
					style={styles.textlabel_text51Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group50Group}>
					<View
						style={styles.view_shape136}>
					</View>
					<View
						style={styles.view_shape135}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job9Group}>
				<Text
					style={styles.attrbuted_textlabel_text50Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text50Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group49Group}>
					<View
						style={styles.view_shape134}>
					</View>
					<View
						style={styles.view_shape133}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job3Group2}>
				<View
					style={styles.view_shape132}>
				</View>
				<View
					style={styles.group_group48Group}>
					<View
						style={styles.view_shape131}>
					</View>
					<View
						style={styles.view_shape130}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text49Text}>Activity log
			</Text>
			<View
				style={styles.group_group47Group}>
				<View
					style={styles.view_shape129}>
				</View>
				<View
					style={styles.view_shape128}>
				</View>
			</View>
			<View
				style={styles.group_group46Group}>
				<View
					style={styles.view_shape127}>
				</View>
				<View
					style={styles.view_shape126}>
				</View>
			</View>
			<View
				style={styles.group_group45Group}>
				<View
					style={styles.view_shape125}>
				</View>
				<View
					style={styles.view_shape124}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text48Text}>2
			</Text>
			<View
				style={styles.group_group44Group}>
				<View
					style={styles.view_shape123}>
				</View>
				<View
					style={styles.view_shape122}>
				</View>
			</View>
			<View
				style={styles.group_group43Group}>
				<View
					style={styles.view_shape121}>
				</View>
				<View
					style={styles.view_shape120}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text47Text}>20
			</Text>
			<View
				style={styles.group_job8Group}>
				<View
					style={styles.view_shape119}>
				</View>
				<View
					style={styles.view_shape118}>
				</View>
				<View
					style={styles.group_group42Group}>
					<View
						style={styles.view_shape117}>
					</View>
					<View
						style={styles.view_shape116}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text46Text}>Applied - Matching
				</Text>
				<Text
					style={styles.textlabel_text45Text}>Reject
				</Text>
			</View>
			<View
				style={styles.group_job7Group}>
				<View
					style={styles.group_group41Group}>
					<View
						style={styles.view_shape115}>
					</View>
					<View
						style={styles.view_shape114}>
					</View>
					<View
						style={styles.view_shape113}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text44Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job6Group}>
				<Text
					style={styles.textlabel_text43Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group40Group}>
					<View
						style={styles.view_shape112}>
					</View>
					<View
						style={styles.view_shape111}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job5Group4}>
				<Text
					style={styles.attrbuted_textlabel_text42Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text42Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group39Group}>
					<View
						style={styles.view_shape110}>
					</View>
					<View
						style={styles.view_shape109}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_group38Group}>
				<View
					style={styles.view_shape108}>
				</View>
				<View
					style={styles.view_shape107}>
				</View>
			</View>
			<View
				style={styles.group_group37Group}>
				<View
					style={styles.view_shape106}>
				</View>
				<View
					style={styles.view_shape105}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text41Text}>20
			</Text>
			<View
				style={styles.group_job4Group3}>
				<View
					style={styles.view_shape104}>
				</View>
				<View
					style={styles.view_shape103}>
				</View>
				<View
					style={styles.group_group36Group}>
					<View
						style={styles.view_shape102}>
					</View>
					<View
						style={styles.view_shape101}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text40Text}>Applied - Matching
				</Text>
				<Text
					style={styles.textlabel_text39Text}>Reject
				</Text>
			</View>
			<View
				style={styles.group_job3Group3}>
				<View
					style={styles.group_group35Group}>
					<View
						style={styles.view_shape100}>
					</View>
					<View
						style={styles.view_shape99}>
					</View>
					<View
						style={styles.view_shape98}>
					</View>
				</View>
				<Text
					style={styles.textlabel_text38Text}>Ref Check Form
				</Text>
			</View>
			<View
				style={styles.group_job2Group3}>
				<Text
					style={styles.textlabel_text37Text}>Part of 10 no. of request
				</Text>
				<View
					style={styles.group_group34Group}>
					<View
						style={styles.view_shape97}>
					</View>
					<View
						style={styles.view_shape96}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job1Group4}>
				<Text
					style={styles.attrbuted_textlabel_text36Text}>Call Status:
					<Text
						style={styles.attrbuted_textlabel_text36Text2}>Missed Called
					</Text>

				</Text>
				<View
					style={styles.group_group33Group}>
					<View
						style={styles.view_shape95}>
					</View>
					<View
						style={styles.view_shape94}>
					</View>
				</View>
			</View>
			<View
				style={styles.group_job2Group4}>
				<View
					style={styles.view_shape93}>
				</View>
				<View
					style={styles.group_group32Group}>
					<View
						style={styles.view_shape92}>
					</View>
					<View
						style={styles.view_shape91}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text35Text}>Activity log
			</Text>
			<View
				style={styles.group_group31Group}>
				<View
					style={styles.view_shape90}>
				</View>
				<View
					style={styles.view_shape89}>
				</View>
			</View>
			<View
				style={styles.group_job1Group5}>
				<View
					style={styles.view_shape88}>
				</View>
				<View
					style={styles.group_group30Group}>
					<View
						style={styles.view_shape87}>
					</View>
					<View
						style={styles.view_shape86}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text34Text}>Activity log
			</Text>
			<View
				style={styles.group_group29Group}>
				<View
					style={styles.view_shape85}>
				</View>
				<View
					style={styles.view_shape84}>
				</View>
			</View>
			<View
				style={styles.group_job1Group6}>
				<View
					style={styles.view_shape83}>
				</View>
				<View
					style={styles.group_group28Group}>
					<View
						style={styles.view_shape82}>
					</View>
					<View
						style={styles.view_shape81}>
					</View>
				</View>
			</View>
			<Text
				style={styles.textlabel_text33Text}>Activity log
			</Text>
			<View
				style={styles.group_group27Group}>
				<View
					style={styles.view_shape80}>
				</View>
				<View
					style={styles.view_shape79}>
				</View>
			</View>
			<View
				style={styles.group_group26Group}>
				<View
					style={styles.view_shape78}>
				</View>
				<View
					style={styles.view_shape77}>
				</View>
			</View>
			<View
				style={styles.group_group25Group}>
				<View
					style={styles.view_shape76}>
				</View>
				<View
					style={styles.view_shape75}>
				</View>
			</View>
			<View
				style={styles.view_shape1}>
			</View>
			<Text
				style={styles.textlabel_text32Text}>Download Log
			</Text>
			<Image
				style={styles.image_dashboardImage}
				source={require('../assets/dumdum.png')}
			/>
			<View
				style={styles.view_shape74}>
			</View>
			<View
				style={styles.view_shape73}>
			</View>
			<View
				style={styles.view_shape72}>
			</View>
			<View
				style={styles.group_group24Group}>
				<View
					style={styles.view_shape71}>
				</View>
				<View
					style={styles.view_shape70}>
				</View>
			</View>
			<View
				style={styles.view_shape69}>
			</View>
			<View
				style={styles.view_shape68}>
			</View>
			<View
				style={styles.group_group23Group}>
				<View
					style={styles.view_shape67}>
				</View>
				<View
					style={styles.view_shape66}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text31Text}>Help
			</Text>
			<Text
				style={styles.textlabel_text30Text}>Advanced Search
			</Text>
			<Text
				style={styles.textlabel_text29Text}>Search for profiles
			</Text>
			<View
				style={styles.group_group22Group}>
				<View
					style={styles.view_shape65}>
				</View>
				<View
					style={styles.view_shape64}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text28Text}>Requirements
			</Text>
			<Text
				style={styles.textlabel_text27Text}>Dashboard
			</Text>
			<View
				style={styles.view_shape63}>
			</View>
			<Text
				style={styles.textlabel_text26Text}>Profiles
			</Text>
			<Text
				style={styles.textlabel_text25Text}>Reports
			</Text>
			<View
				style={styles.view_shape62}>
			</View>
			<View
				style={styles.view_shape61}>
			</View>
			<View
				style={styles.view_shape60}>
			</View>
			<View
				style={styles.view_shape59}>
			</View>
			<View
				style={styles.view_shape58}>
			</View>
			<View
				style={styles.group_group21Group}>
				<View
					style={styles.view_shape57}>
				</View>
				<View
					style={styles.view_shape56}>
				</View>
			</View>
			<View
				style={styles.group_group20Group}>
				<View
					style={styles.view_shape55}>
				</View>
				<View
					style={styles.view_shape54}>
				</View>
			</View>
			<View
				style={styles.group_group19Group}>
				<View
					style={styles.view_shape53}>
				</View>
				<View
					style={styles.view_shape52}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text24Text}>Search Job Title
			</Text>
			<Text
				style={styles.textlabel_text23Text}>Search Locations
			</Text>
			<Text
				style={styles.textlabel_text22Text}>Skill and expertise or boolean
			</Text>
			<Text
				style={styles.textlabel_text21Text}>Job Titles
			</Text>
			<Text
				style={styles.textlabel_text20Text}>Locations
			</Text>
			<Text
				style={styles.textlabel_text19Text}>Skills
			</Text>
			<Text
				style={styles.textlabel_text18Text}>Clear
			</Text>
			<Text
				style={styles.textlabel_text17Text}>Clear
			</Text>
			<Text
				style={styles.textlabel_text16Text}>Clear
			</Text>
			<View
				style={styles.group_group18Group}>
				<View
					style={styles.view_shape51}>
				</View>
				<View
					style={styles.view_shape50}>
				</View>
			</View>
			<View
				style={styles.group_group17Group}>
				<View
					style={styles.view_shape49}>
				</View>
				<View
					style={styles.view_shape48}>
				</View>
			</View>
			<View
				style={styles.group_group16Group}>
				<View
					style={styles.view_shape47}>
				</View>
				<View
					style={styles.view_shape46}>
				</View>
			</View>
			<View
				style={styles.group_group15Group}>
				<View
					style={styles.view_shape45}>
				</View>
				<View
					style={styles.view_shape44}>
				</View>
			</View>
			<View
				style={styles.group_group14Group}>
				<View
					style={styles.view_shape43}>
				</View>
				<View
					style={styles.view_shape42}>
				</View>
			</View>
			<View
				style={styles.group_group13Group}>
				<View
					style={styles.view_shape41}>
				</View>
				<View
					style={styles.view_shape40}>
				</View>
			</View>
			<View
				style={styles.group_group12Group}>
				<View
					style={styles.view_shape39}>
				</View>
				<View
					style={styles.view_shape38}>
				</View>
			</View>
			<View
				style={styles.group_group11Group}>
				<View
					style={styles.view_shape37}>
				</View>
				<View
					style={styles.view_shape36}>
				</View>
			</View>
			<View
				style={styles.group_group10Group}>
				<View
					style={styles.view_shape35}>
				</View>
				<View
					style={styles.view_shape34}>
				</View>
			</View>
			<View
				style={styles.group_group9Group}>
				<View
					style={styles.view_shape33}>
				</View>
				<View
					style={styles.view_shape32}>
				</View>
			</View>
			<View
				style={styles.group_group8Group}>
				<View
					style={styles.view_shape31}>
				</View>
				<View
					style={styles.view_shape30}>
				</View>
			</View>
			<View
				style={styles.group_group7Group}>
				<View
					style={styles.view_shape29}>
				</View>
				<View
					style={styles.view_shape28}>
				</View>
			</View>
			<View
				style={styles.group_group6Group}>
				<View
					style={styles.view_shape27}>
				</View>
				<View
					style={styles.view_shape26}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text15Text}>Accounting
			</Text>
			<Text
				style={styles.textlabel_text14Text}>United States
			</Text>
			<Text
				style={styles.textlabel_text13Text}>Agriculture
			</Text>
			<Text
				style={styles.textlabel_text12Text}>Marketing & Sales
			</Text>
			<Text
				style={styles.textlabel_text11Text}>Jaipur
			</Text>
			<Text
				style={styles.textlabel_text10Text}>Architecture
			</Text>
			<Text
				style={styles.textlabel_text9Text}>Sales
			</Text>
			<Text
				style={styles.textlabel_text8Text}>Rajasthan
			</Text>
			<Text
				style={styles.textlabel_text7Text}>UI Designer
			</Text>
			<Text
				style={styles.textlabel_text6Text}>India
			</Text>
			<View
				style={styles.view_shape25}>
			</View>
			<View
				style={styles.view_shape24}>
			</View>
			<View
				style={styles.view_shape23}>
			</View>
			<View
				style={styles.view_shape22}>
			</View>
			<View
				style={styles.view_shape21}>
			</View>
			<View
				style={styles.view_shape20}>
			</View>
			<View
				style={styles.view_shape19}>
			</View>
			<View
				style={styles.view_shape18}>
			</View>
			<View
				style={styles.view_shape17}>
			</View>
			<View
				style={styles.view_shape16}>
			</View>
			<View
				style={styles.group_group5Group}>
				<View
					style={styles.view_shape15}>
				</View>
				<View
					style={styles.view_shape14}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text5Text}>The Walt Disney Company
			</Text>
			<View
				style={styles.view_shape13}>
			</View>
			<View
				style={styles.group_group4Group}>
				<View
					style={styles.view_shape12}>
				</View>
				<View
					style={styles.view_shape11}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text4Text}>Facebook
			</Text>
			<View
				style={styles.view_shape10}>
			</View>
			<View
				style={styles.group_group3Group}>
				<View
					style={styles.view_shape9}>
				</View>
				<View
					style={styles.view_shape8}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text3Text}>Nintendo
			</Text>
			<View
				style={styles.view_shape7}>
			</View>
			<View
				style={styles.group_group2Group}>
				<View
					style={styles.view_shape6}>
				</View>
				<View
					style={styles.view_shape5}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text2Text}>Marketing
			</Text>
			<View
				style={styles.view_shape4}>
			</View>
			<View
				style={styles.group_group1Group2}>
				<View
					style={styles.view_shape3}>
				</View>
				<View
					style={styles.view_shape2}>
				</View>
			</View>
			<Text
				style={styles.textlabel_text1Text}>Audio & Video
			</Text>
			<View
				style={styles.view_shape1100}>
			</View>
		</View>
	}

}
const styles = StyleSheet.create({
	JobListingView:
	{
		backgroundColor: "rgba(252, 252, 252, 1)",
		flex: 1,
		alignItems: "flex-start"
	},

	view_shape382:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(1086, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(151, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(0, 0, 0, 0.12)',
		borderRadius: deviceBasedDynamicDimension(2, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape381:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(4000, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(151, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(0, 0, 0, 0.12)',
		borderRadius: deviceBasedDynamicDimension(2, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape380:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(52, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(366, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape379:
	{
		width: deviceBasedDynamicDimension(44, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(1180, true, 1),
		marginTop: deviceBasedDynamicDimension(378, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	textlabel_text183Text:
	{
		width: deviceBasedDynamicDimension(78, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(440, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text183Text:
	{
		width: deviceBasedDynamicDimension(78, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(440, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text182Text:
	{
		width: deviceBasedDynamicDimension(94, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2503, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text182Text:
	{
		width: deviceBasedDynamicDimension(94, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2503, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text181Text:
	{
		width: deviceBasedDynamicDimension(121, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2876, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text181Text:
	{
		width: deviceBasedDynamicDimension(121, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2876, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text180Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(813, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text180Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(813, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text179Text:
	{
		width: deviceBasedDynamicDimension(101, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1539, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text179Text:
	{
		width: deviceBasedDynamicDimension(101, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1539, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text178Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(466, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text178Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(466, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text177Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2529, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text177Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2529, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text176Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2902, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text176Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2902, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text175Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(839, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text175Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(839, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text174Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1565, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text174Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1565, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape378:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(442, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text173Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(406, true, 1),
		marginTop: deviceBasedDynamicDimension(444, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text173Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(406, true, 1),
		marginTop: deviceBasedDynamicDimension(444, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text172Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(399, true, 1),
		marginTop: deviceBasedDynamicDimension(817, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text172Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(399, true, 1),
		marginTop: deviceBasedDynamicDimension(817, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job5Group:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(408, true, 1),
		marginTop: deviceBasedDynamicDimension(1544, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text171Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text171Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job5Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group142Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape377:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape376:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape375:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job36Group:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(400, true, 1),
		marginTop: deviceBasedDynamicDimension(2506, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text170Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text170Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job4Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group141Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape374:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape373:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape372:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job35Group:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(427, true, 1),
		marginTop: deviceBasedDynamicDimension(2880, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text169Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text169Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job3Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group140Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape371:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape370:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape369:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job34Group:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(413, true, 1),
		marginTop: deviceBasedDynamicDimension(3254, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text168Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text168Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job2Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group139Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape368:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape367:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape366:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job1Group:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(395, true, 1),
		marginTop: deviceBasedDynamicDimension(3628, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text167Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text167Text:
	{
		width: deviceBasedDynamicDimension(19, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(22, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job1Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group138Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape365:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape364:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape363:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job2Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(384, true, 1),
		marginTop: deviceBasedDynamicDimension(443, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group137Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape362:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape361:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape360:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job1Group3:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(377, true, 1),
		marginTop: deviceBasedDynamicDimension(816, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group136Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape359:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape358:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape357:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(243, 164, 10, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape356:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(2505, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape355:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(2878, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape354:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(815, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape353:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(1541, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape352:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(805, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape351:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(1023, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape350:
	{
		width: deviceBasedDynamicDimension(885, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(323, true, 1),
		marginTop: deviceBasedDynamicDimension(345, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(218, 220, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group135Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1566, true, 1),
		marginTop: deviceBasedDynamicDimension(1188, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape349:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape348:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text166Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1186, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text166Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1186, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape347:
	{
		width: deviceBasedDynamicDimension(157, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(1181, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape346:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(869, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(229, 229, 229, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape345:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(1087, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(229, 229, 229, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group134Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1689, true, 1),
		marginTop: deviceBasedDynamicDimension(881, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape344:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape343:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group133Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1689, true, 1),
		marginTop: deviceBasedDynamicDimension(1099, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape342:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape341:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text165Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(882, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text165Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(882, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text164Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1100, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text164Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1100, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text163Text:
	{
		width: deviceBasedDynamicDimension(103, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(826, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text163Text:
	{
		width: deviceBasedDynamicDimension(103, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(826, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text162Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(1042, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(18, 18, 18, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text162Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(1042, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(18, 18, 18, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	textlabel_text161Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1663, true, 1),
		marginTop: deviceBasedDynamicDimension(828, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text161Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1663, true, 1),
		marginTop: deviceBasedDynamicDimension(828, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text160Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1663, true, 1),
		marginTop: deviceBasedDynamicDimension(1044, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text160Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1663, true, 1),
		marginTop: deviceBasedDynamicDimension(1044, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	group_group132Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1720, true, 1),
		marginTop: deviceBasedDynamicDimension(829, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape340:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(196, 196, 196, 0)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape339:
	{
		width: deviceBasedDynamicDimension(5, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group131Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1720, true, 1),
		marginTop: deviceBasedDynamicDimension(1045, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape338:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(196, 196, 196, 0)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape337:
	{
		width: deviceBasedDynamicDimension(5, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group130Group:
	{
		width: deviceBasedDynamicDimension(77, true, 1),
		height: deviceBasedDynamicDimension(52, false, 1),
		marginLeft: deviceBasedDynamicDimension(582, true, 1),
		marginTop: deviceBasedDynamicDimension(298, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text159Text:
	{
		width: deviceBasedDynamicDimension(77, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text159Text:
	{
		width: deviceBasedDynamicDimension(77, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text158Text:
	{
		width: deviceBasedDynamicDimension(74, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text158Text:
	{
		width: deviceBasedDynamicDimension(74, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	group_group129Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(52, false, 1),
		marginLeft: deviceBasedDynamicDimension(250, true, 1),
		marginTop: deviceBasedDynamicDimension(298, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text157Text:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text157Text:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text156Text:
	{
		width: deviceBasedDynamicDimension(93, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text156Text:
	{
		width: deviceBasedDynamicDimension(93, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	group_group128Group:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(52, false, 1),
		marginLeft: deviceBasedDynamicDimension(901, true, 1),
		marginTop: deviceBasedDynamicDimension(298, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text155Text:
	{
		width: deviceBasedDynamicDimension(45, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text155Text:
	{
		width: deviceBasedDynamicDimension(45, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text154Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text154Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	group_group127Group:
	{
		width: deviceBasedDynamicDimension(74, true, 1),
		height: deviceBasedDynamicDimension(52, false, 1),
		marginLeft: deviceBasedDynamicDimension(1206, true, 1),
		marginTop: deviceBasedDynamicDimension(298, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text153Text:
	{
		width: deviceBasedDynamicDimension(74, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text153Text:
	{
		width: deviceBasedDynamicDimension(74, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text152Text:
	{
		width: deviceBasedDynamicDimension(37, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(18, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text152Text:
	{
		width: deviceBasedDynamicDimension(37, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(18, true, 1),
		marginTop: deviceBasedDynamicDimension(24, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(20, 29, 61, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	textlabel_text151Text:
	{
		width: deviceBasedDynamicDimension(266, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(216, true, 1),
		marginTop: deviceBasedDynamicDimension(193, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	attrbuted_textlabel_text151Text:
	{
		width: deviceBasedDynamicDimension(266, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(216, true, 1),
		marginTop: deviceBasedDynamicDimension(193, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(20, true, 1),
	},

	group_group126Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(195, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape336:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape335:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(4, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape334:
	{
		width: deviceBasedDynamicDimension(414, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(959, true, 1),
		marginTop: deviceBasedDynamicDimension(216, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(0, 0, 0, 0.12)',
		borderRadius: deviceBasedDynamicDimension(2, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group125Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1331, true, 1),
		marginTop: deviceBasedDynamicDimension(224, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape333:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape332:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text150Text:
	{
		width: deviceBasedDynamicDimension(153, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(973, true, 1),
		marginTop: deviceBasedDynamicDimension(225, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text150Text:
	{
		width: deviceBasedDynamicDimension(153, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(973, true, 1),
		marginTop: deviceBasedDynamicDimension(225, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text149Text:
	{
		width: deviceBasedDynamicDimension(53, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1252, true, 1),
		marginTop: deviceBasedDynamicDimension(225, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text149Text:
	{
		width: deviceBasedDynamicDimension(53, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1252, true, 1),
		marginTop: deviceBasedDynamicDimension(225, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape331:
	{
		width: deviceBasedDynamicDimension(83, true, 1),
		height: deviceBasedDynamicDimension(27, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(229, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(210, 210, 210, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape330:
	{
		width: deviceBasedDynamicDimension(131, true, 1),
		height: deviceBasedDynamicDimension(27, false, 1),
		marginLeft: deviceBasedDynamicDimension(275, true, 1),
		marginTop: deviceBasedDynamicDimension(229, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(210, 210, 210, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group124Group:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(287, true, 1),
		marginTop: deviceBasedDynamicDimension(236, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape329:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape328:
	{
		width: deviceBasedDynamicDimension(10, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text148Text:
	{
		width: deviceBasedDynamicDimension(59, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(196, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text148Text:
	{
		width: deviceBasedDynamicDimension(59, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(196, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text147Text:
	{
		width: deviceBasedDynamicDimension(35, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(242, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text147Text:
	{
		width: deviceBasedDynamicDimension(35, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(242, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group123Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape327:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape326:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text146Text:
	{
		width: deviceBasedDynamicDimension(52, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(315, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text146Text:
	{
		width: deviceBasedDynamicDimension(52, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(315, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group122Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(293, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape325:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape324:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text145Text:
	{
		width: deviceBasedDynamicDimension(64, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(405, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text145Text:
	{
		width: deviceBasedDynamicDimension(64, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(405, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group121Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(383, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape323:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape322:
	{
		width: deviceBasedDynamicDimension(10, true, 1),
		height: deviceBasedDynamicDimension(13, false, 1),
		marginLeft: deviceBasedDynamicDimension(4, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text144Text:
	{
		width: deviceBasedDynamicDimension(95, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(507, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text144Text:
	{
		width: deviceBasedDynamicDimension(95, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(507, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group120Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(485, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape321:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape320:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(1, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text143Text:
	{
		width: deviceBasedDynamicDimension(134, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(640, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text143Text:
	{
		width: deviceBasedDynamicDimension(134, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(640, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group119Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(776, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape319:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape318:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group118Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(618, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape317:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape316:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text142Text:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(832, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text142Text:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(832, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group117Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(810, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape315:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape314:
	{
		width: deviceBasedDynamicDimension(10, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(4, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text141Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(305, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text141Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(305, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape313:
	{
		width: deviceBasedDynamicDimension(70, true, 1),
		height: deviceBasedDynamicDimension(27, false, 1),
		marginLeft: deviceBasedDynamicDimension(414, true, 1),
		marginTop: deviceBasedDynamicDimension(229, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(210, 210, 210, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	textlabel_text140Text:
	{
		width: deviceBasedDynamicDimension(32, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(426, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text140Text:
	{
		width: deviceBasedDynamicDimension(32, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(426, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group116Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(460, true, 1),
		marginTop: deviceBasedDynamicDimension(234, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape312:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape311:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text139Text:
	{
		width: deviceBasedDynamicDimension(51, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(216, true, 1),
		marginTop: deviceBasedDynamicDimension(175, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text139Text:
	{
		width: deviceBasedDynamicDimension(51, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(216, true, 1),
		marginTop: deviceBasedDynamicDimension(175, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape310:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(280, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape309:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(366, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape308:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(417, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape307:
	{
		width: deviceBasedDynamicDimension(1, true, 1),
		height: deviceBasedDynamicDimension(87, false, 1),
		marginLeft: deviceBasedDynamicDimension(774, true, 1),
		marginTop: deviceBasedDynamicDimension(280, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape306:
	{
		width: deviceBasedDynamicDimension(1, true, 1),
		height: deviceBasedDynamicDimension(87, false, 1),
		marginLeft: deviceBasedDynamicDimension(466, true, 1),
		marginTop: deviceBasedDynamicDimension(280, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape305:
	{
		width: deviceBasedDynamicDimension(1, true, 1),
		height: deviceBasedDynamicDimension(87, false, 1),
		marginLeft: deviceBasedDynamicDimension(1082, true, 1),
		marginTop: deviceBasedDynamicDimension(280, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape304:
	{
		width: deviceBasedDynamicDimension(308, true, 1),
		height: deviceBasedDynamicDimension(2, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(365, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(20, 29, 61, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group115Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(382, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape303:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape302:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group114Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(466, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape301:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape300:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group113Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(2529, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape299:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape298:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group112Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(2902, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape297:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape296:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group111Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(839, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape295:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape294:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group110Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(1565, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape293:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape292:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape291:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(341, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(1173, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape290:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(588, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(1899, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textinput_textboxsfTxtInput:
	{
		width: deviceBasedDynamicDimension(816, true, 1),
		height: deviceBasedDynamicDimension(157, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1923, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		backgroundColor: 'rgba(0, 0, 0, 0.87)',
	},

	SUBTITLE1COLORSTYLEText:
	{
		width: deviceBasedDynamicDimension(296, true, 2.4878048780487805),
		height: deviceBasedDynamicDimension(24, false, 2.0657894736842106),
		marginLeft: deviceBasedDynamicDimension(16, true, 2.4878048780487805),
		marginTop: deviceBasedDynamicDimension(16, false, 2.0657894736842106),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_SUBTITLE1COLORSTYLEText:
	{
		width: deviceBasedDynamicDimension(296, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(16, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text138Text:
	{
		width: deviceBasedDynamicDimension(203, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2065, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text138Text:
	{
		width: deviceBasedDynamicDimension(203, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2065, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	TEXTCOLORSTYLEText:
	{
		width: deviceBasedDynamicDimension(119, true, 1.1523178807947019),
		height: deviceBasedDynamicDimension(16, false, 0.7857142857142857),
		marginLeft: deviceBasedDynamicDimension(16, true, 1.1523178807947019),
		marginTop: deviceBasedDynamicDimension(20, false, 0.7857142857142857),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_TEXTCOLORSTYLEText:
	{
		width: deviceBasedDynamicDimension(119, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(20, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	button_button1Button:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(44, false, 1),
		marginLeft: deviceBasedDynamicDimension(942, true, 1),
		marginTop: deviceBasedDynamicDimension(2096, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		justifyContent: 'center',
	},

	textlabel_text137Text:
	{
		width: deviceBasedDynamicDimension(73, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1197, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text137Text:
	{
		width: deviceBasedDynamicDimension(73, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1197, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text136Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1257, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text136Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1257, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text136Text2:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1257, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text136Text3:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1257, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text135Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1237, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text135Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1237, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text134Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text2:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text3:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text4:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text5:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text134Text6:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text133Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text2:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text3:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text4:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text5:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text133Text6:
	{
		width: deviceBasedDynamicDimension(598, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1437, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text132Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1310, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text132Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1310, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text131Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1417, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text131Text:
	{
		width: deviceBasedDynamicDimension(69, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1417, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape289:
	{
		width: deviceBasedDynamicDimension(839, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1293, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape288:
	{
		width: deviceBasedDynamicDimension(839, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1400, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group109Group:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1358, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape287:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text130Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(20, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text130Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(20, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape286:
	{
		width: deviceBasedDynamicDimension(6, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(8, true, 1),
		marginTop: deviceBasedDynamicDimension(10, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group108Group:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1465, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape285:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text129Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(20, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text129Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(20, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape284:
	{
		width: deviceBasedDynamicDimension(6, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(8, true, 1),
		marginTop: deviceBasedDynamicDimension(10, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape283:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(392, true, 1),
		marginTop: deviceBasedDynamicDimension(1358, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(156, 31, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape282:
	{
		width: deviceBasedDynamicDimension(84, true, 1),
		height: deviceBasedDynamicDimension(26, false, 1),
		marginLeft: deviceBasedDynamicDimension(392, true, 1),
		marginTop: deviceBasedDynamicDimension(1465, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(156, 31, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text128Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(412, true, 1),
		marginTop: deviceBasedDynamicDimension(1362, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text128Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(412, true, 1),
		marginTop: deviceBasedDynamicDimension(1362, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text127Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(412, true, 1),
		marginTop: deviceBasedDynamicDimension(1469, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text127Text:
	{
		width: deviceBasedDynamicDimension(56, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(412, true, 1),
		marginTop: deviceBasedDynamicDimension(1469, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape281:
	{
		width: deviceBasedDynamicDimension(6, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(400, true, 1),
		marginTop: deviceBasedDynamicDimension(1368, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape280:
	{
		width: deviceBasedDynamicDimension(6, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(400, true, 1),
		marginTop: deviceBasedDynamicDimension(1475, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text126Text:
	{
		width: deviceBasedDynamicDimension(140, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1230, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text126Text:
	{
		width: deviceBasedDynamicDimension(140, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1230, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text125Text:
	{
		width: deviceBasedDynamicDimension(34, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1138, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text125Text:
	{
		width: deviceBasedDynamicDimension(34, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1138, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text124Text:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(1186, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text124Text:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(1186, true, 1),
		marginTop: deviceBasedDynamicDimension(384, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group107Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1206, true, 1),
		marginTop: deviceBasedDynamicDimension(383, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape279:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape278:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group106Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1305, true, 1),
		marginTop: deviceBasedDynamicDimension(227, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape277:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape276:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text123Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text123Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text123Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text123Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text123Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text123Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(521, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text122Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text122Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text122Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text122Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text122Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text122Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2584, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text121Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text121Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text121Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text121Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text121Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text121Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2957, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text120Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text120Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text120Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text120Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text120Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text120Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(894, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text119Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text119Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text119Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text119Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text119Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text119Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1620, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text118Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text118Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(546, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text117Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text117Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2609, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text116Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text116Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2982, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text115Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text115Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(919, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text114Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text114Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1645, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape275:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1845, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(26, 24, 29, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape274:
	{
		width: deviceBasedDynamicDimension(82, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(455, true, 1),
		marginTop: deviceBasedDynamicDimension(1845, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(156, 31, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group105Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(431, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape273:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape272:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text113Text:
	{
		width: deviceBasedDynamicDimension(103, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(316, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text113Text:
	{
		width: deviceBasedDynamicDimension(103, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(316, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text112Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(471, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(241, 241, 241, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text112Text:
	{
		width: deviceBasedDynamicDimension(50, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(471, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(241, 241, 241, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job32Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(579, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group104Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape271:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape270:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape269:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text111Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text111Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job31Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(943, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text110Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text110Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group103Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape268:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape267:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job30Group:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(729, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text109Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text109Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text109Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group102Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape266:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape265:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text108Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(522, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text108Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(522, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text108Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(522, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text108Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(522, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text108Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(522, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text107Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2585, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text107Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2585, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text107Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2585, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text107Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2585, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text107Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2585, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text106Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2958, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text106Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2958, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text106Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2958, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text106Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2958, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text106Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2958, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text105Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(895, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text105Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(895, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text105Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(895, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text105Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(895, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text105Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(895, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text104Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1621, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text104Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1621, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text104Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1621, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text104Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1621, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text104Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1621, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group101Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(618, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape264:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape263:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group100Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(2681, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape262:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape261:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group99Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3054, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape260:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape259:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group98Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(991, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape258:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape257:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group97Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(1717, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape256:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape255:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group96Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(586, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape254:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape253:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group95Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(2649, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape252:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape251:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group94Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(3022, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape250:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape249:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group93Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(959, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape248:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape247:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group92Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(1685, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape246:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape245:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape244:
	{
		width: deviceBasedDynamicDimension(1187, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(788, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape243:
	{
		width: deviceBasedDynamicDimension(1187, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(2851, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape242:
	{
		width: deviceBasedDynamicDimension(1187, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(3224, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape241:
	{
		width: deviceBasedDynamicDimension(1235, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(1514, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	textlabel_text103Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(443, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text103Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(443, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text103Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(443, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group91Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(443, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape240:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape239:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job29Group:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(2506, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text102Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text102Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text102Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group90Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape238:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape237:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job28Group:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(2879, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text101Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text101Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text101Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group89Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape236:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape235:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job27Group:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(816, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text100Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text100Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text100Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group88Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape234:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape233:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text99Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(1542, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text99Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(1542, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text99Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1224, true, 1),
		marginTop: deviceBasedDynamicDimension(1542, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group87Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(1542, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape232:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape231:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job5Group3:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape230:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group86Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape229:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape228:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text98Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(749, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text98Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(749, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group85Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape227:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape226:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group84Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape225:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape224:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group83Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1110, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape223:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape222:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group82Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1130, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape221:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape220:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text97Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(749, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text97Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(749, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text96Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3249, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text96Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3249, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text95Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3275, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text95Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3275, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape219:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(3251, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group81Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(3275, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape218:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape217:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text94Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text94Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text94Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text94Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text94Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text94Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3330, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text93Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text93Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3355, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group80Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1110, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape216:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape215:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group79Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1130, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape214:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape213:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text92Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(3558, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text92Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(3558, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job26Group:
	{
		width: deviceBasedDynamicDimension(223, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3551, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape212:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape211:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(155, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group78Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(131, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape210:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape209:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text91Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text91Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text90Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text90Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job25Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(539, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group77Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape208:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape207:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape206:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text89Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text89Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job24Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(903, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text88Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text88Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group76Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape205:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape204:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job23Group:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(689, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text87Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text87Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text87Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group75Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape203:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape202:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text86Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3331, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text86Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3331, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text86Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3331, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text86Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3331, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text86Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3331, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group74Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3427, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape201:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape200:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group73Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(3395, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape199:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape198:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape197:
	{
		width: deviceBasedDynamicDimension(1187, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(3597, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_job22Group:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(3252, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text85Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text85Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text85Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group72Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape196:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape195:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text84Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3622, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text84Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3622, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text83Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3648, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text83Text:
	{
		width: deviceBasedDynamicDimension(880, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3648, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape194:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(68, false, 1),
		marginLeft: deviceBasedDynamicDimension(220, true, 1),
		marginTop: deviceBasedDynamicDimension(3624, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group71Group:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(3648, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape193:
	{
		width: deviceBasedDynamicDimension(20, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape192:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text82Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text82Text:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text82Text2:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text82Text3:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text82Text4:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text82Text5:
	{
		width: deviceBasedDynamicDimension(280, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3703, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text81Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text2:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text3:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text4:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text5:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text6:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text7:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text8:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(150, 150, 150, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text9:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text10:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text11:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text12:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text13:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text81Text14:
	{
		width: deviceBasedDynamicDimension(321, true, 1),
		height: deviceBasedDynamicDimension(176, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3728, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text80Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text80Text:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text80Text2:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text80Text3:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text80Text4:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(112, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	view_shape191:
	{
		width: deviceBasedDynamicDimension(1187, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(184, true, 1),
		marginTop: deviceBasedDynamicDimension(3947, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_job21Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group70Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape190:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape189:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape188:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text79Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text79Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job20Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(664, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text78Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text78Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group69Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape187:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape186:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job19Group:
	{
		width: deviceBasedDynamicDimension(96, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(863, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text77Text:
	{
		width: deviceBasedDynamicDimension(70, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text77Text:
	{
		width: deviceBasedDynamicDimension(70, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group68Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape185:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape184:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job18Group:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(450, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text76Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text76Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text76Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group67Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape183:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape182:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job17Group:
	{
		width: deviceBasedDynamicDimension(174, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1198, true, 1),
		marginTop: deviceBasedDynamicDimension(3625, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text75Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text75Text:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text75Text2:
	{
		width: deviceBasedDynamicDimension(148, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group66Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape181:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape180:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job4Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape179:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group65Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape178:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape177:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text74Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1300, true, 1),
		marginTop: deviceBasedDynamicDimension(3907, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text74Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1300, true, 1),
		marginTop: deviceBasedDynamicDimension(3907, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group64Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(983, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape176:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape175:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group63Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1003, true, 1),
		marginTop: deviceBasedDynamicDimension(3905, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape174:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape173:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text73Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1023, true, 1),
		marginTop: deviceBasedDynamicDimension(3904, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text73Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1023, true, 1),
		marginTop: deviceBasedDynamicDimension(3904, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group1Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape172:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group62Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape171:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape170:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text72Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text72Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group61Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape169:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape168:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job33Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape167:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group60Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape166:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape165:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text71Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text71Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group59Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape164:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape163:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape162:
	{
		width: deviceBasedDynamicDimension(817, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2164, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape161:
	{
		width: deviceBasedDynamicDimension(817, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2326, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape160:
	{
		width: deviceBasedDynamicDimension(48, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2205, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape159:
	{
		width: deviceBasedDynamicDimension(48, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2351, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text70Text:
	{
		width: deviceBasedDynamicDimension(150, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2206, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text70Text:
	{
		width: deviceBasedDynamicDimension(150, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2206, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text69Text:
	{
		width: deviceBasedDynamicDimension(113, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2230, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text69Text:
	{
		width: deviceBasedDynamicDimension(113, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2230, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text68Text:
	{
		width: deviceBasedDynamicDimension(110, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2352, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text68Text:
	{
		width: deviceBasedDynamicDimension(110, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2352, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text67Text:
	{
		width: deviceBasedDynamicDimension(110, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2376, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text67Text:
	{
		width: deviceBasedDynamicDimension(110, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2376, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text66Text:
	{
		width: deviceBasedDynamicDimension(53, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1064, true, 1),
		marginTop: deviceBasedDynamicDimension(2205, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text66Text:
	{
		width: deviceBasedDynamicDimension(53, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1064, true, 1),
		marginTop: deviceBasedDynamicDimension(2205, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text65Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1008, true, 1),
		marginTop: deviceBasedDynamicDimension(2173, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text65Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1008, true, 1),
		marginTop: deviceBasedDynamicDimension(2173, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text64Text:
	{
		width: deviceBasedDynamicDimension(140, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(977, true, 1),
		marginTop: deviceBasedDynamicDimension(2351, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text64Text:
	{
		width: deviceBasedDynamicDimension(140, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(977, true, 1),
		marginTop: deviceBasedDynamicDimension(2351, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text63Text:
	{
		width: deviceBasedDynamicDimension(760, true, 1),
		height: deviceBasedDynamicDimension(44, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2258, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text63Text:
	{
		width: deviceBasedDynamicDimension(760, true, 1),
		height: deviceBasedDynamicDimension(44, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2258, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text62Text:
	{
		width: deviceBasedDynamicDimension(760, true, 1),
		height: deviceBasedDynamicDimension(44, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2404, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text62Text:
	{
		width: deviceBasedDynamicDimension(760, true, 1),
		height: deviceBasedDynamicDimension(44, false, 1),
		marginLeft: deviceBasedDynamicDimension(356, true, 1),
		marginTop: deviceBasedDynamicDimension(2404, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text61Text:
	{
		width: deviceBasedDynamicDimension(60, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1313, true, 1),
		marginTop: deviceBasedDynamicDimension(176, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text61Text:
	{
		width: deviceBasedDynamicDimension(60, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1313, true, 1),
		marginTop: deviceBasedDynamicDimension(176, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'center',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	group_job16Group:
	{
		width: deviceBasedDynamicDimension(223, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(742, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape158:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape157:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(155, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group58Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(131, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape156:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape155:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text60Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text60Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text59Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text59Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job15Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(539, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group57Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape154:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape153:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape152:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text58Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text58Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job14Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(903, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text57Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text57Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group56Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape151:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape150:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job13Group:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(689, true, 1),
		marginTop: deviceBasedDynamicDimension(748, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text56Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text56Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text56Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group55Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape149:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape148:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group54Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1110, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape147:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape146:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group53Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1130, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape145:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape144:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text55Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(1125, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text55Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(1125, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job12Group:
	{
		width: deviceBasedDynamicDimension(223, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(1118, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape143:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape142:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(155, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group52Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(131, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape141:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape140:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text54Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text54Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text53Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text53Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job11Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(539, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group51Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape139:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape138:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape137:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text52Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text52Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job10Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(903, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text51Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text51Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group50Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape136:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape135:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job9Group:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(689, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text50Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text50Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text50Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group49Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape134:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape133:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job3Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(1124, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape132:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group48Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape131:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape130:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text49Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1125, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text49Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(1125, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group47Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape129:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape128:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group46Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1170, true, 1),
		marginTop: deviceBasedDynamicDimension(1852, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape127:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape126:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group45Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(987, true, 1),
		marginTop: deviceBasedDynamicDimension(2173, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape125:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape124:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text48Text:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1190, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text48Text:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1190, true, 1),
		marginTop: deviceBasedDynamicDimension(1851, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group44Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1110, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape123:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape122:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group43Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1130, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape121:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape120:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text47Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(2812, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text47Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(2812, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job8Group:
	{
		width: deviceBasedDynamicDimension(223, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(2805, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape119:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape118:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(155, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group42Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(131, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape117:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape116:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text46Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text46Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text45Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text45Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job7Group:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(539, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group41Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape115:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape114:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape113:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text44Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text44Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job6Group:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(903, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text43Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text43Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group40Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape112:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape111:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job5Group4:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(689, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text42Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text42Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text42Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group39Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape110:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape109:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group38Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1110, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape108:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape107:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group37Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1130, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape106:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape105:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text41Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(3185, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text41Text:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1150, true, 1),
		marginTop: deviceBasedDynamicDimension(3185, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job4Group3:
	{
		width: deviceBasedDynamicDimension(223, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(300, true, 1),
		marginTop: deviceBasedDynamicDimension(3178, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape104:
	{
		width: deviceBasedDynamicDimension(155, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(41, 156, 20, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape103:
	{
		width: deviceBasedDynamicDimension(68, true, 1),
		height: deviceBasedDynamicDimension(30, false, 1),
		marginLeft: deviceBasedDynamicDimension(155, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(241, 241, 241, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group36Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(131, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape102:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape101:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(3, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text40Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text40Text:
	{
		width: deviceBasedDynamicDimension(107, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(16, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	textlabel_text39Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text39Text:
	{
		width: deviceBasedDynamicDimension(36, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(171, true, 1),
		marginTop: deviceBasedDynamicDimension(7, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(26, 24, 29, 1)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job3Group3:
	{
		width: deviceBasedDynamicDimension(126, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(539, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group35Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape100:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape99:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape98:
	{
		width: deviceBasedDynamicDimension(7, true, 1),
		height: deviceBasedDynamicDimension(7, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(5, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text38Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text38Text:
	{
		width: deviceBasedDynamicDimension(100, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_job2Group3:
	{
		width: deviceBasedDynamicDimension(175, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(903, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text37Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text37Text:
	{
		width: deviceBasedDynamicDimension(149, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group34Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape97:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape96:
	{
		width: deviceBasedDynamicDimension(12, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job1Group4:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(689, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text36Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text36Text:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(94, 93, 96, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text36Text2:
	{
		width: deviceBasedDynamicDimension(164, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(26, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group33Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape95:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape94:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job2Group4:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape93:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group32Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape92:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape91:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text35Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(2812, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text35Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(2812, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group31Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(2811, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape90:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape89:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job1Group5:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape88:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group30Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape87:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape86:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text34Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(3185, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text34Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(3185, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group29Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(3184, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape85:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape84:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_job1Group6:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1248, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape83:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group28Group:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape82:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(1, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape81:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(6, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(6, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text33Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(3558, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text33Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1274, true, 1),
		marginTop: deviceBasedDynamicDimension(3558, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	group_group27Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1353, true, 1),
		marginTop: deviceBasedDynamicDimension(3557, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape80:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape79:
	{
		width: deviceBasedDynamicDimension(8, true, 1),
		height: deviceBasedDynamicDimension(4, false, 1),
		marginLeft: deviceBasedDynamicDimension(5, true, 1),
		marginTop: deviceBasedDynamicDimension(8, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group26Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(694, true, 1),
		marginTop: deviceBasedDynamicDimension(3800, false, 1),
		opacity: 0.4699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape78:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape77:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(12, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group25Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(692, true, 1),
		marginTop: deviceBasedDynamicDimension(3768, false, 1),
		opacity: 0.5699999928474426,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape76:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape75:
	{
		width: deviceBasedDynamicDimension(14, true, 1),
		height: deviceBasedDynamicDimension(14, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape1:
	{
		width: deviceBasedDynamicDimension(125, true, 1),
		height: deviceBasedDynamicDimension(36, false, 1),
		marginLeft: deviceBasedDynamicDimension(1249, true, 1),
		marginTop: deviceBasedDynamicDimension(780, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text32Text:
	{
		width: deviceBasedDynamicDimension(90, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1265, true, 1),
		marginTop: deviceBasedDynamicDimension(790, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	attrbuted_textlabel_text32Text:
	{
		width: deviceBasedDynamicDimension(90, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1265, true, 1),
		marginTop: deviceBasedDynamicDimension(790, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(12, true, 1),
	},

	image_dashboardImage:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(224, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(1261, false, 1),
		opacity: 1,
		position: 'absolute',
		resizeMode: 'contain',
	},

	view_shape74:
	{
		width: deviceBasedDynamicDimension(1920, true, 1),
		height: deviceBasedDynamicDimension(70, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(20, 29, 61, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape73:
	{
		width: deviceBasedDynamicDimension(500, true, 1),
		height: deviceBasedDynamicDimension(40, false, 1),
		marginLeft: deviceBasedDynamicDimension(227, true, 1),
		marginTop: deviceBasedDynamicDimension(15, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(40, 50, 85, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape72:
	{
		width: deviceBasedDynamicDimension(32, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1720, true, 1),
		marginTop: deviceBasedDynamicDimension(16, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(216, 216, 216, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group24Group:
	{
		width: deviceBasedDynamicDimension(43, true, 1),
		height: deviceBasedDynamicDimension(34, false, 1),
		marginLeft: deviceBasedDynamicDimension(160, true, 1),
		marginTop: deviceBasedDynamicDimension(18, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape71:
	{
		width: deviceBasedDynamicDimension(28, true, 1),
		height: deviceBasedDynamicDimension(28, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape70:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(28, true, 1),
		marginTop: deviceBasedDynamicDimension(19, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape69:
	{
		width: deviceBasedDynamicDimension(1920, true, 1),
		height: deviceBasedDynamicDimension(51, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(70, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape68:
	{
		width: deviceBasedDynamicDimension(1920, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(120, false, 1),
		opacity: 0.1000000014901161,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group23Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1681, true, 1),
		marginTop: deviceBasedDynamicDimension(20, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape67:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape66:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(4, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(252, 252, 252, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text31Text:
	{
		width: deviceBasedDynamicDimension(33, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1720, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text31Text:
	{
		width: deviceBasedDynamicDimension(33, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1720, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text30Text:
	{
		width: deviceBasedDynamicDimension(129, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(748, true, 1),
		marginTop: deviceBasedDynamicDimension(25, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text30Text:
	{
		width: deviceBasedDynamicDimension(129, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(748, true, 1),
		marginTop: deviceBasedDynamicDimension(25, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text29Text:
	{
		width: deviceBasedDynamicDimension(131, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(283, true, 1),
		marginTop: deviceBasedDynamicDimension(25, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Light',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text29Text:
	{
		width: deviceBasedDynamicDimension(131, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(283, true, 1),
		marginTop: deviceBasedDynamicDimension(25, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(255, 255, 255, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Light',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	group_group22Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(243, true, 1),
		marginTop: deviceBasedDynamicDimension(23, false, 1),
		opacity: 0.5999999642372131,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape65:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape64:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text28Text:
	{
		width: deviceBasedDynamicDimension(101, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(287, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text28Text:
	{
		width: deviceBasedDynamicDimension(101, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(287, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(228, 93, 51, 1)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text27Text:
	{
		width: deviceBasedDynamicDimension(81, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(158, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text27Text:
	{
		width: deviceBasedDynamicDimension(81, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(158, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape63:
	{
		width: deviceBasedDynamicDimension(101, true, 1),
		height: deviceBasedDynamicDimension(2, false, 1),
		marginLeft: deviceBasedDynamicDimension(287, true, 1),
		marginTop: deviceBasedDynamicDimension(119, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(228, 93, 51, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text26Text:
	{
		width: deviceBasedDynamicDimension(52, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(538, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text26Text:
	{
		width: deviceBasedDynamicDimension(52, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(538, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text25Text:
	{
		width: deviceBasedDynamicDimension(55, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(435, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text25Text:
	{
		width: deviceBasedDynamicDimension(55, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(435, true, 1),
		marginTop: deviceBasedDynamicDimension(86, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape62:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(408, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape61:
	{
		width: deviceBasedDynamicDimension(334, true, 1),
		height: deviceBasedDynamicDimension(1, false, 1),
		marginLeft: deviceBasedDynamicDimension(1419, true, 1),
		marginTop: deviceBasedDynamicDimension(627, false, 1),
		opacity: 0.57,
		position: 'absolute',
		borderColor: 'rgba(221, 221, 221, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape60:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(214, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(229, 229, 229, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape59:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(476, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(229, 229, 229, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape58:
	{
		width: deviceBasedDynamicDimension(294, true, 1),
		height: deviceBasedDynamicDimension(48, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(691, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		borderColor: 'rgba(229, 229, 229, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group21Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1689, true, 1),
		marginTop: deviceBasedDynamicDimension(226, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape57:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape56:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group20Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1689, true, 1),
		marginTop: deviceBasedDynamicDimension(488, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape55:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape54:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group19Group:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1689, true, 1),
		marginTop: deviceBasedDynamicDimension(703, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape53:
	{
		width: deviceBasedDynamicDimension(24, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape52:
	{
		width: deviceBasedDynamicDimension(17, true, 1),
		height: deviceBasedDynamicDimension(17, false, 1),
		marginLeft: deviceBasedDynamicDimension(3, true, 1),
		marginTop: deviceBasedDynamicDimension(3, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text24Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(227, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text24Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(227, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text23Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(489, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text23Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(489, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text22Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text22Text:
	{
		width: deviceBasedDynamicDimension(270, true, 1),
		height: deviceBasedDynamicDimension(33, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(704, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text21Text:
	{
		width: deviceBasedDynamicDimension(78, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(171, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text21Text:
	{
		width: deviceBasedDynamicDimension(78, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(171, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text20Text:
	{
		width: deviceBasedDynamicDimension(79, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(433, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text20Text:
	{
		width: deviceBasedDynamicDimension(79, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(433, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text19Text:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(648, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	attrbuted_textlabel_text19Text:
	{
		width: deviceBasedDynamicDimension(41, true, 1),
		height: deviceBasedDynamicDimension(24, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(648, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Medium',
		fontSize: deviceBasedDynamicDimension(16, true, 1),
	},

	textlabel_text18Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(174, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text18Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(174, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text17Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(435, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text17Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(435, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text16Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(650, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text16Text:
	{
		width: deviceBasedDynamicDimension(49, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1661, true, 1),
		marginTop: deviceBasedDynamicDimension(650, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.6)',
		textAlign: 'right',
		textAlignVertical: 'top',
		fontFamily: 'Roboto-Medium',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	group_group18Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1717, true, 1),
		marginTop: deviceBasedDynamicDimension(174, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape51:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(196, 196, 196, 0)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape50:
	{
		width: deviceBasedDynamicDimension(5, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group17Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1717, true, 1),
		marginTop: deviceBasedDynamicDimension(435, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape49:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(196, 196, 196, 0)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape48:
	{
		width: deviceBasedDynamicDimension(5, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group16Group:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(1717, true, 1),
		marginTop: deviceBasedDynamicDimension(650, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape47:
	{
		width: deviceBasedDynamicDimension(16, true, 1),
		height: deviceBasedDynamicDimension(16, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(196, 196, 196, 0)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape46:
	{
		width: deviceBasedDynamicDimension(5, true, 1),
		height: deviceBasedDynamicDimension(10, false, 1),
		marginLeft: deviceBasedDynamicDimension(6, true, 1),
		marginTop: deviceBasedDynamicDimension(4, false, 1),
		opacity: 0.699999988079071,
		position: 'absolute',
		backgroundColor: 'rgba(18, 18, 18, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group15Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1543, true, 1),
		marginTop: deviceBasedDynamicDimension(279, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape45:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape44:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group14Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1555, true, 1),
		marginTop: deviceBasedDynamicDimension(539, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape43:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape42:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group13Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1539, true, 1),
		marginTop: deviceBasedDynamicDimension(752, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape41:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape40:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group12Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1588, true, 1),
		marginTop: deviceBasedDynamicDimension(320, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape39:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape38:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group11Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1505, true, 1),
		marginTop: deviceBasedDynamicDimension(580, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape37:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape36:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group10Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1548, true, 1),
		marginTop: deviceBasedDynamicDimension(360, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape35:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape34:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group9Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1685, true, 1),
		marginTop: deviceBasedDynamicDimension(320, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape33:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape32:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group8Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1636, true, 1),
		marginTop: deviceBasedDynamicDimension(580, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape31:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape30:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group7Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1679, true, 1),
		marginTop: deviceBasedDynamicDimension(279, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape29:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape28:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	group_group6Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1650, true, 1),
		marginTop: deviceBasedDynamicDimension(539, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape27:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape26:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text15Text:
	{
		width: deviceBasedDynamicDimension(83, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(277, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text15Text:
	{
		width: deviceBasedDynamicDimension(83, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(277, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text14Text:
	{
		width: deviceBasedDynamicDimension(96, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(537, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text14Text:
	{
		width: deviceBasedDynamicDimension(96, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(537, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text13Text:
	{
		width: deviceBasedDynamicDimension(80, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(750, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text13Text:
	{
		width: deviceBasedDynamicDimension(80, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(750, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text12Text:
	{
		width: deviceBasedDynamicDimension(129, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(318, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text12Text:
	{
		width: deviceBasedDynamicDimension(129, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(318, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text11Text:
	{
		width: deviceBasedDynamicDimension(46, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(578, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text11Text:
	{
		width: deviceBasedDynamicDimension(46, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(578, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text10Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(358, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text10Text:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(358, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text9Text:
	{
		width: deviceBasedDynamicDimension(39, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1638, true, 1),
		marginTop: deviceBasedDynamicDimension(318, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text9Text:
	{
		width: deviceBasedDynamicDimension(39, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1638, true, 1),
		marginTop: deviceBasedDynamicDimension(318, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text8Text:
	{
		width: deviceBasedDynamicDimension(73, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1555, true, 1),
		marginTop: deviceBasedDynamicDimension(578, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text8Text:
	{
		width: deviceBasedDynamicDimension(73, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1555, true, 1),
		marginTop: deviceBasedDynamicDimension(578, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text7Text:
	{
		width: deviceBasedDynamicDimension(82, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1589, true, 1),
		marginTop: deviceBasedDynamicDimension(277, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text7Text:
	{
		width: deviceBasedDynamicDimension(82, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1589, true, 1),
		marginTop: deviceBasedDynamicDimension(277, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	textlabel_text6Text:
	{
		width: deviceBasedDynamicDimension(37, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1605, true, 1),
		marginTop: deviceBasedDynamicDimension(537, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text6Text:
	{
		width: deviceBasedDynamicDimension(37, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1605, true, 1),
		marginTop: deviceBasedDynamicDimension(537, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape25:
	{
		width: deviceBasedDynamicDimension(130, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(272, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape24:
	{
		width: deviceBasedDynamicDimension(146, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(532, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape23:
	{
		width: deviceBasedDynamicDimension(130, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(745, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape22:
	{
		width: deviceBasedDynamicDimension(179, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(313, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape21:
	{
		width: deviceBasedDynamicDimension(96, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(573, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape20:
	{
		width: deviceBasedDynamicDimension(139, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(353, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape19:
	{
		width: deviceBasedDynamicDimension(89, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1626, true, 1),
		marginTop: deviceBasedDynamicDimension(313, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape18:
	{
		width: deviceBasedDynamicDimension(123, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1543, true, 1),
		marginTop: deviceBasedDynamicDimension(573, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape17:
	{
		width: deviceBasedDynamicDimension(132, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1577, true, 1),
		marginTop: deviceBasedDynamicDimension(272, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	view_shape16:
	{
		width: deviceBasedDynamicDimension(87, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1593, true, 1),
		marginTop: deviceBasedDynamicDimension(532, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group5Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1649, true, 1),
		marginTop: deviceBasedDynamicDimension(934, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape15:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape14:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text5Text:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(932, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text5Text:
	{
		width: deviceBasedDynamicDimension(190, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(932, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape13:
	{
		width: deviceBasedDynamicDimension(240, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(927, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group4Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1530, true, 1),
		marginTop: deviceBasedDynamicDimension(974, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape12:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape11:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text4Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(972, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text4Text:
	{
		width: deviceBasedDynamicDimension(71, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(972, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape10:
	{
		width: deviceBasedDynamicDimension(121, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(967, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group3Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1654, true, 1),
		marginTop: deviceBasedDynamicDimension(974, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape9:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape8:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text3Text:
	{
		width: deviceBasedDynamicDimension(66, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1580, true, 1),
		marginTop: deviceBasedDynamicDimension(972, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text3Text:
	{
		width: deviceBasedDynamicDimension(66, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1580, true, 1),
		marginTop: deviceBasedDynamicDimension(972, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape7:
	{
		width: deviceBasedDynamicDimension(116, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1568, true, 1),
		marginTop: deviceBasedDynamicDimension(967, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group2Group:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1530, true, 1),
		marginTop: deviceBasedDynamicDimension(1148, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape6:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape5:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text2Text:
	{
		width: deviceBasedDynamicDimension(72, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1146, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text2Text:
	{
		width: deviceBasedDynamicDimension(72, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1451, true, 1),
		marginTop: deviceBasedDynamicDimension(1146, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape4:
	{
		width: deviceBasedDynamicDimension(121, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1439, true, 1),
		marginTop: deviceBasedDynamicDimension(1141, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

	group_group1Group2:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(1691, true, 1),
		marginTop: deviceBasedDynamicDimension(1147, false, 1),
		opacity: 0.3700000047683716,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape3:
	{
		width: deviceBasedDynamicDimension(18, true, 1),
		height: deviceBasedDynamicDimension(18, false, 1),
		marginLeft: deviceBasedDynamicDimension(0, true, 1),
		marginTop: deviceBasedDynamicDimension(0, false, 1),
		opacity: 1,
		position: 'absolute',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	view_shape2:
	{
		width: deviceBasedDynamicDimension(15, true, 1),
		height: deviceBasedDynamicDimension(15, false, 1),
		marginLeft: deviceBasedDynamicDimension(2, true, 1),
		marginTop: deviceBasedDynamicDimension(2, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 1)',
		borderRadius: deviceBasedDynamicDimension(0, true, 1),
		borderWidth: deviceBasedDynamicDimension(0, true, 1),
	},

	textlabel_text1Text:
	{
		width: deviceBasedDynamicDimension(102, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1581, true, 1),
		marginTop: deviceBasedDynamicDimension(1145, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	attrbuted_textlabel_text1Text:
	{
		width: deviceBasedDynamicDimension(102, true, 1),
		height: deviceBasedDynamicDimension(20, false, 1),
		marginLeft: deviceBasedDynamicDimension(1581, true, 1),
		marginTop: deviceBasedDynamicDimension(1145, false, 1),
		opacity: 1,
		position: 'absolute',
		backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'normal',
		includeFontPadding: false,
		padding: deviceBasedDynamicDimension(0, true, 1),
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		textAlignVertical: 'top',
		fontFamily: 'Poppins-Regular',
		fontSize: deviceBasedDynamicDimension(14, true, 1),
	},

	view_shape1100:
	{
		width: deviceBasedDynamicDimension(152, true, 1),
		height: deviceBasedDynamicDimension(32, false, 1),
		marginLeft: deviceBasedDynamicDimension(1569, true, 1),
		marginTop: deviceBasedDynamicDimension(1140, false, 1),
		opacity: 1,
		position: 'absolute',
		borderColor: 'rgba(224, 224, 224, 1)',
		borderRadius: deviceBasedDynamicDimension(4, true, 1),
		borderWidth: deviceBasedDynamicDimension(1, true, 1),
	},

})
